export const GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  spreedlyList: {},
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        spreedlyList: {}
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        spreedlyList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        ingenicoList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        spreedlyList: action.result,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfigSpreedly = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigSpreedly/${storeId}`)
  };
};

export const updateStorePaymentSystemConfigSpreedly = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SPREEDLY_FAILURE],
    promise: client => client.post(`upateStorePaymentSystemConfigSpreedly/${storeId}`, { data })
  };
};
