const currentReducer = 'deliveryScreen';
const GET_DELIVERY_REQUESTED = `${currentReducer}/GET_DELIVERY_REQUESTED`;
const GET_DELIVERY_SUCCESS = `${currentReducer}/GET_DELIVERY_SUCCESS`;
const GET_DELIVERY_FAILURE = `${currentReducer}/GET_DELIVERY_FAILURE`;

const ADD_DELIVERY_REQUESTED = 'deliveryScreen/ADD_DELIVERY_REQUESTED';
const ADD_DELIVERY_SUCCESS = 'deliveryScreen/ADD_DELIVERY_SUCCESS';
const ADD_DELIVERY_FAILURE = 'deliveryScreen/ADD_DELIVERY_FAILURE';

const UPDATE_DELIVERY_REQUESTED = 'deliveryScreen/UPDATE_DELIVERY_REQUESTED';
const UPDATE_DELIVERY_SUCCESS = 'deliveryScreen/UPDATE_DELIVERY_SUCCESS';
const UPDATE_DELIVERY_FAILURE = 'deliveryScreen/UPDATE_DELIVERY_FAILURE';

const DELETE_DELIVERY_REQUESTED = 'deliveryScreen/DELETE_DELIVERY_REQUESTED';
const DELETE_DELIVERY_SUCCESS = 'deliveryScreen/DELETE_DELIVERY_SUCCESS';
const DELETE_DELIVERY_FAILURE = 'deliveryScreen/DELETE_DELIVERY_FAILURE';

const GET_DELIVERY_BY_ID_REQUESTED = 'deliveryScreen/GET_DELIVERY_BY_ID_REQUESTED';
const GET_DELIVERY_BY_ID_SUCCESS = 'deliveryScreen/GET_DELIVERY_BY_ID_SUCCESS';
const GET_DELIVERY_BY_ID_FAILURE = 'deliveryScreen/GET_DELIVERY_BY_ID_FAILURE';

const ADD_DELIVERY_RULE_REQUESTED = 'deliveryScreen/ADD_DELIVERY_RULE_REQUESTED';
const ADD_DELIVERY_RULE_SUCCESS = 'deliveryScreen/ADD_DELIVERY_RULE_SUCCESS';
const ADD_DELIVERY_RULE_FAILURE = 'deliveryScreen/ADD_DELIVERY_RULE_FAILURE';

const UPDATE_DELIVERY_RULE_REQUESTED = 'deliveryScreen/UPDATE_DELIVERY_RULE_REQUESTED';
const UPDATE_DELIVERY_RULE_SUCCESS = 'deliveryScreen/UPDATE_DELIVERY_RULE_SUCCESS';
const UPDATE_DELIVERY_RULE_FAILURE = 'deliveryScreen/UPDATE_DELIVERY_RULE_FAILURE';

const DELETE_DELIVERY_RULE_REQUESTED = 'deliveryScreen/DELETE_DELIVERY_RULE_REQUESTED';
const DELETE_DELIVERY_RULE_SUCCESS = 'deliveryScreen/DELETE_DELIVERY_RULE_SUCCESS';
const DELETE_DELIVERY_RULE_FAILURE = 'deliveryScreen/DELETE_DELIVERY_RULE_FAILURE';

const ADD_DELIVERY_SLOT_REQUESTED = 'deliveryScreen/ADD_DELIVERY_SLOT_REQUESTED';
const ADD_DELIVERY_SLOT_SUCCESS = 'deliveryScreen/ADD_DELIVERY_SLOT_SUCCESS';
const ADD_DELIVERY_SLOT_FAILURE = 'deliveryScreen/ADD_DELIVERY_SLOT_FAILURE';

const UPDATE_DELIVERY_SLOT_REQUESTED = 'deliveryScreen/UPDATE_DELIVERY_SLOT_REQUESTED';
const UPDATE_DELIVERY_SLOT_SUCCESS = 'deliveryScreen/UPDATE_DELIVERY_SLOT_SUCCESS';
const UPDATE_DELIVERY_SLOT_FAILURE = 'deliveryScreen/UPDATE_DELIVERY_SLOT_FAILURE';

const DELETE_DELIVERY_SLOT_REQUESTED = 'deliveryScreen/DELETE_DELIVERY_SLOT_REQUESTED';
const DELETE_DELIVERY_SLOT_SUCCESS = 'deliveryScreen/DELETE_DELIVERY_SLOT_SUCCESS';
const DELETE_DELIVERY_SLOT_FAILURE = 'deliveryScreen/DELETE_DELIVERY_SLOT_FAILURE';

const GET_DELIVERY_RULE_TYPE_REQUESTED = 'deliveryScreen/GET_DELIVERY_RULE_TYPE_REQUESTED';
const GET_DELIVERY_RULE_TYPE_SUCCESS = 'deliveryScreen/GET_DELIVERY_RULE_TYPE_SUCCESS';
const GET_DELIVERY_RULE_TYPE_FAILURE = 'deliveryScreen/GET_DELIVERY_RULE_TYPE_FAILURE';

const GET_DELIVERY_FEE_TYPE_REQUESTED = 'deliveryScreen/GET_DELIVERY_FEE_TYPE_REQUESTED';
const GET_DELIVERY_FEE_TYPE_SUCCESS = 'deliveryScreen/GET_DELIVERY_FEE_TYPE_SUCCESS';
const GET_DELIVERY_FEE_TYPE_FAILURE = 'deliveryScreen/GET_DELIVERY_FEE_TYPE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  deliveryScreenList: [],
  deliveryData: null,
  ruleType: [],
  feeType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_DELIVERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deliveryScreenList: action.result
      };
    }
    case GET_DELIVERY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_DELIVERY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DELIVERY_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_DELIVERY_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_DELIVERY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DELIVERY_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_DELIVERY_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_DELIVERY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DELIVERY_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_DELIVERY_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_DELIVERY_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DELIVERY_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deliveryData: action.result,
      };
    }
    case GET_DELIVERY_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_DELIVERY_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DELIVERY_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_DELIVERY_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_DELIVERY_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DELIVERY_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_DELIVERY_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_DELIVERY_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DELIVERY_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_DELIVERY_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_DELIVERY_SLOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DELIVERY_SLOT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_DELIVERY_SLOT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_DELIVERY_SLOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DELIVERY_SLOT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_DELIVERY_SLOT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_DELIVERY_SLOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DELIVERY_SLOT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_DELIVERY_SLOT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_DELIVERY_RULE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_DELIVERY_RULE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ruleType: action.result
      };
    }
    case GET_DELIVERY_RULE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_DELIVERY_FEE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_DELIVERY_FEE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        feeType: action.result
      };
    }
    case GET_DELIVERY_FEE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getdeliveryList = (brandId) => {
  return {
    types: [GET_DELIVERY_REQUESTED, GET_DELIVERY_SUCCESS, GET_DELIVERY_FAILURE],
    promise: client => client.get(`deliveryList/${brandId}`)
  };
};

export const deleteDeliveryItem = (brandDeliveryId) => {
  return {
    types: [DELETE_DELIVERY_REQUESTED, DELETE_DELIVERY_SUCCESS, DELETE_DELIVERY_FAILURE],
    promise: client => client.post(`deleteDeliveryItem/${brandDeliveryId}`)
  };
};

export const adddeliveryScreen = (data) => {
  return {
    types: [ADD_DELIVERY_REQUESTED, ADD_DELIVERY_SUCCESS, ADD_DELIVERY_FAILURE],
    promise: client => client.post('addDeliveryScreen', { data })
  };
};

export const updatedeliveryScreen = (data) => {
  return {
    types: [UPDATE_DELIVERY_REQUESTED, UPDATE_DELIVERY_SUCCESS, UPDATE_DELIVERY_FAILURE],
    promise: client => client.post('updateDeliveryScreen', { data })
  };
};

export const getBrandDeliveryById = (deliveryId) => {
  return {
    types: [GET_DELIVERY_BY_ID_REQUESTED, GET_DELIVERY_BY_ID_SUCCESS, GET_DELIVERY_BY_ID_FAILURE],
    promise: client => client.get(`getBrandDeliveryById/${deliveryId}`)
  };
};

export const deleteDeliveryRuleItem = (deliveryRuleId) => {
  return {
    types: [DELETE_DELIVERY_RULE_REQUESTED, DELETE_DELIVERY_RULE_SUCCESS, DELETE_DELIVERY_RULE_FAILURE],
    promise: client => client.post(`deleteDeliveryRule/${deliveryRuleId}`)
  };
};

export const addDeliveryRule = (data) => {
  return {
    types: [ADD_DELIVERY_RULE_REQUESTED, ADD_DELIVERY_RULE_SUCCESS, ADD_DELIVERY_RULE_FAILURE],
    promise: client => client.post('addDeliveryRule', { data })
  };
};

export const updateDeliveryRule = (data) => {
  return {
    types: [UPDATE_DELIVERY_RULE_REQUESTED, UPDATE_DELIVERY_RULE_SUCCESS, UPDATE_DELIVERY_RULE_FAILURE],
    promise: client => client.post('updateDeliveryRule', { data })
  };
};

export const deleteDeliverySlotItem = (deliverySlotId) => {
  return {
    types: [DELETE_DELIVERY_SLOT_REQUESTED, DELETE_DELIVERY_SLOT_SUCCESS, DELETE_DELIVERY_SLOT_FAILURE],
    promise: client => client.post(`deleteDeliverySlot/${deliverySlotId}`)
  };
};

export const addDeliverySlot = (data) => {
  return {
    types: [ADD_DELIVERY_SLOT_REQUESTED, ADD_DELIVERY_SLOT_SUCCESS, ADD_DELIVERY_SLOT_FAILURE],
    promise: client => client.post('addDeliverySlot', { data })
  };
};

export const updateDeliverySlot = (data) => {
  return {
    types: [UPDATE_DELIVERY_SLOT_REQUESTED, UPDATE_DELIVERY_SLOT_SUCCESS, UPDATE_DELIVERY_SLOT_FAILURE],
    promise: client => client.post('updateDeliverySlot', { data })
  };
};

export const getDelivertRuleTypes = () => {
  return {
    types: [GET_DELIVERY_RULE_TYPE_REQUESTED, GET_DELIVERY_RULE_TYPE_SUCCESS, GET_DELIVERY_RULE_TYPE_FAILURE],
    promise: client => client.get('getDelivertRuleTypes')
  };
};

export const getFeeTypes = () => {
  return {
    types: [GET_DELIVERY_FEE_TYPE_REQUESTED, GET_DELIVERY_FEE_TYPE_SUCCESS, GET_DELIVERY_FEE_TYPE_FAILURE],
    promise: client => client.get('getChargeType')
  };
};
