const GET_BRAND_THEME_REQUESTED = 'theme/GET_BRAND_THEME_REQUESTED';
const GET_BRAND_THEME_SUCCESS = 'theme/GET_BRAND_THEME_SUCCESS';
const GET_BRAND_THEME_FAILURE = 'theme/GET_BRAND_THEME_FAILURE';

const GET_BRAND_CUSTOMIZE_REQUESTED = 'theme/GET_BRAND_CUSTOMIZE_REQUESTED';
const GET_BRAND_CUSTOMIZE_SUCCESS = 'theme/GET_BRAND_CUSTOMIZE_SUCCESS';
const GET_BRAND_CUSTOMIZE_FAILURE = 'theme/GET_BRAND_CUSTOMIZE_FAILURE';

const POST_BRAND_CUSTOMIZE_REQUESTED = 'theme/POST_BRAND_CUSTOMIZE_REQUESTED';
const POST_BRAND_CUSTOMIZE_SUCCESS = 'theme/POST_BRAND_CUSTOMIZE_SUCCESS';
const POST_BRAND_CUSTOMIZE_FAILURE = 'theme/POST_BRAND_CUSTOMIZE_FAILURE';

const RESET_THEME = 'theme/RESET_THEME';

const GET_MOBILE_CUSTOMIZE_REQUESTED = 'theme/GET_MOBILE_CUSTOMIZE_REQUESTED';
const GET_MOBILE_CUSTOMIZE_SUCCESS = 'theme/GET_MOBILE_CUSTOMIZE_SUCCESS';
const GET_MOBILE_CUSTOMIZE_FAILURE = 'theme/GET_MOBILE_CUSTOMIZE_FAILURE';

const POST_MOBILE_CUSTOMIZE_REQUESTED = 'theme/POST_MOBILE_CUSTOMIZE_REQUESTED';
const POST_MOBILE_CUSTOMIZE_SUCCESS = 'theme/POST_MOBILE_CUSTOMIZE_SUCCESS';
const POST_MOBILE_CUSTOMIZE_FAILURE = 'theme/POST_MOBILE_CUSTOMIZE_FAILURE';

const POST_SERVER_APP_CUSTOMIZE_REQUESTED = 'theme/POST_SERVER_APP_CUSTOMIZE_REQUESTED';
const POST_SERVER_APP_CUSTOMIZE_SUCCESS = 'theme/POST_SERVER_APP_CUSTOMIZE_SUCCESS';
const POST_SERVER_APP_CUSTOMIZE_FAILURE = 'theme/POST_SERVER_APP_CUSTOMIZE_FAILURE';

const GET_BRAND_KIOSK_CUSTOMIZE_REQUESTED = 'theme/GET_BRAND_KIOSK_CUSTOMIZE_REQUESTED';
const GET_BRAND_KIOSK_CUSTOMIZE_SUCCESS = 'theme/GET_BRAND_KIOSK_CUSTOMIZE_SUCCESS';
const GET_BRAND_KIOSK_CUSTOMIZE_FAILURE = 'theme/GET_BRAND_KIOSK_CUSTOMIZE_FAILURE';

const POST_BRAND_KIOSK_CUSTOMIZE_REQUESTED = 'theme/POST_BRAND_KIOSK_CUSTOMIZE_REQUESTED';
const POST_BRAND_KIOSK_CUSTOMIZE_SUCCESS = 'theme/POST_BRAND_KIOSK_CUSTOMIZE_SUCCESS';
const POST_BRAND_KIOSK_CUSTOMIZE_FAILURE = 'theme/POST_BRAND_KIOSK_CUSTOMIZE_FAILURE';

const GET_TABLE_TOP_CUSTOMIZE_REQUESTED = 'theme/GET_TABLE_TOP_CUSTOMIZE_REQUESTED';
const GET_TABLE_TOP_CUSTOMIZE_SUCCESS = 'theme/GET_TABLE_TOP_CUSTOMIZE_SUCCESS';
const GET_TABLE_TOP_CUSTOMIZE_FAILURE = 'theme/GET_TABLE_TOP_CUSTOMIZE_FAILURE';

const POST_TABLE_TOP_CUSTOMIZE_REQUESTED = 'theme/POST_TABLE_TOP_CUSTOMIZE_REQUESTED';
const POST_TABLE_TOP_CUSTOMIZE_SUCCESS = 'theme/POST_TABLE_TOP_CUSTOMIZE_SUCCESS';
const POST_TABLE_TOP_CUSTOMIZE_FAILURE = 'theme/POST_TABLE_TOP_CUSTOMIZE_FAILURE';

const GET_ORDER_UP_CUSTOMIZE_REQUESTED = 'theme/GET_ORDER_UP_CUSTOMIZE_REQUESTED';
const GET_ORDER_UP_CUSTOMIZE_SUCCESS = 'theme/GET_ORDER_UP_CUSTOMIZE_SUCCESS';
const GET_ORDER_UP_CUSTOMIZE_FAILURE = 'theme/GET_ORDER_UP_CUSTOMIZE_FAILURE';

const POST_ORDER_UP_CUSTOMIZE_REQUESTED = 'theme/POST_ORDER_UP_CUSTOMIZE_REQUESTED';
const POST_ORDER_UP_CUSTOMIZE_SUCCESS = 'theme/POST_ORDER_UP_CUSTOMIZE_SUCCESS';
const POST_ORDER_UP_CUSTOMIZE_FAILURE = 'theme/POST_ORDER_UP_CUSTOMIZE_FAILURE';

const GET_BRAND_KDS_CUSTOMIZE_REQUESTED = 'theme/GET_BRAND_KDS_CUSTOMIZE_REQUESTED';
const GET_BRAND_KDS_CUSTOMIZE_SUCCESS = 'theme/GET_BRAND_KDS_CUSTOMIZE_SUCCESS';
const GET_BRAND_KDS_CUSTOMIZE_FAILURE = 'theme/GET_BRAND_KDS_CUSTOMIZE_FAILURE';

const POST_BRAND_KDS_CUSTOMIZE_REQUESTED = 'theme/POST_BRAND_KDS_CUSTOMIZE_REQUESTED';
const POST_BRAND_KDS_CUSTOMIZE_SUCCESS = 'theme/POST_BRAND_KDS_CUSTOMIZE_SUCCESS';
const POST_BRAND_KDS_CUSTOMIZE_FAILURE = 'theme/POST_BRAND_KDS_CUSTOMIZE_FAILURE';

const GET_BRAND_SERVER_APP_THEME_REQUESTED = 'theme/GET_BRAND_SERVER_APP_THEME_REQUESTED';
const GET_BRAND_SERVER_APP_THEME_SUCCESS = 'theme/GET_BRAND_SERVER_APP_THEME_SUCCESS';
const GET_BRAND_SERVER_APP_THEME_FAILURE = 'theme/GET_BRAND_SERVER_APP_THEME_FAILURE';

const GET_BRAND_KIOSK_THEME_REQUESTED = 'theme/GET_BRAND_KIOSK_THEME_REQUESTED';
const GET_BRAND_KIOSK_THEME_SUCCESS = 'theme/GET_BRAND_KIOSK_THEME_SUCCESS';
const GET_BRAND_KIOSK_THEME_FAILURE = 'theme/GET_BRAND_KIOSK_THEME_FAILURE';

const GET_BRAND_TABLE_TOP_THEME_REQUESTED = 'theme/GET_BRAND_TABLE_TOP_THEME_REQUESTED';
const GET_BRAND_TABLE_TOP_THEME_SUCCESS = 'theme/GET_BRAND_TABLE_TOP_THEME_SUCCESS';
const GET_BRAND_TABLE_TOP_THEME_FAILURE = 'theme/GET_BRAND_TABLE_TOP_THEME_FAILURE';

const POST_KIOSK_THEME_UPDATE_REQUESTED = 'theme/POST_KIOSK_THEME_UPDATE_REQUESTED';
const POST_KIOSK_THEME_UPDATE_SUCCESS = 'theme/POST_KIOSK_THEME_UPDATE_SUCCESS';
const POST_KIOSK_THEME_UPDATE_FAILURE = 'theme/POST_KIOSK_THEME_UPDATE_FAILURE';

const POST_TABLE_TOP_THEME_UPDATE_REQUESTED = 'theme/POST_TABLE_TOP_THEME_UPDATE_REQUESTED';
const POST_TABLE_TOP_THEME_UPDATE_SUCCESS = 'theme/POST_TABLE_TOP_THEME_UPDATE_SUCCESS';
const POST_TABLE_TOP_THEME_UPDATE_FAILURE = 'theme/POST_TABLE_TOP_THEME_UPDATE_FAILURE';

const GET_BRAND_KDS_THEME_REQUESTED = 'theme/GET_BRAND_KDS_THEME_REQUESTED';
const GET_BRAND_KDS_THEME_SUCCESS = 'theme/GET_BRAND_KDS_THEME_SUCCESS';
const GET_BRAND_KDS_THEME_FAILURE = 'theme/GET_BRAND_KDS_THEME_FAILURE';

const UPDATE_KDS_THEME_REQUESTED = 'theme/UPDATE_KDS_THEME_REQUESTED';
const UPDATE_KDS_THEME_SUCCESS = 'theme/UPDATE_KDS_THEME_SUCCESS';
const UPDATE_KDS_THEME_FAILURE = 'theme/UPDATE_KDS_THEME_FAILURE';

const GET_BRAND_ORDER_UP_THEME_REQUESTED = 'theme/GET_BRAND_ORDER_UP_THEME_REQUESTED';
const GET_BRAND_ORDER_UP_THEME_SUCCESS = 'theme/GET_BRAND_ORDER_UP_THEME_SUCCESS';
const GET_BRAND_ORDER_UP_THEME_FAILURE = 'theme/GET_BRAND_ORDER_UP_THEME_FAILURE';

const UPDATE_ORDER_UP_THEME_REQUESTED = 'theme/UPDATE_ORDER_UP_THEME_REQUESTED';
const UPDATE_ORDER_UP_THEME_SUCCESS = 'theme/UPDATE_ORDER_UP_THEME_SUCCESS';
const UPDATE_ORDER_UP_THEME_FAILURE = 'theme/UPDATE_ORDER_UP_THEME_FAILURE';

const GET_BRAND_WAITLIST_THEME_REQUESTED = 'theme/GET_BRAND_WAITLIST_THEME_REQUESTED';
const GET_BRAND_WAITLIST_THEME_SUCCESS = 'theme/GET_BRAND_WAITLIST_THEME_SUCCESS';
const GET_BRAND_WAITLIST_THEME_FAILURE = 'theme/GET_BRAND_WAITLIST_THEME_FAILURE';

const UPDATE_WAITLIST_THEME_REQUESTED = 'theme/UPDATE_WAITLIST_THEME_REQUESTED';
const UPDATE_WAITLIST_THEME_SUCCESS = 'theme/UPDATE_WAITLIST_THEME_SUCCESS';
const UPDATE_WAITLIST_THEME_FAILURE = 'theme/UPDATE_WAITLIST_THEME_FAILURE';

const GET_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED = 'theme/GET_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED';
const GET_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS = 'theme/GET_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS';
const GET_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE = 'theme/GET_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE';

const POST_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED = 'theme/POST_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED';
const POST_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS = 'theme/POST_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS';
const POST_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE = 'theme/POST_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE';

const GET_BRAND_MOBILE_APP_THEME_REQUESTED = 'theme/GET_BRAND_MOBILE_APP_THEME_REQUESTED';
const GET_BRAND_MOBILE_APP_THEME_SUCCESS = 'theme/GET_BRAND_MOBILE_APP_THEME_SUCCESS';
const GET_BRAND_MOBILE_APP_THEME_FAILURE = 'theme/GET_BRAND_MOBILE_APP_THEME_FAILURE';

const UPDATE_MOBILE_APP_THEME_REQUESTED = 'theme/UPDATE_MOBILE_APP_THEME_REQUESTED';
const UPDATE_MOBILE_APP_THEME_SUCCESS = 'theme/UPDATE_MOBILE_APP_THEME_SUCCESS';
const UPDATE_MOBILE_APP_THEME_FAILURE = 'theme/UPDATE_MOBILE_APP_THEME_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  styleCssURL: null,
  serverAppTheme: null,
  brandCustomization: null,
  serverAppCustomization: null,
  kioskAppCustomization: null,
  tableTopCustomization: null,
  orderUpCustomization: null,
  kdsCustomization: null,
  kioskTheme: null,
  tableTopTheme: null,
  KDSTheme: null,
  orderUpTheme: null,
  mobileAppTheme: null,
  waitListTheme: null,
  waitListCustomization: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        styleCssURL: action.result,
      };
    }
    case GET_BRAND_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        styleCssURL: null,
      };
    }
    case GET_BRAND_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        brandCustomization: null,
      };
    }
    case GET_BRAND_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandCustomization: action.result,
      };
    }
    case GET_BRAND_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        brandCustomization: null,
      };
    }
    case POST_BRAND_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BRAND_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_BRAND_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case RESET_THEME: {
      return {
        ...state,
        styleCssURL: null,
      };
    }
    case GET_MOBILE_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        serverAppCustomization: null,
      };
    }
    case GET_MOBILE_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        serverAppCustomization: action.result,
      };
    }
    case GET_MOBILE_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        serverAppCustomization: null,
      };
    }
    case POST_MOBILE_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_MOBILE_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_MOBILE_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_SERVER_APP_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_SERVER_APP_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_SERVER_APP_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_KIOSK_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        kioskAppCustomization: null,
      };
    }
    case GET_BRAND_KIOSK_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kioskAppCustomization: action.result,
      };
    }
    case GET_BRAND_KIOSK_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        kioskAppCustomization: null,
      };
    }
    case POST_BRAND_KIOSK_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BRAND_KIOSK_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_BRAND_KIOSK_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TABLE_TOP_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        tableTopCustomization: null,
      };
    }
    case GET_TABLE_TOP_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tableTopCustomization: action.result,
      };
    }
    case GET_TABLE_TOP_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        tableTopCustomization: null,
      };
    }
    case POST_TABLE_TOP_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_TABLE_TOP_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_TABLE_TOP_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ORDER_UP_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        orderUpCustomization: null,
      };
    }
    case GET_ORDER_UP_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        orderUpCustomization: action.result,
      };
    }
    case GET_ORDER_UP_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        orderUpCustomization: null,
      };
    }
    case POST_ORDER_UP_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ORDER_UP_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ORDER_UP_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_KDS_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        kdsCustomization: null,
      };
    }
    case GET_BRAND_KDS_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsCustomization: action.result,
      };
    }
    case GET_BRAND_KDS_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        kdsCustomization: null,
      };
    }
    case POST_BRAND_KDS_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BRAND_KDS_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_BRAND_KDS_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_SERVER_APP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_SERVER_APP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        serverAppTheme: action.result,
      };
    }
    case GET_BRAND_SERVER_APP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        serverAppTheme: null,
      };
    }
    case GET_BRAND_KIOSK_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_KIOSK_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kioskTheme: action.result,
      };
    }
    case GET_BRAND_KIOSK_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        kioskTheme: null,
      };
    }
    case GET_BRAND_TABLE_TOP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_TABLE_TOP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tableTopTheme: action.result,
      };
    }
    case GET_BRAND_TABLE_TOP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        tableTopTheme: null,
      };
    }
    case POST_KIOSK_THEME_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_KIOSK_THEME_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_KIOSK_THEME_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_TABLE_TOP_THEME_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_TABLE_TOP_THEME_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_TABLE_TOP_THEME_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_KDS_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_KDS_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        KDSTheme: action.result,
      };
    }
    case GET_BRAND_KDS_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        KDSTheme: null,
      };
    }
    case UPDATE_KDS_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_KDS_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_KDS_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_ORDER_UP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_ORDER_UP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        orderUpTheme: action.result,
      };
    }
    case GET_BRAND_ORDER_UP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        orderUpTheme: null,
      };
    }
    case UPDATE_ORDER_UP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ORDER_UP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ORDER_UP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        waitListCustomization: null,
      };
    }
    case GET_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        waitListCustomization: action.result,
      };
    }
    case GET_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        waitListCustomization: null,
      };
    }
    case POST_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_WAITLIST_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_WAITLIST_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        waitListTheme: action.result,
      };
    }
    case GET_BRAND_WAITLIST_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        waitListTheme: null,
      };
    }
    case UPDATE_WAITLIST_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_WAITLIST_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_WAITLIST_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_MOBILE_APP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_MOBILE_APP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppTheme: action.result,
      };
    }
    case GET_BRAND_MOBILE_APP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        mobileAppTheme: null,
      };
    }
    case UPDATE_MOBILE_APP_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MOBILE_APP_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MOBILE_APP_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: return state;
  }
};

export const getBrandThemeCssURL = (brandId) => {
  return {
    types: [GET_BRAND_THEME_REQUESTED, GET_BRAND_THEME_SUCCESS, GET_BRAND_THEME_FAILURE],
    promise: client => client.get(`getBrandTheme/${brandId}`)
  };
};

export const resetThemeURL = () => {
  return {
    type: RESET_THEME
  };
};

export const getBrandCustomization = (brandId, languageId) => {
  return {
    types: [GET_BRAND_CUSTOMIZE_REQUESTED, GET_BRAND_CUSTOMIZE_SUCCESS, GET_BRAND_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandCustomize/${brandId}/languageId/${languageId}`)
  };
};

export const updateBrandTheme = (data) => {
  return {
    types: [POST_BRAND_CUSTOMIZE_REQUESTED, POST_BRAND_CUSTOMIZE_SUCCESS, POST_BRAND_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveBrandTheme', { data })
  };
};

export const saveBrandAppCustomize = (data) => {
  return {
    types: [POST_BRAND_CUSTOMIZE_REQUESTED, POST_BRAND_CUSTOMIZE_SUCCESS, POST_BRAND_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveBrandAppCustomize', { data })
  };
};

export const getBrandThemeCss = (brandId) => {
  return {
    types: [GET_BRAND_THEME_REQUESTED, GET_BRAND_THEME_SUCCESS, GET_BRAND_THEME_FAILURE],
    promise: client => client.get(`getBrandTheme/${brandId}`)
  };
};

export const getBrandMobileCustomization = (brandId, languageId) => {
  return {
    types: [GET_MOBILE_CUSTOMIZE_REQUESTED, GET_MOBILE_CUSTOMIZE_SUCCESS, GET_MOBILE_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandServerAppCustomize/${brandId}/languageId/${languageId}`)
  };
};

export const updateBrandMobileTheme = (data) => {
  return {
    types: [POST_MOBILE_CUSTOMIZE_REQUESTED, POST_MOBILE_CUSTOMIZE_SUCCESS, POST_MOBILE_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveBrandServerAppTheme', { data })
  };
};

export const saveServerAppCustomize = (data) => {
  return {
    types: [POST_SERVER_APP_CUSTOMIZE_REQUESTED, POST_SERVER_APP_CUSTOMIZE_SUCCESS, POST_SERVER_APP_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveServerAppCustomize', { data })
  };
};

export const getBrandKioskCustomization = (brandId, languageId) => {
  return {
    types: [GET_BRAND_KIOSK_CUSTOMIZE_REQUESTED, GET_BRAND_KIOSK_CUSTOMIZE_SUCCESS, GET_BRAND_KIOSK_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandKioskCustomize/${brandId}/languageId/${languageId}`)
  };
};
export const saveBrandKioskCustomize = (data) => {
  return {
    types: [POST_BRAND_KIOSK_CUSTOMIZE_REQUESTED, POST_BRAND_KIOSK_CUSTOMIZE_SUCCESS, POST_BRAND_KIOSK_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveKioskCustomize', { data })
  };
};

export const getBrandTableTopCustomization = (brandId, languageId) => {
  return {
    types: [GET_TABLE_TOP_CUSTOMIZE_REQUESTED, GET_TABLE_TOP_CUSTOMIZE_SUCCESS, GET_TABLE_TOP_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandTableTopCustomize/${brandId}/languageId/${languageId}`)
  };
};
export const saveBrandTableTopCustomize = (data) => {
  return {
    types: [POST_TABLE_TOP_CUSTOMIZE_REQUESTED, POST_TABLE_TOP_CUSTOMIZE_SUCCESS, POST_TABLE_TOP_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveTableTopCustomize', { data })
  };
};

export const getBrandOrderUpCustomization = (brandId, languageId) => {
  return {
    types: [GET_ORDER_UP_CUSTOMIZE_REQUESTED, GET_ORDER_UP_CUSTOMIZE_SUCCESS, GET_ORDER_UP_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandOrderUpCustomize/${brandId}/languageId/${languageId}`)
  };
};
export const saveBrandOrderUpCustomize = (data) => {
  return {
    types: [POST_ORDER_UP_CUSTOMIZE_REQUESTED, POST_ORDER_UP_CUSTOMIZE_SUCCESS, POST_ORDER_UP_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveOrderUpCustomize', { data })
  };
};

export const getBrandKdsCustomization = (brandId, languageId) => {
  return {
    types: [GET_BRAND_KDS_CUSTOMIZE_REQUESTED, GET_BRAND_KDS_CUSTOMIZE_SUCCESS, GET_BRAND_KDS_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandKdsCustomize/${brandId}/languageId/${languageId}`)
  };
};
export const saveBrandKdsCustomize = (data) => {
  return {
    types: [POST_BRAND_KDS_CUSTOMIZE_REQUESTED, POST_BRAND_KDS_CUSTOMIZE_SUCCESS, POST_BRAND_KDS_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveKdsCustomize', { data })
  };
};

export const getBrandServerAppTheme = (brandId) => {
  return {
    types: [GET_BRAND_SERVER_APP_THEME_REQUESTED, GET_BRAND_SERVER_APP_THEME_SUCCESS, GET_BRAND_SERVER_APP_THEME_FAILURE],
    promise: client => client.get(`getBrandServerAppTheme/${brandId}`)
  };
};

export const getBrandKioskTheme = (brandId) => {
  return {
    types: [GET_BRAND_KIOSK_THEME_REQUESTED, GET_BRAND_KIOSK_THEME_SUCCESS, GET_BRAND_KIOSK_THEME_FAILURE],
    promise: client => client.get(`getBrandKioskTheme/${brandId}`)
  };
};

export const getBrandTableTheme = (brandId) => {
  return {
    types: [GET_BRAND_TABLE_TOP_THEME_REQUESTED, GET_BRAND_TABLE_TOP_THEME_SUCCESS, GET_BRAND_TABLE_TOP_THEME_FAILURE],
    promise: client => client.get(`getBrandTableTopTheme/${brandId}`)
  };
};

export const updateBrandKioskTheme = (data) => {
  return {
    types: [POST_KIOSK_THEME_UPDATE_REQUESTED, POST_KIOSK_THEME_UPDATE_SUCCESS, POST_KIOSK_THEME_UPDATE_FAILURE],
    promise: client => client.post('updateBrandKioskTheme', { data })
  };
};

export const updateBrandTableTopTheme = (data) => {
  return {
    types: [POST_TABLE_TOP_THEME_UPDATE_REQUESTED, POST_TABLE_TOP_THEME_UPDATE_SUCCESS, POST_TABLE_TOP_THEME_UPDATE_FAILURE],
    promise: client => client.post('updateBrandTableTopTheme', { data })
  };
};

export const getBrandKDSTheme = (brandId) => {
  return {
    types: [GET_BRAND_KDS_THEME_REQUESTED, GET_BRAND_KDS_THEME_SUCCESS, GET_BRAND_KDS_THEME_FAILURE],
    promise: client => client.get(`getBrandKDSTheme/${brandId}`)
  };
};

export const updateBrandKDSTheme = (data) => {
  return {
    types: [UPDATE_KDS_THEME_REQUESTED, UPDATE_KDS_THEME_SUCCESS, UPDATE_KDS_THEME_FAILURE],
    promise: client => client.post('updateBrandKDSTheme', { data })
  };
};
export const getBrandOrderUpTheme = (brandId) => {
  return {
    types: [GET_BRAND_ORDER_UP_THEME_REQUESTED, GET_BRAND_ORDER_UP_THEME_SUCCESS, GET_BRAND_ORDER_UP_THEME_FAILURE],
    promise: client => client.get(`getBrandOrderUpTheme/${brandId}`)
  };
};

export const updateBrandOrderUpTheme = (data) => {
  return {
    types: [UPDATE_ORDER_UP_THEME_REQUESTED, UPDATE_ORDER_UP_THEME_SUCCESS, UPDATE_ORDER_UP_THEME_FAILURE],
    promise: client => client.post('updateBrandOrderUpTheme', { data })
  };
};

export const getBrandWaitListCustomization = (brandId, languageId) => {
  return {
    types: [GET_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED, GET_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS, GET_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE],
    promise: client => client.get(`getBrandWaitListCustomize/${brandId}/languageId/${languageId}`)
  };
};
export const saveBrandWaitListCustomize = (data) => {
  return {
    types: [POST_BRAND_WAIT_LIST_CUSTOMIZE_REQUESTED, POST_BRAND_WAIT_LIST_CUSTOMIZE_SUCCESS, POST_BRAND_WAIT_LIST_CUSTOMIZE_FAILURE],
    promise: client => client.post('saveWaitListCustomize', { data })
  };
};

export const getBrandWaitListTheme = (brandId) => {
  return {
    types: [GET_BRAND_WAITLIST_THEME_REQUESTED, GET_BRAND_WAITLIST_THEME_SUCCESS, GET_BRAND_WAITLIST_THEME_FAILURE],
    promise: client => client.get(`getBrandWaitListTheme/${brandId}`)
  };
};

export const updateBrandWaitListTheme = (data) => {
  return {
    types: [UPDATE_WAITLIST_THEME_REQUESTED, UPDATE_WAITLIST_THEME_SUCCESS, UPDATE_WAITLIST_THEME_FAILURE],
    promise: client => client.post('updateBrandWaitListTheme', { data })
  };
};

export const getBrandMobileAppTheme = (brandId) => {
  return {
    types: [GET_BRAND_MOBILE_APP_THEME_REQUESTED, GET_BRAND_MOBILE_APP_THEME_SUCCESS, GET_BRAND_MOBILE_APP_THEME_FAILURE],
    promise: client => client.get(`getBrandMobileAppTheme/${brandId}`)
  };
};

export const updateBrandMobileAppTheme = (data) => {
  return {
    types: [UPDATE_MOBILE_APP_THEME_REQUESTED, UPDATE_MOBILE_APP_THEME_SUCCESS, UPDATE_MOBILE_APP_THEME_FAILURE],
    promise: client => client.post('updateBrandMobileAppTheme', { data })
  };
};
