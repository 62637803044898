const currentReducer = 'sensorGroup';
const GET_SENSOR_GROUP_BY_BRAND_ID_REQUESTED = `${currentReducer}/GET_SENSOR_GROUP_BY_BRAND_ID_REQUESTED`;
const GET_SENSOR_GROUP_BY_BRAND_ID_SUCCESS = `${currentReducer}/GET_SENSOR_GROUP_BY_BRAND_ID_SUCCESS`;
const GET_SENSOR_GROUP_BY_BRAND_ID_FAILURE = `${currentReducer}/GET_SENSOR_GROUP_BY_BRAND_ID_FAILURE`;

const ADD_SENSOR_GROUP_REQUESTED = 'sensorGroup/ADD_SENSOR_GROUP_REQUESTED';
const ADD_SENSOR_GROUP_SUCCESS = 'sensorGroup/ADD_SENSOR_GROUP_SUCCESS';
const ADD_SENSOR_GROUP_FAILURE = 'sensorGroup/ADD_SENSOR_GROUP_FAILURE';

const UPDATE_SENSOR_GROUP_REQUESTED = 'sensorGroup/UPDATE_SENSOR_GROUP_REQUESTED';
const UPDATE_SENSOR_GROUP_SUCCESS = 'sensorGroup/UPDATE_SENSOR_GROUP_SUCCESS';
const UPDATE_SENSOR_GROUP_FAILURE = 'sensorGroup/UPDATE_SENSOR_GROUP_FAILURE';

const DELETE_SENSOR_GROUP_REQUESTED = 'sensorGroup/DELETE_SENSOR_GROUP_REQUESTED';
const DELETE_SENSOR_GROUP_SUCCESS = 'sensorGroup/DELETE_SENSOR_GROUP_SUCCESS';
const DELETE_SENSOR_GROUP_FAILURE = 'sensorGroup/DELETE_SENSOR_GROUP_FAILURE';

const ASSIGN_SENSOR_GROUP_REQUESTED = 'sensorGroup/ASSIGN_SENSOR_GROUP_REQUESTED';
const ASSIGN_SENSOR_GROUP_SUCCESS = 'sensorGroup/ASSIGN_SENSOR_GROUP_SUCCESS';
const ASSIGN_SENSOR_GROUP_FAILURE = 'sensorGroup/ASSIGN_SENSOR_GROUP_FAILURE';

const GET_SENSOR_GROUP_BY_ID_REQUESTED = `${currentReducer}/GET_SENSOR_GROUP_BY_ID_REQUESTED`;
const GET_SENSOR_GROUP_BY_ID_SUCCESS = `${currentReducer}/GET_SENSOR_GROUP_BY_ID_SUCCESS`;
const GET_SENSOR_GROUP_BY_ID_FAILURE = `${currentReducer}/GET_SENSOR_GROUP_BY_ID_FAILURE`;

const GET_SENSOR_GROUP_BY_ROLE_ID_REQUESTED = `${currentReducer}/GET_SENSOR_GROUP_BY_ROLE_ID_REQUESTED`;
const GET_SENSOR_GROUP_BY_ROLE_ID_SUCCESS = `${currentReducer}/GET_SENSOR_GROUP_BY_ROLE_ID_SUCCESS`;
const GET_SENSOR_GROUP_BY_ROLE_ID_FAILURE = `${currentReducer}/GET_SENSOR_GROUP_BY_ROLE_ID_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sensorGroupListByBrandId: [],
  sensorGroupById: [],
  sensorGroupListByRoleId: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SENSOR_GROUP_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SENSOR_GROUP_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupListByBrandId: action.result
      };
    }
    case GET_SENSOR_GROUP_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupListByBrandId: []
      };
    }
    case ADD_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ASSIGN_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ASSIGN_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ASSIGN_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_SENSOR_GROUP_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        sensorGroupById: [],
      };
    }
    case GET_SENSOR_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupById: action.result
      };
    }
    case GET_SENSOR_GROUP_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupById: []
      };
    }
    case GET_SENSOR_GROUP_BY_ROLE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SENSOR_GROUP_BY_ROLE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupListByRoleId: action.result
      };
    }
    case GET_SENSOR_GROUP_BY_ROLE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupListByRoleId: []
      };
    }
    default:
      return state;
  }
};

export const getSensorGroupByBrandId = (storeId) => {
  return {
    types: [GET_SENSOR_GROUP_BY_BRAND_ID_REQUESTED, GET_SENSOR_GROUP_BY_BRAND_ID_SUCCESS, GET_SENSOR_GROUP_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getSensorGroupByBrandId/${storeId}`)
  };
};

export const addSensorGroup = (data) => {
  return {
    types: [ADD_SENSOR_GROUP_REQUESTED, ADD_SENSOR_GROUP_SUCCESS, ADD_SENSOR_GROUP_FAILURE],
    promise: client => client.post('addSensorGroup', { data })
  };
};

export const updateSensorGroup = (data) => {
  return {
    types: [UPDATE_SENSOR_GROUP_REQUESTED, UPDATE_SENSOR_GROUP_SUCCESS, UPDATE_SENSOR_GROUP_FAILURE],
    promise: client => client.post('updateSensorGroup', { data })
  };
};

export const deleteSensorGroup = (sensorGroupId) => {
  return {
    types: [DELETE_SENSOR_GROUP_REQUESTED, DELETE_SENSOR_GROUP_SUCCESS, DELETE_SENSOR_GROUP_FAILURE],
    promise: client => client.post(`deleteSensorGroup/${sensorGroupId}`)
  };
};

export const assignSensorGroup = (sensorId, sensorGroupId) => {
  return {
    types: [ASSIGN_SENSOR_GROUP_REQUESTED, ASSIGN_SENSOR_GROUP_SUCCESS, ASSIGN_SENSOR_GROUP_FAILURE],
    promise: client => client.post(`assignSensorGroup/${sensorId}/assignSensorGroup/sensorGroupId/${sensorGroupId}`)
  };
};

export const unassignSensorGroup = (sensorId) => {
  return {
    types: [ASSIGN_SENSOR_GROUP_REQUESTED, ASSIGN_SENSOR_GROUP_SUCCESS, ASSIGN_SENSOR_GROUP_FAILURE],
    promise: client => client.post(`assignSensorGroup/${sensorId}/unAssignSensorGroup`)
  };
};

export const getSensorGroupById = (sensorGroupById) => {
  return {
    types: [GET_SENSOR_GROUP_BY_ID_REQUESTED, GET_SENSOR_GROUP_BY_ID_SUCCESS, GET_SENSOR_GROUP_BY_ID_FAILURE],
    promise: client => client.get(`getSensorGroupById/${sensorGroupById}`)
  };
};

export const getSensorGroupByRoleId = (storeId) => {
  return {
    types: [GET_SENSOR_GROUP_BY_ROLE_ID_REQUESTED, GET_SENSOR_GROUP_BY_ROLE_ID_SUCCESS, GET_SENSOR_GROUP_BY_ROLE_ID_FAILURE],
    promise: client => client.get(`getSensorGroupByRoleId/${storeId}`)
  };
};
