export const GET_POS_SYSTEM_MENU_REQUESTED = 'brand/GET_POS_SYSTEM_MENU_REQUESTED';
export const GET_POS_SYSTEM_MENU_SUCCESS = 'brand/GET_POS_SYSTEM_MENU_SUCCESS';
export const GET_POS_SYSTEM_MENU_FAILURE = 'brand/GET_POS_SYSTEM_MENU_FAILURE';

export const UPDATE_POS_SYSTEM_MENU_REQUESTED = 'brand/UPDATE_POS_SYSTEM_MENU_REQUESTED';
export const UPDATE_POS_SYSTEM_MENU_SUCCESS = 'brand/UPDATE_POS_SYSTEM_MENU_SUCCESS';
export const UPDATE_POS_SYSTEM_MENU_FAILURE = 'brand/UPDATE_POS_SYSTEM_MENU_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  posMenuSystemList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_POS_SYSTEM_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        posMenuSystemList: null,
      };
    }
    case GET_POS_SYSTEM_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posMenuSystemList: action.result,
      };
    }
    case GET_POS_SYSTEM_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_POS_SYSTEM_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_POS_SYSTEM_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_POS_SYSTEM_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPOSMenu = (brandId) => {
  return {
    types: [GET_POS_SYSTEM_MENU_REQUESTED, GET_POS_SYSTEM_MENU_SUCCESS, GET_POS_SYSTEM_MENU_FAILURE],
    promise: client => client.get(`getPOSMenuSystem/${brandId}`)
  };
};

export const updatePOSMenu = (brandId, data) => {
  return {
    types: [UPDATE_POS_SYSTEM_MENU_REQUESTED, UPDATE_POS_SYSTEM_MENU_SUCCESS, UPDATE_POS_SYSTEM_MENU_FAILURE],
    promise: client => client.post(`updatePOSMenuSystem/${brandId}`, { data })
  };
};
