export const GET_POS_SYSTEM_REQUESTED = 'store/GET_POS_SYSTEM_REQUESTED';
export const GET_POS_SYSTEM_SUCCESS = 'store/GET_POS_SYSTEM_SUCCESS';
export const GET_POS_SYSTEM_FAILURE = 'store/GET_POS_SYSTEM_FAILURE';

export const GET_STORE_POS_SYSTEM_CONFIG_REQUESTED = 'store/GET_STORE_POS_SYSTEM_CONFIG_REQUESTED';
export const GET_STORE_POS_SYSTEM_CONFIG_SUCCESS = 'store/GET_STORE_POS_SYSTEM_CONFIG_SUCCESS';
export const GET_STORE_POS_SYSTEM_CONFIG_FAILURE = 'store/GET_STORE_POS_SYSTEM_CONFIG_FAILURE';

export const UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED = 'store/UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED';
export const UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS = 'store/UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS';
export const UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE = 'store/UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE';

export const GET_POS_INTEGRATION_TYPE_REQUESTED = 'store/GET_POS_INTEGRATION_TYPE_REQUESTED';
export const GET_POS_INTEGRATION_TYPE_SUCCESS = 'store/GET_POS_INTEGRATION_TYPE_SUCCESS';
export const GET_POS_INTEGRATION_TYPE_FAILURE = 'store/GET_POS_INTEGRATION_TYPE_FAILURE';

export const GET_POS_SUBTYPES_REQUESTED = 'store/GET_POS_SUBTYPES_REQUESTED';
export const GET_POS_SUBTYPES_SUCCESS = 'store/GET_POS_SUBTYPES_SUCCESS';
export const GET_POS_SUBTYPES_FAILURE = 'store/GET_POS_SUBTYPES_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  posSytem: [],
  storePosSystem: [],
  posIntegrationVendor: [],
  posSubtypes: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_POS_SYSTEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POS_SYSTEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posSytem: action.result,
      };
    }
    case GET_POS_SYSTEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_POS_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_POS_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storePosSystem: action.result,
      };
    }
    case GET_STORE_POS_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storePosSystem: action.result,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POS_INTEGRATION_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POS_INTEGRATION_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posIntegrationVendor: action.result,
      };
    }
    case GET_POS_INTEGRATION_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POS_SUBTYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POS_SUBTYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posSubtypes: action.result,
      };
    }
    case GET_POS_SUBTYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPosSytems = () => {
  return {
    types: [GET_POS_SYSTEM_REQUESTED, GET_POS_SYSTEM_SUCCESS, GET_POS_SYSTEM_FAILURE],
    promise: client => client.get('getPosSytems')
  };
};

export const getStorePosSystemConfig = (storeId) => {
  return {
    types: [GET_STORE_POS_SYSTEM_CONFIG_REQUESTED, GET_STORE_POS_SYSTEM_CONFIG_SUCCESS, GET_STORE_POS_SYSTEM_CONFIG_FAILURE],
    promise: client => client.get(`getStorePosSystemConfig/${storeId}`)
  };
};

export const updateStorePosSystemConfig = (storeId, data) => {
  return {
    types: [UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED, UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS, UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE],
    promise: client => client.post(`updateStorePosSystemConfig/${storeId}`, { data })
  };
};

export const getPosIntegrations = () => {
  return {
    types: [GET_POS_INTEGRATION_TYPE_REQUESTED, GET_POS_INTEGRATION_TYPE_SUCCESS, GET_POS_INTEGRATION_TYPE_FAILURE],
    promise: client => client.get('getPosIntegrations')
  };
};

export const getPosSubTypes = () => {
  return {
    types: [GET_POS_SUBTYPES_REQUESTED, GET_POS_SUBTYPES_SUCCESS, GET_POS_SUBTYPES_FAILURE],
    promise: client => client.get('getPosSubTypes')
  };
};
