export const POST_ADD_MENU_SENSOR_GROUP_REQUESTED = 'menuSensorGroupSetUp/POST_ADD_MENU_SENSOR_GROUP_REQUESTED';
export const POST_ADD_MENU_SENSOR_GROUP_SUCCESS = 'menuSensorGroupSetUp/POST_ADD_MENU_SENSOR_GROUP_SUCCESS';
export const POST_ADD_MENU_SENSOR_GROUP_FAILURE = 'menuSensorGroupSetUp/POST_ADD_MENU_SENSOR_GROUP_FAILURE';

export const GET_SENSOR_GROUPS_REQUESTED = 'menuSensorGroupSetUp/GET_SENSOR_GROUPS_REQUESTED';
export const GET_SENSOR_GROUPS_SUCCESS = 'menuSensorGroupSetUp/GET_SENSOR_GROUPS_SUCCESS';
export const GET_SENSOR_GROUPS_FAILURE = 'menuSensorGroupSetUp/GET_SENSOR_GROUPS_FAILURE';

export const POST_DELETE_SENSOR_GROUPS_REQUESTED = 'menuSensorGroupSetUp/POST_DELETE_SENSOR_GROUPS_REQUESTED';
export const POST_DELETE_SENSOR_GROUPS_SUCCESS = 'menuSensorGroupSetUp/POST_DELETE_SENSOR_GROUPS_SUCCESS';
export const POST_DELETE_SENSOR_GROUPS_FAILURE = 'menuSensorGroupSetUp/POST_DELETE_SENSOR_GROUPS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sensorGroupList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_MENU_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ADD_MENU_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ADD_MENU_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SENSOR_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SENSOR_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupList: action.result,
      };
    }
    case GET_SENSOR_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupList: [],
      };
    }
    case POST_DELETE_SENSOR_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_DELETE_SENSOR_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_DELETE_SENSOR_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addMenuSensorGroup = (data) => {
  return {
    types: [POST_ADD_MENU_SENSOR_GROUP_REQUESTED, POST_ADD_MENU_SENSOR_GROUP_SUCCESS, POST_ADD_MENU_SENSOR_GROUP_FAILURE],
    promise: client => client.post('addMenuSensorGroup', { data })
  };
};

export const getMenuSensorGroup = (menuId) => {
  return {
    types: [GET_SENSOR_GROUPS_REQUESTED, GET_SENSOR_GROUPS_SUCCESS, GET_SENSOR_GROUPS_FAILURE],
    promise: client => client.get(`getMenuSensorGroup/${menuId}`)
  };
};

export const deleteMenuSensorGroup = (data) => {
  return {
    types: [POST_DELETE_SENSOR_GROUPS_REQUESTED, POST_DELETE_SENSOR_GROUPS_SUCCESS, POST_DELETE_SENSOR_GROUPS_FAILURE],
    promise: client => client.post('deleteMenuSensorGroup', { data })
  };
};
