import {
  setBusinessNode,
  getBusinessNode,
  setBusinessType as setBusinessTypeStore,
  getBusinessType
} from '@utility/storageOperations';

const currentReducer = 'OPERATIONS';
const UPDATE_BUSINESS_NODE = `${currentReducer}/UPDATE_BUSINESS_NODE`;
const SET_BUSINESS_TYPE = `${currentReducer}/SET_BUSINESS_TYPE`;

const initialState = {
  businessType: getBusinessType(),
  business: {
    ...getBusinessNode()
  },
};

export default(state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BUSINESS_NODE: {
      setBusinessNode(action.payload);
      return {
        ...state,
        business: {
          ...action.payload
        }
      };
    }
    case SET_BUSINESS_TYPE: {
      setBusinessTypeStore(action.payload);
      return {
        ...state,
        businessType: action.payload
      };
    }
    default:
      return state;
  }
};

export const updateBusinessNode = (data) => {
  return {
    type: UPDATE_BUSINESS_NODE,
    payload: data
  };
};

export const setBusinessType = (type) => {
  return {
    type: SET_BUSINESS_TYPE,
    payload: type
  };
};
