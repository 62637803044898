const currentReducer = 'menuLocationSetup';
export const ITEMS_REQUESTED = `${currentReducer}/ITEMS_REQUESTED`;
export const ITEMS_SUCCESS = `${currentReducer}/ITEMS_SUCCESS`;
export const ITEMS_FAILURE = `${currentReducer}/ITEMS_FAILURE`;

export const UPDATE_REQUESTED = `${currentReducer}/UPDATE_REQUESTED`;
export const UPDATE_SUCCESS = `${currentReducer}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${currentReducer}/UPDATE_FAILURE`;

export const UPDATE_MENU_ITEM_ENTERPRISE_DATA = `${currentReducer}/UPDATE_MENU_ITEM_ENTERPRISE_DATA`;
export const UPDATE_MODIFERS_ENTERPRISE_DATA = `${currentReducer}/UPDATE_MODIFERS_ENTERPRISE_DATA`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  itemList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemList: action.result
      };
    }
    case ITEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemList: []
      };
    }
    case UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MENU_ITEM_ENTERPRISE_DATA:
    case UPDATE_MODIFERS_ENTERPRISE_DATA: {
      return {
        ...state,
        itemList: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getEnterpriseItems = (menuId, menuType) => {
  let url = '';
  if (menuType === 'menu') {
    url = 'getEnterpriseMenuItemsByMenuItemId';
  } else {
    url = 'getEnterpriseModifiersByModifierId';
  }
  return {
    types: [ITEMS_REQUESTED, ITEMS_SUCCESS, ITEMS_FAILURE],
    promise: client => client.get(`${url}/${menuId}`)
  };
};

export const updateEnterpriseItems = (data, menuType) => {
  let url = '';
  if (menuType === 'menu') {
    url = 'updateEnterpriseMenuItemsByMenuItemId';
  } else {
    url = 'updateEnterpriseModifiersByModifierId';
  }
  return {
    types: [UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: client => client.post(url, { data })
  };
};

export const updateMenuItemEnterpriseList = (data) => {
  return {
    type: UPDATE_MENU_ITEM_ENTERPRISE_DATA,
    payload: data,
  };
};

export const updateModifierEnterpriseList = (data) => {
  return {
    type: UPDATE_MODIFERS_ENTERPRISE_DATA,
    payload: data,
  };
};
