export const GET_MODFUNCTION_BY_BRAND_REQUESTED = 'modifierModifierGroup/GET_MODFUNCTION_BY_BRAND_REQUESTED';
export const GET_MODFUNCTION_BY_BRAND_SUCCESS = 'modifierModifierGroup/GET_MODFUNCTION_BY_BRAND_SUCCESS';
export const GET_MODFUNCTION_BY_BRAND_FAILURE = 'modifierModifierGroup/GET_MODFUNCTION_BY_BRAND_FAILURE';

export const ADD_MENUITEM_MODIFIER_GROUP_REQUESTED = 'modifierModifierGroup/ADD_MENUITEM_MODIFIER_GROUP_REQUESTED';
export const ADD_MENUITEM_MODIFIER_GROUP_SUCCESS = 'modifierModifierGroup/ADD_MENUITEM_MODIFIER_GROUP_SUCCESS';
export const ADD_MENUITEM_MODIFIER_GROUP_FAILURE = 'modifierModifierGroup/ADD_MENUITEM_MODIFIER_GROUP_FAILURE';

export const UPDATE_MENUITEM_MODIFIER_GROUP_REQUESTED = 'modifierModifierGroup/UPDATE_MENUITEM_MODIFIER_GROUP_REQUESTED';
export const UPDATE_MENUITEM_MODIFIER_GROUP_SUCCESS = 'modifierModifierGroup/UPDATE_MENUITEM_MODIFIER_GROUP_SUCCESS';
export const UPDATE_MENUITEM_MODIFIER_GROUP_FAILURE = 'modifierModifierGroup/UPDATE_MENUITEM_MODIFIER_GROUP_FAILURE';

export const UPDATE_MODIFIER_REQUESTED = 'modifierModifierGroup/UPDATE_MODIFIER_REQUESTED';
export const UPDATE_MODIFIER_SUCCESS = 'modifierModifierGroup/UPDATE_MODIFIER_SUCCESS';
export const UPDATE_MODIFIER_FAILURE = 'modifierModifierGroup/UPDATE_MODIFIER_FAILURE';

export const ADD_MODGROUP_TO_MENUITEM_REQUESTED = 'modifierModifierGroup/ADD_MODGROUP_TO_MENUITEM_REQUESTED';
export const ADD_MODGROUP_TO_MENUITEM_SUCCESS = 'modifierModifierGroup/ADD_MODGROUP_TO_MENUITEM_SUCCESS';
export const ADD_MODGROUP_TO_MENUITEM_FAILURE = 'modifierModifierGroup/ADD_MODGROUP_TO_MENUITEM_FAILURE';

export const REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED';
export const REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS';
export const REMOVE_MODGROUP_FROM_MENUITEM_FAILURE = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MENUITEM_FAILURE';

export const ADD_MODIFIER_TO_MODGROUP_REQUESTED = 'modifierModifierGroup/ADD_MODIFIER_TO_MODGROUP_REQUESTED';
export const ADD_MODIFIER_TO_MODGROUP_SUCCESS = 'modifierModifierGroup/ADD_MODIFIER_TO_MODGROUP_SUCCESS';
export const ADD_MODIFIER_TO_MODGROUP_FAILURE = 'modifierModifierGroup/ADD_MODIFIER_TO_MODGROUP_FAILURE';

export const REMOVE_MODIFIER_FROM_MODGROUP_REQUESTED = 'modifierModifierGroup/REMOVE_MODIFIER_FROM_MODGROUP_REQUESTED';
export const REMOVE_MODIFIER_FROM_MODGROUP_SUCCESS = 'modifierModifierGroup/REMOVE_MODIFIER_FROM_MODGROUP_SUCCESS';
export const REMOVE_MODIFIER_FROM_MODGROUP_FAILURE = 'modifierModifierGroup/REMOVE_MODIFIER_FROM_MODGROUP_FAILURE';

export const ADD_MODGROUP_TO_MODIFIER_REQUESTED = 'modifierModifierGroup/ADD_MODGROUP_TO_MODIFIER_REQUESTED';
export const ADD_MODGROUP_TO_MODIFIER_SUCCESS = 'modifierModifierGroup/ADD_MODGROUP_TO_MODIFIER_SUCCESS';
export const ADD_MODGROUP_TO_MODIFIER_FAILURE = 'modifierModifierGroup/ADD_MODGROUP_TO_MODIFIER_FAILURE';

export const REMOVE_MODGROUP_FROM_MODIFIER_REQUESTED = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MODIFIER_REQUESTED';
export const REMOVE_MODGROUP_FROM_MODIFIER_SUCCESS = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MODIFIER_SUCCESS';
export const REMOVE_MODGROUP_FROM_MODIFIER_FAILURE = 'modifierModifierGroup/REMOVE_MODGROUP_FROM_MODIFIER_FAILURE';

export const ADD_MODIFIER_TO_BRAND_REQUESTED = 'modifierModifierGroup/ADD_MODIFIER_TO_BRAND_REQUESTED';
export const ADD_MODIFIER_TO_BRAND_SUCCESS = 'modifierModifierGroup/ADD_MODIFIER_TO_BRAND_SUCCESS';
export const ADD_MODIFIER_TO_BRAND_FAILURE = 'modifierModifierGroup/ADD_MODIFIER_TO_BRAND_FAILURE';

export const CLONE_MOD_GROUP_REQUESTED = 'modifierModifierGroup/CLONE_MOD_GROUP_REQUESTED';
export const CLONE_MOD_GROUP_SUCCESS = 'modifierModifierGroup/CLONE_MOD_GROUP_SUCCESS';
export const CLONE_MOD_GROUP_FAILURE = 'modifierModifierGroup/CLONE_MOD_GROUP_FAILURE';

export const CLONE_MODIFIER_REQUESTED = 'modifierModifierGroup/CLONE_MODIFIER_REQUESTED';
export const CLONE_MODIFIER_SUCCESS = 'modifierModifierGroup/CLONE_MODIFIER_SUCCESS';
export const CLONE_MODIFIER_FAILURE = 'modifierModifierGroup/CLONE_MODIFIER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  modFunctionListByBrand: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MODFUNCTION_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODFUNCTION_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        modFunctionListByBrand: action.result,
      };
    }
    case GET_MODFUNCTION_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MENUITEM_MODIFIER_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENUITEM_MODIFIER_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENUITEM_MODIFIER_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MENUITEM_MODIFIER_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MENUITEM_MODIFIER_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MENUITEM_MODIFIER_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MODIFIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MODIFIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MODGROUP_TO_MENUITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MODGROUP_TO_MENUITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MODGROUP_TO_MENUITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REMOVE_MODGROUP_FROM_MENUITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MODIFIER_TO_MODGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MODIFIER_TO_MODGROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MODIFIER_TO_MODGROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REMOVE_MODIFIER_FROM_MODGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REMOVE_MODIFIER_FROM_MODGROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REMOVE_MODIFIER_FROM_MODGROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MODGROUP_TO_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MODGROUP_TO_MODIFIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MODGROUP_TO_MODIFIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REMOVE_MODGROUP_FROM_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REMOVE_MODGROUP_FROM_MODIFIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REMOVE_MODGROUP_FROM_MODIFIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MODIFIER_TO_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MODIFIER_TO_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MODIFIER_TO_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CLONE_MOD_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CLONE_MOD_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CLONE_MOD_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CLONE_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CLONE_MODIFIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CLONE_MODIFIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getModFunctionByBrandId = (brandId) => {
  return {
    types: [GET_MODFUNCTION_BY_BRAND_REQUESTED, GET_MODFUNCTION_BY_BRAND_SUCCESS, GET_MODFUNCTION_BY_BRAND_FAILURE],
    promise: client => client.get(`getModFunctionByBrandId/${brandId}`)
  };
};

export const addMenuItemModGroups = (data) => {
  return {
    types: [ADD_MENUITEM_MODIFIER_GROUP_REQUESTED, ADD_MENUITEM_MODIFIER_GROUP_SUCCESS, ADD_MENUITEM_MODIFIER_GROUP_FAILURE],
    promise: client => client.post('addMenuItemModifierGroup', { data })
  };
};

export const updateMenuItemModGroups = (data, level = 0, isBrandModGroup = false) => {
  let url = 'updateMenuItemModifierGroup';
  if (level > 1) {
    url = 'updateModifierModifierGroup';
  }
  if (isBrandModGroup) {
    url = 'updateBrandModifierGroup';
  }
  return {
    types: [UPDATE_MENUITEM_MODIFIER_GROUP_REQUESTED, UPDATE_MENUITEM_MODIFIER_GROUP_SUCCESS, UPDATE_MENUITEM_MODIFIER_GROUP_FAILURE],
    promise: client => client.post(url, { data })
  };
};

export const updateModifier = (data) => {
  return {
    types: [UPDATE_MODIFIER_REQUESTED, UPDATE_MODIFIER_SUCCESS, UPDATE_MODIFIER_FAILURE],
    promise: client => client.post('updateModifier', { data })
  };
};

export const addModGroupToMenuItem = (menuItemId, modGroupId) => {
  return {
    types: [ADD_MODGROUP_TO_MENUITEM_REQUESTED, ADD_MODGROUP_TO_MENUITEM_SUCCESS, ADD_MODGROUP_TO_MENUITEM_FAILURE],
    promise: client => client.post(`addModGroupToMenuItem/${menuItemId}/modifierGroupId/${modGroupId}`)
  };
};

export const removeModGroupFromMenu = (menuItemId, modGroupId) => {
  return {
    types: [REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED, REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS, REMOVE_MODGROUP_FROM_MENUITEM_FAILURE],
    promise: client => client.post(`removeModGroupFromMenuItem/${menuItemId}/modifierGroupId/${modGroupId}`)
  };
};

export const addModifierToModGroup = (modGroupId, modifierId) => {
  return {
    types: [ADD_MODGROUP_TO_MENUITEM_REQUESTED, ADD_MODGROUP_TO_MENUITEM_SUCCESS, ADD_MODGROUP_TO_MENUITEM_FAILURE],
    promise: client => client.post(`addModifierToModGroup/${modGroupId}/modifierId/${modifierId}`)
  };
};

export const removeModifierFromModGroup = (modGroupId, modifierId) => {
  return {
    types: [REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED, REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS, REMOVE_MODGROUP_FROM_MENUITEM_FAILURE],
    promise: client => client.post(`removeModifierFromModGroup/${modGroupId}/modifierId/${modifierId}`)
  };
};

export const addModGroupToModifier = (modifierId, modGroupId) => {
  return {
    types: [ADD_MODGROUP_TO_MENUITEM_REQUESTED, ADD_MODGROUP_TO_MENUITEM_SUCCESS, ADD_MODGROUP_TO_MENUITEM_FAILURE],
    promise: client => client.post(`addModGroupToModifier/${modifierId}/modifierGroupId/${modGroupId}`)
  };
};

export const removeModGroupFromModifier = (modifierId, modGroupId) => {
  return {
    types: [REMOVE_MODGROUP_FROM_MENUITEM_REQUESTED, REMOVE_MODGROUP_FROM_MENUITEM_SUCCESS, REMOVE_MODGROUP_FROM_MENUITEM_FAILURE],
    promise: client => client.post(`removeModGroupFromModifier/${modifierId}/modifierGroupId/${modGroupId}`)
  };
};

export const addModifer = (data) => {
  return {
    types: [ADD_MODIFIER_TO_BRAND_REQUESTED, ADD_MODIFIER_TO_BRAND_SUCCESS, ADD_MODIFIER_TO_BRAND_FAILURE],
    promise: client => client.post('addModifer', { data })
  };
};
export const cloneModGroup = (menuItemId, modifierGroupId) => {
  return {
    types: [CLONE_MOD_GROUP_REQUESTED, CLONE_MOD_GROUP_SUCCESS, CLONE_MOD_GROUP_FAILURE],
    promise: client => client.post(`cloneModGroup/${menuItemId}/modifierGroupId/${modifierGroupId}`)
  };
};
export const cloneModfier = (modifierId, modifierGroupId) => {
  return {
    types: [CLONE_MODIFIER_REQUESTED, CLONE_MODIFIER_SUCCESS, CLONE_MODIFIER_FAILURE],
    promise: client => client.post(`cloneModifier/${modifierId}/modifierGroupId/${modifierGroupId}`)
  };
};
