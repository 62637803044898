export const GET_STATION_BYSTORE_ID_REQUESTED = 'tables/GET_STATION_BYSTORE_ID_REQUESTED';
export const GET_STATION_BYSTORE_ID_SUCCESS = 'tables/GET_STATION_BYSTORE_ID_SUCCESS';
export const GET_STATION_BYSTORE_ID_FAILURE = 'tables/GET_STATION_BYSTORE_ID_FAILURE';

export const UPDATE_STATION_SEATS_REQUESTED = 'tables/UPDATE_STATION_SEATS_REQUESTED';
export const UPDATE_STATION_SEATS_SUCCESS = 'tables/UPDATE_STATION_SEATS_SUCCESS';
export const UPDATE_STATION_SEATS_FAILURE = 'tables/UPDATE_STATION_SEATS_FAILURE';

export const GET_STATION_TYPES_REQUESTED = 'tables/GET_STATION_TYPES_REQUESTED';
export const GET_STATION_TYPES_SUCCESS = 'tables/GET_STATION_TYPES_SUCCESS';
export const GET_STATION_TYPES_FAILURE = 'tables/GET_STATION_TYPES_FAILURE';

export const UPDATE_STATION_PRINTERS_REQUESTED = 'tables/UPDATE_STATION_PRINTERS_REQUESTED';
export const UPDATE_STATION_PRINTERS_SUCCESS = 'tables/UPDATE_STATION_PRINTERS_SUCCESS';
export const UPDATE_STATION_PRINTERS_FAILURE = 'tables/UPDATE_STATION_PRINTERS_FAILURE';

export const UPDATE_STATION_AVAILABILITY_REQUESTED = 'tables/UPDATE_STATION_AVAILABILITY_REQUESTED';
export const UPDATE_STATION_AVAILABILITY_SUCCESS = 'tables/UPDATE_STATION_AVAILABILITY_SUCCESS';
export const UPDATE_STATION_AVAILABILITY_FAILURE = 'tables/UPDATE_STATION_AVAILABILITY_FAILURE';

export const GET_PRINTER_BYSTORE_ID_REQUESTED = 'tables/GET_PRINTER_BYSTORE_ID_REQUESTED';
export const GET_PRINTER_BYSTORE_ID_SUCCESS = 'tables/GET_PRINTER_BYSTORE_ID_SUCCESS';
export const GET_PRINTER_BYSTORE_ID_FAILURE = 'tables/GET_PRINTER_BYSTORE_ID_FAILURE';

export const GET_STATION_GROUPS_BY_STORE_ID_REQUESTED = 'tables/GET_STATION_GROUPS_BY_STORE_ID_REQUESTED';
export const GET_STATION_GROUPS_BY_STORE_ID_SUCCESS = 'tables/GET_STATION_GROUPS_BY_STORE_ID_SUCCESS';
export const GET_STATION_GROUPS_BY_STORE_ID_FAILURE = 'tables/GET_STATION_GROUPS_BY_STORE_ID_FAILURE';

export const UPDATE_STATION_GROUPS_REQUESTED = 'tables/UPDATE_STATION_GROUPS_REQUESTED';
export const UPDATE_STATION_GROUPS_SUCCESS = 'tables/UPDATE_STATION_GROUPS_SUCCESS';
export const UPDATE_STATION_GROUPS_FAILURE = 'tables/UPDATE_STATION_GROUPS_FAILURE';

export const QUICK_ADD_TABLES_REQUESTED = 'tables/QUICK_ADD_TABLES_REQUESTED';
export const QUICK_ADD_TABLES_SUCCESS = 'tables/QUICK_ADD_TABLES_SUCCESS';
export const QUICK_ADD_TABLES_FAILURE = 'tables/QUICK_ADD_TABLES_FAILURE';

export const UPDATE_STATION_SECTION_REQUESTED = 'tables/UPDATE_STATION_SECTION_REQUESTED';
export const UPDATE_STATION_SECTION_SUCCESS = 'tables/UPDATE_STATION_SECTION_SUCCESS';
export const UPDATE_STATION_SECTION_FAILURE = 'tables/UPDATE_STATION_SECTION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getStation: [],
  getStationType: [],
  getPrinter: [],
  stationGroups: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STATION_BYSTORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getStation: []
      };
    }
    case GET_STATION_BYSTORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getStation: action.result
      };
    }
    case GET_STATION_BYSTORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_SEATS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_SEATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_SEATS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STATION_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STATION_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getStationType: action.result
      };
    }
    case GET_STATION_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_PRINTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_PRINTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_PRINTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_AVAILABILITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_AVAILABILITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PRINTER_BYSTORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PRINTER_BYSTORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPrinter: action.result
      };
    }
    case GET_PRINTER_BYSTORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STATION_GROUPS_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STATION_GROUPS_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationGroups: action.result
      };
    }
    case GET_STATION_GROUPS_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case QUICK_ADD_TABLES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case QUICK_ADD_TABLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case QUICK_ADD_TABLES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_SECTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_SECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_SECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStationByStoreId = (storeId) => {
  return {
    types: [GET_STATION_BYSTORE_ID_REQUESTED, GET_STATION_BYSTORE_ID_SUCCESS, GET_STATION_BYSTORE_ID_FAILURE],
    promise: client => client.get(`getStationByStoreId/${storeId}`)
  };
};

export const updateStationSeats = (data) => {
  return {
    types: [UPDATE_STATION_SEATS_REQUESTED, UPDATE_STATION_SEATS_SUCCESS, UPDATE_STATION_SEATS_FAILURE],
    promise: client => client.post('updateStationSeats', { data })
  };
};

export const getStationTypes = () => {
  return {
    types: [GET_STATION_TYPES_REQUESTED, GET_STATION_TYPES_SUCCESS, GET_STATION_TYPES_FAILURE],
    promise: client => client.get('getStationTypes')
  };
};

export const updateStationPrinter = (data) => {
  return {
    types: [UPDATE_STATION_PRINTERS_REQUESTED, UPDATE_STATION_PRINTERS_SUCCESS, UPDATE_STATION_PRINTERS_FAILURE],
    promise: client => client.post('updateStationPrinter', { data })
  };
};

export const updateStationAvailability = (data) => {
  return {
    types: [UPDATE_STATION_AVAILABILITY_REQUESTED, UPDATE_STATION_AVAILABILITY_SUCCESS, UPDATE_STATION_AVAILABILITY_FAILURE],
    promise: client => client.post('updateStationAvailability', { data })
  };
};

export const getPrinterByStoreId = (storeId) => {
  return {
    types: [GET_PRINTER_BYSTORE_ID_REQUESTED, GET_PRINTER_BYSTORE_ID_SUCCESS, GET_PRINTER_BYSTORE_ID_FAILURE],
    promise: client => client.get(`getPrintersByStoreId/${storeId}`)
  };
};

export const getStationGroupsByStoreId = (storeId) => {
  return {
    types: [GET_STATION_GROUPS_BY_STORE_ID_REQUESTED, GET_STATION_GROUPS_BY_STORE_ID_SUCCESS, GET_STATION_GROUPS_BY_STORE_ID_FAILURE],
    promise: client => client.get(`getStationGroupsByStoreId/${storeId}`)
  };
};

export const updateStationGroups = (data) => {
  return {
    types: [UPDATE_STATION_GROUPS_REQUESTED, UPDATE_STATION_GROUPS_SUCCESS, UPDATE_STATION_GROUPS_FAILURE],
    promise: client => client.post('updateStationGroups', { data })
  };
};

export const quickAddTables = (data) => {
  return {
    types: [QUICK_ADD_TABLES_REQUESTED, QUICK_ADD_TABLES_SUCCESS, QUICK_ADD_TABLES_FAILURE],
    promise: client => client.post('quickAddTables', { data })
  };
};

export const updateStationSection = (data) => {
  return {
    types: [UPDATE_STATION_SECTION_REQUESTED, UPDATE_STATION_SECTION_SUCCESS, UPDATE_STATION_SECTION_FAILURE],
    promise: client => client.post('updateStationSection', { data })
  };
};
