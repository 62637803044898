export const GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED = 'financialAccounts/GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED';
export const GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS = 'financialAccounts/GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS';
export const GET_ACCOUNTS_LOOKUPS_LIST_FAILURE = 'financialAccounts/GET_ACCOUNTS_LOOKUPS_LIST_FAILURE';

export const GET_BRAND_FINANCIAL_ACCOUNTS_LIST_REQUESTED = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNTS_LIST_REQUESTED';
export const GET_BRAND_FINANCIAL_ACCOUNTS_LIST_SUCCESS = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNTS_LIST_SUCCESS';
export const GET_BRAND_FINANCIAL_ACCOUNTS_LIST_FAILURE = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNTS_LIST_FAILURE';

export const GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED';
export const GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS';
export const GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE = 'financialAccounts/GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE';

export const UPDATE_BRAND_FINANCIAL_ACCOUNT_REQUESTED = 'financialAccounts/UPDATE_BRAND_FINANCIAL_ACCOUNT_REQUESTED';
export const UPDATE_BRAND_FINANCIAL_ACCOUNT_SUCCESS = 'financialAccounts/UPDATE_BRAND_FINANCIAL_ACCOUNT_SUCCESS';
export const UPDATE_BRAND_FINANCIAL_ACCOUNT_FAILURE = 'financialAccounts/UPDATE_BRAND_FINANCIAL_ACCOUNT_FAILURE';

export const GET_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED';
export const GET_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS';
export const GET_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE';

export const GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED';
export const GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS';
export const GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE = 'financialAccounts/GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE';

export const UPDATE_STORE_FINANCIAL_ACCOUNT_REQUESTED = 'financialAccounts/UPDATE_STORE_FINANCIAL_ACCOUNT_REQUESTED';
export const UPDATE_STORE_FINANCIAL_ACCOUNT_SUCCESS = 'financialAccounts/UPDATE_STORE_FINANCIAL_ACCOUNT_SUCCESS';
export const UPDATE_STORE_FINANCIAL_ACCOUNT_FAILURE = 'financialAccounts/UPDATE_STORE_FINANCIAL_ACCOUNT_FAILURE';

export const GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED = 'financialAccounts/GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED';
export const GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS = 'financialAccounts/GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS';
export const GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE = 'financialAccounts/GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE';

export const GET_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED = 'financialAccounts/GET_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED';
export const GET_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS = 'financialAccounts/GET_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS';
export const GET_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE = 'financialAccounts/GET_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE';

export const GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_REQUESTED = 'financialAccounts/GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_REQUESTED';
export const GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_SUCCESS = 'financialAccounts/GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_SUCCESS';
export const GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_FAILURE = 'financialAccounts/GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_FAILURE';

export const GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED = 'financialAccounts/GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED';
export const GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS = 'financialAccounts/GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS';
export const GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE = 'financialAccounts/GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE';

export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED';
export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS';
export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE';

export const GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED';
export const GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS';
export const GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE = 'financialAccounts/GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandAccountsList: [],
  accountLookups: [],
  storeAccountsList: [],
  storeAccountData: null,
  brandAccountData: null,
  storeAccountBalanceList: [],
  storeAccountJournalList: [],
  accountJournals: [],
  managerstoreAccountsList: [],
  managerstoreAccountBalanceList: [],
  managerstoreAccountJournalList: [],

};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        accountLookups: []
      };
    }
    case GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        accountLookups: action.result,
      };
    }
    case GET_ACCOUNTS_LOOKUPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandAccountsList: action.result,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandAccountData: action.result,
      };
    }
    case GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_FINANCIAL_ACCOUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_FINANCIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_FINANCIAL_ACCOUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountsList: action.result,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountData: action.result,
      };
    }
    case GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_FINANCIAL_ACCOUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_FINANCIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_FINANCIAL_ACCOUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        storeAccountBalanceList: []
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountBalanceList: action.result,
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        storeAccountJournalList: [],
      };
    }
    case GET_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountJournalList: action.result,
      };
    }
    case GET_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        accountJournals: action.result,
      };
    }
    case GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        managerstoreAccountsList: action.result,
      };
    }
    case GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        managerstoreAccountBalanceList: []
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        managerstoreAccountBalanceList: action.result,
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        managerstoreAccountJournalList: [],
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        managerstoreAccountJournalList: action.result,
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getAccountsLookUps = () => {
  return {
    types: [GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED,
      GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS,
      GET_ACCOUNTS_LOOKUPS_LIST_FAILURE],
    promise: client => client.get('getAccountTypesLookUps')
  };
};

export const getBrandAccountsList = (brandId) => {
  return {
    types: [GET_BRAND_FINANCIAL_ACCOUNTS_LIST_REQUESTED,
      GET_BRAND_FINANCIAL_ACCOUNTS_LIST_SUCCESS,
      GET_BRAND_FINANCIAL_ACCOUNTS_LIST_FAILURE],
    promise: client => client.get(`getBrandAccountsByBrandId/${brandId}`)
  };
};
export const getBrandAccountDataById = (accountId) => {
  return {
    types: [GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED,
      GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS,
      GET_BRAND_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE],
    promise: client => client.get(`getBrandAccountDataById/${accountId}`)
  };
};

export const updateBrandAccounts = (data) => {
  return {
    types: [UPDATE_BRAND_FINANCIAL_ACCOUNT_REQUESTED,
      UPDATE_BRAND_FINANCIAL_ACCOUNT_SUCCESS,
      UPDATE_BRAND_FINANCIAL_ACCOUNT_FAILURE],
    promise: client => client.post('updateBrandAccounts', { data })
  };
};

export const getStoreAccountsList = (brandId) => {
  return {
    types: [GET_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED,
      GET_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS,
      GET_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE],
    promise: client => client.get(`getStoreAccountsByStoreId/${brandId}`)
  };
};

export const getStoreAccountDataById = (accountId) => {
  return {
    types: [GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_REQUESTED,
      GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_SUCCESS,
      GET_STORE_FINANCIAL_ACCOUNT_DATA_BY_ID_FAILURE],
    promise: client => client.get(`getStoreAccountDataById/${accountId}`)
  };
};

export const updateStoreAccounts = (data) => {
  return {
    types: [UPDATE_STORE_FINANCIAL_ACCOUNT_REQUESTED,
      UPDATE_STORE_FINANCIAL_ACCOUNT_SUCCESS,
      UPDATE_STORE_FINANCIAL_ACCOUNT_FAILURE],
    promise: client => client.post('updateStoreAccounts', { data })
  };
};

export const getStoreAccountsBalance = (storeId, businessDate) => {
  return {
    types: [GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED,
      GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS,
      GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE],
    promise: client => client.get(`getStoreAccountsBalance/${storeId}/businessDate/${businessDate}`)
  };
};

export const getStoreAccountsJournal = (storeId, businessDate) => {
  return {
    types: [GET_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED,
      GET_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS,
      GET_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE],
    promise: client => client.get(`getStoreAccountsJournal/${storeId}/businessDate/${businessDate}`)
  };
};

export const getAccountJournalLookUps = () => {
  return {
    types: [GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_REQUESTED,
      GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_SUCCESS,
      GET_ACCOUNTS_JOURNAL_LOOKUPS_LIST_FAILURE],
    promise: client => client.get('getAccountJournalLookUps')
  };
};
export const getManagerStoreAccountsList = (brandId) => {
  return {
    types: [GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_REQUESTED,
      GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_SUCCESS,
      GET_MANAGER_STORE_FINANCIAL_ACCOUNTS_LIST_FAILURE],
    promise: client => client.get(`getManagerStoreAccountsByStoreId/${brandId}`)
  };
};
export const getManagerStoreAccountsBalance = (storeId, businessDate) => {
  return {
    types: [GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED,
      GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS,
      GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE],
    promise: client => client.get(`getManagerStoreAccountsBalance/${storeId}/businessDate/${businessDate}`)
  };
};

export const getManagerStoreAccountsJournal = (storeId, businessDate) => {
  return {
    types: [GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_REQUESTED,
      GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_SUCCESS,
      GET_MANAGER_STORE_ACCOUNTS_JOURNAL_LIST_FAILURE],
    promise: client => client.get(`getManagerStoreAccountsBalance/${storeId}/businessDate/${businessDate}`)
  };
};
