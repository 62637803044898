export const GET_TSD_GLOBAL_BY_BRANDID_REQUESTED = 'tsdGlobal/GET_TSD_GLOBAL_BY_BRANDID_REQUESTED';
export const GET_TSD_GLOBAL_BY_BRANDID_SUCCESS = 'tsdGlobal/GET_TSD_GLOBAL_BY_BRANDID_SUCCESS';
export const GET_TSD_GLOBAL_BY_BRANDID_FAILURE = 'tsdGlobal/GET_TSD_GLOBAL_BY_BRANDID_FAILURE';

export const UPDATE_TSD_GLOBAL_BY_BRANDID_REQUESTED = 'tsdGlobal/UPDATE_TSD_GLOBAL_BY_BRANDID_REQUESTED';
export const UPDATE_TSD_GLOBAL_BY_BRANDID_SUCCESS = 'tsdGlobal/UPDATE_TSD_GLOBAL_BY_BRANDID_SUCCESS';
export const UPDATE_TSD_GLOBAL_BY_BRANDID_FAILURE = 'tsdGlobal/UPDATE_TSD_GLOBAL_BY_BRANDID_FAILURE';

export const GET_DEVICES_CALL_CENTER_BY_BRAND_REQUESTED = 'tsdGlobal/GET_DEVICES_CALL_CENTER_BY_BRAND_REQUESTED';
export const GET_DEVICES_CALL_CENTER_BY_BRAND_SUCCESS = 'tsdGlobal/GET_DEVICES_CALL_CENTER_BY_BRAND_SUCCESS';
export const GET_DEVICES_CALL_CENTER_BY_BRAND_FAILURE = 'tsdGlobal/GET_DEVICES_CALL_CENTER_BY_BRAND_FAILURE';

export const UPDATE_TSD_GLOBAL_LIST_DATA = 'tsdGlobal/UPDATE_TSD_GLOBAL_LIST_DATA';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getDevicesCallCenterList: null,
  getTSDGlobalList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TSD_GLOBAL_BY_BRANDID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TSD_GLOBAL_BY_BRANDID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getTSDGlobalList: action.result,
      };
    }
    case GET_TSD_GLOBAL_BY_BRANDID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TSD_GLOBAL_BY_BRANDID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TSD_GLOBAL_BY_BRANDID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TSD_GLOBAL_BY_BRANDID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DEVICES_CALL_CENTER_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICES_CALL_CENTER_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getDevicesCallCenterList: action.result,
      };
    }
    case GET_DEVICES_CALL_CENTER_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TSD_GLOBAL_LIST_DATA: {
      return {
        ...state,
        getTSDGlobalList: action.payload,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getTSDGlobalByBrandId = (brandId) => {
  return {
    types: [GET_TSD_GLOBAL_BY_BRANDID_REQUESTED, GET_TSD_GLOBAL_BY_BRANDID_SUCCESS, GET_TSD_GLOBAL_BY_BRANDID_FAILURE],
    promise: client => client.get(`getTSDGlodal/${brandId}`)
  };
};

export const updateTSDGlobalByBrandId = (brandId, data) => {
  return {
    types: [UPDATE_TSD_GLOBAL_BY_BRANDID_REQUESTED, UPDATE_TSD_GLOBAL_BY_BRANDID_SUCCESS, UPDATE_TSD_GLOBAL_BY_BRANDID_FAILURE],
    promise: client => client.post(`updateTSDGlobal/${brandId}`, { data })
  };
};

export const getDeviceCallCenterByBrand = (brandId) => {
  return {
    types: [GET_DEVICES_CALL_CENTER_BY_BRAND_REQUESTED, GET_DEVICES_CALL_CENTER_BY_BRAND_SUCCESS, GET_DEVICES_CALL_CENTER_BY_BRAND_FAILURE],
    promise: client => client.get(`getDeviceCallCenterList/${brandId}`)
  };
};

export const updateTSDGlobalList = (data) => {
  return {
    type: UPDATE_TSD_GLOBAL_LIST_DATA,
    payload: data
  };
};
