const currentReducer = 'orderLabelTemplate';
const GET_ORDER_LABEL_TEMPLATE_REQUESTED = `${currentReducer}/GET_ORDER_LABEL_TEMPLATE_REQUESTED`;
const GET_ORDER_LABEL_TEMPLATE_SUCCESS = `${currentReducer}/GET_ORDER_LABEL_TEMPLATE_SUCCESS`;
const GET_ORDER_LABEL_TEMPLATE_FAILURE = `${currentReducer}/GET_ORDER_LABEL_TEMPLATE_FAILURE`;

export const ADD_ORDER_LABEL_TEMPLATE_REQUESTED = `${currentReducer}/ADD_ORDER_LABEL_TEMPLATE_REQUESTED`;
export const ADD_ORDER_LABEL_TEMPLATE_SUCCESS = `${currentReducer}/ADD_ORDER_LABEL_TEMPLATE_SUCCESS`;
export const ADD_ORDER_LABEL_TEMPLATE_FAILURE = `${currentReducer}/ADD_ORDER_LABEL_TEMPLATE_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  orderLabelTemplateList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_LABEL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_ORDER_LABEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        orderLabelTemplateList: action.result
      };
    }
    case GET_ORDER_LABEL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_ORDER_LABEL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_ORDER_LABEL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_ORDER_LABEL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getOderLabelTemplateList = (brandId) => {
  return {
    types: [GET_ORDER_LABEL_TEMPLATE_REQUESTED, GET_ORDER_LABEL_TEMPLATE_SUCCESS, GET_ORDER_LABEL_TEMPLATE_FAILURE],
    promise: client => client.get(`getOderLabelTemplateList/${brandId}`)
  };
};

export const updateOrderLabelTemaplate = (data) => {
  return {
    types: [ADD_ORDER_LABEL_TEMPLATE_REQUESTED, ADD_ORDER_LABEL_TEMPLATE_SUCCESS, ADD_ORDER_LABEL_TEMPLATE_FAILURE],
    promise: client => client.post('updateOrderLabelTemaplate', { data })
  };
};
