export const GET_OFFER_SYSTEM_REQUESTED = 'brand/GET_OFFER_SYSTEM_REQUESTED';
export const GET_OFFER_SYSTEM_SUCCESS = 'brand/GET_OFFER_SYSTEM_SUCCESS';
export const GET_OFFER_SYSTEM_FAILURE = 'brand/GET_OFFER_SYSTEM_FAILURE';

export const GET_OFFER_SYSTEM_CONFIG_REQUESTED = 'brand/GET_OFFER_SYSTEM_CONFIG_REQUESTED';
export const GET_OFFER_SYSTEM_CONFIG_SUCCESS = 'brand/GET_OFFER_SYSTEM_CONFIG_SUCCESS';
export const GET_OFFER_SYSTEM_CONFIG_FAILURE = 'brand/GET_OFFER_SYSTEM_CONFIG_FAILURE';

export const UPDATE_OFFER_SYSTEM_CONFIG_REQUESTED = 'brand/UPDATE_OFFER_SYSTEM_CONFIG_REQUESTED';
export const UPDATE_OFFER_SYSTEM_CONFIG_SUCCESS = 'brand/UPDATE_OFFER_SYSTEM_CONFIG_SUCCESS';
export const UPDATE_OFFER_SYSTEM_CONFIG_FAILURE = 'brand/UPDATE_OFFER_SYSTEM_CONFIG_FAILURE';

export const GET_LOYALTY_METHODS_REQUESTED = 'brand/GET_LOYALTY_METHODS_REQUESTED';
export const GET_LOYALTY_METHODS_SUCCESS = 'brand/GET_LOYALTY_METHODS_SUCCESS';
export const GET_LOYALTY_METHODS_FAILURE = 'brand/GET_LOYALTY_METHODS_FAILURE';

export const GET_GIFT_CARD_METHODS_REQUESTED = 'brand/GET_GIFT_CARD_METHODS_REQUESTED';
export const GET_GIFT_CARD_METHODS_SUCCESS = 'brand/GET_GIFT_CARD_METHODS_SUCCESS';
export const GET_GIFT_CARD_METHODS_FAILURE = 'brand/GET_GIFT_CARD_METHODS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getOffer: [],
  getSystemConfig: [],
  loyaltyMethods: [],
  giftCardMethods: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_SYSTEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFER_SYSTEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getOffer: action.result,
      };
    }
    case GET_OFFER_SYSTEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_OFFER_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFER_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getSystemConfig: action.result,
      };
    }
    case GET_OFFER_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_OFFER_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_OFFER_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_OFFER_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_LOYALTY_METHODS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LOYALTY_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        loyaltyMethods: action.result,
      };
    }
    case GET_LOYALTY_METHODS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_GIFT_CARD_METHODS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_GIFT_CARD_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        giftCardMethods: action.result,
      };
    }
    case GET_GIFT_CARD_METHODS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getOfferSystem = () => {
  return {
    types: [GET_OFFER_SYSTEM_REQUESTED, GET_OFFER_SYSTEM_SUCCESS, GET_OFFER_SYSTEM_FAILURE],
    promise: client => client.get('getOfferSystem')
  };
};

export const getOfferSystemConfig = (brandId) => {
  return {
    types: [GET_OFFER_SYSTEM_CONFIG_REQUESTED, GET_OFFER_SYSTEM_CONFIG_SUCCESS, GET_OFFER_SYSTEM_CONFIG_FAILURE],
    promise: client => client.get(`getOfferSytemConfig/${brandId}`)
  };
};

export const updateOfferSystemConfig = (brandId, data) => {
  return {
    types: [UPDATE_OFFER_SYSTEM_CONFIG_REQUESTED, UPDATE_OFFER_SYSTEM_CONFIG_SUCCESS, UPDATE_OFFER_SYSTEM_CONFIG_FAILURE],
    promise: client => client.post(`updateOfferSystemConfig/${brandId}`, { data })
  };
};

export const getLoyaltyMethods = () => {
  return {
    types: [GET_LOYALTY_METHODS_REQUESTED, GET_LOYALTY_METHODS_SUCCESS, GET_LOYALTY_METHODS_FAILURE],
    promise: client => client.get('getLoyaltyMethods')
  };
};

export const getGiftCardMethods = () => {
  return {
    types: [GET_GIFT_CARD_METHODS_REQUESTED, GET_GIFT_CARD_METHODS_SUCCESS, GET_GIFT_CARD_METHODS_FAILURE],
    promise: client => client.get('getGiftCardMethods')
  };
};
