export const GET_PNP_REQUESTED = 'brand/GET_PNP_REQUESTED';
export const GET_PNP_SUCCESS = 'brand/GET_PNP_SUCCESS';
export const GET_PNP_FAILURE = 'brand/GET_PNP_FAILURE';

export const GET_PAYTRONIX_REQUESTED = 'brand/GET_PAYTRONIX_REQUESTED';
export const GET_PAYTRONIX_SUCCESS = 'brand/GET_PAYTRONIX_SUCCESS';
export const GET_PAYTRONIX_FAILURE = 'brand/GET_PAYTRONIX_FAILURE';

export const GET_OMS_BRAND_OFFER_REQUESTED = 'brand/GET_OMS_BRAND_OFFER_REQUESTED';
export const GET_OMS_BRAND_OFFER_SUCCESS = 'brand/GET_OMS_BRAND_OFFER_SUCCESS';
export const GET_OMS_BRAND_OFFER_FAILURE = 'brand/GET_OMS_BRAND_OFFER_FAILURE';

export const GET_SPENDGO_REQUESTED = 'brand/GET_SPENDGO_REQUESTED';
export const GET_SPENDGO_SUCCESS = 'brand/GET_SPENDGO_SUCCESS';
export const GET_SPENDGO_FAILURE = 'brand/GET_SPENDGO_FAILURE';

export const GET_PAYTRONIX_GIFT_CARD_REQUESTED = 'brand/GET_PAYTRONIX_GIFT_CARD_REQUESTED';
export const GET_PAYTRONIX_GIFT_CARD_SUCCESS = 'brand/GET_PAYTRONIX_GIFT_CARD_SUCCESS';
export const GET_PAYTRONIX_GIFT_CARD_FAILURE = 'brand/GET_PAYTRONIX_GIFT_CARD_FAILURE';

export const UPDATE_PAYTRONIX_REQUESTED = 'brand/UPDATE_PAYTRONIX_REQUESTED';
export const UPDATE_PAYTRONIX_SUCCESS = 'brand/UPDATE_PAYTRONIX_SUCCESS';
export const UPDATE_PAYTRONIX_FAILURE = 'brand/UPDATE_PAYTRONIX_FAILURE';

export const UPDATE_SPENDGO_REQUESTED = 'brand/UPDATE_SPENDGO_REQUESTED';
export const UPDATE_SPENDGO_SUCCESS = 'brand/UPDATE_SPENDGO_SUCCESS';
export const UPDATE_SPENDGO_FAILURE = 'brand/UPDATE_SPENDGO_FAILURE';

export const UPDATE_PNP_REQUESTED = 'brand/UPDATE_PNP_REQUESTED';
export const UPDATE_PNP_SUCCESS = 'brand/UPDATE_PNP_SUCCESS';
export const UPDATE_PNP_FAILURE = 'brand/UPDATE_PNP_FAILURE';

export const UPDATE_PAYTRONIX_GIFT_CARD_REQUESTED = 'brand/UPDATE_PAYTRONIX_GIFT_CARD_REQUESTED';
export const UPDATE_PAYTRONIX_GIFT_CARD_SUCCESS = 'brand/UPDATE_PAYTRONIX_GIFT_CARD_SUCCESS';
export const UPDATE_PAYTRONIX_GIFT_CARD_FAILURE = 'brand/UPDATE_PAYTRONIX_GIFT_CARD_FAILURE';

export const UPDATE_OMS_BRAND_OFFER_REQUESTED = 'brand/UPDATE_OMS_BRAND_OFFER_REQUESTED';
export const UPDATE_OMS_BRAND_OFFER_SUCCESS = 'brand/UPDATE_OMS_BRAND_OFFER_SUCCESS';
export const UPDATE_OMS_BRAND_OFFER_FAILURE = 'brand/UPDATE_OMS_BRAND_OFFER_FAILURE';

export const GET_CLUTCH_REQUESTED = 'brand/GET_CLUTCH_REQUESTED';
export const GET_CLUTCH_SUCCESS = 'brand/GET_CLUTCH_SUCCESS';
export const GET_CLUTCH_FAILURE = 'brand/GET_CLUTCH_FAILURE';

export const UPDATE_CLUTCH_REQUESTED = 'brand/UPDATE_CLUTCH_REQUESTED';
export const UPDATE_CLUTCH_SUCCESS = 'brand/UPDATE_CLUTCH_SUCCESS';
export const UPDATE_CLUTCH_FAILURE = 'brand/UPDATE_CLUTCH_FAILURE';

export const GET_LOOK_LOYALTY_REQUESTED = 'brand/GET_LOOK_LOYALTY_REQUESTED';
export const GET_LOOK_LOYALTY_SUCCESS = 'brand/GET_LOOK_LOYALTY_SUCCESS';
export const GET_LOOK_LOYALTY_FAILURE = 'brand/GET_LOOK_LOYALTY_FAILURE';

export const UPDATE_LOOK_LOYALTY_REQUESTED = 'brand/UPDATE_LOOK_LOYALTY_REQUESTED';
export const UPDATE_LOOK_LOYALTY_SUCCESS = 'brand/UPDATE_LOOK_LOYALTY_SUCCESS';
export const UPDATE_LOOK_LOYALTY_FAILURE = 'brand/UPDATE_LOOK_LOYALTY_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getPnp: [],
  getPaytronix: [],
  brandOMSOffer: [],
  getPaytronixGiftCard: [],
  clutchData: {},
  lookLoyaltyData: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_PNP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PNP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPnp: action.result,
      };
    }
    case GET_PNP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PAYTRONIX_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getPaytronix: [],
      };
    }
    case GET_PAYTRONIX_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPaytronix: action.result,
      };
    }
    case GET_PAYTRONIX_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_OMS_BRAND_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OMS_BRAND_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandOMSOffer: action.result,
      };
    }
    case GET_OMS_BRAND_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PAYTRONIX_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PAYTRONIX_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PAYTRONIX_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_OMS_BRAND_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_OMS_BRAND_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_OMS_BRAND_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SPENDGO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SPENDGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getSpendgo: action.result,
      };
    }
    case GET_SPENDGO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SPENDGO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SPENDGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SPENDGO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PNP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PNP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PNP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PAYTRONIX_GIFT_CARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getPaytronixGiftCard: [],
      };
    }
    case GET_PAYTRONIX_GIFT_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPaytronixGiftCard: action.result,
      };
    }
    case GET_PAYTRONIX_GIFT_CARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PAYTRONIX_GIFT_CARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PAYTRONIX_GIFT_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PAYTRONIX_GIFT_CARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CLUTCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        clutchData: {},
      };
    }
    case GET_CLUTCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        clutchData: action.result,
      };
    }
    case GET_CLUTCH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CLUTCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CLUTCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CLUTCH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_LOOK_LOYALTY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        lookLoyaltyData: [],
      };
    }
    case GET_LOOK_LOYALTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        lookLoyaltyData: action.result,
      };
    }
    case GET_LOOK_LOYALTY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_LOOK_LOYALTY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_LOOK_LOYALTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_LOOK_LOYALTY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPnp = (brandId) => {
  return {
    types: [GET_PNP_REQUESTED, GET_PNP_SUCCESS, GET_PNP_FAILURE],
    promise: client => client.get(`getPnp/${brandId}`)
  };
};

export const getPaytronix = (brandId) => {
  return {
    types: [GET_PAYTRONIX_REQUESTED, GET_PAYTRONIX_SUCCESS, GET_PAYTRONIX_FAILURE],
    promise: client => client.get(`getPaytronix/${brandId}`)
  };
};

export const getBrandOMSOffer = (brandId) => {
  return {
    types: [GET_OMS_BRAND_OFFER_REQUESTED, GET_OMS_BRAND_OFFER_SUCCESS, GET_OMS_BRAND_OFFER_FAILURE],
    promise: client => client.get(`getBrandOMSOffer/${brandId}`)
  };
};

export const updateBrandOMSOffer = (brandId, data) => {
  return {
    types: [UPDATE_OMS_BRAND_OFFER_REQUESTED, UPDATE_OMS_BRAND_OFFER_SUCCESS, UPDATE_OMS_BRAND_OFFER_FAILURE],
    promise: client => client.post(`updateBrandOMSOffer/${brandId}`, { data })
  };
};

export const updatePaytronix = (brandId, data) => {
  return {
    types: [UPDATE_PAYTRONIX_REQUESTED, UPDATE_PAYTRONIX_SUCCESS, UPDATE_PAYTRONIX_FAILURE],
    promise: client => client.post(`updatePaytronix/${brandId}`, { data })
  };
};

export const getPaytronixGiftCard = (brandId) => {
  return {
    types: [GET_PAYTRONIX_GIFT_CARD_REQUESTED, GET_PAYTRONIX_GIFT_CARD_SUCCESS, GET_PAYTRONIX_GIFT_CARD_FAILURE],
    promise: client => client.get(`getPaytronixGiftCard/${brandId}`)
  };
};

export const updatePaytronixGiftCard = (brandId, data) => {
  return {
    types: [UPDATE_PAYTRONIX_GIFT_CARD_REQUESTED, UPDATE_PAYTRONIX_GIFT_CARD_SUCCESS, UPDATE_PAYTRONIX_GIFT_CARD_FAILURE],
    promise: client => client.post(`updatePaytronixGiftCard/${brandId}`, { data })
  };
};

export const getSpendgo = (brandId) => {
  return {
    types: [GET_SPENDGO_REQUESTED, GET_SPENDGO_SUCCESS, GET_SPENDGO_FAILURE],
    promise: client => client.get(`getSpendgo/${brandId}`)
  };
};

export const updateSpendgo = (brandId, data) => {
  return {
    types: [UPDATE_SPENDGO_REQUESTED, UPDATE_SPENDGO_SUCCESS, UPDATE_SPENDGO_FAILURE],
    promise: client => client.post(`updateSpendgo/${brandId}`, { data })
  };
};

export const updatePnp = (brandId, data) => {
  return {
    types: [UPDATE_PNP_REQUESTED, UPDATE_PNP_SUCCESS, UPDATE_PNP_FAILURE],
    promise: client => client.post(`updatePnp/${brandId}`, { data })
  };
};

export const getClutchLoyalty = (brandId) => {
  return {
    types: [GET_CLUTCH_REQUESTED, GET_CLUTCH_SUCCESS, GET_CLUTCH_FAILURE],
    promise: client => client.get(`getClutchLoyalty/${brandId}`)
  };
};

export const updateClutchLoyalty = (brandId, data) => {
  return {
    types: [UPDATE_CLUTCH_REQUESTED, UPDATE_CLUTCH_SUCCESS, UPDATE_CLUTCH_FAILURE],
    promise: client => client.post(`updateClutchLoyalty/${brandId}`, { data })
  };
};

export const getlookLoyalty = (brandId) => {
  return {
    types: [GET_LOOK_LOYALTY_REQUESTED, GET_LOOK_LOYALTY_SUCCESS, GET_LOOK_LOYALTY_FAILURE],
    promise: client => client.get(`getLookLoyalty/${brandId}`)
  };
};

export const updateLookLoyalty = (brandId, data) => {
  return {
    types: [UPDATE_LOOK_LOYALTY_REQUESTED, UPDATE_LOOK_LOYALTY_SUCCESS, UPDATE_LOOK_LOYALTY_FAILURE],
    promise: client => client.post(`updateLookLoyalty/${brandId}`, { data })
  };
};
