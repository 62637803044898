export const GET_FIREBOARD_NUMBER_REQUESTED = 'storeManager/GET_FIREBOARD_NUMBER_REQUESTED';
export const GET_FIREBOARD_NUMBER_SUCCESS = 'storeManager/GET_FIREBOARD_NUMBER_SUCCESS';
export const GET_FIREBOARD_NUMBER_FAILURE = 'storeManager/GET_FIREBOARD_NUMBER_FAILURE';

export const UPDATE_FIREBOARD_NUMBER_REQUESTED = 'storeManager/UPDATE_FIREBOARD_NUMBER_REQUESTED';
export const UPDATE_FIREBOARD_NUMBER_SUCCESS = 'storeManager/UPDATE_FIREBOARD_NUMBER_SUCCESS';
export const UPDATE_FIREBOARD_NUMBER_FAILURE = 'storeManager/UPDATE_FIREBOARD_NUMBER_FAILURE';

export const TEXT_TOPAY_REQUESTED = 'storeManager/TEXT_TOPAY_REQUESTED';
export const TEXT_TOPAY_SUCCESS = 'storeManager/TEXT_TOPAY_SUCCESS';
export const TEXT_TOPAY_FAILURE = 'storeManager/TEXT_TOPAY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  fireboardNumber: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_FIREBOARD_NUMBER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_FIREBOARD_NUMBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        fireboardNumber: action.result,
      };
    }
    case GET_FIREBOARD_NUMBER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_FIREBOARD_NUMBER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_FIREBOARD_NUMBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_FIREBOARD_NUMBER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case TEXT_TOPAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TEXT_TOPAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case TEXT_TOPAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getFireboardNumber = (storeId) => {
  return {
    types: [GET_FIREBOARD_NUMBER_REQUESTED, GET_FIREBOARD_NUMBER_SUCCESS, GET_FIREBOARD_NUMBER_FAILURE],
    promise: client => client.get(`getFireboardNumber/${storeId}`)
  };
};

export const updateFireboardNumber = (storeId, phoneNumber) => {
  return {
    types: [UPDATE_FIREBOARD_NUMBER_REQUESTED, UPDATE_FIREBOARD_NUMBER_SUCCESS, UPDATE_FIREBOARD_NUMBER_FAILURE],
    promise: client => client.post(`updateFireboardNumber/${storeId}/phoneNumber/${phoneNumber}`)
  };
};

export const deleteFireBoardNumber = (storeId) => {
  return {
    types: [UPDATE_FIREBOARD_NUMBER_REQUESTED, UPDATE_FIREBOARD_NUMBER_SUCCESS, UPDATE_FIREBOARD_NUMBER_FAILURE],
    promise: client => client.post(`clearFireBoardNumber/${storeId}`)
  };
};

export const textToPayForStore = (data) => {
  return {
    types: [TEXT_TOPAY_REQUESTED, TEXT_TOPAY_SUCCESS, TEXT_TOPAY_FAILURE],
    promise: client => client.post('textToPay', { data })
  };
};
