export const GET_SEAT_ORDER_APPROVAL_REQUESTED = 'seatOrderApproval/GET_SEAT_ORDER_APPROVAL_REQUESTED';
export const GET_SEAT_ORDER_APPROVAL_SUCCESS = 'seatOrderApproval/GET_SEAT_ORDER_APPROVAL_SUCCESS';
export const GET_SEAT_ORDER_APPROVAL_FAILURE = 'seatOrderApproval/GET_SEAT_ORDER_APPROVAL_FAILURE';

export const UPDATE_SEAT_ORDER_APPROVAL_REQUESTED = 'seatOrderApproval/UPDATE_SEAT_ORDER_APPROVAL_REQUESTED';
export const UPDATE_SEAT_ORDER_APPROVAL_SUCCESS = 'seatOrderApproval/UPDATE_SEAT_ORDER_APPROVAL_SUCCESS';
export const UPDATE_SEAT_ORDER_APPROVAL_FAILURE = 'seatOrderApproval/UPDATE_SEAT_ORDER_APPROVAL_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  seatOrderApprovalData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEAT_ORDER_APPROVAL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_SEAT_ORDER_APPROVAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        seatOrderApprovalData: action.result
      };
    }
    case GET_SEAT_ORDER_APPROVAL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case UPDATE_SEAT_ORDER_APPROVAL_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_SEAT_ORDER_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case UPDATE_SEAT_ORDER_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export const getSeatOrderApproval = (checkHeaderId, referenceCode, seatId) => {
  return {
    types: [GET_SEAT_ORDER_APPROVAL_REQUESTED, GET_SEAT_ORDER_APPROVAL_SUCCESS, GET_SEAT_ORDER_APPROVAL_FAILURE],
    promise: client => client.get(`getSeatOrderApproval/${checkHeaderId}/${referenceCode}/${seatId}`),
  };
};

export const updateSeatOrderApproval = (data) => {
  return {
    types: [UPDATE_SEAT_ORDER_APPROVAL_REQUESTED, UPDATE_SEAT_ORDER_APPROVAL_SUCCESS, UPDATE_SEAT_ORDER_APPROVAL_FAILURE],
    promise: client => client.post('updateSeatOrderApproval', {
      data
    }),
  };
};
