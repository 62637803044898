const GET_PRODUCTION_RATE_REQUESTED = 'productionRate/GET_PRODUCTION_RATE_REQUESTED';
const GET_PRODUCTION_RATE_SUCCESS = 'productionRate/GET_PRODUCTION_RATE_SUCCESS';
const GET_PRODUCTION_RATE_FAILURE = 'productionRate/GET_PRODUCTION_RATE_FAILURE';

const ADD_PRODUCTION_RATE_REQUESTED = 'productionRate/ADD_PRODUCTION_RATE_REQUESTED';
const ADD_PRODUCTION_RATE_SUCCESS = 'productionRate/ADD_PRODUCTION_RATE_SUCCESS';
const ADD_PRODUCTION_RATE_FAILURE = 'productionRate/ADD_PRODUCTION_RATE_FAILURE';

const UPDATE_PRODUCTION_RATE_REQUESTED = 'productionRate/UPDATE_PRODUCTION_RATE_REQUESTED';
const UPDATE_PRODUCTION_RATE_SUCCESS = 'productionRate/UPDATE_PRODUCTION_RATE_SUCCESS';
const UPDATE_PRODUCTION_RATE_FAILURE = 'productionRate/UPDATE_PRODUCTION_RATE_FAILURE';

const DELETE_PRODUCTION_RATE_REQUESTED = 'productionRate/DELETE_PRODUCTION_RATE_REQUESTED';
const DELETE_PRODUCTION_RATE_SUCCESS = 'productionRate/DELETE_PRODUCTION_RATE_SUCCESS';
const DELETE_PRODUCTION_RATE_FAILURE = 'productionRate/DELETE_PRODUCTION_RATE_FAILURE';

const OVERRIDE_PRODUCTION_RATE_REQUESTED = 'productionRate/OVERRIDE_PRODUCTION_RATE_REQUESTED';
const OVERRIDE_PRODUCTION_RATE_SUCCESS = 'productionRate/OVERRIDE_PRODUCTION_RATE_SUCCESS';
const OVERRIDE_PRODUCTION_RATE_FAILURE = 'productionRate/OVERRIDE_PRODUCTION_RATE_FAILURE';

const QUICK_ADD_PRODUCTION_RATE_REQUESTED = 'productionRate/QUICK_ADD_PRODUCTION_RATE_REQUESTED';
const QUICK_ADD_PRODUCTION_RATE_SUCCESS = 'productionRate/QUICK_ADD_PRODUCTION_RATE_SUCCESS';
const QUICK_ADD_PRODUCTION_RATE_FAILURE = 'productionRate/QUICK_ADD_PRODUCTION_RATE_FAILURE';

const QUICK_DELETE_PRODUCTION_RATE_REQUESTED = 'productionRate/QUICK_DELETE_PRODUCTION_RATE_REQUESTED';
const QUICK_DELETE_PRODUCTION_RATE_SUCCESS = 'productionRate/QUICK_DELETE_PRODUCTION_RATE_SUCCESS';
const QUICK_DELETE_PRODUCTION_RATE_FAILURE = 'productionRate/QUICK_DELETE_PRODUCTION_RATE_FAILURE';

const GET_PRODUCTION_OVERRIDE_REASON_REQUESTED = 'productionRate/GET_PRODUCTION_OVERRIDE_REASON_REQUESTED';
const GET_PRODUCTION_OVERRIDE_REASON_SUCCESS = 'productionRate/GET_PRODUCTION_OVERRIDE_REASON_SUCCESS';
const GET_PRODUCTION_OVERRIDE_REASON_FAILURE = 'productionRate/GET_PRODUCTION_OVERRIDE_REASON_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  storeproductionRateList: [],
  overrideReasonList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        storeproductionRateList: []
      };
    }
    case GET_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeproductionRateList: action.result
      };
    }
    case GET_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        storeproductionRateList: []
      };
    }
    case ADD_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case OVERRIDE_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case OVERRIDE_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case OVERRIDE_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case QUICK_ADD_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case QUICK_ADD_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case QUICK_ADD_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case QUICK_DELETE_PRODUCTION_RATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case QUICK_DELETE_PRODUCTION_RATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case QUICK_DELETE_PRODUCTION_RATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_PRODUCTION_OVERRIDE_REASON_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        overrideReasonList: []
      };
    }
    case GET_PRODUCTION_OVERRIDE_REASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        overrideReasonList: action.result
      };
    }
    case GET_PRODUCTION_OVERRIDE_REASON_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        overrideReasonList: []
      };
    }
    default:
      return state;
  }
};

export const getproductionRateByStoreId = (storeId) => {
  return {
    types: [GET_PRODUCTION_RATE_REQUESTED, GET_PRODUCTION_RATE_SUCCESS, GET_PRODUCTION_RATE_FAILURE],
    promise: client => client.get(`getProductionRateByStoreId/${storeId}`)
  };
};

export const addproductionRate = (data) => {
  return {
    types: [ADD_PRODUCTION_RATE_REQUESTED, ADD_PRODUCTION_RATE_SUCCESS, ADD_PRODUCTION_RATE_FAILURE],
    promise: client => client.post('addProductionRate', { data })
  };
};

export const updateproductionRate = (data) => {
  return {
    types: [UPDATE_PRODUCTION_RATE_REQUESTED, UPDATE_PRODUCTION_RATE_SUCCESS, UPDATE_PRODUCTION_RATE_FAILURE],
    promise: client => client.post('updateProductionRate', { data })
  };
};

export const deleteproductionRate = (productionRateId, data) => {
  return {
    types: [DELETE_PRODUCTION_RATE_REQUESTED, DELETE_PRODUCTION_RATE_SUCCESS, DELETE_PRODUCTION_RATE_FAILURE],
    promise: client => client.post(`deleteProductionRate/${productionRateId}`, { data })
  };
};

export const overrideProductionRate = (data) => {
  return {
    types: [OVERRIDE_PRODUCTION_RATE_REQUESTED, OVERRIDE_PRODUCTION_RATE_SUCCESS, OVERRIDE_PRODUCTION_RATE_FAILURE],
    promise: client => client.post('overrideProductionRate', { data })
  };
};

export const quickAddProductionRate = (data) => {
  return {
    types: [QUICK_ADD_PRODUCTION_RATE_REQUESTED, QUICK_ADD_PRODUCTION_RATE_SUCCESS, QUICK_ADD_PRODUCTION_RATE_FAILURE],
    promise: client => client.post('quickAddProductionRate', { data })
  };
};

export const quickDeleteProductionRate = (data) => {
  return {
    types: [QUICK_DELETE_PRODUCTION_RATE_REQUESTED, QUICK_DELETE_PRODUCTION_RATE_SUCCESS, QUICK_DELETE_PRODUCTION_RATE_FAILURE],
    promise: client => client.post('quickDeleteProductionRate', { data })
  };
};

export const getProductionOverrideReason = () => {
  return {
    types: [GET_PRODUCTION_OVERRIDE_REASON_REQUESTED, GET_PRODUCTION_OVERRIDE_REASON_SUCCESS, GET_PRODUCTION_OVERRIDE_REASON_FAILURE],
    promise: client => client.get('getProductionOverrideReason')
  };
};
