export const GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED = 'amazonPay/GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS = 'amazonPay/GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE = 'amazonPay/GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED = 'amazonPay/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS = 'amazonPay/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE = 'amazonPay/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  amazonPayList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        amazonPayList: null
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        amazonPayList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        amazonPayList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        amazonPayList: action.result,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getAmazonPayPaymentConfigByStoreId = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE],
    promise: client => client.get(`getAmazonPayPaymentConfigByStoreId/${storeId}`)
  };
};

export const updateAmazonPayPaymentConfigByStoreId = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_AMAZON_PAY_FAILURE],
    promise: client => client.post(`updateAmazonPayPaymentConfigByStoreId/${storeId}`, { data })
  };
};
