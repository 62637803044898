const currentReducer = 'brandEmailTemplate';
const GET_EMAIL_TEMPLATE_REQUESTED = `${currentReducer}/GET_EMAIL_TEMPLATE_REQUESTED`;
const GET_EMAIL_TEMPLATE_SUCCESS = `${currentReducer}/GET_EMAIL_TEMPLATE_SUCCESS`;
const GET_EMAIL_TEMPLATE_FAILURE = `${currentReducer}/GET_EMAIL_TEMPLATE_FAILURE`;

const GET_EMAIL_TYPES_REQUESTED = `${currentReducer}/GET_EMAIL_TYPES_REQUESTED`;
const GET_EMAIL_TYPES_SUCCESS = `${currentReducer}/GET_EMAIL_TYPES_SUCCESS`;
const GET_EMAIL_TYPES_FAILURE = `${currentReducer}/GET_EMAIL_TYPES_FAILURE`;

const GET_TEMPLATE_STATUS_REQUESTED = `${currentReducer}/GET_TEMPLATE_STATUS_REQUESTED`;
const GET_TEMPLATE_STATUS_SUCCESS = `${currentReducer}/GET_TEMPLATE_STATUS_SUCCESS`;
const GET_TEMPLATE_STATUS_FAILURE = `${currentReducer}/GET_TEMPLATE_STATUS_FAILURE`;

const DELETE_EMAIL_TEMPLATE_REQUESTED = `${currentReducer}/DELETE_TEMPLATE_STATUS_REQUESTED`;
const DELETE_EMAIL_TEMPLATE_SUCCESS = `${currentReducer}/DELETE_TEMPLATE_STATUS_SUCCESS`;
const DELETE_EMAIL_TEMPLATE_FAILURE = `${currentReducer}/DELETE_TEMPLATE_STATUS_FAILURE`;

const GET_CUSTOMIZABLE_EMAIL_REQUESTED = `${currentReducer}/GET_CUSTOMIZABLE_EMAIL_REQUESTED`;
const GET_CUSTOMIZABLE_EMAIL_SUCCESS = `${currentReducer}/GET_CUSTOMIZABLE_EMAIL_SUCCESS`;
const GET_CUSTOMIZABLE_EMAIL_FAILURE = `${currentReducer}/GET_CUSTOMIZABLE_EMAIL_FAILURE`;

const GET_EMAIL_TEMPLATE_MERGE_FIELDS_REQUESTED = `${currentReducer}/GET_EMAIL_TEMPLATE_MERGE_FIELDS_REQUESTED`;
const GET_EMAIL_TEMPLATE_MERGE_FIELDS_SUCCESS = `${currentReducer}/GET_EMAIL_TEMPLATE_MERGE_FIELDS_SUCCESS`;
const GET_EMAIL_TEMPLATE_MERGE_FIELDS_FAILURE = `${currentReducer}/GET_EMAIL_TEMPLATE_MERGE_FIELDS_FAILURE`;

const GET_EMAIL_TEMPLATE_BY_ID_REQUESTED = `${currentReducer}/GET_EMAIL_TEMPLATE_BY_ID_REQUESTED`;
const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS = `${currentReducer}/GET_EMAIL_TEMPLATE_BY_ID_SUCCESS`;
const GET_EMAIL_TEMPLATE_BY_ID_FAILURE = `${currentReducer}/GET_EMAIL_TEMPLATE_BY_ID_FAILURE`;

export const ADD_EMAIL_TEMPLATE_REQUESTED = `${currentReducer}/ADD_EMAIL_TEMPLATE_REQUESTED`;
export const ADD_EMAIL_TEMPLATE_SUCCESS = `${currentReducer}/ADD_EMAIL_TEMPLATE_SUCCESS`;
export const ADD_EMAIL_TEMPLATE_FAILURE = `${currentReducer}/ADD_EMAIL_TEMPLATE_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  emailTemplateList: [],
  emailTypeData: [],
  templateStatusData: [],
  emailCustomTemplateList: [],
  emailMergeField: [],
  emailTemplateById: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        emailTemplateList: action.result
      };
    }
    case GET_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_EMAIL_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EMAIL_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        emailTypeData: action.result
      };
    }
    case GET_EMAIL_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_TEMPLATE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_TEMPLATE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        templateStatusData: action.result
      };
    }
    case GET_TEMPLATE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_CUSTOMIZABLE_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CUSTOMIZABLE_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        emailCustomTemplateList: action.result
      };
    }
    case GET_CUSTOMIZABLE_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_EMAIL_TEMPLATE_MERGE_FIELDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_EMAIL_TEMPLATE_MERGE_FIELDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        emailMergeField: action.result,
      };
    }
    case GET_EMAIL_TEMPLATE_MERGE_FIELDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_EMAIL_TEMPLATE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        emailTemplateById: action.result
      };
    }
    case GET_EMAIL_TEMPLATE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getBrandEmailTemplateList = (brandId) => {
  return {
    types: [GET_EMAIL_TEMPLATE_REQUESTED, GET_EMAIL_TEMPLATE_SUCCESS, GET_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.get(`getBrandEmailTemplateList/${brandId}`)
  };
};
export const getEmailTypes = () => {
  return {
    types: [GET_EMAIL_TYPES_REQUESTED, GET_EMAIL_TYPES_SUCCESS, GET_EMAIL_TYPES_FAILURE],
    promise: client => client.get('getEmailTypes')
  };
};
export const getEmailTemplateStatus = () => {
  return {
    types: [GET_TEMPLATE_STATUS_REQUESTED, GET_TEMPLATE_STATUS_SUCCESS, GET_TEMPLATE_STATUS_FAILURE],
    promise: client => client.get('getTemplateStatus')
  };
};
export const deleteEmailTemplate = (brandEmailTemplateId, data) => {
  return {
    types: [DELETE_EMAIL_TEMPLATE_REQUESTED, DELETE_EMAIL_TEMPLATE_SUCCESS, DELETE_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.post(`deleteEmailTemplate/${brandEmailTemplateId}`, { data })
  };
};
export const getCustomizableEmailTemplate = () => {
  return {
    types: [GET_CUSTOMIZABLE_EMAIL_REQUESTED, GET_CUSTOMIZABLE_EMAIL_SUCCESS, GET_CUSTOMIZABLE_EMAIL_FAILURE],
    promise: client => client.get('getCustomizableEmail')
  };
};
export const getEmailMergeFields = (id) => {
  return {
    types: [GET_EMAIL_TEMPLATE_MERGE_FIELDS_REQUESTED, GET_EMAIL_TEMPLATE_MERGE_FIELDS_SUCCESS, GET_EMAIL_TEMPLATE_MERGE_FIELDS_FAILURE],
    promise: client => client.get(`getEmailMergeFields/${id}`)
  };
};
export const getBrandEmailListById = (id) => {
  return {
    types: [GET_EMAIL_TEMPLATE_BY_ID_REQUESTED, GET_EMAIL_TEMPLATE_BY_ID_SUCCESS, GET_EMAIL_TEMPLATE_BY_ID_FAILURE],
    promise: client => client.get(`getBrandEmailListById/${id}`)
  };
};
export const addBrandEmailTemplate = (data) => {
  return {
    types: [ADD_EMAIL_TEMPLATE_REQUESTED, ADD_EMAIL_TEMPLATE_SUCCESS, ADD_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.post('addBrandEmailTemplate', { data })
  };
};
export const updateBrandEmailTemaplate = (data) => {
  return {
    types: [ADD_EMAIL_TEMPLATE_REQUESTED, ADD_EMAIL_TEMPLATE_SUCCESS, ADD_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.post('updateBrandEmailTemaplate', { data })
  };
};
