import {
  getCompanyId,
  getBrandId,
  getStoreId
} from '@utility/storageOperations';
import ApiClient from '@helpers/ApiClient';

export const redirectToAnalytics = (redirectToUrl) => {
  const companyId = getCompanyId();
  const brandId = getBrandId();
  const storeId = getStoreId();
  const request = {
    companyId,
    brandId,
    storeId,
    redirectUrl: redirectToUrl // '/Reports/CheckReport'
  };
  const apiClient = new ApiClient();
  return apiClient.post('getAnalyticsSsoUrl', { data: request })
    .then((url) => {
      if (url) {
        window.open(url, '_blank');
      }
    });
};

export default {
  redirectToAnalytics
};
