export const GET_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED = 'menuEnterprise/GET_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED';
export const GET_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS = 'menuEnterprise/GET_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS';
export const GET_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE = 'menuEnterprise/GET_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE';

export const UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED = 'menuEnterprise/UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED';
export const UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS = 'menuEnterprise/UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS';
export const UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE = 'menuEnterprise/UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE';

export const UPDATE_MANAGE_STORE_ITEMS_DATA = 'menuEnterprise/UPDATE_MANAGE_STORE_ITEMS_DATA';

export const GET_ENTERPRISE_MENUITEM_BY_SCREENID_REQUESTED = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_SCREENID_REQUESTED';
export const GET_ENTERPRISE_MENUITEM_BY_SCREENID_SUCCESS = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_SCREENID_SUCCESS';
export const GET_ENTERPRISE_MENUITEM_BY_SCREENID_FAILURE = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_SCREENID_FAILURE';

export const GET_ENTERPRISE_MENUITEM_BY_STOREID_REQUESTED = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_STOREID_REQUESTED';
export const GET_ENTERPRISE_MENUITEM_BY_STOREID_SUCCESS = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_STOREID_SUCCESS';
export const GET_ENTERPRISE_MENUITEM_BY_STOREID_FAILURE = 'menuEnterprise/GET_ENTERPRISE_MENUITEM_BY_STOREID_FAILURE';

export const GET_MENU_BY_STOREID_REQUESTED = 'menuEnterprise/GET_MENU_BY_STOREID_REQUESTED';
export const GET_MENU_BY_STOREID_SUCCESS = 'menuEnterprise/GET_MENU_BY_STOREID_SUCCESS';
export const GET_MENU_BY_STOREID_FAILURE = 'menuEnterprise/GET_MENU_BY_STOREID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  manageStoreItemsData: [],
  enterpriseMenuItemByScreenId: [],
  enterpriseMenuItemByStoreId: [],
  menuByStoreId: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        manageStoreItemsData: [],
      };
    }
    case GET_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        manageStoreItemsData: action.result,
      };
    }
    case GET_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MANAGE_STORE_ITEMS_DATA: {
      return {
        ...state,
        manageStoreItemsData: action.payload,
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_SCREENID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        enterpriseMenuItemByScreenId: [],
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_SCREENID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        enterpriseMenuItemByScreenId: action.result,
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_SCREENID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        enterpriseMenuItemByScreenId: [],
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        enterpriseMenuItemByStoreId: []
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        enterpriseMenuItemByStoreId: action.result,
      };
    }
    case GET_ENTERPRISE_MENUITEM_BY_STOREID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        enterpriseMenuItemByStoreId: [],
      };
    }
    case GET_MENU_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        menuByStoreId: []
      };
    }
    case GET_MENU_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        menuByStoreId: action.result,
      };
    }
    case GET_MENU_BY_STOREID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        menuByStoreId: [],
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getManageStoreItemsByType = (type, storeId) => {
  return {
    types: [GET_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED, GET_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS, GET_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE],
    promise: client => client.get(`mangeStoreItemByType/${type}/storeId/${storeId}`)
  };
};

export const updateManageStoreItemsByType = (type, data) => {
  return {
    types: [UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_REQUESTED, UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_SUCCESS, UPDATE_MANAGE_STORE_ITEMS_BY_TYPES_FAILURE],
    promise: client => client.post(`mangeStoreItemByType/${type}/store/update`, { data })
  };
};

export const updateManageStoreItems = (data) => {
  return {
    type: UPDATE_MANAGE_STORE_ITEMS_DATA,
    payload: data,
  };
};

export const getEnterpriseMenuItemsByScreenId = (menuScreenId) => {
  return {
    types: [GET_ENTERPRISE_MENUITEM_BY_SCREENID_REQUESTED, GET_ENTERPRISE_MENUITEM_BY_SCREENID_SUCCESS, GET_ENTERPRISE_MENUITEM_BY_SCREENID_FAILURE],
    promise: client => client.get(`getEnterpriseMenuItemsByScreenId/${menuScreenId}`)
  };
};

export const getEnterpriseMenuItemsByStoreId = (storeId, menuScreenId) => {
  return {
    types: [GET_ENTERPRISE_MENUITEM_BY_STOREID_REQUESTED, GET_ENTERPRISE_MENUITEM_BY_STOREID_SUCCESS, GET_ENTERPRISE_MENUITEM_BY_STOREID_FAILURE],
    promise: client => client.get(`getEnterpriseMenuItemsByStoreId/${storeId}/menuScreenId/${menuScreenId}`)
  };
};

export const getMenuByStoreId = (brandId, storeId) => {
  return {
    types: [GET_MENU_BY_STOREID_REQUESTED, GET_MENU_BY_STOREID_SUCCESS, GET_MENU_BY_STOREID_FAILURE],
    promise: client => client.get(`getMenuByStoreId/${brandId}/storeId/${storeId}`)
  };
};
