export const GET_FISHBOWL_CONFIG_REQUESTED = 'brand/GET_FISHBOWL_CONFIG_REQUESTED';
export const GET_FISHBOWL_CONFIG_SUCCESS = 'brand/GET_FISHBOWL_CONFIG_SUCCESS';
export const GET_FISHBOWL_CONFIG_FAILURE = 'brand/GET_FISHBOWL_CONFIG_FAILURE';

export const UPDATE_FISHBOWL_CONFIG_REQUESTED = 'brand/UPDATE_FISHBOWL_CONFIG_REQUESTED';
export const UPDATE_FISHBOWL_CONFIG_SUCCESS = 'brand/UPDATE_FISHBOWL_CONFIG_SUCCESS';
export const UPDATE_FISHBOWL_CONFIG_FAILURE = 'brand/UPDATE_FISHBOWL_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  fishbowlList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_FISHBOWL_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        fishbowlList: [],
      };
    }
    case GET_FISHBOWL_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        fishbowlList: action.result,
      };
    }
    case GET_FISHBOWL_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_FISHBOWL_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_FISHBOWL_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_FISHBOWL_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getFishbowlConfig = (brandId) => {
  return {
    types: [GET_FISHBOWL_CONFIG_REQUESTED, GET_FISHBOWL_CONFIG_SUCCESS, GET_FISHBOWL_CONFIG_FAILURE],
    promise: client => client.get(`getFishbowlConfig/${brandId}`)
  };
};

export const updateFishbowlConfig = (brandId, data) => {
  return {
    types: [UPDATE_FISHBOWL_CONFIG_REQUESTED, UPDATE_FISHBOWL_CONFIG_SUCCESS, UPDATE_FISHBOWL_CONFIG_FAILURE],
    promise: client => client.post(`updateFishbowlConfig/${brandId}`, { data })
  };
};
