export const POST_ADD_SCREEN_LOCATION_REQUESTED = 'screenLocation/POST_ADD_SCREEN_LOCATION_REQUESTED';
export const POST_ADD_SCREEN_LOCATION_SUCCESS = 'screenLocation/POST_ADD_SCREEN_LOCATION_SUCCESS';
export const POST_ADD_SCREEN_LOCATION_FAILURE = 'screenLocation/POST_ADD_SCREEN_LOCATION_FAILURE';

export const GET_SCREEN_LOCATIONS_REQUESTED = 'screenLocation/GET_SCREEN_LOCATIONS_REQUESTED';
export const GET_SCREEN_LOCATIONS_SUCCESS = 'screenLocation/GET_SCREEN_LOCATIONS_SUCCESS';
export const GET_SCREEN_LOCATIONS_FAILURE = 'screenLocation/GET_SCREEN_LOCATIONS_FAILURE';

export const POST_DELETE_SCREEN_LOCATION_REQUESTED = 'screenLocation/POST_DELETE_SCREEN_LOCATION_REQUESTED';
export const POST_DELETE_SCREEN_LOCATION_SUCCESS = 'screenLocation/POST_DELETE_SCREEN_LOCATION_SUCCESS';
export const POST_DELETE_SCREEN_LOCATION_FAILURE = 'screenLocation/POST_DELETE_SCREEN_LOCATION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  screenLocationList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_SCREEN_LOCATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ADD_SCREEN_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ADD_SCREEN_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SCREEN_LOCATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SCREEN_LOCATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        screenLocationList: action.result,
      };
    }
    case GET_SCREEN_LOCATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        screenLocationList: [],
      };
    }
    case POST_DELETE_SCREEN_LOCATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_DELETE_SCREEN_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_DELETE_SCREEN_LOCATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addScreenLocation = (screenId, storeId) => {
  return {
    types: [POST_ADD_SCREEN_LOCATION_REQUESTED, POST_ADD_SCREEN_LOCATION_SUCCESS, POST_ADD_SCREEN_LOCATION_FAILURE],
    promise: client => client.post(`addScreenLocation/${screenId}/storeId/${storeId}`)
  };
};

export const getScreenLocation = (screenId) => {
  return {
    types: [GET_SCREEN_LOCATIONS_REQUESTED, GET_SCREEN_LOCATIONS_SUCCESS, GET_SCREEN_LOCATIONS_FAILURE],
    promise: client => client.get(`getScreenLocation/${screenId}`)
  };
};

export const deleteScreenLocation = (screenId, storeId) => {
  return {
    types: [POST_DELETE_SCREEN_LOCATION_REQUESTED, POST_DELETE_SCREEN_LOCATION_SUCCESS, POST_DELETE_SCREEN_LOCATION_FAILURE],
    promise: client => client.post(`deleteScreenLocation/${screenId}/storeId/${storeId}`)
  };
};
