export const GET_USERWAY_CONFIG_REQUESTED = 'brand/GET_USERWAY_CONFIG_REQUESTED';
export const GET_USERWAY_CONFIG_SUCCESS = 'brand/GET_USERWAY_CONFIG_SUCCESS';
export const GET_USERWAY_CONFIG_FAILURE = 'brand/GET_USERWAY_CONFIG_FAILURE';

export const UPDATE_USERWAY_CONFIG_REQUESTED = 'brand/UPDATE_USERWAY_CONFIG_REQUESTED';
export const UPDATE_USERWAY_CONFIG_SUCCESS = 'brand/UPDATE_USERWAY_CONFIG_SUCCESS';
export const UPDATE_USERWAY_CONFIG_FAILURE = 'brand/UPDATE_USERWAY_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userWayList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_USERWAY_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        userWayList: [],
      };
    }
    case GET_USERWAY_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userWayList: action.result,
      };
    }
    case GET_USERWAY_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        userWayList: [],
      };
    }
    case UPDATE_USERWAY_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_USERWAY_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_USERWAY_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getUserWayConfig = (brandId) => {
  return {
    types: [GET_USERWAY_CONFIG_REQUESTED, GET_USERWAY_CONFIG_SUCCESS, GET_USERWAY_CONFIG_FAILURE],
    promise: client => client.get(`getUserWayConfig/${brandId}`)
  };
};

export const updateUserWayConfig = (brandId, data) => {
  return {
    types: [UPDATE_USERWAY_CONFIG_REQUESTED, UPDATE_USERWAY_CONFIG_SUCCESS, UPDATE_USERWAY_CONFIG_FAILURE],
    promise: client => client.post(`updateUserWayConfig/${brandId}`, { data })
  };
};
