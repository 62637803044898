import uuid from 'uuid/v4';

export const GET_DEVICES_BY_STORE_REQUESTED = 'device/GET_DEVICES_BY_STORE_REQUESTED';
export const GET_DEVICES_BY_STORE_SUCCESS = 'device/GET_DEVICES_BY_STORE_SUCCESS';
export const GET_DEVICES_BY_STORE_FAILURE = 'device/GET_DEVICES_BY_STORE_FAILURE';

export const REGISTER_DEVICE_REQUESTED = 'device/REGISTER_DEVICE_REQUESTED';
export const REGISTER_DEVICE_SUCCESS = 'device/REGISTER_DEVICE_SUCCESS';
export const REGISTER_DEVICE_FAILURE = 'device/REGISTER_DEVICE_FAILURE';

export const GET_DEVICE_BY_ID_REQUESTED = 'device/GET_DEVICE_BY_ID_REQUESTED';
export const GET_DEVICE_BY_ID_SUCCESS = 'device/GET_DEVICE_BY_ID_SUCCESS';
export const GET_DEVICE_BY_ID_FAILURE = 'device/GET_DEVICE_BY_ID_FAILURE';

export const UPDATE_DEVICE_REQUESTED = 'device/UPDATE_COMPANY_REQUESTED';
export const UPDATE_DEVICE_SUCCESS = 'device/UPDATE_COMPANY_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'device/UPDATE_COMPANY_FAILURE';

export const RE_REGISTER_DEVICE_REQUESTED = 'device/RE_REGISTER_DEVICE_REQUESTED';
export const RE_REGISTER_DEVICE_SUCCESS = 'device/RE_REGISTER_DEVICE_SUCCESS';
export const RE_REGISTER_DEVICE_FAILURE = 'device/RE_REGISTER_DEVICE_FAILURE';

export const DE_REGISTER_DEVICE_REQUESTED = 'device/DE_REGISTER_DEVICE_REQUESTED';
export const DE_REGISTER_DEVICE_SUCCESS = 'device/DE_REGISTER_DEVICE_SUCCESS';
export const DE_REGISTER_DEVICE_FAILURE = 'device/DE_REGISTER_DEVICE_FAILURE';

export const GET_PRINTER_TYPES_REQUESTED = 'device/GET_PRINTER_TYPES_REQUESTED';
export const GET_PRINTER_TYPES_SUCCESS = 'device/GET_PRINTER_TYPES_SUCCESS';
export const GET_PRINTER_TYPES_FAILURE = 'device/GET_PRINTER_TYPES_FAILURE';

export const SEND_MESSAGE_REQUESTED = 'device/SEND_MESSAGE_REQUESTED';
export const SEND_MESSAGE_SUCCESS = 'device/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'device/SEND_MESSAGE_FAILURE';

export const GET_DEVICE_GROUP_REQUESTED = 'device/GET_DEVICE_GROUP_REQUESTED';
export const GET_DEVICE_GROUP_SUCCESS = 'device/GET_DEVICE_GROUP_SUCCESS';
export const GET_DEVICE_GROUP_FAILURE = 'device/GET_DEVICE_GROUP_FAILURE';

export const DELETE_DEVICE_GROUP_REQUESTED = 'device/DELETE_DEVICE_GROUP_REQUESTED';
export const DELETE_DEVICE_GROUP_SUCCESS = 'device/DELETE_DEVICE_GROUP_SUCCESS';
export const DELETE_DEVICE_GROUP_FAILURE = 'device/DELETE_DEVICE_GROUP_FAILURE';

export const DEVICE_GROUP_BY_DEVICE_TYPE_REQUESTED = 'device/DEVICE_GROUP_BY_DEVICE_TYPE_REQUESTED';
export const DEVICE_GROUP_BY_DEVICE_TYPE_SUCCESS = 'device/DEVICE_GROUPBY_DEVICE_TYPE_SUCCESS';
export const DEVICE_GROUP_BY_DEVICE_TYPE_FAILURE = 'device/DEVICE_GROUP_BY_DEVICE_TYPE_FAILURE';

export const ADD_DEVICE_GROUP_REQUESTED = 'device/ADD_DEVICE_GROUP_REQUESTED';
export const ADD_DEVICE_GROUP_SUCCESS = 'device/ADD_DEVICE_GROUP_SUCCESS';
export const ADD_DEVICE_GROUP_FAILURE = 'device/ADD_DEVICE_GROUP_FAILURE';

export const GET_DEVICE_GROUP_BY_ID_REQUESTED = 'device/GET_DEVICE_GROUP_BY_ID_REQUESTED';
export const GET_DEVICE_GROUP_BY_ID_SUCCESS = 'device/GET_DEVICE_GROUP_BY_ID_SUCCESS';
export const GET_DEVICE_GROUP_BY_ID_FAILURE = 'device/GET_DEVICE_GROUP_BY_ID_FAILURE';

export const GET_START_MODE_REQUESTED = 'device/GET_START_MODE_REQUESTED';
export const GET_START_MODE_SUCCESS = 'device/GET_START_MODE_SUCCESS';
export const GET_START_MODE_FAILURE = 'device/GET_START_MODE_FAILURE';

export const GET_CARD_READER_TYPES_REQUESTED = 'device/GET_CARD_READER_TYPES_REQUESTED';
export const GET_CARD_READER_TYPES_SUCCESS = 'device/GET_CARD_READER_TYPES_SUCCESS';
export const GET_CARD_READER_TYPES_FAILURE = 'device/GET_CARD_READER_TYPES_FAILURE';

export const GET_DISTINCT_ORDER_TYPES_REQUESTED = 'device/GET_DISTINCT_ORDER_TYPES_REQUESTED';
export const GET_DISTINCT_ORDER_TYPES_SUCCESS = 'device/GET_DISTINCT_ORDER_TYPES_SUCCESS';
export const GET_DISTINCT_ORDER_TYPES_FAILURE = 'device/GET_DISTINCT_ORDER_TYPES_FAILURE';

export const GET_DISTINCT_REVENUE_CENTERS_REQUESTED = 'device/GET_DISTINCT_REVENUE_CENTERS_REQUESTED';
export const GET_DISTINCT_REVENUE_CENTERS_SUCCESS = 'device/GET_DISTINCT_REVENUE_CENTERS_SUCCESS';
export const GET_DISTINCT_REVENUE_CENTERS_FAILURE = 'device/GET_DISTINCT_REVENUE_CENTERS_FAILURE';

export const TEST_INGENICO_DEVICE_CREDENTIALS_REQUESTED = 'device/TEST_INGENICO_DEVICE_CREDENTIALS_REQUESTED';
export const TEST_INGENICO_DEVICE_CREDENTIALS_SUCCESS = 'device/TEST_INGENICO_DEVICE_CREDENTIALS_SUCCESS';
export const TEST_INGENICO_DEVICE_CREDENTIALS_FAILURE = 'device/TEST_INGENICO_DEVICE_CREDENTIALS_FAILURE';

export const REGISTER_BULK_DEVICES_REQUESTED = 'device/REGISTER_BULK_DEVICES_REQUESTED';
export const REGISTER_BULK_DEVICES_SUCCESS = 'device/REGISTER_BULK_DEVICES_SUCCESS';
export const REGISTER_BULK_DEVICES_FAILURE = 'device/REGISTER_BULK_DEVICES_FAILURE';

export const CARD_READER_PROVISION_REQUESTED = 'device/CARD_READER_PROVISION_REQUESTED';
export const CARD_READER_PROVISION_SUCCESS = 'device/CARD_READER_PROVISION_SUCCESS';
export const CARD_READER_PROVISION_FAILURE = 'device/CARD_READER_PROVISION_FAILURE';

export const CARD_READER_DE_PROVISION_REQUESTED = 'device/CARD_READER_DE_PROVISION_REQUESTED';
export const CARD_READER_DE_PROVISION_SUCCESS = 'device/CARD_READER_DE_PROVISION_SUCCESS';
export const CARD_READER_DE_PROVISION_FAILURE = 'device/CARD_READER_DE_PROVISION_FAILURE';

export const GET_KDS_SORT_TYPES_REQUESTED = 'device/GET_KDS_SORT_TYPES_REQUESTED';
export const GET_KDS_SORT_TYPES_SUCCESS = 'device/GET_KDS_SORT_TYPES_SUCCESS';
export const GET_KDS_SORT_TYPES_FAILURE = 'device/GET_KDS_SORT_TYPES_FAILURE';

export const GET_DEVICE_GROUP_BY_ROLE_REQUESTED = 'device/GET_DEVICE_GROUP_BY_ROLE_REQUESTED';
export const GET_DEVICE_GROUP_BY_ROLE_SUCCESS = 'device/GET_DEVICE_GROUP_BY_ROLE_SUCCESS';
export const GET_DEVICE_GROUP_BY_ROLE_FAILURE = 'device/GET_DEVICE_GROUP_BY_ROLE_FAILURE';

export const DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_REQUESTED = 'device/DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_REQUESTED';
export const DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_SUCCESS = 'device/DEVICE_GROUPBY_DEVICE_TYPE_BY_ROLE_SUCCESS';
export const DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_FAILURE = 'device/DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  devicesList: [],
  deviceData: null,
  printerTypes: [],
  deviceGroupData: [],
  deviceGrouByDeviceType: [],
  deviceGroupById: [],
  startMode: [],
  getCardReaderTypes: [],
  distinctOrderTypesList: [],
  distinctRevenueCentersList: [],
  kdsSortTypesList: [],
  deviceGroupListByRole: [],
  deviceGrouByDeviceTypeByRole: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_BY_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICES_BY_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        devicesList: action.result.map(obj => ({ ...obj, uniqueId: uuid() })),
      };
    }
    case GET_DEVICES_BY_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_PRINTER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PRINTER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        printerTypes: action.result,
      };
    }
    case GET_PRINTER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case REGISTER_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REGISTER_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REGISTER_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_DEVICE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceData: action.result,
      };
    }
    case GET_DEVICE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SEND_MESSAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SEND_MESSAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case RE_REGISTER_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case RE_REGISTER_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case RE_REGISTER_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DE_REGISTER_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DE_REGISTER_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DE_REGISTER_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_DEVICE_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGroupData: action.result,
      };
    }
    case GET_DEVICE_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_DEVICE_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DEVICE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_DEVICE_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DEVICE_GROUP_BY_DEVICE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DEVICE_GROUP_BY_DEVICE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGrouByDeviceType: action.result,
      };
    }
    case DEVICE_GROUP_BY_DEVICE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case ADD_DEVICE_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DEVICE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_DEVICE_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DEVICE_GROUP_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICE_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGroupById: action.result,
      };
    }
    case GET_DEVICE_GROUP_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_START_MODE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_START_MODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        startMode: action.result,
      };
    }
    case GET_START_MODE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CARD_READER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CARD_READER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCardReaderTypes: action.result,
      };
    }
    case GET_CARD_READER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_DISTINCT_ORDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        distinctOrderTypesList: [],
      };
    }
    case GET_DISTINCT_ORDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        distinctOrderTypesList: action.result,
      };
    }
    case GET_DISTINCT_ORDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_DISTINCT_REVENUE_CENTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        distinctRevenueCentersList: []
      };
    }
    case GET_DISTINCT_REVENUE_CENTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        distinctRevenueCentersList: action.result,
      };
    }
    case GET_DISTINCT_REVENUE_CENTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case TEST_INGENICO_DEVICE_CREDENTIALS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case TEST_INGENICO_DEVICE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case TEST_INGENICO_DEVICE_CREDENTIALS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REGISTER_BULK_DEVICES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case REGISTER_BULK_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REGISTER_BULK_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CARD_READER_PROVISION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case CARD_READER_PROVISION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceData: action.result,
      };
    }
    case CARD_READER_PROVISION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CARD_READER_DE_PROVISION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case CARD_READER_DE_PROVISION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceData: action.result,
      };
    }
    case CARD_READER_DE_PROVISION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_SORT_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_SORT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsSortTypesList: action.result,
      };
    }
    case GET_KDS_SORT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DEVICE_GROUP_BY_ROLE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICE_GROUP_BY_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGroupListByRole: action.result,
      };
    }
    case GET_DEVICE_GROUP_BY_ROLE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGrouByDeviceTypeByRole: action.result,
      };
    }
    case DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default: return state;
  }
};

export const getDevicesByStoreId = (storeId) => {
  return {
    types: [GET_DEVICES_BY_STORE_REQUESTED, GET_DEVICES_BY_STORE_SUCCESS, GET_DEVICES_BY_STORE_FAILURE],
    promise: client => client.get(`getDevicesByStoreId/${storeId}`)
  };
};

export const registerDevice = (data) => {
  return {
    types: [REGISTER_DEVICE_REQUESTED, REGISTER_DEVICE_SUCCESS, REGISTER_DEVICE_FAILURE],
    promise: client => client.post('registerDevice', { data })
  };
};

export const getPrinterTypes = () => {
  return {
    types: [GET_PRINTER_TYPES_REQUESTED, GET_PRINTER_TYPES_SUCCESS, GET_PRINTER_TYPES_FAILURE],
    promise: client => client.get('getPrinterTypes')
  };
};

export const getDeviceById = (deviceId) => {
  return {
    types: [GET_DEVICE_BY_ID_REQUESTED, GET_DEVICE_BY_ID_SUCCESS, GET_DEVICE_BY_ID_FAILURE],
    promise: client => client.get(`getDeviceById/${deviceId}`)
  };
};

export const updateDevice = (data) => {
  return {
    types: [UPDATE_DEVICE_REQUESTED, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE],
    promise: client => client.post('updateDevice', { data })
  };
};

export const reRegisterDevice = (deviceId) => {
  return {
    types: [RE_REGISTER_DEVICE_REQUESTED, RE_REGISTER_DEVICE_SUCCESS, RE_REGISTER_DEVICE_FAILURE],
    promise: client => client.post(`reRegisterDevice/${deviceId}`)
  };
};

export const deRegisterDevice = (deviceId) => {
  return {
    types: [DE_REGISTER_DEVICE_REQUESTED, DE_REGISTER_DEVICE_SUCCESS, DE_REGISTER_DEVICE_FAILURE],
    promise: client => client.post(`deRegisterDevice/${deviceId}`)
  };
};
export const sendMessage = (data) => {
  return {
    types: [SEND_MESSAGE_REQUESTED, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE],
    promise: client => client.post('sendMessage', { data })
  };
};
export const getDeviceGroupList = (storeId) => {
  return {
    types: [GET_DEVICE_GROUP_REQUESTED, GET_DEVICE_GROUP_SUCCESS, GET_DEVICE_GROUP_FAILURE],
    promise: client => client.get(`getDeviceGroupList/${storeId}`)
  };
};
export const deviceGroupDelete = (storeId) => {
  return {
    types: [DELETE_DEVICE_GROUP_REQUESTED, DELETE_DEVICE_GROUP_SUCCESS, DELETE_DEVICE_GROUP_FAILURE],
    promise: client => client.post(`deleteDeviceGroup/${storeId}`)
  };
};

export const deviceGroupbyDeviceType = (storeId, deviceTypeId) => {
  return {
    types: [DEVICE_GROUP_BY_DEVICE_TYPE_REQUESTED, DEVICE_GROUP_BY_DEVICE_TYPE_SUCCESS, DEVICE_GROUP_BY_DEVICE_TYPE_FAILURE],
    promise: client => client.get(`getDeviceGroupList/${storeId}/deviceTypeId/${deviceTypeId}`)
  };
};

export const addDeviceGroup = (data) => {
  return {
    types: [ADD_DEVICE_GROUP_REQUESTED, ADD_DEVICE_GROUP_SUCCESS, ADD_DEVICE_GROUP_FAILURE],
    promise: client => client.post('addDeviceGroup', { data })
  };
};

export const getDeviceGroupById = (deviceId) => {
  return {
    types: [GET_DEVICE_GROUP_BY_ID_REQUESTED, GET_DEVICE_GROUP_BY_ID_SUCCESS, GET_DEVICE_GROUP_BY_ID_FAILURE],
    promise: client => client.get(`getDeviceGroupById/${deviceId}`)
  };
};

export const updateDeviceGroup = (data) => {
  return {
    types: [UPDATE_DEVICE_REQUESTED, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE],
    promise: client => client.post('updateDeviceGroup', { data })
  };
};

export const getStartMode = (deviceTypeId) => {
  return {
    types: [GET_START_MODE_REQUESTED, GET_START_MODE_SUCCESS, GET_START_MODE_FAILURE],
    promise: client => client.get(`getStartMode/${deviceTypeId}/deviceTypeId`)
  };
};

export const getCardReaderTypes = () => {
  return {
    types: [GET_CARD_READER_TYPES_REQUESTED, GET_CARD_READER_TYPES_SUCCESS, GET_CARD_READER_TYPES_FAILURE],
    promise: client => client.get('CardReaderTypes')
  };
};

export const getDistinctOrderTypes = (brandId) => {
  return {
    types: [GET_DISTINCT_ORDER_TYPES_REQUESTED, GET_DISTINCT_ORDER_TYPES_SUCCESS, GET_DISTINCT_ORDER_TYPES_FAILURE],
    promise: client => client.get(`getDistinctOrderTypes/${brandId}`)
  };
};

export const getDistinctRevenueCenters = (brandId) => {
  return {
    types: [GET_DISTINCT_REVENUE_CENTERS_REQUESTED, GET_DISTINCT_REVENUE_CENTERS_SUCCESS, GET_DISTINCT_REVENUE_CENTERS_FAILURE],
    promise: client => client.get(`getDistinctRevenueCenters/${brandId}`)
  };
};

export const testDeviceCardReader = (data) => {
  return {
    types: [TEST_INGENICO_DEVICE_CREDENTIALS_REQUESTED, TEST_INGENICO_DEVICE_CREDENTIALS_SUCCESS, TEST_INGENICO_DEVICE_CREDENTIALS_FAILURE],
    promise: client => client.post('testDeviceCardReader', { data })
  };
};

export const registerBulkDevices = (data) => {
  return {
    types: [REGISTER_BULK_DEVICES_REQUESTED, REGISTER_BULK_DEVICES_SUCCESS, REGISTER_BULK_DEVICES_FAILURE],
    promise: client => client.post('registerBulkDevices', { data })
  };
};

export const cardReaderProvision = (data) => {
  return {
    types: [CARD_READER_PROVISION_REQUESTED, CARD_READER_PROVISION_SUCCESS, CARD_READER_PROVISION_FAILURE],
    promise: client => client.post('cardReaderProvision', { data })
  };
};

export const cardReaderDeProvision = (data) => {
  return {
    types: [CARD_READER_DE_PROVISION_REQUESTED, CARD_READER_DE_PROVISION_SUCCESS, CARD_READER_DE_PROVISION_FAILURE],
    promise: client => client.post('cardReaderDeProvision', { data })
  };
};

export const getKDSSortTypes = () => {
  return {
    types: [GET_KDS_SORT_TYPES_REQUESTED, GET_KDS_SORT_TYPES_SUCCESS, GET_KDS_SORT_TYPES_FAILURE],
    promise: client => client.get('getKDSSortTypes')
  };
};

export const getDeviceGroupListByRole = (storeId) => {
  return {
    types: [GET_DEVICE_GROUP_BY_ROLE_REQUESTED, GET_DEVICE_GROUP_BY_ROLE_SUCCESS, GET_DEVICE_GROUP_BY_ROLE_FAILURE],
    promise: client => client.get(`getDeviceGroupListByRole/${storeId}`)
  };
};

export const deviceGroupbyDeviceTypeByRole = (storeId, deviceTypeId) => {
  return {
    types: [DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_REQUESTED, DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_SUCCESS, DEVICE_GROUP_BY_DEVICE_TYPE_BY_ROLE_FAILURE],
    promise: client => client.get(`getDeviceGroupListByRole/${storeId}/deviceTypeId/${deviceTypeId}`)
  };
};
