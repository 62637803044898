import { map } from 'lodash';

const currentReducer = 'REDUCER_NAME';
const GET_MODGROUPS_BY_MENUITEM_REQUESTED = `${currentReducer}/GET_MODGROUPS_BY_MENUITEM_REQUESTED`;
const GET_MODGROUPS_BY_MENUITEM_SUCCESS = `${currentReducer}/GET_MODGROUPS_BY_MENUITEM_SUCCESS`;
const GET_MODGROUPS_BY_MENUITEM_FAILURE = `${currentReducer}/GET_MODGROUPS_BY_MENUITEM_FAILURE`;

const CLEAR_MODIFIRE_DATA = 'customMenu/CLEAR_MODIFIRE_DATA';
export const UPDATE_MODGROUP_LIST = 'customMenu/UPDATE_MODGROUP_LIST';

export const UPDATE_SAVED_DATA_TO_MODGROUP_LIST = 'customMenu/UPDATE_SAVED_DATA_TO_MODGROUP_LIST';

export const GET_MODGROUPS_LINKS_REQUESTED = 'customMenu/GET_MODGROUPS_LINKS_REQUESTED';
export const GET_MODGROUPS_LINKS_SUCCESS = 'customMenu/GET_MODGROUPS_LINKS_SUCCESS';
export const GET_MODGROUPS_LINKS_FAILURE = 'customMenu/GET_MODGROUPS_LINKS_FAILURE';

export const UPDATE_MODGROUPS_LINKS_REQUESTED = 'customMenu/UPDATE_MODGROUPS_LINKS_REQUESTED';
export const UPDATE_MODGROUPS_LINKS_SUCCESS = 'customMenu/UPDATE_MODGROUPS_LINKS_SUCCESS';
export const UPDATE_MODGROUPS_LINKS_FAILURE = 'customMenu/UPDATE_MODGROUPS_LINKS_FAILURE';

export const GET_SEND_HOLD_TYPES_REQUESTED = 'customMenu/GET_SEND_HOLD_TYPES_REQUESTED';
export const GET_SEND_HOLD_TYPES_SUCCESS = 'customMenu/GET_SEND_HOLD_TYPES_SUCCESS';
export const GET_SEND_HOLD_TYPES_FAILURE = 'customMenu/GET_SEND_HOLD_TYPES_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  modGroupsListByMenuItem: [],
  modGroupsLinks: [],
  sendHoldTypes: [],
};

const objectAssign = (level, id, currentState, newObject) => {
  return map(currentState, (obj) => {
    if (((level % 2) === 0) && (obj.id === id && id === newObject[0].modifierId)) {
      return {
        ...obj,
        modifire: newObject
      };
    }
    if ((obj.id === id) && (id === newObject[0].modifierGroupId)) {
      return {
        ...obj,
        modifire: newObject
      };
    }
    if (obj.modifire && obj.modifire.length > 0) {
      return {
        ...obj,
        modifire: objectAssign(level, id, obj.modifire, newObject)
      };
    }
    return {
      ...obj,
      modifire: []
    };
  });
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MODGROUPS_BY_MENUITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODGROUPS_BY_MENUITEM_SUCCESS: {
      let modifire = [];
      if (action.payload.level !== 0) {
        modifire = action.result.length > 0 ? objectAssign(
          action.payload.level,
          action.payload.id,
          state.modGroupsListByMenuItem,
          action.result
        ) : state.modGroupsListByMenuItem;
      } else {
        modifire = action.result;
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByMenuItem: modifire,
      };
    }
    case GET_MODGROUPS_BY_MENUITEM_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByMenuItem: [],
      };
    }
    case CLEAR_MODIFIRE_DATA: {
      return {
        ...state,
        modGroupsListByMenuItem: [],
      };
    }
    case UPDATE_MODGROUP_LIST: {
      const updatedModGroupList = state.modGroupsListByMenuItem.map((e) => {
        if (e.id === action.modGropuId) {
          return { ...e, menuLinks: action.payload };
        } return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        modGroupsListByMenuItem: updatedModGroupList
      };
    }
    case UPDATE_SAVED_DATA_TO_MODGROUP_LIST: {
      const updatedModGroupList = state.modGroupsListByMenuItem.map((e) => {
        if (e.id === action.targetId) {
          return { ...e, menuLinks: action.payload };
        } return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        modGroupsListByMenuItem: updatedModGroupList
      };
    }
    case GET_MODGROUPS_LINKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODGROUPS_LINKS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsLinks: action.result,
      };
    }
    case GET_MODGROUPS_LINKS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        brandModifiersListLookup: [],
      };
    }
    case UPDATE_MODGROUPS_LINKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MODGROUPS_LINKS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_MODGROUPS_LINKS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_SEND_HOLD_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEND_HOLD_TYPES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        sendHoldTypes: action.result,
      };
    }
    case GET_SEND_HOLD_TYPES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        brandModifiersListLookup: [],
      };
    }
    default:
      return state;
  }
};

export const clearModifires = () => {
  return {
    type: CLEAR_MODIFIRE_DATA,
  };
};

export const getModifires = (id) => {
  return {
    types: [GET_MODGROUPS_BY_MENUITEM_REQUESTED, GET_MODGROUPS_BY_MENUITEM_SUCCESS, GET_MODGROUPS_BY_MENUITEM_FAILURE],
    promise: client => client.get(`getModGroupsBymenuItemId/${id}`),
    payload: { level: 0 }
  };
};

export const getNestedModifires = (id, level) => {
  let url = 'getModGroupsByModifierId';
  if ((level % 2) !== 0) {
    url = 'getModifiersByModGroupId';
  }
  return {
    types: [GET_MODGROUPS_BY_MENUITEM_REQUESTED, GET_MODGROUPS_BY_MENUITEM_SUCCESS, GET_MODGROUPS_BY_MENUITEM_FAILURE],
    promise: client => client.get(`${url}/${id}`),
    payload: { id, level }
  };
};

export const getModifierGroupLinks = (modGroupID) => {
  return {
    types: [GET_MODGROUPS_LINKS_REQUESTED, GET_MODGROUPS_LINKS_SUCCESS, GET_MODGROUPS_LINKS_FAILURE],
    promise: client => client.get(`getModifierGroupLinks/${modGroupID}`)
  };
};

export const updateModifierGroupLinks = (data) => {
  return {
    types: [UPDATE_MODGROUPS_LINKS_REQUESTED, UPDATE_MODGROUPS_LINKS_SUCCESS, UPDATE_MODGROUPS_LINKS_FAILURE],
    promise: client => client.post('updateModifierGroupLinks', {
      data
    })
  };
};

export const updateModGroupList = (data, modGropuId) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_MODGROUP_LIST,
        payload: data,
        modGropuId,
      }
    );
  };
};

export const updateMenuLinkToModGroup = (data, targetId) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_SAVED_DATA_TO_MODGROUP_LIST,
        payload: data,
        targetId,
      }
    );
  };
};

export const getSendHoldTypes = () => {
  return {
    types: [GET_SEND_HOLD_TYPES_REQUESTED, GET_SEND_HOLD_TYPES_SUCCESS, GET_SEND_HOLD_TYPES_FAILURE],
    promise: client => client.get('getSendHoldTypes')
  };
};
