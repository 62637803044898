const currentReducer = 'brandReceiptTemplate';
const GET_RECEIPT_TEMPLATE_REQUESTED = `${currentReducer}/GET_RECEIPT_TEMPLATE_REQUESTED`;
const GET_RECEIPT_TEMPLATE_SUCCESS = `${currentReducer}/GET_RECEIPT_TEMPLATE_SUCCESS`;
const GET_RECEIPT_TEMPLATE_FAILURE = `${currentReducer}/GET_RECEIPT_TEMPLATE_FAILURE`;

export const ADD_RECEIPT_TEMPLATE_REQUESTED = `${currentReducer}/ADD_RECEIPT_TEMPLATE_REQUESTED`;
export const ADD_RECEIPT_TEMPLATE_SUCCESS = `${currentReducer}/ADD_RECEIPT_TEMPLATE_SUCCESS`;
export const ADD_RECEIPT_TEMPLATE_FAILURE = `${currentReducer}/ADD_RECEIPT_TEMPLATE_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  receiptTemplateList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIPT_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_RECEIPT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        receiptTemplateList: action.result
      };
    }
    case GET_RECEIPT_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_RECEIPT_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_RECEIPT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_RECEIPT_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getBrandReceiptTemplateList = (brandId) => {
  return {
    types: [GET_RECEIPT_TEMPLATE_REQUESTED, GET_RECEIPT_TEMPLATE_SUCCESS, GET_RECEIPT_TEMPLATE_FAILURE],
    promise: client => client.get(`getBrandReceiptTemplateList/${brandId}`)
  };
};

export const updateBrandReceiptTemaplate = (data) => {
  return {
    types: [ADD_RECEIPT_TEMPLATE_REQUESTED, ADD_RECEIPT_TEMPLATE_SUCCESS, ADD_RECEIPT_TEMPLATE_FAILURE],
    promise: client => client.post('updateBrandReceiptTemaplate', { data })
  };
};
