import { getSurveyId } from '@utility/storageOperations';

const defaultHeaderState = {
  headerBar: {
    show: false,
    tittle: 'Dashboard'
  },
  menu: {
    type: 1,
    controlUrl: ''
  },
  closeButton: {
    type: null,
    controlUrl: ''
  },
  restaurantInfo: {
    show: false
  }
};

export default (onUrl) => {
  let url = '';
  if (onUrl.includes('/company/update')) {
    url = '/company/update';
  } else if (onUrl.includes('/mall/update')) {
    url = '/mall/update';
  } else if (onUrl.includes('brand/update')) {
    url = '/brand/update';
  } else if (onUrl.includes('store/update')) {
    url = '/store/update';
  } else if (onUrl.includes('store/guestApp')) {
    url = '/store/guestApp';
  } else if (onUrl.includes('store/serverApp')) {
    url = '/store/serverApp';
  } else if (onUrl.includes('store/tables')) {
    url = '/store/tables';
  } else if (onUrl.includes('store/batchServices')) {
    url = '/store/batchServices';
  } else if (onUrl.includes('store/paymentSystem')) {
    url = '/store/paymentSystem';
  } else if (onUrl.includes('store/bookingSystem')) {
    url = '/store/bookingSystem';
  } else if (onUrl.includes('store/posSystem')) {
    url = '/store/posSystem';
  } else if (onUrl.includes('store/paypal')) {
    url = '/store/paypal';
  } else if (onUrl.includes('store/stripe')) {
    url = '/store/stripe';
  } else if (onUrl.includes('store/spreedly')) {
    url = '/store/spreedly';
  } else if (onUrl.includes('user/update')) {
    url = '/user/update';
  } else if (onUrl.includes('brand/offerSystem')) {
    url = '/brand/offerSystem';
  } else if (onUrl.includes('device/update')) {
    url = '/device/update';
  } else if (onUrl.includes('brand/survey/update')) {
    url = '/brand/survey/update';
  } else if (onUrl.includes('survey/question/update')) {
    url = '/survey/question/update';
  } else if (onUrl.includes('receipt/display')) {
    url = '/receipt/display';
  } else if (onUrl.includes('receipt/giftCard/display')) {
    url = '/receipt/giftCard/display';
  } else if (onUrl.includes('apiLogsDetails')) {
    url = '/apiLogsDetails';
  } else if (onUrl.includes('brand/sensorGroups')) {
    url = '/brand/sensorGroups';
  } else if (onUrl.includes('store/sensor-assignment')) {
    url = '/store/sensor-assignment';
  } else if (onUrl.includes('store/jobs/edit')) {
    url = '/store/jobs/edit';
  } else if (onUrl.includes('store/section')) {
    url = '/store/section';
  } else if (onUrl.includes('store/jobsetup')) {
    url = '/store/jobsetup';
  } else if (onUrl.includes('reports/checkdetail')) {
    url = '/reports/checkdetail';
  } else if (onUrl.includes('customMenu/jobs')) {
    url = '/customMenu/jobs';
  } else if (onUrl.includes('store/hours')) {
    url = '/store/hours';
  } else if (onUrl.includes('store/drawer')) {
    url = '/store/drawer';
  } else if (onUrl.includes('brand/holiday/update')) {
    url = '/brand/holiday/update';
  } else if (onUrl.includes('brand/prepTime/list')) {
    url = '/brand/prepTime/list';
  } else if (onUrl.includes('brand/prepTime/update')) {
    url = '/brand/prepTime/update';
  } else if (onUrl.includes('/tms/tags')) {
    url = '/tms/tags';
  } else if (onUrl.includes('/brand/storeOffPremiseSetup')) {
    url = '/brand/storeOffPremiseSetup';
  } else if (onUrl.includes('brand/menuItems')) {
    url = '/brand/menuItems';
  } else if (onUrl.includes('/brand/tax/list')) {
    url = '/brand/tax/list';
  } else if (onUrl.includes('/brand/tax/add')) {
    url = '/brand/tax/add';
  } else if (onUrl.includes('/brand/tax/update')) {
    url = '/brand/tax/update';
  } else if (onUrl.includes('brand/config-channel')) {
    url = '/brand/config-channel';
  } else if (onUrl.includes('brand/menuItems')) {
    url = '/brand/menuItems';
  } else if (onUrl.includes('brand/modifierGroups')) {
    url = '/brand/modifierGroups';
  } else if (onUrl.includes('brand/modifiers')) {
    url = '/brand/modifiers';
  } else if (onUrl.includes('brand/menu/location-setup')) {
    url = '/brand/menu/location-setup';
  } else if (onUrl.includes('brand/menu/merge/location-setup')) {
    url = '/brand/menu/merge/location-setup';
  } else if (onUrl.includes('brand/modifier/location-setup')) {
    url = '/brand/modifier/location-setup';
  } else if (onUrl.includes('brand/modifier/merge/location-setup')) {
    url = '/brand/modifier/merge/location-setup';
  } else if (onUrl.includes('store/mobilePaymentSetup')) {
    url = '/store/mobilePaymentSetup';
  } else if (onUrl.includes('brand/paytronixLoyalty')) {
    url = '/brand/paytronixLoyalty';
  } else if (onUrl.includes('brand/paytronixGiftCard')) {
    url = '/brand/paytronixGiftCard';
  } else if (onUrl.includes('brand/svs')) {
    url = '/brand/svs';
  } else if (onUrl.includes('brand/punchh')) {
    url = '/brand/punchh';
  } else if (onUrl.includes('brand/spendgo')) {
    url = '/brand/spendgo';
  } else if (onUrl.includes('brand/tattle')) {
    url = '/brand/tattle';
  } else if (onUrl.includes('brand/fishbowl')) {
    url = '/brand/fishbowl';
  } else if (onUrl.includes('brand/deliverySetup')) {
    url = '/brand/deliverySetup';
  } else if (onUrl.includes('brand/pnp')) {
    url = '/brand/pnp';
  } else if (onUrl.includes('brand/heartland')) {
    url = '/brand/heartland';
  } else if (onUrl.includes('brand/givex')) {
    url = '/brand/givex';
  } else if (onUrl.includes('brand/fiserv')) {
    url = '/brand/fiserv';
  } else if (onUrl.includes('store/ingenico')) {
    url = '/store/ingenico';
  } else if (onUrl.includes('store/cardconnectbolt')) {
    url = '/store/cardconnectbolt';
  } else if (onUrl.includes('/brand/tiles/update')) {
    url = '/brand/tiles/update';
  } else if (onUrl.includes('/brand/campaign/list')) {
    url = '/brand/campaign/list';
  } else if (onUrl.includes('/brand/campaign/update')) {
    url = '/brand/campaign/update';
  } else if (onUrl.includes('/brand/campaign/location')) {
    url = '/brand/campaign/location';
  } else if (onUrl.includes('/brand/delivery/list')) {
    url = '/brand/delivery/list';
  } else if (onUrl.includes('/brand/charges/list')) {
    url = '/brand/charges/list';
  } else if (onUrl.includes('/brand/tips/list')) {
    url = '/brand/tips/list';
  } else if (onUrl.includes('/brand/omsoffers/list')) {
    url = '/brand/omsoffers/list';
  } else if (onUrl.includes('/brand/charity/list')) {
    url = '/brand/charity/list';
  } else if (onUrl.includes('/brand/charity/update')) {
    url = '/brand/charity/update';
  } else if (onUrl.includes('/brand/delivery/add')) {
    url = '/brand/delivery/add';
  } else if (onUrl.includes('/brand/tips/add')) {
    url = '/brand/tips/add';
  } else if (onUrl.includes('/brand/delivery/update')) {
    url = '/brand/delivery/update';
  } else if (onUrl.includes('/brand/tips/update')) {
    url = '/brand/tips/update';
  } else if (onUrl.includes('/store/employees/update')) {
    url = '/store/employees/update';
  } else if (onUrl.includes('/store/employees/add')) {
    url = '/store/employees/add';
  } else if (onUrl.includes('/store/tables/add')) {
    url = '/store/tables/add';
  } else if (onUrl.includes('/store/tables/list')) {
    url = '/store/tables/list';
  } else if (onUrl.includes('/store/categories')) {
    url = '/store/categories';
  } else if (onUrl.includes('/store/discounts')) {
    url = '/store/discounts';
  } else if (onUrl.includes('/store/orderTypes')) {
    url = '/store/orderTypes';
  } else if (onUrl.includes('/store/revenueCenters')) {
    url = '/store/revenueCenters';
  } else if (onUrl.includes('/store/tenderTypes')) {
    url = '/store/tenderTypes';
  } else if (onUrl.includes('/menubrowser/menu/location-setup')) {
    url = '/menubrowser/menu/location-setup';
  } else if (onUrl.includes('brand/customMenu/menu/location-setup')) {
    url = 'brand/customMenu/menu/location-setup';
  } else if (onUrl.includes('brand/customMenu/modifier/location-setup')) {
    url = 'brand/customMenu/modifier/location-setup';
  } else if (onUrl.includes('store/namedSensor')) {
    url = '/store/namedSensor';
  } else if (onUrl.includes('store/offPremiseSensors')) {
    url = '/store/offPremiseSensors';
  } else if (onUrl.includes('brand/customMenu/menu/merge/location-setup')) {
    url = 'brand/customMenu/menu/merge/location-setup';
  } else if (onUrl.includes('brand/customMenu/modifier/merge/location-setup')) {
    url = 'brand/customMenu/modifier/merge/location-setup';
  } else if (onUrl.includes('store/storeNamedSensor/add')) {
    url = '/store/storeNamedSensor/add';
  } else if (onUrl.includes('store/storeNamedSensor/edit')) {
    url = '/store/storeNamedSensor/edit';
  } else if (onUrl.includes('store/offPremiseSensor/add')) {
    url = '/store/offPremiseSensor/add';
  } else if (onUrl.includes('store/offPremiseSensor/edit')) {
    url = '/store/offPremiseSensor/edit';
  } else if (onUrl.includes('brand/sensorGroups')) {
    url = '/brand/sensorGroups';
  } else if (onUrl.includes('store/venueNamedSensor/add')) {
    url = '/store/venueNamedSensor/add';
  } else if (onUrl.includes('store/venueNamedSensor/edit')) {
    url = '/store/venueNamedSensor/edit';
  } else if (onUrl.includes('/mdm/policy')) {
    url = '/mdm/policy';
  } else if (onUrl.includes('/device/mdm/list')) {
    url = '/device/mdm/list';
  } else if (onUrl.includes('table-magement/channel/setup')) {
    url = 'table-magement/channel/setup';
  } else if (onUrl.includes('/brand/heartland')) {
    url = '/brand/heartland/';
  } else if (onUrl.includes('/sparkfly/setup')) {
    url = '/sparkfly/setup';
  } else if (onUrl.includes('/admin/tags')) {
    url = '/admin/tags';
  } else if (onUrl.includes('/releaseNotes/list')) {
    url = '/releaseNotes/list';
  } else if (onUrl.includes('/system/notifications/add')) {
    url = '/system/notifications/add';
  } else if (onUrl.includes('/system/notifications/update')) {
    url = '/system/notifications/update';
  } else if (onUrl.includes('/system/notifications/clone')) {
    url = '/system/notifications/clone';
  } else if (onUrl.includes('/system/notifications')) {
    url = '/system/notifications';
  } else if (onUrl.includes('/releaseNotes/add')) {
    url = '/releaseNotes/add';
  } else if (onUrl.includes('/releaseNotes/update')) {
    url = '/releaseNotes/update';
  } else if (onUrl.includes('/warehouse-reports/checkDetailes')) {
    url = '/warehouse-reports/checkDetailes';
  } else if (onUrl.includes('/brand/theme')) {
    url = '/brand/theme';
  } else if (onUrl.includes('/customMenu/menuLinks')) {
    url = '/customMenu/menuLinks';
  } else if (onUrl.includes('/brand/offerForm/update')) {
    url = '/brand/offerForm/update';
  } else if (onUrl.includes('/brand/batchServices')) {
    url = '/brand/batchServices';
  } else if (onUrl.includes('/brand/sensorGroup/update')) {
    url = '/brand/sensorGroup/update';
  } else if (onUrl.includes('/brand/segment/list')) {
    url = '/brand/segment/list';
  } else if (onUrl.includes('/brand/import/guests')) {
    url = '/brand/import/guests';
  } else if (onUrl.includes('/brand/segment/add')) {
    url = '/brand/segment/add';
  } else if (onUrl.includes('/brand/segment/update')) {
    url = '/brand/segment/update';
  } else if (onUrl.includes('/brand/segment/preview')) {
    url = '/brand/segment/preview';
  } else if (onUrl.includes('/brand/smsTemplates/list')) {
    url = '/brand/smsTemplates/list';
  } else if (onUrl.includes('/brand/smsTemplates/add')) {
    url = '/brand/smsTemplates/add';
  } else if (onUrl.includes('/brand/smsTemplates/update')) {
    url = '/brand/smsTemplates/update';
  } else if (onUrl.includes('/brand/campaignOccurrence/list')) {
    url = '/brand/campaignOccurrence/list';
  } else if (onUrl.includes('/brand/campaignTriggers/list')) {
    url = '/brand/campaignTriggers/list';
  } else if (onUrl.includes('/brand/campaignOccurrence/update')) {
    url = '/brand/campaignOccurrence/update';
  } else if (onUrl.includes('/brand/campaignTriggers/update')) {
    url = '/brand/campaignTriggers/update';
  } else if (onUrl.includes('/brand/emailTemplates/list')) {
    url = '/brand/emailTemplates/list';
  } else if (onUrl.includes('/brand/external/campaigns')) {
    url = '/brand/external/campaigns';
  } else if (onUrl.includes('/brand/emailTemplate/add')) {
    url = '/brand/emailTemplate/add';
  } else if (onUrl.includes('/brand/emailTemplate/update')) {
    url = '/brand/emailTemplate/update';
  } else if (onUrl.includes('/brand/emailTemplates/emailEditor')) {
    url = '/brand/emailTemplates/emailEditor';
  } else if (onUrl.includes('/customMenu/category/list')) {
    url = '/customMenu/category/list';
  } else if (onUrl.includes('/customMenu/category/add')) {
    url = '/customMenu/category/add';
  } else if (onUrl.includes('/customMenu/category/edit')) {
    url = '/customMenu/category/edit';
  } else if (onUrl.includes('/brand/restaurantMagic')) {
    url = '/brand/restaurantMagic';
  } else if (onUrl.includes('/brand/charges/update')) {
    url = '/brand/charges/update';
  } else if (onUrl.includes('/brand/chowly')) {
    url = '/brand/chowly';
  } else if (onUrl.includes('/brand/otter')) {
    url = '/brand/otter';
  } else if (onUrl.includes('/brand/happyCog')) {
    url = '/brand/happyCog';
  } else if (onUrl.includes('/brand/printCategory/list')) {
    url = '/brand/printCategory/list';
  } else if (onUrl.includes('/brand/printCategory/add')) {
    url = '/brand/printCategory/add';
  } else if (onUrl.includes('/brand/printCategory/update')) {
    url = '/brand/printCategory/update';
  } else if (onUrl.includes('/data-request/list')) {
    url = '/data-request/list';
  } else if (onUrl.includes('/data-request/edit')) {
    url = '/data-request/edit';
  } else if (onUrl.includes('/admin/posAgent/statusList')) {
    url = '/admin/posAgent/statusList';
  } else if (onUrl.includes('/brand/serverApp/theme')) {
    url = '/brand/serverApp/theme';
  } else if (onUrl.includes('/brand/kiosk/theme')) {
    url = '/brand/kiosk/theme';
  } else if (onUrl.includes('/brand/tableTop/theme')) {
    url = '/brand/tableTop/theme';
  } else if (onUrl.includes('/brand/orderUp/theme')) {
    url = '/brand/orderUp/theme';
  } else if (onUrl.includes('/brand/voucher/update')) {
    url = '/brand/voucher/update';
  } else if (onUrl.includes('/brand/deviceGroup/list')) {
    url = '/brand/deviceGroup/list';
  } else if (onUrl.includes('/brand/deviceGroup/add')) {
    url = '/brand/deviceGroup/add';
  } else if (onUrl.includes('/brand/deviceGroup/update')) {
    url = '/brand/deviceGroup/update';
  } else if (onUrl.includes('/admin/editLanguages/serverApp')) {
    url = '/admin/editLanguages/serverApp';
  } else if (onUrl.includes('/admin/editLanguages/mobileWeb')) {
    url = '/admin/editLanguages/mobileWeb';
  } else if (onUrl.includes('/admin/editLanguages/kiosk')) {
    url = '/admin/editLanguages/kiosk';
  } else if (onUrl.includes('/admin/editLanguages/tableTop')) {
    url = '/admin/editLanguages/tableTop';
  } else if (onUrl.includes('/admin/editLanguages/orderUp')) {
    url = '/admin/editLanguages/orderUp';
  } else if (onUrl.includes('/admin/editLanguages/kds')) {
    url = '/admin/editLanguages/kds';
  } else if (onUrl.includes('/admin/editLanguages/waitList')) {
    url = '/admin/editLanguages/waitList';
  } else if (onUrl.includes('/brand/languages/mobileApp/edit')) {
    url = '/brand/languages/mobileApp/edit';
  } else if (onUrl.includes('/brand/productionRate')) {
    url = '/brand/productionRate';
  } else if (onUrl.includes('/brand/languages/serverApp/edit')) {
    url = '/brand/languages/serverApp/edit';
  } else if (onUrl.includes('/brand/languages/kds/edit')) {
    url = '/brand/languages/kds/edit';
  } else if (onUrl.includes('/brand/languages/kiosk/edit')) {
    url = '/brand/languages/kiosk/edit';
  } else if (onUrl.includes('/brand/languages/tableTop/edit')) {
    url = '/brand/languages/tableTop/edit';
  } else if (onUrl.includes('/brand/languages/orderUp/edit')) {
    url = '/brand/languages/orderUp/edit';
  } else if (onUrl.includes('/brand/languages/waitList/edit')) {
    url = '/brand/languages/waitList/edit';
  } else if (onUrl.includes('/admin/languages')) {
    url = '/admin/languages';
  } else if (onUrl.includes('/brand/languages/mobileApp')) {
    url = '/brand/languages/mobileApp';
  } else if (onUrl.includes('/brand/languages/serverApp')) {
    url = '/brand/languages/serverApp';
  } else if (onUrl.includes('/brand/languages/kds')) {
    url = '/brand/languages/kds';
  } else if (onUrl.includes('/brand/languages/waitList')) {
    url = '/brand/languages/waitList';
  } else if (onUrl.includes('/brand/languages/kiosk')) {
    url = '/brand/languages/kiosk';
  } else if (onUrl.includes('/brand/languages/tableTop')) {
    url = '/brand/languages/tableTop';
  } else if (onUrl.includes('/brand/languages/orderUp')) {
    url = '/brand/languages/orderUp';
  } else if (onUrl.includes('/cubby/list')) {
    url = '/cubby/list';
  } else if (onUrl.includes('/cubby/add')) {
    url = '/cubby/add';
  } else if (onUrl.includes('/cubby/slot/add')) {
    url = '/cubby/slot/add';
  } else if (onUrl.includes('/cubby/update')) {
    url = '/cubby/update';
  } else if (onUrl.includes('/brand/wiselyWebhooks')) {
    url = '/brand/wiselyWebhooks';
  } else if (onUrl.includes('/brand/spendgoWebhooks')) {
    url = '/brand/spendgoWebhooks';
  } else if (onUrl.includes('/brand/survey/questionUpdate')) {
    url = '/brand/survey/questionUpdate';
  } else if (onUrl.includes('/brand/survey/surveyQuestionUpdate')) {
    url = '/brand/survey/surveyQuestionUpdate';
  } else if (onUrl.includes('/brand/manageStore')) {
    url = '/brand/manageStore';
  } else if (onUrl.includes('/brand/multiLingual')) {
    url = '/brand/multiLingual';
  } else if (onUrl.includes('/empApp/bank')) {
    url = '/empApp/bank';
  } else if (onUrl.includes('/empApp/reloadBank')) {
    url = '/empApp/reloadBank';
  } else if (onUrl.includes('/empApp/cashDrop')) {
    url = '/empApp/cashDrop';
  } else if (onUrl.includes('/brand/riskRule/add')) {
    url = '/brand/riskRule/add';
  } else if (onUrl.includes('/brand/riskRule/update')) {
    url = '/brand/riskRule/update';
  } else if (onUrl.includes('store/teams')) {
    url = '/store/teams';
  } else if (onUrl.includes('brand/siftFraudSystem')) {
    url = '/brand/siftFraudSystem';
  } else if (onUrl.includes('brand/rokt')) {
    url = '/brand/rokt';
  } else if (onUrl.includes('brand/medallia')) {
    url = '/brand/medallia';
  } else if (onUrl.includes('/brand/marketMan')) {
    url = '/brand/marketMan';
  } else if (onUrl.includes('/brand/valueTec')) {
    url = '/brand/valueTec';
  } else if (onUrl.includes('/brand/tsdGlobal')) {
    url = '/brand/tsdGlobal';
  } else if (onUrl.includes('/store/storeMappings')) {
    url = '/store/storeMappings';
  } else if (onUrl.includes('/store/tenderTypeMappings')) {
    url = '/store/tenderTypeMappings';
  } else if (onUrl.includes('/store/offPremiseMappings')) {
    url = '/store/offPremiseMappings';
  } else if (onUrl.includes('/store/posSmsMappings')) {
    url = '/store/posSmsMappings';
  } else if (onUrl.includes('brand/menus/course')) {
    url = '/brand/menus/course';
  } else if (onUrl.includes('/mall/stores')) {
    url = '/mall/stores';
  } else if (onUrl.includes('brand/userWay')) {
    url = '/brand/userWay';
  } else if (onUrl.includes('/store/serviceCharges')) {
    url = '/store/serviceCharges';
  } else if (onUrl.includes('/store/cabbagePay')) {
    url = '/store/cabbagePay';
  } else if (onUrl.includes('/brand/marketPlace')) {
    url = '/brand/marketPlace';
  } else if (onUrl.includes('/brand/menus/happyHour')) {
    url = '/brand/menus/happyHour';
  } else if (onUrl.includes('/brand/kds/stations/add')) {
    url = '/brand/kds/stations/add';
  } else if (onUrl.includes('/brand/kds/stations/update')) {
    url = '/brand/kds/stations/update';
  } else if (onUrl.includes('/brand/kds/layouts/add')) {
    url = '/brand/kds/layouts/add';
  } else if (onUrl.includes('/brand/kds/layouts/update')) {
    url = '/brand/kds/layouts/update';
  } else if (onUrl.includes('/store/amazonPay')) {
    url = '/store/amazonPay';
  } else if (onUrl.includes('/brand/kds/theme')) {
    url = '/brand/kds/theme';
  } else if (onUrl.includes('/brand/kds/layouts/rules')) {
    url = '/brand/kds/layouts/rules';
  } else if (onUrl.includes('/brand/mobileApp/media/list')) {
    url = '/brand/mobileApp/media';
  } else if (onUrl.includes('/brand/mobileApp/media/add')) {
    url = '/brand/mobileApp/media/add';
  } else if (onUrl.includes('/brand/mobileApp/media/update')) {
    url = '/brand/mobileApp/media/update';
  } else if (onUrl.includes('/externalIntegration/list')) {
    url = '/externalIntegration/list';
  } else if (onUrl.includes('/brand/mobileApp/page/list')) {
    url = '/brand/mobileApp/page/list';
  } else if (onUrl.includes('/brand/mobileApp/page/add')) {
    url = '/brand/mobileApp/page/add';
  } else if (onUrl.includes('/brand/mobileApp/page/update')) {
    url = '/brand/mobileApp/page/update';
  } else if (onUrl.includes('/company/api&webhooks')) {
    url = '/company/api&webhooks';
  } else if (onUrl.includes('/brand/mobileApp/layouts/list')) {
    url = '/brand/mobileApp/layouts/list';
  } else if (onUrl.includes('/brand/mobileApp/layouts/add')) {
    url = '/brand/mobileApp/layouts/add';
  } else if (onUrl.includes('/brand/mobileApp/layouts/update')) {
    url = '/brand/mobileApp/layouts/update';
  } else if (onUrl.includes('store/nmiPayments')) {
    url = '/store/nmiPayments';
  } else if (onUrl.includes('/brand/mobileApp/theme')) {
    url = '/brand/mobileApp/theme';
  } else if (onUrl.includes('/brand/waitList/theme')) {
    url = '/brand/waitList/theme';
  } else if (onUrl.includes('store/paymentSense')) {
    url = '/store/paymentSense';
  } else if (onUrl.includes('/brand/mobileApp/layouts/design')) {
    url = '/brand/mobileApp/layouts/design';
  } else if (onUrl.includes('/company/externalIntegration/identifiers')) {
    url = '/company/externalIntegration/identifiers';
  } else if (onUrl.includes('/brand/mobileApp/page/design')) {
    url = '/brand/mobileApp/page/design';
  } else if (onUrl.includes('/brand/kds/print/add')) {
    url = '/brand/kds/print/add';
  } else if (onUrl.includes('/brand/kds/print/update')) {
    url = '/brand/kds/print/update';
  } else if (onUrl.includes('/brand/kds/print/rules')) {
    url = '/brand/kds/print/rules';
  } else if (onUrl.includes('brand/intercard')) {
    url = '/brand/intercard';
  } else if (onUrl.includes('brand/powercard')) {
    url = '/brand/powercard';
  } else if (onUrl.includes('brand/offer/list')) {
    url = '/brand/offer/list';
  } else if (onUrl.includes('brand/giftcard')) {
    url = '/brand/giftcard';
  } else if (onUrl.includes('/brand/ezCater')) {
    url = '/brand/ezCater';
  } else if (onUrl.includes('brand/posSystemConfig')) {
    url = '/brand/posSystemConfig';
  } else if (onUrl.includes('brand/clutchLoyalty')) {
    url = '/brand/clutchLoyalty';
  } else {
    url = onUrl;
  }

  switch (url) {
    case '/':
    case '/login':
    case '/forgotpassword':
      return {
        ...defaultHeaderState
      };
    case '/dashboard':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
      };
    // for v4
    case '/home':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
      };
    case '/company/list':
    case '/mall/list':
    case '/store/list':
    case '/brand/list':
    case '/user/list':
    case '/survey/list':
    case '/brand/delivery/list':
    case '/brand/charges/list':
    case '/brand/campaign/list':
    case '/brand/tips/list':
    case '/brand/omsoffers/list':
    case '/brand/charity/list':
    case '/device/list':
    case '/posMenu/list':
    case '/customMenu/list':
    case '/menubrowser/list':
    case '/store/namedSensor':
    case '/brand/tileSettings':
    case '/brand/localization':
    case '/device/mdm/list':
    case 'table-magement/channel/setup':
    case '/sparkly/setup':
    case '/admin/tags':
    case '/whats-new':
    case '/releaseNotes/list':
    case '/data-request/list':
    case '/system/notifications':
    case '/store/otherSettings':
    case '/store/offPremiseSensors':
    case '/brand/segment/list':
    case '/brand/smsTemplates/list':
    case '/brand/campaignOccurrence/list':
    case '/brand/campaignTriggers/list':
    case '/customMenu/category/list':
    case '/brand/emailTemplates/list':
    case '/brand/external/campaigns':
    case '/admin/posAgent/console':
    case '/brand/deviceGroup/list':
    case '/admin/languages':
    case '/brand/language/list':
    case '/brand/productionRate':
    case '/brand/languages/kiosk':
    case '/brand/languages/mobileApp':
    case '/brand/languages/serverApp':
    case '/brand/languages/tableTop':
    case '/brand/languages/orderUp':
    case '/brand/languages/waitList':
    case '/cubby/list':
    case '/brand/survey/questionLibrary':
    case '/brand/survey/list':
    case '/applePayVerification':
    case '/brand/kds/settings':
    case '/brand/kds/stations/list':
    case '/brand/kds/layouts/list':
    case '/brand/languages/kds':
    case '/company/api&webhooks':
    case '/brand/mobileApp/theme':
    case '/company/externalIntegration/identifiers':
    case '/brand/kds/print/list':
    case '/brand/import/guests':
    case '/brand/offer/list':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/company/update':
    case '/company/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/company/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/customMenu/category/add':
    case '/customMenu/category/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/customMenu/category/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/mall/update':
    case '/mall/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/mall/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/tiles/add':
    case '/brand/tiles/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/tiles'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/smsTemplates/add':
    case '/brand/smsTemplates/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/smsTemplates/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/emailTemplate/add':
    case '/brand/emailTemplate/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/emailTemplates/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/deviceGroup/add':
    case '/brand/deviceGroup/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        newMenu: {
          type: 2,
          controlUrl: '/brand/deviceGroup/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/offerSystem':
    case '/brand/storeOffPremiseSetup':
    case '/brand/config-channel':
    case '/brand/svs':
    case '/brand/punchh':
    case '/brand/paytronixLoyalty':
    case '/brand/paytronixGiftCard':
    case '/brand/spendgo':
    case '/brand/fishbowl':
    case '/brand/deliverySetup':
    case '/brand/pnp':
    case '/brand/heartland':
    case '/brand/tiles':
    case '/brand/offers/list':
    case '/brand/holidays/list':
    case '/brand/prepTime/list':
    case '/brand/tags':
    case '/brand/restaurantMagic':
    case '/brand/givex':
    case '/brand/fiserv':
    case '/brand/tattle':
    case '/brand/chowly':
    case '/brand/otter':
    case '/brand/printCategory/list':
    case '/brand/tax/list':
    case '/brand/vouchers/list':
    case '/brand/wiselyWebhooks':
    case '/brand/spendgoWebhooks':
    case '/brand/riskRule/list':
    case '/brand/siftFraudSystem':
    case '/brand/rokt':
    case '/brand/medallia':
    case '/brand/marketMan':
    case '/brand/valueTec':
    case '/brand/tsdGlobal':
    case '/brand/userWay':
    case '/brand/marketPlace':
    case '/brand/mobileApp/media':
    case '/brand/mobileApp/pages':
    case '/brand/mobileApp/images':
    case '/brand/mobileApp/page/list':
    case '/brand/happyCog':
    case '/brand/mobileApp/layouts/list':
    case '/brand/intercard':
    case '/brand/powercard':
    case '/brand/giftcard':
    case '/brand/ezCater':
    case '/brand/posSystemConfig':
    case '/brand/clutchLoyalty':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/offerForm/add':
    case '/brand/offerForm/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/offers/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/tax/add':
    case '/brand/tax/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/tax/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/mobileApp/media/add':
    case '/brand/mobileApp/media/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/mobileApp/media/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/user/update':
    case '/user/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/user/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/profile/basicInfo':
      return {
        headerBar: {
          show: true,
          tittle: 'Profile'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/profile/changePassword':
      return {
        headerBar: {
          show: true,
          tittle: 'Change Password'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/guestApp':
    case '/store/serverApp':
    case '/store/storeMappings':
    case '/store/tenderTypeMappings':
    case '/store/offPremiseMappings':
    case '/store/posSmsMappings':
    case '/store/section':
    case '/store/tables':
    case '/store/batchServices':
    case '/store/paymentSystem':
    case '/store/bookingSystem':
    case '/store/posSystem':
    case '/store/hours':
    case '/store/drawer':
    case '/store/mobilePaymentSetup':
    case '/store/ingenico':
    case '/store/cardconnectbolt':
    case '/store/paypal':
    case '/store/stripe':
    case '/store/spreedly':
    case '/store/teams':
    case '/store/cabbagePay':
    case '/store/amazonPay':
    case '/store/nmiPayments':
    case '/store/paymentSense':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/holiday/add':
    case '/brand/holiday/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/holidays/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/riskRule/add':
    case '/brand/riskRule/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/riskRule/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/prepTime/add':
    case '/brand/prepTime/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/prepTime/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/data-request/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/data-request/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/printCategory/add':
    case '/brand/printCategory/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/printCategory/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/update':
    case '/brand/add':
    case '/brand/heartland/':
    case '/brand/theme':
    case '/brand/serverApp/theme':
    case '/brand/kiosk/theme':
    case '/brand/tableTop/theme':
    case '/brand/orderUp/theme':
    case '/brand/batchServices':
    case '/brand/kds/theme':
    case '/brand/waitList/theme':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/add':
    case '/store/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/survey/questionAdd':
    case '/brand/survey/questionUpdate':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/survey/questionLibrary'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/delivery/add':
    case '/brand/delivery/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/delivery/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/segment/add':
    case '/brand/segment/update':
    case '/brand/segment/preview':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/segment/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/system/notifications/add':
    case '/system/notifications/update':
    case '/system/notifications/clone':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/system/notifications'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/tips/add':
    case '/brand/tips/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/tips/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/charity/add':
    case '/brand/charity/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/charity/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/tips/rule/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/tips/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/campaign/add':
    case '/brand/campaign/update':
    case '/brand/campaign/location':

      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/campaign/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/campaignOccurrence/add':
    case '/brand/campaignOccurrence/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/campaignOccurrence/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/campaignTriggers/add':
    case '/brand/campaignTriggers/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/campaignTriggers/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/survey/add':
    case '/brand/survey/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/survey/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/survey/surveyQuestionAdd':
    case '/brand/survey/surveyQuestionUpdate':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: `/brand/survey/update/${getSurveyId()}`
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/reports/batch':
    case '/reports/preAuth':
    case '/reports/batchsummary':
    case '/reports/transactions':
    case '/reports/availabilityReport':
    case '/reports/reconciliation':
    case '/reports/survey':
    case '/reports/sensors':
    case '/reports/check':
    case '/reports/adminTransactions':
    case '/reports/sales':
    case '/reports/guest':
    case '/reports/order':
    case '/reports/advanceOrder':
    case '/reports/orderDistribution':
    case '/reports/payment-adjustment':
    case '/reports/adminBatchDetail':
    case '/warehouse-reports/batch':
    case '/warehouse-reports/transaction':
    case '/warehouse-reports/check':
    case '/warehouse-reports/rolling-sales':
    case '/warehouse-reports/item-sales':
    case '/reports/dispatchTip':
    case '/reports/marketplace-orders':
    {
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    }
    case '/device/register':
    case '/device/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/device/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/sensorGroups':
    case '/mdm/policy':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/tms/tags':
      // case '/tms/tags':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/jobs':
    case '/store/employees':
    case '/store/categories':
    case '/store/discounts':
    case '/store/orderTypes':
    case '/store/revenueCenters':
    case '/store/tenderTypes':
    case '/store/serviceCharges':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/jobs/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/jobs'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/receipt/display':
      return {
        headerBar: {
          show: false,
          tittle: ''
        },
        menu: {
          type: 2,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/receipt/giftCard/display':
      return {
        headerBar: {
          show: false,
          tittle: ''
        },
        menu: {
          type: 2,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/sensor-assignment':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/jobsetup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/posMenu/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/reports/checkdetail':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/reports/check'
        },
        closeButton: {
          type: 3,
          controlUrl: '/reports/check'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/customMenu/jobs':
    case '/customMenu/menuLinks':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/customMenu/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/menuItems':
    case '/brand/modifierGroups':
    case '/brand/modifiers':
    case '/brand/menus/course':
    case '/brand/menus/happyHour':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store-manaager/phone':
    case '/store-manaager/text-to-pay':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/manager/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/manager/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/employees/update':
    case '/store/employees/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/employees'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/tables/update':
    case '/store/tables/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/tables/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/menu/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/menuItems',
          loadFromCache: true
        },
        closeButton: {
          type: 3,
          controlUrl: '/brand/menuItems'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/menu/merge/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/menuItems',
          loadFromCache: true
        },
        closeButton: {
          type: 3,
          controlUrl: '/brand/menuItems'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/modifier/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/modifiers',
          loadFromCache: true
        },
        closeButton: {
          type: 3,
          controlUrl: '/brand/modifiers'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/modifier/merge/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/modifiers',
          loadFromCache: true
        },
        closeButton: {
          type: 3,
          controlUrl: '/brand/modifiers'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/menubrowser/menu/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/menubrowser/list',
        },
        closeButton: {
          type: 3,
          controlUrl: '/menubrowser/list'
        },
        restaurantInfo: {
          show: false
        }
      };
    case 'brand/customMenu/menu/location-setup':
    case 'brand/customMenu/modifier/location-setup':
    case 'brand/customMenu/menu/merge/location-setup':
    case 'brand/customMenu/modifier/merge/location-setup':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/customMenu/list',
        },
        closeButton: {
          type: 3,
          controlUrl: '/customMenu/list'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/storeNamedSensor/add':
    case '/store/storeNamedSensor/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/namedSensor/6'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/venueNamedSensor/add':
    case '/store/venueNamedSensor/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/namedSensor/9'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/store/offPremiseSensor/add':
    case '/store/offPremiseSensor/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/store/offPremiseSensors/11'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/releaseNotes/add':
    case '/releaseNotes/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/releaseNotes/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/warehouse-reports/checkDetailes':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/warehouse-reports/check'
        },
        closeButton: {
          type: 3,
          controlUrl: '/warehouse-reports/check'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/sensorGroup/add':
    case '/brand/sensorGroup/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/sensorGroups'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/emailTemplates/emailEditor':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/emailTemplates/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/charges/add':
    case '/brand/charges/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/charges/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/posAgent/statusList':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/posAgent/console'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/voucher/add':
    case '/brand/voucher/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/vouchers/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/reports/apiLogs':
    case '/apiLogsDetails':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/mobileWeb':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/mobileWeb'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/serverApp':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/serverApp'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/kiosk':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/kiosk'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/tableTop':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/tableTop'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/orderUp':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/orderUp'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/kds':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/kds'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/admin/editLanguages/waitList':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/admin/languages/waitList'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/mobileApp/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/mobileApp'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/serverApp/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/serverApp'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/kds/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/kds'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/kiosk/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/kiosk'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/tableTop/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/tableTop'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/orderUp/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/orderUp'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/languages/waitList/edit':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/languages/waitList'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/cubby/add':
    case '/cubby/update':
    case '/cubby/slot/add':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/cubby/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/manageStore':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/multiLingual':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/financial/chartOfAccounts':
    case '/brand/financial/storeAccounts':
    case '/brand/financial/storeAccountsBalance':
    case '/brand/financial/storeAccountsJournal':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/mall/stores':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 2,
          controlUrl: '/mall/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/login':
      return {
        headerBar: {
          show: false,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/storeSelect':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/mainMenu':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/storeSelect'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/bank':
    case '/empApp/reloadBank':
    case '/empApp/cashDrop':
    case '/empApp/paidIn':
    case '/empApp/paidOut':
    case '/empApp/cashCollectedToBankDeposit':
    case '/empApp/safeDeposit':
    case '/empApp/safeWithdraw':
    case '/empApp/serverCashOut':
    case '/empApp/cashierCashOut':
    case '/empApp/cashOutSummary':
    case '/empApp/empSalesSummary':
    case '/empApp/accountBalances':
    case '/empApp/bankDepositeToSafe':
    case '/empApp/safeToBankDeposit':
    case '/empApp/paymentAdjustment':
    case '/empApp/void':
    case '/empApp/discount':
    case '/empApp/guestRecovery':
    case '/empApp/tableSensor':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/mainMenu'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/paymentAdjustmentDetails':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/paymentAdjustment'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/paymentAdjustmentForm':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/paymentAdjustmentDetails'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/voidDetails':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/void'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/discountDetails':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/discount'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/sensorsList':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/tableSensor'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/empApp/addSensor':
    case '/empApp/editSensor':
    case '/empApp/addSensorCode':
    case '/empApp/addSensorQr':
      return {
        headerBar: {
          show: true,
          tittle: 'OneDine'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/empApp/sensorsList'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/kds/stations/add':
    case '/brand/kds/stations/update':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/kds/stations/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/kds/layouts/add':
    case '/brand/kds/layouts/update':
    case '/brand/kds/layouts/rules':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/kds/layouts/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/mobileApp/layouts/add':
    case '/brand/mobileApp/layouts/update':
    case '/brand/mobileApp/layouts/design':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/mobileApp/layouts/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/mobileApp/page/add':
    case '/brand/mobileApp/page/update':
    case '/brand/mobileApp/page/design':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/mobileApp/page/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    case '/brand/kds/print/add':
    case '/brand/kds/print/update':
    case '/brand/kds/print/rules':
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: 2,
          controlUrl: '/brand/kds/print/list'
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        restaurantInfo: {
          show: false
        }
      };
    default:
      return {
        headerBar: {
          show: true,
          tittle: 'onedine'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        restaurantInfo: {
          show: false
        }
      };
  }
};
