const currentReducer = 'offerManagement';

export const GET_OFFERS_REQUESTED = `${currentReducer}/GET_OFFERS_REQUESTED`;
export const GET_OFFERS_SUCCESS = `${currentReducer}/GET_OFFERS_SUCCESS`;
export const GET_OFFERS_FAILURE = `${currentReducer}/GET_OFFERS_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  offersList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OFFERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        offersList: action.result
      };
    }
    case GET_OFFERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getOffers = (data) => {
  return {
    types: [GET_OFFERS_REQUESTED, GET_OFFERS_SUCCESS, GET_OFFERS_FAILURE],
    promise: client => client.post('getOffers', { data })
  };
};
