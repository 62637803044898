import { groupElemetsByKey } from '@utility/arrayOperations';

export const GET_BRAND_MARKETPLACE_SETUP_REQUESTED = 'brandMarketPlace/GET_BRAND_MARKETPLACE_SETUP_REQUESTED';
export const GET_BRAND_MARKETPLACE_SETUP_SUCCESS = 'brandMarketPlace/GET_BRAND_MARKETPLACE_SETUP_SUCCESS';
export const GET_BRAND_MARKETPLACE_SETUP_FAILURE = 'brandMarketPlace/GET_BRAND_MARKETPLACE_SETUP_FAILURE';

export const UPDATE_BRAND_MARKETPLACE_SETUP_REQUESTED = 'brandMarketPlace/UPDATE_BRAND_MARKETPLACE_SETUP_REQUESTED';
export const UPDATE_BRAND_MARKETPLACE_SETUP_SUCCESS = 'brandMarketPlace/UPDATE_BRAND_MARKETPLACE_SETUP_SUCCESS';
export const UPDATE_BRAND_MARKETPLACE_SETUP_FAILURE = 'brandMarketPlace/UPDATE_BRAND_MARKETPLACE_SETUP_FAILURE';

export const GET_SERVICE_CHARGE_BY_BRAND_REQUESTED = 'brandMarketPlace/GET_SERVICE_CHARGE_BY_BRAND_REQUESTED';
export const GET_SERVICE_CHARGE_BY_BRAND_SUCCESS = 'brandMarketPlace/GET_SERVICE_CHARGE_BY_BRAND_SUCCESS';
export const GET_SERVICE_CHARGE_BY_BRAND_FAILURE = 'brandMarketPlace/GET_SERVICE_CHARGE_BY_BRAND_FAILURE';

export const GET_MARKET_PLACE_VENDORS_BY_BRAND_REQUESTED = 'brandMarketPlace/GET_MARKET_PLACE_VENDORS_BY_BRAND_REQUESTED';
export const GET_MARKET_PLACE_VENDORS_BY_BRAND_SUCCESS = 'brandMarketPlace/GET_MARKET_PLACE_VENDORS_BY_BRAND_SUCCESS';
export const GET_MARKET_PLACE_VENDORS_BY_BRAND_FAILURE = 'brandMarketPlace/GET_MARKET_PLACE_VENDORS_BY_BRAND_FAILURE';

export const GET_STORE_MENUITEMS_BY_BRAND_REQUESTED = 'brandMarketPlace/GET_STORE_MENUITEMS_BY_BRAND_REQUESTED';
export const GET_STORE_MENUITEMS_BY_BRAND_SUCCESS = 'brandMarketPlace/GET_STORE_MENUITEMS_BY_BRAND_SUCCESS';
export const GET_STORE_MENUITEMS_BY_BRAND_FAILURE = 'brandMarketPlace/GET_STORE_MENUITEMS_BY_BRAND_FAILURE';

export const GET_ITEM_ORDER_TYPE_REQUESTED = 'brandMarketPlace/GET_ITEM_ORDER_TYPE_REQUESTED';
export const GET_ITEM_ORDER_TYPE_SUCCESS = 'brandMarketPlace/GET_ITEM_ORDER_TYPE_SUCCESS';
export const GET_ITEM_ORDER_TYPE_FAILURE = 'barndMarketPlace/GET_ITEM_ORDER_TYPE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandMarketPlaceList: [],
  serviceChargeList: [],
  menuItemsList: [],
  vendorTypeList: [],
  itemOrderTypeList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_MARKETPLACE_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        brandMarketPlaceList: [],
      };
    }
    case GET_BRAND_MARKETPLACE_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandMarketPlaceList: action.result,
      };
    }
    case GET_BRAND_MARKETPLACE_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_MARKETPLACE_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        // brandMarketPlaceList: [],
      };
    }
    case UPDATE_BRAND_MARKETPLACE_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandMarketPlaceList: action.result,
      };
    }
    case UPDATE_BRAND_MARKETPLACE_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_MENUITEMS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        menuItemsList: [],
      };
    }
    case GET_STORE_MENUITEMS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuItemsList: action.result,
      };
    }
    case GET_STORE_MENUITEMS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SERVICE_CHARGE_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        serviceChargeList: [],
      };
    }
    case GET_SERVICE_CHARGE_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        serviceChargeList: action.result && groupElemetsByKey(action.result, 'storeId'),
      };
    }
    case GET_SERVICE_CHARGE_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MARKET_PLACE_VENDORS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        vendorTypeList: [],
      };
    }
    case GET_MARKET_PLACE_VENDORS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        vendorTypeList: action.result,
      };
    }
    case GET_MARKET_PLACE_VENDORS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ITEM_ORDER_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        itemOrderTypeList: [],
      };
    }
    case GET_ITEM_ORDER_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemOrderTypeList: action.result
      };
    }
    case GET_ITEM_ORDER_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        errror: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBrandMarketPlaceSetup = (brandId) => {
  return {
    types: [GET_BRAND_MARKETPLACE_SETUP_REQUESTED, GET_BRAND_MARKETPLACE_SETUP_SUCCESS, GET_BRAND_MARKETPLACE_SETUP_FAILURE],
    promise: client => client.get(`getMarketPlaceSetup/${brandId}`)
  };
};

export const updateBrandMarketPlaceSetup = (brandId, data) => {
  return {
    types: [UPDATE_BRAND_MARKETPLACE_SETUP_REQUESTED, UPDATE_BRAND_MARKETPLACE_SETUP_SUCCESS, UPDATE_BRAND_MARKETPLACE_SETUP_FAILURE],
    promise: client => client.post(`updateMarketPlaceSetup/${brandId}`, { data })
  };
};

export const getServiceChargeByBrand = (brandId) => {
  return {
    types: [GET_SERVICE_CHARGE_BY_BRAND_REQUESTED, GET_SERVICE_CHARGE_BY_BRAND_SUCCESS, GET_SERVICE_CHARGE_BY_BRAND_FAILURE],
    promise: client => client.get(`getServiceChargeByBrandId/${brandId}`)
  };
};

export const getMarketPlaceVendors = () => {
  return {
    types: [GET_MARKET_PLACE_VENDORS_BY_BRAND_REQUESTED, GET_MARKET_PLACE_VENDORS_BY_BRAND_SUCCESS, GET_MARKET_PLACE_VENDORS_BY_BRAND_FAILURE],
    promise: client => client.get('getMarketPlaceVendors')
  };
};

export const getStoreMenuItemsByBrand = (brandId) => {
  return {
    types: [GET_STORE_MENUITEMS_BY_BRAND_REQUESTED, GET_STORE_MENUITEMS_BY_BRAND_SUCCESS, GET_STORE_MENUITEMS_BY_BRAND_FAILURE],
    promise: client => client.get(`getStoreMenuItemsByBrandId/${brandId}`)
  };
};

export const getItemOrderTypeByBrand = (brandId) => {
  return {
    types: [GET_ITEM_ORDER_TYPE_REQUESTED, GET_ITEM_ORDER_TYPE_SUCCESS, GET_ITEM_ORDER_TYPE_FAILURE],
    promise: client => client.get(`getbrandItemOrderType/${brandId}`)
  };
};
