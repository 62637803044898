export const GET_BRAND_HOLIDAYS_REQUESTED = 'brandSetup/GET_BRAND_HOLIDAYS_REQUESTED';
export const GET_BRAND_HOLIDAYS_SUCCESS = 'brandSetup/GET_BRAND_HOLIDAYS_SUCCESS';
export const GET_BRAND_HOLIDAYS_FAILURE = 'brandSetup/GET_BRAND_HOLIDAYS_FAILURE';

export const ADD_BRAND_HOLIDAYS_REQUESTED = 'brandSetup/ADD_BRAND_HOLIDAYS_REQUESTED';
export const ADD_BRAND_HOLIDAYS_SUCCESS = 'brandSetup/ADD_BRAND_HOLIDAYS_SUCCESS';
export const ADD_BRAND_HOLIDAYS_FAILURE = 'brandSetup/ADD_BRAND_HOLIDAYS_FAILURE';

export const UPDATE_BRAND_HOLIDAYS_REQUESTED = 'brandSetup/UPDATE_BRAND_HOLIDAYS_REQUESTED';
export const UPDATE_BRAND_HOLIDAYS_SUCCESS = 'brandSetup/UPDATE_BRAND_HOLIDAYS_SUCCESS';
export const UPDATE_BRAND_HOLIDAYS_FAILURE = 'brandSetup/UPDATE_BRAND_HOLIDAYS_FAILURE';

export const DELETE_BRAND_HOLIDAYS_REQUESTED = 'brandSetup/DELETE_BRAND_HOLIDAYS_REQUESTED';
export const DELETE_BRAND_HOLIDAYS_SUCCESS = 'brandSetup/DELETE_BRAND_HOLIDAYS_SUCCESS';
export const DELETE_BRAND_HOLIDAYS_FAILURE = 'brandSetup/DELETE_BRAND_HOLIDAYS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandHolidaysList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_HOLIDAYS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandHolidaysList: action.result,
      };
    }
    case GET_BRAND_HOLIDAYS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_HOLIDAYS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_HOLIDAYS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_HOLIDAYS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_HOLIDAYS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_HOLIDAYS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_HOLIDAYS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBrandHolidays = (storeId) => {
  return {
    types: [GET_BRAND_HOLIDAYS_REQUESTED, GET_BRAND_HOLIDAYS_SUCCESS, GET_BRAND_HOLIDAYS_FAILURE],
    promise: client => client.get(`getBrandHolidays/${storeId}`)
  };
};

export const addBrandHoliday = (data) => {
  return {
    types: [ADD_BRAND_HOLIDAYS_REQUESTED, ADD_BRAND_HOLIDAYS_SUCCESS, ADD_BRAND_HOLIDAYS_FAILURE],
    promise: client => client.post('addBrandHoliday', { data })
  };
};

export const updateBrandHoliday = (data) => {
  return {
    types: [UPDATE_BRAND_HOLIDAYS_REQUESTED, UPDATE_BRAND_HOLIDAYS_SUCCESS, UPDATE_BRAND_HOLIDAYS_FAILURE],
    promise: client => client.post('updateBrandHoliday', { data })
  };
};

export const deleteBrandHoliday = (id, data) => {
  return {
    types: [DELETE_BRAND_HOLIDAYS_REQUESTED, DELETE_BRAND_HOLIDAYS_SUCCESS, DELETE_BRAND_HOLIDAYS_FAILURE],
    promise: client => client.post(`deleteBrandHoliday/${id}`, { data })
  };
};
