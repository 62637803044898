
export const ADD_HOME_ICON_REQUESTED = 'customIcons/ADD_HOME_ICON_REQUESTED';
export const ADD_HOME_ICON_SUCCESS = 'customIcons/ADD_HOME_ICON_SUCCESS';
export const ADD_HOME_ICON_FAILURE = 'customIcons/ADD_HOME_ICON_FAILURE';

export const GET_CARD_FROM_SESSION = 'customIcons/GET_CARD_FROM_SESSION';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case ADD_HOME_ICON_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case ADD_HOME_ICON_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ADD_HOME_ICON_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

export const addCustomImages = (brandId, type, data, isSave) => {
  let action = 'delete';
  if (isSave) {
    action = 'save';
  }
  return {
    types: [ADD_HOME_ICON_REQUESTED, ADD_HOME_ICON_SUCCESS, ADD_HOME_ICON_FAILURE],
    promise: client => client.post(`brandImage/${brandId}/${type}/${action}`, {
      data
    })
  };
};

export const handleBrandFont = (brandId, appType, isSave, data) => {
  let action = 'delete';
  if (isSave) {
    action = 'save';
  }
  return {
    types: [ADD_HOME_ICON_REQUESTED, ADD_HOME_ICON_SUCCESS, ADD_HOME_ICON_FAILURE],
    promise: client => client.post(`brandFont/${brandId}/${appType}/${action}`, {
      data
    })
  };
};

export const handleBrandHeaderFont = (brandId, appType, isSave, data) => {
  let action = 'delete';
  if (isSave) {
    action = 'save';
  }
  return {
    types: [ADD_HOME_ICON_REQUESTED, ADD_HOME_ICON_SUCCESS, ADD_HOME_ICON_FAILURE],
    promise: client => client.post(`brandHeaderFont/${brandId}/${appType}/${action}`, {
      data
    })
  };
};
