export const GET_PRINT_CATEGORY_LIST_REQUESTED = 'printCategory/GET_PRINT_CATEGORY_LIST_REQUESTED';
export const GET_PRINT_CATEGORY_LIST_SUCCESS = 'printCategory/GET_PRINT_CATEGORY_LIST_SUCCESS';
export const GET_PRINT_CATEGORY_LIST_FAILURE = 'printCategory/GET_PRINT_CATEGORY_LIST_FAILURE';

export const ADD_PRINT_CATEGORY_REQUESTED = 'printCategory/ADD_PRINT_CATEGORY_REQUESTED';
export const ADD_PRINT_CATEGORY_SUCCESS = 'printCategory/ADD_PRINT_CATEGORY_SUCCESS';
export const ADD_PRINT_CATEGORY_FAILURE = 'printCategory/ADD_PRINT_CATEGORY_FAILURE';

export const UPDATE_PRINT_CATEGORY_REQUESTED = 'printCategory/UPDATE_PRINT_CATEGORY_REQUESTED';
export const UPDATE_PRINT_CATEGORY_SUCCESS = 'printCategory/UPDATE_PRINT_CATEGORY_SUCCESS';
export const UPDATE_PRINT_CATEGORY_FAILURE = 'printCategory/UPDATE_PRINT_CATEGORY_FAILURE';

export const DELETE_PRINT_CATEGORY_REQUESTED = 'printCategory/DELETE_PRINT_CATEGORY_REQUESTED';
export const DELETE_PRINT_CATEGORY_SUCCESS = 'printCategory/DELETE_PRINT_CATEGORY_SUCCESS';
export const DELETE_PRINT_CATEGORY_FAILURE = 'printCategory/DELETE_PRINT_CATEGORY_FAILURE';

export const GET_KDS_EXPO_STATION_BY_BRAND_REQUESTED = 'printCategory/GET_KDS_EXPO_STATION_BY_BRAND_REQUESTED';
export const GET_KDS_EXPO_STATION_BY_BRAND_SUCCESS = 'printCategory/GET_KDS_EXPO_STATION_BY_BRAND_SUCCESS';
export const GET_KDS_EXPO_STATION_BY_BRAND_FAILURE = 'printCategory/GET_KDS_EXPO_STATION_BY_BRAND_FAILURE';

export const GET_KDS_PREP_STATION_BY_BRAND_REQUESTED = 'printCategory/GET_KDS_PREP_STATION_BY_BRAND_REQUESTED';
export const GET_KDS_PREP_STATION_BY_BRAND_SUCCESS = 'printCategory/GET_KDS_PREP_STATION_BY_BRAND_SUCCESS';
export const GET_KDS_PREP_STATION_BY_BRAND_FAILURE = 'printCategory/GET_KDS_PREP_STATION_BY_BRAND_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  printCategoryList: [],
  kdsExpoStationList: [],
  kdsPrepStationList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_PRINT_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PRINT_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        printCategoryList: action.result,
      };
    }
    case GET_PRINT_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_PRINT_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_PRINT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_PRINT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PRINT_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PRINT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PRINT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_PRINT_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_PRINT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_PRINT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_EXPO_STATION_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_KDS_EXPO_STATION_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsExpoStationList: action.result,
      };
    }
    case GET_KDS_EXPO_STATION_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_PREP_STATION_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_KDS_PREP_STATION_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsPrepStationList: action.result,
      };
    }
    case GET_KDS_PREP_STATION_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPrintCategories = (brandId) => {
  return {
    types: [GET_PRINT_CATEGORY_LIST_REQUESTED, GET_PRINT_CATEGORY_LIST_SUCCESS, GET_PRINT_CATEGORY_LIST_FAILURE],
    promise: client => client.get(`printCategoryList/${brandId}`)
  };
};

export const addPrintCategory = (data) => {
  return {
    types: [ADD_PRINT_CATEGORY_REQUESTED, ADD_PRINT_CATEGORY_SUCCESS, ADD_PRINT_CATEGORY_FAILURE],
    promise: client => client.post('addPrintCategory', { data })
  };
};

export const updatePrintCategory = (data) => {
  return {
    types: [UPDATE_PRINT_CATEGORY_REQUESTED, UPDATE_PRINT_CATEGORY_SUCCESS, UPDATE_PRINT_CATEGORY_FAILURE],
    promise: client => client.post('updatePrintCategory', { data })
  };
};

export const deletePrintCategory = (id) => {
  return {
    types: [DELETE_PRINT_CATEGORY_REQUESTED, DELETE_PRINT_CATEGORY_SUCCESS, DELETE_PRINT_CATEGORY_FAILURE],
    promise: client => client.post(`deletePrintCategory/${id}`)
  };
};

export const getKDSExpoStationByBrand = (brandId) => {
  return {
    types: [GET_KDS_EXPO_STATION_BY_BRAND_REQUESTED, GET_KDS_EXPO_STATION_BY_BRAND_SUCCESS, GET_KDS_EXPO_STATION_BY_BRAND_FAILURE],
    promise: client => client.get(`getKDSExpoStationByBrand/${brandId}`)
  };
};

export const getKDSPrepStationByBrand = (brandId) => {
  return {
    types: [GET_KDS_PREP_STATION_BY_BRAND_REQUESTED, GET_KDS_PREP_STATION_BY_BRAND_SUCCESS, GET_KDS_PREP_STATION_BY_BRAND_FAILURE],
    promise: client => client.get(`getKDSPrepStationByBrand/${brandId}`)
  };
};
