import KioskLoginScreen from '@assets/serverappscreen/KioskLoginScreen.png';
import KioskLandingPage from '@assets/serverappscreen/KioskLandingScreen.png';
import KioskUserInformationScreen from '@assets/serverappscreen/KioskUserInformationScreen.png';
import KioskItemDetailsScreen from '@assets/serverappscreen/KioskItemDetailsScreen.png';
import KioskOrderCartScreen from '@assets/serverappscreen/KioskOrderCartScreen.png';
import KioskPaymentScreen from '@assets/serverappscreen/KioskPaymentScreen.png';
import KioskSurveyScreen from '@assets/serverappscreen/KioskSurveyScreen.png';
import KioskOrderTypeSelection from '@assets/serverappscreen/KioskOrderTypeSelection.png';

export default {
  LoginPage: {
    screenLabel: 'Login Screen',
    image: [KioskLoginScreen],
    customLabels: [
      {
        defaultText: 'LOGIN',
        key: 'label.KIOSK_LOGIN',
        type: 'label',
        value: 'KIOSK_LOGIN',
      },
      {
        defaultText: 'ACTIVE INGENICO DEVICE',
        key: 'label.ACTIVE_INGENICO_DEVICE',
        type: 'label',
        value: 'ACTIVE_INGENICO_DEVICE',
      },
      {
        defaultText: 'CONTACT',
        key: 'label.CONTACT',
        type: 'label',
        value: 'CONTACT',
      },
      {
        defaultText: 'FOR SUPPORT',
        key: 'label.FOR_SUPPORT',
        type: 'label',
        value: 'FOR_SUPPORT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CANCEL',
        type: 'label',
        value: 'CANCEL',
      },
      {
        defaultText: 'Succesfully Logged In',
        key: 'customMessage.LOGIN_SUCCESS',
        type: 'customMessage',
        value: 'LOGIN_SUCCESS',
      },
      {
        defaultText: 'Device Activated Successfully',
        key: 'customMessage.ACT_SUCCESS',
        type: 'customMessage',
        value: 'ACT_SUCCESS',
      },
      {
        defaultText: 'No Notification Found',
        key: 'customMessage.NO_NOTIFICATION',
        type: 'customMessage',
        value: 'NO_NOTIFICATION',
      },
      {
        defaultText: 'Do you wish to re-activate with different activation code?',
        key: 'customMessage.REACTIVATE_WITH_NEW_CODE',
        type: 'customMessage',
        value: 'REACTIVATE_WITH_NEW_CODE',
      },
      {
        defaultText: 'Enter your verification code:',
        key: 'customMessage.ENTER_VERIFICATION_CODE',
        type: 'customMessage',
        value: 'ENTER_VERIFICATION_CODE',
      },
      {
        defaultText: 'CLOSE',
        key: 'label.KIOSK_CLOSE',
        type: 'label',
        value: 'KIOSK_CLOSE',
      },
      {
        defaultText: 'SUBMIT',
        key: 'label.KIOSK_SUBMIT',
        type: 'label',
        value: 'KIOSK_SUBMIT',
      },
      {
        defaultText: 'Enter Employee Code',
        key: 'customMessage.ENTER_EMPLOYEE_CODE',
        type: 'customMessage',
        value: 'ENTER_EMPLOYEE_CODE',
      },
    ]
  },
  LandingPage: {
    screenLabel: 'Landing Screen',
    image: [KioskLandingPage],
    customLabels: [
      {
        defaultText: 'START ORDER',
        key: 'label.KIOSK_START_ORDER',
        type: 'label',
        value: 'KIOSK_START_ORDER',
      },
      {
        defaultText: 'Need Assistance?',
        key: 'label.NEED_ASSISTANCE',
        type: 'label',
        value: 'NEED_ASSISTANCE',
      },
      {
        defaultText: 'Accessibility',
        key: 'label.ACCESSIBILITY',
        type: 'label',
        value: 'ACCESSIBILITY',
      },
    ]
  },
  OrderTypeSelectionScreen: {
    screenLabel: ' Order Type Selection Screen',
    image: [KioskOrderTypeSelection],
    customLabels: [
      {
        defaultText: 'Order for here',
        key: 'label.ORDER_FOR_HERE',
        type: 'label',
        value: 'ORDER_FOR_HERE',
      },
      {
        defaultText: 'Order to-go',
        key: 'label.ORDER_TO_GO',
        type: 'label',
        value: 'ORDER_TO_GO',
      },
      {
        defaultText: 'Start Over',
        key: 'label.START_OVER',
        type: 'label',
        value: 'START_OVER',
      },
      {
        defaultText: 'This will restart order. Do you want to continue?',
        key: 'customMessage.RESTART_ORDER',
        type: 'customMessage',
        value: 'RESTART_ORDER',
      },
      {
        defaultText: 'NO',
        key: 'label.KIOSK_NO',
        type: 'label',
        value: 'KIOSK_NO',
      },
      {
        defaultText: 'YES',
        key: 'label.KIOSK_YES',
        type: 'label',
        value: 'KIOSK_YES',
      },
    ]
  },
  UserInformationScreen: {
    screenLabel: 'User Information Screen',
    image: [KioskUserInformationScreen],
    customLabels: [
      {
        defaultText: 'Name (required)',
        key: 'label.NAME_REQUIRED',
        type: 'label',
        value: 'NAME_REQUIRED',
      },
      {
        defaultText: 'Phone (optional)',
        key: 'label.PHONE_OPTIONAL',
        type: 'label',
        value: 'PHONE_OPTIONAL',
      },
      {
        defaultText: 'CONTINUE',
        key: 'label.KIOSK_CONTINUE',
        type: 'label',
        value: 'KIOSK_CONTINUE',
      },
      {
        defaultText: 'Please enter a name for this order',
        key: 'customMessage.NAME_FOR_THIS_ORDER',
        type: 'customMessage',
        value: 'NAME_FOR_THIS_ORDER',
      },
      {
        defaultText: 'Do you want us to text you when order is ready?',
        key: 'customMessage.DO_WANT_US_TO_TEXT',
        type: 'customMessage',
        value: 'DO_WANT_US_TO_TEXT',
      },
      {
        defaultText: 'I agree to receive text messages to the phone number provided about this order. Msg and data rates may apply',
        key: 'customMessage.I_AGREE_TO_RECEIV_TEXT',
        type: 'customMessage',
        value: 'I_AGREE_TO_RECEIV_TEXT',
      },
    ]
  },
  ItemDetailsScreen: {
    screenLabel: 'Item Details Screen',
    image: [KioskItemDetailsScreen],
    customLabels: [
      {
        defaultText: 'View My Order',
        key: 'label.VIEW_MY_ORDER',
        type: 'label',
        value: 'VIEW_MY_ORDER',
      },
      {
        defaultText: 'More Info',
        key: 'label.MORE_INFO',
        type: 'label',
        value: 'MORE_INFO',
      },
      {
        defaultText: 'Order',
        key: 'label.ORDER',
        type: 'label',
        value: 'ORDER',
      },
      {
        defaultText: 'Modify',
        key: 'label.MODIFY',
        type: 'label',
        value: 'MODIFY',
      },
      {
        defaultText: 'Update Cart',
        key: 'label.UPDATE_CART',
        type: 'label',
        value: 'UPDATE_CART',
      },
      {
        defaultText: 'ORDER ANOTHER',
        key: 'label.ORDER_ANOTHER',
        type: 'label',
        value: 'ORDER_ANOTHER',
      },
      {
        defaultText: 'CHECKOUT',
        key: 'label.CHECKOUT',
        type: 'label',
        value: 'CHECKOUT',
      },
      {
        defaultText: 'No, Thanks',
        key: 'label.NO_THANKS',
        type: 'label',
        value: 'NO_THANKS',
      },
      {
        defaultText: 'Add to Cart',
        key: 'label.ADD_TO_CART',
        type: 'label',
        value: 'ADD_TO_CART',
      },
      {
        defaultText: 'Close',
        key: 'label.CLOSE',
        type: 'label',
        value: 'CLOSE',
      },
      {
        defaultText: 'Submit',
        key: 'label.SUBMIT',
        type: 'label',
        value: 'SUBMIT',
      },
      {
        defaultText: 'Add a note',
        key: 'label.ADD_A_NOTE',
        type: 'label',
        value: 'ADD_A_NOTE',
      },
      {
        defaultText: 'SPECIAL INSTRUCTIONS',
        key: 'label.SPECIAL_INSTRUCTIONS',
        type: 'label',
        value: 'SPECIAL_INSTRUCTIONS',
      },
      {
        defaultText: 'Item Facts',
        key: 'label.ITEM_FACTS',
        type: 'label',
        value: 'ITEM_FACTS',
      },
      {
        defaultText: 'Nutrition Facts',
        key: 'label.NUTRITION_FACT',
        type: 'label',
        value: 'NUTRITION_FACT',
      },
      {
        defaultText: 'Additional Time',
        key: 'label.ADDITIONAL_TIME',
        type: 'label',
        value: 'ADDITIONAL_TIME',
      },
      {
        defaultText: 'View Only',
        key: 'label.VIEW_ONLY',
        type: 'label',
        value: 'VIEW_ONLY',
      },
      {
        defaultText: 'Special',
        key: 'label.SPECIAL',
        type: 'label',
        value: 'SPECIAL',
      },
      {
        defaultText: 'Coming Soon',
        key: 'label.COMING_SOON',
        type: 'label',
        value: 'COMING_SOON',
      },
      {
        defaultText: 'Limited Quantity',
        key: 'label.LIMITED_QUANTITY',
        type: 'label',
        value: 'LIMITED_QUANTITY',
      },
      {
        defaultText: 'Featured',
        key: 'label.FEATURED',
        type: 'label',
        value: 'FEATURED',
      },
      {
        defaultText: 'Out of Stock',
        key: 'label.OUT_OF_STOCK',
        type: 'label',
        value: 'OUT_OF_STOCK',
      },
      {
        defaultText: 'Limited Time',
        key: 'label.LIMITED_TIME',
        type: 'label',
        value: 'LIMITED_TIME',
      },
      {
        defaultText: 'New Item',
        key: 'label.NEW_ITEM',
        type: 'label',
        value: 'NEW_ITEM',
      },
      {
        defaultText: 'Yes',
        key: 'label.YES',
        type: 'label',
        value: 'YES',
      },
      {
        defaultText: 'No',
        key: 'label.NO',
        type: 'label',
        value: 'NO',
      },
      {
        defaultText: 'Are you sure, you want to delete this item? ',
        key: 'customMessage.DELETE_ITEM_CONFIRMATION',
        type: 'customMessage',
        value: 'DELETE_ITEM_CONFIRMATION',
      },
      {
        defaultText: 'ADD NOTE',
        key: 'label.ADD_NOTE',
        type: 'label',
        value: 'ADD_NOTE',
      },
      {
        defaultText: 'RETURN TO MENU',
        key: 'label.RETURN_TO_MENU',
        type: 'label',
        value: 'RETURN_TO_MENU',
      },
      {
        defaultText: 'TYPE YOUR NOTE',
        key: 'label.TYPE_YOUR_NOTE',
        type: 'label',
        value: 'TYPE_YOUR_NOTE',
      },
      {
        defaultText: 'CANCEL',
        key: 'label.KIOSK_CANCEL',
        type: 'label',
        value: 'KIOSK_CANCEL',
      },
    ]
  },
  OrderCartScreen: {
    screenLabel: 'Order And Cart Screen',
    image: [KioskOrderCartScreen],
    customLabels: [
      {
        defaultText: 'Order more Items',
        key: 'label.ORDER_MORE_ITEMS',
        type: 'label',
        value: 'ORDER_MORE_ITEMS',
      },
      {
        defaultText: 'Pay with card',
        key: 'label.PAY_WITH_CARD',
        type: 'label',
        value: 'PAY_WITH_CARD',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.SUB_TOTAL',
        type: 'label',
        value: 'SUB_TOTAL',
      },
      {
        defaultText: 'ADD TO ORDER',
        key: 'label.ADD_TO_ORDER',
        type: 'label',
        value: 'ADD_TO_ORDER',
      },
      {
        defaultText: 'REMOVE',
        key: 'label.REFILL_REMOVE',
        type: 'label',
        value: 'REFILL_REMOVE',
      },
      {
        defaultText: 'show more',
        key: 'label.SHOW_MORE',
        type: 'label',
        value: 'SHOW_MORE',
      },
      {
        defaultText: 'show less',
        key: 'label.SHOW_LESS',
        type: 'label',
        value: 'SHOW_LESS',
      },
      {
        defaultText: 'OK',
        key: 'label.KIOSK_OK',
        type: 'label',
        value: 'KIOSK_OK',
      },
    ]
  },
  PaymentScreen: {
    screenLabel: 'Payment & Receipt Screen',
    image: [KioskPaymentScreen],
    customLabels: [
      {
        defaultText: 'Select tip',
        key: 'label.SELECT_TIP',
        type: 'label',
        value: 'SELECT_TIP',
      },
      {
        defaultText: 'Other',
        key: 'label.OTHER',
        type: 'label',
        value: 'OTHER',
      },
      {
        defaultText: 'Tax',
        key: 'label.TAX',
        type: 'label',
        value: 'TAX',
      },
      {
        defaultText: 'Total',
        key: 'label.TOTAL',
        type: 'label',
        value: 'TOTAL',
      },
      {
        defaultText: 'Paid',
        key: 'label.PAID',
        type: 'label',
        value: 'PAID',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP',
        type: 'label',
        value: 'TIP',
      },
      {
        defaultText: 'Payments',
        key: 'label.PAYMENTS',
        type: 'label',
        value: 'PAYMENTS',
      },
      {
        defaultText: 'Continue with Payment',
        key: 'label.CONTINUE_WITH_PAYMENT',
        type: 'label',
        value: 'CONTINUE_WITH_PAYMENT',
      },
      {
        defaultText: 'Payment',
        key: 'label.PAYMENT',
        type: 'label',
        value: 'PAYMENT',
      },
      {
        defaultText: 'Receipt',
        key: 'label.RECEIPT',
        type: 'label',
        value: 'RECEIPT',
      },
      {
        defaultText: 'Text',
        key: 'label.TEXT',
        type: 'label',
        value: 'TEXT',
      },
      {
        defaultText: 'Email',
        key: 'label.EMAIL',
        type: 'label',
        value: 'EMAIL',
      },
      {
        defaultText: 'No Receipt',
        key: 'label.NO_RECEIPT',
        type: 'label',
        value: 'NO_RECEIPT',
      },
      {
        defaultText: 'Enter your phone number',
        key: 'label.MOBILE_NUMBER',
        type: 'label',
        value: 'MOBILE_NUMBER',
      },
      {
        defaultText: 'GO BACK',
        key: 'label.GO_BACK',
        type: 'label',
        value: 'GO_BACK',
      },
      {
        defaultText: 'Enter your email address',
        key: 'label.ENTER_EMAIL_ADDRESS',
        type: 'label',
        value: 'ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Payment',
        key: 'label.RECEIPT_PAYMENT',
        type: 'label',
        value: 'RECEIPT_PAYMENT',
      },
      {
        defaultText: 'PAYMENT COMPLETED',
        key: 'label.PAYMENT_COMPLETED',
        type: 'label',
        value: 'PAYMENT_COMPLETED',
      },
      {
        defaultText: 'Retry',
        key: 'label.RETRY',
        type: 'label',
        value: 'RETRY',
      },
      {
        defaultText: 'Send me news and offers via SMS',
        key: 'customMessage.SEND_NEWS_VIA_SMS',
        type: 'customMessage',
        value: 'SEND_NEWS_VIA_SMS',
      },
      {
        defaultText: 'Send me news and offers via EMAIL',
        key: 'customMessage.SEND_NEWS_VIA_EMAIL',
        type: 'customMessage',
        value: 'SEND_NEWS_VIA_EMAIL',
      },
      {
        defaultText: 'Enter your phone number',
        key: 'customMessage.RECEIPT_PHONE_NUMBER',
        type: 'customMessage',
        value: 'RECEIPT_PHONE_NUMBER',
      },
      {
        defaultText: 'Enter your email address',
        key: 'customMessage.RECEIPT_EMAIL',
        type: 'customMessage',
        value: 'RECEIPT_EMAIL',
      },
      {
        defaultText: 'There was error processing your payment',
        key: 'customMessage.PAYMENT_PROCESSING_ERROR',
        type: 'customMessage',
        value: 'PAYMENT_PROCESSING_ERROR',
      },
      {
        defaultText: 'CASH',
        key: 'label.KIOSK_CASH',
        type: 'label',
        value: 'KIOSK_CASH',
      },
      {
        defaultText: 'CREDIT CARD',
        key: 'label.KIOSK_CREDIT_CARD',
        type: 'label',
        value: 'KIOSK_CREDIT_CARD',
      },
      {
        defaultText: 'BACK TO TIP',
        key: 'label.BACK_TO_TIP',
        type: 'label',
        value: 'BACK_TO_TIP',
      },
      {
        defaultText: 'SCAN TO PAY',
        key: 'label.SCAN_TO_PAY',
        type: 'label',
        value: 'SCAN_TO_PAY',
      },
      {
        defaultText: 'HOW WOULD YOU LIKE TO PAY?',
        key: 'customMessage.HOW_WOULD_YOU_LIKE_TO_PAY',
        type: 'customMessage',
        value: 'HOW_WOULD_YOU_LIKE_TO_PAY',
      },
      {
        defaultText: 'TEXT IT TO ME',
        key: 'label.TEXT_IT_TO_ME',
        type: 'label',
        value: 'TEXT_IT_TO_ME',
      },
      {
        defaultText: 'EMAIL IT TO ME',
        key: 'label.EMAIL_IT_TO_ME',
        type: 'label',
        value: 'EMAIL_IT_TO_ME',
      },
      {
        defaultText: 'NO, THANK YOU',
        key: 'label.NO_THANK_YOU',
        type: 'label',
        value: 'NO_THANK_YOU',
      },
      {
        defaultText: 'WOULD YOU LIKE A RECEIPT?',
        key: 'customMessage.WOULD_YOU_LIKE_A_RECEIPT',
        type: 'customMessage',
        value: 'WOULD_YOU_LIKE_A_RECEIPT',
      },
      {
        defaultText: 'SEND TEXT',
        key: 'label.SEND_TEXT',
        type: 'label',
        value: 'SEND_TEXT',
      },
      {
        defaultText: 'SEND EMAIL',
        key: 'label.SEND_EMAIL',
        type: 'label',
        value: 'SEND_EMAIL',
      },
      {
        defaultText: 'PAYMENT DUE',
        key: 'label.PAYMENT_DUE',
        type: 'label',
        value: 'PAYMENT_DUE',
      },
      {
        defaultText: 'Total due',
        key: 'label.PAYEMENT_TOTAL_DUE',
        type: 'label',
        value: 'PAYEMENT_TOTAL_DUE',
      },
      {
        defaultText: 'Total paid',
        key: 'label.TOTAL_PAID',
        type: 'label',
        value: 'TOTAL_PAID',
      },
      {
        defaultText: 'CUSTOM AMOUNT',
        key: 'label.KIOSK_CUSTOM_AMOUNT',
        type: 'label',
        value: 'KIOSK_CUSTOM_AMOUNT',
      },
      {
        defaultText: 'CUSTOM PERCENT',
        key: 'label.KIOSK_CUSTOM_PERCENT',
        type: 'label',
        value: 'KIOSK_CUSTOM_PERCENT',
      },
      {
        defaultText: 'YOUR ORDER HAS BEEN PLACED!',
        key: 'customMessage.YOUR_ORDER_PLACED',
        type: 'customMessage',
        value: 'YOUR_ORDER_PLACED',
      },
      {
        defaultText: 'THANK YOU!',
        key: 'customMessage.THANK_YOU',
        type: 'customMessage',
        value: 'THANK_YOU',
      },
      {
        defaultText: 'PLEASE COMPLETE PAYMENT ON THE PIN PAD BELOW',
        key: 'customMessage.PLEASE_CONTINUE_THE_PAYMENT',
        type: 'customMessage',
        value: 'PLEASE_CONTINUE_THE_PAYMENT',
      },
      {
        defaultText: 'PRESS CONTINUE WHEN READY',
        key: 'customMessage.PRESS_CONTINUE',
        type: 'customMessage',
        value: 'PRESS_CONTINUE',
      },
      {
        defaultText: 'Press retry when ready',
        key: 'customMessage.RETRY_WHEN_READY',
        type: 'customMessage',
        value: 'RETRY_WHEN_READY',
      },
      {
        defaultText: 'BACK TO CART',
        key: 'label.BACK_TO_CART',
        type: 'label',
        value: 'BACK_TO_CART',
      },
      {
        defaultText: 'PAY AT COUNTER',
        key: 'label.PAY_AT_COUNTER',
        type: 'label',
        value: 'PAY_AT_COUNTER',
      },
      {
        defaultText: 'PLEASE COMPLETE PAYMENT AT THE COUNTER',
        key: 'customMessage.PLEASE_COMPLETE_PAYMENT_AT_THE_COUNTER',
        type: 'customMessage',
        value: 'PLEASE_COMPLETE_PAYMENT_AT_THE_COUNTER',
      },
      {
        defaultText: 'PRESS CONTINUE TO PLACE ORDER',
        key: 'customMessage.PRESS_CONTINUE_TO_PLACE_ORDER',
        type: 'customMessage',
        value: 'PRESS_CONTINUE_TO_PLACE_ORDER',
      },
    ]
  },
  SurveyScreen: {
    screenLabel: 'Survey Screen',
    image: [KioskSurveyScreen],
    customLabels: [
      {
        defaultText: 'Skip Survey',
        key: 'label.SKIP_SURVEY',
        type: 'label',
        value: 'SKIP_SURVEY',
      },
      {
        defaultText: 'Next',
        key: 'label.NEXT',
        type: 'label',
        value: 'NEXT',
      },
      {
        defaultText: 'Previous',
        key: 'label.PREVIOUS',
        type: 'label',
        value: 'PREVIOUS',
      },
      {
        defaultText: 'Done',
        key: 'label.DONE',
        type: 'label',
        value: 'DONE',
      },
    ]
  },
  OtherScreen: {
    screenLabel: 'Other Screen',
    image: [],
    customLabels: [
      {
        defaultText: 'Stay',
        key: 'label.STAY',
        type: 'label',
        value: 'STAY',
      },
      {
        defaultText: 'You will be logged out for inactivity in',
        key: 'customMessage.INACTIVITY_LOG_OUT',
        type: 'customMessage',
        value: 'INACTIVITY_LOG_OUT',
      },
      {
        defaultText: 'CANCEL ORDER',
        key: 'label.CANCEL_ORDER',
        type: 'label',
        value: 'CANCEL_ORDER',
      },
      {
        defaultText: 'CONTINUE ORDER',
        key: 'label.CONTINUE_ORDER',
        type: 'label',
        value: 'CONTINUE_ORDER',
      },
      {
        defaultText: 'Your order will be cancelled for inactivity in',
        key: 'customMessage.IN_ACTIVITY_MESSAGE',
        type: 'customMessage',
        value: 'IN_ACTIVITY_MESSAGE',
      },
      {
        defaultText: 'You will be redirected to landing page in',
        key: 'customMessage.IN_RECEIPT_ACTIVITY_MESSAGE',
        type: 'customMessage',
        value: 'IN_RECEIPT_ACTIVITY_MESSAGE',
      },
      {
        defaultText: 'START NEW ORDER',
        key: 'label.START_NEW_ORDER',
        type: 'label',
        value: 'START_NEW_ORDER',
      },
    ]
  },
};
