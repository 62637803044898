import LABEL from './label';
import { getUserRoleId } from '@utility/userInfo';

export default {
  // Super Admin (RoleId: 100) and Tech Support (RoleId: 90)
  SUPER_ADMIN: {
    COMPANY_OPTIONS: {
      config: [
        {
          link: '/company/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editCompany',
          isURLParamRequired: true,
        },
        {
          link: '/brand/list',
          icon: 'fa fa-eye',
          title: LABEL.BRAND,
          name: 'viewBrands'
        },
        {
          link: '/user/list',
          icon: 'fa fa-user-plus',
          title: LABEL.USER,
          name: 'viewUsers'
        },
        {
          link: '/mall/list',
          icon: 'fa fa-building-o',
          title: LABEL.VENUES,
          name: 'venueList',
        },
        {
          link: '/company/api&webhooks',
          icon: 'fa fa-external-link-square',
          title: LABEL.API_WEBHOOKS,
          name: 'externalIntegration',
        },
        {
          link: '/company/externalIntegration/identifiers',
          icon: 'fa fa-cogs',
          title: LABEL.INTEGRATION_CONFIGURATION,
          name: 'integrationConfiguration',
        },
        {
          popUp: true,
          icon: 'fa fa-remove',
          title: LABEL.DEACTIVATE,
          name: 'companyDeactivate',
          idType: 'companyId',
          // label: 'Company Deactivate',
          // keyName: 'Company Id',
          // type: 'companies',
        },
      ]
    },
    BRAND_OPTIONS: {
      config: [
        {
          link: '/brand/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editBrand',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.IMAGES,
          name: 'editBrandImage',
        },
        {
          link: '/store/list',
          icon: 'fa fa-building-o',
          title: LABEL.STORES,
          name: 'viewStores'
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/brand/taxSetup',
            //   icon: 'fa fa-percent',
            //   title: LABEL.TAX_RATE,
            //   name: 'taxrate',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/tax/list',
              icon: 'fa fa-percent',
              title: LABEL.TAX,
              name: 'taxes',
            },
            {
              // link: '/brand/tips',
              link: '/brand/tips/list',
              icon: 'fa fa-pencil',
              title: LABEL.TIPS,
              name: 'tips',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/charity/list',
              icon: 'fa fa-handshake-o',
              title: LABEL.CHARITY,
              name: 'charity',
            },
            {
              link: '/brand/tiles',
              icon: 'fa fa-sticky-note',
              title: LABEL.TILES,
              name: 'tiles',
            },
            // {
            //   link: '/brand/vouchers/list',
            //   icon: 'fa fa-ticket',
            //   title: LABEL.VOUCHERS,
            //   name: 'vouchers',
            // },
            {
              link: '/brand/tags',
              icon: 'fa fa-tags',
              title: LABEL.TAGS,
              name: 'tags',
            },
            {
              link: '/brand/giftcard',
              icon: 'fa fa-gift',
              title: LABEL.GIFT_CARD_SALE,
              name: 'giftcard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/delivery/list',
              icon: 'fa fa-truck',
              title: LABEL.DELIVERY,
              name: 'deliveryList',
            },
            {
              link: '/brand/charges/list',
              icon: 'fa fa-usd',
              title: LABEL.CHARGES,
              name: 'chargesList',
            },
            {
              link: '/brand/marketPlace',
              icon: 'fa fa-shopping-cart',
              title: LABEL.MARKETPLACE,
              name: 'marketPlace',
              isURLParamRequired: true,
            },
            {
              link: '/brand/holidays/list',
              icon: 'fa fa-calendar',
              title: LABEL.HOLIDAYS,
              name: 'holidays',
            },
            {
              link: '/brand/productionRate',
              icon: 'fa fa-industry',
              title: LABEL.PRODUCTION_RATE,
              name: 'productionRate',
            },
            {
              link: '/brand/riskRule/list',
              icon: 'fa fa-file',
              title: LABEL.RISK_RULES,
              name: 'riskrules',
            },
            /* {
              link: '/brand/prepTime/list',
              icon: 'fa fa-clock-o',
              title: LABEL.PREP_TIME,
              name: 'prepTime',
            }, */
            {
              link: '/brand/sensorGroups',
              icon: 'fa fa-qrcode',
              title: LABEL.SENSOR_GROUP,
              name: 'sensorGroup',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/deviceGroup/list',
              icon: 'fa fa-sitemap',
              title: LABEL.DEVICE_GROUP,
              name: 'deviceGroup',
            },
            {
              link: '/brand/printCategory/list',
              icon: 'fa fa-list-alt',
              title: LABEL.PRINT_CATEGORY,
              name: 'printCategory',
            },
            // {
            //   link: '/brand/posSystemConfig',
            //   icon: 'fa fa-cutlery',
            //   title: LABEL.POS_MENU_LOAD,
            //   name: 'posSystemConfig',
            //   isURLParamRequired: true,
            // },
            {
              title: 'POS Agent',
              name: 'Pos agent',
              icon: 'fa fa-cutlery',
              children: [
                {
                  link: '/brand/multiLingual/tile-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.MENU_LOAD,
                  name: 'tileName',
                },
                {
                  link: '/brand/multiLingual/tile-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DOWNLOAD,
                  name: 'tileDescription',
                },
              ]
            },
            {
              link: '/brand/tileSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            // {
            //   link: '/brand/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'tiles',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            /* {
              link: '/menubrowser/list',
              icon: 'fa fa-cutlery',
              title: LABEL.BROWSABLE_MENU,
              name: 'browsableMenu',
            }, */
            {
              link: '/customMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU,
              name: 'customMenu',
            },
            // {
            //   link: '/customMenu/category/list',
            //   icon: 'fa fa-list-alt',
            //   title: LABEL.CATEGORIES,
            //   name: 'customMenu',
            // },
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ],
        },
        {
          title: LABEL.KDS,
          name: 'brandKDS',
          children: [
            {
              link: '/brand/kds/settings',
              icon: 'fa fa-wrench',
              title: LABEL.KDS_SETTINGS,
              name: 'KDSSettings',
            },
            {
              link: '/brand/kds/stations/list',
              icon: 'fa fa-pencil',
              title: LABEL.STATION_TYPES,
              name: 'KDSStationTypes',
            },
            {
              link: '/brand/kds/layouts/list',
              icon: 'fa fa-list-alt',
              title: LABEL.LAYOUT_RULES,
              name: 'KDSLayouts',
            },
            {
              link: '/brand/kds/print/list',
              icon: 'fa fa-list-alt',
              title: LABEL.PRINT_RULES,
              name: 'KDSPrintRules',
            },
          ],
        },
        {
          title: 'Multi-lingual',
          children: [
            {
              title: 'Menu',
              name: 'menu',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menu-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuName',
                },
                {
                  link: '/brand/multiLingual/menu-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuDescription',
                },
                {
                  link: '/brand/multiLingual/menu-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Screen',
              name: 'menuScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuscreen-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuScreenName',
                },
                {
                  link: '/brand/multiLingual/menuscreen-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuScreenDescription',
                },
                {
                  link: '/brand/multiLingual/menuscreen-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuScreenDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Item',
              name: 'menuItem',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuitem-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'menuItemName',
                },
                {
                  link: '/brand/multiLingual/menuitem-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuItemDescription',
                },
                {
                  link: '/brand/multiLingual/menuitem-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuItemDisclaimer',
                },
              ],
            },
            {
              title: 'Modifier Group',
              name: 'modifierGroup',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifiergroup-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierGroupName',
                },
              ],
            },
            {
              title: 'Modifier',
              name: 'modifier',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifier-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierName',
                },
                {
                  link: '/brand/multiLingual/modifier-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'modifierDescription',
                },
                {
                  link: '/brand/multiLingual/modifier-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'modifierDisclaimer',
                },
              ],
            },
            {
              title: 'Tiles',
              name: 'tiles',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/tile-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'tileName',
                },
                {
                  link: '/brand/multiLingual/tile-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'tileDescription',
                },
              ]
            },
            {
              title: 'Survey Screen',
              name: 'SurveyScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/survey-welcomemessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.WELCOME_MSG,
                  name: 'surveyWelcomeMessage',
                },
                {
                  link: '/brand/multiLingual/survey-confirmationMessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.CONFIRMATION_MESSAGE,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyquestion-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_QUESTION_NAME,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyresponse-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_RESPONSE_NAME,
                  name: 'surveyConfirmationMessage',
                },
              ],
            },
          ],
        },
        {
          title: 'Channels',
          name: 'channels',
          children: [
            {
              link: '/brand/storeOffPremiseSetup',
              icon: 'fa fa-pencil',
              title: 'Setup',
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            },
            {
              link: '/brand/config-channel',
              icon: 'fa fa-pencil',
              title: 'Configure',
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            }
          ],
        },
        {
          title: 'Surveys',
          name: 'surveys',
          children: [
            {
              link: '/brand/survey/questionLibrary',
              icon: 'fa fa-list-alt',
              title: LABEL.QUESTION_LIBRARY,
              name: 'surveyQuestionLibrary',
            },
            {
              link: '/brand/survey/list',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_SURVEY,
              name: 'surveyList',
            }
          ],
        },
        {
          title: LABEL.CUSTOMIZATION,
          name: LABEL.CUSTOMIZATION.toLowerCase(),
          children: [
            {
              title: LABEL.THEME,
              name: LABEL.THEME.toLowerCase(),
              icon: 'fa fa-paint-brush',
              children: [
                {
                  link: '/brand/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.MOBILE_WEB,
                  name: 'brandTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/serverApp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.SERVER_APP,
                  name: 'serverAppTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kiosk/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KIOSK,
                  name: 'kioskTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kds/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KDS,
                  name: 'kdsTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/orderUp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.ORDER_UP,
                  name: 'orderUpTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/waitList/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.WAITLIST,
                  name: 'waitListTheme',
                  isURLParamRequired: true,
                }
              ],
            },
            {
              title: LABEL.LABELS,
              name: LABEL.LABELS.toLowerCase(),
              icon: 'fa fa-tag',
              children: [
                {
                  link: '/brand/languages/mobileApp',
                  icon: 'fa fa-tag',
                  title: LABEL.MOBILE_WEB,
                  name: 'mobileWeb',
                },
                {
                  link: '/brand/languages/serverApp',
                  icon: 'fa fa-tag',
                  title: LABEL.SERVER_APP,
                  name: 'serverApp',
                },
                {
                  link: '/brand/languages/kiosk',
                  icon: 'fa fa-tag',
                  title: LABEL.KIOSK,
                  name: 'kiosk',
                },
                {
                  link: '/brand/languages/kds',
                  icon: 'fa fa-tag',
                  title: LABEL.KDS,
                  name: 'kds',
                },
                {
                  link: '/brand/languages/orderUp',
                  icon: 'fa fa-tag',
                  title: LABEL.ORDER_UP,
                  name: 'orderUp',
                },
                {
                  link: '/brand/languages/waitList',
                  icon: 'fa fa-tag',
                  title: LABEL.WAIT_LIST,
                  name: 'waitList',
                },
              ],
            },
            {
              title: LABEL.TEMPLATES,
              name: LABEL.TEMPLATES.toLowerCase(),
              icon: 'fa fa-commenting-o',
              children: [
                {
                  link: '/brand/smsTemplates/list',
                  icon: 'fa fa-commenting-o',
                  title: LABEL.SMS_TEMPLATES,
                  name: 'smsTemplates',
                },
                {
                  link: '/brand/emailTemplates/list',
                  icon: 'fa fa-envelope',
                  title: LABEL.EMAIL_TEMPLATES,
                  name: 'emailList',
                },
              ],
            },
            {
              popUp: true,
              // link: '/custom/icon',
              icon: 'fa fa-picture-o',
              title: LABEL.IMAGES,
              name: 'customIcons',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/external/campaigns',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.EXTERNAL_CAMPAIGN,
              name: 'externalCampaign',
            },
          ],
        },
        {
          title: 'Marketing',
          children: [
            {
              link: '/brand/omsoffers/list',
              icon: 'fa fa-wrench',
              title: LABEL.OMS_SETUP,
              name: 'offersList',
              disabled: ![10, 90, 100].includes(getUserRoleId()) || false,
            },
            {
              link: '/brand/import/guests',
              icon: 'fa fa-pencil',
              title: LABEL.IMPORT_GUESTS,
              name: 'importGuest',
            },
            {
              link: '/brand/offer/list',
              icon: 'fa fa-gift',
              title: LABEL.GUESTS,
              name: 'brandOfferList',
            },
            {
              link: '/brand/campaign/list',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.CAMPAIGN,
              name: 'campaignList',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/segment/list',
              icon: 'fa fa-pencil',
              title: LABEL.SEGMENT,
              name: 'segmentList',
            },

            // {
            //   link: '/brand/campaignOccurrence/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_OCCURRENCE,
            //   name: 'campaignOccurrence',
            // },
            // {
            //   link: '/brand/campaignTriggers/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_TRIGGERS,
            //   name: 'campaignTriggers',
            // },
          ]
        },
        {
          title: 'Financial',
          children: [
            {
              link: '/brand/financial/chartOfAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.CHART_OF_ACCOUNTS,
              name: 'chartsOfAccounts',
            },
            {
              link: '/brand/financial/storeAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.STORE_ACCOUNTS,
              name: 'storeAccounts',
            },
            {
              link: '/brand/financial/storeAccountsBalance',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_BALANCES,
              name: 'storeAccountsBalance',
            },
            {
              link: '/brand/financial/storeAccountsJournal',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_JOURNAL,
              name: 'storeAccountsJournal',
            },
          ],
        },
        // {
        //   title: 'OMS',
        //   children: [
        //     {
        //       link: '/brand/offers/list',
        //       icon: 'fa fa-eye',
        //       title: LABEL.OFFERS,
        //       name: 'viewOffers'
        //     },
        //   ],
        // },
        {
          title: 'Third party',
          children: [
            /* {
              link: '/brand/pnp',
              icon: 'fa fa-pencil',
              title: LABEL.ONEDINE_OFFERS,
              name: 'pnp',
              isURLParamRequired: true,
            }, */
            {
              link: '/brand/clutchLoyalty',
              icon: 'fa fa-pencil',
              title: LABEL.CLUTCH_LOYALTY,
              name: 'clutchLoyalty',
              isURLParamRequired: true,
            },
            {
              link: '/brand/paytronixLoyalty',
              icon: 'fa fa-pencil',
              title: LABEL.PAYTRONIX_LOYALTY,
              name: 'payTronixLoyalty',
              isURLParamRequired: true,
            },
            {
              link: '/brand/punchh',
              icon: 'fa fa-pencil',
              title: LABEL.PUNCHH,
              name: 'Punchh',
              isURLParamRequired: true,
            },
            {
              link: '/sparkfly/setup',
              icon: 'fa fa fa-pencil',
              title: LABEL.SPARKFLY_SETUP,
              name: 'sparkfly',
              isURLParamRequired: true,
            },
            {
              link: '/brand/spendgo',
              icon: 'fa fa-pencil',
              title: LABEL.SPENDGO,
              name: 'Spendgo',
              isURLParamRequired: true,
            },
            /* {
              link: '/brand/fishbowl',
              icon: 'fa fa-pencil',
              title: LABEL.FISHBOWL,
              name: 'Fishbowl',
              isURLParamRequired: true,
            }, */
            {
              horizontalRule: true
            },
            {
              link: '/brand/fiserv',
              icon: 'fa fa-pencil',
              title: LABEL.FISERV,
              name: 'fiserv',
              isURLParamRequired: true,
            },
            {
              link: '/brand/givex',
              icon: 'fa fa-pencil',
              title: LABEL.GIVEX_GIFT_CARD,
              name: 'Heartland',
              isURLParamRequired: true,
            },
            {
              link: '/brand/heartland',
              icon: 'fa fa-pencil',
              title: LABEL.HEARTLAND_GIFT_CARD,
              name: 'Heartland',
              isURLParamRequired: true,
            },
            {
              link: '/brand/paytronixGiftCard',
              icon: 'fa fa-pencil',
              title: LABEL.PAYTRONIX_GIFT_CARD,
              name: 'payTronixGiftCard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/svs',
              icon: 'fa fa-pencil',
              title: LABEL.SVS,
              name: 'SVS',
              isURLParamRequired: true,
            },
            {
              link: '/brand/valueTec',
              icon: 'fa fa-pencil',
              title: LABEL.VALUE_TEC_GIFT_CARD,
              name: 'valueTecGiftCard',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/medallia',
              icon: 'fa fa-pencil',
              title: LABEL.MEDALLIA,
              name: 'MEDALLIA',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/siftFraudSystem',
              icon: 'fa fa-pencil',
              title: LABEL.SIFT,
              name: 'SIFT',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/userWay',
              icon: 'fa fa-pencil',
              title: LABEL.USERWAY,
              name: 'UserWay',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/deliverySetup',
              icon: 'fa fa-pencil',
              title: LABEL.DELIVERY,
              name: 'Delivery',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/chowly',
              icon: 'fa fa-pencil',
              title: LABEL.CHOWLY,
              name: 'chowly',
              isURLParamRequired: true,
            },
            {
              link: '/brand/otter',
              icon: 'fa fa-pencil',
              title: LABEL.OTTER,
              name: 'otter',
              isURLParamRequired: true,
            },
            {
              link: '/brand/ezCater',
              icon: 'fa fa-pencil',
              title: LABEL.EZCATER,
              name: 'ezCater',
              isURLParamRequired: true,
            },
            // {
            //   link: '/brand/happyCog',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.HAPPY_COG,
            //   name: 'happyCog',
            //   isURLParamRequired: true,
            // },
            {
              horizontalRule: true
            },
            {
              link: '/brand/rokt',
              icon: 'fa fa-pencil',
              title: LABEL.ROKT,
              name: 'rokt',
              isURLParamRequired: true,
            },
            {
              link: '/brand/tsdGlobal',
              icon: 'fa fa-pencil',
              title: LABEL.TSD_GLOBAL,
              name: 'tsdGlobal',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            // {
            //   link: '/brand/restaurantMagic',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.RESTAURANT_MAGIC,
            //   name: 'restaurantMagic',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/brand/marketMan',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MARKETMAN,
            //   name: 'marketMan',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/intercard',
              icon: 'fa fa-pencil',
              title: LABEL.INTERCARD,
              name: 'intercard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/powercard',
              icon: 'fa fa-pencil',
              title: LABEL.POWERCARD,
              name: 'powercard',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            // {
            //   link: '/brand/restaurantMagic',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.RESTAURANT_MAGIC,
            //   name: 'restaurantMagic',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/brand/marketMan',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MARKETMAN,
            //   name: 'marketMan',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/spendgoWebhooks',
              icon: 'fa fa-pencil',
              title: LABEL.SPENDGO_WEBHOOKS,
              name: 'spendgoWebhooks',
              isURLParamRequired: true,
            },
            {
              link: '/brand/wiselyWebhooks',
              icon: 'fa fa-pencil',
              title: LABEL.WISELY_WEBHOOKS,
              name: 'wiselyWebhooks',
              isURLParamRequired: true,
            },
          ],
        },
        {
          title: 'Mobile App',
          children: [
            {
              link: '/brand/mobileApp/theme',
              icon: 'fa fa-paint-brush',
              title: LABEL.THEME,
              name: 'mobileAppTheme',
              isURLParamRequired: true,
            },
            {
              link: '/brand/mobileApp/layouts/list',
              icon: 'fa fa-list-alt',
              title: LABEL.MOBILE_APP_LAYOUT,
              name: 'mobileAppLayouts',
            },
            {
              link: '/brand/mobileApp/page/list',
              icon: 'fa fa-file-text-o',
              title: LABEL.PAGES,
              name: 'mobileAppPages',
              isURLParamRequired: false,
            },
            {
              link: '/brand/mobileApp/media/list',
              icon: 'fa fa-pencil',
              title: LABEL.MOBILE_APP_MEDIA,
              name: 'media',
              isURLParamRequired: false,
            },
          ],
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
            // {
            //   link: '/brand/offers/list',
            //   icon: 'fa fa-eye',
            //   title: LABEL.OFFERS,
            //   name: 'viewOffers'
            // },
          ],
        },
        {
          popUp: true,
          icon: 'fa fa-remove',
          title: LABEL.DEACTIVATE,
          name: 'brandDeactivate',
          idType: 'brandId',
          // label: 'Brand Deactivate',
          // keyName: 'Brand Id',
          // type: 'stores',

        },
        {
          link: '/brand/batchServices',
          icon: 'fa fa-wrench',
          title: LABEL.ONEDINE_SERVICES,
          name: 'batchService',
          isURLParamRequired: true,
        },
      ],
    },
    STORE_OPTIONS: {
      config: [
        {
          link: '/store/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editStore',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.EDIT_IMAGE,
          name: 'editImage',
        },
        {
          title: 'Configuration',
          children: [
            {
              link: '/store/guestApp',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_APP,
              name: 'guestApp',
            },
            {
              link: '/store/serverApp',
              icon: 'fa fa-pencil',
              title: LABEL.SERVER_APP,
              name: 'serverApp',
            },
          ],
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/store/mapping',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MAPPINGS,
            //   name: 'mappings',
            //   isURLParamRequired: true,
            // },
            {
              link: '/store/sections',
              icon: 'fa fa-list-alt',
              title: LABEL.SECTIONS,
              name: 'section',
            },
            {
              link: '/store/tables/list',
              icon: 'fa fa-table',
              title: LABEL.TABLE,
              name: 'tables',
            },
            {
              link: '/store/jobs',
              icon: 'fa fa-briefcase',
              title: LABEL.JOB,
              name: 'jobs',
            },
            {
              link: '/store/teams',
              icon: 'fa fa-users',
              title: LABEL.TEAMS,
              name: 'teams',
            },
            {
              link: '/store/employees',
              icon: 'fa fa-user-circle-o',
              title: LABEL.EMPLOYEE,
              name: 'jobs',
            },
            {
              link: '/store/categories',
              icon: 'fa fa-list-alt',
              title: LABEL.CATEGORIES,
              name: 'categories',
            },
            {
              link: '/store/discounts',
              icon: 'fa fa-percent',
              title: LABEL.DISCOUNT,
              name: 'discounts',
            },
            {
              link: '/store/serviceCharges',
              icon: 'fa fa-usd',
              title: LABEL.SERVICE_CHARGE_STORE,
              name: 'serviceCharge',
            },
            {
              link: '/store/orderTypes',
              icon: 'fa fa-first-order',
              title: LABEL.ORDER_TYPES,
              name: 'orderTypes',
            },
            {
              link: '/store/revenueCenters',
              icon: 'fa fa-pencil',
              title: LABEL.REVENUE_CENTERS,
              name: 'revenueCenters',
            },
            {
              link: '/store/tenderTypes',
              icon: 'fa fa-gavel',
              title: LABEL.TENDER_TYPES,
              name: 'tenderTypes',
            },
            {
              link: '/store/hours',
              icon: 'fa fa-clock-o',
              title: LABEL.STORE_HOUR,
              name: 'hours',
            },
            {
              link: '/store/drawers',
              icon: 'fa fa-list-alt',
              title: LABEL.DRAWER,
              name: 'drawer',
            },
            {
              link: '/cubby/list',
              icon: 'fa fa-th-large',
              title: LABEL.CUBBY,
              name: 'cubby',
            },
            {
              link: '/store/otherSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            {
              link: '/reports/payment-adjustment',
              icon: 'fa fa-shield',
              title: LABEL.PAYMENT_ADJUSTMENT,
              name: 'Payment adjustment report',
            },
            // {
            //   link: '/store/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'mdmpolicy',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Mappings',
          children: [
            {
              link: '/store/storeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.STORE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/tenderTypeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.TENDER_TYPE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/offPremiseMappings',
              icon: 'fa fa-pencil',
              title: LABEL.OFF_PREMISE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/posSmsMappings',
              icon: 'fa fa-pencil',
              title: LABEL.SMS_MAPPINGS,
              name: 'mappings',
            },
          ]
        },
        {
          title: 'Sensors',
          children: [
            {
              link: '/store/sensor-assignment/list',
              icon: 'fa fa-qrcode',
              title: LABEL.TABLE_SENSOR,
              name: 'scanner',
            },
            {
              link: '/store/namedSensor/6',
              icon: 'fa fa-qrcode',
              title: LABEL.STORE_ON_PREMISE_SENSOR,
              name: 'storeNamedScanner',
            },
            /* {
              link: '/store/namedSensor/9',
              icon: 'fa fa-qrcode',
              title: LABEL.VENUE_ON_PREMISE_SENSOR,
              name: 'venueNamedScanner',
            }, */
            {
              link: '/store/offPremiseSensors/11',
              icon: 'fa fa-qrcode',
              title: LABEL.OTHER_SENSOR,
              name: 'deliveryLocationScanner',
            }
          ]
        },
        {
          title: 'Menu',
          children: [
            {
              link: '/posMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.POS_MENU,
              name: 'posMenu',
            },
          ],
        },
        {
          title: 'Device',
          children: [
            {
              link: '/device/register',
              icon: 'fa fa-plus',
              title: LABEL.REGISTER_DEVICE,
              name: 'registerDevice',
            },
            {
              link: '/device/list',
              icon: 'fa fa-list-alt',
              title: LABEL.VIEW_DEVICES,
              name: 'viewDevices',
            },
            // {
            //   link: '/device/mdm/list',
            //   icon: 'fa fa-eye',
            //   title: LABEL.MDM_DEVICES,
            //   name: 'viewDevices',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/sensor-assignment',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.TABLE_SENSOR,
            //   name: 'scanner',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/namedSensor/6',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.STORE_NAMED_SENSOR,
            //   name: 'storeNamedScanner',
            // },
            // {
            //   link: '/store/namedSensor/9',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.VENUE_NAMED_SENSOR,
            //   name: 'venueNamedScanner',
            // }
          ],
        },
        /* {
          title: 'Charity',
          children: [
            {
              link: '/charity/add',
              icon: 'fa fa-plus',
              title: LABEL.ADD_CHARITY,
              name: 'addCharity',
            },
            {
              link: '/charity/list',
              icon: 'fa fa-eye',
              title: LABEL.VIEW_CHARITY,
              name: 'viewCharity',
            }
          ],
        }, */
        {
          title: 'Third party',
          children: [
            {
              link: '/store/posSystem',
              icon: 'fa fa-pencil',
              title: LABEL.POS,
              name: 'posSystem',
              isURLParamRequired: true,
            },
            // {
            //   link: '/store/paymentSystem',
            //   icon: 'fa fa-pencil',
            //   title: 'Payment',
            //   name: 'paymentSystem',
            //   isURLParamRequired: true,
            // },
            {
              link: '/store/mobilePaymentSetup',
              icon: 'fa fa-credit-card-alt',
              title: LABEL.MOBILE_PAYMENTS,
              name: 'mobilePayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/paypal',
              icon: 'fa fa-paypal',
              title: LABEL.PAYPAL,
              name: 'paypalPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/cabbagePay',
              icon: 'fa fa-credit-card',
              title: LABEL.CABBAGEPAY_SETUP,
              name: 'cabbagePayPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/amazonPay',
              icon: 'fa fa-amazon',
              title: LABEL.AMAZON_PAY,
              name: 'amazonPayPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/stripe',
              icon: 'fa fa-cc-stripe',
              title: LABEL.STRIPE,
              name: 'stripePayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/spreedly',
              icon: 'fa fa-pencil',
              title: LABEL.SPREEDLY_FOR_TOAST,
              name: 'spreedlyPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/ingenico',
              icon: 'fa fa-money',
              title: LABEL.INGENICO,
              name: 'ingenico',
              isURLParamRequired: true,
            },
            {
              link: '/store/cardconnectbolt',
              icon: 'fa fa-money',
              title: LABEL.CARD_CONNECT_BOLT,
              name: 'cardconnectbolt',
              isURLParamRequired: true,
            },
            {
              link: '/store/nmiPayments',
              icon: 'fa fa-money',
              title: LABEL.NMI_PAYMENTS,
              name: 'nmiPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/paymentSense',
              icon: 'fa fa-money',
              title: LABEL.PAYMENT_SENSE,
              name: 'paymentSense',
              isURLParamRequired: true,
            },
            // {
            //   link: '/store/bookingSystem',
            //   icon: 'fa fa-pencil',
            //   title: 'Reservation System',
            //   name: 'bookingSystem',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
          ],
        },
        {
          popUp: true,
          icon: 'fa fa-remove',
          title: LABEL.DEACTIVATE,
          name: 'storeDeactivate',
          idType: 'storeId',
          // label: 'Store Deactivate',
          // keyName: 'Store Id',
          // type: 'stores',
        },
        {
          link: '/store/batchServices',
          icon: 'fa fa-wrench',
          title: LABEL.ONEDINE_SERVICES,
          name: 'batchService',
          isURLParamRequired: true,
        },
      ],
    },
    SIDE_MENUS: [
      {
        link: '/batchReport',
        icon: 'fa fa-book',
        title: LABEL.BATCH_REPORT,
        name: 'batchReport',
      },
    ],
  },
  // Tech Support (RoleId: 90)
  TECH_SUPPORT: {
    COMPANY_OPTIONS: {
      config: [
        {
          link: '/company/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editCompany',
          isURLParamRequired: true,
        },
        {
          link: '/brand/list',
          icon: 'fa fa-eye',
          title: LABEL.BRAND,
          name: 'viewBrands'
        },
        {
          link: '/user/list',
          icon: 'fa fa-user-plus',
          title: LABEL.USER,
          name: 'viewUsers'
        },
        {
          link: '/mall/list',
          icon: 'fa fa-building-o',
          title: LABEL.VENUES,
          name: 'venueList',
        },
        {
          link: '/company/externalIntegration/identifiers',
          icon: 'fa fa-cogs',
          title: LABEL.INTEGRATION_CONFIGURATION,
          name: 'integrationConfiguration',
        },
      ]
    },
    BRAND_OPTIONS: {
      config: [
        {
          link: '/brand/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editBrand',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.IMAGES,
          name: 'editBrandImage',
        },
        {
          link: '/store/list',
          icon: 'fa fa-building-o',
          title: LABEL.STORES,
          name: 'viewStores'
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/brand/taxSetup',
            //   icon: 'fa fa-percent',
            //   title: LABEL.TAX_RATE,
            //   name: 'taxrate',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/tax/list',
              icon: 'fa fa-percent',
              title: LABEL.TAX,
              name: 'taxes',
            },
            {
              // link: '/brand/tips',
              link: '/brand/tips/list',
              icon: 'fa fa-pencil',
              title: LABEL.TIPS,
              name: 'tips',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/charity/list',
              icon: 'fa fa-handshake-o',
              title: LABEL.CHARITY,
              name: 'charity',
            },
            {
              link: '/brand/tiles',
              icon: 'fa fa-sticky-note',
              title: LABEL.TILES,
              name: 'tiles',
            },
            // {
            //   link: '/brand/vouchers/list',
            //   icon: 'fa fa-ticket',
            //   title: LABEL.VOUCHERS,
            //   name: 'vouchers',
            // },
            {
              link: '/brand/tags',
              icon: 'fa fa-tags',
              title: LABEL.TAGS,
              name: 'tags',
            },
            {
              link: '/brand/giftcard',
              icon: 'fa fa-gift',
              title: LABEL.GIFT_CARD_SALE,
              name: 'giftcard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/delivery/list',
              icon: 'fa fa-truck',
              title: LABEL.DELIVERY,
              name: 'deliveryList',
            },
            {
              link: '/brand/charges/list',
              icon: 'fa fa-usd',
              title: LABEL.CHARGES,
              name: 'chargesList',
            },
            {
              link: '/brand/marketPlace',
              icon: 'fa fa-shopping-cart',
              title: LABEL.MARKETPLACE,
              name: 'marketPlace',
              isURLParamRequired: true,
            },
            {
              link: '/brand/holidays/list',
              icon: 'fa fa-calendar',
              title: LABEL.HOLIDAYS,
              name: 'holidays',
            },
            {
              link: '/brand/productionRate',
              icon: 'fa fa-industry',
              title: LABEL.PRODUCTION_RATE,
              name: 'productionRate',
            },
            {
              link: '/brand/riskRule/list',
              icon: 'fa fa-file',
              title: LABEL.RISK_RULES,
              name: 'riskrules',
            },
            /* {
              link: '/brand/prepTime/list',
              icon: 'fa fa-clock-o',
              title: LABEL.PREP_TIME,
              name: 'prepTime',
            }, */
            {
              link: '/brand/sensorGroups',
              icon: 'fa fa-qrcode',
              title: LABEL.SENSOR_GROUP,
              name: 'sensorGroup',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/deviceGroup/list',
              icon: 'fa fa-sitemap',
              title: LABEL.DEVICE_GROUP,
              name: 'deviceGroup',
            },
            {
              link: '/brand/printCategory/list',
              icon: 'fa fa-list-alt',
              title: LABEL.PRINT_CATEGORY,
              name: 'printCategory',
            },
            {
              link: '/brand/posSystemConfig',
              icon: 'fa fa-cutlery',
              title: LABEL.POS_MENU_LOAD,
              name: 'posSystemConfig',
              isURLParamRequired: true,
            },
            {
              link: '/brand/tileSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            // {
            //   link: '/brand/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'tiles',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            /* {
              link: '/menubrowser/list',
              icon: 'fa fa-cutlery',
              title: LABEL.BROWSABLE_MENU,
              name: 'browsableMenu',
            }, */
            {
              link: '/customMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU,
              name: 'customMenu',
            },
            // {
            //   link: '/customMenu/category/list',
            //   icon: 'fa fa-list-alt',
            //   title: LABEL.CATEGORIES,
            //   name: 'customMenu',
            // },
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ],
        },
        {
          title: LABEL.KDS,
          name: 'brandKDS',
          children: [
            {
              link: '/brand/kds/settings',
              icon: 'fa fa-wrench',
              title: LABEL.KDS_SETTINGS,
              name: 'KDSSettings',
            },
            {
              link: '/brand/kds/stations/list',
              icon: 'fa fa-pencil',
              title: LABEL.STATION_TYPES,
              name: 'KDSStationTypes',
            },
            {
              link: '/brand/kds/layouts/list',
              icon: 'fa fa-list-alt',
              title: LABEL.LAYOUT_RULES,
              name: 'KDSLayouts',
            },
            {
              link: '/brand/kds/print/list',
              icon: 'fa fa-list-alt',
              title: LABEL.PRINT_RULES,
              name: 'KDSPrintRules',
            },
          ],
        },
        {
          title: 'Multi-lingual',
          children: [
            {
              title: 'Menu',
              name: 'menu',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menu-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuName',
                },
                {
                  link: '/brand/multiLingual/menu-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuDescription',
                },
                {
                  link: '/brand/multiLingual/menu-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Screen',
              name: 'menuScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuscreen-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuScreenName',
                },
                {
                  link: '/brand/multiLingual/menuscreen-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuScreenDescription',
                },
                {
                  link: '/brand/multiLingual/menuscreen-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuScreenDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Item',
              name: 'menuItem',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuitem-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'menuItemName',
                },
                {
                  link: '/brand/multiLingual/menuitem-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuItemDescription',
                },
                {
                  link: '/brand/multiLingual/menuitem-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuItemDisclaimer',
                },
              ],
            },
            {
              title: 'Modifier Group',
              name: 'modifierGroup',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifiergroup-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierGroupName',
                },
              ],
            },
            {
              title: 'Modifier',
              name: 'modifier',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifier-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierName',
                },
                {
                  link: '/brand/multiLingual/modifier-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'modifierDescription',
                },
                {
                  link: '/brand/multiLingual/modifier-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'modifierDisclaimer',
                },
              ],
            },
            {
              title: 'Tiles',
              name: 'tiles',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/tile-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'tileName',
                },
                {
                  link: '/brand/multiLingual/tile-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'tileDescription',
                },
              ]
            },
            {
              title: 'Survey Screen',
              name: 'SurveyScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/survey-welcomemessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.WELCOME_MSG,
                  name: 'surveyWelcomeMessage',
                },
                {
                  link: '/brand/multiLingual/survey-confirmationMessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.CONFIRMATION_MESSAGE,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyquestion-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_QUESTION_NAME,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyresponse-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_RESPONSE_NAME,
                  name: 'surveyConfirmationMessage',
                },
              ],
            },
          ],
        },
        {
          title: 'Channels',
          name: 'channels',
          children: [
            {
              link: '/brand/storeOffPremiseSetup',
              icon: 'fa fa-pencil',
              title: 'Setup',
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            },
            {
              link: '/brand/config-channel',
              icon: 'fa fa-pencil',
              title: 'Configure',
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            }
          ],
        },
        {
          title: 'Surveys',
          name: 'surveys',
          children: [
            {
              link: '/brand/survey/questionLibrary',
              icon: 'fa fa-list-alt',
              title: LABEL.QUESTION_LIBRARY,
              name: 'surveyQuestionLibrary',
            },
            {
              link: '/brand/survey/list',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_SURVEY,
              name: 'surveyList',
            }
          ],
        },
        {
          title: LABEL.CUSTOMIZATION,
          name: LABEL.CUSTOMIZATION.toLowerCase(),
          children: [
            {
              title: LABEL.THEME,
              name: LABEL.THEME.toLowerCase(),
              icon: 'fa fa-paint-brush',
              children: [
                {
                  link: '/brand/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.MOBILE_WEB,
                  name: 'brandTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/serverApp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.SERVER_APP,
                  name: 'serverAppTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kiosk/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KIOSK,
                  name: 'kioskTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kds/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KDS,
                  name: 'kdsTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/orderUp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.ORDER_UP,
                  name: 'orderUpTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/waitList/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.WAITLIST,
                  name: 'waitListTheme',
                  isURLParamRequired: true,
                }
              ],
            },
            {
              title: LABEL.LABELS,
              name: LABEL.LABELS.toLowerCase(),
              icon: 'fa fa-tag',
              children: [
                {
                  link: '/brand/languages/mobileApp',
                  icon: 'fa fa-tag',
                  title: LABEL.MOBILE_WEB,
                  name: 'mobileWeb',
                },
                {
                  link: '/brand/languages/serverApp',
                  icon: 'fa fa-tag',
                  title: LABEL.SERVER_APP,
                  name: 'serverApp',
                },
                {
                  link: '/brand/languages/kiosk',
                  icon: 'fa fa-tag',
                  title: LABEL.KIOSK,
                  name: 'kiosk',
                },
                {
                  link: '/brand/languages/kds',
                  icon: 'fa fa-tag',
                  title: LABEL.KDS,
                  name: 'kds',
                },
                {
                  link: '/brand/languages/orderUp',
                  icon: 'fa fa-tag',
                  title: LABEL.ORDER_UP,
                  name: 'orderUp',
                },
                {
                  link: '/brand/languages/waitList',
                  icon: 'fa fa-tag',
                  title: LABEL.WAIT_LIST,
                  name: 'waitList',
                },
              ],
            },
            {
              title: LABEL.TEMPLATES,
              name: LABEL.TEMPLATES.toLowerCase(),
              icon: 'fa fa-commenting-o',
              children: [
                {
                  link: '/brand/smsTemplates/list',
                  icon: 'fa fa-commenting-o',
                  title: LABEL.SMS_TEMPLATES,
                  name: 'smsTemplates',
                },
                {
                  link: '/brand/emailTemplates/list',
                  icon: 'fa fa-envelope',
                  title: LABEL.EMAIL_TEMPLATES,
                  name: 'emailList',
                },
              ],
            },
            {
              popUp: true,
              // link: '/custom/icon',
              icon: 'fa fa-picture-o',
              title: LABEL.IMAGES,
              name: 'customIcons',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/external/campaigns',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.EXTERNAL_CAMPAIGN,
              name: 'externalCampaign',
            },
          ],
        },
        {
          title: 'Marketing',
          children: [
            {
              link: '/brand/omsoffers/list',
              icon: 'fa fa-wrench',
              title: LABEL.OMS_SETUP,
              name: 'offersList',
              disabled: ![10, 90, 100].includes(getUserRoleId()) || false,
            },
            {
              link: '/brand/import/guests',
              icon: 'fa fa-pencil',
              title: LABEL.IMPORT_GUESTS,
              name: 'importGuest',
            },
            {
              link: '/brand/offer/list',
              icon: 'fa fa-gift',
              title: LABEL.GUESTS,
              name: 'brandOfferList',
            },
            {
              link: '/brand/campaign/list',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.CAMPAIGN,
              name: 'campaignList',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/segment/list',
              icon: 'fa fa-pencil',
              title: LABEL.SEGMENT,
              name: 'segmentList',
            },

            // {
            //   link: '/brand/campaignOccurrence/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_OCCURRENCE,
            //   name: 'campaignOccurrence',
            // },
            // {
            //   link: '/brand/campaignTriggers/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_TRIGGERS,
            //   name: 'campaignTriggers',
            // },
          ]
        },
        {
          title: 'Financial',
          children: [
            {
              link: '/brand/financial/chartOfAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.CHART_OF_ACCOUNTS,
              name: 'chartsOfAccounts',
            },
            {
              link: '/brand/financial/storeAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.STORE_ACCOUNTS,
              name: 'storeAccounts',
            },
            {
              link: '/brand/financial/storeAccountsBalance',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_BALANCES,
              name: 'storeAccountsBalance',
            },
            {
              link: '/brand/financial/storeAccountsJournal',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_JOURNAL,
              name: 'storeAccountsJournal',
            },
          ],
        },
        // {
        //   title: 'OMS',
        //   children: [
        //     {
        //       link: '/brand/offers/list',
        //       icon: 'fa fa-eye',
        //       title: LABEL.OFFERS,
        //       name: 'viewOffers'
        //     },
        //   ],
        // },
        {
          title: 'Third party',
          children: [
            /* {
              link: '/brand/pnp',
              icon: 'fa fa-pencil',
              title: LABEL.ONEDINE_OFFERS,
              name: 'pnp',
              isURLParamRequired: true,
            }, */
            {
              link: '/brand/clutchLoyalty',
              icon: 'fa fa-pencil',
              title: LABEL.CLUTCH_LOYALTY,
              name: 'clutchLoyalty',
              isURLParamRequired: true,
            },
            {
              link: '/brand/paytronixLoyalty',
              icon: 'fa fa-pencil',
              title: LABEL.PAYTRONIX_LOYALTY,
              name: 'payTronixLoyalty',
              isURLParamRequired: true,
            },
            {
              link: '/brand/punchh',
              icon: 'fa fa-pencil',
              title: LABEL.PUNCHH,
              name: 'Punchh',
              isURLParamRequired: true,
            },
            {
              link: '/spartfly/setup',
              icon: 'fa fa fa-pencil',
              title: LABEL.SPARKFLY_SETUP,
              name: 'sparkfly',
              isURLParamRequired: true,
            },
            {
              link: '/brand/spendgo',
              icon: 'fa fa-pencil',
              title: LABEL.SPENDGO,
              name: 'Spendgo',
              isURLParamRequired: true,
            },
            /* {
              link: '/brand/fishbowl',
              icon: 'fa fa-pencil',
              title: LABEL.FISHBOWL,
              name: 'Fishbowl',
              isURLParamRequired: true,
            }, */
            {
              horizontalRule: true
            },
            {
              link: '/brand/fiserv',
              icon: 'fa fa-pencil',
              title: LABEL.FISERV,
              name: 'fiserv',
              isURLParamRequired: true,
            },
            {
              link: '/brand/givex',
              icon: 'fa fa-pencil',
              title: LABEL.GIVEX_GIFT_CARD,
              name: 'Heartland',
              isURLParamRequired: true,
            },
            {
              link: '/brand/heartland',
              icon: 'fa fa-pencil',
              title: LABEL.HEARTLAND_GIFT_CARD,
              name: 'Heartland',
              isURLParamRequired: true,
            },
            {
              link: '/brand/paytronixGiftCard',
              icon: 'fa fa-pencil',
              title: LABEL.PAYTRONIX_GIFT_CARD,
              name: 'payTronixGiftCard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/svs',
              icon: 'fa fa-pencil',
              title: LABEL.SVS,
              name: 'SVS',
              isURLParamRequired: true,
            },
            {
              link: '/brand/valueTec',
              icon: 'fa fa-pencil',
              title: LABEL.VALUE_TEC_GIFT_CARD,
              name: 'valueTecGiftCard',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/medallia',
              icon: 'fa fa-pencil',
              title: LABEL.MEDALLIA,
              name: 'MEDALLIA',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/siftFraudSystem',
              icon: 'fa fa-pencil',
              title: LABEL.SIFT,
              name: 'SIFT',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/userWay',
              icon: 'fa fa-pencil',
              title: LABEL.USERWAY,
              name: 'UserWay',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/deliverySetup',
              icon: 'fa fa-pencil',
              title: LABEL.DELIVERY,
              name: 'Delivery',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/chowly',
              icon: 'fa fa-pencil',
              title: LABEL.CHOWLY,
              name: 'chowly',
              isURLParamRequired: true,
            },
            {
              link: '/brand/otter',
              icon: 'fa fa-pencil',
              title: LABEL.OTTER,
              name: 'otter',
              isURLParamRequired: true,
            },
            {
              link: '/brand/ezCater',
              icon: 'fa fa-pencil',
              title: LABEL.EZCATER,
              name: 'ezCater',
              isURLParamRequired: true,
            },
            {
              link: '/brand/happyCog',
              icon: 'fa fa-pencil',
              title: LABEL.HAPPY_COG,
              name: 'happyCog',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/rokt',
              icon: 'fa fa-pencil',
              title: LABEL.ROKT,
              name: 'rokt',
              isURLParamRequired: true,
            },
            {
              link: '/brand/tsdGlobal',
              icon: 'fa fa-pencil',
              title: LABEL.TSD_GLOBAL,
              name: 'tsdGlobal',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            {
              link: '/brand/intercard',
              icon: 'fa fa-pencil',
              title: LABEL.INTERCARD,
              name: 'intercard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/powercard',
              icon: 'fa fa-pencil',
              title: LABEL.POWERCARD,
              name: 'powercard',
              isURLParamRequired: true,
            },
            {
              horizontalRule: true
            },
            // {
            //   link: '/brand/restaurantMagic',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.RESTAURANT_MAGIC,
            //   name: 'restaurantMagic',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/brand/marketMan',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MARKETMAN,
            //   name: 'marketMan',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/spendgoWebhooks',
              icon: 'fa fa-pencil',
              title: LABEL.SPENDGO_WEBHOOKS,
              name: 'spendgoWebhooks',
              isURLParamRequired: true,
            },
            {
              link: '/brand/wiselyWebhooks',
              icon: 'fa fa-pencil',
              title: LABEL.WISELY_WEBHOOKS,
              name: 'wiselyWebhooks',
              isURLParamRequired: true,
            },
          ],
        },
        {
          title: 'Mobile App',
          children: [
            {
              link: '/brand/mobileApp/theme',
              icon: 'fa fa-paint-brush',
              title: LABEL.THEME,
              name: 'mobileAppTheme',
              isURLParamRequired: true,
            },
            {
              link: '/brand/mobileApp/layouts/list',
              icon: 'fa fa-list-alt',
              title: LABEL.MOBILE_APP_LAYOUT,
              name: 'mobileAppLayouts',
            },
            {
              link: '/brand/mobileApp/page/list',
              icon: 'fa fa-file-text-o',
              title: LABEL.PAGES,
              name: 'mobileAppPages',
              isURLParamRequired: false,
            },
            {
              link: '/brand/mobileApp/media/list',
              icon: 'fa fa-pencil',
              title: LABEL.MOBILE_APP_MEDIA,
              name: 'media',
              isURLParamRequired: false,
            },
          ],
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
            // {
            //   link: '/brand/offers/list',
            //   icon: 'fa fa-eye',
            //   title: LABEL.OFFERS,
            //   name: 'viewOffers'
            // },
          ],
        },
        {
          link: '/brand/batchServices',
          icon: 'fa fa-wrench',
          title: LABEL.ONEDINE_SERVICES,
          name: 'batchService',
          isURLParamRequired: true,
        },
      ],
    },
    STORE_OPTIONS: {
      config: [
        {
          link: '/store/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editStore',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.EDIT_IMAGE,
          name: 'editImage',
        },
        {
          title: 'Configuration',
          children: [
            {
              link: '/store/guestApp',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_APP,
              name: 'guestApp',
            },
            {
              link: '/store/serverApp',
              icon: 'fa fa-pencil',
              title: LABEL.SERVER_APP,
              name: 'serverApp',
            },
          ],
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/store/mapping',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MAPPINGS,
            //   name: 'mappings',
            //   isURLParamRequired: true,
            // },
            {
              link: '/store/sections',
              icon: 'fa fa-list-alt',
              title: LABEL.SECTIONS,
              name: 'section',
            },
            {
              link: '/store/tables/list',
              icon: 'fa fa-table',
              title: LABEL.TABLE,
              name: 'tables',
            },
            {
              link: '/store/jobs',
              icon: 'fa fa-briefcase',
              title: LABEL.JOB,
              name: 'jobs',
            },
            {
              link: '/store/teams',
              icon: 'fa fa-users',
              title: LABEL.TEAMS,
              name: 'teams',
            },
            {
              link: '/store/employees',
              icon: 'fa fa-user-circle-o',
              title: LABEL.EMPLOYEE,
              name: 'jobs',
            },
            {
              link: '/store/categories',
              icon: 'fa fa-list-alt',
              title: LABEL.CATEGORIES,
              name: 'categories',
            },
            {
              link: '/store/discounts',
              icon: 'fa fa-percent',
              title: LABEL.DISCOUNT,
              name: 'discounts',
            },
            {
              link: '/store/serviceCharges',
              icon: 'fa fa-usd',
              title: LABEL.SERVICE_CHARGE_STORE,
              name: 'serviceCharge',
            },
            {
              link: '/store/orderTypes',
              icon: 'fa fa-first-order',
              title: LABEL.ORDER_TYPES,
              name: 'orderTypes',
            },
            {
              link: '/store/revenueCenters',
              icon: 'fa fa-pencil',
              title: LABEL.REVENUE_CENTERS,
              name: 'revenueCenters',
            },
            {
              link: '/store/tenderTypes',
              icon: 'fa fa-gavel',
              title: LABEL.TENDER_TYPES,
              name: 'tenderTypes',
            },
            {
              link: '/store/hours',
              icon: 'fa fa-clock-o',
              title: LABEL.STORE_HOUR,
              name: 'hours',
            },
            {
              link: '/store/drawers',
              icon: 'fa fa-list-alt',
              title: LABEL.DRAWER,
              name: 'drawer',
            },
            {
              link: '/cubby/list',
              icon: 'fa fa-th-large',
              title: LABEL.CUBBY,
              name: 'cubby',
            },
            {
              link: '/store/otherSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            // {
            //   link: '/store/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'mdmpolicy',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Mappings',
          children: [
            {
              link: '/store/storeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.STORE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/tenderTypeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.TENDER_TYPE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/offPremiseMappings',
              icon: 'fa fa-pencil',
              title: LABEL.OFF_PREMISE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/posSmsMappings',
              icon: 'fa fa-pencil',
              title: LABEL.SMS_MAPPINGS,
              name: 'mappings',
            },
          ]
        },
        {
          title: 'Sensors',
          children: [
            {
              link: '/store/sensor-assignment/list',
              icon: 'fa fa-qrcode',
              title: LABEL.TABLE_SENSOR,
              name: 'scanner',
            },
            {
              link: '/store/namedSensor/6',
              icon: 'fa fa-qrcode',
              title: LABEL.STORE_ON_PREMISE_SENSOR,
              name: 'storeNamedScanner',
            },
            /* {
              link: '/store/namedSensor/9',
              icon: 'fa fa-qrcode',
              title: LABEL.VENUE_ON_PREMISE_SENSOR,
              name: 'venueNamedScanner',
            }, */
            {
              link: '/store/offPremiseSensors/11',
              icon: 'fa fa-qrcode',
              title: LABEL.OTHER_SENSOR,
              name: 'deliveryLocationScanner',
            }
          ]
        },
        {
          title: 'Menu',
          children: [
            {
              link: '/posMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.POS_MENU,
              name: 'posMenu',
            },
          ],
        },
        {
          title: 'Device',
          children: [
            {
              link: '/device/register',
              icon: 'fa fa-plus',
              title: LABEL.REGISTER_DEVICE,
              name: 'registerDevice',
            },
            {
              link: '/device/list',
              icon: 'fa fa-list-alt',
              title: LABEL.VIEW_DEVICES,
              name: 'viewDevices',
            },
            // {
            //   link: '/device/mdm/list',
            //   icon: 'fa fa-eye',
            //   title: LABEL.MDM_DEVICES,
            //   name: 'viewDevices',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/sensor-assignment',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.TABLE_SENSOR,
            //   name: 'scanner',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/namedSensor/6',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.STORE_NAMED_SENSOR,
            //   name: 'storeNamedScanner',
            // },
            // {
            //   link: '/store/namedSensor/9',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.VENUE_NAMED_SENSOR,
            //   name: 'venueNamedScanner',
            // }
          ],
        },
        /* {
          title: 'Charity',
          children: [
            {
              link: '/charity/add',
              icon: 'fa fa-plus',
              title: LABEL.ADD_CHARITY,
              name: 'addCharity',
            },
            {
              link: '/charity/list',
              icon: 'fa fa-eye',
              title: LABEL.VIEW_CHARITY,
              name: 'viewCharity',
            }
          ],
        }, */
        {
          title: 'Third party',
          children: [
            {
              link: '/store/posSystem',
              icon: 'fa fa-pencil',
              title: LABEL.POS,
              name: 'posSystem',
              isURLParamRequired: true,
            },
            // {
            //   link: '/store/paymentSystem',
            //   icon: 'fa fa-pencil',
            //   title: 'Payment',
            //   name: 'paymentSystem',
            //   isURLParamRequired: true,
            // },
            {
              link: '/store/mobilePaymentSetup',
              icon: 'fa fa-credit-card-alt',
              title: LABEL.MOBILE_PAYMENTS,
              name: 'mobilePayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/paypal',
              icon: 'fa fa-paypal',
              title: LABEL.PAYPAL,
              name: 'paypalPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/cabbagePay',
              icon: 'fa fa-credit-card',
              title: LABEL.CABBAGEPAY_SETUP,
              name: 'cabbagePayPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/amazonPay',
              icon: 'fa fa-amazon',
              title: LABEL.AMAZON_PAY,
              name: 'amazonPayPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/stripe',
              icon: 'fa fa-cc-stripe',
              title: LABEL.STRIPE,
              name: 'stripePayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/spreedly',
              icon: 'fa fa-pencil',
              title: LABEL.SPREEDLY_FOR_TOAST,
              name: 'spreedlyPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/ingenico',
              icon: 'fa fa-money',
              title: LABEL.INGENICO,
              name: 'ingenico',
              isURLParamRequired: true,
            },
            {
              link: '/store/cardconnectbolt',
              icon: 'fa fa-money',
              title: LABEL.CARD_CONNECT_BOLT,
              name: 'cardconnectbolt',
              isURLParamRequired: true,
            },
            {
              link: '/store/nmiPayments',
              icon: 'fa fa-money',
              title: LABEL.NMI_PAYMENTS,
              name: 'nmiPayments',
              isURLParamRequired: true,
            },
            {
              link: '/store/paymentSense',
              icon: 'fa fa-money',
              title: LABEL.PAYMENT_SENSE,
              name: 'paymentSense',
              isURLParamRequired: true,
            },
            // {
            //   link: '/store/bookingSystem',
            //   icon: 'fa fa-pencil',
            //   title: 'Reservation System',
            //   name: 'bookingSystem',
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
          ],
        },
        {
          link: '/store/batchServices',
          icon: 'fa fa-wrench',
          title: LABEL.ONEDINE_SERVICES,
          name: 'batchService',
          isURLParamRequired: true,
        },
      ],
    },
    SIDE_MENUS: [
      {
        link: '/batchReport',
        icon: 'fa fa-book',
        title: LABEL.BATCH_REPORT,
        name: 'batchReport',
      },
    ],
  },
  // Admin (RoleId: 10)
  STORE_ADMIN: {
    COMPANY_OPTIONS: {
      config: [
        {
          link: '/company/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editCompany',
          isURLParamRequired: true,
        },
        {
          link: '/brand/list',
          icon: 'fa fa-eye',
          title: LABEL.BRAND,
          name: 'viewBrands'
        },
        {
          link: '/user/list',
          icon: 'fa fa-user-plus',
          title: LABEL.USER,
          name: 'viewUsers'
        },
        {
          link: '/mall/list',
          icon: 'fa fa-building-o',
          title: LABEL.VENUES,
          name: 'venueList',
        },
      ]
    },
    BRAND_OPTIONS: {
      config: [
        {
          link: '/brand/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editBrand',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.IMAGES,
          name: 'editBrandImage',
        },
        {
          link: '/store/list',
          icon: 'fa fa-building-o',
          title: LABEL.STORES,
          name: 'viewStores'
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/brand/taxSetup',
            //   icon: 'fa fa-percent',
            //   title: LABEL.TAX_RATE,
            //   name: 'taxrate',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/tax/list',
              icon: 'fa fa-percent',
              title: LABEL.TAX,
              name: 'taxes',
            },
            {
              // link: '/brand/tips',
              link: '/brand/tips/list',
              icon: 'fa fa-pencil',
              title: LABEL.TIPS,
              name: 'tips',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/charity/list',
              icon: 'fa fa-handshake-o',
              title: LABEL.CHARITY,
              name: 'charity',
            },
            {
              link: '/brand/tiles',
              icon: 'fa fa-sticky-note',
              title: LABEL.TILES,
              name: 'tiles',
            },
            // {
            //   link: '/brand/vouchers/list',
            //   icon: 'fa fa-ticket',
            //   title: LABEL.VOUCHERS,
            //   name: 'vouchers',
            // },
            {
              link: '/brand/tags',
              icon: 'fa fa-tags',
              title: LABEL.TAGS,
              name: 'tags',
            },
            {
              link: '/brand/giftcard',
              icon: 'fa fa-gift',
              title: LABEL.GIFT_CARD_SALE,
              name: 'giftcard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/delivery/list',
              icon: 'fa fa-truck',
              title: LABEL.DELIVERY,
              name: 'deliveryList',
            },
            {
              link: '/brand/charges/list',
              icon: 'fa fa-usd',
              title: LABEL.CHARGES,
              name: 'chargesList',
            },
            {
              link: '/brand/marketPlace',
              icon: 'fa fa-shopping-cart',
              title: LABEL.MARKETPLACE,
              name: 'marketPlace',
              isURLParamRequired: true,
            },
            {
              link: '/brand/holidays/list',
              icon: 'fa fa-calendar',
              title: LABEL.HOLIDAYS,
              name: 'holidays',
            },
            {
              link: '/brand/productionRate',
              icon: 'fa fa-industry',
              title: LABEL.PRODUCTION_RATE,
              name: 'productionRate',
            },
            {
              link: '/brand/riskRule/list',
              icon: 'fa fa-file',
              title: LABEL.RISK_RULES,
              name: 'riskrules',
            },
            /* {
              link: '/brand/prepTime/list',
              icon: 'fa fa-clock-o',
              title: LABEL.PREP_TIME,
              name: 'prepTime',
            }, */
            {
              link: '/brand/sensorGroups',
              icon: 'fa fa-qrcode',
              title: LABEL.SENSOR_GROUP,
              name: 'sensorGroup',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/deviceGroup/list',
              icon: 'fa fa-sitemap',
              title: LABEL.DEVICE_GROUP,
              name: 'deviceGroup',
            },
            {
              link: '/brand/printCategory/list',
              icon: 'fa fa-tags',
              title: LABEL.PRINT_CATEGORY,
              name: 'printCategory',
            },
            {
              link: '/brand/tileSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            // {
            //   link: '/brand/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'mdm',
            //   disabled: [5].includes(getUserRoleId()) || false,
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            /* {
              link: '/menubrowser/list',
              icon: 'fa fa-cutlery',
              title: LABEL.BROWSABLE_MENU,
              name: 'browsableMenu',
            }, */
            {
              link: '/customMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.MENUS,
              name: 'customMenu',
            },
            // {
            //   link: '/customMenu/category/list',
            //   icon: 'fa fa-list-alt',
            //   title: LABEL.CATEGORIES,
            //   name: 'customMenu',
            // },
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ],
        },
        {
          title: LABEL.KDS,
          name: 'brandKDS',
          children: [
            {
              link: '/brand/kds/settings',
              icon: 'fa fa-wrench',
              title: LABEL.KDS_SETTINGS,
              name: 'KDSSettings',
            },
            {
              link: '/brand/kds/stations/list',
              icon: 'fa fa-pencil',
              title: LABEL.STATION_TYPES,
              name: 'KDSStationTypes',
            },
            {
              link: '/brand/kds/layouts/list',
              icon: 'fa fa-list-alt',
              title: LABEL.LAYOUT_RULES,
              name: 'KDSLayouts',
            },
            {
              link: '/brand/kds/print/list',
              icon: 'fa fa-list-alt',
              title: LABEL.PRINT_RULES,
              name: 'KDSPrintRules',
            },
          ],
        },
        {
          title: 'Multi-lingual',
          children: [
            {
              title: 'Menu',
              name: 'menu',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menu-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuName',
                },
                {
                  link: '/brand/multiLingual/menu-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuDescription',
                },
                {
                  link: '/brand/multiLingual/menu-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Screen',
              name: 'menuScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuscreen-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'menuScreenName',
                },
                {
                  link: '/brand/multiLingual/menuscreen-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuScreenDescription',
                },
                {
                  link: '/brand/multiLingual/menuscreen-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuScreenDisclaimer',
                },
              ],
            },
            {
              title: 'Menu Item',
              name: 'menuItem',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/menuitem-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'menuItemName',
                },
                {
                  link: '/brand/multiLingual/menuitem-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'menuItemDescription',
                },
                {
                  link: '/brand/multiLingual/menuitem-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'menuItemDisclaimer',
                },
              ],
            },
            {
              title: 'Modifier Group',
              name: 'modifierGroup',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifiergroup-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierGroupName',
                },
              ],
            },
            {
              title: 'Modifier',
              name: 'modifier',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/modifier-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.NAME,
                  name: 'modifierName',
                },
                {
                  link: '/brand/multiLingual/modifier-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'modifierDescription',
                },
                {
                  link: '/brand/multiLingual/modifier-disclaimer',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISCLAIMER,
                  name: 'modifierDisclaimer',
                },
              ],
            },
            {
              title: 'Tiles',
              name: 'tiles',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/tile-displayname',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DISPLAY_NAME,
                  name: 'tileName',
                },
                {
                  link: '/brand/multiLingual/tile-description',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.DESCRIPTION,
                  name: 'tileDescription',
                },
              ]
            },
            {
              title: 'Survey Screen',
              name: 'SurveyScreen',
              icon: 'fa fa-language',
              children: [
                {
                  link: '/brand/multiLingual/survey-welcomemessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.WELCOME_MSG,
                  name: 'surveyWelcomeMessage',
                },
                {
                  link: '/brand/multiLingual/survey-confirmationMessage',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.CONFIRMATION_MESSAGE,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyquestion-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_QUESTION_NAME,
                  name: 'surveyConfirmationMessage',
                },
                {
                  link: '/brand/multiLingual/surveyresponse-name',
                  icon: 'fa fa-pencil-square',
                  title: LABEL.SURVEY_RESPONSE_NAME,
                  name: 'surveyConfirmationMessage',
                },
              ],
            },
          ],
        },
        {
          title: 'Channels',
          name: 'channels',
          children: [
            {
              link: '/brand/storeOffPremiseSetup',
              icon: 'fa fa-pencil',
              title: LABEL.SETUP,
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            },
            {
              link: '/brand/config-channel',
              icon: 'fa fa-pencil',
              title: LABEL.CONFIGURE,
              name: 'storeOffPremiseSetup',
              isURLParamRequired: true,
            }
          ],
        },
        {
          title: 'Surveys',
          name: 'surveys',
          children: [
            {
              link: '/brand/survey/questionLibrary',
              icon: 'fa fa-list-alt',
              title: LABEL.QUESTION_LIBRARY,
              name: 'surveyQuestionLibrary',
            },
            {
              link: '/brand/survey/list',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_SURVEY,
              name: 'surveyList',
            }
          ],
        },
        {
          title: LABEL.CUSTOMIZATION,
          name: LABEL.CUSTOMIZATION.toLowerCase(),
          children: [
            {
              title: LABEL.THEME,
              name: LABEL.THEME.toLowerCase(),
              icon: 'fa fa-paint-brush',
              children: [
                {
                  link: '/brand/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.MOBILE_WEB,
                  name: 'brandTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/serverApp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.SERVER_APP,
                  name: 'serverAppTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kiosk/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KIOSK,
                  name: 'kioskTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/kds/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.KDS,
                  name: 'kdsTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/orderUp/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.ORDER_UP,
                  name: 'orderUpTheme',
                  isURLParamRequired: true,
                },
                {
                  link: '/brand/waitList/theme',
                  icon: 'fa fa-paint-brush',
                  title: LABEL.WAITLIST,
                  name: 'waitListTheme',
                  isURLParamRequired: true,
                }
              ],
            },
            {
              title: LABEL.LABELS,
              name: LABEL.LABELS.toLowerCase(),
              icon: 'fa fa-tag',
              children: [
                {
                  link: '/brand/languages/mobileApp',
                  icon: 'fa fa-tag',
                  title: LABEL.MOBILE_WEB,
                  name: 'mobileWeb',
                },
                {
                  link: '/brand/languages/serverApp',
                  icon: 'fa fa-tag',
                  title: LABEL.SERVER_APP,
                  name: 'serverApp',
                },
                {
                  link: '/brand/languages/kiosk',
                  icon: 'fa fa-tag',
                  title: LABEL.KIOSK,
                  name: 'kiosk',
                },
                {
                  link: '/brand/languages/kds',
                  icon: 'fa fa-tag',
                  title: LABEL.KDS,
                  name: 'kds',
                },
                {
                  link: '/brand/languages/orderUp',
                  icon: 'fa fa-tag',
                  title: LABEL.ORDER_UP,
                  name: 'orderUp',
                },
                {
                  link: '/brand/languages/waitList',
                  icon: 'fa fa-tag',
                  title: LABEL.WAIT_LIST,
                  name: 'waitList',
                },
              ],
            },
            {
              title: LABEL.TEMPLATES,
              name: LABEL.TEMPLATES.toLowerCase(),
              icon: 'fa fa-commenting-o',
              children: [
                {
                  link: '/brand/smsTemplates/list',
                  icon: 'fa fa-commenting-o',
                  title: LABEL.SMS_TEMPLATES,
                  name: 'smsTemplates',
                },
                {
                  link: '/brand/emailTemplates/list',
                  icon: 'fa fa-envelope',
                  title: LABEL.EMAIL_TEMPLATES,
                  name: 'emailList',
                },
              ],
            },
            {
              popUp: true,
              // link: '/custom/icon',
              icon: 'fa fa-picture-o',
              title: LABEL.IMAGES,
              name: 'customIcons',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/external/campaigns',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.EXTERNAL_CAMPAIGN,
              name: 'externalCampaign',
            },
          ],
        },
        {
          title: 'Marketing',
          children: [
            {
              link: '/brand/omsoffers/list',
              icon: 'fa fa-wrench',
              title: LABEL.OMS_SETUP,
              name: 'offersList',
              disabled: ![10, 90, 100].includes(getUserRoleId()) || false,
            },
            {
              link: '/brand/import/guests',
              icon: 'fa fa-pencil',
              title: LABEL.IMPORT_GUESTS,
              name: 'importGuest',
            },
            {
              link: '/brand/offer/list',
              icon: 'fa fa-gift',
              title: LABEL.GUESTS,
              name: 'brandOfferList',
            },
            {
              link: '/brand/campaign/list',
              icon: 'fa fa fa-bullhorn',
              title: LABEL.CAMPAIGN,
              name: 'campaignList',
              // isURLParamRequired: true,
            },
            {
              link: '/brand/segment/list',
              icon: 'fa fa-pencil',
              title: LABEL.SEGMENT,
              name: 'segmentList',
            },

            // {
            //   link: '/brand/campaignOccurrence/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_OCCURRENCE,
            //   name: 'campaignOccurrence',
            // },
            // {
            //   link: '/brand/campaignTriggers/list',
            //   icon: 'fa fa fa-bullhorn',
            //   title: LABEL.CAMPAIGN_TRIGGERS,
            //   name: 'campaignTriggers',
            // },
          ]
        },
        {
          title: 'Financial',
          children: [
            {
              link: '/brand/financial/chartOfAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.CHART_OF_ACCOUNTS,
              name: 'chartsOfAccounts',
            },
            {
              link: '/brand/financial/storeAccounts',
              icon: 'fa fa-list-alt',
              title: LABEL.STORE_ACCOUNTS,
              name: 'storeAccounts',
            },
            {
              link: '/brand/financial/storeAccountsBalance',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_BALANCES,
              name: 'storeAccountsBalance',
            },
            {
              link: '/brand/financial/storeAccountsJournal',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_JOURNAL,
              name: 'storeAccountsJournal',
            },
          ],
        },
        {
          title: 'Third party',
          // disabled: true,
          children: [
            /* {
              link: '/brand/pnp',
              icon: 'fa fa-pencil',
              title: LABEL.ONEDINE_OFFERS,
              name: 'pnp',
              isURLParamRequired: true,
            }, */
            // {
            //   link: '/brand/paytronixLoyalty',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.PAYTRONIX_LOYALTY,
            //   name: 'payTronixLoyalty',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/punchh',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.PUNCHH,
            //   name: 'Punchh',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/spartfly/setup',
            //   icon: 'fa fa fa-pencil',
            //   title: LABEL.SPARKFLY_SETUP,
            //   name: 'sparkfly',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/spendgo',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.SPENDGO,
            //   name: 'Spendgo',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/fishbowl',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.FISHBOWL,
            //   name: 'Fishbowl',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/fiserv',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.FISERV,
            //   name: 'fiserv',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/givex',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.GIVEX_GIFT_CARD,
            //   name: 'Heartland',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/heartland',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.HEARTLAND_GIFT_CARD,
            //   name: 'Heartland',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/paytronixGiftCard',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.PAYTRONIX_GIFT_CARD,
            //   name: 'payTronixGiftCard',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/svs',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.SVS,
            //   name: 'SVS',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/valueTec',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.VALUE_TEC_GIFT_CARD,
            //   name: 'valueTecGiftCard',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/medallia',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MEDALLIA,
            //   name: 'MEDALLIA',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/siftFraudSystem',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.SIFT,
            //   name: 'SIFT',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/userWay',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.USERWAY,
            //   name: 'UserWay',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/deliverySetup',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.DELIVERY,
            //   name: 'Delivery',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/chowly',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.CHOWLY,
            //   name: 'chowly',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/otter',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.OTTER,
            //   name: 'otter',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/ezCater',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.EZCATER,
            //   name: 'ezCater',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/happyCog',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.HAPPY_COG,
            //   name: 'happyCog',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   horizontalRule: true
            // },
            // {
            //   link: '/brand/rokt',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.ROKT,
            //   name: 'rokt',
            //   isURLParamRequired: true,
            // },
            {
              link: '/brand/intercard',
              icon: 'fa fa-pencil',
              title: LABEL.INTERCARD,
              name: 'intercard',
              isURLParamRequired: true,
            },
            {
              link: '/brand/powercard',
              icon: 'fa fa-pencil',
              title: LABEL.POWERCARD,
              name: 'powercard',
              isURLParamRequired: true,
            },
            // {
            //   horizontalRule: true
            // },
            // // {
            // //   link: '/brand/restaurantMagic',
            // //   icon: 'fa fa-pencil',
            // //   title: LABEL.RESTAURANT_MAGIC,
            // //   name: 'restaurantMagic',
            // //   isURLParamRequired: true,
            // // },
            // // {
            // //   link: '/brand/marketMan',
            // //   icon: 'fa fa-pencil',
            // //   title: LABEL.MARKETMAN,
            // //   name: 'marketMan',
            // //   isURLParamRequired: true,
            // // },
            // {
            //   link: '/brand/spendgoWebhooks',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.SPENDGO_WEBHOOKS,
            //   name: 'spendgoWebhooks',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
            // {
            //   link: '/brand/wiselyWebhooks',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.WISELY_WEBHOOKS,
            //   name: 'wiselyWebhooks',
            //   isURLParamRequired: true,
            //   disabled: true,
            // },
          ],
        },
        {
          title: 'Mobile App',
          children: [{
            link: '/brand/mobileApp/theme',
            icon: 'fa fa-paint-brush',
            title: LABEL.THEME,
            name: 'mobileAppTheme',
            isURLParamRequired: true,
          },
          {
            link: '/brand/mobileApp/layouts/list',
            icon: 'fa fa-list-alt',
            title: LABEL.MOBILE_APP_LAYOUT,
            name: 'mobileAppLayouts',
          },
          {
            link: '/brand/mobileApp/page/list',
            icon: 'fa fa-file-text-o',
            title: LABEL.PAGES,
            name: 'mobileAppPages',
            isURLParamRequired: false,
          },
          {
            link: '/brand/mobileApp/media/list',
            icon: 'fa fa-pencil',
            title: LABEL.MOBILE_APP_MEDIA,
            name: 'media',
            isURLParamRequired: false,
          },
          ],
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
          ],
        },
      ],
    },
    STORE_OPTIONS: {
      config: [
        {
          link: '/store/update',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editStore',
          isURLParamRequired: true,
        },
        {
          popUp: true,
          icon: 'fa fa-picture-o',
          title: LABEL.EDIT_IMAGE,
          name: 'editImage',
        },
        {
          title: 'Configuration',
          children: [
            {
              link: '/store/guestApp',
              icon: 'fa fa-pencil',
              title: LABEL.GUEST_APP,
              name: 'guestApp',
            },
            {
              link: '/store/serverApp',
              icon: 'fa fa-pencil',
              title: LABEL.SERVER_APP,
              name: 'serverApp',
            },
          ],
        },
        {
          title: 'Setup',
          children: [
            // {
            //   link: '/store/mapping',
            //   icon: 'fa fa-pencil',
            //   title: LABEL.MAPPINGS,
            //   name: 'mappings',
            //   isURLParamRequired: true,
            // },
            {
              link: '/store/sections',
              icon: 'fa fa-list-alt',
              title: LABEL.SECTIONS,
              name: 'section',
            },
            {
              link: '/store/tables/list',
              icon: 'fa fa-table',
              title: LABEL.TABLE,
              name: 'tables',
            },
            {
              link: '/store/jobs',
              icon: 'fa fa-briefcase',
              title: LABEL.JOB,
              name: 'jobs',
            },
            {
              link: '/store/teams',
              icon: 'fa fa-users',
              title: LABEL.TEAMS,
              name: 'teams',
            },
            {
              link: '/store/employees',
              icon: 'fa fa-user-circle-o',
              title: LABEL.EMPLOYEE,
              name: 'jobs',
            },
            {
              link: '/store/categories',
              icon: 'fa fa-list-alt',
              title: LABEL.CATEGORIES,
              name: 'categories',
            },
            {
              link: '/store/discounts',
              icon: 'fa fa-percent',
              title: LABEL.DISCOUNT,
              name: 'discounts',
            },
            {
              link: '/store/serviceCharges',
              icon: 'fa fa-usd',
              title: LABEL.SERVICE_CHARGE_STORE,
              name: 'serviceCharge',
            },
            {
              link: '/store/orderTypes',
              icon: 'fa fa-first-order',
              title: LABEL.ORDER_TYPES,
              name: 'orderTypes',
            },
            {
              link: '/store/revenueCenters',
              icon: 'fa fa-pencil',
              title: LABEL.REVENUE_CENTERS,
              name: 'revenueCenters',
            },
            {
              link: '/store/tenderTypes',
              icon: 'fa fa-gavel',
              title: LABEL.TENDER_TYPES,
              name: 'tenderTypes',
            },
            {
              link: '/store/hours',
              icon: 'fa fa-clock-o',
              title: LABEL.STORE_HOUR,
              name: 'hours',
            },
            {
              link: '/store/drawers',
              icon: 'fa fa-list-alt',
              title: LABEL.DRAWER,
              name: 'drawer',
            },
            {
              link: '/cubby/list',
              icon: 'fa fa-th-large',
              title: LABEL.CUBBY,
              name: 'cubby',
            },
            {
              link: '/store/otherSettings',
              icon: 'fa fa-gear',
              title: LABEL.OTHER_SETTINGS,
              name: 'otherSettings',
            },
            // {
            //   link: '/store/mdm/policy',
            //   icon: 'fa fa-shield',
            //   title: LABEL.MDM_POLICY,
            //   name: 'tiles',
            //   disabled: [5].includes(getUserRoleId()) || false,
            //   isURLParamRequired: true,
            // },
          ],
        },
        {
          title: 'Mappings',
          children: [
            {
              link: '/store/storeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.STORE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/tenderTypeMappings',
              icon: 'fa fa-pencil',
              title: LABEL.TENDER_TYPE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/offPremiseMappings',
              icon: 'fa fa-pencil',
              title: LABEL.OFF_PREMISE_MAPPINGS,
              name: 'mappings',
            },
            {
              link: '/store/posSmsMappings',
              icon: 'fa fa-pencil',
              title: LABEL.SMS_MAPPINGS,
              name: 'mappings',
            },
          ]
        },
        {
          title: 'Sensors',
          children: [
            {
              link: '/store/sensor-assignment/list',
              icon: 'fa fa-qrcode',
              title: LABEL.TABLE_SENSOR,
              name: 'scanner',
            },
            {
              link: '/store/namedSensor/6',
              icon: 'fa fa-qrcode',
              title: LABEL.STORE_ON_PREMISE_SENSOR,
              name: 'storeNamedScanner',
            },
            /* {
              link: '/store/namedSensor/9',
              icon: 'fa fa-qrcode',
              title: LABEL.VENUE_ON_PREMISE_SENSOR,
              name: 'venueNamedScanner',
            }, */
            {
              link: '/store/offPremiseSensors/11',
              icon: 'fa fa-qrcode',
              title: LABEL.OTHER_SENSOR,
              name: 'deliveryLocationScanner',
            }
          ]
        },
        {
          title: 'Menu',
          children: [
            {
              link: '/posMenu/list',
              icon: 'fa fa-cutlery',
              title: LABEL.POS_MENU,
              name: 'posMenu',
            },
          ],
        },
        {
          title: 'Device',
          children: [
            {
              link: '/device/register',
              icon: 'fa fa-plus',
              title: LABEL.REGISTER_DEVICE,
              name: 'registerDevice',
            },
            {
              link: '/device/list',
              icon: 'fa fa-list-alt',
              title: LABEL.VIEW_DEVICES,
              name: 'viewDevices',
            },
            // {
            //   link: '/device/mdm/list',
            //   icon: 'fa fa-eye',
            //   title: LABEL.MDM_DEVICES,
            //   name: 'viewDevices',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/sensor-assignment',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.TABLE_SENSOR,
            //   name: 'scanner',
            //   isURLParamRequired: true,
            // },
            // {
            //   link: '/store/namedSensor/6',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.STORE_NAMED_SENSOR,
            //   name: 'storeNamedScanner',
            // },
            // {
            //   link: '/store/namedSensor/9',
            //   icon: 'fa fa-qrcode',
            //   title: LABEL.VENUE_NAMED_SENSOR,
            //   name: 'venueNamedScanner',
            // }
          ],
        },
        /* {
          title: 'Charity',
          children: [
            {
              link: '/charity/add',
              icon: 'fa fa-plus',
              title: LABEL.ADD_CHARITY,
              name: 'addCharity',
            },
            {
              link: '/charity/list',
              icon: 'fa fa-eye',
              title: LABEL.VIEW_CHARITY,
              name: 'viewCharity',
            }
          ],
        }, */
        {
          link: '/empApp/storeSelect',
          icon: 'fa fa-rocket',
          title: LABEL.MANAGER_APP,
          name: 'managerApp',
          inSeperateTab: true
        },
        // {
        //   title: 'Third party',
        //   disabled: true,
        //   children: [
        //     {
        //       link: '/store/posSystem',
        //       icon: 'fa fa-pencil',
        //       title: LABEL.POS,
        //       name: 'posSystem',
        //       isURLParamRequired: true,
        //     },
        //     // {
        //     //   link: '/store/paymentSystem',
        //     //   icon: 'fa fa-pencil',
        //     //   title: 'Payment',
        //     //   name: 'paymentSystem',
        //     //   isURLParamRequired: true,
        //     // },
        //     {
        //       link: '/store/mobilePaymentSetup',
        //       icon: 'fa fa-credit-card-alt',
        //       title: LABEL.MOBILE_PAYMENTS,
        //       name: 'mobilePayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/paypal',
        //       icon: 'fa fa-paypal',
        //       title: LABEL.PAYPAL,
        //       name: 'paypalPayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/cabbagePay',
        //       icon: 'fa fa-credit-card',
        //       title: LABEL.CABBAGEPAY_SETUP,
        //       name: 'cabbagePayPayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/amazonPay',
        //       icon: 'fa fa-amazon',
        //       title: LABEL.AMAZON_PAY,
        //       name: 'amazonPayPayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/stripe',
        //       icon: 'fa fa-cc-stripe',
        //       title: LABEL.STRIPE,
        //       name: 'stripePayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/spreedly',
        //       icon: 'fa fa-pencil',
        //       title: LABEL.SPREEDLY_FOR_TOAST,
        //       name: 'spreedlyPayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/ingenico',
        //       icon: 'fa fa-money',
        //       title: LABEL.INGENICO,
        //       name: 'ingenico',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/cardconnectbolt',
        //       icon: 'fa fa-money',
        //       title: LABEL.CARD_CONNECT_BOLT,
        //       name: 'cardconnectbolt',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/nmiPayments',
        //       icon: 'fa fa-money',
        //       title: LABEL.NMI_PAYMENTS,
        //       name: 'nmiPayments',
        //       isURLParamRequired: true,
        //     },
        //     {
        //       link: '/store/paymentSense',
        //       icon: 'fa fa-money',
        //       title: LABEL.PAYMENT_SENSE,
        //       name: 'paymentSense',
        //       isURLParamRequired: true,
        //     },
        //     // {
        //     //   link: '/store/bookingSystem',
        //     //   icon: 'fa fa-pencil',
        //     //   title: 'Reservation System',
        //     //   name: 'bookingSystem',
        //     //   isURLParamRequired: true,
        //     // },
        //   ],
        // },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              link: '/reports/advanceOrder',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/CheckReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.CHECK_REPORT,
              name: 'checksReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
            // {
            //   link: '/reports/sales',
            //   icon: 'fa fa-file-text-o',
            //   title: LABEL.SALES_REPORT,
            //   name: 'salesReport',
            // },
          ],
        },
      ],
    },
    SIDE_MENUS: [
      {
        link: '/batchReport',
        icon: 'fa fa-book',
        title: LABEL.BATCH_REPORT,
        name: 'batchReport',
      },
    ],
  },
  // Store User (RoleId: 3)
  STORE_USER: {
    BRAND_OPTIONS: {
      config: [
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ]
        }
      ]
    },
    STORE_OPTIONS: {
      config: [
        {
          analytics: true,
          redirectUrl: null, // '/Reports/CheckReport'
          icon: 'fa fa-file-text-o',
          title: LABEL.ANALYTICS,
          name: 'analytics'
        },
        {
          horizontalRule: true
        },
        {
          analytics: true,
          redirectUrl: '/Reports/AdvanceOrderReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.ADVANCE_ORDER_REPORT,
          name: 'advanceOrderReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/BatchDetailReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.BATCH_REPORT,
          name: 'batchReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/BatchSummaryReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.BATCH_SUMMARY_REPORT,
          name: 'batchSummaryReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/OrderDetailsReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.ORDER_REPORT,
          name: 'orderReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/OrderDistributionReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.ORDER_DISTRIBUTION,
          name: 'orderDistributionReport',
        },
        {
          analytics: false,
          link: '/reports/payment-adjustment',
          icon: 'fa fa-file-text-o',
          title: LABEL.PAYMENT_ADJUSTMENT,
          name: 'payment-adjustment',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/ReconciliationReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.RECONCILIATION,
          name: 'reconciliationReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/SensorsReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.SENSOR_REPORT,
          name: 'sensorReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/SurveyReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.SURVEY_REPORT,
          name: 'surveyQuestionReport',
        },
        {
          analytics: true,
          redirectUrl: '/Reports/TransactionsReport',
          icon: 'fa fa-file-text-o',
          title: LABEL.TRANSACTIONS_REPORT,
          name: 'transactionReport',
        },
      ],
    }
  },
  // Store Manager (RoleId: 5)
  STORE_MANAGER: {
    BRAND_OPTIONS: {
      config: [
        {
          title: 'Setup',
          children: [
            {
              link: '/brand/holidays/list',
              icon: 'fa fa-calendar',
              title: LABEL.HOLIDAYS,
              name: 'holidays',
            }
          ],
        },
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ]
        },
        {
          title: 'Financial',
          name: 'financial',
          children: [
            {
              link: '/brand/financial/storeAccountsBalance',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_BALANCES,
              name: 'storeAccountsBalance',
            },
            {
              link: '/brand/financial/storeAccountsJournal',
              icon: 'fa fa-list-alt',
              title: LABEL.ACCOUNT_JOURNAL,
              name: 'storeAccountsJournal',
            },
          ],
        },
      ]
    },
    STORE_OPTIONS: {
      config: [
        {
          title: 'Setup',
          children: [
            {
              link: '/store/hours',
              icon: 'fa fa-clock-o',
              title: LABEL.STORE_HOUR,
              name: 'hours',
            },
            {
              link: '/store/jobs',
              icon: 'fa fa-briefcase',
              title: LABEL.JOB,
              name: 'jobs',
            },
            {
              link: '/store/employees',
              icon: 'fa fa-user-circle-o',
              title: LABEL.EMPLOYEE,
              name: 'jobs',
            },
          ],
        },
        {
          title: 'Sensors',
          children: [
            {
              link: '/store/sensor-assignment/list',
              icon: 'fa fa-qrcode',
              title: LABEL.TABLE_SENSOR,
              name: 'scanner',
            },
            {
              link: '/store/namedSensor/6',
              icon: 'fa fa-qrcode',
              title: LABEL.STORE_ON_PREMISE_SENSOR,
              name: 'storeNamedScanner',
            },
          ],
        },
        {
          title: 'Device',
          children: [
            {
              link: '/device/register',
              icon: 'fa fa-plus',
              title: LABEL.REGISTER_DEVICE,
              name: 'registerDevice',
            },
            {
              link: '/device/list',
              icon: 'fa fa-list-alt',
              title: LABEL.VIEW_DEVICES,
              name: 'viewDevices',
            },
          ],
        },
        {
          link: '/empApp/storeSelect',
          icon: 'fa fa-rocket',
          title: LABEL.MANAGER_APP,
          name: 'managerApp',
          inSeperateTab: true
        },
        {
          title: 'Reports',
          children: [
            {
              analytics: true,
              redirectUrl: null, // '/Reports/CheckReport'
              icon: 'fa fa-file-text-o',
              title: LABEL.ANALYTICS,
              name: 'analytics'
            },
            {
              horizontalRule: true
            },
            {
              analytics: true,
              redirectUrl: '/Reports/AdvanceOrderReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ADVANCE_ORDER_REPORT,
              name: 'advanceOrderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchDetailReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_REPORT,
              name: 'batchReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/BatchSummaryReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.BATCH_SUMMARY_REPORT,
              name: 'batchSummaryReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDetailsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_REPORT,
              name: 'orderReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/OrderDistributionReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.ORDER_DISTRIBUTION,
              name: 'orderDistributionReport',
            },
            {
              analytics: false,
              link: '/reports/payment-adjustment',
              icon: 'fa fa-file-text-o',
              title: LABEL.PAYMENT_ADJUSTMENT,
              name: 'payment-adjustment',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ReconciliationReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.RECONCILIATION,
              name: 'reconciliationReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SensorsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SENSOR_REPORT,
              name: 'sensorReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/SurveyReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.SURVEY_REPORT,
              name: 'surveyQuestionReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/ApiLogsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.API_LOGS,
              name: 'apiLogsReport',
            },
            {
              analytics: true,
              redirectUrl: '/Reports/TransactionsReport',
              icon: 'fa fa-file-text-o',
              title: LABEL.TRANSACTIONS_REPORT,
              name: 'transactionReport',
            },
          ],
        },
      ],
    }
  },
  // Store menu user (RoleId: 2)
  STORE_MENU_USER: {
    BRAND_OPTIONS: {
      config: [
        {
          title: 'Menu',
          name: 'menusMenu',
          children: [
            {
              link: '/brand/manageStore/menuItemByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_STORE,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/menuItemByCategory',
              icon: 'fa fa-cutlery',
              title: LABEL.MENU_ITEMS_BY_SCREEN,
              name: 'menuItem',
            },
            {
              link: '/brand/manageStore/modifierByStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_BY_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/manageStore/modifierAcrossStore',
              icon: 'fa fa-cutlery',
              title: LABEL.MODIFIERS_ACROSS_STORE,
              name: 'modifier',
            },
            {
              link: '/brand/menus/course',
              icon: 'fa fa-cutlery',
              title: LABEL.COURSES,
              name: 'courses',
            },
            {
              link: '/brand/menus/happyHour',
              icon: 'fa fa-cutlery',
              title: LABEL.HAPPY_HOUR,
              name: 'happyHour',
            },
          ],
        },
      ]
    },
  },
  CATEGORY: {
    CATEGORY_OPTIONS: {
      config: [
        {
          link: '/customMenu/category/edit',
          icon: 'fa fa-pencil',
          title: LABEL.EDIT,
          name: 'editCategory',
          isURLParamRequired: true,
        },
      ],
    }
  }
};
