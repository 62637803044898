export const ADD_MENU_REQUESTED = 'menu/ADD_MENU_REQUESTED';
export const ADD_MENU_SUCCESS = 'menu/ADD_MENU_SUCCESS';
export const ADD_MENU_FAILURE = 'menu/ADD_MENU_FAILURE';

export const UPDATE_MENU_REQUESTED = 'menu/UPDATE_MENU_REQUESTED';
export const UPDATE_MENU_SUCCESS = 'menu/UPDATE_MENU_SUCCESS';
export const UPDATE_MENU_FAILURE = 'menu/UPDATE_MENU_FAILURE';

export const DELETE_MENU_REQUESTED = 'menu/DELETE_MENU_REQUESTED';
export const DELETE_MENU_SUCCESS = 'menu/DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAILURE = 'menu/DELETE_MENU_FAILURE';

export const CLONE_MENU_REQUESTED = 'menu/CLONE_MENU_REQUESTED';
export const CLONE_MENU_SUCCESS = 'menu/CLONE_MENU_SUCCESS';
export const CLONE_MENU_FAILURE = 'menu/CLONE_MENU_FAILURE';

export const STORE_MENU_IMAGE_SAVE_REQUESTED = 'menu/STORE_MENU_IMAGE_SAVE_REQUESTED';
export const STORE_MENU_IMAGE_SAVE_SUCCESS = 'menu/STORE_MENU_IMAGE_SAVE_SUCCESS';
export const STORE_MENU_IMAGE_SAVE_FAILURE = 'menu/STORE_MENU_IMAGE_SAVE_FAILURE';

export const STORE_MENU_IMAGE_DELETE_REQUESTED = 'menu/STORE_MENU_IMAGE_DELETE_REQUESTED';
export const STORE_MENU_IMAGE_DELETE_SUCCESS = 'menu/STORE_MENU_IMAGE_DELETE_SUCCESS';
export const STORE_MENU_IMAGE_DELETE_FAILURE = 'menu/STORE_MENU_IMAGE_DELETE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CLONE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CLONE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CLONE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case STORE_MENU_IMAGE_SAVE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case STORE_MENU_IMAGE_SAVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case STORE_MENU_IMAGE_SAVE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case STORE_MENU_IMAGE_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case STORE_MENU_IMAGE_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case STORE_MENU_IMAGE_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addMenu = (data) => {
  return {
    types: [ADD_MENU_REQUESTED, ADD_MENU_SUCCESS, ADD_MENU_FAILURE],
    promise: client => client.post('addMenu', { data })
  };
};

export const updateMenu = (data) => {
  return {
    types: [UPDATE_MENU_REQUESTED, UPDATE_MENU_SUCCESS, UPDATE_MENU_FAILURE],
    promise: client => client.post('updateMenu', { data })
  };
};

export const deleteMenu = (menuId) => {
  return {
    types: [UPDATE_MENU_REQUESTED, UPDATE_MENU_SUCCESS, UPDATE_MENU_FAILURE],
    promise: client => client.post(`deleteMenu/${menuId}`)
  };
};

export const clonesMenu = (menuId, data) => {
  return {
    types: [CLONE_MENU_REQUESTED, CLONE_MENU_SUCCESS, CLONE_MENU_FAILURE],
    promise: client => client.post(`cloneMenu/${menuId}`, { data })
  };
};

export const storeMenuImageSave = (menuId, data) => {
  return {
    types: [CLONE_MENU_REQUESTED, CLONE_MENU_SUCCESS, CLONE_MENU_FAILURE],
    promise: client => client.post(`storeMenuImage/${menuId}/image/add`, { data })
  };
};

export const storeMenuImageDelete = (menuId) => {
  return {
    types: [CLONE_MENU_REQUESTED, CLONE_MENU_SUCCESS, CLONE_MENU_FAILURE],
    promise: client => client.post(`storeMenuImage/${menuId}/image/delete`)
  };
};
