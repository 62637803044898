export const HEADER_TOGGLE_NAV = 'header/HEADER_TOGGLE_NAV';
export const SET_HEADER_INFO = 'header/SET_HEADER_INFO';
export const SET_HEADER_REQUEST = 'header/SET_HEADER_REQUEST';
export const TOGGLE_RESTAURANT_BAR = 'header/TOGGLE_RESTAURANT_BAR';
export const SET_COMPANYID_LANDINGPAGE = 'header/SET_COMPANYID_LANDINGPAGE';

export const RELEASE_NOTES_VIEWED_REQUESTED = 'header/RELEASE_NOTES_VIEWED_REQUESTED';
export const RELEASE_NOTES_VIEWED_SUCCESS = 'header/RELEASE_NOTES_VIEWED_SUCCESS';
export const RELEASE_NOTES_VIEWED_FAILURE = 'header/RELEASE_NOTES_VIEWED_FAILURE';

export const SET_SIDEMENU_V3 = 'header/SET_SIDEMENU_V3';

const initialValues = {
  isNavOpen: null,
  selectedSideMenu: 'mainMenu',
  selectedCompany: null,
  tempLoad: false,
  headerBar: {
    show: true,
    tittle: ''
  },
  menu: {
    type: '',
    controlUrl: ''
  },
  newMenu: {
    type: '',
    controlUrl: ''
  },
  closeButton: {
    type: '',
    controlUrl: ''
  },
  restaurantInfo: {
    show: false
  },
  loading: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case HEADER_TOGGLE_NAV:
      return {
        ...state,
        isNavOpen: !state.isNavOpen
      };
    case SET_HEADER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_HEADER_INFO:
      return {
        ...state,
        loading: false,
        tempLoad: !state.tempLoad,
        ...action.payload
      };
    case TOGGLE_RESTAURANT_BAR:
      return {
        ...state,
        isRestaurantBarOpen: !state.isRestaurantBarOpen
      };
    case SET_COMPANYID_LANDINGPAGE:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case RELEASE_NOTES_VIEWED_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case RELEASE_NOTES_VIEWED_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case RELEASE_NOTES_VIEWED_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SET_SIDEMENU_V3: {
      return {
        ...state,
        selectedSideMenu: action.payload,
      };
    }
    default:
      return state;
  }
};

export const toggleNavBar = () => {
  return (dispatch) => {
    dispatch(
      {
        type: HEADER_TOGGLE_NAV
      }
    );
  };
};

export const setHeaderInfo = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_HEADER_INFO,
        payload: data
      }
    );
  };
};

export const setHeaderTitlle = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_HEADER_INFO,
        payload: data
      }
    );
  };
};

export const toggleRestaurantBar = () => {
  return (dispatch) => {
    dispatch(
      {
        type: TOGGLE_RESTAURANT_BAR
      }
    );
  };
};

export const setSelectedCompany = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_COMPANYID_LANDINGPAGE,
        payload: data
      }
    );
  };
};
// export const setSelectedMall = (data) => {
//   return (dispatch) => {
//     dispatch(
//       {
//         type: SET_COMPANYID_LANDINGPAGE,
//         payload: data
//       }
//     );
//   };
// };

export const updateHeaderData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HEADER_REQUEST,
      payload: data
    });

    setTimeout(() => {
      dispatch({
        type: SET_HEADER_INFO,
        payload: data
      });
    }, 500);
  };
};

export const releaseNotesViewed = (data) => {
  return {
    types: [RELEASE_NOTES_VIEWED_REQUESTED, RELEASE_NOTES_VIEWED_SUCCESS, RELEASE_NOTES_VIEWED_FAILURE],
    promise: client => client.post('releaseNotesViewed', { data })
  };
};

export const setSelectedSideMenu = (data) => {
  return {
    type: SET_SIDEMENU_V3,
    payload: data,
  };
};
