
export const ADD_APPLE_PAY_REQUESTED = 'applePay/ADD_APPLE_PAY_REQUESTED';
export const ADD_APPLE_PAY_SUCCESS = 'applePay/ADD_APPLE_PAY_SUCCESS';
export const ADD_APPLE_PAY_FAILURE = 'applePay/ADD_APPLE_PAY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_APPLE_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_APPLE_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_APPLE_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addApplePay = (data) => {
  return {
    types: [ADD_APPLE_PAY_REQUESTED, ADD_APPLE_PAY_SUCCESS, ADD_APPLE_PAY_FAILURE],
    promise: client => client.post('applePayVerification', { data })
  };
};
