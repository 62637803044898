export const GET_CATEGORIES_REQUESTED = 'storeSetupOptions/GET_CATEGORIES_REQUESTED';
export const GET_CATEGORIES_SUCCESS = 'storeSetupOptions/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'storeSetupOptions/GET_CATEGORIES_FAILURE';

export const ADD_CATEGORIES_REQUESTED = 'storeSetupOptions/ADD_CATEGORIES_REQUESTED';
export const ADD_CATEGORIES_SUCCESS = 'storeSetupOptions/ADD_CATEGORIES_SUCCESS';
export const ADD_CATEGORIES_FAILURE = 'storeSetupOptions/ADD_CATEGORIES_FAILURE';

export const REORDER_DATA_REQUESTED = 'storeSetupOptions/REORDER_DATA_REQUESTED';
export const REORDER_DATA_SUCCESS = 'storeSetupOptions/REORDER_DATA_SUCCESS';
export const REORDER_DATA_FAILURE = 'storeSetupOptions/REORDER_DATA_FAILURE';

export const UPDATE_CATEGORIES_REQUESTED = 'storeSetupOptions/UPDATE_CATEGORIES_REQUESTED';
export const UPDATE_CATEGORIES_SUCCESS = 'storeSetupOptions/UPDATE_CATEGORIES_SUCCESS';
export const UPDATE_CATEGORIES_FAILURE = 'storeSetupOptions/UPDATE_CATEGORIES_FAILURE';

export const GET_DISCOUNTS_REQUESTED = 'storeSetupOptions/GET_DISCOUNTS_REQUESTED';
export const GET_DISCOUNTS_SUCCESS = 'storeSetupOptions/GET_DISCOUNTS_SUCCESS';
export const GET_DISCOUNTS_FAILURE = 'storeSetupOptions/GET_DISCOUNTS_FAILURE';

export const ADD_DISCOUNTS_REQUESTED = 'storeSetupOptions/ADD_DISCOUNTS_REQUESTED';
export const ADD_DISCOUNTS_SUCCESS = 'storeSetupOptions/ADD_DISCOUNTS_SUCCESS';
export const ADD_DISCOUNTS_FAILURE = 'storeSetupOptions/ADD_DISCOUNTS_FAILURE';

export const UPDATE_DISCOUNTS_REQUESTED = 'storeSetupOptions/UPDATE_DISCOUNTS_REQUESTED';
export const UPDATE_DISCOUNTS_SUCCESS = 'storeSetupOptions/UPDATE_DISCOUNTS_SUCCESS';
export const UPDATE_DISCOUNTS_FAILURE = 'storeSetupOptions/UPDATE_DISCOUNTS_FAILURE';

export const GET_ORDER_TYPES_REQUESTED = 'storeSetupOptions/GET_ORDER_TYPES_REQUESTED';
export const GET_ORDER_TYPES_SUCCESS = 'storeSetupOptions/GET_ORDER_TYPES_SUCCESS';
export const GET_ORDER_TYPES_FAILURE = 'storeSetupOptions/GET_ORDER_TYPES_FAILURE';

export const ADD_ORDER_TYPES_REQUESTED = 'storeSetupOptions/ADD_ORDER_TYPES_REQUESTED';
export const ADD_ORDER_TYPES_SUCCESS = 'storeSetupOptions/ADD_ORDER_TYPES_SUCCESS';
export const ADD_ORDER_TYPES_FAILURE = 'storeSetupOptions/ADD_ORDER_TYPES_FAILURE';

export const UPDATE_ORDER_TYPES_REQUESTED = 'storeSetupOptions/UPDATE_ORDER_TYPES_REQUESTED';
export const UPDATE_ORDER_TYPES_SUCCESS = 'storeSetupOptions/UPDATE_ORDER_TYPES_SUCCESS';
export const UPDATE_ORDER_TYPES_FAILURE = 'storeSetupOptions/UPDATE_ORDER_TYPES_FAILURE';

export const GET_REVENUE_CENTERS_REQUESTED = 'storeSetupOptions/GET_REVENUE_CENTERS_REQUESTED';
export const GET_REVENUE_CENTERS_SUCCESS = 'storeSetupOptions/GET_REVENUE_CENTERS_SUCCESS';
export const GET_REVENUE_CENTERS_FAILURE = 'storeSetupOptions/GET_REVENUE_CENTERS_FAILURE';

export const ADD_REVENUE_CENTERS_REQUESTED = 'storeSetupOptions/ADD_REVENUE_CENTERS_REQUESTED';
export const ADD_REVENUE_CENTERS_SUCCESS = 'storeSetupOptions/ADD_REVENUE_CENTERS_SUCCESS';
export const ADD_REVENUE_CENTERS_FAILURE = 'storeSetupOptions/ADD_REVENUE_CENTERS_FAILURE';

export const UPDATE_REVENUE_CENTERS_REQUESTED = 'storeSetupOptions/UPDATE_REVENUE_CENTERS_REQUESTED';
export const UPDATE_REVENUE_CENTERS_SUCCESS = 'storeSetupOptions/UPDATE_REVENUE_CENTERS_SUCCESS';
export const UPDATE_REVENUE_CENTERS_FAILURE = 'storeSetupOptions/UPDATE_REVENUE_CENTERS_FAILURE';

export const GET_TENDER_TYPES_REQUESTED = 'storeSetupOptions/GET_TENDER_TYPES_REQUESTED';
export const GET_TENDER_TYPES_SUCCESS = 'storeSetupOptions/GET_TENDER_TYPES_SUCCESS';
export const GET_TENDER_TYPES_FAILURE = 'storeSetupOptions/GET_TENDER_TYPES_FAILURE';

export const ADD_TENDER_TYPES_REQUESTED = 'storeSetupOptions/ADD_TENDER_TYPES_REQUESTED';
export const ADD_TENDER_TYPES_SUCCESS = 'storeSetupOptions/ADD_TENDER_TYPES_SUCCESS';
export const ADD_TENDER_TYPES_FAILURE = 'storeSetupOptions/ADD_TENDER_TYPES_FAILURE';

export const UPDATE_TENDER_TYPES_REQUESTED = 'storeSetupOptions/UPDATE_TENDER_TYPES_REQUESTED';
export const UPDATE_TENDER_TYPES_SUCCESS = 'storeSetupOptions/UPDATE_TENDER_TYPES_SUCCESS';
export const UPDATE_TENDER_TYPES_FAILURE = 'storeSetupOptions/UPDATE_TENDER_TYPES_FAILURE';

export const ADD_STATION_REQUESTED = 'storeSetupOptions/ADD_STATION_REQUESTED';
export const ADD_STATION_SUCCESS = 'storeSetupOptions/ADD_STATION_SUCCESS';
export const ADD_STATION_FAILURE = 'storeSetupOptions/ADD_STATION_FAILURE';

export const UPDATE_STATION_REQUESTED = 'storeSetupOptions/UPDATE_STATION_REQUESTED';
export const UPDATE_STATION_SUCCESS = 'storeSetupOptions/UPDATE_STATION_SUCCESS';
export const UPDATE_STATION_FAILURE = 'storeSetupOptions/UPDATE_STATION_FAILURE';

export const GET_REVENUE_CENTER_BY_STORE_ID_REQUESTED = 'storeSetupOptions/GET_REVENUE_CENTER_BY_STORE_ID_REQUESTED';
export const GET_REVENUE_CENTER_BY_STORE_ID_SUCCESS = 'storeSetupOptions/GET_REVENUE_CENTER_BY_STORE_ID_SUCCESS';
export const GET_REVENUE_CENTER_BY_STORE_ID_FAILURE = 'storeSetupOptions/GET_REVENUE_CENTER_BY_STORE_ID_FAILURE';

export const GET_STORE_DRAWER_REQUESTED = 'storeSetupOptions/GET_STORE_DRAWER_REQUESTED';
export const GET_STORE_DRAWER_SUCCESS = 'storeSetupOptions/GET_STORE_DRAWER_SUCCESS';
export const GET_STORE_DRAWER_FAILURE = 'storeSetupOptions/GET_STORE_DRAWER_FAILURE';

export const ADD_STORE_DRAWER_REQUESTED = 'storeSetupOptions/ADD_STORE_DRAWER_REQUESTED';
export const ADD_STORE_DRAWER_SUCCESS = 'storeSetupOptions/ADD_STORE_DRAWER_SUCCESS';
export const ADD_STORE_DRAWER_FAILURE = 'storeSetupOptions/ADD_STORE_DRAWER_FAILURE';

export const UPDATE_STORE_DRAWER_REQUESTED = 'storeSetupOptions/UPDATE_STORE_DRAWER_REQUESTED';
export const UPDATE_STORE_DRAWER_SUCCESS = 'storeSetupOptions/UPDATE_STORE_DRAWER_SUCCESS';
export const UPDATE_STORE_DRAWER_FAILURE = 'storeSetupOptions/UPDATE_STORE_DRAWER_FAILURE';

export const GET_STORE_DISCOUNT_TYPE_REQUESTED = 'storeSetupOptions/GET_STORE_DISCOUNT_TYPE_REQUESTED';
export const GET_STORE_DISCOUNT_TYPE_SUCCESS = 'storeSetupOptions/GET_STORE_DISCOUNT_TYPE_SUCCESS';
export const GET_STORE_DISCOUNT_TYPE_FAILURE = 'storeSetupOptions/GET_STORE_DISCOUNT_TYPE_FAILURE';

export const GET_STORE_SECTION_REQUESTED = 'storeSetupOptions/GET_STORE_SECTION_REQUESTED';
export const GET_STORE_SECTION_SUCCESS = 'storeSetupOptions/GET_STORE_SECTION_SUCCESS';
export const GET_STORE_SECTION_FAILURE = 'storeSetupOptions/GET_STORE_SECTION_FAILURE';

export const ADD_STORE_SECTION_REQUESTED = 'storeSetupOptions/ADD_STORE_SECTION_REQUESTED';
export const ADD_STORE_SECTION_SUCCESS = 'storeSetupOptions/ADD_STORE_SECTION_SUCCESS';
export const ADD_STORE_SECTION_FAILURE = 'storeSetupOptions/ADD_STORE_SECTION_FAILURE';

export const UPDATE_STORE_SECTION_REQUESTED = 'storeSetupOptions/UPDATE_STORE_SECTION_REQUESTED';
export const UPDATE_STORE_SECTION_SUCCESS = 'storeSetupOptions/UPDATE_STORE_SECTION_SUCCESS';
export const UPDATE_STORE_SECTION_FAILURE = 'storeSetupOptions/UPDATE_STORE_SECTION_FAILURE';

export const GET_JOB_TEAMS_REQUESTED = 'storeSetupOptions/GET_JOB_TEAMS_REQUESTED';
export const GET_JOB_TEAMS_SUCCESS = 'storeSetupOptions/GET_JOB_TEAMS_SUCCESS';
export const GET_JOB_TEAMS_FAILURE = 'storeSetupOptions/GET_JOB_TEAMS_FAILURE';

export const ADD_JOB_TEAM_REQUESTED = 'storeSetupOptions/ADD_JOB_TEAM_REQUESTED';
export const ADD_JOB_TEAM_SUCCESS = 'storeSetupOptions/ADD_JOB_TEAM_SUCCESS';
export const ADD_JOB_TEAM_FAILURE = 'storeSetupOptions/ADD_JOB_TEAM_FAILURE';

export const UPDATE_JOB_TEAM_REQUESTED = 'storeSetupOptions/UPDATE_JOB_TEAM_REQUESTED';
export const UPDATE_JOB_TEAM_SUCCESS = 'storeSetupOptions/UPDATE_JOB_TEAM_SUCCESS';
export const UPDATE_JOB_TEAM_FAILURE = 'storeSetupOptions/UPDATE_JOB_TEAM_FAILURE';

export const GET_SERVICE_CHARGES_REQUESTED = 'storeSetupOptions/GET_SERVICE_CHARGES_REQUESTED';
export const GET_SERVICE_CHARGES_SUCCESS = 'storeSetupOptions/GET_SERVICE_CHARGES_SUCCESS';
export const GET_SERVICE_CHARGES_FAILURE = 'storeSetupOptions/GET_SERVICE_CHARGES_FAILURE';

export const ADD_SERVICE_CHARGE_REQUESTED = 'storeSetupOptions/ADD_SERVICE_CHARGE_REQUESTED';
export const ADD_SERVICE_CHARGE_SUCCESS = 'storeSetupOptions/ADD_SERVICE_CHARGE_SUCCESS';
export const ADD_SERVICE_CHARGE_FAILURE = 'storeSetupOptions/ADD_SERVICE_CHARGE_FAILURE';

export const UPDATE_SERVICE_CHARGE_REQUESTED = 'storeSetupOptions/UPDATE_SERVICE_CHARGE_REQUESTED';
export const UPDATE_SERVICE_CHARGE_SUCCESS = 'storeSetupOptions/UPDATE_SERVICE_CHARGE_SUCCESS';
export const UPDATE_SERVICE_CHARGE_FAILURE = 'storeSetupOptions/UPDATE_SERVICE_CHARGE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  // getstoreSetupOptions: [],
  getCategories: [],
  getDiscounts: [],
  getOrderTypes: [],
  getRevenueCenters: [],
  getTenderTypes: [],
  revenueCenterList: [],
  getDrawers: [],
  getDiscountType: [],
  getSections: [],
  getJobTeamList: [],
  getServiceCharges: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCategories: action.result,
      };
    }
    case GET_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getCategories: []
      };
    }
    case ADD_CATEGORIES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CATEGORIES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DISCOUNTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getDiscounts: action.result,
      };
    }
    case GET_DISCOUNTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_DISCOUNTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_DISCOUNTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_DISCOUNTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DISCOUNTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ORDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ORDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getOrderTypes: action.result,
      };
    }
    case GET_ORDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_ORDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_ORDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_ORDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_ORDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ORDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ORDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_REVENUE_CENTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_REVENUE_CENTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getRevenueCenters: action.result,
      };
    }
    case GET_REVENUE_CENTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_REVENUE_CENTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_REVENUE_CENTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_REVENUE_CENTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_REVENUE_CENTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_REVENUE_CENTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_REVENUE_CENTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TENDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TENDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getTenderTypes: action.result,
      };
    }
    case GET_TENDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_TENDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TENDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TENDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TENDER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TENDER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TENDER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_REVENUE_CENTER_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_REVENUE_CENTER_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        revenueCenterList: action.result,
      };
    }
    case GET_REVENUE_CENTER_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_DRAWER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_DRAWER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getDrawers: action.result,
      };
    }
    case GET_STORE_DRAWER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STORE_DRAWER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STORE_DRAWER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STORE_DRAWER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_DRAWER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_DRAWER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_DRAWER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_DISCOUNT_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_DISCOUNT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getDiscountType: action.result,
      };
    }
    case GET_STORE_DISCOUNT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REORDER_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REORDER_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case REORDER_DATA_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_STORE_SECTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_SECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getSections: action.result,
      };
    }
    case GET_STORE_SECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STORE_SECTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STORE_SECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STORE_SECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_SECTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_SECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_SECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_JOB_TEAMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getJobTeamList: [],
      };
    }
    case GET_JOB_TEAMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getJobTeamList: action.result,
      };
    }
    case GET_JOB_TEAMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getJobTeamList: [],
      };
    }
    case ADD_JOB_TEAM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_JOB_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_JOB_TEAM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_JOB_TEAM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_JOB_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_JOB_TEAM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SERVICE_CHARGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SERVICE_CHARGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getServiceCharges: action.result,
      };
    }
    case GET_SERVICE_CHARGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SERVICE_CHARGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SERVICE_CHARGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_SERVICE_CHARGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SERVICE_CHARGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SERVICE_CHARGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SERVICE_CHARGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getCategories = (storeId) => {
  return {
    types: [GET_CATEGORIES_REQUESTED, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE],
    promise: client => client.get(`getStoreCategories/${storeId}`)
  };
};

export const addCategories = (data) => {
  return {
    types: [ADD_CATEGORIES_REQUESTED, ADD_CATEGORIES_SUCCESS, ADD_CATEGORIES_FAILURE],
    promise: client => client.post('addStoreCategories', { data }),
  };
};

export const updateCategories = (data) => {
  return {
    types: [UPDATE_CATEGORIES_REQUESTED, UPDATE_CATEGORIES_SUCCESS, UPDATE_CATEGORIES_FAILURE],
    promise: client => client.post('updateStoreCategories', { data }),
  };
};

export const getDiscounts = (storeId) => {
  return {
    types: [GET_DISCOUNTS_REQUESTED, GET_DISCOUNTS_SUCCESS, GET_DISCOUNTS_FAILURE],
    promise: client => client.get(`getStoreDiscounts/${storeId}`)
  };
};

export const addDiscounts = (data) => {
  return {
    types: [ADD_DISCOUNTS_REQUESTED, ADD_DISCOUNTS_SUCCESS, ADD_DISCOUNTS_FAILURE],
    promise: client => client.post('addStoreDiscounts', { data }),
  };
};

export const updateDiscounts = (data) => {
  return {
    types: [UPDATE_DISCOUNTS_REQUESTED, UPDATE_DISCOUNTS_SUCCESS, UPDATE_DISCOUNTS_FAILURE],
    promise: client => client.post('updateStoreDiscounts', { data }),
  };
};

export const getOrderTypes = (storeId) => {
  return {
    types: [GET_ORDER_TYPES_REQUESTED, GET_ORDER_TYPES_SUCCESS, GET_ORDER_TYPES_FAILURE],
    promise: client => client.get(`getStoreOrderTypes/${storeId}`)
  };
};

export const addOrderTypes = (data) => {
  return {
    types: [ADD_ORDER_TYPES_REQUESTED, ADD_ORDER_TYPES_SUCCESS, ADD_ORDER_TYPES_FAILURE],
    promise: client => client.post('addStoreOrderTypes', { data }),
  };
};

export const updateOrderTypes = (data) => {
  return {
    types: [UPDATE_ORDER_TYPES_REQUESTED, UPDATE_ORDER_TYPES_SUCCESS, UPDATE_ORDER_TYPES_FAILURE],
    promise: client => client.post('updateStoreOrderTypes', { data }),
  };
};

export const getRevenueCenters = (storeId) => {
  return {
    types: [GET_REVENUE_CENTERS_REQUESTED, GET_REVENUE_CENTERS_SUCCESS, GET_REVENUE_CENTERS_FAILURE],
    promise: client => client.get(`getStoreRevenueCenters/${storeId}`)
  };
};

export const addRevenueCenters = (data) => {
  return {
    types: [ADD_REVENUE_CENTERS_REQUESTED, ADD_REVENUE_CENTERS_SUCCESS, ADD_REVENUE_CENTERS_FAILURE],
    promise: client => client.post('addStoreRevenueCenters', { data }),
  };
};

export const updateRevenueCenters = (data) => {
  return {
    types: [UPDATE_REVENUE_CENTERS_REQUESTED, UPDATE_REVENUE_CENTERS_SUCCESS, UPDATE_REVENUE_CENTERS_FAILURE],
    promise: client => client.post('updateStoreRevenueCenters', { data }),
  };
};

export const getTenderTypes = (storeId) => {
  return {
    types: [GET_TENDER_TYPES_REQUESTED, GET_TENDER_TYPES_SUCCESS, GET_TENDER_TYPES_FAILURE],
    promise: client => client.get(`getStoreTenderTypes/${storeId}`)
  };
};

export const addTenderTypes = (data) => {
  return {
    types: [ADD_TENDER_TYPES_REQUESTED, ADD_TENDER_TYPES_SUCCESS, ADD_TENDER_TYPES_FAILURE],
    promise: client => client.post('addStoreTenderTypes', { data }),
  };
};

export const updateTenderTypes = (data) => {
  return {
    types: [UPDATE_TENDER_TYPES_REQUESTED, UPDATE_TENDER_TYPES_SUCCESS, UPDATE_TENDER_TYPES_FAILURE],
    promise: client => client.post('updateStoreTenderTypes', { data }),
  };
};

export const addStations = (data) => {
  return {
    types: [ADD_STATION_REQUESTED, ADD_STATION_SUCCESS, ADD_STATION_FAILURE],
    promise: client => client.post('addStoreStations', { data }),
  };
};

export const updateStations = (data) => {
  return {
    types: [UPDATE_STATION_REQUESTED, UPDATE_STATION_SUCCESS, UPDATE_STATION_FAILURE],
    promise: client => client.post('updateStoreStations', { data }),
  };
};

export const getRevenueCentersByStoreId = (storeId) => {
  return {
    types: [GET_REVENUE_CENTER_BY_STORE_ID_REQUESTED, GET_REVENUE_CENTER_BY_STORE_ID_SUCCESS, GET_REVENUE_CENTER_BY_STORE_ID_FAILURE],
    promise: client => client.get(`getRevenueCentersByStoreId/${storeId}`)
  };
};

export const getDrawers = (storeId) => {
  return {
    types: [GET_STORE_DRAWER_REQUESTED, GET_STORE_DRAWER_SUCCESS, GET_STORE_DRAWER_FAILURE],
    promise: client => client.get(`getStoreDrawers/${storeId}`)
  };
};

export const addDrawers = (data) => {
  return {
    types: [ADD_STORE_DRAWER_REQUESTED, ADD_STORE_DRAWER_SUCCESS, ADD_STORE_DRAWER_FAILURE],
    promise: client => client.post('addStoreDrawers', { data }),
  };
};

export const updateDrawers = (data) => {
  return {
    types: [UPDATE_STORE_DRAWER_REQUESTED, UPDATE_STORE_DRAWER_SUCCESS, UPDATE_STORE_DRAWER_FAILURE],
    promise: client => client.post('updateStoreDrawers', { data }),
  };
};

export const getDiscountTypes = () => {
  return {
    types: [GET_STORE_DISCOUNT_TYPE_REQUESTED, GET_STORE_DISCOUNT_TYPE_SUCCESS, GET_STORE_DISCOUNT_TYPE_FAILURE],
    promise: client => client.get('getDiscountTypes')
  };
};

export const reOrderOrederTypes = (data) => {
  return {
    types: [REORDER_DATA_REQUESTED, REORDER_DATA_SUCCESS, REORDER_DATA_FAILURE],
    promise: client => client.post('sortedData', { data }),
  };
};

export const reorderRevenueCenters = (data) => {
  return {
    types: [REORDER_DATA_REQUESTED, REORDER_DATA_SUCCESS, REORDER_DATA_FAILURE],
    promise: client => client.post('reorderRevenueCenter', { data }),
  };
};

export const getSections = (storeId) => {
  return {
    types: [GET_STORE_SECTION_REQUESTED, GET_STORE_SECTION_SUCCESS, GET_STORE_SECTION_FAILURE],
    promise: client => client.get(`getStoreSections/${storeId}`)
  };
};

export const addSections = (data) => {
  return {
    types: [ADD_STORE_SECTION_REQUESTED, ADD_STORE_SECTION_SUCCESS, ADD_STORE_SECTION_FAILURE],
    promise: client => client.post('addStoreSections', { data }),
  };
};

export const updateSections = (data) => {
  return {
    types: [UPDATE_STORE_SECTION_REQUESTED, UPDATE_STORE_SECTION_SUCCESS, UPDATE_STORE_SECTION_FAILURE],
    promise: client => client.post('updateStoreSections', { data }),
  };
};
export const getJobsTeamList = (storeId) => {
  return {
    types: [GET_JOB_TEAMS_REQUESTED, GET_JOB_TEAMS_SUCCESS, GET_JOB_TEAMS_FAILURE],
    promise: client => client.get(`getJobTeams/${storeId}`)
  };
};

export const addJobTeam = (data) => {
  return {
    types: [ADD_JOB_TEAM_REQUESTED, ADD_JOB_TEAM_SUCCESS, ADD_JOB_TEAM_FAILURE],
    promise: client => client.post('addJobTeam', { data }),
  };
};

export const updateJobTeam = (data) => {
  return {
    types: [UPDATE_JOB_TEAM_REQUESTED, UPDATE_JOB_TEAM_SUCCESS, UPDATE_JOB_TEAM_FAILURE],
    promise: client => client.post('updateJobTeam', { data }),
  };
};

export const getServiceCharges = (storeId) => {
  return {
    types: [GET_SERVICE_CHARGES_REQUESTED, GET_SERVICE_CHARGES_SUCCESS, GET_SERVICE_CHARGES_FAILURE],
    promise: client => client.get(`getStoreServiceCharges/${storeId}`)
  };
};

export const addServiceCharge = (data) => {
  return {
    types: [ADD_SERVICE_CHARGE_REQUESTED, ADD_SERVICE_CHARGE_SUCCESS, ADD_SERVICE_CHARGE_FAILURE],
    promise: client => client.post('addStoreServiceCharge', { data })
  };
};

export const updateServiceCharge = (data) => {
  return {
    types: [UPDATE_SERVICE_CHARGE_REQUESTED, UPDATE_SERVICE_CHARGE_SUCCESS, UPDATE_SERVICE_CHARGE_FAILURE],
    promise: client => client.post('updateStoreServiceCharge', { data })
  };
};
