const currentReducer = 'tmsStoreChannelSetup';

const GET_STORE_CHANNEL_REQUESTED = `${currentReducer}/GET_STORE_CHANNEL_REQUESTED`;
const GET_STORE_CHANNEL_SUCCESS = `${currentReducer}/GET_STORE_CHANNEL_SUCCESS`;
const GET_STORE_CHANNEL_FAILURE = `${currentReducer}/GET_STORE_CHANNEL_FAILURE`;

const GET_ALL_CHANNELS_REQUESTED = `${currentReducer}/GET_ALL_CHANNELS_REQUESTED`;
const GET_ALL_CHANNELS_SUCCESS = `${currentReducer}/GET_ALL_CHANNELS_SUCCESS`;
const GET_ALL_CHANNELS_FAILURE = `${currentReducer}/GET_ALL_CHANNELS_FAILURE`;

const UPDATE_STORE_CHANNEL_REQUESTED = `${currentReducer}/UPDATE_STORE_CHANNEL_REQUESTED`;
const UPDATE_STORE_CHANNEL_SUCCESS = `${currentReducer}/UPDATE_STORE_CHANNEL_SUCCESS`;
const UPDATE_STORE_CHANNEL_FAILURE = `${currentReducer}/UPDATE_STORE_CHANNEL_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  storeChannelList: [],
  availableChannelList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_CHANNEL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        storeChannelList: null
      };
    }
    case GET_STORE_CHANNEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeChannelList: action.result
      };
    }
    case GET_STORE_CHANNEL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        storeChannelList: []
      };
    }
    case GET_ALL_CHANNELS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_ALL_CHANNELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        availableChannelList: action.result
      };
    }
    case GET_ALL_CHANNELS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        availableChannelList: [],
      };
    }
    case UPDATE_STORE_CHANNEL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_STORE_CHANNEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_CHANNEL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getStoreChannels = (data) => {
  return {
    types: [GET_STORE_CHANNEL_REQUESTED, GET_STORE_CHANNEL_SUCCESS, GET_STORE_CHANNEL_FAILURE],
    promise: client => client.post('tmsSetup', { data })
  };
};

export const getAvailableChannels = (data) => {
  return {
    types: [GET_ALL_CHANNELS_REQUESTED, GET_ALL_CHANNELS_SUCCESS, GET_ALL_CHANNELS_FAILURE],
    promise: client => client.post('tmsSetup', { data })
  };
};

export const updateStoreChannel = (data) => {
  return {
    types: [UPDATE_STORE_CHANNEL_REQUESTED, UPDATE_STORE_CHANNEL_SUCCESS, UPDATE_STORE_CHANNEL_FAILURE],
    promise: client => client.post('tmsSetup', { data })
  };
};
