export const GET_RECEIPT_REQUESTED = 'reports/GET_RECEIPT_REQUESTED';
export const GET_RECEIPT_SUCCESS = 'reports/GET_RECEIPT_SUCCESS';
export const GET_RECEIPT_FAILURE = 'reports/GET_RECEIPT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  receiptData: {},
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIPT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RECEIPT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        receiptData: action.result,
      };
    }
    case GET_RECEIPT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        receiptData: {},
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getReceiptData = (ticketId, referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: client => client.get(`getReceipt/${ticketId}-${referenceCode}`),
  };
};

export const getDisplayOrder = (referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: client => client.get(`getOrderDisplay/${referenceCode}`),
  };
};

export const getCheckReceiptData = (ticketId, referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: client => client.get(`checkReceipt/${ticketId}-${referenceCode}`),
  };
};

export const getGiftCardReceiptData = (ticketId, referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: client => client.get(`giftCardReceipt/${ticketId}-${referenceCode}`),
  };
};
