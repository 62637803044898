
export const ADD_MALL_REQUESTED = 'mall/ADD_MALL_REQUESTED';
export const ADD_MALL_SUCCESS = 'mall/ADD_MALL_SUCCESS';
export const ADD_MALL_FAILURE = 'mall/ADD_MALL_FAILURE';

export const POST_MALL_IMAGE_REQUESTED = 'mall/POST_MALL_IMAGE_REQUESTED';
export const POST_MALL_IMAGE_SUCCESS = 'mall/POST_MALL_IMAGE_SUCCESS';
export const POST_MALL_IMAGE_FAILURE = 'mall/POST_MALL_IMAGE_FAILURE';

export const UPDATE_MALL_REQUESTED = 'mall/UPDATE_MALL_REQUESTED';
export const UPDATE_MALL_SUCCESS = 'mall/UPDATE_MALL_SUCCESS';
export const UPDATE_MALL_FAILURE = 'mall/UPDATE_MALL_FAILURE';

export const GET_MALL_BYID_REQUESTED = 'mall/GET_MALL_BYID_REQUESTED';
export const GET_MALL_BYID_SUCCESS = 'mall/GET_MALL_BYID_SUCCESS';
export const GET_MALL_BYID_FAILURE = 'mall/GET_MALL_BYID_FAILURE';

export const GET_MALLS_LIST_REQUESTED = 'mall/GET_MALLS_LIST_REQUESTED';
export const GET_MALLS_LIST_SUCCESS = 'mall/GET_MALLS_LIST_SUCCESS';
export const GET_MALLS_LIST_FAILURE = 'mall/GET_MALLS_LIST_FAILURE';

export const GET_MALLS_LIST_DATA_REQUESTED = 'mall/GET_MALLS_LIST_DATA_REQUESTED';
export const GET_MALLS_LIST_DATA_SUCCESS = 'mall/GET_MALLS_LIST_DATA_SUCCESS';
export const GET_MALLS_LIST_DATA_FAILURE = 'mall/GET_MALLS_LIST_DATA_FAILURE';

export const GET_MALLS_STORES_REQUESTED = 'mall/GET_MALLS_STORES_REQUESTED';
export const GET_MALLS_STORES_SUCCESS = 'mall/GET_MALLS_STORES_SUCCESS';
export const GET_MALLS_STORES_FAILURE = 'mall/GET_MALLS_STORES_FAILURE';

export const ADD_MALL_STORE_REQUESTED = 'mall/ADD_MALL_STORE_REQUESTED';
export const ADD_MALL_STORE_SUCCESS = 'mall/ADD_MALL_STORE_SUCCESS';
export const ADD_MALL_STORE_FAILURE = 'mall/ADD_MALL_STORE_FAILURE';

export const DELETE_MALL_STORE_REQUESTED = 'mall/DELETE_MALL_STORE_REQUESTED';
export const DELETE_MALL_STORE_SUCCESS = 'mall/DELETE_MALL_STORE_SUCCESS';
export const DELETE_MALL_STORE_FAILURE = 'mall/DELETE_MALL_STORE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getMallByID: null,
  mallList: [],
  mallListData: [],
  mallStores: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MALLS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MALLS_LIST_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        mallList: action.result,
      };
    }
    case GET_MALLS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        mallList: [],
      };
    }
    case POST_MALL_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_MALL_IMAGE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case POST_MALL_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MALL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MALL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MALL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MALL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MALL_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MALL_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMallById: action.result,
      };
    }
    case GET_MALL_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MALLS_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MALLS_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        mallListData: action.result,
      };
    }
    case GET_MALLS_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MALLS_STORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MALLS_STORES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        mallStores: action.result,
      };
    }
    case GET_MALLS_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MALL_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MALL_STORE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        mallStores: action.result,
      };
    }
    case ADD_MALL_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MALL_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MALL_STORE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        mallStores: action.result,
      };
    }
    case DELETE_MALL_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addMall = (data) => {
  return {
    types: [ADD_MALL_REQUESTED, ADD_MALL_SUCCESS, ADD_MALL_FAILURE],
    promise: client => client.post('addMall', { data })
  };
};

export const updateMall = (data) => {
  return {
    types: [UPDATE_MALL_REQUESTED, UPDATE_MALL_SUCCESS, UPDATE_MALL_FAILURE],
    promise: client => client.post('updateMall', { data })
  };
};

export const getMallById = (id) => {
  return {
    types: [GET_MALL_BYID_REQUESTED, GET_MALL_BYID_SUCCESS, GET_MALL_BYID_FAILURE],
    promise: client => client.get(`getMallById/${id}`)
  };
};

export const getMallsList = () => {
  return {
    types: [GET_MALLS_LIST_REQUESTED, GET_MALLS_LIST_SUCCESS, GET_MALLS_LIST_FAILURE],
    promise: client => client.get('getMallsList')
  };
};

export const getMallsListData = (id) => {
  return {
    types: [GET_MALLS_LIST_DATA_REQUESTED, GET_MALLS_LIST_DATA_SUCCESS, GET_MALLS_LIST_DATA_FAILURE],
    promise: client => client.get(`getMallsListData/${id}`)
  };
};

export const getMallStores = (id) => {
  return {
    types: [GET_MALLS_STORES_REQUESTED, GET_MALLS_STORES_SUCCESS, GET_MALLS_STORES_FAILURE],
    promise: client => client.get(`getVenuesById/${id}`)
  };
};

export const addMallStore = (id, storeId) => {
  return {
    types: [ADD_MALL_REQUESTED, ADD_MALL_STORE_SUCCESS, ADD_MALL_STORE_FAILURE],
    promise: client => client.post(`addMallStore/${id}/storeId/${storeId}`)
  };
};

export const deleteMallStore = (id, storeId) => {
  return {
    types: [DELETE_MALL_STORE_REQUESTED, DELETE_MALL_STORE_SUCCESS, DELETE_MALL_STORE_FAILURE],
    promise: client => client.post(`deleteMallStore/${id}/storeId/${storeId}`)
  };
};

export const addMallImage = (mallId, data, type) => {
  return {
    types: [POST_MALL_IMAGE_REQUESTED, POST_MALL_IMAGE_SUCCESS, POST_MALL_IMAGE_FAILURE],
    promise: client => client.post(`mallLogoImage/${mallId}/${type}/save`, { data })
  };
};

export const deleteMallImage = (mallId, type) => {
  return {
    types: [POST_MALL_IMAGE_REQUESTED, POST_MALL_IMAGE_SUCCESS, POST_MALL_IMAGE_FAILURE],
    promise: client => client.post(`mallLogoImage/${mallId}/${type}/delete`)
  };
};
