export const GET_COURSE_BY_MENU_REQUESTED = 'menuCourses/GET_COURSE_BY_MENU_REQUESTED';
export const GET_COURSE_BY_MENU_SUCCESS = 'menuCourses/GET_COURSE_BY_MENU_SUCCESS';
export const GET_COURSE_BY_MENU_FAILURE = 'menuCourses/GET_COURSE_BY_MENU_FAILURE';

export const ADD_COURSE_MENU_REQUESTED = 'menuCourses/ADD_COURSE_MENU_REQUESTED';
export const ADD_COURSE_MENU_SUCCESS = 'menuCourses/ADD_COURSE_MENU_SUCCESS';
export const ADD_COURSE_MENU_FAILURE = 'menuCourses/ADD_COURSE_MENU_FAILURE';

export const UPDATE_COURSE_MENU_REQUESTED = 'menuCourses/UPDATE_COURSE_MENU_REQUESTED';
export const UPDATE_COURSE_MENU_SUCCESS = 'menuCourses/UPDATE_COURSE_MENU_SUCCESS';
export const UPDATE_COURSE_MENU_FAILURE = 'menuCourses/UPDATE_COURSE_MENU_FAILURE';

export const DELETE_COURSE_MENU_REQUESTED = 'menuCourses/DELETE_COURSE_MENU_REQUESTED';
export const DELETE_COURSE_MENU_SUCCESS = 'menuCourses/DELETE_COURSE_MENU_SUCCESS';
export const DELETE_COURSE_MENU_FAILURE = 'menuCourses/DELETE_COURSE_MENU_FAILURE';

export const REORDER_COURSE_MENU_REQUESTED = 'menuCourses/REORDER_COURSE_MENU_REQUESTED';
export const REORDER_COURSE_MENU_SUCCESS = 'menuCourses/REORDER_COURSE_MENU_SUCCESS';
export const REORDER_COURSE_MENU_FAILURE = 'menuCourses/REORDER_COURSE_MENU_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getMenuCourseList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_BY_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getMenuCourseList: [],
      };
    }
    case GET_COURSE_BY_MENU_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getMenuCourseList: action.result,
      };
    }
    case GET_COURSE_BY_MENU_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        getMenuCourseList: [],
      };
    }
    case ADD_COURSE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_COURSE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_COURSE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_COURSE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_COURSE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_COURSE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_COURSE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_COURSE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_COURSE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REORDER_COURSE_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REORDER_COURSE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REORDER_COURSE_MENU_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getMenuCourses = (brandId) => {
  return {
    types: [GET_COURSE_BY_MENU_REQUESTED, GET_COURSE_BY_MENU_SUCCESS, GET_COURSE_BY_MENU_FAILURE],
    promise: client => client.get(`getMenuCourses/${brandId}`)
  };
};

export const addMenuCourses = (data) => {
  return {
    types: [ADD_COURSE_MENU_REQUESTED, ADD_COURSE_MENU_SUCCESS, ADD_COURSE_MENU_FAILURE],
    promise: client => client.post('addMenuCourses', { data })
  };
};

export const updateMenuCourses = (data) => {
  return {
    types: [UPDATE_COURSE_MENU_REQUESTED, UPDATE_COURSE_MENU_SUCCESS, UPDATE_COURSE_MENU_FAILURE],
    promise: client => client.post('updateMenuCourses', { data })
  };
};

export const deleteMenuCourses = (courseId) => {
  return {
    types: [DELETE_COURSE_MENU_REQUESTED, DELETE_COURSE_MENU_SUCCESS, DELETE_COURSE_MENU_FAILURE],
    promise: client => client.post(`deleteMenuCourses/${courseId}`)
  };
};

export const reorderMenuCourses = (data) => {
  return {
    types: [REORDER_COURSE_MENU_REQUESTED, REORDER_COURSE_MENU_SUCCESS, REORDER_COURSE_MENU_FAILURE],
    promise: client => client.post('reorderMenuCourses', { data })
  };
};
