export const getUserBasicDetails = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).user;
};

export const setUserBasicDetails = (data) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  userData.user = data;
  window.localStorage.setItem('userData', JSON.stringify(userData));
};

export const getToken = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const isUserLogin = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const getUserRoleId = () => {
  const userData = (JSON.parse(window.localStorage.getItem('userData')) || {});
  let roleId = null;
  if (userData && userData.user) {
    roleId = userData.user.roleId;
  }
  return roleId;
};

export const getEmpSelectedStoreDetails = () => {
  return (JSON.parse(window.localStorage.getItem('empSelectedStore')) || {});
};

export default {
  getUserBasicDetails,
  setUserBasicDetails,
  getToken,
  isUserLogin,
  getUserRoleId,
  getEmpSelectedStoreDetails,
};
