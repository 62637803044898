
export const GET_OTTER_SETUP_REQUESTED = 'otter/GET_OTTER_SETUP_REQUESTED';
export const GET_OTTER_SETUP_SUCCESS = 'otter/GET_OTTER_SETUP_SUCCESS';
export const GET_OTTER_SETUP_FAILURE = 'otter/GET_OTTER_SETUP_FAILURE';

export const UPDATE_OTTER_SETUP_REQUESTED = 'otter/UPDATE_OTTER_SETUP_REQUESTED';
export const UPDATE_OTTER_SETUP_SUCCESS = 'otter/UPDATE_OTTER_SETUP_SUCCESS';
export const UPDATE_OTTER_SETUP_FAILURE = 'otter/UPDATE_OTTER_SETUP_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  otterList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_OTTER_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        otterList: [],
      };
    }
    case GET_OTTER_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        otterList: action.result,
      };
    }
    case GET_OTTER_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_OTTER_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_OTTER_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_OTTER_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getOtterConfig = (brandId) => {
  return {
    types: [GET_OTTER_SETUP_REQUESTED, GET_OTTER_SETUP_SUCCESS, GET_OTTER_SETUP_FAILURE],
    promise: client => client.get(`getOtterConfig/${brandId}`)
  };
};

export const updateOtterConfig = (brandId, data) => {
  return {
    types: [UPDATE_OTTER_SETUP_REQUESTED, UPDATE_OTTER_SETUP_SUCCESS, UPDATE_OTTER_SETUP_FAILURE],
    promise: client => client.post(`updateOtterConfig/${brandId}`, { data })
  };
};
