import {
  getConfigData, setConfigData, getCompanyData, getBrandObject, getStoreData
} from '@utility/storageOperations';


const SET_LOCATION_INFO = 'mainContent/SET_LOCATION_INFO';
const SET_TOGGLE_INFO = 'mainContent/SET_TOGGLE_INFO';
const SET_SETTINGS_SEARCH_KEY = 'mainContent/SET_SETTINGS_SEARCH_KEY';
const SET_SIDEBAR_TYPE = 'mainContent/SET_SIDEBAR_TYPE';
const SET_SIDEBAR_LEVEL = 'mainContent/SET_SIDEBAR_LEVEL';
const SET_SETTINGS_LAYOUT = 'mainContent/SET_SETTINGS_LAYOUT';
const SET_SETTINGS_GRID_SIZE = 'mainContent/SET_SETTINGS_GRID_SIZE';
const SET_SELECTED_COMPANY = 'mainContent/SET_SELECTED_COMPANY';
const SET_SELECTED_BRAND = 'mainContent/SET_SELECTED_BRAND';
const SET_SELECTED_STORE = 'mainContent/SET_SELECTED_STORE';
const TOGGLE_SIDE_BAR = 'mainContent/TOGGLE_SIDE_BAR';
const HIDE_SIDE_BAR = 'mainContent/HIDE_SIDE_BAR';
const SET_SELECTED_SETTING = 'mainContent/SET_SELECTED_SETTING';
const SET_ADDITIONAL_TITLE = 'mainContent/SET_ADDITIONAL_TITLE';

const DEFAULT_SIDEBAR_TYPE = 'dropdown';
const DEFAULT_SIDEBAR_DETAIL_LEVEL = 'name';
const DEFAULT_LAYOUT_VIEW = 'grid';
const DEFAULT_GRID_SIZE = 'compact';

const initialValues = {
  sidebar: {
    type: (getConfigData() && getConfigData().sidebar && getConfigData().sidebar.type) || DEFAULT_SIDEBAR_TYPE,
    level: (getConfigData() && getConfigData().sidebar && getConfigData().sidebar.level) || DEFAULT_SIDEBAR_DETAIL_LEVEL,
    collapse: false,
    hidden: false,
  },
  settings: {
    layout: (getConfigData() && getConfigData().settings && getConfigData().settings.layout) || DEFAULT_LAYOUT_VIEW,
    gridSize: (getConfigData() && getConfigData().settings && getConfigData().settings.gridSize) || DEFAULT_GRID_SIZE,
  },
  isNavOpen: null,
  tempLoad: false,
  menu: {
    type: '',
    controlUrl: ''
  },
  newMenu: {
    type: '',
    controlUrl: ''
  },
  closeButton: {
    type: '',
    controlUrl: ''
  },
  restaurantInfo: {
    show: false
  },
  loading: false,
  settingsSearchKey: null,
  company: getCompanyData() || null,
  brand: getBrandObject() || null,
  store: getStoreData() || null,
  settingsData: null,
  additionalSettingsTitle: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case SET_TOGGLE_INFO:
      return {
        ...state,
        isNavOpen: !state.isNavOpen
      };
    case SET_LOCATION_INFO:
      return {
        ...state,
        loading: false,
        tempLoad: !state.tempLoad,
        ...action.payload
      };
    case SET_SETTINGS_SEARCH_KEY:
      return {
        ...state,
        settingsSearchKey: action.payload,
      };
    case SET_SIDEBAR_TYPE:
      setConfigData({ ...getConfigData(), sidebar: { ...getConfigData().sidebar, type: action.payload } });
      return {
        ...state,
        sidebar: { ...state.sidebar, type: action.payload },
      };
    case SET_SIDEBAR_LEVEL:
      setConfigData({ ...getConfigData(), sidebar: { ...getConfigData().sidebar, level: action.payload } });
      return {
        ...state,
        sidebar: { ...state.sidebar, level: action.payload },
      };
    case SET_SETTINGS_LAYOUT:
      setConfigData({ ...getConfigData(), settings: { ...getConfigData().settings, layout: action.payload } });
      return {
        ...state,
        settings: { ...state.settings, layout: action.payload },
      };
    case SET_SETTINGS_GRID_SIZE:
      setConfigData({ ...getConfigData(), settings: { ...getConfigData().settings, gridSize: action.payload } });
      return {
        ...state,
        settings: { ...state.settings, gridSize: action.payload },
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_SELECTED_BRAND:
      return {
        ...state,
        brand: action.payload,
      };
    case SET_SELECTED_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case TOGGLE_SIDE_BAR:
      return {
        ...state,
        sidebar: { ...state.sidebar, collapse: action.payload },
      };
    case HIDE_SIDE_BAR:
      return {
        ...state,
        sidebar: { ...state.sidebar, hidden: action.payload },
      };
    case SET_SELECTED_SETTING:
      return {
        ...state,
        settingsData: action.payload,
      };
    case SET_ADDITIONAL_TITLE:
      return {
        ...state,
        additionalSettingsTitle: action.payload,
      };
    default:
      return state;
  }
};

export const toggleLocation = () => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_TOGGLE_INFO
      }
    );
  };
};

export const setLocationInfo = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_LOCATION_INFO,
        payload: data
      }
    );
  };
};

export const setSettingsSearchKey = (results) => {
  return (dispatch) => {
    dispatch({
      type: SET_SETTINGS_SEARCH_KEY,
      payload: results,
    });
  };
};

export const setSidebarType = (results) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_TYPE,
      payload: results,
    });
  };
};

export const setSidebarLevel = (results) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_LEVEL,
      payload: results,
    });
  };
};

export const setSettingsLayout = (results) => {
  return (dispatch) => {
    dispatch({
      type: SET_SETTINGS_LAYOUT,
      payload: results,
    });
  };
};

export const setSettingsGridSize = (results) => {
  return (dispatch) => {
    dispatch({
      type: SET_SETTINGS_GRID_SIZE,
      payload: results,
    });
  };
};

export const setCompany = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_COMPANY,
      payload: data,
    });
  };
};

export const setBrand = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_BRAND,
      payload: data,
    });
  };
};

export const setStore = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_STORE,
      payload: data,
    });
  };
};

export const toggleSideBar = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SIDE_BAR,
      payload: data,
    });
  };
};

export const hideSideBar = (data) => {
  return (dispatch) => {
    dispatch({
      type: HIDE_SIDE_BAR,
      payload: data,
    });
  };
};


export const setSelectedSettingData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_SETTING,
      payload: data,
    });
  };
};

export const setAdditionalTitle = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ADDITIONAL_TITLE,
      payload: data,
    });
  };
};
