export default {
  MDM_DEVICE_ADD: 'mdm_device_add',
  MDM_DEVICE_UPDATE: 'mdm_device_update',
  MDM_DEVICE_REACTIVATE: 'mdm_device_reactivate',
  MDM_DEVICE_DEACTIVATE: 'mdm_device_deactivate',
  MDM_DEVICE_GET_STORE: 'mdm_device_get_store',
  MDM_STORE_POLICY_GET: 'mdm_store_policy_get',
  MDM_STORE_ADD: 'mdm_store_add',
  GET_STORE_CHANNEL: 'get_store_channel',
  UPDATE_STORE_CHANNEL: 'update_store_channel',
  GET_CHANNEL: 'get_channel',
  TMS_ADD_TAG: 'add_tag',
  TMS_ADD_STORE: 'add_store',
  TMS_GET_TAGS_LIST: 'get_store_tag',
  TMS_UPDATE_TAG: 'update_tag',

  WAREHOUSE_REPORT_BATCH: 'report_batch',
  WAREHOUSE_REPORT_TRANSACTION: 'report_transaction',
  WAREHOUSE_REPORT_CHECKOUTER: 'report_check_outer',
  WAREHOUSE_REPORT_CHECKINNER: 'report_check_inner',
  WAREHOUSE_REPORT_ROLLING_BATCH: 'report_rolling_batch',
  WAREHOUSE_REPORT_ITEMS_SALES: 'report_item_sales',
  SURVEY_REPORT: 'report_survey',
  GUEST_CHARGEBACK: 'report_guest_chargeback',

  // OMS
  OMS_GET_OFFER_URL: 'service/Offer/brandOffers',
  RULE_TYPE_LIST: 'service/Offer/ruleTypes',
  DISCOUNT_TYPE_LIST: 'service/Offer/discountTypes',
  CREATE_OFFERS: 'service/Offer/createOffer',
  LOAD_OFFER: 'service/Offer/loadOffer',
  UPDATE_OFFER: 'service/Offer/updateOffer',

  // ORDER REPORT
  ORDER_REPORT: 'report_orders',
  ADVANCE_ORDER_REPORT: 'report_advanced_orders',
  ORDER_DISTRIBUTION_REPORT: 'report_orders_by_timeofday',
  REPORT_ORDERS_DEVLIVERY: 'report_orders_deliveryvendor',
  REPORT_ORDERS_MARKETPLACE: 'report_orders_marketplace',

  // API LOGS REPORT
  API_LOGS_REPORT: 'search_apilog',
  API_LOGS_STATUS: 'search_apilog_status',

  // Data Request reportType
  DATA_REQUEST_REPORT: 'report_data_request'
};
