const currentReducer = 'channelConfig';
export const GET_CHANNEL_CONFIG_REQUESTED = `${currentReducer}/GET_CHANNEL_CONFIG_REQUESTED`;
export const GET_CHANNEL_CONFIG_SUCCESS = `${currentReducer}/GET_CHANNEL_CONFIG_SUCCESS`;
export const GET_CHANNEL_CONFIG_FAILURE = `${currentReducer}/GET_CHANNEL_CONFIG_FAILURE`;

export const UPDATE_CHANNEL_CONFIG_REQUESTED = `${currentReducer}/UPDATE_CHANNEL_CONFIG_REQUESTED`;
export const UPDATE_CHANNEL_CONFIG_SUCCESS = `${currentReducer}/UPDATE_CHANNEL_CONFIG_SUCCESS`;
export const UPDATE_CHANNEL_CONFIG_FAILURE = `${currentReducer}/UPDATE_CHANNEL_CONFIG_FAILURE`;


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  channelList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHANNEL_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CHANNEL_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelList: action.result
      };
    }
    case GET_CHANNEL_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_CHANNEL_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_CHANNEL_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelList: action.result
      };
    }
    case UPDATE_CHANNEL_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getChannelConfig = (brandId) => {
  return {
    types: [GET_CHANNEL_CONFIG_REQUESTED, GET_CHANNEL_CONFIG_SUCCESS, GET_CHANNEL_CONFIG_FAILURE],
    promise: client => client.get(`getChannelConfig/${brandId}`)
  };
};

export const updateChannelConfig = (data) => {
  return {
    types: [UPDATE_CHANNEL_CONFIG_REQUESTED, UPDATE_CHANNEL_CONFIG_SUCCESS, UPDATE_CHANNEL_CONFIG_FAILURE],
    promise: client => client.post('updateChannelConfig', { data })
  };
};
