const currentReducer = 'systemNotifications';
const GET_SYSTEM_NOTIFICATIONS_REQUESTED = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_REQUESTED`;
const GET_SYSTEM_NOTIFICATIONS_SUCCESS = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_SUCCESS`;
const GET_SYSTEM_NOTIFICATIONS_FAILURE = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_FAILURE`;

const GET_SYSTEM_NOTIFICATIONS_BY_ID_REQUESTED = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_BY_ID_REQUESTED`;
const GET_SYSTEM_NOTIFICATIONS_BY_ID_SUCCESS = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_BY_ID_SUCCESS`;
const GET_SYSTEM_NOTIFICATIONS_BY_ID_FAILURE = `${currentReducer}/GET_SYSTEM_NOTIFICATIONS_BY_ID_FAILURE`;

const ADD_SYSTEM_NOTIFICATIONS_REQUESTED = 'systemNotifications/ADD_SYSTEM_NOTIFICATIONS_REQUESTED';
const ADD_SYSTEM_NOTIFICATIONS_SUCCESS = 'systemNotifications/ADD_SYSTEM_NOTIFICATIONS_SUCCESS';
const ADD_SYSTEM_NOTIFICATIONS_FAILURE = 'systemNotifications/ADD_SYSTEM_NOTIFICATIONS_FAILURE';

const UPDATE_SYSTEM_NOTIFICATIONS_REQUESTED = 'systemNotifications/UPDATE_SYSTEM_NOTIFICATIONS_REQUESTED';
const UPDATE_SYSTEM_NOTIFICATIONS_SUCCESS = 'systemNotifications/UPDATE_SYSTEM_NOTIFICATIONS_SUCCESS';
const UPDATE_SYSTEM_NOTIFICATIONS_FAILURE = 'systemNotifications/UPDATE_SYSTEM_NOTIFICATIONS_FAILURE';

const DELETE_SYSTEM_NOTIFICATIONS_REQUESTED = 'systemNotifications/DELETE_SYSTEM_NOTIFICATIONS_REQUESTED';
const DELETE_SYSTEM_NOTIFICATIONS_SUCCESS = 'systemNotifications/DELETE_SYSTEM_NOTIFICATIONS_SUCCESS';
const DELETE_SYSTEM_NOTIFICATIONS_FAILURE = 'systemNotifications/DELETE_SYSTEM_NOTIFICATIONS_FAILURE';

const SERVERITIES_SYSTEM_NOTIFICATIONS_REQUESTED = 'systemNotifications/SERVERITIES_SYSTEM_NOTIFICATIONS_REQUESTED';
const SERVERITIES_SYSTEM_NOTIFICATIONS_SUCCESS = 'systemNotifications/SERVERITIES_SYSTEM_NOTIFICATIONS_SUCCESS';
const SERVERITIES_SYSTEM_NOTIFICATIONS_FAILURE = 'systemNotifications/SERVERITIES_SYSTEM_NOTIFICATIONS_FAILURE';

const SYSTEM_NOTIFICATIONS_TYPE_REQUESTED = `${currentReducer}/SYSTEM_NOTIFICATIONS_TYPE_REQUESTED`;
const SYSTEM_NOTIFICATIONS_TYPE_SUCCESS = `${currentReducer}/SYSTEM_NOTIFICATIONS_TYPE_SUCCESS`;
const SYSTEM_NOTIFICATIONS_TYPE_FAILURE = `${currentReducer}/SYSTEM_NOTIFICATIONS_TYPE_FAILURE`;

const SYSTEM_NOTIFICATIONS_PUBLISH_REQUESTED = `${currentReducer}/SYSTEM_NOTIFICATIONS_PUBLISH_REQUESTED`;
const SYSTEM_NOTIFICATIONS_PUBLISH_SUCCESS = `${currentReducer}/SYSTEM_NOTIFICATIONS_PUBLISH_SUCCESS`;
const SYSTEM_NOTIFICATIONS_PUBLISH_FAILURE = `${currentReducer}/SYSTEM_NOTIFICATIONS_PUBLISH_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  systemNotificationsList: [],
  severitiesList: [],
  systemNotificationTypeList: [],
  notificationIdList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        systemNotificationsList: action.result
      };
    }
    case GET_SYSTEM_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        systemNotificationsList: []
      };
    }
    case GET_SYSTEM_NOTIFICATIONS_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SYSTEM_NOTIFICATIONS_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        notificationIdList: action.result
      };
    }
    case GET_SYSTEM_NOTIFICATIONS_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        systemNotificationsList: []
      };
    }
    case ADD_SYSTEM_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_SYSTEM_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_SYSTEM_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_SYSTEM_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_SYSTEM_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_SYSTEM_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SERVERITIES_SYSTEM_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SERVERITIES_SYSTEM_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        severitiesList: action.result
      };
    }
    case SERVERITIES_SYSTEM_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SYSTEM_NOTIFICATIONS_PUBLISH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SYSTEM_NOTIFICATIONS_PUBLISH_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        severitiesList: action.result
      };
    }
    case SYSTEM_NOTIFICATIONS_PUBLISH_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SYSTEM_NOTIFICATIONS_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SYSTEM_NOTIFICATIONS_TYPE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        systemNotificationTypeList: action.result
      };
    }
    case SYSTEM_NOTIFICATIONS_TYPE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getSystemNotificationList = () => {
  return {
    types: [GET_SYSTEM_NOTIFICATIONS_REQUESTED, GET_SYSTEM_NOTIFICATIONS_SUCCESS, GET_SYSTEM_NOTIFICATIONS_FAILURE],
    promise: client => client.get('systemNotificationList')
  };
};

export const getSystemNotificationById = (id) => {
  return {
    types: [GET_SYSTEM_NOTIFICATIONS_BY_ID_REQUESTED, GET_SYSTEM_NOTIFICATIONS_BY_ID_SUCCESS, GET_SYSTEM_NOTIFICATIONS_BY_ID_FAILURE],
    promise: client => client.get(`systemNotificationListById/${id}`)
  };
};

export const getSeverities = () => {
  return {
    types: [SERVERITIES_SYSTEM_NOTIFICATIONS_REQUESTED, SERVERITIES_SYSTEM_NOTIFICATIONS_SUCCESS, SERVERITIES_SYSTEM_NOTIFICATIONS_FAILURE],
    promise: client => client.get('severities')
  };
};

export const getSystemNotificationTypes = () => {
  return {
    types: [SYSTEM_NOTIFICATIONS_TYPE_REQUESTED, SYSTEM_NOTIFICATIONS_TYPE_SUCCESS, SYSTEM_NOTIFICATIONS_TYPE_FAILURE],
    promise: client => client.get('systemNotificationTypes')
  };
};

export const addSystemNotification = (data) => {
  return {
    types: [ADD_SYSTEM_NOTIFICATIONS_REQUESTED, ADD_SYSTEM_NOTIFICATIONS_SUCCESS, ADD_SYSTEM_NOTIFICATIONS_FAILURE],
    promise: client => client.post('addSystemNotification', { data })
  };
};

export const publishSystemNotification = (id) => {
  return {
    types: [SYSTEM_NOTIFICATIONS_PUBLISH_REQUESTED, SYSTEM_NOTIFICATIONS_PUBLISH_SUCCESS, SYSTEM_NOTIFICATIONS_PUBLISH_FAILURE],
    // promise: client => client.post('publishSystemNotification', { data })
    promise: client => client.post(`publishSystemNotification/${id}`)
  };
};

export const updateSystemNotification = (data) => {
  return {
    types: [UPDATE_SYSTEM_NOTIFICATIONS_REQUESTED, UPDATE_SYSTEM_NOTIFICATIONS_SUCCESS, UPDATE_SYSTEM_NOTIFICATIONS_FAILURE],
    promise: client => client.post('updateSystemNotification', { data })
  };
};

export const deleteSystemNotification = (id) => {
  return {
    types: [DELETE_SYSTEM_NOTIFICATIONS_REQUESTED, DELETE_SYSTEM_NOTIFICATIONS_SUCCESS, DELETE_SYSTEM_NOTIFICATIONS_FAILURE],
    promise: client => client.post(`deleteSystemNotification/${id}`)
  };
};
