export const GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE';

export const TEST_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED = 'store/TEST_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED';
export const TEST_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS = 'store/TEST_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS';
export const TEST_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE = 'store/TEST_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  ingenicoList: null
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        ingenicoList: null
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ingenicoList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        ingenicoList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case TEST_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case TEST_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case TEST_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfigIngenico = (storeId, venderId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigIngenico/${storeId}/vendorId/${venderId}`)
  };
};

export const updateStorePaymentSystemConfigIngenico = (storeId, venderId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_INGENICO_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfigIngenico/${storeId}/vendorId/${venderId}`, { data })
  };
};

export const testStorePaymentSystemConfig = (storeId, venderId) => {
  return {
    types: [TEST_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED, TEST_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS, TEST_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE],
    promise: client => client.post(`testStorePaymentSystemConfig/${storeId}/vendorId/${venderId}`)
  };
};
