import { userInfo } from '@utility';
import { formatUrl } from '@helpers/ApiClient';
import { getUserId } from '@utility/storageOperations';

const download = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const fileExportFromStream = ({
  method = 'GET', path, params = {}, body, fileName,
}) => {
  const formattedUrl = formatUrl(path);
  const constructedUrl = new URL(formattedUrl);
  Object.keys(params).forEach(key => constructedUrl.searchParams.append(key, params[key]));

  const fetchParams = {
    method,
    'Content-Type': 'application/json',
    headers: new Headers({
      Authorization: `Bearer ${userInfo.getToken()}`,
      memberId: getUserId(),
    })
  };

  /* eslint-disable */
  fetchParams.body = JSON.stringify(body);
  return new Promise((resolve, reject) => {
    fetch(
      constructedUrl, { ...fetchParams }
    ).then((res) => {
      if (res.ok || res.status === 200) {
        res.blob().then(blob => download(blob, fileName));
        resolve('Success');
      } else reject('Failed to download - Please check input and try again');
    });
  })
};

export default {
  fileExportFromStream
};
