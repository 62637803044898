const currentReducer = 'channelSetup';

export const GET_CHANNEL_SETUP_REQUESTED = `${currentReducer}/GET_CHANNEL_SETUP_REQUESTED`;
export const GET_CHANNEL_SETUP_SUCCESS = `${currentReducer}/GET_CHANNEL_SETUP_SUCCESS`;
export const GET_CHANNEL_SETUP_FAILURE = `${currentReducer}/GET_CHANNEL_SETUP_FAILURE`;

export const GENERATE_CHANNEL_SETUP_REQUESTED = `${currentReducer}/GENERATE_CHANNEL_SETUP_REQUESTED`;
export const GENERATE_CHANNEL_SETUP_SUCCESS = `${currentReducer}/GENERATE_CHANNEL_SETUP_SUCCESS`;
export const GENERATE_CHANNEL_SETUP_FAILURE = `${currentReducer}/GENERATE_CHANNEL_SETUP_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  channelSetupDataList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHANNEL_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CHANNEL_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelSetupDataList: action.result
      };
    }
    case GET_CHANNEL_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GENERATE_CHANNEL_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GENERATE_CHANNEL_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelSetupDataList: action.result,
      };
    }
    case GENERATE_CHANNEL_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getChannelSetup = (brandId) => {
  return {
    types: [GET_CHANNEL_SETUP_REQUESTED, GET_CHANNEL_SETUP_SUCCESS, GET_CHANNEL_SETUP_FAILURE],
    promise: client => client.get(`getChannelSetup/${brandId}`)
  };
};

export const generateChannelSetup = (brandId, data) => {
  return {
    types: [GENERATE_CHANNEL_SETUP_REQUESTED, GENERATE_CHANNEL_SETUP_SUCCESS, GENERATE_CHANNEL_SETUP_FAILURE],
    promise: client => client.post(`generateChannelSetup/${brandId}`, { data })
  };
};
