const currentReducer = 'categoryMenu';
export const GET_CATEGORY_LIST_REQUESTED = `${currentReducer}/GET_CATEGORY_LIST_REQUESTED`;
export const GET_CATEGORY_LIST_SUCCESS = `${currentReducer}/GET_CATEGORY_LIST_SUCCESS`;
export const GET_CATEGORY_LIST_FAILURE = `${currentReducer}/GET_CATEGORY_LIST_FAILURE`;

export const GET_PREP_TIME_UNIT_REQUESTED = `${currentReducer}/GET_PREP_TIME_UNIT_REQUESTED`;
export const GET_PREP_TIME_UNIT_SUCCESS = `${currentReducer}/GET_PREP_TIME_UNIT_SUCCESS`;
export const GET_PREP_TIME_UNIT_FAILURE = `${currentReducer}/GET_PREP_TIME_UNIT_FAILURE`;

export const ADD_CATEGORY_LIST_REQUESTED = `${currentReducer}/ADD_CATEGORY_LIST_REQUESTED`;
export const ADD_CATEGORY_LIST_SUCCESS = `${currentReducer}/ADD_CATEGORY_LIST_SUCCESS`;
export const ADD_CATEGORY_LIST_FAILURE = `${currentReducer}/ADD_CATEGORY_LIST_FAILURE`;

export const UPDATE_CATEGORY_REQUESTED = `${currentReducer}/UPDATE_CATEGORY_REQUESTED`;
export const UPDATE_CATEGORY_SUCCESS = `${currentReducer}/UPDATE_CATEGORY_SUCCESS`;
export const UPDATE_CATEGORY_FAILURE = `${currentReducer}/UPDATE_CATEGORY_FAILURE`;


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  categoryList: [],
  prepTimeValueList: []
//   channelList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        categoryList: action.result
      };
    }
    case GET_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_PREP_TIME_UNIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_PREP_TIME_UNIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        prepTimeValueList: action.result
      };
    }
    case GET_PREP_TIME_UNIT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_CATEGORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelList: action.result
      };
    }
    case UPDATE_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getCategoryList = (brandId) => {
  return {
    types: [GET_CATEGORY_LIST_REQUESTED, GET_CATEGORY_LIST_SUCCESS, GET_CATEGORY_LIST_FAILURE],
    promise: client => client.get(`getCategoryList/${brandId}`)
  };
};

export const addCategoryList = (data) => {
  return {
    types: [ADD_CATEGORY_LIST_REQUESTED, ADD_CATEGORY_LIST_SUCCESS, ADD_CATEGORY_LIST_FAILURE],
    promise: client => client.post('addCategoryList', { data })
  };
};

export const getPrepTimeValues = () => {
  return {
    types: [GET_PREP_TIME_UNIT_REQUESTED, GET_PREP_TIME_UNIT_SUCCESS, GET_PREP_TIME_UNIT_FAILURE],
    promise: client => client.get('prepTimeValue')
  };
};

export const updateCategory = (data) => {
  return {
    types: [UPDATE_CATEGORY_REQUESTED, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILURE],
    promise: client => client.post('updateCategory', { data })
  };
};
