export const GET_JOBS_BY_STORE_ID_REQUESTED = 'jobs/GET_JOBS_BY_STORE_ID_REQUESTED';
export const GET_JOBS_BY_STORE_ID_SUCCESS = 'jobs/GET_JOBS_BY_STORE_ID_SUCCESS';
export const GET_JOBS_BY_STORE_ID_FAILURE = 'jobs/GET_JOBS_BY_STORE_ID_FAILURE';

export const GET_JOBS_BY_ID_REQUESTED = 'jobs/GET_JOBS_BY_ID_REQUESTED';
export const GET_JOBS_BY_ID_SUCCESS = 'jobs/GET_JOBS_BY_ID_SUCCESS';
export const GET_JOBS_BY_ID_FAILURE = 'jobs/GET_JOBS_BY_ID_FAILURE';

export const UPDATE_JOB_REQUESTED = 'jobs/UPDATE_JOB_REQUESTED';
export const UPDATE_JOB_SUCCESS = 'jobs/UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILURE = 'jobs/UPDATE_JOB_FAILURE';

export const ADD_JOB_REQUESTED = 'jobs/ADD_JOB_REQUESTED';
export const ADD_JOB_SUCCESS = 'jobs/ADD_JOB_SUCCESS';
export const ADD_JOB_FAILURE = 'jobs/ADD_JOB_FAILURE';

export const GET_MENU_JOBS_REQUESTED = 'jobs/GET_MENU_JOBS_REQUESTED';
export const GET_MENU_JOBS_SUCCESS = 'jobs/GET_MENU_JOBS_SUCCESS';
export const GET_MENU_JOBS_FAILURE = 'jobs/GET_MENU_JOBS_FAILURE';

export const ENABLE_MENU_JOB_REQUESTED = 'jobs/ENABLE_MENU_JOB_REQUESTED';
export const ENABLE_MENU_JOB_SUCCESS = 'jobs/ENABLE_MENU_JOB_SUCCESS';
export const ENABLE_MENU_JOB_FAILURE = 'jobs/ENABLE_MENU_JOB_FAILURE';

export const GET_JOB_TYPES_REQUESTED = 'jobs/GET_JOB_TYPES_REQUESTED';
export const GET_JOB_TYPES_SUCCESS = 'jobs/GET_JOB_TYPES_SUCCESS';
export const GET_JOB_TYPES_FAILURE = 'jobs/GET_JOB_TYPES_FAILURE';

export const GET_JOB_REQUESTED = 'jobs/GET_JOB_REQUESTED';
export const GET_JOB_SUCCESS = 'jobs/GET_JOB_SUCCESS';
export const GET_JOB_FAILURE = 'jobs/GET_JOB_FAILURE';

export const CHECK_VISIBILITY_REQUESTED = 'jobs/CHECK_VISIBILITY_REQUESTED';
export const CHECK_VISIBILITY_SUCCESS = 'jobs/CHECK_VISIBILITY_SUCCESS';
export const CHECK_VISIBILITY_FAILURE = 'jobs/CHECK_VISIBILITY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  jobsByStoreId: [],
  jobById: [],
  menuJobs: [],
  jobTypes: [],
  jobs: [],
  checkVisibilityList: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_JOBS_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        jobsByStoreId: action.result,
      };
    }
    case GET_JOBS_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_JOBS_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_JOBS_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        jobById: action.result,
      };
    }
    case GET_JOBS_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_JOB_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_JOB_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_JOB_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_JOB_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_JOB_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_JOB_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MENU_JOBS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENU_JOBS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuJobs: action.result,
      };
    }
    case GET_MENU_JOBS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ENABLE_MENU_JOB_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ENABLE_MENU_JOB_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ENABLE_MENU_JOB_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_JOB_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_JOB_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        jobTypes: action.result,
      };
    }
    case GET_JOB_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_JOB_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_JOB_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        jobs: action.result,
      };
    }
    case GET_JOB_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHECK_VISIBILITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_VISIBILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkVisibilityList: action.result,
      };
    }
    case CHECK_VISIBILITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getJobsByStoreId = (storeId) => {
  return {
    types: [GET_JOBS_BY_STORE_ID_REQUESTED, GET_JOBS_BY_STORE_ID_SUCCESS, GET_JOBS_BY_STORE_ID_FAILURE],
    promise: client => client.get(`getJobsByStoreId/${storeId}`)
  };
};

export const getJobById = (id) => {
  return {
    types: [GET_JOBS_BY_ID_REQUESTED, GET_JOBS_BY_ID_SUCCESS, GET_JOBS_BY_ID_FAILURE],
    promise: client => client.get(`getJobById/${id}`)
  };
};

export const addJobs = (data) => {
  return {
    types: [ADD_JOB_REQUESTED, ADD_JOB_SUCCESS, ADD_JOB_FAILURE],
    promise: client => client.post('addJobs', { data })
  };
};

export const updateJob = (data) => {
  return {
    types: [UPDATE_JOB_REQUESTED, UPDATE_JOB_SUCCESS, UPDATE_JOB_FAILURE],
    promise: client => client.post('updateJob', { data })
  };
};

export const getMenuJobs = (id) => {
  return {
    types: [GET_MENU_JOBS_REQUESTED, GET_MENU_JOBS_SUCCESS, GET_MENU_JOBS_FAILURE],
    promise: client => client.get(`getMenuJobs/${id}`)
  };
};

export const enableMenuJob = (menuId, jobId) => {
  return {
    types: [UPDATE_JOB_REQUESTED, UPDATE_JOB_SUCCESS, UPDATE_JOB_FAILURE],
    promise: client => client.post(`enableMenuJob/${menuId}/jobTypeId/${jobId}/enabled/true`)
  };
};

export const disableMenuJob = (menuId, jobId) => {
  return {
    types: [UPDATE_JOB_REQUESTED, UPDATE_JOB_SUCCESS, UPDATE_JOB_FAILURE],
    promise: client => client.post(`enableMenuJob/${menuId}/jobTypeId/${jobId}/enabled/false`)
  };
};

export const getJobTypes = () => {
  return {
    types: [GET_JOB_TYPES_REQUESTED, GET_JOB_TYPES_SUCCESS, GET_JOB_TYPES_FAILURE],
    promise: client => client.get('getJobTypes'),
  };
};

export const getJobs = (id) => {
  return {
    types: [GET_JOB_REQUESTED, GET_JOB_SUCCESS, GET_JOB_FAILURE],
    promise: client => client.get(`getJobs/${id}`)
  };
};

export const checkVisibility = () => {
  return {
    types: [CHECK_VISIBILITY_REQUESTED, CHECK_VISIBILITY_SUCCESS, CHECK_VISIBILITY_FAILURE],
    promise: client => client.get('checkVisibility'),
  };
};
