export const GET_SERVER_APP_REQUESTED = 'store/GET_SERVER_APP_REQUESTED';
export const GET_SERVER_APP_SUCCESS = 'store/GET_SERVER_APP_SUCCESS';
export const GET_SERVER_APP_FAILURE = 'store/GET_SERVER_APP_FAILURE';

export const UPDATE_SERVER_APP_REQUESTED = 'store/UPDATE_SERVER_APP_REQUESTED';
export const UPDATE_SERVER_APP_SUCCESS = 'store/UPDATE_SERVER_APP_SUCCESS';
export const UPDATE_SERVER_APP_FAILURE = 'store/UPDATE_SERVER_APP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getServer: null,
  updateServer: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SERVER_APP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getServer: null,
      };
    }
    case GET_SERVER_APP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getServer: action.result,
      };
    }
    case GET_SERVER_APP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getServer: null,
      };
    }
    case UPDATE_SERVER_APP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SERVER_APP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SERVER_APP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getServerApp = (storeId) => {
  return {
    types: [GET_SERVER_APP_REQUESTED, GET_SERVER_APP_SUCCESS, GET_SERVER_APP_FAILURE],
    promise: client => client.get(`getServerByStoreId/${storeId}`)
  };
};

export const updateServerApp = (storeId, data) => {
  return {
    types: [UPDATE_SERVER_APP_REQUESTED, UPDATE_SERVER_APP_SUCCESS, UPDATE_SERVER_APP_FAILURE],
    promise: client => client.post(`updateServerAppFeatures/${storeId}`, { data })
  };
};
