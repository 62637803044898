
export const GET_ROLE_TEMPLATES_REQUESTED = 'roleTemplates/GET_ROLE_TEMPLATES_REQUESTED';
export const GET_ROLE_TEMPLATES_SUCCESS = 'roleTemplates/GET_ROLE_TEMPLATES_SUCCESS';
export const GET_ROLE_TEMPLATES_FAILURE = 'roleTemplates/GET_ROLE_TEMPLATES_FAILURE';

export const CREATE_ROLE_TEMPLATE_REQUESTED = 'roleTemplates/CREATE_ROLE_TEMPLATE_REQUESTED';
export const CREATE_ROLE_TEMPLATE_SUCCESS = 'roleTemplates/CREATE_ROLE_TEMPLATE_SUCCESS';
export const CREATE_ROLE_TEMPLATE_FAILURE = 'roleTemplates/CREATE_ROLE_TEMPLATE_FAILURE';

export const UPDATE_ROLE_TEMPLATE_REQUESTED = 'roleTemplates/UPDATE_ROLE_TEMPLATE_REQUESTED';
export const UPDATE_ROLE_TEMPLATE_SUCCESS = 'roleTemplates/UPDATE_ROLE_TEMPLATE_SUCCESS';
export const UPDATE_ROLE_TEMPLATE_FAILURE = 'roleTemplates/UPDATE_ROLE_TEMPLATE_FAILURE';

export const DELETE_ROLE_TEMPLATE_REQUESTED = 'roleTemplates/DELETE_ROLE_TEMPLATE_REQUESTED';
export const DELETE_ROLE_TEMPLATE_SUCCESS = 'roleTemplates/DELETE_ROLE_TEMPLATE_SUCCESS';
export const DELETE_ROLE_TEMPLATE_FAILURE = 'roleTemplates/DELETE_ROLE_TEMPLATE_FAILURE';

export const GET_PERMISSIONS_REQUESTED = 'authRoleTemplates/GET_PERMISSIONS_REQUESTED';
export const GET_PERMISSIONS_SUCCESS = 'authRoleTemplates/GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'authRoleTemplates/GET_PERMISSIONS_FAILURE';

export const GET_AUTH_ROLE_TEMPLATE_REQUESTED = 'authRoleTemplates/GET_AUTH_ROLE_TEMPLATE_REQUESTED';
export const GET_AUTH_ROLE_TEMPLATE_SUCCESS = 'authRoleTemplates/GET_AUTH_ROLE_TEMPLATE_SUCCESS';
export const GET_AUTH_ROLE_TEMPLATE_FAILURE = 'authRoleTemplates/GET_AUTH_ROLE_TEMPLATE_FAILURE';

export const UPDATE_PERMISSIONS_REQUESTED = 'authRoleTemplates/UPDATE_PERMISSIONS_REQUESTED';
export const UPDATE_PERMISSIONS_SUCCESS = 'authRoleTemplates/UPDATE_PERMISSIONS_SUCCESS';
export const UPDATE_PERMISSIONS_FAILURE = 'authRoleTemplates/UPDATE_PERMISSIONS_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  roleTemplatesList: [],
  permissionsList: [],
  authRoleTemplate: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_TEMPLATES_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case GET_ROLE_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        roleTemplatesList: action.result,

      };
    case GET_ROLE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    case CREATE_ROLE_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CREATE_ROLE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CREATE_ROLE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_ROLE_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ROLE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_ROLE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_ROLE_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_ROLE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_ROLE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_PERMISSIONS_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        permissionsList: []
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        permissionsList: action.result,
      };
    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    case GET_AUTH_ROLE_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_AUTH_ROLE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        authRoleTemplate: action.result,
      };
    }
    case GET_AUTH_ROLE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PERMISSIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getRoleTemplates = () => {
  return {
    types: [GET_ROLE_TEMPLATES_REQUESTED, GET_ROLE_TEMPLATES_SUCCESS, GET_ROLE_TEMPLATES_FAILURE],
    promise: client => client.get('getRoleTemplates')
  };
};
export const createRoleTemplate = data => ({
  types: [CREATE_ROLE_TEMPLATE_REQUESTED, CREATE_ROLE_TEMPLATE_SUCCESS, CREATE_ROLE_TEMPLATE_FAILURE],
  promise: client => client.post('createRoleTemplate', { data }),
});

export const updateRoleTemplate = data => ({
  types: [UPDATE_ROLE_TEMPLATE_REQUESTED, UPDATE_ROLE_TEMPLATE_SUCCESS, UPDATE_ROLE_TEMPLATE_FAILURE],
  promise: client => client.post('updateRoleTemplate', { data }),
});


export const deleteRoleTemplate = (roleTemplateId) => {
  return {
    types: [DELETE_ROLE_TEMPLATE_REQUESTED, DELETE_ROLE_TEMPLATE_SUCCESS, DELETE_ROLE_TEMPLATE_FAILURE],
    promise: client => client.post(`deleteRoleTemplate/${roleTemplateId}`)
  };
};
export const getPermissions = () => {
  return {
    types: [GET_PERMISSIONS_REQUESTED, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE],
    promise: client => client.get('getPermissions')
  };
};
export const getAuthRoleTemplate = (authRoleTemplateId) => {
  return {
    types: [GET_AUTH_ROLE_TEMPLATE_REQUESTED, GET_AUTH_ROLE_TEMPLATE_SUCCESS, GET_AUTH_ROLE_TEMPLATE_FAILURE],
    promise: client => client.get(`getAuthRoleTemplate/${authRoleTemplateId}`)
  };
};

export const updatePermissions = (data) => {
  return {
    types: [UPDATE_PERMISSIONS_REQUESTED, UPDATE_PERMISSIONS_SUCCESS, UPDATE_PERMISSIONS_FAILURE],
    promise: client => client.post('updatePermissions', { data })
  };
};
