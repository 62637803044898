export default {
  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Please try again in sometime.',

  // Messsages
  PARTICIPATING_RESTAURANT_TITLE: 'If you are at participating restaurant, look for the onedine QR code to pay your check from your phone',
  PAGE_NOT_FOUND: 'Oops! Page not found',
  ERROR_MESSAGE: 'We are sorry, but the page you requested was not found',
  USER_INFO: 'User Info',
  CONNECT_WITH: 'OR CONNECT WITH',
  YOUR_SERVER_IS: 'Your server is',
  SERVER_CALL_MESSAGE: 'Your server has been called and should arrive shortly',
  THANK_YOU: 'Thank you',
  FORGOT_YOUR_PASSWORD: 'FORGOT YOUR PASSWORD',
  ACTION_PAGE_TITLE: 'What would you like to do?',
  CONFIRM_LEAVE_TABLE: 'Please confirm that you want to leave table',
  LEAVE_TABLE_CONFIRMATION: 'Are you sure, you want to leave table?',
  QR_CODE_MESSAGE: 'Fit the onedine QR Code completely into the box below',
  POPIN_CONFIRM_MSG: 'Are you sure, you want to cancel?',
  RECEIPT_SENT_TO: 'Receipt has been sent to your',
  YOUR_RECEIPT: 'Your Receipt',
  PLEASE_SELECT_SPLIT_CHECK: 'Please select How many people will split this check:',
  SPLITED_CHECK_LIST_TITLE: 'Your server seperated the check, please select your split/s',
  HOW_YOU_SPLIT_CHECK: 'How would you like to split the check',
  SURVEY_TITLE: 'We would appreciate if you can take 1 minute to answer a survey',
  LETS_GET_STARTED: 'Alright, let\'s get started.',
  WHAT_IS_THE_NUMBER: 'What\'s the number of this phone?',
  ENTER_TABLE_NUMBER: 'Enter Table Number',
  VERIFY_OTP_TITLE: 'We sent you a text with your verification code',
  PLEASE_ENTER_CODE: 'Please enter the code below',
  YOU_ARE_LOCATED_AT_TABLE: 'You are located at table ',
  MORE_ITEMS: 'more items',
  YOUR_REQUEST_FOR_LEAVE_TABLE: 'Your request for leave table is recorded.',
  PROFILE_HEADER: 'onedine',
  YOUR_PAYMENT_WAS_SUCCESSFUL: 'Your Payment was successful',
  THANK_YOU_MESSAGE: 'Thank you and come see us again soon',
  HOW_WOULD_YOU_LIKE_YOUR_RECEIPT: 'How would you like your receipt',
  AT_LEAST_ONE_SPECIAL_CASE: 'At least 1 special case, uppercase & number',
  TERMS_AND_CONDITIONS: 'I agree to terms and conditions',
  PASSWORD_MUST_MEET_THE_FOLLOWING: 'Your password must meet the following:',
  PASSWORD_CHARACTERS_LONG: '  be atleast 8 to 15 characters long',
  AT_LEAST_ONE_NUMBER: 'contain at least one number',
  AT_LEAST_ONE_SPECIAL_CHARACTER: 'contain at least one special character',
  AT_LEAST_UPPER_CASE_LOWER_CASE: 'contain a mix of upper and lower case letters',
  SWIPE_LEFT_REMOVE_CARD: 'Swipe left to edit / remove a card',
  WARNING_MESSAGE: 'Ugh! Something went wrong.',
  PLEASE_ENTER_EMAIL: 'Please enter the email you use to sign in to onedine.',
  SUDDENLY_REMEMBERED_YOUR_PASSWORD: 'Suddenly remembered your password? ',
  OVERALL_EXPERIENCE: 'How was your overall experience?',
  CANCEL: 'Cancel',
  TABLE_NOT_SELECTED: 'Table not selected',
  PAYMENT_SUCCESS: 'Payment Success!',
  APPLY_PAYMENT: 'Select or add a card to apply towards payment',
  CONFIRMATION_MESSAGE: 'This will overwrite existing data. Do you want to continue',
  NON_INTEGRATED_POS_CONFIRMATION_MESSAGE: 'Please verify that the Non-Integrated POS flag has been setup correctly. This cannot be changed once the brand is created. Do you want to proceed ?',
  RESET_YOUR_PASSWORD_BY_EMAIL: 'To reset your password by email, please contact your administrator',
  PAYMENT_ADJ_MESSAGE: 'Payment Adjustments have been moved!',
  REPORTS_MESSAGE: 'Reports have been moved!',
  REPORT_NOTE: 'Reports have been moved to the header section. You can now access Reports using the new report icon located in the header.',
  PAYMENT_ADJ_NOTE: 'Payment Adjustments have been moved to the header section. You can now access Payment Adjustments using the new Payment Adjustments icon located in the header.',


  // links
  FORGOT_PASSWORD: 'FORGOT YOUR PASSWORD?',
  SIGN_UP: 'SIGN UP',
  CHANGE_YOUR_PASSWORD: 'Change Your Password',
  CHANGE_BASIC_INFO: 'Change Your Basic Informations',
  VIEW_DETAILS: 'View Details',
  EDIT: 'Edit',
  GO_TO_HOME: 'Go to Home',
  VIEW_CHECK: 'View Check',
  CHANGE_PROFILE: 'Change Profile',
  CHANGE: 'Change',
  DELETE_CONFIRMATION: 'Are you sure you want to delete?',
  DELETE_SENSOR_CONFIRMATION: 'This will delete the Sensor. Do you want to continue?',
  SYNC_CONFIRMATION: 'This will sync the check. Do you want to continue?',
  CLOSE_CONFIRMATION: 'This will mark the check as closed. Do you want to continue?',
  ENABLE_ALL_SENSOR_GROUPS_CONFIRMATION: 'This will enable the menu for all sensor groups. Do you want to continue?',
  CLEAR_ALL_SENSOR_GROUPS_CONFIRMATION: 'This will clear the menu from all sensor groups. Do you want to continue?',
  CLEAR_ALL_TILE_SENSOR_GROUPS_CONFIRMATION: 'This will clear the tile from all sensor groups. Do you want to continue?',
  ENABLE_ALL_TILE_SENSOR_GROUPS_CONFIRMATION: 'This will enable the tile for all sensor groups. Do you want to continue?',
  CLEAR_ALL_DEVICE_GROUPS_CONFIRMATION: 'This will clear the menu from all device groups. Do you want to continue?',
  ENABLE_ALL_DEVICE_GROUPS_CONFIRMATION: 'This will enable the menu for all device groups. Do you want to continue?',

  // OTHER-LINKS
  TAP_SENSOR_NO_STAGE: 'Tap Sensor / No Stage',
  TAP_SENSOR_STAGE: 'Tap Sensor / Staged',
  TAP_SENSOR_NO_CHECK: 'Tap Sensor / No Check',
  KIOSK_TEXT: 'Kiosk Text',
  ONEDINE_MAIL: 'www.onedineapp.com',
  CPK_ONEDINE: 'cpk.onedineapp.com',

  // DEVICE
  DEVICE_CUSTOM_MESSAGE: 'Are you sure you want to',
  DEVICE_CUSTOM_MESSAGE_2: 'the device',
  CARD_READER_DEPROVISION_CONFIRMATION: 'This will de-provision the device and you will not be able to process payments. Do you want to continue?',

  // TIPS
  TIPS_MESSAGE: 'Present the guest with these pre-set tip percentages:',

  // SENSOR-ASSIGNMENT
  ASSIGN_SENSOR_TABLE: 'This will assign the sensor to table',

  // QR SCANNER
  WARNING_MESSAGE_QRCODE: 'Ugh! Invalid sensor code',
  AUTHKEY_FAILED: 'Invalid auth key, please try again',

  DEREGISTER_SENSOR_CONFIRMATION: 'Are you sure, you want to deregister this sensor?',

  // POS Repost
  POS_REPOST_TRANSACTION: 'This will repost the transaction to the POS. Do you want to continue?',

  // Select brand
  PLEASE_ADD_BRAND: 'Please add the brand before editing store',

  // Release user notification
  UNREAD_USER_NOTIFICATION: 'This will notify all users of a new release notes update',
  READ_USER_NOTIFICATION: 'This will clear release notes notification from all users',

  // Pos Agent
  POSAGENT_CONFIRM_MESSAGE_TYPE: 'Are you sure you want to',
  POSAGENT_CONFIRM_CACHE_NOTE: 'Note that the Agent will purge all cached entries and re-populate its cache which may take several minutes.',
  POSAGENT_CONFIRM_REACTIVATE_AGENT_NOTE: 'Note that upon reactivation the Agent will refresh all of its settings and internal cache.',
  POSAGENT_CONFIRM_RESTART_AGENT_NOTE: 'Note that the Agent will purge all unprocessed messages in the queue when restarted and will take over a minute to come back online.',

  DEACTIVATE_COMPANY_CONFIRM_MSG: 'This will deactivate the company and all brands/stores under it. This action cannot be reversed. Are you sure you want to continue?',
  DEACTIVATE_BRAND_CONFIRM_MSG: 'This will deactivate the brand and all stores under it. This action cannot be reversed. Are you sure you want to continue?',
  DEACTIVATE_STORE_CONFIRM_MSG: 'This will deactivate the store. This action cannot be reversed. Are you sure you want to continue?',

  // Brand Language
  DELETE_BRAND_LANGUGAE: 'This will delete the brand language. Do you want to continue?',
  SELECT_BRAND_LANGUAGE: 'Select Brand Language',
  DEFAULT_BRAND_LANGUAGE: 'This language will now be the default for the brand. Do you want to continue?',

  // Cubby
  CUBBY_ADD_CONFIRMATION_MSG: 'This will save the cubby information. Once saved, the number of rows and columns cannot be changed. Do you want to continue?',

  // StoreEmployee
  CONFIRM_EMPLOYEE_UNLINK: 'Are you sure, you want to unlink selected employee ?',

  // Payment Adjustment > Block User
  BLOCK_USER_IN_FRAUD_SYSTEM: 'This will block the user in the fraud monitoring system. Do you want to continue?',

  // Production Rate
  CLEAR_OVERRIDE_FOR_TODAY: 'This will clear overrides for today. Do you want to continue?',
  CONFIRMATION_QUICK_ADD_STORE_MSG: 'This will delete all existing production rates for the selected criteria and replace it with the quick add values. Do you want to continue?',
  CONFIRMATION_QUICK_DELETE_STORE_MSG: 'This will delete all existing production rates for the selected criteria. Do you want to continue?',


  // Brand Holiday
  DELETE_CONFIRMATION_NO_STORE_SELECTED: 'No stores selected - this will delete the holiday. do you want to continue?',
  AT_LEAST_ONE_STORE: 'At least one Store should be selected',

  // DATA REQUEST REPORT
  GUEST_DATA_CONFRIM: 'By confirming, I acknowledge that the data request has been emailed to the guest and details captures in Sales Force.',
  DELETE_DATA_REQUEST: 'Are you sure you want to proceed with data deletion request?',

  // KDS Layout
  DEFAULT_KDS_LAYOUT: 'This KDS Layout will now be the default for the brand. Do you want to continue?',

  // KDS Station Type
  KDS_STATION_TYPE_CONFIRMATION: 'Station type cannot be changed once the station is added. Do you want to continue?',

  // Menu Basket Tester
  BASKET_TESTER_SCHEDULE_TEST_CONFIRMATION: 'Running basket tester can take a long time (1-2 hours) and uses up POS computing resources. It is recommended to run the basket tester when the location is closed.',

  // Mobile App Pages
  MOBILE_APP_PAGE_CONFIRMATION: 'Page type cannot be changed once the page is created. Do you want to continue?',
  DELETE_MOBILE_APP_PAGE_DESIGN_TILE_CONFIRM: 'This will delete the Tile. Do you want to continue?',
  DELETE_MOBILE_APP_PAGE_DESIGN_SECTION_CONFIRM: 'This will delete the Section. Do you want to continue?',

  // KDS Print
  DEFAULT_KDS_PRINT: 'This KDS Print Definition will now be the default for the brand. Do you want to continue?',

  // Intercard
  DELETE_MENU_CONFIRMATION: 'Are you sure you want to delete this entry?',

  // New Dashboard
  WELCOME_MESSAGE: 'To start exploring, simply choose your company, brand, and store on the left-hand side. Once selected, the header menu will automatically update to show relevant options based on your choices. This means you’ll see menus tailored specifically to the company, brand, and store you’ve picked.',
};
