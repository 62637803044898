export const GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED = 'languageEnity/GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED';
export const GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS = 'languageEnity/GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS';
export const GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE = 'languageEnity/GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE';

export const UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED = 'languageEnity/UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED';
export const UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS = 'languageEnity/UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS';
export const UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE = 'languageEnity/UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  languageEntityTypeList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageEntityTypeList: [],
      };
    }
    case GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageEntityTypeList: action.result,
      };
    }
    case GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getLanguageEntityByType = (brandId, entityType) => {
  return {
    types: [GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED, GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS, GET_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE],
    promise: client => client.get(`getLanguageEntity/${brandId}/entityType/${entityType}`)
  };
};

export const saveLanguageEntityByType = (brandId, entityType, data) => {
  return {
    types: [UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_REQUESTED, UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_SUCCESS, UPDATE_LANGUAGES_ENTITY_LABELS_BY_TYPE_FAILURE],
    promise: client => client.post(`updateLanguageEntity/${brandId}/entityType/${entityType}`, { data })
  };
};
