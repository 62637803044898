export const GET_MEDALLIA_CONFIG_REQUESTED = 'brand/GET_MEDALLIA_CONFIG_REQUESTED';
export const GET_MEDALLIA_CONFIG_SUCCESS = 'brand/GET_MEDALLIA_CONFIG_SUCCESS';
export const GET_MEDALLIA_CONFIG_FAILURE = 'brand/GET_MEDALLIA_CONFIG_FAILURE';

export const UPDATE_MEDALLIA_CONFIG_REQUESTED = 'brand/UPDATE_MEDALLIA_CONFIG_REQUESTED';
export const UPDATE_MEDALLIA_CONFIG_SUCCESS = 'brand/UPDATE_MEDALLIA_CONFIG_SUCCESS';
export const UPDATE_MEDALLIA_CONFIG_FAILURE = 'brand/UPDATE_MEDALLIA_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  medalliaList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MEDALLIA_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        medalliaList: [],
      };
    }
    case GET_MEDALLIA_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        medalliaList: action.result,
      };
    }
    case GET_MEDALLIA_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        medalliaList: [],
      };
    }
    case UPDATE_MEDALLIA_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MEDALLIA_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MEDALLIA_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getMedalliaConfig = (brandId) => {
  return {
    types: [GET_MEDALLIA_CONFIG_REQUESTED, GET_MEDALLIA_CONFIG_SUCCESS, GET_MEDALLIA_CONFIG_FAILURE],
    promise: client => client.get(`getMedalliaConfig/${brandId}`)
  };
};

export const updateMedalliaConfig = (brandId, data) => {
  return {
    types: [UPDATE_MEDALLIA_CONFIG_REQUESTED, UPDATE_MEDALLIA_CONFIG_SUCCESS, UPDATE_MEDALLIA_CONFIG_FAILURE],
    promise: client => client.post(`updateMedalliaConfig/${brandId}`, { data })
  };
};
