import loginScreen from '@assets/serverappscreen/KdsLoginScreen.png';
import landingScreen from '@assets/serverappscreen/KdsLandingPage.png';

export default {
  LoginPage: {
    screenLabel: 'Login Screen',
    image: [loginScreen],
    customLabels: [
      {
        defaultText: 'LOGIN',
        key: 'label.LOGIN',
        type: 'label',
        value: 'LOGIN',
      },
      {
        defaultText: 'ACTIVE INGENICO DEVICE',
        key: 'label.ACTIVE_INGENICO_DEVICE',
        type: 'label',
        value: 'ACTIVE_INGENICO_DEVICE',
      },
      {
        defaultText: 'CONTACT',
        key: 'label.CONTACT',
        type: 'label',
        value: 'CONTACT',
      },
      {
        defaultText: 'FOR SUPPORT',
        key: 'label.FOR_SUPPORT',
        type: 'label',
        value: 'FOR_SUPPORT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CANCEL',
        type: 'label',
        value: 'CANCEL',
      },
      {
        defaultText: 'Succesfully Logged In',
        key: 'customMessage.LOGIN_SUCCESS',
        type: 'customMessage',
        value: 'LOGIN_SUCCESS',
      },
      {
        defaultText: 'Device Activated Successfully',
        key: 'customMessage.ACT_SUCCESS',
        type: 'customMessage',
        value: 'ACT_SUCCESS',
      },
      {
        defaultText: 'No Notification Found',
        key: 'customMessage.NO_NOTIFICATION',
        type: 'customMessage',
        value: 'NO_NOTIFICATION',
      },
      {
        defaultText: 'Do you wish to re-activate with different activation code?',
        key: 'customMessage.REACTIVATE_WITH_NEW_CODE',
        type: 'customMessage',
        value: 'REACTIVATE_WITH_NEW_CODE',
      },
      {
        defaultText: 'Enter your verification code:',
        key: 'customMessage.ENTER_VERIFICATION_CODE',
        type: 'customMessage',
        value: 'ENTER_VERIFICATION_CODE',
      },
      {
        defaultText: 'TABLET REQUIRES A REBOOT. PLEASE HOLD THE POWER BUTTON AND CLICK ON RESTART',
        key: 'customMessage.TABLET_REQUIRES_A_REBOOT',
        type: 'customMessage',
        value: 'TABLET_REQUIRES_A_REBOOT',
      },
      {
        defaultText: 'Enter employee code',
        key: 'customMessage.ENTER_EMPLOYEE_CODE',
        type: 'customMessage',
        value: 'ENTER_EMPLOYEE_CODE',
      },
    ]
  },
  LandingPage: {
    screenLabel: 'Landing Screen',
    image: [landingScreen],
    customLabels: [
      {
        defaultText: 'Bump',
        key: 'label.KDS_BUMP',
        type: 'label',
        value: 'KDS_BUMP',
      },
      {
        defaultText: 'All Day',
        key: 'label.KDS_ALL_DAY',
        type: 'label',
        value: 'KDS_ALL_DAY',
      },
      {
        defaultText: 'Log Out',
        key: 'label.KDS_LOG_OUT',
        type: 'label',
        value: 'KDS_LOG_OUT',
      },
      {
        defaultText: 'Bump Selected',
        key: 'label.KDS_BUMP_SELECTED',
        type: 'label',
        value: 'KDS_BUMP_SELECTED',
      },
      {
        defaultText: 'Layout',
        key: 'label.KDS_LAYOUT',
        type: 'label',
        value: 'KDS_LAYOUT',
      },
      {
        defaultText: 'Duplicate',
        key: 'label.KDS_DUPLICATE',
        type: 'label',
        value: 'KDS_DUPLICATE',
      },
      {
        defaultText: 'Submit',
        key: 'label.KDS_SUBMIT',
        type: 'label',
        value: 'KDS_SUBMIT',
      },
      {
        defaultText: 'Prioritize',
        key: 'label.KDS_PRIORITIZE',
        type: 'label',
        value: 'KDS_PRIORITIZE',
      },
      {
        defaultText: 'Select',
        key: 'label.KDS_SELECT',
        type: 'label',
        value: 'KDS_SELECT',
      },
      {
        defaultText: 'Drop',
        key: 'label.KDS_DROP',
        type: 'label',
        value: 'KDS_DROP',
      },
    ]
  },
};
