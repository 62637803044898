export const GET_STORE_TAXES_REQUESTED = 'storeSetup/GET_STORE_TAXES_REQUESTED';
export const GET_STORE_TAXES_SUCCESS = 'storeSetup/GET_STORE_TAXES_SUCCESS';
export const GET_STORE_TAXES_FAILURE = 'storeSetup/GET_STORE_TAXES_FAILURE';

export const UPDATE_STORE_TAXES_REQUESTED = 'storeSetup/UPDATE_STORE_TAXES_REQUESTED';
export const UPDATE_STORE_TAXES_SUCCESS = 'storeSetup/UPDATE_STORE_TAXES_SUCCESS';
export const UPDATE_STORE_TAXES_FAILURE = 'storeSetup/UPDATE_STORE_TAXES_FAILURE';

export const ADD_BRAND_TAXES_REQUESTED = 'storeSetup/ADD_BRAND_TAXES_REQUESTED';
export const ADD_BRAND_TAXES_SUCCESS = 'storeSetup/ADD_BRAND_TAXES_SUCCESS';
export const ADD_BRAND_TAXES_FAILURE = 'storeSetup/ADD_BRAND_TAXES_FAILURE';

export const GET_TAX_BY_ID_REQUESTED = 'storeSetup/GET_TAX_BY_ID_REQUESTED';
export const GET_TAX_BY_ID_SUCCESS = 'storeSetup/GET_TAX_BY_ID_SUCCESS';
export const GET_TAX_BY_ID_FAILURE = 'storeSetup/GET_TAX_BY_ID_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  storeTaxesList: [],
  getBrandTaxData: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_TAXES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_TAXES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeTaxesList: action.result,
      };
    }
    case GET_STORE_TAXES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        storeTaxesList: [],
      };
    }
    case UPDATE_STORE_TAXES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_TAXES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_TAXES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_TAXES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_TAXES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_TAXES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TAX_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TAX_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandTaxData: action.result,
      };
    }
    case GET_TAX_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStoreTaxSetup = (brandId) => {
  return {
    types: [GET_STORE_TAXES_REQUESTED, GET_STORE_TAXES_SUCCESS, GET_STORE_TAXES_FAILURE],
    promise: client => client.get(`getStoreTaxes/${brandId}`)
  };
};

export const updateBrandTaxSetup = (data) => {
  return {
    types: [UPDATE_STORE_TAXES_REQUESTED, UPDATE_STORE_TAXES_SUCCESS, UPDATE_STORE_TAXES_FAILURE],
    promise: client => client.post('updateBrandTax', { data })
  };
};

export const addBrandTaxSetup = (data) => {
  return {
    types: [ADD_BRAND_TAXES_REQUESTED, ADD_BRAND_TAXES_SUCCESS, ADD_BRAND_TAXES_FAILURE],
    promise: client => client.post('addBrandTax', { data })
  };
};

export const getBrandTaxById = (brandTaxId) => {
  return {
    types: [GET_TAX_BY_ID_REQUESTED, GET_TAX_BY_ID_SUCCESS, GET_TAX_BY_ID_FAILURE],
    promise: client => client.get(`getBrandTaxById/${brandTaxId}`)
  };
};
