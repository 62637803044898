
export const POST_TEST_PAYMENT_REQUESTED = 'wallet/POST_TEST_PAYMENT_REQUESTED';
export const POST_TEST_PAYMENT_SUCCESS = 'wallet/POST_TEST_PAYMENT_SUCCESS';
export const POST_TEST_PAYMENT_FAILURE = 'wallet/POST_TEST_PAYMENT_FAILURE';

export const GET_MANIFEST_DETAILS_REQUESTED = 'wallet/GET_MANIFEST_DETAILS_REQUESTED';
export const GET_MANIFEST_DETAILS_SUCCESS = 'wallet/GET_MANIFEST_DETAILS_SUCCESS';
export const GET_MANIFEST_DETAILS_FAILURE = 'wallet/GET_MANIFEST_DETAILS_FAILURE';

export const GET_CARD_FROM_SESSION = 'wallet/GET_CARD_FROM_SESSION';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  manifestUrl: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case POST_TEST_PAYMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case POST_TEST_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case POST_TEST_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_MANIFEST_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        manifestUrl: action.result
      };
    case GET_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

export const getMenifest = (storeId) => {
  return {
    types: [GET_MANIFEST_DETAILS_REQUESTED, GET_MANIFEST_DETAILS_SUCCESS, GET_MANIFEST_DETAILS_FAILURE],
    promise: client => client.get(`cardManifestByStore/${storeId}`)
  };
};

export const testPaymentSystem = (data, storeId) => {
  return {
    types: [POST_TEST_PAYMENT_REQUESTED, POST_TEST_PAYMENT_SUCCESS, POST_TEST_PAYMENT_FAILURE],
    promise: client => client.post(`testPaymentSystem/${storeId}`, {
      data
    })
  };
};
