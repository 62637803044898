export const GET_BRAND_PREP_TIME_REQUESTED = 'prepTime/GET_BRAND_PREP_TIME_REQUESTED';
export const GET_BRAND_PREP_TIME_SUCCESS = 'prepTime/GET_BRAND_PREP_TIME_SUCCESS';
export const GET_BRAND_PREP_TIME_FAILURE = 'prepTime/GET_BRAND_PREP_TIME_FAILURE';

export const ADD_BRAND_PREP_TIME_REQUESTED = 'prepTime/ADD_BRAND_PREP_TIME_REQUESTED';
export const ADD_BRAND_PREP_TIME_SUCCESS = 'prepTime/ADD_BRAND_PREP_TIME_SUCCESS';
export const ADD_BRAND_PREP_TIME_FAILURE = 'prepTime/ADD_BRAND_PREP_TIME_FAILURE';

export const UPDATE_BRAND_PREP_TIME_REQUESTED = 'prepTime/UPDATE_BRAND_PREP_TIME_REQUESTED';
export const UPDATE_BRAND_PREP_TIME_SUCCESS = 'prepTime/UPDATE_BRAND_PREP_TIME_SUCCESS';
export const UPDATE_BRAND_PREP_TIME_FAILURE = 'prepTime/UPDATE_BRAND_PREP_TIME_FAILURE';

export const DELETE_BRAND_PREP_TIME_REQUESTED = 'prepTime/DELETE_BRAND_PREP_TIME_REQUESTED';
export const DELETE_BRAND_PREP_TIME_SUCCESS = 'prepTime/DELETE_BRAND_PREP_TIME_SUCCESS';
export const DELETE_BRAND_PREP_TIME_FAILURE = 'prepTime/DELETE_BRAND_PREP_TIME_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  prepTimeList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_PREP_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_PREP_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        prepTimeList: action.result,
      };
    }
    case GET_BRAND_PREP_TIME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_PREP_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_PREP_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_PREP_TIME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_PREP_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_PREP_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_PREP_TIME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_PREP_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_PREP_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_PREP_TIME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPrepTime = (brandId) => {
  return {
    types: [GET_BRAND_PREP_TIME_REQUESTED, GET_BRAND_PREP_TIME_SUCCESS, GET_BRAND_PREP_TIME_FAILURE],
    promise: client => client.get(`getPrepTime/${brandId}`)
  };
};

export const addPrepTime = (data) => {
  return {
    types: [ADD_BRAND_PREP_TIME_REQUESTED, ADD_BRAND_PREP_TIME_SUCCESS, ADD_BRAND_PREP_TIME_FAILURE],
    promise: client => client.post('addPrepTime', { data })
  };
};

export const updatePrepTime = (data) => {
  return {
    types: [UPDATE_BRAND_PREP_TIME_REQUESTED, UPDATE_BRAND_PREP_TIME_SUCCESS, UPDATE_BRAND_PREP_TIME_FAILURE],
    promise: client => client.post('updatePrepTime', { data })
  };
};

export const deletePrepTime = (id, data) => {
  return {
    types: [DELETE_BRAND_PREP_TIME_REQUESTED, DELETE_BRAND_PREP_TIME_SUCCESS, DELETE_BRAND_PREP_TIME_FAILURE],
    promise: client => client.post(`deletePrepTime/${id}`, { data })
  };
};
