export default {
  LOGIN_SUCCESS: 'Succesfully Logged In',
  PASSWORD_MISMATCH: "Password and Confirm Password doesn't match!",
  OTP_SUCCESS: 'Otp Sent successfully to your registered Mobile Number',
  COPY_TO_CLIPBOARD_SUCCESS: 'Successfully copied to the clipboard',

  // Notification_message
  SERVER_CALL: 'Server have been called',
  SERVER_SUCCESS_REQUEST: 'Server has received the request',
  MAIL_SENT_SUCCESS: 'Mail has been successfully sent',
  BASIC_UPDATE: 'Basic details successfully updated',
  NEW_PASSWORD_UPDATE: 'New Password successfully updated',
  MESSAGE_SENT: 'Message has been successfully sent',
  LOGIN_PASSWORD: 'please login with your new password',
  OTP_SENT_TO_MOBILE: 'OTP has been sent to your mobile number',
  OTP_SENT_TO_EMAIL: 'OTP has been sent to your email',
  SURVEY_SAVE: 'Thank you, survey has saved successfully',
  TABLE_UPDATE: 'Table Inforamtion updated successfully',
  LEAVE_TABLE: 'Leave Table request accepted successfully',
  NEW_CARD_ADDED: 'New card has been added successfully',
  SUCCESSFULLY_UPDATED_OMS_OFFER: 'successfully Updated Brand Offer',
  PLEASE_LOGIN: 'Please login',

  // Company
  COMPANY_ADD_SUCCESS: 'Company added successfully',
  COMPANY_UPDATE_SUCCESS: 'Company updated successfully',
  MALL_ADD_SUCCESS: 'Venue added successfully',
  MALL_UPDATE_SUCCESS: 'Venue updated successfully',
  CHARITY_UPDATE_SUCCESS: 'Charity updated successfully',
  CHARITY_ADD_SUCCESS: 'Charity added successfully',

  // Booking System
  BOOKING_SYSTEM_UPDATE_SUCCESS: 'Booking System updated successfully',

  // Brand
  BRAND_ADD_SUCCESS: 'Brand added successfully',
  BRAND_UPDATE_SUCCESS: 'Brand updated successfully',
  OFFER_SYSTEM_UPDATE_SUCCESS: 'Offer System updated successfully',
  TIPS_UPDATE_SUCCESS: 'Tips updated successfully',
  OTHER_SETTINGS_UPDATE_SUCCESS: 'Other Settings updated successfully',

  // Charity
  // CHARITY_ADD_SUCCESS: 'Charity Added Successfully',
  // CHARITY_UPDATE_SUCCESS: 'Charity Updated Successfully',

  // Device
  DEVICE_ADD_SUCCESS: 'Device Added Successfully',
  DEVICE_UPDATE_SUCCESS: 'Device Data Updated Successfully',
  DEVICE_REREGISTER_SUCCESS: 'Device Re-Registered Successfully',
  DEVICE_DEREGISTER_SUCCESS: 'Device De-Registered Successfully',
  SEND_MESSAGE_SUCCESS: 'Message Sent Successfully',
  REGISTER_BULK_OTG_SUCCESS: 'Devices registered Successfully',

  // DATA REQUEST
  // POSMenu
  SCHEDULE_ADD_SUCCESS: 'Schedule added successfully',
  SCHEDULE_UPDATE_SUCCESS: 'Schedule updated successfully',
  SCHEDULE_MENU_DELETE_SUCCESS: 'Menu Schedule deleted successfully',
  POS_MENU_ADD_SUCCESS: 'POS System updated successfully',
  ADD_MENU_STORE_SUCCESS: 'Menu store added successfully',
  DELETE_MENU_STORE_SUCCESS: 'Menu store deleted successfully',
  ADD_SCREEN_STORE_SUCCESS: 'Screen location added successfully',
  DELETE_SCREEN_STORE_SUCCESS: 'Screen location deleted successfully',
  POSMENU_DISPLAY_NAME_UPDATED_SUCCESSFULLY: 'Menu name updated successfully',
  SUCCESSFULLY_DELETED_CAMPAIGN_TRIGGER: 'Campaign Trigger deleted Successfully',

  // Profile
  BASIC_DETAILS_SUCCESS: 'Basic details successfully updated',
  PROFILE_UPDATE_SUCCESS: 'New Password successfully updated',

  // GUEST APP
  GUEST_APP_UPDATE_SUCCESS: 'Guest App updated successfully',

  // TILE
  TILE_ADD_SUCCESS: 'Tile Added Successfully',
  TILE_UPDATE_SUCCESS: 'Tile Updated Successfully',
  TILE_DELETE_SUCCESS: 'Tile Deleted Successfully',
  TILES_SORTED_SUCCESS: 'Tiles Sorted Successfully',

  // MAPPING
  MAPPING_UPDATE_SUCCESS: 'Mapping updated successfully',

  // PAYMENT SYSTEM
  PAYMENT_SYSTEM_UPDATE_SUCCESS: 'Payment System updated successfully',

  // SERVER APP
  SERVER_APP_UPDATE_SUCCESS: 'Server App updated successfully',

  // SURVEY
  QUESTION_ADD_SUCCESS: 'Question added successfully',
  QUESTION_UPDATE_SUCCESS: 'Question updated successfully',
  RESPONSE_DELETE_SUCCESS: 'Response deleted successfully',
  RESPONSE_ADD_SUCCESS: 'Response added successfully',
  RESPONSE_UPDATE_SUCCESS: 'Response updated successfully',
  SURVEY_ADD_SUCCESS: 'Survey added successfully',
  SURVEY_UPDATE_SUCCESS: 'Survey updated successfully',
  QUESTION_DELETE_SUCCESS: 'Question deleted successfully',
  WELCOME_IMAGE_SUCCESS: 'Welcome Image uploaded successfully',
  CONFIRMATION_IMAGE_SUCCESS: 'Confirmation Image uploaded successfully',
  DELETE_WELCOME_IMAGE_SUCCESS: 'Welcome Image deleted successfully',
  DELETE_CONFIRMATION_IMAGE_SUCCESS: 'Confirmation Image deleted successfully',
  QUICK_RESPONSES_ADD_SUCCESS: 'Quick Responses added successfully',

  // TAX
  TAX_ADD_SUCCESS: 'Tax Rate added successfully',
  TAX_UPDATE_SUCCESS: 'Tax Rate updated successfully',

  // DELIVERY
  DELIVERY_ADDED_SUCCESS: 'Delivery setup added successfully',
  DELIVERY_UPDATE_SUCCESS: 'Delivery setup updated successfully',
  DELIVERY_DELETE_SUCCESS: 'Delivery setup item deleted successfully',
  DELIVERY_RULE_ADDED_SUCCESS: 'Delivery tier added successfully',
  DELIVERY_RULE_UPDATE_SUCCESS: 'Delivery tier updated successfully',
  DELIVERY_RULE_DELETE_SUCCESS: 'Delivery tier item deleted successfully',
  DELIVERY_SLOT_ADDED_SUCCESS: 'Delivery slot added successfully',
  DELIVERY_SLOT_UPDATE_SUCCESS: 'Delivery slot updated successfully',
  DELIVERY_SLOT_DELETE_SUCCESS: 'Delivery slot item deleted successfully',

  // TIPS
  TIP_DELETE_SUCCESS: 'Tip rule deleted successfully',
  TIP_ADDED_SUCCESS: 'Tip rule added successfully',
  TIP_UPDATE_SUCCESS: 'Tip rule updated successfully',
  TIP_RULE_UPDATE_SUCCESS: 'Tip Rule updated successfully',
  TIP_RULE_DELETE_SUCCESS: 'Tip rule deleted successfully',
  TIP_RULE_ADDED_SUCCESS: 'Tip Rule added Successfully',

  // USER
  USER_UPDATE_SUCCESS: 'User Updated Successfully',
  USER_ADD_SUCCESS: 'User added successfully',

  // STORE
  STORE_ADD_SUCCESS: 'Store added successfully',
  STORE_UPDATE_SUCCESS: 'Store updated successfully',
  CHANNEL_CONFIG_UPDATE_SUCCESS: 'Channel configuration updated successfully',
  UPDATE_ENTERPRISE_ITEM_SUCCESS: 'Updated enterprise item',
  MENU_ITEM_MERGE_SUCCESS: 'Menu Item Merge Updated Successfully',
  MODIFIER_MERGE_SUCCESS: 'Modifier Merge Updated Successfully',

  // SUCCESS
  STORE_IMAGE_DELETED_SUCCESSFULLY: 'Store Image Deleted Successfully',
  STORE_LOGO_IMAGE_DELETED_SUCCESSFULLY: 'Store Logo Image Deleted Successfully',
  KIOSK_LANDING_IMAGE_DELETED_SUCCESSFULLY: 'Kiosk Landing Image Deleted Successfully',
  KIOSK_CONFIRMATION_IMAGE_DELETED_SUCCESSFULLY: 'Kiosk Confirmation Image Deleted Successfully',
  TABLETOP_LANDING_IMAGE_DELETED_SUCCESSFULLY: 'TableTop Landing Image Deleted Successfully',
  TABLETOP_CONFIRMATION_IMAGE_DELETED_SUCCESSFULLY: 'TableTop Confirmation Image Deleted Successfully',
  DEFAULT_MENUITEM_IMAGE_DELETED_SUCCESSFULLY: 'Default MenuItem Image Deleted Successfully',
  STORE_IMAGE_ADDED_SUCCESSFULLY: 'Store Image Added Successfully',
  STORE_LOGO_IMAGE_ADDED_SUCCESSFULLY: 'Store Logo Image Added Successfully',
  KIOSK_LANDING_IMAGE_ADDED_SUCCESSFULLY: 'Kiosk Landing Image Added Successfully',
  KIOSK_CONFIRMATION_IMAGE_ADDED_SUCCESSFULLY: 'Kiosk Confirmation Image Added Successfully',
  TABLETOP_LANDING_IMAGE_ADDED_SUCCESSFULLY: 'TableTop Landing Image Added Successfully',
  TABLETOP_CONFIRMATION_IMAGE_ADDED_SUCCESSFULLY: 'TableTop Confirmation Image Added Successfully',
  DEFAULT_MENUITEM_IMAGE_ADDED_SUCCESSFULLY: 'Default MenuItem Image Added Successfully',
  TABLEGROUP_DELETED_SUCCESSFULLY: 'TableGroup Deleted Successfully',
  SUCCESSFULLY_UPDATED_MOBILE_PAYMENT_SYSTEM: 'Successfully updated Mobile Payment System',
  PAYMENT_SYSTEM_TEST_SUCCESSFULL: 'Payment system test successfull',
  MENUITEM_IMAGE_ADDED_SUCCESSFULLY: 'MenuItem Image Added Successfully',
  MENUITEM_IMAGE_DELETED_SUCCESSFULLY: 'MenuItem Image Deleted Successfully',
  STORE_HOURS_UPDATED_SUCCESSFULLY: 'Store hours updated successfully',
  ACCESS_API: 'Access API key sent via SMS successfully',
  REVOKED_API: 'API key has been revoked successfully',
  PAYMENT_SYSTEM_TEST_SUCESSFULL: 'Payment system test successfull',
  UPDATED_INGENICO: 'Successfully updated ingenico',
  UPDATED_CARD_CONNECT_BOLT: 'Successfully updated card connect bolt',
  MENU_ADDED_SUCCESSFULLY: 'Menu Added Successfully',
  MENU_UPDATED_SUCCESSFULLY: 'Menu Updated Successfully',
  MENU_CLONED_SUCCESSFULLY: 'Menu Cloned Successfully',
  MENUITEM_UPDATED_SUCCESSFULLY: 'MenuItem Updated Successfully',
  STORE_PHONE_UPDATED_SUCCESSFULLY: 'Store phone updated successfully',
  STORE_PHONE_DELETED_SUCCESSFULLY: 'Store phone deleted successfully',
  TABLEGROUP_ADDED_SUCCESSFULLY: 'TableGroup Added Successfully',
  TABLEGROUP_UPDATED_SUCCESSFULLY: 'TableGroup Updated Successfully',
  TEXT_TO_PAY_REQUEST_SUCCESSFULL: 'Text to pay request successfull',
  MENUITEM_ADDED_SUCCESSFULLY: 'MenuItem Added Successfully',
  STORE_DELETED_SUCCESSFULLY: 'Store Deleted Successfully',
  STORE_ADDED_SUCCESSFULLY: 'Store Added Successfully',
  MALL_LOGO_ADDED_SUCCESSFULLY: ' Added Successfully',
  MALL_LOGO_DELETED_SUCCESSFULLY: ' Deleted Successfully',
  JOB_UPDATED_SUCCESSFULLY: 'Job Updated Successfully',
  JOB_ADDED_SUCCESSFULLY: 'Job Added Successfully',
  SCREEN_ADDED_SUCCESSFULLY: 'Screen Added Successfully',
  SCREEN_UPDATED_SUCCESSFULLY: 'Screen Updated Successfully',
  SCREEN_CLONED_SUCCESSFULLY: 'Screen Cloned Successfully',
  MODIFIER_GROUP_CLONED_SUCCESSFULLY: 'Modifier Group Cloned Successfully',
  MODIFIER_CLONED_SUCCESSFULLY: 'Modifier Cloned Successfully',
  MODIFIER_GROUP_ADDED_SUCCESSFULLY: 'Modifier Group Added Successfully',
  MODIFIER_GROUP_UPDATED_SUCCESSFULLY: 'Modifier Group Updated Successfully',
  MODIFIER_UPDATED_SUCCESSFULLY: 'Modifier Updated Successfully',
  MODIFIER_ADDED_SUCCESSFULLY: 'Modifier Added Successfully',
  SUCCESSFULLY_UPDATED_PAYTRONIX_LOYALTY: 'Successfully updated Paytronix Loyalty',
  SUCCESSFULLY_UPDATED_PAYTRONIX_GIFT_CARD: 'Successfully updated Paytronix Gift Card',
  SUCCESSFULLY_UPDATED_PNP: 'Successfully updated PNP',
  CHANNEL_SETUP_GENERATED_SUCCESSFULLY: 'Channel setup generated successfully',
  STORE_TAXE_RATES_UPDATED_SUCCESSFULLY: 'Store taxe rates updated successfully',
  CHARITY_IMAGE_SAVED_SUCCESSFULLY: 'Charity Image Saved Successfully',
  CHARITY_IMAGE_DELETED_SUCCESSFULLY: 'Charity Image Deleted Successfully',
  MENU_IMAGE_ADDED_SUCCESSFULLY: 'Menu Image added Successfully',
  MENU_IMAGE_DELETED_SUCCESSFULLY: 'Menu Image Deleted Successfully',
  MENU_DELETED_SUCCESSFULLY: 'Menu Deleted Successfully',
  SCREEN_DELETED_SUCCESSFULLY: 'Screen Deleted Successfully',
  GUEST_REPORT_SUCCESS: 'Email Id Updated Sccessfuly',
  SCREEN_IMAGE_ADDED_SUCCESSFULLY: 'Screen Image Added Successfully',
  SCREEN_IMAGE_DELETED_SUCCESSFULLY: 'Screen Image Deleted Successfully',
  MODIFIER_IMAGE_ADDED_SUCCESSFULLY: 'Modifier Image Added Successfully',
  MODIFIER_IMAGE_DELETED_SUCCESSFULLY: 'Modifier Image Deleted Successfully',
  CREDENTIALS_VALIDATED: 'Credentials Validated',
  NMI_PAYMENTS_UPDATE_SUCCESS: 'NMI Payments has been successfully updated',
  PAYMENT_SENSE_UPDATE_SUCCESS: 'Payment Sense has been successfully updated',
  SUCCESSFULLY_UPDATED_CLUTCH_LOYALTY: 'Successfully updated Clutch Loyalty',
  SUCCESSFULLY_UPDATED_LOOK_LOYALTY: 'Successfully updated Look Loyalty',

  // Named Sensor
  NAMED_SENSOR_ADD_SUCCESS: 'Sensor Added Successfully',
  NAMED_SENSOR_UPDATE_SUCCESS: 'Sensor Updated Successfully',
  DEREGISTER_SENSOR_SUCCESS: 'Sensor Deregistered Successfully',

  // Sensor Group
  SENSORGROUP_ADDED_SUCCESSFULLY: 'SensorGroup Added Successfully',
  SENSORGROUPS_ADDED_SUCCESSFULLY: 'SensorGroup(s) Added Successfully',
  SUCCESSFULLY_ASSIGNED_SENSOR_GROUP: 'SensorGroup Assigned Successfully',
  SUCCESSFULLY_UNASSIGNED_SENSOR_GROUP: 'SensorGroup Unassigned Successfully',
  SENSORGROUP_UPDATED_SUCCESSFULLY: 'SensorGroup Updated Successfully',
  SENSORGROUP_DELETED_SUCCESSFULLY: 'SensorGroup Deleted Successfully',
  SENSORGROUPS_DELETED_SUCCESSFULLY: 'SensorGroup(s) Deleted Successfully',

  // admin Tags
  ADMIN_TAG_ADDED_SUCCESSFULLY: 'Admin Tag Added Successfully',
  UPDATE_ADMIN_TAG_ADDED_SUCCESSFULLY: 'Admin Tag Updated Successfully',
  TMS_UPDATE_STORE_CHANNEL_SUCCESS: 'Channel Updated Successfully',

  // seatOrder
  SEAT_ORDER_APPROVAL_SUCCESS: 'Seat Order Approve/Reject Successfull',

  MDM_POLICY_UPDATED_SUCCESS: 'MDM Policy Updated Successfull',


  TMS_OCCASSION_DELETE_SUCCESS: 'TMS Occassion Deleted Successfully',


  SUCCESSFULLY_UPDATED_HEARTLAND: 'Successfully updated heartland',
  SPARKFLY_SETUP_SUCCESS: 'Succesfully updated sparkfly setup',

  UPDATED_MENUITEM_LIST_SUCCESSFULLY: 'Successfully updated menu item list',
  APP_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated mobile web customization',
  SERVER_APP_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Server App customization',
  KIOSK_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Kiosk customization',
  TABLE_TOP_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Table Top customization',
  ORDER_UP_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Order Up customization',
  KDS_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated KDS customization',
  WAIT_LIST_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Wait List customization',
  TILE_FILE_UPLOAD_SUCCESS: 'Successfully uploaded file',
  TILE_FILE_DELETE_SUCCESS: 'Successfully deleted file',
  FONT_UPLOAD_SUCCESS: 'Successfully uploaded font',
  FONT_DELETE_SUCCESS: 'Successfully deleted font',
  HEADER_FONT_UPLOAD_SUCCESS: 'Successfully uploaded header font',
  HEADER_FONT_DELETE_SUCCESS: 'Successfully deleted header font',
  CHECKOUT_FONT_UPLOAD_SUCCESS: 'Successfully uploaded checkout font',
  CHECKOUT_FONT_DELETE_SUCCESS: 'Successfully deleted checkout font',
  CHECKOUT_HEADER_FONT_UPLOAD_SUCCESS: 'Successfully uploaded checkout header font',
  CHECKOUT_HEADER_FONT_DELETE_SUCCESS: 'Successfully deleted checkout header font',
  OPT_IN_FILE_UPLOAD_SUCCESS: 'Successfully uploaded file',
  MWEB_NEW_BRAND_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Mobile web new customization',


  ADDED_RELEASE_NOTE_SUCCESS: 'Successfully added release note',
  UPDATED_RELEASE_NOTE_SUCCESS: 'Successfully updated release note',
  DELETED_RELEASE_NOTE_SUCCESS: 'Successfully deleted release note',
  DELETED_RELEASE_NOTE_ITEM_SUCCESS: 'Successfully deleted release note item',


  ADDED_RELEASE_NOTE_ITEM_SUCCESS: 'Successfully added release note item',
  UPDATED_RELEASE_NOTE_ITEM_SUCCESS: 'Successfully updated release note item',

  SUCCESSFULLY_UPDATED_SVS: 'Successfully updated SVS',
  SUCCESSFULLY_UPDATED_PUNCHH: 'Successfully updated punchh',
  SUCCESSFULLY_UPDATED_FISHBOWL: 'Successfully updated fishbowl',
  SUCCESSFULLY_UPDATED_SPENDGO: 'Successfully updated spendgo',
  SUCCESSFULLY_UPDATED_DELIVERY: 'Successfully updated Delivery Setup',
  SUCCESSFULLY_UPDATED_RESTRAURANT_MAGIC: 'Successfully updated Restraurant Magic',
  SUCCESSFULLY_UPDATED_TATTLE_SURVEY: 'Successfully updated Tattle Survey',
  SUCCESSFULLY_UPDATED_CHOWLY: 'Successfully updated Chowly',
  SUCCESSFULLY_UPDATED_OTTER: 'Successfully updated Otter',
  SUCCESSFULLY_UPDATED_WISELY_WEBHOOK: 'Successfully updated wisely webhook',
  SUCCESSFULLY_UPDATED_SPENDGO_WEBHOOK: 'Successfully updated spendgo webhook',
  SUCCESSFULLY_UPDATED_VALUETEC_GIFT_CARD: 'Successfully updated ValueTec Gift Card',
  SUCCESSFULLY_UPDATED_EZCATER: 'Successfully updated ezCater',

  // SIFT Fraud System
  SUCCESSFULLY_UPDATED_SIFT_FRAUD_SYSTEM: 'Successfully updated SIFT Fraud System',
  // ROKT
  SUCCESSFULLY_UPDATED_ROKT_INTEGRATION: 'Successfully updated ROKT Integration',

  // Marketman
  SUCCESSFULLY_UPDATED_MARKETMAN_INVENTORY: 'Successfully updated Marketman Inventory',

  UPDATED_PAYPAL: 'Successfully updated paypal',

  STORE_ADD_ACCOUNTING_SUCCESS: 'Accounting data added successfully',
  STORE_UPDATE_ACCOUNTING_SUCCESS: 'Accounting data updated successfully',

  UPDATED_MEMBER_RELEASE_NOTE_SUCCESS: 'Member releaseNotes updated successfully',
  UPDATE_COLUMN_SUCCESS: 'Successfully updated column list',

  CREATED_OFFERS_SUCCESFULLY: 'Successfully created offers',
  UPDATED_OFFERS_SUCCESFULLY: 'Successfully updated offers',

  RELEASE_NOTE_PDF_UPLOADED_SUCCESS: 'Successfully uploaded Pdf',
  RELEASE_NOTE_PDF_DELETED_SUCCESS: 'Successfully deleted Pdf',

  SURVEY_IMAGE_UPLOADED_SUCCESS: 'Successfully updated survey image',
  SURVEY_IMAGE_DELETED_SUCCESS: 'Successfully deleted survey image',
  RESET_PASSWORD_SUCCESS: 'OTP sent to email/phone successfully',
  SUCCESSFULLY_ADDED_BRAND_HOLIDAY: 'Successfully added brand holiday',
  SUCCESSFULLY_UPDATED_BRAND_HOLIDAY: 'Successfully updated brand holiday',

  SUCCESSFULLY_ADDED_PREP_TIME: 'Successfully added brand prep time',
  SUCCESSFULLY_UPDATED_PREP_TIME: 'Successfully updated brand prep time',
  SUCCESSFULLY_DELETED_PREP_TIME: 'Successfully deleted brand prep time',
  UPDATED_STRIPE: 'Successfully updated stripe',

  BRAND_TAG_ADDED_SUCCESSFULLY: 'Brand Tag Overridden successfully',
  DELETE_BRAND_TAG_SUCCESSFULLY: 'Brand Tag override values removed successfully',

  BRAND_TAG_IMAGE_ADDED_SUCCESSFULLY: 'Brand Tag Icon Added Successfully',
  DELETE_BRAND_TAG_IMAGE_SUCCESSFULLY: 'Brand Tag Icon Deleted Successfully',

  ADMIN_TAG_IMAGE_ADDED_SUCCESSFULLY: 'Tag Icon Added Successfully',
  DELETE_ADMIN_TAG_IMAGE_SUCCESSFULLY: 'Tag Icon Deleted Successfully',

  BRAND_TILE_IMAGE_ADDED_SUCCESSFULLY: 'Tile Image Added Successfully',
  DELETE_BRAND_TILE_IMAGE_SUCCESSFULLY: 'Tile Image Deleted Successfully',

  BRAND_CHARITY_IMAGE_ADDED_SUCCESSFULLY: 'Charity Image Added Successfully',
  DELETE_BRAND_CHARITY_IMAGE_SUCCESSFULLY: 'Charity Image Deleted Successfully',

  BRAND_CHARITY_DELETE_SUCCESS: 'Charity deleted successfully',
  UPDATED_SPREEDLY: 'Successfully updated Spreedly for Toast',

  SUCCESSFULLY_UPDATED_GIVEX: 'Successfully updated givex',

  // Campaign
  CAMPAIGN_ADD_SUCCESS: 'Successfully added Campaign',
  CAMPAIGN_TRIGGER_ADD_SUCCESS: 'Successfully added Campaign Trigger',
  CAMPAIGN_TRIGGER_UPDATED_SUCCESS: 'Successfully updated Campaign Trigger',
  CAMPAIGN_UPDATED_SUCCESS: 'Successfully updated campaign',
  CAMPAIGN_DELETE_SUCCESS: 'Campaign Deleted Successfully',
  CAMPAIGN_IMAGE_ADDED_SUCCESSFULLY: 'Campaign Image Added Successfully',
  DELETE_CAMPAIGN_IMAGE_SUCCESSFULLY: 'Campaign Image Deleted Successfully',
  CAMPAIGN_LOCATION_ADD_SUCCESS: 'Campaign Location added Successfully',


  // System Notification
  SN_ADDED_SUCCESS: 'Successfully added system notification',
  SN_UPDATE_SUCCESS: 'Successfully updated system notification',
  SN_DELETE_SUCCESS: 'Successfully deleted system notification',
  SN_PUBLISH_SUCCESS: 'Successfully published system notification',

  // SMS Template
  SMS_TEMPLATE_DELETE_SUCCESS: 'Successfully deleted sms template',
  ADD_SMS_TEMPLATE_SUCCESS: 'Successfully added sms template',
  UPDATE_SMS_TEMPLATE_SUCCESS: 'Successfully updated sms template',

  // Segment
  SEGMENT_SETUP_ADDED_SUCCESSFULLY: 'Segment Added Successfully',
  SEGMENT_SETUP_UPDATED_SUCCESSFULLY: 'Segment Updated Successfully',
  SEGMENT_OFFER_DEACTIVATED_SUCCESSFULLY: 'Segment Offer deactivated successfully',
  REISSUE_OFFER_SUCCESSFULLY: 'Offer Reissued successfully',
  ISSUE_OFFER_SUCCESSFULLY: 'Offer Issued Successfully',
  // Campaign Occurrence
  CAMPAIGN_OCCURRENCE_ADD_SUCCESS: 'Campaign Occurrence Added Successfully',
  CAMPAIGN_OCCURRENCE_UPDATE_SUCCESS: 'Campaign Occurrence Updated Successfully',
  SAVED_CAMPAIGN_REWARD: 'Campaign Rewards Saved Successfully',
  DELETE_CAMPAIGN_REWARD: 'Campaign Rewards Deleted Successfully',
  SAVED_CAMPAIGN_RULE: 'Campaign Rules Saved Successfully',
  DELETE_CAMPAIGN_RULE: 'Campaign Rules Deleted Successfully',
  CAMPAIGN_OCCURRENCE_RULE_ADDED_SUCCESS: 'Campaign Occurrence Rule Added Successfully',
  CAMPAIGN_OCCURRENCE_RULE_DELETE_SUCCESS: 'Campaign occurrence rule deleted successfully',
  PUBLISH_SUCCESS: 'Campaign Published Successfully',
  SEGMENT_GUEST_ADDED_SUCCESSFULLY: 'Segment Guest added successfully',
  DELETE_SEGMENT_GUESTS_SUCCESSFULLY: 'Segment Guests deleted successfully',
  IMPORTED_CSV_SEGMENT_GUESTS_SUCCESSFULLY: 'Uploaded Segment Guests successfully',

  // Category
  CATEGORY_ADDED_SUCCESSFULLY: 'Category added successfully',
  CATEGORY_UPDATED_SUCCESSFULLY: 'Category updated successfully',

  // Email Template
  EMAIL_TEMPLATE_DELETE_SUCCESS: 'Successfully deleted Email template',
  ADD_EMAIL_TEMPLATE_SUCCESS: 'Successfully added Email template',
  UPDATE_EMAIL_TEMPLATE_SUCCESS: 'Successfully updated Email template',

  // Download Apps
  DOWNLOAD_SUCCESS: 'Successfully Downloaded',

  // External Script
  UPDATE_EXTERNAL_SCRIPT_SUCCESS: 'Successfully updated External script',
  // Store setups
  CATEGORIES_ADDED_SUCCESSFULLY: 'Successfully added categories',
  DISCOUNTS_ADDED_SUCCESSFULLY: 'Successfully added discounts',
  CATEGORIES_UPDATED_SUCCESSFULLY: 'Successfully updated categories',
  DISCOUNTS_UPDATED_SUCCESSFULLY: 'Successfully updated discounts',
  IMAGE_UPLOAD_SUCCESS: 'Image Uploaded Successfully',
  ORDER_TYPES_ADDED_SUCCESSFULLY: 'Successfully added order types',
  ORDER_TYPES_UPDATED_SUCCESSFULLY: 'Successfully updated order types',
  RC_ADDED_SUCCESSFULLY: 'Successfully added revenue centers',
  RC_UPDATED_SUCCESSFULLY: 'Successfully updated revenue centers',
  TENDER_TYPE_ADDED_SUCCESSFULLY: 'Successfully added tender types',
  SUCCESSFULLY_UPDATED_EMPLOYEE: 'Successfully updated employee',
  SUCCESSFULLY_ADDED_EMPLOYEE: 'Successfully added employee',
  TABLE_ADDED_SUCCESSFULLY: 'Successfully added table',
  TABLE_UPDATED_SUCCESSFULLY: 'Successfully updated table',
  STATION_UPDATED_SUCCESSFULLY: 'Successfully updated station',
  TENDER_TYPE_UPDATED_SUCCESSFULLY: 'Successfully updated tender types',
  QUICK_ADDED_TABLE_SUCCESSFULLY: 'Successfully added tables',
  STORE_DRAWER_ADDED_SUCCESSFULLY: 'Successfully added store drawers',
  STORE_DRAWER_UPDATED_SUCCESSFULLY: 'Successfully updated store drawers',
  EMPLOYEE_UNLINK_SUCCESS: 'Successfully unlinked employee',
  EMPLOYEE_LINK_SUCCESS: 'Successfully linked employee',
  STORE_SECTION_ADDED_SUCCESSFULLY: 'Successfully added store section',
  STORE_SECTION_UPDATED_SUCCESSFULLY: 'Successfully updated store section',
  // Teams
  JOB_TEAM_ADDED_SUCCESSFULLY: 'Successfully added job team',
  JOB_TEAM_UPDATED_SUCCESSFULLY: 'Successfully updated job team',
  // Service charges
  SERVICE_CHARGES_ADDED_SUCCESSFULLY: 'Successfully added service charges',
  SERVICE_CHARGES_UPDATED_SUCCESSFULLY: 'Successfully updated service charges',


  DATA_REQUEST_UPDATED_SUCCESSFULLY: 'Successfully updated data request',
  // Charges
  CHARGE_DELETE_SUCCESS: 'Successfully deleted selected charge',
  CHARGE_ADDED_SUCCESS: 'Successfully added charge',
  CHARGE_TIER_ADDED_SUCCESS: 'Successfully added charge tier',
  CHARGE_UPDATE_SUCCESS: 'Successfully updated charge data',
  CHARGE_TIER_UPDATE_SUCCESS: 'Successfully updated charge tier data',
  CHARGE_TIER_DELETE_SUCCESS: 'Successfully deleted charge tier',

  // Print category
  SUCCESSFULLY_ADDED_PRINT_CATEGORY: 'Successfully added print category',
  SUCCESSFULLY_UPDATED_PRINT_CATEGORY: 'Successfully updated print category',
  SUCCESSFULLY_DELETED_PRINT_CATEGORY: 'Successfully deleted print category',

  // Pos Agent
  SUCCESSFULLY_CHECKED_FOR_UPDATE: 'Successfully checked for updated',
  SUCCESSFULLY_REACTIVATE_POSAGENT: 'Successfully reactivated pos agent',
  SUCCESSFULLY_RESTART_POSAGENT: 'Successfully restarted pos agent',
  SUCCESSFULLY_REFRESH_CACHE: 'Successfully refreshed cache',
  SUCCESSFULLY_DOWNLOADED_MENU_FILE: 'Successfully downloaded menu file',
  SUCCESSFULLY_DOWNLOADED_CURRENT_LOG_FILE: 'Successfully downloaded current log file',
  SUCCESSFULLY_DOWNLOADED_LOGS: 'Successfully downloaded logs',
  SUCCESSFULLY_GOT_AGENT_INFO: 'Successfully called agent health info',

  // VOUCHERS
  SUCCESSFULLY_ADDED_VOUCHERS: 'Successfully added voucher',
  SUCCESSFULLY_UPDATED_VOUCHERS: 'Successfully updated voucher',
  SUCCESSFULLY_ADDED_BULK_VOUCHERS: 'Successfully added bulk voucher',
  SUCCESSFULLY_ADDED_VOUCHER_DATE: 'Successfully added voucher date',
  SUCCESSFULLY_UPDATED_VOUCHER_DATE: 'Successfully updated voucher date',
  SUCCESSFULLY_DELETED_VOUCHER_DATE: 'Successfully deleted voucher date',
  SUCCESSFULLY_ADDED_VOUCHER_TIME: 'Successfully added voucher time',
  SUCCESSFULLY_UPDATED_VOUCHER_TIME: 'Successfully updated voucher time',
  SUCCESSFULLY_DELETED_VOUCHER_TIME: 'Successfully deleted voucher time',
  SUCCESSFULLY_ADDED_VOUCHER_IMAGE: 'Successfully added voucher image',
  SUCCESSFULLY_DELETED_VOUCHER_IMAGE: 'Successfully deleted voucher image',

  // Device Group

  SUCCESSFULLY_DELETED_DEVICE_GROUP: 'Successfully deleted device group',
  DEVICE_GROUP_ADD_SUCCESS: 'Successfully added device group',
  DEVICE_GROUP_UPDATE_SUCCESS: 'Successfully updated device group',
  CARD_READER_DEVICE_PROVISION_SUCCESS: 'Successfully provisioned the device',
  CARD_READER_DEVICE_DE_PROVISION_SUCCESS: 'Successfully De-provisioned the device',

  // Store Production
  STORE_PROD_ADDED_SUCCESSFULLY: 'Successfully added store production rate',
  STORE_PROD_UPDATED_SUCCESSFULLY: 'Successfully updated store production rate',
  STORE_PROD_DELETED_SUCCESSFULLY: 'Successfully deleted selected store production rate',
  STORE_PROD_OVERRIDE_SUCCESSFULLY: 'Successfully override store production rate',
  QUICK_ADD_STORE_PROD_SUCCESSFULLY: 'Successfully added store production rate',
  QUICK_DELETE_STORE_PROD_SUCCESSFULLY: 'Successfully deleted store production rate',

  // Admin language
  APP_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated mobile web default customization',
  SERVER_APP_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated server app default customization',
  KIOSK_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated kiosk default customization',
  TABLE_TOP_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated tableTop default customization',
  KDS_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated kds default customization',
  ORDER_UP_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated orderUp default customization',
  WAIT_LIST_DEFAULT_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated wait list default customization',
  MWEB_NEW_CUSTOMIZATION_UPDATE_SUCCESS: 'Successfully updated Mobile web new default customization',


  // Brand Langugae
  DELETE_BRAND_LANGUAGE_SUCCESS: 'Successfully deleted selected language',
  DEFAULT_BRAND_LANGUAGE_SUCCESS: 'Successfully made selected language as default',
  BRAND_LANGUAGE_SORTED_SUCCESS: 'Successfully sorted language',
  ADDED_BRAND_LANGUAGE_SUCCESS: 'Successfully added selected language',
  ENABLE_SUCCESS: 'Successfully enabled selected language',
  DISABLE_SUCCESS: 'Successfully disabled selected language',

  // theme
  BRAND_MOBILE_APP_THEME_UPDATE_SUCCESS: 'Brand mobile web theme updated successfully',
  BRAND_SERVER_APP_THEME_UPDATE_SUCCESS: 'Brand Server App theme updated successfully',
  BRAND_KIOSK_THEME_UPDATE_SUCCESS: 'Brand Kiosk theme updated successfully',
  BRAND_TABLE_TOP_THEME_UPDATE_SUCCESS: 'Brand Tabletop theme updated successfully',
  BRAND_ORDER_UP_THEME_UPDATE_SUCCESS: 'Brand OrderUp theme updated successfully',
  BRAND_KDS_THEME_UPDATE_SUCCESS: 'Brand KDS theme updated successfully',
  MOBILE_APP_THEME_UPDATE_SUCCESS: 'Mobile App theme updated successfully',
  BRAND_WAITLIST_THEME_UPDATE_SUCCESS: 'Brand Wait List theme updated successfully',

  // Cubby
  CUBBY_ADD_SUCCESS: 'Cubby Added Successfully',
  CUBBY_UPDATE_SUCCESS: 'Cubby Updated Successfully',
  SLOTS_ADDED_SUCCESS: 'Cubby Slots Added Successfully',
  SLOTS_UPDATE_SUCCESS: 'Cubby Slot Updated Successfully',

  // Fiserv
  SUCCESSFULLY_UPDATED_FISERV: 'Successfully updated fiserv',

  // Internationalization
  LANGUAGE_CUSTOMIZATION_SUCCESS: 'Successfully updated label customization of',

  // Financial Account
  BRAND_ACCOUNT_UPDATE_SUCCESS: 'Successfully updated brand account',
  STORE_ACCOUNT_UPDATE_SUCCESS: 'Successfully updated store account',

  // reorder
  ORDER_TYPES_SORTED_SUCCESS: 'Order Type Sorted Successfully',
  REVENUE_CENTER_SORTED_SUCCESS: 'Revenue Center Sorted Successfully',


  // EmpApp Payment Adjustment
  PAYMENT_ADJUSTMENT_REPORT_SUCCESS: 'Payment Adjustment success',

  // EmpApp Void
  VOID_ITEMS_SUCCESS: 'Successfully voided check items',
  VOID_CHECK_SUCCESS: 'Successfully voided checks',
  DISCOUNT_CHECK_SUCCESS: 'Successfully discounted checks',

  // RiskRule
  RISK_RULE_ADD_SUCCESS: 'Risk Rule Added Successfully',
  RISK_RULE_UPDATE_SUCCESS: 'Risk Rule Updated Successfully',
  RISK_RULE_DELETE_SUCCESS: 'Risk Rule Deleted Successfully',
  SAVED_RISK_RULE: 'Risk Rule Saved Successfully',
  DELETE_RISK_RULE: 'Risk Rule Deleted Successfully',

  // Payment Adjustment
  REFUND_SUCCESS: 'Refund successful',
  CHARGEBACK_SUCCESS: 'Chargeback Successful',
  REPOST_TO_TRANSACTION_SUCCESS: 'Repost transaction to POS successfull',
  USER_BLOCKED_SUCCESS: 'User Blocked Successfully',

  // Apple
  APPLE_VERFICATION_SUCCESS: 'Apple Verification successful',

  // TSD Global
  TSD_GLOBAL_UPDATE_SUCCESS: 'Updated TSD Global Successfully',

  // STORE MAPPING
  STORE_MAPPING_UPDATE_SUCCESS: 'Store Mapping updated successfully',
  TENDER_TYPE_MAPPING_UPDATE_SUCCESS: 'Tender Type Mapping updated successfully',
  OFF_PREMISE_MAPPING_UPDATE_SUCCESS: 'Off Premise Mapping updated successfully',
  POS_SMS_MAPPING_UPDATE_SUCCESS: 'POS SMS Mapping updated successfully',

  // MENU COURSES
  MENU_COURSE_ADDED_SUCCESSFULLY: 'Menu Course Added Successfully',
  MENU_COURSE_UPDATED_SUCCESSFULLY: 'Menu Course Updated Successfully',
  MENU_COURSE_DELETE_SUCCESS: 'Menu Course Deleted Successfully',
  MENU_COURSE_SORTED_SUCCESS: 'Menu Course Sorted Successfully',

  // Data Request Report
  SUCCESSFULLY_DELETED_SELECTED_DATA_REPORT: 'Deleted selected data report successfully',
  UPDATED_GUEST_DATA_SUCCESSFULLY: 'Updated Guest Data Successfully',

  // UserWay Third Party
  SUCCESSFULLY_UPDATED_USERWAY_WIDGET: 'Successfully updated UserWay Accessibility Widget',

  // Cabbage pay
  UPDATED_CABBAGE_PAY: 'Successfully updated cabbage pay',

  // Brand Marketplace setup
  SUCCESSFULLY_UPDATED_MARKETPLACE: 'Successfully updated Marketplace',

  // Brand Happy Hours
  SUCCESSFULLY_UPDATED_HAPPY_HOUR: 'Successfully updated Happy Hour',

  // Brand Receipt Template
  SUCCESSFULLY_UPDATED_RECEIPT_TEMPLATE: 'Successfully updated Receipt Template',

  // Brand Order Label Template
  SUCCESSFULLY_UPDATED_ORDER_LABEL_TEMPLATE: 'Successfully updated Order Label Template',


  // Brand KDS Setup
  KDS_SETTINGS_UPDATED_SUCCESSFULLY: 'KDS Settings updated successfully',
  BRAND_KDS_STATION_TYPE_DELETE_SUCCESS: 'KDS Station Type deleted successfully',
  BRAND_KDS_STATION_TYPE_ADD_SUCCESS: 'KDS Station Type added successfully',
  BRAND_KDS_STATION_TYPE_UPDATE_SUCCESS: 'KDS Station Type updated successfully',
  BRAND_KDS_LAYOUT_ADD_SUCCESS: 'KDS Layout added successfully',
  BRAND_KDS_LAYOUT_UPDATE_SUCCESS: 'KDS Layout updated successfully',
  BRAND_KDS_LAYOUT_DELETE_SUCCESS: 'KDS Layout deleted successfully',
  SAVED_KDS_RULE: 'KDS Rule Saved Successfully',
  DELETE_KDS_RULE: 'KDS Rule Deleted Successfully',
  BRAND_KDS_LAYOUT_SORTED_SUCCESS: 'KDS Layout Sorted Successfully',
  BRAND_KDS_PRINT_ADD_SUCCESS: 'KDS Print Definition added successfully',
  BRAND_KDS_PRINT_UPDATE_SUCCESS: 'KDS Print Definition updated successfully',
  BRAND_KDS_PRINT_DELETE_SUCCESS: 'KDS Print Definition deleted successfully',
  SAVED_KDS_PRINT_RULE: 'KDS Print Definition Rule Saved Successfully',
  DELETE_KDS_PRINT_RULE: 'KDS Print Definition Rule Deleted Successfully',
  DEFAULT_KDS_PRINT_SUCCESS: 'Successfully made selected KDS Print Definition as default',

  // Amazon pay
  UPDATED_AMAZON_PAY: 'Successfully updated amazon pay',

  // KDS Layout
  DEFAULT_KDS_LAYOUT_SUCCESS: 'Successfully made selected KDS Layout as default',

  // Medallia Survey
  SUCCESSFULLY_UPDATED_MEDALLIA_SURVEY: 'Successfully updated Medallia Survey',

  // Mobile App > Pages
  SUCCESSFULLY_UPDATED_MOBILE_APP_PAGES: 'Successfully updated Mobile App Pages',
  SUCCESSFULLY_SAVED_MOBILE_APP_PAGES: 'Successfully saved Mobile App Pages',
  SUCCESSFULLY_SAVED_MOBILE_APP_PAGE_DESIGN: 'Successfully saved Mobile App Page Design',

  // External Template
  EXTERNAL_INTEGRATION_DELETE_SUCCESS: 'Successfully deleted External integration',
  EXTERNAL_INTEGRATION_ADD_SUCCESS: 'Successfully added External integration',
  EXTERNAL_INTEGRATION_UPDATE_SUCCESS: 'Successfully updated External integration',
  EXTERNAL_INTEGRATION_REGENERATE_SUCCESS: 'Successfully regenerated the External integration',

  // Mobile App
  MOBILE_APP_MEDIA_ADDED_SUCCESSFULLY: 'Mobile App Media added successfully',
  MOBILE_APP_MEDIA_IMAGE_ADDED_SUCCESSFULLY: 'Mobile App Media Image added successfully',
  MOBILE_APP_MEDIA_UPDATE_SUCCESS: 'Mobile App Media Updated successfully',
  SUCCESSFULLY_UPDATED_MOBILE_APP_IMAGE: 'Successfully saved Mobile App Image',
  MOBILE_APP_IMAGE_ADDED_SUCCESSFULLY: 'Mobile App Image added successfully',
  MOBILE_APP_LAYOUT_ADD_SUCCESS: 'Mobile App Layout added successfully',
  MOBILE_APP_LAYOUT_UPDATE_SUCCESS: 'Mobile App Layout updated successfully',
  MOBILE_APP_LAYOUT_ENABLE_PRODUCTION_SUCCESS: 'Enabled Layout for Production successfully',
  MOBILE_APP_LAYOUT_ENABLE_TEST_SUCCESS: 'Enabled Layout for Test successfully',
  SUCCESSFULLY_SAVED_MOBILE_APP_LAYOUT_DESIGN: 'Successfully saved Mobile App Layout Design',
  MOBILE_APP_LAYOUT_DELETE_SUCCESS: 'Mobile App Layout deleted successfully',
  MOBILE_APP_MEDIA_DELETED_SUCCESSFULLY: 'Mobile App Media deleted successfully',
  MOBILE_APP_PAGE_DELETE_SUCCESS: 'Mobile App Page deleted successfully',

  // Menu device Group
  DEVICE_GROUPS_ADDED_SUCCESSFULLY: 'DeviceGroup(s) Added Successfully',
  DEVICE_GROUPS_DELETED_SUCCESSFULLY: 'DeviceGroup(s) Deleted Successfully',

  // Intercard
  SUCCESSFULLY_UPDATED_INTERCARD: 'Successfully updated intercard',
  // powercard
  SUCCESSFULLY_UPDATED_POWERCARD: 'Successfully updated Powercard',
  // giftcard
  SUCCESSFULLY_UPDATED_GIFTCARD: 'Successfully updated Giftcard',
  // POS Menu Load
  SUCCESSFULLY_UPDATED_POS_SYSTEM: 'Successfully updated POS System',

  // EmpApp Member Search
  SUCCESSFULLY_NEW_GUEST_ADDED: 'Successfully new guest added',

  // D&B Loyalty
  SUCCESSFULLY_UPDATED_DB_LOYALTY: 'Successfully updated D&B Loyalty',

  // Role Templates
  ROLE_TEMPLATES_ADDED_SUCCESS: 'Role templates added successfully',
  ROLE_TEMPLATES_UPDATED_SUCCESS: 'Role templates updated successfully',
  ROLE_TEMPLATES_DELETE_SUCCESS: 'Role templates deleted successfully',

  // Company Roles
  SUCCESSFULLY_ADDED_ROLE: 'Role added successfully',
  SUCCESSFULLY_UPDATED_ROLE: 'Role updated successfully',
  SUCCESSFULLY_DELETED_ROLE: 'Role deleted successfully',
  SUCCESSFULLY_ADDED_ROLE_PERMISSION: 'Role Permissions added successfully',

  // Permissions Templates
  PERMISSIONS_TEMPLATES_UPDATED_SUCCESS: 'Permissions templates updated successfully',

};
