const currentReducer = 'whatsNew';
const GET_RELEASE_NOTE_REQUESTED = `${currentReducer}/GET_RELEASE_NOTE_REQUESTED`;
const GET_RELEASE_NOTE_SUCCESS = `${currentReducer}/GET_RELEASE_NOTE_SUCCESS`;
const GET_RELEASE_NOTE_FAILURE = `${currentReducer}/GET_RELEASE_NOTE_FAILURE`;

const GET_RELEASE_INITIALDATA_REQUESTED = `${currentReducer}/GET_RELEASE_INITIALDATA_REQUESTED`;
const GET_RELEASE_INITIALDATA_FAILURE = `${currentReducer}/GET_RELEASE_INITIALDATA_FAILURE`;

const GET_RELEASE_NOTE_PRODUCTS_SUCCESS = `${currentReducer}/GET_RELEASE_NOTE_PRODUCTS_SUCCESS`;
const GET_RELEASE_NOTE_STATUS_SUCCESS = `${currentReducer}/GET_RELEASE_NOTE_STATUS_SUCCESS`;
const GET_RELEASE_NOTE_TYPES_SUCCESS = `${currentReducer}/GET_RELEASE_NOTE_TYPES_SUCCESS`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  releaseNote: [],
  releaseNoteProducts: [],
  releaseNoteStatus: [],
  releaseNoteTypes: [],
  pdfUrl: null,
};

const getUrl = (data) => {
  let url = null;
  url = data.map((x) => {
    return x.pdfUrl;
  });
  return url;
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNote: action.result.sort((a, b) => {
          return new Date(b.releaseDate) - new Date(a.releaseDate);
        }),
        pdfUrl: getUrl(action.result),
      };
    }
    case GET_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_RELEASE_INITIALDATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_RELEASE_INITIALDATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_RELEASE_NOTE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNoteProducts: action.result
      };
    }
    case GET_RELEASE_NOTE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNoteStatus: action.result
      };
    }
    case GET_RELEASE_NOTE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNoteTypes: action.result
      };
    }
    default:
      return state;
  }
};

export const getReleaseNote = () => {
  return {
    types: [GET_RELEASE_NOTE_REQUESTED, GET_RELEASE_NOTE_SUCCESS, GET_RELEASE_NOTE_FAILURE],
    promise: client => client.get('getReleaseNote')
  };
};

export const updateReleaseNoteViewedStatus = (userId) => {
  return {
    types: [GET_RELEASE_NOTE_REQUESTED, GET_RELEASE_NOTE_SUCCESS, GET_RELEASE_NOTE_FAILURE],
    promise: client => client.post(`updateReleaseNoteViewedStatus/${userId}`)
  };
};

export const getReleaseNoteProducts = () => {
  return {
    types: [GET_RELEASE_INITIALDATA_REQUESTED, GET_RELEASE_NOTE_PRODUCTS_SUCCESS, GET_RELEASE_INITIALDATA_FAILURE],
    promise: client => client.get('getReleaseNoteProducts')
  };
};


export const getReleaseNoteTypes = () => {
  return {
    types: [GET_RELEASE_INITIALDATA_REQUESTED, GET_RELEASE_NOTE_TYPES_SUCCESS, GET_RELEASE_INITIALDATA_FAILURE],
    promise: client => client.get('getReleaseNoteTypes')
  };
};

export const getReleaseNoteStatus = () => {
  return {
    types: [GET_RELEASE_INITIALDATA_REQUESTED, GET_RELEASE_NOTE_STATUS_SUCCESS, GET_RELEASE_INITIALDATA_FAILURE],
    promise: client => client.get('getReleaseNoteStatuses')
  };
};
