export const GET_EMP_STORELIST_REQUESTED = 'empApp/GET_EMP_STORELIST_REQUESTED';
export const GET_EMP_STORELIST_SUCCESS = 'empApp/GET_EMP_STORELIST_SUCCESS';
export const GET_EMP_STORELIST_FAILURE = 'empApp/GET_EMP_STORELIST_FAILURE';

export const GET_ACTIVE_EMPS_LIST_REQUESTED = 'empApp/GET_ACTIVE_EMPS_LIST_REQUESTED';
export const GET_ACTIVE_EMPS_LIST_SUCCESS = 'empApp/GET_ACTIVE_EMPS_LIST_SUCCESS';
export const GET_ACTIVE_EMPS_LIST_FAILURE = 'empApp/GET_ACTIVE_EMPS_LIST_FAILURE';

export const GET_STORE_DRAWERS_LIST_REQUESTED = 'empApp/GET_STORE_DRAWERS_LIST_REQUESTED';
export const GET_STORE_DRAWERS_LIST_SUCCESS = 'empApp/GET_STORE_DRAWERS_LIST_SUCCESS';
export const GET_STORE_DRAWERS_LIST_FAILURE = 'empApp/GET_STORE_DRAWERS_LIST_FAILURE';

export const GET_STORE_DRAWER_BALANCE_REQUESTED = 'empApp/GET_STORE_DRAWER_BALANCE_REQUESTED';
export const GET_STORE_DRAWER_BALANCE_SUCCESS = 'empApp/GET_STORE_DRAWER_BALANCE_SUCCESS';
export const GET_STORE_DRAWER_BALANCE_FAILURE = 'empApp/GET_STORE_DRAWER_BALANCE_FAILURE';

export const GET_EMPLOYEE_DRAWER_BALANCE_REQUESTED = 'empApp/GET_EMPLOYEE_DRAWER_BALANCE_REQUESTED';
export const GET_EMPLOYEE_DRAWER_BALANCE_SUCCESS = 'empApp/GET_EMPLOYEE_DRAWER_BALANCE_SUCCESS';
export const GET_EMPLOYEE_DRAWER_BALANCE_FAILURE = 'empApp/GET_EMPLOYEE_DRAWER_BALANCE_FAILURE';

export const UPDATE_BANK_LOAD_EMPLOYEE_REQUESTED = 'empApp/UPDATE_BANK_LOAD_EMPLOYEE_REQUESTED';
export const UPDATE_BANK_LOAD_EMPLOYEE_SUCCESS = 'empApp/UPDATE_BANK_LOAD_EMPLOYEE_SUCCESS';
export const UPDATE_BANK_LOAD_EMPLOYEE_FAILURE = 'empApp/UPDATE_BANK_LOAD_EMPLOYEE_FAILURE';

export const UPDATE_BANK_RELOAD_EMPLOYEE_REQUESTED = 'empApp/UPDATE_BANK_RELOAD_EMPLOYEE_REQUESTED';
export const UPDATE_BANK_RELOAD_EMPLOYEE_SUCCESS = 'empApp/UPDATE_BANK_RELOAD_EMPLOYEE_SUCCESS';
export const UPDATE_BANK_RELOAD_EMPLOYEE_FAILURE = 'empApp/UPDATE_BANK_RELOAD_EMPLOYEE_FAILURE';

export const UPDATE_CASH_DROP_EMPLOYEE_REQUESTED = 'empApp/UPDATE_CASH_DROP_EMPLOYEE_REQUESTED';
export const UPDATE_CASH_DROP_EMPLOYEE_SUCCESS = 'empApp/UPDATE_CASH_DROP_EMPLOYEE_SUCCESS';
export const UPDATE_CASH_DROP_EMPLOYEE_FAILURE = 'empApp/UPDATE_CASH_DROP_EMPLOYEE_FAILURE';

export const UPDATE_PAID_IN_DEPOSIT_REQUESTED = 'empApp/UPDATE_PAID_IN_DEPOSIT_REQUESTED';
export const UPDATE_PAID_IN_DEPOSIT_SUCCESS = 'empApp/UPDATE_PAID_IN_DEPOSIT_SUCCESS';
export const UPDATE_PAID_IN_DEPOSIT_FAILURE = 'empApp/UPDATE_PAID_IN_DEPOSIT_FAILURE';

export const UPDATE_PAID_OUT_WITHDRAW_REQUESTED = 'empApp/UPDATE_PAID_OUT_WITHDRAW_REQUESTED';
export const UPDATE_PAID_OUT_WITHDRAW_SUCCESS = 'empApp/UPDATE_PAID_OUT_WITHDRAW_SUCCESS';
export const UPDATE_PAID_OUT_WITHDRAW_FAILURE = 'empApp/UPDATE_PAID_OUT_WITHDRAW_FAILURE';

export const UPDATE_CASH_DEPOSIT_REQUESTED = 'empApp/UPDATE_CASH_DEPOSIT_REQUESTED';
export const UPDATE_CASH_DEPOSIT_SUCCESS = 'empApp/UPDATE_CASH_DEPOSIT_SUCCESS';
export const UPDATE_CASH_DEPOSIT_FAILURE = 'empApp/UPDATE_CASH_DEPOSIT_FAILURE';

export const UPDATE_SAFE_WITHDRAW_REQUESTED = 'empApp/UPDATE_SAFE_WITHDRAW_REQUESTED';
export const UPDATE_SAFE_WITHDRAW_SUCCESS = 'empApp/UPDATE_SAFE_WITHDRAW_SUCCESS';
export const UPDATE_SAFE_WITHDRAW_FAILURE = 'empApp/UPDATE_SAFE_WITHDRAW_FAILURE';

export const UPDATE_SAFE_DEPOSIT_REQUESTED = 'empApp/UPDATE_SAFE_DEPOSIT_REQUESTED';
export const UPDATE_SAFE_DEPOSIT_SUCCESS = 'empApp/UPDATE_SAFE_DEPOSIT_SUCCESS';
export const UPDATE_SAFE_DEPOSIT_FAILURE = 'empApp/UPDATE_SAFE_DEPOSIT_FAILURE';

export const GET_SAFE_BALANCE_REQUESTED = 'empApp/GET_SAFE_BALANCE_REQUESTED';
export const GET_SAFE_BALANCE_SUCCESS = 'empApp/GET_SAFE_BALANCE_SUCCESS';
export const GET_SAFE_BALANCE_FAILURE = 'empApp/GET_SAFE_BALANCE_FAILURE';

export const GET_CASH_BALANCE_REQUESTED = 'empApp/GET_CASH_BALANCE_REQUESTED';
export const GET_CASH_BALANCE_SUCCESS = 'empApp/GET_CASH_BALANCE_SUCCESS';
export const GET_CASH_BALANCE_FAILURE = 'empApp/GET_CASH_BALANCE_FAILURE';

export const GET_CASH_OUT_BALANCE_REQUESTED = 'empApp/GET_CASH_OUT_BALANCE_REQUESTED';
export const GET_CASH_OUT_BALANCE_SUCCESS = 'empApp/GET_CASH_OUT_BALANCE_SUCCESS';
export const GET_CASH_OUT_BALANCE_FAILURE = 'empApp/GET_CASH_OUT_BALANCE_FAILURE';

export const UPDATE_SERVER_CASH_OUT_REQUESTED = 'empApp/UPDATE_SERVER_CASH_OUT_REQUESTED';
export const UPDATE_SERVER_CASH_OUT_SUCCESS = 'empApp/UPDATE_SERVER_CASH_OUT_SUCCESS';
export const UPDATE_SERVER_CASH_OUT_FAILURE = 'empApp/UPDATE_SERVER_CASH_OUT_FAILURE';

export const UPDATE_CASHIER_CASH_OUT_REQUESTED = 'empApp/UPDATE_CASHIER_CASH_OUT_REQUESTED';
export const UPDATE_CASHIER_CASH_OUT_SUCCESS = 'empApp/UPDATE_CASHIER_CASH_OUT_SUCCESS';
export const UPDATE_CASHIER_CASH_OUT_FAILURE = 'empApp/UPDATE_CASHIER_CASH_OUT_FAILURE';

export const GET_SERVER_ACTIVE_EMPS_LIST_REQUESTED = 'empApp/GET_SERVER_ACTIVE_EMPS_LIST_REQUESTED';
export const GET_SERVER_ACTIVE_EMPS_LIST_SUCCESS = 'empApp/GET_SERVER_ACTIVE_EMPS_LIST_SUCCESS';
export const GET_SERVER_ACTIVE_EMPS_LIST_FAILURE = 'empApp/GET_SERVER_ACTIVE_EMPS_LIST_FAILURE';

export const GET_CASHIER_ACTIVE_EMPS_LIST_REQUESTED = 'empApp/GET_CASHIER_ACTIVE_EMPS_LIST_REQUESTED';
export const GET_CASHIER_ACTIVE_EMPS_LIST_SUCCESS = 'empApp/GET_CASHIER_ACTIVE_EMPS_LIST_SUCCESS';
export const GET_CASHIER_ACTIVE_EMPS_LIST_FAILURE = 'empApp/GET_CASHIER_ACTIVE_EMPS_LIST_FAILURE';

export const GET_CASH_OUT_SUMMARY_REQUESTED = 'empApp/GET_CASH_OUT_SUMMARY_REQUESTED';
export const GET_CASH_OUT_SUMMARY_SUCCESS = 'empApp/GET_CASH_OUT_SUMMARY_SUCCESS';
export const GET_CASH_OUT_SUMMARY_FAILURE = 'empApp/GET_CASH_OUT_SUMMARY_FAILURE';

export const GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED = 'empApp/GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED';
export const GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS = 'empApp/GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS';
export const GET_ACCOUNTS_LOOKUPS_LIST_FAILURE = 'empApp/GET_ACCOUNTS_LOOKUPS_LIST_FAILURE';

export const GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED = 'empApp/GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED';
export const GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS = 'empApp/GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS';
export const GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE = 'empApp/GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE';

export const UPDATE_CASH_SAFE_TRANSFER_REQUESTED = 'empApp/UPDATE_CASH_SAFE_TRANSFER_REQUESTED';
export const UPDATE_CASH_SAFE_TRANSFER_SUCCESS = 'empApp/UPDATE_CASH_SAFE_TRANSFER_SUCCESS';
export const UPDATE_CASH_SAFE_TRANSFER_FAILURE = 'empApp/UPDATE_CASH_SAFE_TRANSFER_FAILURE';

export const UPDATE_CASH_DEPOSIT_TRANSFER_REQUESTED = 'empApp/UPDATE_CASH_DEPOSIT_TRANSFER_REQUESTED';
export const UPDATE_CASH_DEPOSIT_TRANSFER_SUCCESS = 'empApp/UPDATE_CASH_DEPOSIT_TRANSFER_SUCCESS';
export const UPDATE_CASH_DEPOSIT_TRANSFER_FAILURE = 'empApp/UPDATE_CASH_DEPOSIT_TRANSFER_FAILURE';

export const GET_CASH_DEPOSIT_BALANCE_REQUESTED = 'empApp/GET_CASH_DEPOSIT_BALANCE_REQUESTED';
export const GET_CASH_DEPOSIT_BALANCE_SUCCESS = 'empApp/GET_CASH_DEPOSIT_BALANCE_SUCCESS';
export const GET_CASH_DEPOSIT_BALANCE_FAILURE = 'empApp/GET_CASH_DEPOSIT_BALANCE_FAILURE';

export const GET_VOID_CHECKS_BY_STOREID_REQUESTED = 'empApp/GET_VOID_CHECKS_BY_STOREID_REQUESTED';
export const GET_VOID_CHECKS_BY_STOREID_SUCCESS = 'empApp/GET_VOID_CHECKS_BY_STOREID_SUCCESS';
export const GET_VOID_CHECKS_BY_STOREID_FAILURE = 'empApp/GET_VOID_CHECKS_BY_STOREID_FAILURE';

export const SAVE_VOID_CHECKS_REQUESTED = 'empApp/SAVE_VOID_CHECKS_REQUESTED';
export const SAVE_VOID_CHECKS_SUCCESS = 'empApp/SAVE_VOID_CHECKS_SUCCESS';
export const SAVE_VOID_CHECKS_FAILURE = 'empApp/SAVE_VOID_CHECKS_FAILURE';

export const SAVE_VOID_ITEMS_REQUESTED = 'empApp/SAVE_VOID_ITEMS_REQUESTED';
export const SAVE_VOID_ITEMS_SUCCESS = 'empApp/SAVE_VOID_ITEMS_SUCCESS';
export const SAVE_VOID_ITEMS_FAILURE = 'empApp/SAVE_VOID_ITEMS_FAILURE';

export const GET_DISCOUNT_CHECKS_BY_STOREID_REQUESTED = 'empApp/GET_DISCOUNT_CHECKS_BY_STOREID_REQUESTED';
export const GET_DISCOUNT_CHECKS_BY_STOREID_SUCCESS = 'empApp/GET_DISCOUNT_CHECKS_BY_STOREID_SUCCESS';
export const GET_DISCOUNT_CHECKS_BY_STOREID_FAILURE = 'empApp/GET_DISCOUNT_CHECKS_BY_STOREID_FAILURE';

export const GET_DISCOUNT_LIST_BY_STOREID_REQUESTED = 'empApp/GET_DISCOUNT_LIST_BY_STOREID_REQUESTED';
export const GET_DISCOUNT_LIST_BY_STOREID_SUCCESS = 'empApp/GET_DISCOUNT_LIST_BY_STOREID_SUCCESS';
export const GET_DISCOUNT_LIST_BY_STOREID_FAILURE = 'empApp/GET_DISCOUNT_LIST_BY_STOREID_FAILURE';

export const SAVE_DISCOUNT_CHECKS_REQUESTED = 'empApp/SAVE_DISCOUNT_CHECKS_REQUESTED';
export const SAVE_DISCOUNT_CHECKS_SUCCESS = 'empApp/SAVE_DISCOUNT_CHECKS_SUCCESS';
export const SAVE_DISCOUNT_CHECKS_FAILURE = 'empApp/SAVE_DISCOUNT_CHECKS_FAILURE';

export const SAVE_MEMBERS_REQUESTED = 'empApp/SAVE_MEMBERS_REQUESTED';
export const SAVE_MEMBERS_SUCCESS = 'empApp/SAVE_MEMBERS_SUCCESS';
export const SAVE_MEMBERS_FAILURE = 'empApp/SAVE_MEMBERS_FAILURE';

export const GET_EMP_CAMPAIGN_REQUESTED = 'empApp/GET_EMP_CAMPAIGN_REQUESTED';
export const GET_EMP_CAMPAIGN_SUCCESS = 'empApp/GET_EMP_CAMPAIGN_SUCCESS';
export const GET_EMP_CAMPAIGN_FAILURE = 'empApp/GET_EMP_CAMPAIGN_FAILURE';

export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED = 'empApp/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED';
export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS = 'empApp/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS';
export const GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE = 'empApp/GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  empStoreList: [],
  empActiveList: [],
  empServerList: [],
  empCashierList: [],
  storeDrawerBalance: null,
  employeeBalance: null,
  safeBalanceData: null,
  cashBalanceData: null,
  cashOutBalanceData: null,
  cashOutSummaryData: null,
  storeAccountBalanceList: [],
  accountLookups: [],
  cashDepositBalanceData: null,
  voidChecksData: null,
  discountChecksData: null,
  discountListData: [],
  empCampaignList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMP_STORELIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        empStoreList: [],
      };
    }
    case GET_EMP_STORELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        empStoreList: action.result,
      };
    }
    case GET_EMP_STORELIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ACTIVE_EMPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        empActiveList: []
      };
    }
    case GET_ACTIVE_EMPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        empActiveList: action.result,
      };
    }
    case GET_ACTIVE_EMPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_DRAWERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        storeDrawerList: []
      };
    }
    case GET_STORE_DRAWERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeDrawerList: action.result,
      };
    }
    case GET_STORE_DRAWERS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_DRAWER_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        employeeBalance: null,
      };
    }
    case GET_STORE_DRAWER_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeDrawerBalance: action.result,
      };
    }
    case GET_STORE_DRAWER_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_EMPLOYEE_DRAWER_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        employeeBalance: null,
      };
    }
    case GET_EMPLOYEE_DRAWER_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        employeeBalance: action.result,
      };
    }
    case GET_EMPLOYEE_DRAWER_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BANK_LOAD_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BANK_LOAD_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BANK_LOAD_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BANK_RELOAD_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BANK_RELOAD_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BANK_RELOAD_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CASH_DROP_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CASH_DROP_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CASH_DROP_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PAID_IN_DEPOSIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PAID_IN_DEPOSIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PAID_IN_DEPOSIT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PAID_OUT_WITHDRAW_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PAID_OUT_WITHDRAW_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PAID_OUT_WITHDRAW_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CASH_DEPOSIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CASH_DEPOSIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CASH_DEPOSIT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SAFE_WITHDRAW_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SAFE_WITHDRAW_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SAFE_WITHDRAW_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SAFE_DEPOSIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SAFE_DEPOSIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SAFE_DEPOSIT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SAFE_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        safeBalanceData: null,
      };
    }
    case GET_SAFE_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        safeBalanceData: action.result,
      };
    }
    case GET_SAFE_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CASH_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        cashBalanceData: null,
      };
    }
    case GET_CASH_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cashBalanceData: action.result,
      };
    }
    case GET_CASH_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CASH_OUT_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        cashOutBalanceData: null,
      };
    }
    case GET_CASH_OUT_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cashOutBalanceData: action.result,
      };
    }
    case GET_CASH_OUT_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SERVER_CASH_OUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SERVER_CASH_OUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SERVER_CASH_OUT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CASHIER_CASH_OUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CASHIER_CASH_OUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CASHIER_CASH_OUT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SERVER_ACTIVE_EMPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        empServerList: []
      };
    }
    case GET_SERVER_ACTIVE_EMPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        empServerList: action.result,
      };
    }
    case GET_SERVER_ACTIVE_EMPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CASHIER_ACTIVE_EMPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        empCashierList: []
      };
    }
    case GET_CASHIER_ACTIVE_EMPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        empCashierList: action.result,
      };
    }
    case GET_CASHIER_ACTIVE_EMPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CASH_OUT_SUMMARY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        cashOutSummaryData: null,
      };
    }
    case GET_CASH_OUT_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cashOutSummaryData: action.result,
      };
    }
    case GET_CASH_OUT_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        accountLookups: []
      };
    }
    case GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        accountLookups: action.result,
      };
    }
    case GET_ACCOUNTS_LOOKUPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        storeAccountBalanceList: []
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountBalanceList: action.result,
      };
    }
    case GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CASH_SAFE_TRANSFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CASH_SAFE_TRANSFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CASH_SAFE_TRANSFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CASH_DEPOSIT_TRANSFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CASH_DEPOSIT_TRANSFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CASH_DEPOSIT_TRANSFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CASH_DEPOSIT_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        cashDepositBalanceData: null,
      };
    }
    case GET_CASH_DEPOSIT_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cashDepositBalanceData: action.result,
      };
    }
    case GET_CASH_DEPOSIT_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_VOID_CHECKS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        voidChecksData: null,
      };
    }
    case GET_VOID_CHECKS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        voidChecksData: action.result,
      };
    }
    case GET_VOID_CHECKS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_VOID_CHECKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_VOID_CHECKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_VOID_CHECKS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_VOID_ITEMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_VOID_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_VOID_ITEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DISCOUNT_CHECKS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        discountChecksData: null,
      };
    }
    case GET_DISCOUNT_CHECKS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        discountChecksData: action.result,
      };
    }
    case GET_DISCOUNT_CHECKS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DISCOUNT_LIST_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DISCOUNT_LIST_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        discountListData: action.result,
      };
    }
    case GET_DISCOUNT_LIST_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_DISCOUNT_CHECKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_DISCOUNT_CHECKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_DISCOUNT_CHECKS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_MEMBERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_MEMBERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_MEMBERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_EMP_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EMP_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        empCampaignList: action.result
      };
    }
    case GET_EMP_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        storeAccountBalanceList: []
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeAccountBalanceList: action.result,
      };
    }
    case GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getEmpStoreList = () => {
  return {
    types: [GET_EMP_STORELIST_REQUESTED, GET_EMP_STORELIST_SUCCESS, GET_EMP_STORELIST_FAILURE],
    promise: client => client.get('getEmpStoreList')
  };
};

export const getActiveEmpList = (storeId) => {
  return {
    types: [GET_ACTIVE_EMPS_LIST_REQUESTED, GET_ACTIVE_EMPS_LIST_SUCCESS, GET_ACTIVE_EMPS_LIST_FAILURE],
    promise: client => client.get(`getActiveEmpList/${storeId}`)
  };
};

export const getStoreDrawersList = (storeId) => {
  return {
    types: [GET_STORE_DRAWERS_LIST_REQUESTED, GET_STORE_DRAWERS_LIST_SUCCESS, GET_STORE_DRAWERS_LIST_FAILURE],
    promise: client => client.get(`getStoreDrawersList/${storeId}`)
  };
};

export const getStoreDrawerBalance = (drawerId) => {
  return {
    types: [GET_STORE_DRAWER_BALANCE_REQUESTED, GET_STORE_DRAWER_BALANCE_SUCCESS, GET_STORE_DRAWER_BALANCE_FAILURE],
    promise: client => client.get(`getStoreDrawerBalance/${drawerId}`)
  };
};

export const updateBankLoadEmployee = (data) => {
  return {
    types: [UPDATE_BANK_LOAD_EMPLOYEE_REQUESTED, UPDATE_BANK_LOAD_EMPLOYEE_SUCCESS, UPDATE_BANK_LOAD_EMPLOYEE_FAILURE],
    promise: client => client.post('saveBankLoadEmployee', { data })
  };
};

export const updateBankReLoadEmployee = (data) => {
  return {
    types: [UPDATE_BANK_RELOAD_EMPLOYEE_REQUESTED, UPDATE_BANK_RELOAD_EMPLOYEE_SUCCESS, UPDATE_BANK_RELOAD_EMPLOYEE_FAILURE],
    promise: client => client.post('saveBankReloadEmployee', { data })
  };
};

export const updateCashDropEmployee = (data) => {
  return {
    types: [UPDATE_CASH_DROP_EMPLOYEE_REQUESTED, UPDATE_CASH_DROP_EMPLOYEE_SUCCESS, UPDATE_CASH_DROP_EMPLOYEE_FAILURE],
    promise: client => client.post('saveCashDropEmployee', { data })
  };
};

export const updatePaidInDeposit = (data) => {
  return {
    types: [UPDATE_PAID_IN_DEPOSIT_REQUESTED, UPDATE_PAID_IN_DEPOSIT_SUCCESS, UPDATE_PAID_IN_DEPOSIT_FAILURE],
    promise: client => client.post('savePaidInDeposit', { data })
  };
};

export const updatePaidOutWithdraw = (data) => {
  return {
    types: [UPDATE_PAID_OUT_WITHDRAW_REQUESTED, UPDATE_PAID_OUT_WITHDRAW_SUCCESS, UPDATE_PAID_OUT_WITHDRAW_FAILURE],
    promise: client => client.post('savePaidOutWithdraw', { data })
  };
};

export const updateCashDeposit = (data) => {
  return {
    types: [UPDATE_CASH_DEPOSIT_REQUESTED, UPDATE_CASH_DEPOSIT_SUCCESS, UPDATE_CASH_DEPOSIT_FAILURE],
    promise: client => client.post('saveCashDeposit', { data })
  };
};

export const updateSafeDeposit = (data) => {
  return {
    types: [UPDATE_SAFE_DEPOSIT_REQUESTED, UPDATE_SAFE_DEPOSIT_SUCCESS, UPDATE_SAFE_DEPOSIT_FAILURE],
    promise: client => client.post('saveSafeDeposit', { data })
  };
};

export const updateSafeWithdraw = (data) => {
  return {
    types: [UPDATE_SAFE_WITHDRAW_REQUESTED, UPDATE_SAFE_WITHDRAW_SUCCESS, UPDATE_SAFE_WITHDRAW_FAILURE],
    promise: client => client.post('saveSafeWithdraw', { data })
  };
};

export const getSafeBalance = (data) => {
  return {
    types: [GET_SAFE_BALANCE_REQUESTED, GET_SAFE_BALANCE_SUCCESS, GET_SAFE_BALANCE_FAILURE],
    promise: client => client.post('getSafeBalance', { data })
  };
};

export const getEmployeeBalance = (data) => {
  return {
    types: [GET_EMPLOYEE_DRAWER_BALANCE_REQUESTED, GET_EMPLOYEE_DRAWER_BALANCE_SUCCESS, GET_EMPLOYEE_DRAWER_BALANCE_FAILURE],
    promise: client => client.post('getEmployeeBalance', { data })
  };
};

export const getCashBalance = (data) => {
  return {
    types: [GET_CASH_BALANCE_REQUESTED, GET_CASH_BALANCE_SUCCESS, GET_CASH_BALANCE_FAILURE],
    promise: client => client.post('getCashBalance', { data })
  };
};

export const getCashOutBalance = (data) => {
  return {
    types: [GET_CASH_OUT_BALANCE_REQUESTED, GET_CASH_OUT_BALANCE_SUCCESS, GET_CASH_OUT_BALANCE_FAILURE],
    promise: client => client.post('getCashOutBalance', { data })
  };
};

export const updateServerCashOut = (data) => {
  return {
    types: [UPDATE_SERVER_CASH_OUT_REQUESTED, UPDATE_SERVER_CASH_OUT_SUCCESS, UPDATE_SERVER_CASH_OUT_FAILURE],
    promise: client => client.post('saveServerCashOut', { data })
  };
};

export const updateCashierCashOut = (data) => {
  return {
    types: [UPDATE_CASHIER_CASH_OUT_REQUESTED, UPDATE_CASHIER_CASH_OUT_SUCCESS, UPDATE_CASHIER_CASH_OUT_FAILURE],
    promise: client => client.post('saveCashierCashOut', { data })
  };
};

export const getActiveEmpServerList = (storeId) => {
  return {
    types: [GET_SERVER_ACTIVE_EMPS_LIST_REQUESTED, GET_SERVER_ACTIVE_EMPS_LIST_SUCCESS, GET_SERVER_ACTIVE_EMPS_LIST_FAILURE],
    promise: client => client.get(`getActiveEmpServerList/${storeId}`)
  };
};

export const getActiveEmpCashierList = (storeId) => {
  return {
    types: [GET_CASHIER_ACTIVE_EMPS_LIST_REQUESTED, GET_CASHIER_ACTIVE_EMPS_LIST_SUCCESS, GET_CASHIER_ACTIVE_EMPS_LIST_FAILURE],
    promise: client => client.get(`getActiveEmpCashierList/${storeId}`)
  };
};

export const getCashoutSummary = (data) => {
  return {
    types: [GET_CASH_OUT_SUMMARY_REQUESTED, GET_CASH_OUT_SUMMARY_SUCCESS, GET_CASH_OUT_SUMMARY_FAILURE],
    promise: client => client.post('getCashoutSummary', { data })
  };
};

export const getAccountsLookUps = () => {
  return {
    types: [GET_ACCOUNTS_LOOKUPS_LIST_REQUESTED,
      GET_ACCOUNTS_LOOKUPS_LIST_SUCCESS,
      GET_ACCOUNTS_LOOKUPS_LIST_FAILURE],
    promise: client => client.get('getAccountTypesLookUps')
  };
};

export const getStoreAccountsBalance = (storeId) => {
  return {
    types: [GET_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED,
      GET_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS,
      GET_STORE_ACCOUNTS_BALANCE_LIST_FAILURE],
    promise: client => client.get(`getStoreAccountsBalance/${storeId}`)
  };
};

export const updateCashSafeTransfert = (data) => {
  return {
    types: [UPDATE_CASH_SAFE_TRANSFER_REQUESTED, UPDATE_CASH_SAFE_TRANSFER_SUCCESS, UPDATE_CASH_SAFE_TRANSFER_FAILURE],
    promise: client => client.post('saveCashSafeTransfer', { data })
  };
};

export const updateCashDepositTransfer = (data) => {
  return {
    types: [UPDATE_CASH_DEPOSIT_TRANSFER_REQUESTED, UPDATE_CASH_DEPOSIT_TRANSFER_SUCCESS, UPDATE_CASH_DEPOSIT_TRANSFER_FAILURE],
    promise: client => client.post('saveCashDepositTransfer', { data })
  };
};

export const getCashDepositBalance = (data) => {
  return {
    types: [GET_CASH_DEPOSIT_BALANCE_REQUESTED, GET_CASH_DEPOSIT_BALANCE_SUCCESS, GET_CASH_DEPOSIT_BALANCE_FAILURE],
    promise: client => client.post('getCashDepositBalance', { data })
  };
};

export const getVoidChecksData = (storeId) => {
  return {
    types: [GET_VOID_CHECKS_BY_STOREID_REQUESTED, GET_VOID_CHECKS_BY_STOREID_SUCCESS, GET_VOID_CHECKS_BY_STOREID_FAILURE],
    promise: client => client.get(`getVoidChecksByStoreId/${storeId}`)
  };
};

export const saveVoidChecks = (data) => {
  return {
    types: [SAVE_VOID_CHECKS_REQUESTED, SAVE_VOID_CHECKS_SUCCESS, SAVE_VOID_CHECKS_FAILURE],
    promise: client => client.post('saveChecksVoid', { data })
  };
};

export const saveVoidItems = (data) => {
  return {
    types: [SAVE_VOID_ITEMS_REQUESTED, SAVE_VOID_ITEMS_SUCCESS, SAVE_VOID_ITEMS_FAILURE],
    promise: client => client.post('saveChecksVoidItem', { data })
  };
};

export const getDiscountChecksData = (storeId) => {
  return {
    types: [GET_DISCOUNT_CHECKS_BY_STOREID_REQUESTED, GET_DISCOUNT_CHECKS_BY_STOREID_SUCCESS, GET_DISCOUNT_CHECKS_BY_STOREID_FAILURE],
    promise: client => client.get(`getVoidChecksByStoreId/${storeId}`)
  };
};

export const getDiscountList = (storeId) => {
  return {
    types: [GET_DISCOUNT_LIST_BY_STOREID_REQUESTED, GET_DISCOUNT_LIST_BY_STOREID_SUCCESS, GET_DISCOUNT_LIST_BY_STOREID_FAILURE],
    promise: client => client.get(`getDiscountListByStoreId/${storeId}`)
  };
};

export const saveDiscountChecks = (data) => {
  return {
    types: [SAVE_DISCOUNT_CHECKS_REQUESTED, SAVE_DISCOUNT_CHECKS_SUCCESS, SAVE_DISCOUNT_CHECKS_FAILURE],
    promise: client => client.post('saveDiscountChecks', { data })
  };
};

export const saveMembersData = (data) => {
  return {
    types: [SAVE_MEMBERS_REQUESTED, SAVE_MEMBERS_SUCCESS, SAVE_MEMBERS_FAILURE],
    promise: client => client.post('saveMembers', { data })
  };
};
export const getCampaignData = (brandId) => {
  return {
    types: [GET_EMP_CAMPAIGN_REQUESTED, GET_EMP_CAMPAIGN_SUCCESS, GET_EMP_CAMPAIGN_FAILURE],
    promise: client => client.get(`getEmpCampaignList/${brandId}`)
  };
};
export const getManagerStoreAccountsBalance = (storeId) => {
  return {
    types: [GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_REQUESTED,
      GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_SUCCESS,
      GET_MANAGER_STORE_ACCOUNTS_BALANCE_LIST_FAILURE],
    promise: client => client.get(`getManagerStoreAccountsBalance/${storeId}`)
  };
};
