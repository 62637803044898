export const GET_BRAND_CHARGES_LIST_REQUESTED = 'brandCharges/GET_BRAND_CHARGES_LIST_REQUESTED';
export const GET_BRAND_CHARGES_LIST_SUCCESS = 'brandCharges/GET_BRAND_CHARGES_LIST_SUCCESS';
export const GET_BRAND_CHARGES_LIST_FAILURE = 'brandCharges/GET_BRAND_CHARGES_LIST_FAILURE';

export const DELETE_CHARGES_LIST_BY_ID_REQUESTED = 'brandCharges/DELETE_CHARGES_LIST_BY_ID_REQUESTED';
export const DELETE_CHARGES_LIST_BY_ID_SUCCESS = 'brandCharges/DELETE_CHARGES_LIST_BY_ID_SUCCESS';
export const DELETE_CHARGES_LIST_BY_ID_FAILURE = 'brandCharges/DELETE_CHARGES_LIST_BY_ID_FAILURE';

export const ADD_CHARGES_REQUESTED = 'brandCharges/ADD_CHARGES_REQUESTED';
export const ADD_CHARGES_SUCCESS = 'brandCharges/ADD_CHARGES_SUCCESS';
export const ADD_CHARGES_FAILURE = 'brandCharges/ADD_CHARGES_FAILURE';

export const UPDATE_CHARGES_REQUESTED = 'brandCharges/UPDATE_CHARGES_REQUESTED';
export const UPDATE_CHARGES_SUCCESS = 'brandCharges/UPDATE_CHARGES_SUCCESS';
export const UPDATE_CHARGES_FAILURE = 'brandCharges/UPDATE_CHARGES_FAILURE';

export const GET_CHARGES_BY_ID_REQUESTED = 'brandCharges/GET_CHARGES_BY_ID_REQUESTED';
export const GET_CHARGES_BY_ID_SUCCESS = 'brandCharges/GET_CHARGES_BY_ID_SUCCESS';
export const GET_CHARGES_BY_ID_FAILURE = 'brandCharges/GET_CHARGES_BY_ID_FAILURE';

export const DELETE_CHARGE_TIER_BY_ID_REQUESTED = 'brandCharges/DELETE_CHARGE_TIER_BY_ID_REQUESTED';
export const DELETE_CHARGE_TIER_BY_ID_SUCCESS = 'brandCharges/DELETE_CHARGE_TIER_BY_ID_SUCCESS';
export const DELETE_CHARGE_TIER_BY_ID_FAILURE = 'brandCharges/DELETE_CHARGE_TIER_BY_ID_FAILURE';

export const GET_CHARGE_TYPE_REQUESTED = 'brandCharges/GET_CHARGE_TYPE_REQUESTED';
export const GET_CHARGE_TYPE_SUCCESS = 'brandCharges/GET_CHARGE_TYPE_SUCCESS';
export const GET_CHARGE_TYPE_FAILURE = 'brandCharges/GET_CHARGE_TYPE_FAILURE';

export const ADD_CHARGE_TIER_REQUESTED = 'brandCharges/ADD_CHARGE_TIER_REQUESTED';
export const ADD_CHARGE_TIER_SUCCESS = 'brandCharges/ADD_CHARGE_TIER_SUCCESS';
export const ADD_CHARGE_TIER_FAILURE = 'brandCharges/ADD_CHARGE_TIER_FAILURE';

export const UPDATE_CHARGE_TIER_REQUESTED = 'brandCharges/UPDATE_CHARGE_TIER_REQUESTED';
export const UPDATE_CHARGE_TIER_SUCCESS = 'brandCharges/UPDATE_CHARGE_TIER_SUCCESS';
export const UPDATE_CHARGE_TIER_FAILURE = 'brandCharges/UPDATE_CHARGE_TIER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  chargesList: [],
  chargeData: [],
  chargeTypeList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_CHARGES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        chargesList: [],
      };
    }
    case GET_BRAND_CHARGES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chargesList: action.result,
      };
    }
    case GET_BRAND_CHARGES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHARGE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        chargeTypeList: [],
      };
    }
    case GET_CHARGE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chargeTypeList: action.result,
      };
    }
    case GET_CHARGE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHARGES_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        chargeData: [],
      };
    }
    case GET_CHARGES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chargeData: action.result,
      };
    }
    case GET_CHARGES_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_CHARGES_LIST_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CHARGES_LIST_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_CHARGES_LIST_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_CHARGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CHARGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CHARGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CHARGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CHARGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CHARGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_CHARGE_TIER_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CHARGE_TIER_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_CHARGE_TIER_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_CHARGE_TIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CHARGE_TIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CHARGE_TIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CHARGE_TIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CHARGE_TIER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CHARGE_TIER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getChargeList = (brandId) => {
  return {
    types: [GET_BRAND_CHARGES_LIST_REQUESTED, GET_BRAND_CHARGES_LIST_SUCCESS, GET_BRAND_CHARGES_LIST_FAILURE],
    promise: client => client.get(`getChargeList/${brandId}`)
  };
};
export const getChargesById = (chargeId) => {
  return {
    types: [GET_CHARGES_BY_ID_REQUESTED, GET_CHARGES_BY_ID_SUCCESS, GET_CHARGES_BY_ID_FAILURE],
    promise: client => client.get(`getChargesById/${chargeId}`)
  };
};

export const deleteChargeByID = (chargeId) => {
  return {
    types: [DELETE_CHARGES_LIST_BY_ID_REQUESTED, DELETE_CHARGES_LIST_BY_ID_SUCCESS, DELETE_CHARGES_LIST_BY_ID_FAILURE],
    promise: client => client.post(`deleteChargeById/${chargeId}`)
  };
};

export const addCharges = (data) => {
  return {
    types: [ADD_CHARGES_REQUESTED, ADD_CHARGES_SUCCESS, ADD_CHARGES_FAILURE],
    promise: client => client.post('addCharge', { data })
  };
};

export const updateCharges = (data) => {
  return {
    types: [UPDATE_CHARGES_REQUESTED, UPDATE_CHARGES_SUCCESS, UPDATE_CHARGES_FAILURE],
    promise: client => client.post('updateCharge', { data })
  };
};
export const deleteChargeTierById = (chargeId) => {
  return {
    types: [DELETE_CHARGE_TIER_BY_ID_REQUESTED, DELETE_CHARGE_TIER_BY_ID_SUCCESS, DELETE_CHARGE_TIER_BY_ID_FAILURE],
    promise: client => client.post(`deleteChargeTierById/${chargeId}`)
  };
};

export const getChargeType = () => {
  return {
    types: [GET_CHARGE_TYPE_REQUESTED, GET_CHARGE_TYPE_SUCCESS, GET_CHARGE_TYPE_FAILURE],
    promise: client => client.get('getChargeType')
  };
};

export const addChargeTier = (data) => {
  return {
    types: [ADD_CHARGE_TIER_REQUESTED, ADD_CHARGE_TIER_SUCCESS, ADD_CHARGE_TIER_FAILURE],
    promise: client => client.post('addChargeTier', { data })
  };
};

export const updateChargeTier = (data) => {
  return {
    types: [UPDATE_CHARGE_TIER_REQUESTED, UPDATE_CHARGE_TIER_SUCCESS, UPDATE_CHARGE_TIER_FAILURE],
    promise: client => client.post('updateChargeTier', { data })
  };
};
