export const GET_GUEST_REQUESTED = 'guestReport/GET_GUEST_REQUESTED';
export const GET_GUEST_SUCCESS = 'guestReport/GET_GUEST_SUCCESS';
export const GET_GUEST_FAILURE = 'guestReport/GET_GUEST_FAILURE';

export const GET_DATAREQUEST_RESOLUTIONS_REQUESTED = 'guestReport/GET_DATAREQUEST_RESOLUTIONS_REQUESTED';
export const GET_DATAREQUEST_RESOLUTIONS_SUCCESS = 'guestReport/GET_DATAREQUEST_RESOLUTIONS_SUCCESS';
export const GET_DATAREQUEST_RESOLUTIONS_FAILURE = 'guestReport/GET_DATAREQUEST_RESOLUTIONS_FAILURE';

export const POST_GUEST_REPORT_REQUESTED = 'guestReport/POST_GUEST_REPORT_REQUESTED';
export const POST_GUEST_REPORT_SUCCESS = 'guestReport/POST_GUEST_REPORT_SUCCESS';
export const POST_GUEST_REPORT_FAILURE = 'guestReport/POST_GUEST_REPORT_FAILURE';


export const CLEAR_REPORTS_DATA = 'guestReport/CLEAR_REPORTS_DATA';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  guestListData: [],
  guestResolutionsList: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_GUEST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_GUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        guestListData: action.result,
      };
    }
    case GET_GUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DATAREQUEST_RESOLUTIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DATAREQUEST_RESOLUTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        guestResolutionsList: action.result,
      };
    }
    case GET_DATAREQUEST_RESOLUTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_GUEST_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_GUEST_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // guestListData: action.result,
      };
    }
    case POST_GUEST_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};


export const getAdminDataRequest = (data) => {
  return {
    types: [GET_GUEST_REQUESTED, GET_GUEST_SUCCESS, GET_GUEST_FAILURE],
    promise: client => client.get('getAdminDataRequest', { data })
  };
};

export const updateAdminDataRequest = (data) => {
  return {
    types: [POST_GUEST_REPORT_REQUESTED, POST_GUEST_REPORT_SUCCESS, POST_GUEST_REPORT_FAILURE],
    promise: client => client.post('updateAdminDataRequest', { data })
  };
};

export const getDataRequestResolutions = (data) => {
  return {
    types: [GET_DATAREQUEST_RESOLUTIONS_REQUESTED, GET_DATAREQUEST_RESOLUTIONS_SUCCESS, GET_DATAREQUEST_RESOLUTIONS_FAILURE],
    promise: client => client.get('getDataRequestResolutions', { data })
  };
};


export const resetReportsData = () => {
  return {
    type: CLEAR_REPORTS_DATA,
  };
};
