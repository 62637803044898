export const GET_VOUCHERS_LIST_REQUESTED = 'vouchers/GET_VOUCHERS_LIST_REQUESTED';
export const GET_VOUCHERS_LIST_SUCCESS = 'vouchers/GET_VOUCHERS_LIST_SUCCESS';
export const GET_VOUCHERS_LIST_FAILURE = 'vouchers/GET_VOUCHERS_LIST_FAILURE';

export const GET_VOUCHER_BY_ID_REQUESTED = 'vouchers/GET_VOUCHER_BY_ID_REQUESTED';
export const GET_VOUCHER_BY_ID_SUCCESS = 'vouchers/GET_VOUCHER_BY_ID_SUCCESS';
export const GET_VOUCHER_BY_ID_FAILURE = 'vouchers/GET_VOUCHER_BY_ID_FAILURE';

export const ADD_VOUCHER_REQUESTED = 'vouchers/ADD_VOUCHER_REQUESTED';
export const ADD_VOUCHER_SUCCESS = 'vouchers/ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILURE = 'vouchers/ADD_VOUCHER_FAILURE';

export const UPDATE_VOUCHER_REQUESTED = 'vouchers/UPDATE_VOUCHER_REQUESTED';
export const UPDATE_VOUCHER_SUCCESS = 'vouchers/UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAILURE = 'vouchers/UPDATE_VOUCHER_FAILURE';

export const ADD_BULK_VOUCHER_REQUESTED = 'vouchers/ADD_BULK_VOUCHER_REQUESTED';
export const ADD_BULK_VOUCHER_SUCCESS = 'vouchers/ADD_BULK_VOUCHER_SUCCESS';
export const ADD_BULK_VOUCHER_FAILURE = 'vouchers/ADD_BULK_VOUCHER_FAILURE';

export const ADD_VOUCHER_DATE_REQUESTED = 'vouchers/ADD_VOUCHER_DATE_REQUESTED';
export const ADD_VOUCHER_DATE_SUCCESS = 'vouchers/ADD_VOUCHER_DATE_SUCCESS';
export const ADD_VOUCHER_DATE_FAILURE = 'vouchers/ADD_VOUCHER_DATE_FAILURE';

export const UPDATE_VOUCHER_DATE_REQUESTED = 'vouchers/UPDATE_VOUCHER_DATE_REQUESTED';
export const UPDATE_VOUCHER_DATE_SUCCESS = 'vouchers/UPDATE_VOUCHER_DATE_SUCCESS';
export const UPDATE_VOUCHER_DATE_FAILURE = 'vouchers/UPDATE_VOUCHER_DATE_FAILURE';

export const DELETE_VOUCHER_DATE_REQUESTED = 'vouchers/DELETE_VOUCHER_DATE_REQUESTED';
export const DELETE_VOUCHER_DATE_SUCCESS = 'vouchers/DELETE_VOUCHER_DATE_SUCCESS';
export const DELETE_VOUCHER_DATE_FAILURE = 'vouchers/DELETE_VOUCHER_DATE_FAILURE';

export const ADD_VOUCHER_TIME_REQUESTED = 'vouchers/ADD_VOUCHER_TIME_REQUESTED';
export const ADD_VOUCHER_TIME_SUCCESS = 'vouchers/ADD_VOUCHER_TIME_SUCCESS';
export const ADD_VOUCHER_TIME_FAILURE = 'vouchers/ADD_VOUCHER_TIME_FAILURE';

export const UPDATE_VOUCHER_TIME_REQUESTED = 'vouchers/UPDATE_VOUCHER_TIME_REQUESTED';
export const UPDATE_VOUCHER_TIME_SUCCESS = 'vouchers/UPDATE_VOUCHER_TIME_SUCCESS';
export const UPDATE_VOUCHER_TIME_FAILURE = 'vouchers/UPDATE_VOUCHER_TIME_FAILURE';

export const DELETE_VOUCHER_TIME_REQUESTED = 'vouchers/DELETE_VOUCHER_TIME_REQUESTED';
export const DELETE_VOUCHER_TIME_SUCCESS = 'vouchers/DELETE_VOUCHER_TIME_SUCCESS';
export const DELETE_VOUCHER_TIME_FAILURE = 'vouchers/DELETE_VOUCHER_TIME_FAILURE';

export const DELETE_VOUCHER_IMAGE_REQUESTED = 'vouchers/DELETE_VOUCHER_IMAGE_REQUESTED';
export const DELETE_VOUCHER_IMAGE_SUCCESS = 'vouchers/DELETE_VOUCHER_IMAGE_SUCCESS';
export const DELETE_VOUCHER_IMAGE_FAILURE = 'vouchers/DELETE_VOUCHER_IMAGE_FAILURE';

export const ADD_VOUCHER_IMAGE_REQUESTED = 'vouchers/ADD_VOUCHER_IMAGE_REQUESTED';
export const ADD_VOUCHER_IMAGE_SUCCESS = 'vouchers/ADD_VOUCHER_IMAGE_SUCCESS';
export const ADD_VOUCHER_IMAGE_FAILURE = 'vouchers/ADD_VOUCHER_IMAGE_FAILURE';

export const SET_VOUCHERS_LIST_INPUTS = 'vouchers/SET_VOUCHERS_LIST_INPUTS';

export const GET_VOUCHERS_SEND_MODES_REQUESTED = 'vouchers/GET_VOUCHERS_SEND_MODES_REQUESTED';
export const GET_VOUCHERS_SEND_MODES_SUCCESS = 'vouchers/GET_VOUCHERS_SEND_MODES_SUCCESS';
export const GET_VOUCHERS_SEND_MODES_FAILURE = 'vouchers/GET_VOUCHERS_SEND_MODES_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  vouchersList: [],
  voucherData: {},
  vouchersListInputs: {},
  voucherSendModes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VOUCHERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_VOUCHERS_LIST_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        vouchersList: action.result,
      };
    }
    case GET_VOUCHERS_LIST_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_VOUCHER_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        voucherData: {},
      };
    }
    case GET_VOUCHER_BY_ID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        voucherData: action.result,
      };
    }
    case GET_VOUCHER_BY_ID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_VOUCHER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_VOUCHER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_VOUCHER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_VOUCHER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_VOUCHER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_VOUCHER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_BULK_VOUCHER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BULK_VOUCHER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_BULK_VOUCHER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_VOUCHER_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_VOUCHER_DATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_VOUCHER_DATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_VOUCHER_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_VOUCHER_DATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_VOUCHER_DATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_VOUCHER_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_VOUCHER_DATE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_VOUCHER_DATE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_VOUCHER_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_VOUCHER_TIME_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_VOUCHER_TIME_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_VOUCHER_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_VOUCHER_TIME_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_VOUCHER_TIME_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_VOUCHER_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_VOUCHER_TIME_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_VOUCHER_TIME_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_VOUCHER_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_VOUCHER_IMAGE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_VOUCHER_IMAGE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_VOUCHER_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_VOUCHER_IMAGE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_VOUCHER_IMAGE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SET_VOUCHERS_LIST_INPUTS: {
      return {
        ...state,
        vouchersListInputs: action.payload,
      };
    }
    case GET_VOUCHERS_SEND_MODES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_VOUCHERS_SEND_MODES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        voucherSendModes: action.result,
      };
    }
    case GET_VOUCHERS_SEND_MODES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getVouchersList = (brandId) => {
  return {
    types: [GET_VOUCHERS_LIST_REQUESTED, GET_VOUCHERS_LIST_SUCCESS, GET_VOUCHERS_LIST_FAILURE],
    promise: client => client.get(`getVouchersList/${brandId}`)
  };
};

export const getVoucherById = (voucherId) => {
  return {
    types: [GET_VOUCHER_BY_ID_REQUESTED, GET_VOUCHER_BY_ID_SUCCESS, GET_VOUCHER_BY_ID_FAILURE],
    promise: client => client.get(`getVouchersById/${voucherId}`)
  };
};

export const addVoucher = (data) => {
  return {
    types: [ADD_VOUCHER_REQUESTED, ADD_VOUCHER_SUCCESS, ADD_VOUCHER_FAILURE],
    promise: client => client.post('addVouchers', {
      data,
    }),
  };
};

export const updateVoucher = (data) => {
  return {
    types: [UPDATE_VOUCHER_REQUESTED, UPDATE_VOUCHER_SUCCESS, UPDATE_VOUCHER_FAILURE],
    promise: client => client.post('updateVouchers', {
      data,
    })
  };
};

export const addBulkVoucher = (data) => {
  return {
    types: [ADD_BULK_VOUCHER_REQUESTED, ADD_BULK_VOUCHER_SUCCESS, ADD_BULK_VOUCHER_FAILURE],
    promise: client => client.post('addBulkVoucher', {
      data,
    }),
  };
};

export const addVoucherDate = (data) => {
  return {
    types: [ADD_VOUCHER_DATE_REQUESTED, ADD_VOUCHER_DATE_SUCCESS, ADD_VOUCHER_DATE_FAILURE],
    promise: client => client.post('addVoucherDate', {
      data,
    }),
  };
};

export const updateVoucherDate = (data) => {
  return {
    types: [UPDATE_VOUCHER_DATE_REQUESTED, UPDATE_VOUCHER_DATE_SUCCESS, UPDATE_VOUCHER_DATE_FAILURE],
    promise: client => client.post('updateVoucherDate', {
      data,
    })
  };
};

export const deleteVoucherDate = (voucherId) => {
  return {
    types: [DELETE_VOUCHER_DATE_REQUESTED, DELETE_VOUCHER_DATE_SUCCESS, DELETE_VOUCHER_DATE_FAILURE],
    promise: client => client.post(`deleteVoucherDate/${voucherId}`)
  };
};


export const addVoucherTime = (data) => {
  return {
    types: [ADD_VOUCHER_TIME_REQUESTED, ADD_VOUCHER_TIME_SUCCESS, ADD_VOUCHER_TIME_FAILURE],
    promise: client => client.post('addVoucherTime', {
      data,
    }),
  };
};

export const updateVoucherTime = (data) => {
  return {
    types: [UPDATE_VOUCHER_TIME_REQUESTED, UPDATE_VOUCHER_TIME_SUCCESS, UPDATE_VOUCHER_TIME_FAILURE],
    promise: client => client.post('updateVoucherTime', {
      data,
    })
  };
};

export const deleteVoucherTime = (voucherId) => {
  return {
    types: [DELETE_VOUCHER_TIME_REQUESTED, DELETE_VOUCHER_TIME_SUCCESS, DELETE_VOUCHER_TIME_FAILURE],
    promise: client => client.post(`deleteVoucherTime/${voucherId}`)
  };
};

export const deleteVoucherImage = (voucherId) => {
  return {
    types: [DELETE_VOUCHER_IMAGE_REQUESTED, DELETE_VOUCHER_IMAGE_SUCCESS, DELETE_VOUCHER_IMAGE_FAILURE],
    promise: client => client.post(`voucherImage/${voucherId}/delete`)
  };
};


export const addVoucherImage = (voucherId, data) => {
  return {
    types: [ADD_VOUCHER_IMAGE_REQUESTED, ADD_VOUCHER_IMAGE_SUCCESS, ADD_VOUCHER_IMAGE_FAILURE],
    promise: client => client.post(`voucherImage/${voucherId}/save`, {
      data,
    }),
  };
};

export const setVoucherListInputs = (data) => {
  return {
    type: SET_VOUCHERS_LIST_INPUTS,
    payload: data,
  };
};
export const getVoucherSendModes = () => {
  return {
    types: [GET_VOUCHERS_SEND_MODES_REQUESTED, GET_VOUCHERS_SEND_MODES_SUCCESS, GET_VOUCHERS_SEND_MODES_FAILURE],
    promise: client => client.get('getVoucherSendModes')
  };
};
