export const GET_TICKET_REASONS_REQUESTED = 'tickets/GET_TICKET_REASONS_REQUESTED';
export const GET_TICKET_REASONS_SUCCESS = 'tickets/GET_TICKET_REASONS_SUCCESS';
export const GET_TICKET_REASONS_FAILURE = 'tickets/GET_TICKET_REASONS_FAILURE';

export const GET_BUSINESS_DATE_REQUESTED = 'tickets/GET_BUSINESS_DATE_REQUESTED';
export const GET_BUSINESS_DATE_SUCCESS = 'tickets/GET_BUSINESS_DATE_SUCCESS';
export const GET_BUSINESS_DATE_FAILURE = 'tickets/GET_BUSINESS_DATE_FAILURE';

export const PROCESS_REFUND_REQUESTED = 'tickets/PROCESS_REFUND_REQUESTED';
export const PROCESS_REFUND_SUCCESS = 'tickets/PROCESS_REFUND_SUCCESS';
export const PROCESS_REFUND_FAILURE = 'tickets/PROCESS_REFUND_FAILURE';

export const PROCESS_REPOST_REQUESTED = 'tickets/PROCESS_REPOST_REQUESTED';
export const PROCESS_REPOST_SUCCESS = 'tickets/PROCESS_REPOST_SUCCESS';
export const PROCESS_REPOST_FAILURE = 'tickets/PROCESS_REPOST_FAILURE';

export const CHARGEBACK_REQUESTED = 'tickets/CHARGEBACK_REQUESTED';
export const CHARGEBACK_SUCCESS = 'tickets/CHARGEBACK_SUCCESS';
export const CHARGEBACK_FAILURE = 'tickets/CHARGEBACK_FAILURE';

export const GET_CHARGEBACK_REASONS_REQUESTED = 'tickets/GET_CHARGEBACK_REASONS_REQUESTED';
export const GET_CHARGEBACK_REASONS_SUCCESS = 'tickets/GET_CHARGEBACK_REASONS_SUCCESS';
export const GET_CHARGEBACK_REASONS_FAILURE = 'tickets/GET_CHARGEBACK_REASONS_FAILURE';

export const BLOCK_USER_REQUESTED = 'tickets/BLOCK_USER_REQUESTED';
export const BLOCK_USER_SUCCESS = 'tickets/BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'tickets/BLOCK_USER_FAILURE';

export const MANAGER_PROCESS_REFUND_REQUESTED = 'tickets/MANAGER_PROCESS_REFUND_REQUESTED';
export const MANAGER_PROCESS_REFUND_SUCCESS = 'tickets/MANAGER_PROCESS_REFUND_SUCCESS';
export const MANAGER_PROCESS_REFUND_FAILURE = 'tickets/MANAGER_PROCESS_REFUND_FAILURE';

export const MANAGER_CHARGEBACK_REQUESTED = 'tickets/MANAGER_CHARGEBACK_REQUESTED';
export const MANAGER_CHARGEBACK_SUCCESS = 'tickets/MANAGER_CHARGEBACK_SUCCESS';
export const MANAGER_CHARGEBACK_FAILURE = 'tickets/MANAGER_CHARGEBACK_FAILURE';

export const MANAGER_BLOCK_USER_REQUESTED = 'tickets/MANAGER_BLOCK_USER_REQUESTED';
export const MANAGER_BLOCK_USER_SUCCESS = 'tickets/MANAGER_BLOCK_USER_SUCCESS';
export const MANAGER_BLOCK_USER_FAILURE = 'tickets/MANAGER_BLOCK_USER_FAILURE';
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  ticketReasonList: [],
  businessDate: null,
  chargebackReasonList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_TICKET_REASONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TICKET_REASONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ticketReasonList: action.result,
      };
    }
    case GET_TICKET_REASONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BUSINESS_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BUSINESS_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        businessDate: action.result,
      };
    }
    case GET_BUSINESS_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case PROCESS_REFUND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case PROCESS_REFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBusinessDt: action.result,
      };
    }
    case PROCESS_REFUND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case PROCESS_REPOST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case PROCESS_REPOST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBusinessDt: action.result,
      };
    }
    case PROCESS_REPOST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHARGEBACK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case CHARGEBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CHARGEBACK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHARGEBACK_REASONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        chargebackReasonList: [],
      };
    }
    case GET_CHARGEBACK_REASONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chargebackReasonList: action.result,
      };
    }
    case GET_CHARGEBACK_REASONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        chargebackReasonList: [],
      };
    }
    case BLOCK_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case BLOCK_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case BLOCK_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MANAGER_PROCESS_REFUND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case MANAGER_PROCESS_REFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBusinessDt: action.result,
      };
    }
    case MANAGER_PROCESS_REFUND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MANAGER_CHARGEBACK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case MANAGER_CHARGEBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case MANAGER_CHARGEBACK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MANAGER_BLOCK_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case MANAGER_BLOCK_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case MANAGER_BLOCK_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getTicketReasons = (data) => {
  return {
    types: [GET_TICKET_REASONS_REQUESTED, GET_TICKET_REASONS_SUCCESS, GET_TICKET_REASONS_FAILURE],
    promise: client => client.get('getTicketReasons', { data })
  };
};

export const getBusinessDate = (storeId) => {
  return {
    types: [GET_BUSINESS_DATE_REQUESTED, GET_BUSINESS_DATE_SUCCESS, GET_BUSINESS_DATE_FAILURE],
    promise: client => client.get(`getBusinessDate/${storeId}`)
  };
};

export const processRefund = (data) => {
  return {
    types: [PROCESS_REFUND_REQUESTED, PROCESS_REFUND_SUCCESS, PROCESS_REFUND_FAILURE],
    promise: client => client.post('processRefund', { data })
  };
};

export const processRepost = (data) => {
  return {
    types: [PROCESS_REPOST_REQUESTED, PROCESS_REPOST_SUCCESS, PROCESS_REPOST_FAILURE],
    promise: client => client.post('processRepost', { data })
  };
};

export const recordingChargeback = (data) => {
  return {
    types: [CHARGEBACK_REQUESTED, CHARGEBACK_SUCCESS, CHARGEBACK_FAILURE],
    promise: client => client.post('recordingChargeback', { data })
  };
};

export const getChargebackReasons = () => {
  return {
    types: [GET_CHARGEBACK_REASONS_REQUESTED, GET_CHARGEBACK_REASONS_SUCCESS, GET_CHARGEBACK_REASONS_FAILURE],
    promise: client => client.get('getChargebackReasons'),
  };
};

export const blockingUsers = (data) => {
  return {
    types: [BLOCK_USER_REQUESTED, BLOCK_USER_SUCCESS, BLOCK_USER_FAILURE],
    promise: client => client.post('blockingUsers', { data }),
  };
};
export const managerProcessRefund = (data) => {
  return {
    types: [MANAGER_PROCESS_REFUND_REQUESTED, MANAGER_PROCESS_REFUND_SUCCESS, MANAGER_PROCESS_REFUND_FAILURE],
    promise: client => client.post('managerProcessRefund', { data })
  };
};
export const managerRecordingChargeback = (data) => {
  return {
    types: [MANAGER_CHARGEBACK_REQUESTED, MANAGER_CHARGEBACK_SUCCESS, MANAGER_CHARGEBACK_FAILURE],
    promise: client => client.post('managerRecordingChargeback', { data })
  };
};
export const managerBlockingUsers = (data) => {
  return {
    types: [MANAGER_BLOCK_USER_REQUESTED, MANAGER_BLOCK_USER_SUCCESS, MANAGER_BLOCK_USER_FAILURE],
    promise: client => client.post('managerBlockingUsers', { data }),
  };
};
