const currentReducer = 'dataRequest';
const GET_DATA_REQUEST = `${currentReducer}/GET_DATA_REQUEST`;
const GET_DATA_SUCCESS = `${currentReducer}/GET_DATA_SUCCESS`;
const GET_DATA_FAILURE = `${currentReducer}/GET_DATA_FAILURE`;

const GET_DATA_RESOLUTIONS_REQUEST = `${currentReducer}/GET_DATA_RESOLUTIONS_REQUEST`;
const GET_DATA_RESOLUTIONS_SUCCESS = `${currentReducer}/GET_DATA_RESOLUTIONS_SUCCESS`;
const GET_DATA_RESOLUTIONS_FAILURE = `${currentReducer}/GET_DATA_RESOLUTIONS_FAILURE`;

const GET_DATA_REQUEST_BY_ID_REQUESTED = `${currentReducer}/GET_DATA_REQUEST_BY_ID_REQUESTED`;
const GET_DATA_REQUEST_BY_ID_SUCCESS = `${currentReducer}/GET_DATA_REQUEST_BY_ID_SUCCESS`;
const GET_DATA_REQUEST_BY_ID_FAILURE = `${currentReducer}/GET_DATA_REQUEST_BY_ID_FAILURE`;

const UPDATE_DATA_REQUESTED = `${currentReducer}/UPDATE_DATA_REQUESTED`;
const UPDATE_DATA_SUCCESS = `${currentReducer}/UPDATE_DATA_SUCCESS`;
const UPDATE_DATA_FAILURE = `${currentReducer}/UPDATE_DATA_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  dataRequestList: [],
  dataRequestById: [],
  dataResolutionList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        dataRequestList: []
      };
    }
    case GET_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        dataRequestList: action.result
      };
    }
    case GET_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_DATA_RESOLUTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        dataRequestList: []
      };
    }
    case GET_DATA_RESOLUTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        dataResolutionList: action.result
      };
    }
    case GET_DATA_RESOLUTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_DATA_REQUEST_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_DATA_REQUEST_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        dataRequestById: action.result
      };
    }
    case GET_DATA_REQUEST_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getDataRequest = (data) => {
  const params = {
    pending: data.Pending,
    complete: data.Complete,
    error: data.Error
  };
  return {
    types: [GET_DATA_REQUEST, GET_DATA_SUCCESS, GET_DATA_FAILURE],
    promise: client => client.get('getDataRequest', { params })
  };
};

export const getDataRequestById = (id) => {
  return {
    types: [GET_DATA_REQUEST_BY_ID_REQUESTED, GET_DATA_REQUEST_BY_ID_SUCCESS, GET_DATA_REQUEST_BY_ID_FAILURE],
    promise: client => client.get(`getDataRequestById/${id}`)
  };
};

export const updateDataRequest = (data) => {
  return {
    types: [UPDATE_DATA_REQUESTED, UPDATE_DATA_SUCCESS, UPDATE_DATA_FAILURE],
    promise: client => client.post('updateDataRequest', { data })
  };
};

export const getDataResolutions = () => {
  return {
    types: [GET_DATA_RESOLUTIONS_REQUEST, GET_DATA_RESOLUTIONS_SUCCESS, GET_DATA_RESOLUTIONS_FAILURE],
    promise: client => client.get('dataResolutions')
  };
};
