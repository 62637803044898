export const GET_MODIFIERS_BY_BRAND_ID_REQUESTED = 'menuItem/GET_MODIFIERS_BY_BRAND_ID_REQUESTED';
export const GET_MODIFIERS_BY_BRAND_ID_SUCCESS = 'menuItem/GET_MODIFIERS_BY_BRAND_ID_SUCCESS';
export const GET_MODIFIERS_BY_BRAND_ID_FAILURE = 'menuItem/GET_MODIFIERS_BY_BRAND_ID_FAILURE';

export const GET_MODIFIER_GROUPS_BY_BRAND_ID_REQUESTED = 'menuItem/GET_MODIFIER_GROUPS_BY_BRAND_ID_REQUESTED';
export const GET_MODIFIER_GROUPS_BY_BRAND_ID_SUCCESS = 'menuItem/GET_MODIFIER_GROUPS_BY_BRAND_ID_SUCCESS';
export const GET_MODIFIER_GROUPS_BY_BRAND_ID_FAILURE = 'menuItem/GET_MODIFIER_GROUPS_BY_BRAND_ID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  modifiersByBrandId: [],
  modifierGroupsByBrandId: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MODIFIERS_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIERS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        modifiersByBrandId: action.result,
      };
    }
    case GET_MODIFIERS_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MODIFIER_GROUPS_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIER_GROUPS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        modifierGroupsByBrandId: action.result,
      };
    }
    case GET_MODIFIER_GROUPS_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getModifiersByBrandId = (brandId) => {
  return {
    types: [GET_MODIFIERS_BY_BRAND_ID_REQUESTED, GET_MODIFIERS_BY_BRAND_ID_SUCCESS, GET_MODIFIERS_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getModifiersByBrandId/${brandId}`)
  };
};

export const getModifierGroupByBrandId = (brandId) => {
  return {
    types: [GET_MODIFIER_GROUPS_BY_BRAND_ID_REQUESTED, GET_MODIFIER_GROUPS_BY_BRAND_ID_SUCCESS, GET_MODIFIER_GROUPS_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getModifierGroupByBrandId/${brandId}`)
  };
};
