export const GET_PERMISSIONS_TEMPLATES_REQUESTED = 'authRoleTemplates/GET_PERMISSIONS_TEMPLATES_REQUESTED';
export const GET_PERMISSIONS_TEMPLATES_SUCCESS = 'authRoleTemplates/GET_TEMPLATES_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_TEMPLATES_FAILURE = 'authRoleTemplates/GET_TEMPLATES_PERMISSIONS_FAILURE';

export const UPDATE_PERMISSIONS_TEMPLATES_REQUESTED = 'authRoleTemplates/UPDATE_PERMISSIONS_TEMPLATES_REQUESTED';
export const UPDATE_PERMISSIONS_TEMPLATES_SUCCESS = 'authRoleTemplates/UPDATE_PERMISSIONS_TEMPLATES_SUCCESS';
export const UPDATE_PERMISSIONS_TEMPLATES_FAILURE = 'authRoleTemplates/UPDATE_PERMISSIONS_TEMPLATES_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  permissionsTemplate: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_TEMPLATES_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        permissionsTemplate: []
      };
    case GET_PERMISSIONS_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        permissionsTemplate: action.result,
      };
    case GET_PERMISSIONS_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    case UPDATE_PERMISSIONS_TEMPLATES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_PERMISSIONS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PERMISSIONS_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getPermissionsTemplates = () => {
  return {
    types: [GET_PERMISSIONS_TEMPLATES_REQUESTED, GET_PERMISSIONS_TEMPLATES_SUCCESS, GET_PERMISSIONS_TEMPLATES_FAILURE],
    promise: client => client.get('getPermissionsTemplates')
  };
};

export const updatePermissionsTemplate = (data) => {
  return {
    types: [UPDATE_PERMISSIONS_TEMPLATES_REQUESTED, UPDATE_PERMISSIONS_TEMPLATES_SUCCESS, UPDATE_PERMISSIONS_TEMPLATES_FAILURE],
    promise: client => client.post('updatePermissionsTemplate', { data })
  };
};
