import loginScreen from '@assets/serverappscreen/LoginPage.png';
import paymentScreen from '@assets/serverappscreen/PaymentScreen.png';
import TableCreationScreen from '@assets/serverappscreen/TableTopAssignTableScreen.png';
import TableTopLandingScreen from '@assets/serverappscreen/TableTopLandingScreen.png';
import TabletTopOrdersScreen from '@assets/serverappscreen/TabletTopOrdersScreen.png';
import TableTopItemDetailsScreen from '@assets/serverappscreen/TableTopItemDetailsScreen.png';
import TableTopLoyaltyScreen from '@assets/serverappscreen/TableTopLoyaltyScreen.png';

export default {
  LoginPage: {
    screenLabel: 'Login Screen',
    image: [loginScreen],
    customLabels: [
      {
        defaultText: 'LOGIN',
        key: 'label.LOGIN',
        type: 'label',
        value: 'LOGIN',
      },
      {
        defaultText: 'ACTIVE INGENICO DEVICE',
        key: 'label.ACTIVE_INGENICO_DEVICE',
        type: 'label',
        value: 'ACTIVE_INGENICO_DEVICE',
      },
      {
        defaultText: 'CONTACT',
        key: 'label.CONTACT',
        type: 'label',
        value: 'CONTACT',
      },
      {
        defaultText: 'FOR SUPPORT',
        key: 'label.FOR_SUPPORT',
        type: 'label',
        value: 'FOR_SUPPORT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CANCEL',
        type: 'label',
        value: 'CANCEL',
      },
      {
        defaultText: 'Succesfully Logged In',
        key: 'customMessage.LOGIN_SUCCESS',
        type: 'customMessage',
        value: 'LOGIN_SUCCESS',
      },
      {
        defaultText: 'Device Activated Successfully',
        key: 'customMessage.ACT_SUCCESS',
        type: 'customMessage',
        value: 'ACT_SUCCESS',
      },
      {
        defaultText: 'No Notification Found',
        key: 'customMessage.NO_NOTIFICATION',
        type: 'customMessage',
        value: 'NO_NOTIFICATION',
      },
      {
        defaultText: 'Do you wish to re-activate with different activation code?',
        key: 'customMessage.REACTIVATE_WITH_NEW_CODE',
        type: 'customMessage',
        value: 'REACTIVATE_WITH_NEW_CODE',
      },
      {
        defaultText: 'Enter your verification code',
        key: 'customMessage.ENTER_VERIFICATION_CODE',
        type: 'customMessage',
        value: 'ENTER_VERIFICATION_CODE',
      },
    ]
  },
  TableTopPage: {
    screenLabel: 'Table Selection Screen',
    image: [TableCreationScreen],
    customLabels: [
      {
        defaultText: 'Enter Table Number',
        key: 'customMessage.ENTER_TABLE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_TABLE_NUMBER',
      },
      {
        defaultText: 'Assign Table',
        key: 'label.ASSIGN_TABLE',
        type: 'label',
        value: 'ASSIGN_TABLE',
      },
    ]
  },
  TableTopLandingPage: {
    screenLabel: 'Landing Screen',
    image: [TableTopLandingScreen],
    customLabels: [
      {
        defaultText: 'Start Order',
        key: 'label.START_NEW_ORDER',
        type: 'label',
        value: 'START_NEW_ORDER',
      },
      {
        defaultText: 'Make Payment',
        key: 'label.MAKE_PAYMENT',
        type: 'label',
        value: 'MAKE_PAYMENT',
      },
      {
        defaultText: 'Order Items',
        key: 'label.ORDER_ITEMS',
        type: 'label',
        value: 'ORDER_ITEMS',
      },
      {
        defaultText: 'No Items Found',
        key: 'label.NO_ITEMS_ADDED',
        type: 'label',
        value: 'NO_ITEMS_ADDED',
      },
      {
        defaultText: 'Edit Items',
        key: 'label.EDIT_ITEMS',
        type: 'label',
        value: 'EDIT_ITEMS',
      },
      {
        defaultText: 'UNSENT ITEMS',
        key: 'label.UNSENT_ITEMS',
        type: 'label',
        value: 'UNSENT_ITEMS',
      },
      {
        defaultText: 'CHECK NOT AVAILABLE',
        key: 'customMessage.NO_CHECK_AVAILABLE',
        type: 'customMessage',
        value: 'NO_CHECK_AVAILABLE',
      },
    ]
  },
  TableTopOrdersAndCartPage: {
    screenLabel: 'Order & Cart Screen',
    image: [TabletTopOrdersScreen],
    customLabels: [
      {
        defaultText: 'Delete',
        key: 'label.DELETE',
        type: 'label',
        value: 'DELETE',
      },
      {
        defaultText: 'Delete All',
        key: 'label.DELETE_ALL',
        type: 'label',
        value: 'DELETE_ALL',
      },
      {
        defaultText: 'Send All',
        key: 'label.SEND_ALL',
        type: 'label',
        value: 'SEND_ALL',
      },
      {
        defaultText: 'Edit',
        key: 'label.EDIT',
        type: 'label',
        value: 'EDIT',
      },
      {
        defaultText: 'Modify',
        key: 'label.MODIFY',
        type: 'label',
        value: 'MODIFY',
      },
      {
        defaultText: 'ORDER ANOTHER',
        key: 'label.ORDER_ANOTHER',
        type: 'label',
        value: 'ORDER_ANOTHER',
      },
      {
        defaultText: 'MORE_INFO',
        key: 'label.More Info',
        type: 'label',
        value: 'MORE_INFO',
      },
      {
        defaultText: 'Order',
        key: 'label.ORDER',
        type: 'label',
        value: 'ORDER',
      },
      {
        defaultText: 'No',
        key: 'label.NO',
        type: 'label',
        value: 'NO',
      },
      {
        defaultText: 'Yes',
        key: 'label.YES',
        type: 'label',
        value: 'YES',
      },
      {
        defaultText: 'Search',
        key: 'label.SEARCH',
        type: 'label',
        value: 'SEARCH',
      },
      {
        defaultText: 'This will delete all items  from cart, do you want to continue ?',
        key: 'customMessage.DELETE_ALL_UNSENT_MSG',
        type: 'customMessage',
        value: 'DELETE_ALL_UNSENT_MSG',
      },
      {
        defaultText: 'This will delete item from the cart. Do you want to continue ?',
        key: 'customMessage.SINGLE_DELETE_MSG',
        type: 'customMessage',
        value: 'SINGLE_DELETE_MSG',
      },
      {
        defaultText: 'This will send all items from the cart to the kitchen, do you want to continue ?',
        key: 'customMessage.SEND_ALL_UNSENT_MSG',
        type: 'customMessage',
        value: 'SEND_ALL_UNSENT_MSG',
      },
      {
        defaultText: 'This will cancel the order. Do you want to continue? ',
        key: 'customMessage.CANCEL_ORDER',
        type: 'customMessage',
        value: 'CANCEL_ORDER',
      },
    ]
  },
  TableTopItemDetailsPage: {
    screenLabel: 'Item Details Screen',
    image: [TableTopItemDetailsScreen],
    customLabels: [
      {
        defaultText: 'Add to Cart',
        key: 'label.ADD_TO_CART',
        type: 'label',
        value: 'ADD_TO_CART',
      },
      {
        defaultText: 'Update Cart',
        key: 'label.UPDATE_CART',
        type: 'label',
        value: 'UPDATE_CART',
      },
      {
        defaultText: 'Add a note',
        key: 'label.ADD_A_NOTE',
        type: 'label',
        value: 'ADD_A_NOTE',
      },
      {
        defaultText: 'SPECIAL INSTRUCTIONS',
        key: 'label.SPECIAL_INSTRUCTIONS',
        type: 'label',
        value: 'SPECIAL_INSTRUCTIONS',
      },
      {
        defaultText: 'Add to Bag',
        key: 'label.ADD_TO_BAG',
        type: 'label',
        value: 'ADD_TO_BAG',
      },
      {
        defaultText: 'Additional Time',
        key: 'label.ADDITIONAL_TIME',
        type: 'label',
        value: 'ADDITIONAL_TIME',
      },
      {
        defaultText: 'Time restricted',
        key: 'label.TIME_RESTRICTED',
        type: 'label',
        value: 'TIME_RESTRICTED',
      },
      {
        defaultText: 'View Only',
        key: 'label.VIEW_ONLY',
        type: 'label',
        value: 'VIEW_ONLY',
      },
      {
        defaultText: 'Special',
        key: 'label.SPECIAL',
        type: 'label',
        value: 'SPECIAL',
      },
      {
        defaultText: 'Coming soon',
        key: 'label.COMING_SOON',
        type: 'label',
        value: 'COMING_SOON',
      },
      {
        defaultText: 'Limited quantity',
        key: 'label.LIMITED_QUANTITY',
        type: 'label',
        value: 'LIMITED_QUANTITY',
      },
      {
        defaultText: 'Featured',
        key: 'label.FEATURED',
        type: 'label',
        value: 'FEATURED',
      },
      {
        defaultText: 'Out of Stock',
        key: 'label.OUT_OF_STOCK',
        type: 'label',
        value: 'OUT_OF_STOCK',
      },
      {
        defaultText: 'Limited Time',
        key: 'label.LIMITED_TIME',
        type: 'label',
        value: 'LIMITED_TIME',
      },
      {
        defaultText: 'New Item',
        key: 'label.NEW_ITEM',
        type: 'label',
        value: 'NEW_ITEM',
      },
      {
        defaultText: 'Item Facts',
        key: 'label.ITEM_FACTS',
        type: 'label',
        value: 'ITEM_FACTS',
      },
      {
        defaultText: 'Nutrition facts',
        key: 'label.NUTRITION_FACT',
        type: 'label',
        value: 'NUTRITION_FACT',
      },
    ]
  },
  TableTopLoyaltyPage: {
    screenLabel: 'Loyalty Screen',
    image: [TableTopLoyaltyScreen],
    customLabels: [
      {
        defaultText: 'Would you like to join our loyalty program today?',
        key: 'customMessage.LOYALTY_JOIN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_JOIN_MESSAGE',
      },
      {
        defaultText: 'Already a Member',
        key: 'label.ALREADY_A_MEMBER',
        type: 'label',
        value: 'ALREADY_A_MEMBER',
      },
      {
        defaultText: 'Join now',
        key: 'label.JOIN_NOW',
        type: 'label',
        value: 'JOIN_NOW',
      },
      {
        defaultText: 'Not now',
        key: 'label.NOT_NOW',
        type: 'label',
        value: 'NOT_NOW',
      },
      {
        defaultText: 'AccountNumber',
        key: 'label.ACCOUNT_NUMBER',
        type: 'label',
        value: 'ACCOUNT_NUMBER',
      },
      {
        defaultText: 'No offers Available',
        key: 'label.NO_OFFERS',
        type: 'label',
        value: 'NO_OFFERS',
      },
      {
        defaultText: 'Available offers',
        key: 'label.AVAILABLE_OFFERS',
        type: 'label',
        value: 'AVAILABLE_OFFERS',
      },
      {
        defaultText: 'Apply Offer',
        key: 'label.APPLY_OFFER',
        type: 'label',
        value: 'APPLY_OFFER',
      },
      {
        defaultText: 'Skip Offer',
        key: 'label.SKIP_OFFER',
        type: 'label',
        value: 'SKIP_OFFER',
      },
      {
        defaultText: 'QR code',
        key: 'label.QR_CODE',
        type: 'label',
        value: 'QR_CODE',
      },
      {
        defaultText: 'How would you like to join today?',
        key: 'customMessage.LOYALTY_JOIN_IN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_JOIN_IN_MESSAGE',
      },
      {
        defaultText: 'Gift card read successfully',
        key: 'customMessage.SUCCESSFULLY_READ_GIFT_CARD',
        type: 'customMessage',
        value: 'SUCCESSFULLY_READ_GIFT_CARD',
      },
      {
        defaultText: 'How would you like to sign in today?',
        key: 'customMessage.LOYALTY_SIGN_IN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_SIGN_IN_MESSAGE',
      },
      {
        defaultText: 'Use Coupon',
        key: 'label.USE_COUPON',
        type: 'label',
        value: 'USE_COUPON',
      },
      {
        defaultText: 'Cancel',
        key: 'label.COUPON_CANCEL',
        type: 'label',
        value: 'COUPON_CANCEL',
      },
      {
        defaultText: 'Apply Code',
        key: 'label.APPLY_CODE',
        type: 'label',
        value: 'APPLY_CODE',
      },
      {
        defaultText: 'Account',
        key: 'label.LOYALTY_ACCOUNT',
        type: 'label',
        value: 'LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Points',
        key: 'label.POINTS',
        type: 'label',
        value: 'POINTS',
      },
      {
        defaultText: 'Tier',
        key: 'label.TIER',
        type: 'label',
        value: 'TIER',
      },
      {
        defaultText: 'Enter Coupon Code',
        key: 'customMessage.ENTER_COUPON_CODE',
        type: 'customMessage',
        value: 'ENTER_COUPON_CODE',
      },
    ]
  },
  PaymentPage: {
    screenLabel: 'Payment & Receipt Screen',
    image: [paymentScreen],
    customLabels: [
      {
        defaultText: 'Pay An Amount',
        key: 'label.PAY_AN_AMOUNT',
        type: 'label',
        value: 'PAY_AN_AMOUNT',
      },
      {
        defaultText: 'Split Evenly',
        key: 'label.SPLIT_EVENLY',
        type: 'label',
        value: 'SPLIT_EVENLY',
      },
      {
        defaultText: 'SPLIT BY',
        key: 'label.SPLIT_BY',
        type: 'label',
        value: 'SPLIT_BY',
      },
      {
        defaultText: 'Pay Full Check',
        key: 'label.PAY_FULL_CHECK',
        type: 'label',
        value: 'PAY_FULL_CHECK',
      },
      {
        defaultText: 'CONTINUE',
        key: 'label.CONTINUE',
        type: 'label',
        value: 'CONTINUE',
      },
      {
        defaultText: 'Gift Card',
        key: 'label.GIFT_CARD',
        type: 'label',
        value: 'GIFT_CARD',
      },
      {
        defaultText: 'Credit Card',
        key: 'label.CREDIT_CARD',
        type: 'label',
        value: 'CREDIT_CARD',
      },
      {
        defaultText: 'Signature',
        key: 'label.SIGNATURE',
        type: 'label',
        value: 'SIGNATURE',
      },
      {
        defaultText: 'No Receipt',
        key: 'label.NO_RECEIPT',
        type: 'label',
        value: 'NO_RECEIPT',
      },
      {
        defaultText: 'Click on Continue to pay remaining balance',
        key: 'customMessage.CONTINUE_PAY_REMAINING_BALANCE',
        type: 'customMessage',
        value: 'CONTINUE_PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Enter Employee Code',
        key: 'customMessage.ENTER_EMPLOYEE_CODE',
        type: 'customMessage',
        value: 'ENTER_EMPLOYEE_CODE',
      },
      {
        defaultText: 'Come back to see us again soon',
        key: 'customMessage.COME_BACK_SOON',
        type: 'customMessage',
        value: 'COME_BACK_SOON',
      },
      {
        defaultText: 'Click To Swipe Card',
        key: 'label.SWIPE_CARD',
        type: 'label',
        value: 'SWIPE_CARD',
      },
      {
        defaultText: 'Click To Enter Card Number',
        key: 'customMessage.ENTER_CARD_NUMBER',
        type: 'customMessage',
        value: 'ENTER_CARD_NUMBER',
      },
      {
        defaultText: 'Click To Enter Security code',
        key: 'customMessage.ENTER_SECURITY_CODE',
        type: 'customMessage',
        value: 'ENTER_SECURITY_CODE',
      },
      {
        defaultText: 'Cash',
        key: 'label.CASH',
        type: 'label',
        value: 'CASH',
      },
      {
        defaultText: 'Pay Remaining Balance',
        key: 'label.PAY_REMAINING_BALANCE',
        type: 'label',
        value: 'PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Done',
        key: 'label.DONE',
        type: 'label',
        value: 'DONE',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.SUB_TOTAL',
        type: 'label',
        value: 'SUB_TOTAL',
      },
      {
        defaultText: 'Tax',
        key: 'label.TAX',
        type: 'label',
        value: 'TAX',
      },
      {
        defaultText: 'VAT',
        key: 'label.VAT',
        type: 'label',
        value: 'VAT',
      },
      {
        defaultText: 'Payment',
        key: 'label.PAYMENT',
        type: 'label',
        value: 'PAYMENT',
      },
      {
        defaultText: 'Delivery',
        key: 'label.DELIVERY',
        type: 'label',
        value: 'DELIVERY',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP',
        type: 'label',
        value: 'TIP',
      },
      {
        defaultText: 'PAYMENT COMPLETED',
        key: 'label.PAYMENT_COMPLETED',
        type: 'label',
        value: 'PAYMENT_COMPLETED',
      },
      {
        defaultText: 'PAYMENT FAILED',
        key: 'label.PAYMENT_FAILED',
        type: 'label',
        value: 'PAYMENT_FAILED',
      },
      {
        defaultText: 'Skip Survey',
        key: 'label.SKIP_SURVEY',
        type: 'label',
        value: 'SKIP_SURVEY',
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'label.ENTER_MOBILE_NUMBER',
        type: 'label',
        value: 'ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Enter Account Number',
        key: 'label.ENTER_ACCOUNT_NUMBER',
        type: 'label',
        value: 'ENTER_ACCOUNT_NUMBER',
      },
      {
        defaultText: 'Enter Table Name',
        key: 'label.ENTER_TABLE_NAME',
        type: 'label',
        value: 'ENTER_TABLE_NAME',
      },
      {
        defaultText: 'Text To Pay',
        key: 'label.TEXT_TO_PAY',
        type: 'label',
        value: 'TEXT_TO_PAY',
      },
      {
        defaultText: 'UNPAID',
        key: 'label.UNPAID',
        type: 'label',
        value: 'UNPAID',
      },
      {
        defaultText: 'Price Check',
        key: 'label.PRICE_CHECK',
        type: 'label',
        value: 'PRICE_CHECK',
      },
      {
        defaultText: 'Confirm',
        key: 'label.CONFIRM',
        type: 'label',
        value: 'CONFIRM',
      },
      {
        defaultText: 'TOTAL DUE',
        key: 'label.PAYEMENT_TOTAL_DUE',
        type: 'label',
        value: 'PAYEMENT_TOTAL_DUE',
      },
      {
        defaultText: 'Total',
        key: 'label.PAYMENT_TOTAL',
        type: 'label',
        value: 'PAYMENT_TOTAL',
      },
      {
        defaultText: 'Payments',
        key: 'label.PAYMENTS',
        type: 'label',
        value: 'PAYMENTS',
      },
      {
        defaultText: 'Service Charge',
        key: 'label.SERVICE_CHARGE',
        type: 'label',
        value: 'SERVICE_CHARGE',
      },
      {
        defaultText: 'Discount',
        key: 'label.DISCOUNT_AMOUNT',
        type: 'label',
        value: 'DISCOUNT_AMOUNT',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP_AMOUNT',
        type: 'label',
        value: 'TIP_AMOUNT',
      },
      {
        defaultText: 'Pay later',
        key: 'label.PAY_LATER',
        type: 'label',
        value: 'PAY_LATER',
      },
      {
        defaultText: 'PERCENT',
        key: 'label.PERCENT',
        type: 'label',
        value: 'PERCENT',
      },
      {
        defaultText: 'AMOUNT',
        key: 'label.AMOUNT',
        type: 'label',
        value: 'AMOUNT',
      },
      {
        defaultText: 'Pay Check',
        key: 'label.PAY_CHECK',
        type: 'label',
        value: 'PAY_CHECK',
      },
      {
        defaultText: 'Total Paid',
        key: 'label.TOTAL_PAID',
        type: 'label',
        value: 'TOTAL_PAID',
      },
      {
        defaultText: 'Balance',
        key: 'label.BALANCE',
        type: 'label',
        value: 'BALANCE',
      },
      {
        defaultText: 'Pay Now',
        key: 'label.PAY_NOW',
        type: 'label',
        value: 'PAY_NOW',
      },
      {
        defaultText: 'Tips',
        key: 'label.TIPS',
        type: 'label',
        value: 'TIPS',
      },
      {
        defaultText: 'Paid Tip',
        key: 'label.PAID_TIPS',
        type: 'label',
        value: 'PAID_TIPS',
      },
      {
        defaultText: 'Unpaid Tip',
        key: 'label.UNPAID_TIPS',
        type: 'label',
        value: 'UNPAID_TIPS',
      },
      {
        defaultText: 'Discounts',
        key: 'label.DISCOUNTS',
        type: 'label',
        value: 'DISCOUNTS',
      },
      {
        defaultText: 'Location',
        key: 'label.LOCATION',
        type: 'label',
        value: 'LOCATION',
      },
      {
        defaultText: 'Total due',
        key: 'label.TOTAL_DUE',
        type: 'label',
        value: 'TOTAL_DUE',
      },
      {
        defaultText: 'Payments',
        key: 'label.RECEIPT_PAYMENT',
        type: 'label',
        value: 'RECEIPT_PAYMENT',
      },
      {
        defaultText: 'Signature required',
        key: 'customMessage.SIGNATURE_REQUIRED',
        type: 'customMessage',
        value: 'SIGNATURE_REQUIRED',
      },
      {
        defaultText: 'Select tips or enter a valid tip amount or 0',
        key: 'customMessage.ENTER_TIP_MSG',
        type: 'customMessage',
        value: 'ENTER_TIP_MSG',
      },
      {
        defaultText: 'Amount to pay',
        key: 'customMessage.AMOUNT_TO_PAY',
        type: 'customMessage',
        value: 'AMOUNT_TO_PAY',
      },
      {
        defaultText: 'It was great serving you',
        key: 'customMessage.GREAT_SERVING_YOU',
        type: 'customMessage',
        value: 'GREAT_SERVING_YOU',
      },
      {
        defaultText: 'There was error processing your payment',
        key: 'customMessage.PAYMENT_PROCESSING_ERROR',
        type: 'customMessage',
        value: 'PAYMENT_PROCESSING_ERROR',
      },
      {
        defaultText: 'Proceed with cash payment of',
        key: 'customMessage.PROCEED_WITH_CASH_PAYMENT_OF',
        type: 'customMessage',
        value: 'PROCEED_WITH_CASH_PAYMENT_OF',
      },
      {
        defaultText: 'Please swipe card after the beep',
        key: 'customMessage.SWIPE_CARD_AFTER_BEEP',
        type: 'customMessage',
        value: 'SWIPE_CARD_AFTER_BEEP',
      },
      {
        defaultText: 'Type',
        key: 'label.CARD_TYPE',
        type: 'label',
        value: 'CARD_TYPE',
      },
      {
        defaultText: 'Name',
        key: 'label.NAME',
        type: 'label',
        value: 'NAME',
      },
      {
        defaultText: 'Card #',
        key: 'label.REDACTED_CARD_NUMBER',
        type: 'label',
        value: 'REDACTED_CARD_NUMBER',
      },
      {
        defaultText: 'RECEIPT',
        key: 'label.PAYMENT_RECEIPT',
        type: 'label',
        value: 'PAYMENT_RECEIPT',
      },
      {
        defaultText: 'ENTER TIP',
        key: 'label.ENTER_TIP',
        type: 'label',
        value: 'ENTER_TIP',
      },
      {
        defaultText: 'Previous',
        key: 'label.PREVIOUS',
        type: 'label',
        value: 'PREVIOUS',
      },
      {
        defaultText: 'Next',
        key: 'label.NEXT',
        type: 'label',
        value: 'NEXT',
      },
      {
        defaultText: 'Employee Login',
        key: 'label.EMPLOYEE_LOGIN',
        type: 'label',
        value: 'EMPLOYEE_LOGIN',
      },
      {
        defaultText: 'Call Server',
        key: 'label.CALL_SERVER',
        type: 'label',
        value: 'CALL_SERVER',
      },
      {
        defaultText: 'Other Options',
        key: 'label.OTHER_OPTIONS',
        type: 'label',
        value: 'OTHER_OPTIONS',
      },
      {
        defaultText: 'Cash Payment has to be processed by your server. Please select one of the following options',
        key: 'customMessage.CASH_PAYMENT_PROCESS',
        type: 'customMessage',
        value: 'CASH_PAYMENT_PROCESS',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'customMessage.ENTER_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Enter Phone Number',
        key: 'customMessage.ENTER_PHONE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_PHONE_NUMBER',
      },
      {
        defaultText: 'Submit',
        key: 'label.SUBMIT',
        type: 'label',
        value: 'SUBMIT',
      },
      {
        defaultText: 'Email',
        key: 'label.EMAIL',
        type: 'label',
        value: 'EMAIL',
      },
      {
        defaultText: 'Text',
        key: 'label.TEXT',
        type: 'label',
        value: 'TEXT',
      },
      {
        defaultText: 'Stay',
        key: 'label.STAY',
        type: 'label',
        value: 'STAY',
      },
      {
        defaultText: 'You will be logged out for inactivity in',
        key: 'customMessage.INACTIVITY_LOG_OUT',
        type: 'customMessage',
        value: 'INACTIVITY_LOG_OUT',
      },
      {
        defaultText: 'Estimated points earned',
        key: 'label.ESTIMATED_POINTS_EARNED',
        type: 'label',
        value: 'ESTIMATED_POINTS_EARNED',
      },
      {
        defaultText: 'This will delete the loyalty account associated with this check. Do you want to continue?',
        key: 'customMessage.DELETE_LOYALTY_ACCOUNT',
        type: 'customMessage',
        value: 'DELETE_LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Credit card or contactless',
        key: 'label.CREDIT_CARD_OR_CONTACTLESS',
        type: 'label',
        value: 'CREDIT_CARD_OR_CONTACTLESS',
      },
      {
        defaultText: 'Your check total is',
        key: 'label.CHECK_TOTAL_TIP',
        type: 'label',
        value: 'CHECK_TOTAL_TIP',
      },
      {
        defaultText: 'Please select a tip amount',
        key: 'label.PLEASE_SELECT_TIP',
        type: 'label',
        value: 'PLEASE_SELECT_TIP',
      },
      {
        defaultText: 'Continue with new total',
        key: 'label.TIP_CONTINUE',
        type: 'label',
        value: 'TIP_CONTINUE',
      },
      {
        defaultText: 'Custom Amount',
        key: 'label.CUSTOM_AMOUNT',
        type: 'label',
        value: 'CUSTOM_AMOUNT',
      },
      {
        defaultText: 'Custom Percent',
        key: 'label.CUSTOM_PERCENT',
        type: 'label',
        value: 'CUSTOM_PERCENT',
      },
      {
        defaultText: 'Please select your form of payment below',
        key: 'customMessage.SELECT_PAYMENT_TYPE',
        type: 'customMessage',
        value: 'SELECT_PAYMENT_TYPE',
      },
      {
        defaultText: 'Payment is now complete',
        key: 'label.PAYMENT_NOW_COMPLETE',
        type: 'label',
        value: 'PAYMENT_NOW_COMPLETE',
      },
      {
        defaultText: 'How would you like your receipt?',
        key: 'customMessage.SELECT_RECEIPT_TYPE',
        type: 'customMessage',
        value: 'SELECT_RECEIPT_TYPE',
      },
      {
        defaultText: 'PAY',
        key: 'label.STAGE_PAY',
        type: 'label',
        value: 'STAGE_PAY',
      },
      {
        defaultText: 'Please select the check you would like to pay',
        key: 'customMessage.SELECT_CHECK',
        type: 'customMessage',
        value: 'SELECT_CHECK',
      },
      {
        defaultText: 'CASH',
        key: 'label.CASH_PAYMENT',
        type: 'label',
        value: 'CASH_PAYMENT',
      },
      {
        defaultText: 'TOTAL PAID',
        key: 'label.TOTAL_PAYMENT_PAID',
        type: 'label',
        value: 'TOTAL_PAYMENT_PAID',
      },
      {
        defaultText: 'TOTAL DUE',
        key: 'label.TOTAL_DUE_PAYMENT',
        type: 'label',
        value: 'TOTAL_DUE_PAYMENT',
      },
      {
        defaultText: 'NO RECEIPT',
        key: 'label.NO_RECEIPT_OPTION',
        type: 'label',
        value: 'NO_RECEIPT_OPTION',
      },
      {
        defaultText: 'TEXT IT TO ME',
        key: 'label.RECEIPT_TEXT_IT_TO_ME',
        type: 'label',
        value: 'RECEIPT_TEXT_IT_TO_ME',
      },
      {
        defaultText: 'EMAIL IT TO ME',
        key: 'label.RECEIPT_EMAIL_IT_TO_ME',
        type: 'label',
        value: 'RECEIPT_EMAIL_IT_TO_ME',
      },
    ]
  },
};
