export const ADD_ADMIN_TAGS_REQUESTED = 'adminTags/ADD_ADMIN_TAGS_REQUESTED';
export const ADD_ADMIN_TAGS_SUCCESS = 'adminTags/ADD_ADMIN_TAGS_SUCCESS';
export const ADD_ADMIN_TAGS_FAILURE = 'adminTags/ADD_ADMIN_TAGS_FAILURE';

export const UPDATE_ADMIN_TAGS_REQUESTED = 'adminTags/UPDATE_ADMIN_TAGS_REQUESTED';
export const UPDATE_ADMIN_TAGS_SUCCESS = 'adminTags/UPDATE_ADMIN_TAGS_SUCCESS';
export const UPDATE_ADMIN_TAGS_FAILURE = 'adminTags/UPDATE_ADMIN_TAGS_FAILURE';

export const GET_ADMIN_TAGS_TYPES_REQUESTED = 'adminTags/GET_ADMIN_TAGS_TYPES_REQUESTED';
export const GET_ADMIN_TAGS_TYPES_SUCCESS = 'adminTags/GET_ADMIN_TAGS_TYPES_SUCCESS';
export const GET_ADMIN_TAGS_TYPES_FAILURE = 'adminTags/GET_ADMIN_TAGS_TYPES_FAILURE';

export const GET_ADMIN_TAGS_LIST_DATA_REQUESTED = 'adminTags/GET_ADMIN_TAGS_LIST_DATA_REQUESTED';
export const GET_ADMIN_TAGS_LIST_DATA_SUCCESS = 'adminTags/GET_ADMIN_TAGS_LIST_DATA_SUCCESS';
export const GET_ADMIN_TAGS_LIST_DATA_FAILURE = 'adminTags/GET_ADMIN_TAGS_LIST_DATA_FAILURE';

export const IMAGE_ACTION_REQUESTED = 'adminTags/IMAGE_ACTION_REQUESTED';
export const IMAGE_ACTION_SUCCESS = 'adminTags/IMAGE_ACTION_SUCCESS';
export const IMAGE_ACTION_FAILURE = 'adminTags/IMAGE_ACTION_FAILURE';

export const GET_TAG_CATEGORY_LIST_REQUESTED = 'adminTags/GET_TAG_CATEGORY_LIST_REQUESTED';
export const GET_TAG_CATEGORY_LIST_SUCCESS = 'adminTags/GET_TAG_CATEGORY_LIST_SUCCESS';
export const GET_TAG_CATEGORY_LIST_FAILURE = 'adminTags/GET_TAG_CATEGORY_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getAdminTags: null,
  getAdminTagTypes: [],
  tagCategoriesList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADMIN_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_ADMIN_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_ADMIN_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_ADMIN_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ADMIN_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ADMIN_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ADMIN_TAGS_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ADMIN_TAGS_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getAdminTagTypes: action.result,
      };
    }
    case GET_ADMIN_TAGS_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ADMIN_TAGS_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ADMIN_TAGS_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getAdminTagsList: action.result,
      };
    }
    case GET_ADMIN_TAGS_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case IMAGE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TAG_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TAG_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tagCategoriesList: action.result,
      };
    }
    case GET_TAG_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getAdminTagTypes = () => {
  return {
    types: [GET_ADMIN_TAGS_TYPES_REQUESTED, GET_ADMIN_TAGS_TYPES_SUCCESS, GET_ADMIN_TAGS_TYPES_FAILURE],
    promise: client => client.get('adminTagTypes')
  };
};

export const getAdminTagsListData = () => {
  return {
    types: [GET_ADMIN_TAGS_LIST_DATA_REQUESTED, GET_ADMIN_TAGS_LIST_DATA_SUCCESS, GET_ADMIN_TAGS_LIST_DATA_FAILURE],
    promise: client => client.get('adminTagsList')
  };
};

export const addAdminTags = (data) => {
  return {
    types: [ADD_ADMIN_TAGS_REQUESTED, ADD_ADMIN_TAGS_SUCCESS, ADD_ADMIN_TAGS_FAILURE],
    promise: client => client.post('addAdminTags', { data })
  };
};

export const updateAdminTags = (data) => {
  return {
    types: [UPDATE_ADMIN_TAGS_REQUESTED, UPDATE_ADMIN_TAGS_SUCCESS, UPDATE_ADMIN_TAGS_FAILURE],
    promise: client => client.post('updateAdminTags', { data })
  };
};

export const uplaodTagImage = (id, data) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`tagImageSave/${id}/save`, { data })
  };
};

export const deleteTagImage = (id) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`tagImageDelete/${id}/delete`)
  };
};

export const tagCategories = () => {
  return {
    types: [GET_TAG_CATEGORY_LIST_REQUESTED, GET_TAG_CATEGORY_LIST_SUCCESS, GET_TAG_CATEGORY_LIST_FAILURE],
    promise: client => client.get('tagCategories')
  };
};
