const currentReducer = 'campaignScreen';
const GET_CAMPAIGN_REQUESTED = `${currentReducer}/GET_CAMPAIGN_REQUESTED`;
const GET_CAMPAIGN_SUCCESS = `${currentReducer}/GET_CAMPAIGN_SUCCESS`;
const GET_CAMPAIGN_FAILURE = `${currentReducer}/GET_CAMPAIGN_FAILURE`;

const GET_CAMPAIGN_MARKETING_REQUESTED = `${currentReducer}/GET_CAMPAIGN_MARKETING_REQUESTED`;
const GET_CAMPAIGN_MARKETING_SUCCESS = `${currentReducer}/GET_CAMPAIGN_MARKETING_SUCCESS`;
const GET_CAMPAIGN_MARKETING_FAILURE = `${currentReducer}/GET_CAMPAIGN_MARKETING_FAILURE`;

const GET_CAMPAIGN_REWARDS_REQUESTED = `${currentReducer}/GET_CAMPAIGN_REWARDS_REQUESTED`;
const GET_CAMPAIGN_REWARDS_SUCCESS = `${currentReducer}/GET_CAMPAIGN_REWARDS_SUCCESS`;
const GET_CAMPAIGN_REWARDS_FAILURE = `${currentReducer}/GET_CAMPAIGN_REWARDS_FAILURE`;

const GET_MENU_ITEMS_BY_BRAND_ID_REQUESTED = `${currentReducer}/GET_MENU_ITEMS_BY_BRAND_ID_REQUESTED`;
const GET_MENU_ITEMS_BY_BRAND_ID_SUCCESS = `${currentReducer}/GET_MENU_ITEMS_BY_BRAND_ID_SUCCESS`;
const GET_MENU_ITEMS_BY_BRAND_ID_FAILURE = `${currentReducer}/GET_MENU_ITEMS_BY_BRAND_ID_FAILURE`;

const SAVE_CAMPAIGN_REWARD_REQUESTED = 'campaignScreen/SAVE_CAMPAIGN_REWARD_REQUESTED';
const SAVE_CAMPAIGN_REWARD_SUCCESS = 'campaignScreen/SAVE_CAMPAIGN_REWARD_SUCCESS';
const SAVE_CAMPAIGN_REWARD_FAILURE = 'campaignScreen/SAVE_CAMPAIGN_REWARD_FAILURE';

const DELETE_CAMPAIGN_REWARD_REQUESTED = 'campaignScreen/DELETE_CAMPAIGN_REWARD_REQUESTED';
const DELETE_CAMPAIGN_REWARD_SUCCESS = 'campaignScreen/DELETE_CAMPAIGN_REWARD_SUCCESS';
const DELETE_CAMPAIGN_REWARD_FAILURE = 'campaignScreen/DELETE_CAMPAIGN_REWARD_FAILURE';

const GET_CAMPAIGN_OFFER_TYPES_REQUESTED = `${currentReducer}/GET_CAMPAIGN_OFFER_TYPES_REQUESTED`;
const GET_CAMPAIGN_OFFER_TYPES_SUCCESS = `${currentReducer}/GET_CAMPAIGN_OFFER_TYPES_SUCCESS`;
const GET_CAMPAIGN_OFFER_TYPES_FAILURE = `${currentReducer}/GET_CAMPAIGN_OFFER_TYPES_FAILURE`;

const GET_CAMPAIGN_CLASSES_REQUESTED = `${currentReducer}/GET_CAMPAIGN_CLASSES_REQUESTED`;
const GET_CAMPAIGN_CLASSES_SUCCESS = `${currentReducer}/GET_CAMPAIGN_CLASSES_SUCCESS`;
const GET_CAMPAIGN_CLASSES_FAILURE = `${currentReducer}/GET_CAMPAIGN_CLASSES_FAILURE`;

const GET_CAMPAIGN_SUBJECTS_REQUESTED = `${currentReducer}/GET_CAMPAIGN_SUBJECTS_REQUESTED`;
const GET_CAMPAIGN_SUBJECTS_SUCCESS = `${currentReducer}/GET_CAMPAIGN_SUBJECTS_SUCCESS`;
const GET_CAMPAIGN_SUBJECTS_FAILURE = `${currentReducer}/GET_CAMPAIGN_SUBJECTS_FAILURE`;

const ADD_CAMPAIGN_REQUESTED = 'campaignScreen/ADD_CAMPAIGN_REQUESTED';
const ADD_CAMPAIGN_SUCCESS = 'campaignScreen/ADD_CAMPAIGN_SUCCESS';
const ADD_CAMPAIGN_FAILURE = 'campaignScreen/ADD_CAMPAIGN_FAILURE';

const ADD_LOCATION_REQUESTED = 'campaignScreen/ADD_LOCATION_REQUESTED';
const ADD_LOCATION_SUCCESS = 'campaignScreen/ADD_LOCATION_SUCCESS';
const ADD_LOCATION_FAILURE = 'campaignScreen/ADD_LOCATION_FAILURE';

const UPDATE_CAMPAIGN_REQUESTED = 'campaignScreen/UPDATE_CAMPAIGN_REQUESTED';
const UPDATE_CAMPAIGN_SUCCESS = 'campaignScreen/UPDATE_CAMPAIGN_SUCCESS';
const UPDATE_CAMPAIGN_FAILURE = 'campaignScreen/UPDATE_CAMPAIGN_FAILURE';

const DELETE_CAMPAIGN_REQUESTED = 'campaignScreen/DELETE_CAMPAIGN_REQUESTED';
const DELETE_CAMPAIGN_SUCCESS = 'campaignScreen/DELETE_CAMPAIGN_SUCCESS';
const DELETE_CAMPAIGN_FAILURE = 'campaignScreen/DELETE_CAMPAIGN_FAILURE';

const GET_CAMPAIGN_BY_ID_REQUESTED = 'campaignScreen/GET_CAMPAIGN_BY_ID_REQUESTED';
const GET_CAMPAIGN_BY_ID_SUCCESS = 'campaignScreen/GET_CAMPAIGN_BY_ID_SUCCESS';
const GET_CAMPAIGN_BY_ID_FAILURE = 'campaignScreen/GET_CAMPAIGN_BY_ID_FAILURE';

const GET_CATEGORIES_BY_BRAND_ID_REQUESTED = 'campaignScreen/GET_CATEGORIES_BY_BRAND_ID_REQUESTED';
const GET_CATEGORIES_BY_BRAND_ID_SUCCESS = 'campaignScreen/GET_CATEGORIES_BY_BRAND_ID_SUCCESS';
const GET_CATEGORIES_BY_BRAND_ID_FAILURE = 'campaignScreen/GET_CATEGORIES_BY_BRAND_ID_FAILURE';

const GET_PAYMENT_TYPE_REQUESTED = 'campaignScreen/GET_PAYMENT_TYPE_REQUESTED';
const GET_PAYMENT_TYPE_SUCCESS = 'campaignScreen/GET_PAYMENT_TYPE_SUCCESS';
const GET_PAYMENT_TYPE_FAILURE = 'campaignScreen/GET_PAYMENT_TYPE_FAILURE';

const GET_CHANNELS_REQUESTED = 'campaignScreen/GET_CHANNELS_REQUESTED';
const GET_CHANNELS_SUCCESS = 'campaignScreen/GET_CHANNELS_SUCCESS';
const GET_CHANNELS_FAILURE = 'campaignScreen/GET_CHANNELS_FAILURE';

const GET_CAMPAIGN_RULE_TYPES_REQUESTED = 'campaignScreen/GET_CAMPAIGN_RULE_TYPES_REQUESTED';
const GET_CAMPAIGN_RULE_TYPES_SUCCESS = 'campaignScreen/GET_CAMPAIGN_RULE_TYPES_SUCCESS';
const GET_CAMPAIGN_RULE_TYPES_FAILURE = 'campaignScreen/GET_CAMPAIGN_RULE_TYPES_FAILURE';

const GET_REDEMPTION_LIMIT_TYPES_REQUESTED = 'campaignScreen/GET_REDEMPTION_LIMIT_TYPES_REQUESTED';
const GET_REDEMPTION_LIMIT_TYPES_SUCCESS = 'campaignScreen/GET_REDEMPTION_LIMIT_TYPES_SUCCESS';
const GET_REDEMPTION_LIMIT_TYPES_FAILURE = 'campaignScreen/GET_REDEMPTION_LIMIT_TYPES_FAILURE';

const GET_REDEMPTION_METHODS_REQUESTED = 'campaignScreen/GET_REDEMPTION_METHODS_REQUESTED';
const GET_REDEMPTION_METHODS_SUCCESS = 'campaignScreen/GET_REDEMPTION_METHODS_SUCCESS';
const GET_REDEMPTION_METHODS_FAILURE = 'campaignScreen/GET_REDEMPTION_METHODS_FAILURE';

const SAVE_CAMPAIGN_RULE_REQUESTED = 'campaignScreen/SAVE_CAMPAIGN_RULE_REQUESTED';
const SAVE_CAMPAIGN_RULE_SUCCESS = 'campaignScreen/SAVE_CAMPAIGN_RULE_SUCCESS';
const SAVE_CAMPAIGN_RULE_FAILURE = 'campaignScreen/SAVE_CAMPAIGN_RULE_FAILURE';

const DELETE_CAMPAIGN_RULE_REQUESTED = 'campaignScreen/DELETE_CAMPAIGN_RULE_REQUESTED';
const DELETE_CAMPAIGN_RULE_SUCCESS = 'campaignScreen/DELETE_CAMPAIGN_RULE_SUCCESS';
const DELETE_CAMPAIGN_RULE_FAILURE = 'campaignScreen/DELETE_CAMPAIGN_RULE_FAILURE';

const GET_EXTERNAL_CAMPAIGN_REQUESTED = 'campaignScreen/GET_EXTERNAL_CAMPAIGN_REQUESTED';
const GET_EXTERNAL_CAMPAIGN_SUCCESS = 'campaignScreen/GET_EXTERNAL_CAMPAIGN_SUCCESS';
const GET_EXTERNAL_CAMPAIGN_FAILURE = 'campaignScreen/GET_EXTERNAL_CAMPAIGN_FAILURE';

const UPDATE_EXTERNAL_SCRIPT_REQUESTED = 'campaignScreen/UPDATE_EXTERNAL_SCRIPT_REQUESTED';
const UPDATE_EXTERNAL_SCRIPT_SUCCESS = 'campaignScreen/UPDATE_EXTERNAL_SCRIPT_SUCCESS';
const UPDATE_EXTERNAL_SCRIPT_FAILURE = 'campaignScreen/UPDATE_EXTERNAL_SCRIPT_FAILURE';

const GET_OFFERS_TRIGGERS_LIST_REQUESTED = 'campaignScreen/GET_OFFERS_TRIGGERS_LIST_REQUESTED';
const GET_OFFERS_TRIGGERS_LIST_SUCCESS = 'campaignScreen/GET_OFFERS_TRIGGERS_LIST_SUCCESS';
const GET_OFFERS_TRIGGERS_LIST_FAILURE = 'campaignScreen/GET_OFFERS_TRIGGERS_LIST_FAILURE';

const GET_EVENTS_TRIGGERS_LIST_REQUESTED = 'campaignScreen/GET_EVENTS_TRIGGERS_LIST_REQUESTED';
const GET_EVENTS_TRIGGERS_LIST_SUCCESS = 'campaignScreen/GET_EVENTS_TRIGGERS_LIST_SUCCESS';
const GET_EVENTS_TRIGGERS_LIST_FAILURE = 'campaignScreen/GET_EVENTS_TRIGGERS_LIST_FAILURE';

export const IMAGE_ACTION_REQUESTED = 'campaignScreen/IMAGE_ACTION_REQUESTED';
export const IMAGE_ACTION_SUCCESS = 'campaignScreen/IMAGE_ACTION_SUCCESS';
export const IMAGE_ACTION_FAILURE = 'campaignScreen/IMAGE_ACTION_FAILURE';

const GET_CAMPAIGN_EMAIL_TEMPLATES_REQUESTED = 'campaignScreen/GET_CAMPAIGN_EMAIL_TEMPLATES_REQUESTED';
const GET_CAMPAIGN_EMAIL_TEMPLATES_SUCCESS = 'campaignScreen/GET_CAMPAIGN_EMAIL_TEMPLATES_SUCCESS';
const GET_CAMPAIGN_EMAIL_TEMPLATES_FAILURE = 'campaignScreen/GET_CAMPAIGN_EMAIL_TEMPLATES_FAILURE';

const GET_CAMPAIGN_DISCOUNT_MASTER_REQUESTED = 'campaignScreen/GET_CAMPAIGN_DISCOUNT_MASTER_REQUESTED';
const GET_CAMPAIGN_DISCOUNT_MASTER_SUCCESS = 'campaignScreen/GET_CAMPAIGN_DISCOUNT_MASTER_SUCCESS';
const GET_CAMPAIGN_DISCOUNT_MASTER_FAILURE = 'campaignScreen/GET_CAMPAIGN_DISCOUNT_MASTER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  campaignList: [],
  campaignClasses: [],
  marketingCampaignTypes: [],
  offerCampaignTypes: [],
  campaignData: null,
  campaignListData: null,
  campaignRewardsTypesList: [],
  campaignRulesTypesList: [],
  menuItemsByBrandIdList: [],
  categoriesTypeList: [],
  paymentTypeList: [],
  channelsList: [],
  redemptionLimitTypeList: [],
  redemptionMethodsList: [],
  externalCampaignList: [],
  offerTriggerList: [],
  eventTriggerList: [],
  campaignEmailTemplates: [],
  campaignDiscountMaster: [],
  campaignSubjects: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignList: action.result
      };
    }
    case GET_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CAMPAIGN_CLASSES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_CLASSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignClasses: action.result
      };
    }
    case GET_CAMPAIGN_CLASSES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CAMPAIGN_SUBJECTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_SUBJECTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignSubjects: action.result
      };
    }
    case GET_CAMPAIGN_SUBJECTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CAMPAIGN_MARKETING_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_MARKETING_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        marketingCampaignTypes: action.result
      };
    }
    case GET_CAMPAIGN_MARKETING_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CAMPAIGN_REWARDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_REWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignRewardsTypesList: action.result
      };
    }
    case GET_CAMPAIGN_REWARDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MENU_ITEMS_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_MENU_ITEMS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuItemsByBrandIdList: action.result
      };
    }
    case GET_MENU_ITEMS_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case SAVE_CAMPAIGN_REWARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CAMPAIGN_REWARD_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_CAMPAIGN_REWARD_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_REWARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CAMPAIGN_REWARD_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_CAMPAIGN_REWARD_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_CAMPAIGN_OFFER_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_OFFER_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        offerCampaignTypes: action.result
      };
    }
    case GET_CAMPAIGN_OFFER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_LOCATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_LOCATION_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_LOCATION_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_CAMPAIGN_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignListData: action.result,
      };
    }
    case GET_CAMPAIGN_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CAMPAIGN_RULE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_RULE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignRulesTypesList: action.result
      };
    }
    case GET_CAMPAIGN_RULE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CATEGORIES_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CATEGORIES_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        categoriesTypeList: action.result
      };
    }
    case GET_CATEGORIES_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_PAYMENT_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paymentTypeList: action.result
      };
    }
    case GET_PAYMENT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CHANNELS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CHANNELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        channelsList: action.result
      };
    }
    case GET_CHANNELS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_REDEMPTION_LIMIT_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_REDEMPTION_LIMIT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        redemptionLimitTypeList: action.result
      };
    }
    case GET_REDEMPTION_LIMIT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case SAVE_CAMPAIGN_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_CAMPAIGN_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_CAMPAIGN_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CAMPAIGN_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_CAMPAIGN_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_REDEMPTION_METHODS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_REDEMPTION_METHODS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        redemptionMethodsList: action.result
      };
    }
    case GET_REDEMPTION_METHODS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_EXTERNAL_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_EXTERNAL_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        externalCampaignList: action.result
      };
    }
    case GET_EXTERNAL_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_EXTERNAL_SCRIPT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_EXTERNAL_SCRIPT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_EXTERNAL_SCRIPT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_OFFERS_TRIGGERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_OFFERS_TRIGGERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        offerTriggerList: action.result
      };
    }
    case GET_OFFERS_TRIGGERS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_EVENTS_TRIGGERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EVENTS_TRIGGERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        eventTriggerList: action.result
      };
    }
    case GET_EVENTS_TRIGGERS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case IMAGE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_CAMPAIGN_EMAIL_TEMPLATES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignEmailTemplates: action.result
      };
    }
    case GET_CAMPAIGN_EMAIL_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CAMPAIGN_DISCOUNT_MASTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CAMPAIGN_DISCOUNT_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignDiscountMaster: action.result
      };
    }
    case GET_CAMPAIGN_DISCOUNT_MASTER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getCampaignList = (brandId) => {
  return {
    types: [GET_CAMPAIGN_REQUESTED, GET_CAMPAIGN_SUCCESS, GET_CAMPAIGN_FAILURE],
    promise: client => client.get(`campaignList/${brandId}`)
  };
};

export const getCampaignClasses = () => {
  return {
    types: [GET_CAMPAIGN_CLASSES_REQUESTED, GET_CAMPAIGN_CLASSES_SUCCESS, GET_CAMPAIGN_CLASSES_FAILURE],
    promise: client => client.get('campaignClass')
  };
};

export const getCampaignMarketingTypes = () => {
  return {
    types: [GET_CAMPAIGN_MARKETING_REQUESTED, GET_CAMPAIGN_MARKETING_SUCCESS, GET_CAMPAIGN_MARKETING_FAILURE],
    promise: client => client.get('campaignMarketingTypes')
  };
};

export const campaignRewardTypes = () => {
  return {
    types: [GET_CAMPAIGN_REWARDS_REQUESTED, GET_CAMPAIGN_REWARDS_SUCCESS, GET_CAMPAIGN_REWARDS_FAILURE],
    promise: client => client.get('campaignRewardTypes')
  };
};
export const getCampaignSubjects = () => {
  return {
    types: [GET_CAMPAIGN_SUBJECTS_REQUESTED, GET_CAMPAIGN_SUBJECTS_SUCCESS, GET_CAMPAIGN_SUBJECTS_FAILURE],
    promise: client => client.get('campaignSubjects')
  };
};

export const getmenuItemsbyBrandId = (brandId) => {
  return {
    types: [GET_MENU_ITEMS_BY_BRAND_ID_REQUESTED, GET_MENU_ITEMS_BY_BRAND_ID_SUCCESS, GET_MENU_ITEMS_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getmenuItemsbyBrandId/${brandId}`)
  };
};

export const saveCampaignReward = (data) => {
  return {
    types: [SAVE_CAMPAIGN_REWARD_REQUESTED, SAVE_CAMPAIGN_REWARD_SUCCESS, SAVE_CAMPAIGN_REWARD_FAILURE],
    promise: client => client.post('saveCampaignReward', { data })
  };
};

export const deleteCampaignReward = (campaignId) => {
  return {
    types: [DELETE_CAMPAIGN_REWARD_REQUESTED, DELETE_CAMPAIGN_REWARD_SUCCESS, DELETE_CAMPAIGN_REWARD_FAILURE],
    promise: client => client.post(`deleteCampaignReward/${campaignId}`)
  };
};

export const getOfferCampaignTypes = () => {
  return {
    types: [GET_CAMPAIGN_OFFER_TYPES_REQUESTED, GET_CAMPAIGN_OFFER_TYPES_SUCCESS, GET_CAMPAIGN_OFFER_TYPES_FAILURE],
    promise: client => client.get('offerCampaignTypes')
  };
};

export const addCampaign = (data) => {
  return {
    types: [ADD_CAMPAIGN_REQUESTED, ADD_CAMPAIGN_SUCCESS, ADD_CAMPAIGN_FAILURE],
    promise: client => client.post('addCampaign', { data })
  };
};
export const addLocation = (data) => {
  return {
    types: [ADD_LOCATION_REQUESTED, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILURE],
    promise: client => client.post('addLocation', { data })
  };
};
export const getCampaignById = (campaignId) => {
  return {
    types: [GET_CAMPAIGN_BY_ID_REQUESTED, GET_CAMPAIGN_BY_ID_SUCCESS, GET_CAMPAIGN_BY_ID_FAILURE],
    promise: client => client.get(`campaignById/${campaignId}`)
  };
};

export const deleteCampaignItem = (brandCampaignId) => {
  return {
    types: [DELETE_CAMPAIGN_REQUESTED, DELETE_CAMPAIGN_SUCCESS, DELETE_CAMPAIGN_FAILURE],
    promise: client => client.post(`deleteCampaignItem/${brandCampaignId}`)
  };
};

export const updateCampaign = (data) => {
  return {
    types: [UPDATE_CAMPAIGN_REQUESTED, UPDATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN_FAILURE],
    promise: client => client.post('updateCampaign', { data })
  };
};

export const getCampaignRuleTypes = () => {
  return {
    types: [GET_CAMPAIGN_RULE_TYPES_REQUESTED, GET_CAMPAIGN_RULE_TYPES_SUCCESS, GET_CAMPAIGN_RULE_TYPES_FAILURE],
    promise: client => client.get('getCampaignRuleTypes')
  };
};

export const getCategoriesByBrandId = (brandId) => {
  return {
    types: [GET_CATEGORIES_BY_BRAND_ID_REQUESTED, GET_CATEGORIES_BY_BRAND_ID_SUCCESS, GET_CATEGORIES_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getCategoriesByBrandId/${brandId}`)
  };
};

export const getTicketPaymentTypes = () => {
  return {
    types: [GET_PAYMENT_TYPE_REQUESTED, GET_PAYMENT_TYPE_SUCCESS, GET_PAYMENT_TYPE_FAILURE],
    promise: client => client.get('getTicketPaymentTypes')
  };
};

export const getChannels = () => {
  return {
    types: [GET_CHANNELS_REQUESTED, GET_CHANNELS_SUCCESS, GET_CHANNELS_FAILURE],
    promise: client => client.get('getChannels')
  };
};

export const getRedemptionLimitTypes = () => {
  return {
    types: [GET_REDEMPTION_LIMIT_TYPES_REQUESTED, GET_REDEMPTION_LIMIT_TYPES_SUCCESS, GET_REDEMPTION_LIMIT_TYPES_FAILURE],
    promise: client => client.get('getRedemptionLimitTypes')
  };
};

export const getRedemptionMethods = () => {
  return {
    types: [GET_REDEMPTION_METHODS_REQUESTED, GET_REDEMPTION_METHODS_SUCCESS, GET_REDEMPTION_METHODS_FAILURE],
    promise: client => client.get('getRedemptionMethods')
  };
};

export const saveCampaignRule = (data) => {
  return {
    types: [SAVE_CAMPAIGN_RULE_REQUESTED, SAVE_CAMPAIGN_RULE_SUCCESS, SAVE_CAMPAIGN_RULE_FAILURE],
    promise: client => client.post('saveCampaignRule', { data })
  };
};

export const deletCampaignRuleId = (campaignRuleId) => {
  return {
    types: [DELETE_CAMPAIGN_RULE_REQUESTED, DELETE_CAMPAIGN_RULE_SUCCESS, DELETE_CAMPAIGN_RULE_FAILURE],
    promise: client => client.post(`deleteCampaignRule/${campaignRuleId}`)
  };
};

export const getExternalCampaign = (campaignId) => {
  return {
    types: [GET_EXTERNAL_CAMPAIGN_REQUESTED, GET_EXTERNAL_CAMPAIGN_SUCCESS, GET_EXTERNAL_CAMPAIGN_FAILURE],
    promise: client => client.get(`externalCampaign/${campaignId}`)
  };
};

export const updateExternalScript = (data) => {
  return {
    types: [UPDATE_EXTERNAL_SCRIPT_REQUESTED, UPDATE_EXTERNAL_SCRIPT_SUCCESS, UPDATE_EXTERNAL_SCRIPT_FAILURE],
    promise: client => client.post('updateExternalCampaignScript', { data })
  };
};

export const getOfferTriggerList = () => {
  return {
    types: [GET_OFFERS_TRIGGERS_LIST_REQUESTED, GET_OFFERS_TRIGGERS_LIST_SUCCESS, GET_OFFERS_TRIGGERS_LIST_FAILURE],
    promise: client => client.get('getOfferTrigger')
  };
};
export const getEventTriggerList = () => {
  return {
    types: [GET_EVENTS_TRIGGERS_LIST_REQUESTED, GET_EVENTS_TRIGGERS_LIST_SUCCESS, GET_EVENTS_TRIGGERS_LIST_FAILURE],
    promise: client => client.get('getEventTrigger')
  };
};

export const uplaodCampaignImage = (id, data) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`campaignImageSave/${id}/save`, { data })
  };
};

export const deleteCampaignImage = (id) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`campaignImageDelete/${id}/delete`)
  };
};

export const getCampaignEmailTemplates = (brandId) => {
  return {
    types: [GET_CAMPAIGN_EMAIL_TEMPLATES_REQUESTED, GET_CAMPAIGN_EMAIL_TEMPLATES_SUCCESS, GET_CAMPAIGN_EMAIL_TEMPLATES_FAILURE],
    promise: client => client.get(`getCampaignEmailTemplates/${brandId}`)
  };
};
export const getCampaignDiscountMaster = (brandId) => {
  return {
    types: [GET_CAMPAIGN_DISCOUNT_MASTER_REQUESTED, GET_CAMPAIGN_DISCOUNT_MASTER_SUCCESS, GET_CAMPAIGN_DISCOUNT_MASTER_FAILURE],
    promise: client => client.get(`getCampaignDiscountMaster/${brandId}`)
  };
};
