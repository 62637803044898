import uuid from 'uuid/v4';

export const GET_BRANDS_REQUESTED = 'brand/GET_BRANDS_REQUESTED';
export const GET_BRANDS_SUCCESS = 'brand/GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'brand/GET_BRANDS_FAILURE';

export const ADD_BRAND_REQUESTED = 'brand/ADD_BRAND_REQUESTED';
export const ADD_BRAND_SUCCESS = 'brand/ADD_BRAND_SUCCESS';
export const ADD_BRAND_FAILURE = 'brand/ADD_BRAND_FAILURE';

export const UPDATE_BRAND_REQUESTED = 'brand/UPDATE_BRAND_REQUESTED';
export const UPDATE_BRAND_SUCCESS = 'brand/UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'brand/UPDATE_BRAND_FAILURE';

export const GET_BRAND_BYID_REQUESTED = 'brand/GET_BRAND_BYID_REQUESTED';
export const GET_BRAND_BYID_SUCCESS = 'brand/GET_BRAND_BYID_SUCCESS';
export const GET_BRAND_BYID_FAILURE = 'brand/GET_BRAND_BYID_FAILURE';

export const ADD_SELECTED_BRAND_IMAGE_REQUESTED = 'brand/ADD_SELECTED_BRAND_IMAGE_REQUESTED';
export const ADD_SELECTED_BRAND_IMAGE_SUCCESS = 'brand/ADD_SELECTED_BRAND_IMAGE_SUCCESS';
export const ADD_SELECTED_BRAND_IMAGE_FAILURE = 'brand/ADD_SELECTED_BRAND_IMAGE_FAILURE';

export const DELETE_SELECTED_BRAND_IMAGE_REQUESTED = 'brand/DELETE_SELECTED_BRAND_IMAGE_REQUESTED';
export const DELETE_SELECTED_BRAND_IMAGE_SUCCESS = 'brand/DELETE_SELECTED_BRAND_IMAGE_SUCCESS';
export const DELETE_SELECTED_BRAND_IMAGE_FAILURE = 'brand/DELETE_SELECTED_BRAND_IMAGE_FAILURE';

export const GET_OTHER_SETTINGS_REQUESTED = 'brand/GET_OTHER_SETTINGS_REQUESTED';
export const GET_OTHER_SETTINGS_SUCCESS = 'brand/GET_OTHER_SETTINGS_SUCCESS';
export const GET_OTHER_SETTINGS_FAILURE = 'brand/GET_OTHER_SETTINGS_FAILURE';

export const UPDATE_OTHER_SETTINGS_REQUESTED = 'brand/UPDATE_OTHER_SETTINGS_REQUESTED';
export const UPDATE_OTHER_SETTINGS_SUCCESS = 'brand/UPDATE_OTHER_SETTINGS_SUCCESS';
export const UPDATE_OTHER_SETTINGS_FAILURE = 'brand/UPDATE_OTHER_SETTINGS_FAILURE';

export const GET_BRAND_TAGS_REQUESTED = 'brand/GET_BRAND_TAGS_REQUESTED';
export const GET_BRAND_TAGS_SUCCESS = 'brand/GET_BRAND_TAGS_SUCCESS';
export const GET_BRAND_TAGS_FAILURE = 'brand/GET_BRAND_TAGS_FAILURE';

export const UPDATE_BRAND_TAG_REQUESTED = 'brand/UPDATE_BRAND_TAG_REQUESTED';
export const UPDATE_BRAND_TAG_SUCCESS = 'brand/UPDATE_BRAND_TAG_SUCCESS';
export const UPDATE_BRAND_TAG_FAILURE = 'brand/UPDATE_BRAND_TAG_FAILURE';

export const DELETE_BRAND_TAG_REQUESTED = 'brand/DELETE_BRAND_TAG_REQUESTED';
export const DELETE_BRAND_TAG_SUCCESS = 'brand/DELETE_BRAND_TAG_SUCCESS';
export const DELETE_BRAND_TAG_FAILURE = 'brand/DELETE_BRAND_TAG_FAILURE';

export const ADD_BRAND_TAG_IMAGE_REQUESTED = 'brand/ADD_BRAND_TAG_IMAGE_REQUESTED';
export const ADD_BRAND_TAG_IMAGE_SUCCESS = 'brand/ADD_BRAND_TAG_IMAGE_SUCCESS';
export const ADD_BRAND_TAG_IMAGE_FAILURE = 'brand/ADD_BRAND_TAG_IMAGE_FAILURE';

export const DELETE_BRAND_TAG_IMAGE_REQUESTED = 'brand/DELETE_BRAND_TAG_IMAGE_REQUESTED';
export const DELETE_BRAND_TAG_IMAGE_SUCCESS = 'brand/DELETE_BRAND_TAG_IMAGE_SUCCESS';
export const DELETE_BRAND_TAG_IMAGE_FAILURE = 'brand/DELETE_BRAND_TAG_IMAGE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandsList: [],
  getBrandByID: null,
  getOtherSettings: null,
  getBrandTagsList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRANDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRANDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandsList: action.result.map(obj => ({ ...obj, uniqueId: uuid() })),
      };
    }
    case GET_BRANDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandByID: action.result,
      };
    }
    case GET_BRAND_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SELECTED_BRAND_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SELECTED_BRAND_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_SELECTED_BRAND_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_SELECTED_BRAND_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SELECTED_BRAND_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_SELECTED_BRAND_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_OTHER_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OTHER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getOtherSettings: action.result,
      };
    }
    case GET_OTHER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_OTHER_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_OTHER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_OTHER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandTagsList: action.result,
      };
    }
    case GET_BRAND_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_TAG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_TAG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_TAG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_TAG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_TAG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_TAG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_TAG_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_TAG_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_TAG_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_TAG_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_TAG_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_TAG_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBrandsByCompany = (companyId) => {
  return {
    types: [GET_BRANDS_REQUESTED, GET_BRANDS_SUCCESS, GET_BRANDS_FAILURE],
    promise: client => client.get(`getBrandsByCompany/${companyId}`)
  };
};

export const addBrand = (data) => {
  return {
    types: [ADD_BRAND_REQUESTED, ADD_BRAND_SUCCESS, ADD_BRAND_FAILURE],
    promise: client => client.post('addBrand', { data })
  };
};

export const updateBrand = (data) => {
  return {
    types: [UPDATE_BRAND_REQUESTED, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE],
    promise: client => client.post('updateBrand', { data })
  };
};

export const getBrandById = (id) => {
  return {
    types: [GET_BRAND_BYID_REQUESTED, GET_BRAND_BYID_SUCCESS, GET_BRAND_BYID_FAILURE],
    promise: client => client.get(`getBrandById/${id}`)
  };
};


export const addSelectedBrandImage = (brandId, imageType, data) => {
  return {
    types: [ADD_SELECTED_BRAND_IMAGE_REQUESTED, ADD_SELECTED_BRAND_IMAGE_SUCCESS, ADD_SELECTED_BRAND_IMAGE_FAILURE],
    promise: client => client.post(`brandImage/${brandId}/${imageType}/save`, { data })
  };
};

export const deleteSelectedBrandImage = (brandId, imageType) => {
  return {
    types: [DELETE_SELECTED_BRAND_IMAGE_REQUESTED, DELETE_SELECTED_BRAND_IMAGE_SUCCESS, DELETE_SELECTED_BRAND_IMAGE_FAILURE],
    promise: client => client.post(`brandImage/${brandId}/${imageType}/delete`)
  };
};

export const getOtherSettings = (id) => {
  return {
    types: [GET_OTHER_SETTINGS_REQUESTED, GET_OTHER_SETTINGS_SUCCESS, GET_OTHER_SETTINGS_FAILURE],
    promise: client => client.get(`getOtherSettings/${id}`)
  };
};

export const updateOtherSettings = (data) => {
  return {
    types: [UPDATE_OTHER_SETTINGS_REQUESTED, UPDATE_OTHER_SETTINGS_SUCCESS, UPDATE_OTHER_SETTINGS_FAILURE],
    promise: client => client.post('updateOtherSettings', { data })
  };
};

export const getBrandTagsList = (brandId) => {
  return {
    types: [GET_BRAND_TAGS_REQUESTED, GET_BRAND_TAGS_SUCCESS, GET_BRAND_TAGS_FAILURE],
    promise: client => client.get(`getBrandTags/${brandId}`)
  };
};

export const updateBrandTag = (data) => {
  return {
    types: [UPDATE_BRAND_TAG_REQUESTED, UPDATE_BRAND_TAG_SUCCESS, UPDATE_BRAND_TAG_FAILURE],
    promise: client => client.post('updateBrandTag', { data })
  };
};

export const deleteBrandTag = (brandId, tagId) => {
  return {
    types: [DELETE_BRAND_TAG_REQUESTED, DELETE_BRAND_TAG_SUCCESS, DELETE_BRAND_TAG_FAILURE],
    promise: client => client.post(`deleterBrandTag/${brandId}/tagId/${tagId}`)
  };
};

export const addBrandTagImage = (brandId, tagId, data) => {
  return {
    types: [UPDATE_BRAND_TAG_REQUESTED, UPDATE_BRAND_TAG_SUCCESS, UPDATE_BRAND_TAG_FAILURE],
    promise: client => client.post(`brandTagImage/${brandId}/tagId/${tagId}/save`, { data })
  };
};

export const deleteBrandTagImage = (brandId, tagId) => {
  return {
    types: [DELETE_BRAND_TAG_REQUESTED, DELETE_BRAND_TAG_SUCCESS, DELETE_BRAND_TAG_FAILURE],
    promise: client => client.post(`brandTagImage/${brandId}/tagId/${tagId}/delete`)
  };
};
