import uuid from 'uuid/v4';

export const ADD_COMPANY_REQUESTED = 'company/ADD_COMPANY_REQUESTED';
export const ADD_COMPANY_SUCCESS = 'company/ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'company/ADD_COMPANY_FAILURE';

export const UPDATE_COMPANY_REQUESTED = 'company/UPDATE_COMPANY_REQUESTED';
export const UPDATE_COMPANY_SUCCESS = 'company/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'company/UPDATE_COMPANY_FAILURE';

export const GET_COMPANY_BYID_REQUESTED = 'company/GET_COMPANY_BYID_REQUESTED';
export const GET_COMPANY_BYID_SUCCESS = 'company/GET_COMPANY_BYID_SUCCESS';
export const GET_COMPANY_BYID_FAILURE = 'company/GET_COMPANY_BYID_FAILURE';

export const GET_COMPANIES_LIST_REQUESTED = 'company/GET_COMPANIES_LIST_REQUESTED';
export const GET_COMPANIES_LIST_SUCCESS = 'company/GET_COMPANIES_LIST_SUCCESS';
export const GET_COMPANIES_LIST_FAILURE = 'company/GET_COMPANIES_LIST_FAILURE';

export const GET_COMPANIES_LIST_DATA_REQUESTED = 'company/GET_COMPANIES_LIST_DATA_REQUESTED';
export const GET_COMPANIES_LIST_DATA_SUCCESS = 'company/GET_COMPANIES_LIST_DATA_SUCCESS';
export const GET_COMPANIES_LIST_DATA_FAILURE = 'company/GET_COMPANIES_LIST_DATA_FAILURE';

export const COUNTRY_LIST_REQUESTED = 'company/COUNTRY_LIST_REQUESTED';
export const COUNTRY_LIST_SUCCESS = 'company/COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAILURE = 'company/COUNTRY_LIST_FAILURE';

const initialState = {
  loading: false,
  companiesListLoading: false,
  getCompanyByIdLoading: false,
  loaded: false,
  error: false,
  getCompanyByID: null,
  companyList: [],
  companyListData: [],
  countryList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        companiesListLoading: true,
        loaded: false,
      };
    }
    case GET_COMPANIES_LIST_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        companiesListLoading: false,
        error: false,
        companyList: action.result.map(obj => ({ ...obj, uniqueId: uuid() })),
      };
    }
    case GET_COMPANIES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        companiesListLoading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_COMPANY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_COMPANY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_COMPANY_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        getCompanyByIdLoading: true,
        loaded: false,
        getCompanyByID: null
      };
    }
    case GET_COMPANY_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        getCompanyByIdLoading: false,
        loaded: true,
        error: false,
        getCompanyByID: action.result,
      };
    }
    case GET_COMPANY_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        getCompanyByIdLoading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_COMPANIES_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_COMPANIES_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        companyListData: action.result,
      };
    }
    case GET_COMPANIES_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case COUNTRY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        countryList: action.result
      };
    }
    case COUNTRY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addCompany = (data) => {
  return {
    types: [ADD_COMPANY_REQUESTED, ADD_COMPANY_SUCCESS, ADD_COMPANY_FAILURE],
    promise: client => client.post('addCompany', { data })
  };
};

export const updateCompany = (data) => {
  return {
    types: [UPDATE_COMPANY_REQUESTED, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE],
    promise: client => client.post('updateCompany', { data })
  };
};

export const getCompanyById = (id) => {
  return {
    types: [GET_COMPANY_BYID_REQUESTED, GET_COMPANY_BYID_SUCCESS, GET_COMPANY_BYID_FAILURE],
    promise: client => client.get(`getCompanyById/${id}`)
  };
};

export const getCompaniesList = () => {
  return {
    types: [GET_COMPANIES_LIST_REQUESTED, GET_COMPANIES_LIST_SUCCESS, GET_COMPANIES_LIST_FAILURE],
    promise: client => client.get('getCompaniesList')
  };
};

export const getCompaniesListData = () => {
  return {
    types: [GET_COMPANIES_LIST_DATA_REQUESTED, GET_COMPANIES_LIST_DATA_SUCCESS, GET_COMPANIES_LIST_DATA_FAILURE],
    promise: client => client.get('getCompaniesListData')
  };
};


export const getCountryList = (data) => {
  return {
    types: [COUNTRY_LIST_REQUESTED, COUNTRY_LIST_SUCCESS, COUNTRY_LIST_FAILURE],
    promise: client => client.get('getCountryList', { data })
  };
};
