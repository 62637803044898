export const GET_BRAND_TIPS_REQUESTED = 'brand/GET_BRAND_TIPS_REQUESTED';
export const GET_BRAND_TIPS_SUCCESS = 'brand/GET_BRAND_TIPS_SUCCESS';
export const GET_BRAND_TIPS_FAILURE = 'brand/GET_BRAND_TIPS_FAILURE';

export const GET_BRAND_TIPS_LIST_REQUESTED = 'brand/GET_BRAND_TIPS_LIST_REQUESTED';
export const GET_BRAND_TIPS_LIST_SUCCESS = 'brand/GET_BRAND_TIPS_LIST_SUCCESS';
export const GET_BRAND_TIPS_LIST_FAILURE = 'brand/GET_BRAND_TIPS_LIST_FAILURE';

export const GET_TIP_RULE_TYPES_REQUESTED = 'brand/GET_TIP_RULE_TYPES_REQUESTED';
export const GET_TIP_RULE_TYPES_SUCCESS = 'brand/GET_TIP_RULE_TYPES_SUCCESS';
export const GET_TIP_RULE_TYPES_FAILURE = 'brand/GET_TIP_RULE_TYPES_FAILURE';

export const GET_TIP_BY_ID_REQUESTED = 'brand/GET_TIP_BY_ID_REQUESTED';
export const GET_TIP_BY_ID_SUCCESS = 'brand/GET_TIP_BY_ID_SUCCESS';
export const GET_TIP_BY_ID_FAILURE = 'brand/GET_TIP_BY_ID_FAILURE';

export const ADD_TIPS_REQUESTED = 'brand/ADD_TIPS_REQUESTED';
export const ADD_TIPS_SUCCESS = 'brand/ADD_TIPS_SUCCESS';
export const ADD_TIPS_FAILURE = 'brand/ADD_TIPS_FAILURE';

export const ADD_TIP_RULES_REQUESTED = 'brand/ADD_TIP_RULES_REQUESTED';
export const ADD_TIP_RULES_SUCCESS = 'brand/ADD_TIP_RULES_SUCCESS';
export const ADD_TIP_RULES_FAILURE = 'brand/ADD_TIP_RULES_FAILURE';

export const UPDATE_TIPS_REQUESTED = 'brand/UPDATE_TIPS_REQUESTED';
export const UPDATE_TIPS_SUCCESS = 'brand/UPDATE_TIPS_SUCCESS';
export const UPDATE_TIPS_FAILURE = 'brand/UPDATE_TIPS_FAILURE';

export const UPDATE_TIP_RULES_REQUESTED = 'brand/UPDATE_TIP_RULES_REQUESTED';
export const UPDATE_TIP_RULES_SUCCESS = 'brand/UPDATE_TIP_RULES_SUCCESS';
export const UPDATE_TIP_RULES_FAILURE = 'brand/UPDATE_TIP_RULES_FAILURE';

export const UPDATE_BRAND_TIPS_REQUESTED = 'brand/UPDATE_BRAND_TIPS_REQUESTED';
export const UPDATE_BRAND_TIPS_SUCCESS = 'brand/UPDATE_BRAND_TIPS_SUCCESS';
export const UPDATE_BRAND_TIPS_FAILURE = 'brand/UPDATE_BRAND_TIPS_FAILURE';

const DELETE_TIP_REQUESTED = 'brand/DELETE_TIP_REQUESTED';
const DELETE_TIP_SUCCESS = 'brand/DELETE_TIP_SUCCESS';
const DELETE_TIP_FAILURE = 'brand/DELETE_TIP_FAILURE';

const DELETE_TIP_RULE_REQUESTED = 'brand/DELETE_TIP_RULE_REQUESTED';
const DELETE_TIP_RULE_SUCCESS = 'brand/DELETE_TIP_RULE_SUCCESS';
const DELETE_TIP_RULE_FAILURE = 'brand/DELETE_TIP_RULE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getBrandTip: [],
  getBrandTipList: [],
  tipRuleTypes: [],
  tipData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandTip: action.result,
      };
    }
    case GET_BRAND_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TIP_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TIP_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tipData: action.result,
      };
    }
    case GET_TIP_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TIP_RULE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tipRuleTypes: action.result,
      };
    }
    case GET_TIP_RULE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandTip: action.result,
      };
    }
    case UPDATE_BRAND_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_TIP_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TIP_RULES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TIP_RULES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TIP_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TIP_RULES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TIP_RULES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_TIPS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_TIPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandTipList: action.result,
      };
    }
    case GET_BRAND_TIPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_TIP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TIP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_TIP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_TIP_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TIP_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_TIP_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBrandTips = (brandId) => {
  return {
    types: [GET_BRAND_TIPS_REQUESTED, GET_BRAND_TIPS_SUCCESS, GET_BRAND_TIPS_FAILURE],
    promise: client => client.get(`getBrandTips/${brandId}`)
  };
};

export const getTipRuleTypes = () => {
  return {
    types: [GET_TIP_RULE_TYPES_REQUESTED, GET_TIP_RULE_TYPES_SUCCESS, GET_TIP_RULE_TYPES_FAILURE],
    promise: client => client.get('getTipRuleTypes')
  };
};

export const getTipsByTipId = (brandTipId) => {
  return {
    types: [GET_TIP_BY_ID_REQUESTED, GET_TIP_BY_ID_SUCCESS, GET_TIP_BY_ID_FAILURE],
    promise: client => client.get(`getTipsByTipId/${brandTipId}`)
  };
};

export const addTips = (data) => {
  return {
    types: [ADD_TIPS_REQUESTED, ADD_TIPS_SUCCESS, ADD_TIPS_FAILURE],
    promise: client => client.post('addTips', { data })
  };
};

export const updateTips = (data) => {
  return {
    types: [UPDATE_TIPS_REQUESTED, UPDATE_TIPS_SUCCESS, UPDATE_TIPS_FAILURE],
    promise: client => client.post('updateTips', { data })
  };
};

export const updateBrandTips = (brandId, data) => {
  return {
    types: [UPDATE_BRAND_TIPS_REQUESTED, UPDATE_BRAND_TIPS_SUCCESS, UPDATE_BRAND_TIPS_FAILURE],
    promise: client => client.post(`updateBrandTips/${brandId}`, { data })
  };
};
export const getBrandTipsList = (brandId) => {
  return {
    types: [GET_BRAND_TIPS_LIST_REQUESTED, GET_BRAND_TIPS_LIST_SUCCESS, GET_BRAND_TIPS_LIST_FAILURE],
    promise: client => client.get(`getBrandTipsList/${brandId}`)
  };
};
export const deleteTipItem = (brandTipId) => {
  return {
    types: [DELETE_TIP_REQUESTED, DELETE_TIP_SUCCESS, DELETE_TIP_FAILURE],
    promise: client => client.post(`deleteTipItem/${brandTipId}`)
  };
};

// Rules
export const addTipsRules = (data) => {
  return {
    types: [ADD_TIP_RULES_REQUESTED, ADD_TIP_RULES_SUCCESS, ADD_TIP_RULES_FAILURE],
    promise: client => client.post('addTipsRules', { data })
  };
};

export const updateTipsRules = (data) => {
  return {
    types: [UPDATE_TIP_RULES_REQUESTED, UPDATE_TIP_RULES_SUCCESS, UPDATE_TIP_RULES_FAILURE],
    promise: client => client.post('updateTipRules', { data })
  };
};

export const deleteTipRuleItem = (brandTipId) => {
  return {
    types: [DELETE_TIP_RULE_REQUESTED, DELETE_TIP_RULE_SUCCESS, DELETE_TIP_RULE_FAILURE],
    promise: client => client.post(`deleteTipRuleItem/${brandTipId}`)
  };
};
