import React from 'react';
import LoaderImage from '@assets/images/V4/loaderv4.png';
import cn from 'classnames';
import styles from './loader.module.css';

const Loader = () => {
  return (
    <div className={cn('mx-sm-0', styles.transparent)}>
      <div className="row mx-0 full-height justify-content-center align-items-center">
        <div className={styles.loaderHeight}>
          <img src={LoaderImage} alt="Icon menu" width={40} heigth={40} backgroundcolor="red" className="image-spin-loding" />
        </div>
      </div>
    </div>
  );
};
export default Loader;
