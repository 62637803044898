export const GET_EMAIL_TEMPLATE_BY_ID_REQUESTED = 'emailEditor/EMAIL_TEMPLATE_BY_ID_REQUESTED';
export const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS = 'emailEditor/EMAIL_TEMPLATE_BY_ID_SUCCESS';
export const GET_EMAIL_TEMPLATE_BY_ID_FAILURE = 'emailEditor/EMAIL_TEMPLATE_BY_ID_FAILURE';

export const GET_EMAIL_EDITOR_CONFIGURATIONS_REQUESTED = 'emailEditor/GET_EMAIL_EDITOR_CONFIGURATIONS_REQUESTED';
export const GET_EMAIL_EDITOR_CONFIGURATIONS_SUCCESS = 'emailEditor/GET_EMAIL_EDITOR_CONFIGURATIONS_SUCCESS';
export const GET_EMAIL_EDITOR_CONFIGURATIONS_FAILURE = 'emailEditor/GET_EMAIL_EDITOR_CONFIGURATIONS_FAILURE';

export const GET_EMAIL_EDITOR_CUSTOMIZATIONS_REQUESTED = 'emailEditor/GET_EMAIL_EDITOR_CUSTOMIZATIONS_REQUESTED';
export const GET_EMAIL_EDITOR_CUSTOMIZATIONS_SUCCESS = 'emailEditor/GET_EMAIL_EDITOR_CUSTOMIZATIONS_SUCCESS';
export const GET_EMAIL_EDITOR_CUSTOMIZATIONS_FAILURE = 'emailEditor/GET_EMAIL_EDITOR_CUSTOMIZATIONS_FAILURE';

export const GET_MERGE_FIELDS_BY_EMAIL_TYPE_REQUESTED = 'emailEditor/GET_MERGE_FIELDS_BY_EMAIL_TYPE_REQUESTED';
export const GET_MERGE_FIELDS_BY_EMAIL_TYPE_SUCCESS = 'emailEditor/GET_MERGE_FIELDS_BY_EMAIL_TYPE_SUCCESS';
export const GET_MERGE_FIELDS_BY_EMAIL_TYPE_FAILURE = 'emailEditor/GET_MERGE_FIELDS_BY_EMAIL_TYPE_FAILURE';

export const ADD_BRAND_EMAIL_TEMPLATE_REQUESTED = 'emailEditor/ADD_BRAND_EMAIL_TEMPLATE_REQUESTED';
export const ADD_BRAND_EMAIL_TEMPLATE_SUCCESS = 'emailEditor/ADD_BRAND_EMAIL_TEMPLATE_SUCCESS';
export const ADD_BRAND_EMAIL_TEMPLATE_FAILURE = 'emailEditor/ADD_BRAND_EMAIL_TEMPLATE_FAILURE';

export const UPDATE_BRAND_EMAIL_TEMPLATE_REQUESTED = 'emailEditor/UPDATE_BRAND_EMAIL_TEMPLATE_REQUESTED';
export const UPDATE_BRAND_EMAIL_TEMPLATE_SUCCESS = 'emailEditor/UPDATE_BRAND_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_BRAND_EMAIL_TEMPLATE_FAILURE = 'emailEditor/UPDATE_BRAND_EMAIL_TEMPLATE_FAILURE';

export const SEND_PREVIEW_URL_REQUESTED = 'emailEditor/SEND_PREVIEW_URL_REQUESTED';
export const SEND_PREVIEW_URL_SUCCESS = 'emailEditor/SEND_PREVIEW_URL_SUCCESS';
export const SEND_PREVIEW_URL_FAILURE = 'emailEditor/SEND_PREVIEW_URL_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  emailTemplateByIdList: null,
  emailEditorConfigurationsList: null,
  mergeFieldsByEmailTypeList: [],
  customizationsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        emailTemplateByIdList: action.result
      };
    }
    case GET_EMAIL_TEMPLATE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_EMAIL_EDITOR_CONFIGURATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_EMAIL_EDITOR_CONFIGURATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        emailEditorConfigurationsList: action.result
      };
    }
    case GET_EMAIL_EDITOR_CONFIGURATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_EMAIL_EDITOR_CUSTOMIZATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_EMAIL_EDITOR_CUSTOMIZATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        customizationsList: action.result
      };
    }
    case GET_EMAIL_EDITOR_CUSTOMIZATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_MERGE_FIELDS_BY_EMAIL_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_MERGE_FIELDS_BY_EMAIL_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        mergeFieldsByEmailTypeList: action.result
      };
    }
    case GET_MERGE_FIELDS_BY_EMAIL_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case ADD_BRAND_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADD_BRAND_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        mergeFieldsByEmailTypeList: action.result
      };
    }
    case ADD_BRAND_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case UPDATE_BRAND_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_BRAND_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_BRAND_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case SEND_PREVIEW_URL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEND_PREVIEW_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SEND_PREVIEW_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return { ...state };
  }
};


export const getEmailTemplateById = (emailTemplateId) => {
  return {
    types: [GET_EMAIL_TEMPLATE_BY_ID_REQUESTED, GET_EMAIL_TEMPLATE_BY_ID_SUCCESS, GET_EMAIL_TEMPLATE_BY_ID_FAILURE],
    promise: client => client.get(`getEmailTemplateById/${emailTemplateId}`),
  };
};

export const getBrandEmailTemplateById = (brandEmailTemplateId) => {
  return {
    types: [GET_EMAIL_TEMPLATE_BY_ID_REQUESTED, GET_EMAIL_TEMPLATE_BY_ID_SUCCESS, GET_EMAIL_TEMPLATE_BY_ID_FAILURE],
    promise: client => client.get(`getBrandEmailTemplateById/${brandEmailTemplateId}`),
  };
};

export const getEmailEditorConfigurations = (brandId, emailType) => {
  return {
    types: [GET_EMAIL_EDITOR_CONFIGURATIONS_REQUESTED, GET_EMAIL_EDITOR_CONFIGURATIONS_SUCCESS, GET_EMAIL_EDITOR_CONFIGURATIONS_FAILURE],
    promise: client => client.get(`getEmailEditorConfiguration/${brandId}/emailType/${emailType}`)
  };
};

export const getCustomizable = () => {
  return {
    types: [GET_EMAIL_EDITOR_CUSTOMIZATIONS_REQUESTED, GET_EMAIL_EDITOR_CUSTOMIZATIONS_SUCCESS, GET_EMAIL_EDITOR_CUSTOMIZATIONS_FAILURE],
    promise: client => client.get('getCustomizable')
  };
};

export const getMergeFieldsByEmailType = (emailType) => {
  return {
    types: [GET_MERGE_FIELDS_BY_EMAIL_TYPE_REQUESTED, GET_MERGE_FIELDS_BY_EMAIL_TYPE_SUCCESS, GET_MERGE_FIELDS_BY_EMAIL_TYPE_FAILURE],
    promise: client => client.get(`getMergeFieldsByEmailType/${emailType}`)
  };
};

export const addBrandEmailTemplateUrl = (data) => {
  return {
    types: [ADD_BRAND_EMAIL_TEMPLATE_REQUESTED, ADD_BRAND_EMAIL_TEMPLATE_SUCCESS, ADD_BRAND_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.post('addBrandEmailTemplateUrl', { data })
  };
};

export const updateBrandEmailTemplateUrl = (data) => {
  return {
    types: [UPDATE_BRAND_EMAIL_TEMPLATE_REQUESTED, UPDATE_BRAND_EMAIL_TEMPLATE_SUCCESS, UPDATE_BRAND_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.post('updateBrandEmailTemplateUrl', { data })
  };
};

export const sendPreviewEmailUrl = (data) => {
  return {
    types: [SEND_PREVIEW_URL_REQUESTED, SEND_PREVIEW_URL_SUCCESS, SEND_PREVIEW_URL_FAILURE],
    promise: client => client.post('sendPreviewEmailUrl', { data })
  };
};
