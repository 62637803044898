export const GET_TABLEGROUPS_BY_STOREID_REQUESTED = 'tableGroups/GET_TABLEGROUPS_BY_STOREID_REQUESTED';
export const GET_TABLEGROUPS_BY_STOREID_SUCCESS = 'tableGroups/GET_TABLEGROUPS_BY_STOREID_SUCCESS';
export const GET_TABLEGROUPS_BY_STOREID_FAILURE = 'tableGroups/GET_TABLEGROUPS_BY_STOREID_FAILURE';

export const ADD_TABLEGROUP_REQUESTED = 'tableGroups/ADD_TABLEGROUP_REQUESTED';
export const ADD_TABLEGROUP_SUCCESS = 'tableGroups/ADD_TABLEGROUP_SUCCESS';
export const ADD_TABLEGROUP_FAILURE = 'tableGroups/ADD_TABLEGROUP_FAILURE';

export const UPDATE_TABLEGROUP_REQUESTED = 'tableGroups/UPDATE_TABLEGROUP_REQUESTED';
export const UPDATE_TABLEGROUP_SUCCESS = 'tableGroups/UPDATE_TABLEGROUP_SUCCESS';
export const UPDATE_TABLEGROUP_FAILURE = 'tableGroups/UPDATE_TABLEGROUP_FAILURE';

export const DELETE_TABLEGROUP_REQUESTED = 'tableGroups/DELETE_TABLEGROUP_REQUESTED';
export const DELETE_TABLEGROUP_SUCCESS = 'tableGroups/DELETE_TABLEGROUP_SUCCESS';
export const DELETE_TABLEGROUP_FAILURE = 'tableGroups/DELETE_TABLEGROUP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getTableGroups: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_TABLEGROUPS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TABLEGROUPS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getTableGroups: action.result,
      };
    }
    case GET_TABLEGROUPS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getTableGroups: [],
      };
    }
    case ADD_TABLEGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TABLEGROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TABLEGROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TABLEGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TABLEGROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TABLEGROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_TABLEGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TABLEGROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_TABLEGROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getTableGroupsByStoreId = (storeId) => {
  return {
    types: [GET_TABLEGROUPS_BY_STOREID_REQUESTED, GET_TABLEGROUPS_BY_STOREID_SUCCESS, GET_TABLEGROUPS_BY_STOREID_FAILURE],
    promise: client => client.get(`getTableGroupsByStore/${storeId}`)
  };
};

export const addTableGroup = (data) => {
  return {
    types: [ADD_TABLEGROUP_REQUESTED, ADD_TABLEGROUP_SUCCESS, ADD_TABLEGROUP_FAILURE],
    promise: client => client.post('addTableGroup', { data }),
  };
};

export const updateTableGroup = (data) => {
  return {
    types: [UPDATE_TABLEGROUP_REQUESTED, UPDATE_TABLEGROUP_SUCCESS, UPDATE_TABLEGROUP_FAILURE],
    promise: client => client.post('updateTableGroup', { data }),
  };
};

export const deleteTableGroup = (tableGroupId) => {
  return {
    types: [DELETE_TABLEGROUP_REQUESTED, DELETE_TABLEGROUP_SUCCESS, DELETE_TABLEGROUP_FAILURE],
    promise: client => client.post(`deleteTableGroup/${tableGroupId}`),
  };
};
