export const GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE';

export const ONBOARDING_URL_PAYPAL_REQUESTED = 'store/ONBOARDING_URL_PAYPAL_REQUESTED';
export const ONBOARDING_URL_PAYPAL_SUCCESS = 'store/ONBOARDING_URL_PAYPAL_SUCCESS';
export const ONBOARDING_URL_PAYPAL_FAILURE = 'store/ONBOARDING_URL_PAYPAL_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  paypalList: null,
  onBoardingUrlSystem: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        paypalList: null
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paypalList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        ingenicoList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paypalList: action.result
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ONBOARDING_URL_PAYPAL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ONBOARDING_URL_PAYPAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        onBoardingUrlSystem: action.result
      };
    }
    case ONBOARDING_URL_PAYPAL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfigPayPal = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigPaypal/${storeId}`)
  };
};

export const updateStorePaymentSystemConfigPayPal = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_PAYPAL_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfigPaypal/${storeId}`, { data })
  };
};

export const onBoardingUrlPayPal = (storeId) => {
  return {
    types: [ONBOARDING_URL_PAYPAL_REQUESTED, ONBOARDING_URL_PAYPAL_SUCCESS, ONBOARDING_URL_PAYPAL_FAILURE],
    promise: client => client.post(`onBoardingUrlPayPal/${storeId}`)
  };
};
