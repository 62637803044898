export const GET_HAPPY_HOURS_BY_BRAND_REQUESTED = 'happyHour/GET_HAPPY_HOURS_BY_BRAND_REQUESTED';
export const GET_HAPPY_HOURS_BY_BRAND_SUCCESS = 'happyHour/GET_HAPPY_HOURS_BY_BRAND_SUCCESS';
export const GET_HAPPY_HOURS_BY_BRAND_FAILURE = 'happyHour/GET_HAPPY_HOURS_BY_BRAND_FAILURE';

export const GET_HAPPY_HOURS_DATA_REQUESTED = 'happyHour/GET_HAPPY_HOURS_DATA_REQUESTED';
export const GET_HAPPY_HOURS_DATA_SUCCESS = 'happyHour/GET_HAPPY_HOURS_DATA_SUCCESS';
export const GET_HAPPY_HOURS_DATA_FAILURE = 'happyHour/GET_HAPPY_HOURS_DATA_FAILURE';

export const UPDATE_HAPPY_HOURS_DATA_REQUESTED = 'happyHour/UPDATE_HAPPY_HOURS_DATA_REQUESTED';
export const UPDATE_HAPPY_HOURS_DATA_SUCCESS = 'happyHour/UPDATE_HAPPY_HOURS_DATA_SUCCESS';
export const UPDATE_HAPPY_HOURS_DATA_FAILURE = 'happyHour/UPDATE_HAPPY_HOURS_DATA_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandHappyHoursList: [],
  happyHoursData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_HAPPY_HOURS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_HAPPY_HOURS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandHappyHoursList: action.result,
      };
    }
    case GET_HAPPY_HOURS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_HAPPY_HOURS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_HAPPY_HOURS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        happyHoursData: action.result,
      };
    }
    case GET_HAPPY_HOURS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_HAPPY_HOURS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_HAPPY_HOURS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_HAPPY_HOURS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getHappyHourListByBrand = (brandId) => {
  return {
    types: [GET_HAPPY_HOURS_BY_BRAND_REQUESTED, GET_HAPPY_HOURS_BY_BRAND_SUCCESS, GET_HAPPY_HOURS_BY_BRAND_FAILURE],
    promise: client => client.get(`getHappyHourByBrandId/${brandId}`)
  };
};

export const getHappyHoursData = (brandId) => {
  return {
    types: [GET_HAPPY_HOURS_DATA_REQUESTED, GET_HAPPY_HOURS_DATA_SUCCESS, GET_HAPPY_HOURS_DATA_FAILURE],
    promise: client => client.get(`getHappyHours/${brandId}`)
  };
};

export const updateHappyHoursData = (data) => {
  return {
    types: [UPDATE_HAPPY_HOURS_DATA_REQUESTED, UPDATE_HAPPY_HOURS_DATA_SUCCESS, UPDATE_HAPPY_HOURS_DATA_FAILURE],
    promise: client => client.post('updateHappyHours', { data })
  };
};
