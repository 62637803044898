import { decriptResponse } from '@utility/helperFunction';

export const GET_GATEWAY_REQUESTED = 'reports/GET_GATEWAY_REQUESTED';
export const GET_GATEWAY_SUCCESS = 'reports/GET_GATEWAY_SUCCESS';
export const GET_GATEWAY_FAILURE = 'reports/GET_GATEWAY_FAILURE';

export const POST_BATCH_REPORT_REQUESTED = 'reports/POST_BATCH_REPORT_REQUESTED';
export const POST_BATCH_REPORT_SUCCESS = 'reports/POST_BATCH_REPORT_SUCCESS';
export const POST_BATCH_REPORT_FAILURE = 'reports/POST_BATCH_REPORT_FAILURE';

export const POST_ADMIN_BATCH_DETAIL_REPORT_REQUESTED = 'reports/POST_ADMIN_BATCH_DETAIL_REPORT_REQUESTED';
export const POST_ADMIN_BATCH_DETAIL_REPORT_SUCCESS = 'reports/POST_ADMIN_BATCH_DETAIL_REPORT_SUCCESS';
export const POST_ADMIN_BATCH_DETAIL_REPORT_FAILURE = 'reports/POST_ADMIN_BATCH_DETAIL_REPORT_FAILURE';

export const POST_BATCH_SUMMARY_REPORT_REQUESTED = 'reports/POST_BATCH_SUMMARY_REPORT_REQUESTED';
export const POST_BATCH_SUMMARY_REPORT_SUCCESS = 'reports/POST_BATCH_SUMMARY_REPORT_SUCCESS';
export const POST_BATCH_SUMMARY_REPORT_FAILURE = 'reports/POST_BATCH_SUMMARY_REPORT_FAILURE';

export const POST_TRANSACTION_REPORT_REQUESTED = 'reports/POST_TRANSACTION_REPORT_REQUESTED';
export const POST_TRANSACTION_REPORT_SUCCESS = 'reports/POST_TRANSACTION_REPORT_SUCCESS';
export const POST_TRANSACTION_REPORT_FAILURE = 'reports/POST_TRANSACTION_REPORT_FAILURE';

export const POST_ITEMSALES_REPORT_REQUESTED = 'reports/POST_ITEMSALES_REPORT_REQUESTED';
export const POST_ITEMSALES_REPORT_SUCCESS = 'reports/POST_ITEMSALES_REPORT_SUCCESS';
export const POST_ITEMSALES_REPORT_FAILURE = 'reports/POST_ITEMSALES_REPORT_FAILURE';

export const POST_RECONCILLATION_REPORT_REQUESTED = 'reports/POST_RECONCILLATION_REPORT_REQUESTED';
export const POST_RECONCILLATION_REPORT_SUCCESS = 'reports/POST_RECONCILLATION_REPORT_SUCCESS';
export const POST_RECONCILLATION_REPORT_FAILURE = 'reports/POST_RECONCILLATION_REPORT_FAILURE';

export const POST_SURVEY_QUESTION_REPORT_REQUESTED = 'reports/POST_SURVEY_QUESTION_REPORT_REQUESTED';
export const POST_SURVEY_QUESTION_REPORT_SUCCESS = 'reports/POST_SURVEY_QUESTION_REPORT_SUCCESS';
export const POST_SURVEY_QUESTION_REPORT_FAILURE = 'reports/POST_SURVEY_QUESTION_REPORT_FAILURE';

export const SURVEY_REPORT_TICKET_REQUESTED = 'reports/SURVEY_REPORT_TICKET_REQUESTED';
export const SURVEY_REPORT_TICKET_SUCCESS = 'reports/SURVEY_REPORT_TICKET_SUCCESS';
export const SURVEY_REPORT_TICKET_FAILURE = 'reports/SURVEY_REPORT_TICKET_FAILURE';

export const GET_CHECKS_BY_STORE_ID_REQUESTED = 'reports/GET_CHECKS_BY_STORE_ID_REQUESTED';
export const GET_CHECKS_BY_STORE_ID_SUCCESS = 'reports/GET_CHECKS_BY_STORE_ID_SUCCESS';
export const GET_CHECKS_BY_STORE_ID_FAILURE = 'reports/GET_CHECKS_BY_STORE_ID_FAILURE';

export const POST_SENSOR_REPORT_REQUESTED = 'reports/POST_SENSOR_REPORT_REQUESTED';
export const POST_SENSOR_REPORT_SUCCESS = 'reports/POST_SENSOR_REPORT_SUCCESS';
export const POST_SENSOR_REPORT_FAILURE = 'reports/POST_SENSOR_REPORT_FAILURE';

export const POST_PAYMENT_ADJ_REPORT_REQUESTED = 'reports/POST_PAYMENT_ADJ_REPORT_REQUESTED';
export const POST_PAYMENT_ADJ_REPORT_SUCCESS = 'reports/POST_PAYMENT_ADJ_REPORT_SUCCESS';
export const POST_PAYMENT_ADJ_REPORT_FAILURE = 'reports/POST_PAYMENT_ADJ_REPORT_FAILURE';

export const POST_PRE_AUTH_REPORT_REQUESTED = 'reports/POST_PRE_AUTH_REPORT_REQUESTED';
export const POST_PRE_AUTH_REPORT_SUCCESS = 'reports/POST_PRE_AUTH_REPORT_SUCCESS';
export const POST_PRE_AUTH_REPORT_FAILURE = 'reports/POST_PRE_AUTH_REPORT_FAILURE';

export const POST_MENU_ITEM_AVAILBILITY_REQUESTED = 'reports/POST_MENU_ITEM_AVAILBILITY_REQUESTED';
export const POST_MENU_ITEM_AVAILBILITY_SUCCESS = 'reports/POST_MENU_ITEM_AVAILBILITYT_SUCCESS';
export const POST_MENU_ITEM_AVAILBILITY_FAILURE = 'reports/POST_MENU_ITEM_AVAILBILITY_FAILURE';

export const POST_MODIFIER_ITEM_AVAILBILITY_REQUESTED = 'reports/POST_MODIFIER_ITEM_AVAILBILITY_REQUESTED';
export const POST_MODIFIER_ITEM_AVAILBILITY_SUCCESS = 'reports/POST_MODIFIER_ITEM_AVAILBILITYT_SUCCESS';
export const POST_MODIFIER_ITEM_AVAILBILITY_FAILURE = 'reports/POST_MODIFIER_ITEM_AVAILBILITY_FAILURE';

export const GET_GUEST_CHARGE_BACK_REQUESTED = 'reports/GET_GUEST_CHARGE_BACK_REQUESTED';
export const GET_GUEST_CHARGE_BACK_SUCCESS = 'reports/GET_GUEST_CHARGE_BACKT_SUCCESS';
export const GET_GUEST_CHARGE_BACK_FAILURE = 'reports/GET_GUEST_CHARGE_BACK_FAILURE';

export const ORDER_REPORT_REQUESTED = 'reports/ORDER_REPORT_REQUESTED';
export const ORDER_REPORT_SUCCESS = 'reports/ORDER_REPORT_SUCCESS';
export const ORDER_REPORT_FAILURE = 'reports/ORDER_REPORT_FAILURE';

export const ORDER_ADVANCE_REPORT_REQUESTED = 'reports/ORDER_ADVANCE_REPORT_REQUESTED';
export const ORDER_ADVANCE_REPORT_SUCCESS = 'reports/ORDER_ADVANCE_REPORT_SUCCESS';
export const ORDER_ADVANCE_REPORT_FAILURE = 'reports/ORDER_ADVANCE_REPORT_FAILURE';

export const ORDER_DISTRIBUTION_REPORT_REQUESTED = 'reports/ORDER_DISTRIBUTION_REPORT_REQUESTED';
export const ORDER_DISTRIBUTION_REPORT_SUCCESS = 'reports/ORDER_DISTRIBUTION_REPORT_SUCCESS';
export const ORDER_DISTRIBUTION_REPORT_FAILURE = 'reports/ORDER_DISTRIBUTION_REPORT_FAILURE';

export const APILOGS_REPORT_REQUESTED = 'reports/APILOGS_REPORT_REQUESTED';
export const APILOGS_REPORT_SUCCESS = 'reports/APILOGS_REPORT_SUCCESS';
export const APILOGS_REPORT_FAILURE = 'reports/APILOGS_REPORT_FAILURE';

export const DISPATCH_TIP_REPORT_REQUESTED = 'reports/DISPATCH_TIP_REPORT_REQUESTED';
export const DISPATCH_TIP_REPORT_SUCCESS = 'reports/DISPATCH_TIP_REPORT_SUCCESS';
export const DISPATCH_TIP_REPORT_FAILURE = 'reports/DISPATCH_TIP_REPORT_FAILURE';

export const MARKETPLACE_ORDERS_REPORT_REQUESTED = 'reports/MARKETPLACE_ORDERS_REPORT_REQUESTED';
export const MARKETPLACE_ORDERS_REPORT_SUCCESS = 'reports/MARKETPLACE_ORDERS_REPORT_SUCCESS';
export const MARKETPLACE_ORDERS_REPORT_FAILURE = 'reports/MARKETPLACE_ORDERS_REPORT_FAILURE';

export const API_LOGS_DETAIL_REPORT_REQUESTED = 'reports/API_LOGS_DETAIL_REPORT_REQUESTED';
export const API_LOGS_DETAIL_REPORT_SUCCESS = 'reports/API_LOGS_DETAIL_REPORT_SUCCESS';
export const API_LOGS_DETAIL_REPORT_FAILURE = 'reports/API_LOGS_DETAIL_REPORT_FAILURE';

export const DATA_REQUEST_REPORT_REQUESTED = 'reports/DATA_REQUEST_REPORT_REQUESTED';
export const DATA_REQUEST_REPORT_SUCCESS = 'reports/DATA_REQUEST_REPORT_SUCCESS';
export const DATA_REQUEST_REPORT_FAILURE = 'reports/DATA_REQUEST_REPORT_FAILURE';

export const CLEAR_REPORTS_DATA = 'reports/CLEAR_REPORTS_DATA';

export const APILOGS_STATUS_REQUESTED = 'reports/APILOGS_STATUS_REQUESTED';
export const APILOGS_STATUS_SUCCESS = 'reports/APILOGS_STATUS_SUCCESS';
export const APILOGS_STATUS_FAILURE = 'reports/APILOGS_STATUS_FAILURE';

export const UPDATE_DATA_REQUEST_GUEST_CONFIRM_REQUESTED = 'reports/UPDATE_DATA_REQUEST_GUEST_CONFIRM_REQUESTED';
export const UPDATE_DATA_REQUEST_GUEST_CONFIRM_SUCCESS = 'reports/UPDATE_DATA_REQUEST_GUEST_CONFIRM_SUCCESS';
export const UPDATE_DATA_REQUEST_GUEST_CONFIRM_FAILURE = 'reports/UPDATE_DATA_REQUEST_GUEST_CONFIRM_FAILURE';

export const DELETE_DATA_REQUEST_REPORT_REQUESTED = 'reports/DELETE_DATA_REQUEST_REPORT_REQUESTED';
export const DELETE_DATA_REQUEST_REPORT_SUCCESS = 'reports/DELETE_DATA_REQUEST_REPORT_SUCCESS';
export const DELETE_DATA_REQUEST_REPORT_FAILURE = 'reports/DELETE_DATA_REQUEST_REPORT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  gatewayList: [],
  reports: null,
  reconciliationSummery: [],
  guestChargeBackList: [],
  apiLogsDetailData: null,
  apiHealthStatus: null,
  apiReportHealthStatus: null,
  dataRequestData: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_GATEWAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_GATEWAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        gatewayList: action.result,
      };
    }
    case GET_GATEWAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_BATCH_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BATCH_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result && action.result.compressedBytes && decriptResponse(action.result.compressedBytes),
      };
    }
    case POST_BATCH_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_ADMIN_BATCH_DETAIL_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ADMIN_BATCH_DETAIL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_ADMIN_BATCH_DETAIL_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_BATCH_SUMMARY_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_BATCH_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result && action.result.compressedBytes && decriptResponse(action.result.compressedBytes),
      };
    }
    case POST_BATCH_SUMMARY_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_TRANSACTION_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_TRANSACTION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_TRANSACTION_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_ITEMSALES_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ITEMSALES_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_ITEMSALES_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_RECONCILLATION_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_RECONCILLATION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.details,
        reconciliationSummery: action.result.summaries
      };
    }
    case POST_RECONCILLATION_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case SURVEY_REPORT_TICKET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SURVEY_REPORT_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SURVEY_REPORT_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHECKS_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CHECKS_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case GET_CHECKS_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case POST_SENSOR_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_SENSOR_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_SENSOR_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case POST_SURVEY_QUESTION_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_SURVEY_QUESTION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body,
      };
    }
    case POST_SURVEY_QUESTION_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case CLEAR_REPORTS_DATA: {
      return {
        ...state,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_PAYMENT_ADJ_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_PAYMENT_ADJ_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_PAYMENT_ADJ_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_PRE_AUTH_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_PRE_AUTH_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result,
      };
    }
    case POST_PRE_AUTH_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_MENU_ITEM_AVAILBILITY_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_MENU_ITEM_AVAILBILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: action.result,
      };
    }
    case POST_MENU_ITEM_AVAILBILITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_MODIFIER_ITEM_AVAILBILITY_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case POST_MODIFIER_ITEM_AVAILBILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: action.result,
      };
    }
    case POST_MODIFIER_ITEM_AVAILBILITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
        reconciliationSummery: [],
      };
    }
    case GET_GUEST_CHARGE_BACK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        guestChargeBackList: [],
      };
    }
    case GET_GUEST_CHARGE_BACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        guestChargeBackList: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body,
      };
    }
    case GET_GUEST_CHARGE_BACK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        guestChargeBackList: []
      };
    }
    case ORDER_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ORDER_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // orderReportsData: action.result.data.body
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case ORDER_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case ORDER_ADVANCE_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ORDER_ADVANCE_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // orderReportsData: action.result.data.body
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case ORDER_ADVANCE_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case ORDER_DISTRIBUTION_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ORDER_DISTRIBUTION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // orderReportsData: action.result.data.body
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case ORDER_DISTRIBUTION_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case APILOGS_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case APILOGS_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body,
        apiReportHealthStatus: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).etlStatus : action.result.data.etlStatus
      };
    }
    case APILOGS_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case DISPATCH_TIP_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DISPATCH_TIP_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case DISPATCH_TIP_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case MARKETPLACE_ORDERS_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case MARKETPLACE_ORDERS_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case MARKETPLACE_ORDERS_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case API_LOGS_DETAIL_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case API_LOGS_DETAIL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        apiLogsDetailData: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case API_LOGS_DETAIL_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case DATA_REQUEST_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DATA_REQUEST_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body
      };
    }
    case DATA_REQUEST_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case APILOGS_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case APILOGS_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        apiHealthStatus: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData) : action.result.data.body
      };
    }
    case APILOGS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        reports: null,
      };
    }
    case DELETE_DATA_REQUEST_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DATA_REQUEST_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_DATA_REQUEST_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_DATA_REQUEST_GUEST_CONFIRM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DATA_REQUEST_GUEST_CONFIRM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DATA_REQUEST_GUEST_CONFIRM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const postItemSalesReport = (data) => {
  return {
    types: [POST_ITEMSALES_REPORT_REQUESTED, POST_ITEMSALES_REPORT_SUCCESS, POST_ITEMSALES_REPORT_FAILURE],
    promise: client => client.post('itemSalesReport', { data })
  };
};

export const getGateway = (data) => {
  return {
    types: [GET_GATEWAY_REQUESTED, GET_GATEWAY_SUCCESS, GET_GATEWAY_FAILURE],
    promise: client => client.get('getPaymentSystems', { data })
  };
};

export const postSensorsReports = (data) => {
  return {
    types: [POST_SENSOR_REPORT_REQUESTED, POST_SENSOR_REPORT_SUCCESS, POST_SENSOR_REPORT_FAILURE],
    promise: client => client.post('sensorReport', { data })
  };
};

export const postBatchReport = (data) => {
  return {
    types: [POST_BATCH_REPORT_REQUESTED, POST_BATCH_REPORT_SUCCESS, POST_BATCH_REPORT_FAILURE],
    promise: client => client.post('postBatchReport', { data })
  };
};

export const postBatchReportNew = (data) => {
  return {
    types: [POST_BATCH_REPORT_REQUESTED, POST_BATCH_REPORT_SUCCESS, POST_BATCH_REPORT_FAILURE],
    promise: client => client.post('postBatchReportNew', { data })
  };
};

export const postTransactionReport = (data) => {
  return {
    types: [POST_TRANSACTION_REPORT_REQUESTED, POST_TRANSACTION_REPORT_SUCCESS, POST_TRANSACTION_REPORT_FAILURE],
    promise: client => client.post('transactionReport', { data })
  };
};

export const postTransactionReportNew = (data) => {
  return {
    types: [POST_TRANSACTION_REPORT_REQUESTED, POST_TRANSACTION_REPORT_SUCCESS, POST_TRANSACTION_REPORT_FAILURE],
    promise: client => client.post('postTransactionReportNew', { data })
  };
};

export const postReconcilationReport = (data) => {
  return {
    types: [POST_RECONCILLATION_REPORT_REQUESTED, POST_RECONCILLATION_REPORT_SUCCESS, POST_RECONCILLATION_REPORT_FAILURE],
    promise: client => client.post('reconciliationReport', { data })
  };
};

export const postSurveyQuestionReport = (data) => {
  return {
    types: [POST_SURVEY_QUESTION_REPORT_REQUESTED, POST_SURVEY_QUESTION_REPORT_SUCCESS, POST_SURVEY_QUESTION_REPORT_FAILURE],
    promise: client => client.post('surveyQuestionReport', { data })
  };
};

export const surveyReportTicket = (data) => {
  return {
    types: [SURVEY_REPORT_TICKET_REQUESTED, SURVEY_REPORT_TICKET_SUCCESS, SURVEY_REPORT_TICKET_FAILURE],
    promise: client => client.post('surveyReportTicket', { data })
  };
};

export const getChecksByStore = (storeId, businessDate) => {
  return {
    types: [GET_CHECKS_BY_STORE_ID_REQUESTED, GET_CHECKS_BY_STORE_ID_SUCCESS, GET_CHECKS_BY_STORE_ID_FAILURE],
    promise: client => client.get(`getChecksByStore/${storeId}/businessDate/${businessDate}`)
  };
};

export const resetReportsData = () => {
  return {
    type: CLEAR_REPORTS_DATA,
  };
};

export const postPaymentAdjuestmentReport = (data) => {
  return {
    types: [POST_PAYMENT_ADJ_REPORT_REQUESTED, POST_PAYMENT_ADJ_REPORT_SUCCESS, POST_PAYMENT_ADJ_REPORT_FAILURE],
    promise: client => client.post('paymentAdjustmentReport', { data })
  };
};
export const postBatchSummaryReport = (data) => {
  return {
    types: [POST_BATCH_SUMMARY_REPORT_REQUESTED, POST_BATCH_SUMMARY_REPORT_SUCCESS, POST_BATCH_SUMMARY_REPORT_FAILURE],
    promise: client => client.post('postBatchSummaryReport', { data })
  };
};

export const postPreAuth = (data) => {
  return {
    types: [POST_PRE_AUTH_REPORT_REQUESTED, POST_PRE_AUTH_REPORT_SUCCESS, POST_PRE_AUTH_REPORT_FAILURE],
    promise: client => client.post('postPreAuth', { data })
  };
};

export const postBatchReportAdmin = (data) => {
  return {
    types: [POST_ADMIN_BATCH_DETAIL_REPORT_REQUESTED, POST_ADMIN_BATCH_DETAIL_REPORT_SUCCESS, POST_ADMIN_BATCH_DETAIL_REPORT_FAILURE],
    promise: client => client.post('postBatchReportAdmin', { data })
  };
};
export const menuItemAvailability = (data) => {
  return {
    types: [POST_MENU_ITEM_AVAILBILITY_REQUESTED, POST_MENU_ITEM_AVAILBILITY_SUCCESS, POST_MENU_ITEM_AVAILBILITY_FAILURE],
    promise: client => client.post('menuItemAvailability', { data })
  };
};

export const modifierItemAvailability = (data) => {
  return {
    types: [POST_MODIFIER_ITEM_AVAILBILITY_REQUESTED, POST_MODIFIER_ITEM_AVAILBILITY_SUCCESS, POST_MODIFIER_ITEM_AVAILBILITY_FAILURE],
    promise: client => client.post('modifierItemAvailability', { data })
  };
};

export const getGuestChargeBack = (data) => {
  return {
    types: [GET_GUEST_CHARGE_BACK_REQUESTED, GET_GUEST_CHARGE_BACK_SUCCESS, GET_GUEST_CHARGE_BACK_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const orderReport = (data) => {
  return {
    types: [ORDER_REPORT_REQUESTED, ORDER_REPORT_SUCCESS, ORDER_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const orderAdvanceReport = (data) => {
  return {
    types: [ORDER_ADVANCE_REPORT_REQUESTED, ORDER_ADVANCE_REPORT_SUCCESS, ORDER_ADVANCE_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const orderDistributionReport = (data) => {
  return {
    types: [ORDER_DISTRIBUTION_REPORT_REQUESTED, ORDER_DISTRIBUTION_REPORT_SUCCESS, ORDER_DISTRIBUTION_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const apiLogsReport = (data) => {
  return {
    types: [APILOGS_REPORT_REQUESTED, APILOGS_REPORT_SUCCESS, APILOGS_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const dispatchTipReport = (data) => {
  return {
    types: [DISPATCH_TIP_REPORT_REQUESTED, DISPATCH_TIP_REPORT_SUCCESS, DISPATCH_TIP_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const marketplaceOrdersReport = (data) => {
  return {
    types: [MARKETPLACE_ORDERS_REPORT_REQUESTED, MARKETPLACE_ORDERS_REPORT_SUCCESS, MARKETPLACE_ORDERS_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const getApiLogDetails = (data) => {
  return {
    types: [API_LOGS_DETAIL_REPORT_REQUESTED, API_LOGS_DETAIL_REPORT_SUCCESS, API_LOGS_DETAIL_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const dataRequestReport = (data, dataRequestId) => {
  return {
    types: [DATA_REQUEST_REPORT_REQUESTED, DATA_REQUEST_REPORT_SUCCESS, DATA_REQUEST_REPORT_FAILURE],
    promise: client => client.get(`getDataRequestReport/${dataRequestId}`, { data })
  };
};

export const apiLogStatus = (data) => {
  return {
    types: [APILOGS_STATUS_REQUESTED, APILOGS_STATUS_SUCCESS, APILOGS_STATUS_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const dataRequestGuestConfirm = (data) => {
  return {
    types: [UPDATE_DATA_REQUEST_GUEST_CONFIRM_REQUESTED, UPDATE_DATA_REQUEST_GUEST_CONFIRM_SUCCESS, UPDATE_DATA_REQUEST_GUEST_CONFIRM_FAILURE],
    promise: client => client.post('getDataRequestGuestConfirm', { data })
  };
};

export const deleteDateRequestReport = (data) => {
  return {
    types: [DELETE_DATA_REQUEST_REPORT_REQUESTED, DELETE_DATA_REQUEST_REPORT_SUCCESS, DELETE_DATA_REQUEST_REPORT_FAILURE],
    promise: client => client.post('deleteDateRequestData', { data })
  };
};
