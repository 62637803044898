export const GET_STATIONS_REQUESTED = 'sensorManagement/GET_STATIONS_REQUESTED';
export const GET_STATIONS_SUCCESS = 'sensorManagement/GET_STATIONS_SUCCESS';
export const GET_STATIONS_FAILURE = 'sensorManagement/GET_STATIONS_FAILURE';

export const REGISTER_SENSOR_REQUESTED = 'sensorManagement/REGISTER_SENSOR_REQUESTED';
export const REGISTER_SENSOR_SUCCESS = 'sensorManagement/REGISTER_SENSOR_SUCCESS';
export const REGISTER_SENSOR_FAILURE = 'sensorManagement/REGISTER_SENSOR_FAILURE';

export const SENSOR_GROUPS_REQUESTED = 'sensorManagement/SENSOR_GROUPS_REQUESTED';
export const SENSOR_GROUPS_SUCCESS = 'sensorManagement/SENSOR_GROUPS_SUCCESS';
export const SENSOR_GROUPS_FAILURE = 'sensorManagement/SENSOR_GROUPS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  stationsList: [],
  sensorGroupsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_STATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        stationsList: action.result
      };
    }
    case GET_STATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case REGISTER_SENSOR_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case REGISTER_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case REGISTER_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case SENSOR_GROUPS_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case SENSOR_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sensorGroupsList: action.result
      };
    case SENSOR_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export const getStationsListByStoreId = (storeId) => {
  return {
    types: [GET_STATIONS_REQUESTED, GET_STATIONS_SUCCESS, GET_STATIONS_FAILURE],
    promise: client => client.get(`getStationsByStoreId/${storeId}`),
  };
};

export const registerSensor = (data) => {
  return {
    types: [REGISTER_SENSOR_REQUESTED, REGISTER_SENSOR_SUCCESS, REGISTER_SENSOR_FAILURE],
    promise: client => client.post('registerSensor', {
      data
    }),
  };
};

export const sensorGroups = (id) => {
  return {
    types: [SENSOR_GROUPS_REQUESTED, SENSOR_GROUPS_SUCCESS, SENSOR_GROUPS_FAILURE],
    promise: client => client.get(`sensorGroups/${id}`),
  };
};
