/* Portal Version - Main content - Layout View configurations */

import { getConfigData } from '@utility/storageOperations';


const DEFAULT_LAYOUT_VIEW = 'grid';
const DEFAULT_GRID_SIZE = 1;
export const SET_LAYOUT_VIEW = 'configData/SET_LAYOUT_VIEW';
export const SET_GRID_SIZE = 'configData/SET_GRID_SIZE';

const initialState = {
  layoutView: (getConfigData() && getConfigData().layout) || DEFAULT_LAYOUT_VIEW,
  gridSize: (getConfigData() && getConfigData().gridSize) || DEFAULT_GRID_SIZE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_VIEW: {
      return {
        ...state,
        layoutView: action.payload
      };
    }
    case SET_GRID_SIZE: {
      return {
        ...state,
        gridSize: action.payload
      };
    }
    default:
      return state;
  }
};

export const setLayoutViewData = (data) => {
  return {
    type: SET_LAYOUT_VIEW,
    payload: data
  };
};

export const setGridSizeData = (data) => {
  return {
    type: SET_GRID_SIZE,
    payload: data
  };
};
