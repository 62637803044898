export const GET_GIFTCARD_REQUESTED = 'brand/GET_GIFTCARD_REQUESTED';
export const GET_GIFTCARD_SUCCESS = 'brand/GET_GIFTCARD_SUCCESS';
export const GET_GIFTCARD_FAILURE = 'brand/GET_GIFTCARD_FAILURE';

export const UPDATE_GIFTCARD_REQUESTED = 'brand/UPDATE_GIFTCARD_REQUESTED';
export const UPDATE_GIFTCARD_SUCCESS = 'brand/UPDATE_GIFTCARD_SUCCESS';
export const UPDATE_GIFTCARD_FAILURE = 'brand/UPDATE_GIFTCARD_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  giftcardList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_GIFTCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        giftcardList: null,
      };
    }
    case GET_GIFTCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        giftcardList: action.result,
      };
    }
    case GET_GIFTCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_GIFTCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_GIFTCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_GIFTCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getGiftcard = (brandId) => {
  return {
    types: [GET_GIFTCARD_REQUESTED, GET_GIFTCARD_SUCCESS, GET_GIFTCARD_FAILURE],
    promise: client => client.get(`getGiftCardConfig/${brandId}`)
  };
};

export const updateGiftcard = (brandId, data) => {
  return {
    types: [UPDATE_GIFTCARD_REQUESTED, UPDATE_GIFTCARD_SUCCESS, UPDATE_GIFTCARD_FAILURE],
    promise: client => client.post(`updateGiftCardConfig/${brandId}`, { data })
  };
};
