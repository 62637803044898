export const GET_GIVEX_CONFIG_REQUESTED = 'brand/GET_GIVEX_CONFIG_REQUESTED';
export const GET_GIVEX_CONFIG_SUCCESS = 'brand/GET_GIVEX_CONFIG_SUCCESS';
export const GET_GIVEX_CONFIG_FAILURE = 'brand/GET_GIVEX_CONFIG_FAILURE';

export const UPDATE_GIVEX_CONFIG_REQUESTED = 'brand/UPDATE_GIVEX_CONFIG_REQUESTED';
export const UPDATE_GIVEX_CONFIG_SUCCESS = 'brand/UPDATE_GIVEX_CONFIG_SUCCESS';
export const UPDATE_GIVEX_CONFIG_FAILURE = 'brand/UPDATE_GIVEX_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  givexList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_GIVEX_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        givexList: [],
      };
    }
    case GET_GIVEX_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        givexList: action.result,
      };
    }
    case GET_GIVEX_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_GIVEX_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_GIVEX_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_GIVEX_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getGivexConfig = (brandId) => {
  return {
    types: [GET_GIVEX_CONFIG_REQUESTED, GET_GIVEX_CONFIG_SUCCESS, GET_GIVEX_CONFIG_FAILURE],
    promise: client => client.get(`getGivexConfig/${brandId}`)
  };
};

export const postGivexConfig = (brandId, data) => {
  return {
    types: [UPDATE_GIVEX_CONFIG_REQUESTED, UPDATE_GIVEX_CONFIG_SUCCESS, UPDATE_GIVEX_CONFIG_FAILURE],
    promise: client => client.post(`postGivexConfig/${brandId}`, { data })
  };
};
