export const GET_MOBILE_IMAGE_MEDIA_REQUESTED = 'mobileApp/GET_MOBILE_IMAGE_MEDIA_REQUESTED';
export const GET_MOBILE_IMAGE_MEDIA_SUCCESS = 'mobileApp/GET_MOBILE_IMAGE_MEDIA_SUCCESS';
export const GET_MOBILE_IMAGE_MEDIA_FAILURE = 'mobileApp/GET_MOBILE_IMAGE_MEDIA_FAILURE';

export const GET_MEDIA_BY_ID_REQUESTED = 'mobileApp/GET_MEDIA_BY_ID_REQUESTED';
export const GET_MEDIA_BY_ID_SUCCESS = 'mobileApp/GET_MEDIA_BY_ID_SUCCESS';
export const GET_MEDIA_BY_ID_FAILURE = 'mobileApp/GET_MEDIA_BY_ID_FAILURE';

export const ADD_MEDIA_REQUESTED = 'mobileApp/ADD_MEDIA_REQUESTED';
export const ADD_MEDIA_SUCCESS = 'mobileApp/ADD_MEDIA_SUCCESS';
export const ADD_MEDIA_FAILURE = 'mobileApp/ADD_MEDIA_FAILURE';

export const UPDATE_MEDIA_REQUESTED = 'mobileApp/UPDATE_MEDIA_REQUESTED';
export const UPDATE_MEDIA_SUCCESS = 'mobileApp/UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILURE = 'mobileApp/UPDATE_MEDIA_FAILURE';

export const MEDIA_IMAGE_ACTION_REQUESTED = 'mobileApp/MEDIA_IMAGE_ACTION_REQUESTED';
export const MEDIA_IMAGE_ACTION_SUCCESS = 'mobileApp/MEDIA_IMAGE_ACTION_SUCCESS';
export const MEDIA_IMAGE_ACTION_FAILURE = 'mobileApp/MEDIA_IMAGE_ACTION_FAILURE';

export const GET_MOBILE_APP_PAGES_DATA_REQUESTED = 'mobileApp/GET_MOBILE_APP_PAGES_DATA_REQUESTED';
export const GET_MOBILE_APP_PAGES_DATA_SUCCESS = 'mobileApp/GET_MOBILE_APP_PAGES_DATA_SUCCESS';
export const GET_MOBILE_APP_PAGES_DATA_FAILURE = 'mobileApp/GET_MOBILE_APP_PAGES_DATA_FAILURE';

export const UPDATE_MOBILE_APP_PAGES_DATA_REQUESTED = 'mobileApp/UPDATE_MOBILE_APP_PAGES_DATA_REQUESTED';
export const UPDATE_MOBILE_APP_PAGES_DATA_SUCCESS = 'mobileApp/UPDATE_MOBILE_APP_PAGES_DATA_SUCCESS';
export const UPDATE_MOBILE_APP_PAGES_DATA_FAILURE = 'mobileApp/UPDATE_MOBILE_APP_PAGES_DATA_FAILURE';

export const ADD_MOBILE_APP_PAGES_DATA_REQUESTED = 'mobileApp/ADD_MOBILE_APP_PAGES_DATA_REQUESTED';
export const ADD_MOBILE_APP_PAGES_DATA_SUCCESS = 'mobileApp/ADD_MOBILE_APP_PAGES_DATA_SUCCESS';
export const ADD_MOBILE_APP_PAGES_DATA_FAILURE = 'mobileApp/ADD_MOBILE_APP_PAGES_DATA_FAILURE';

export const GET_MOBILE_APP_PAGES_TYPES_REQUESTED = 'mobileApp/GET_MOBILE_APP_PAGES_TYPES_REQUESTED';
export const GET_MOBILE_APP_PAGES_TYPES_SUCCESS = 'mobileApp/GET_MOBILE_APP_PAGES_TYPES_SUCCESS';
export const GET_MOBILE_APP_PAGES_TYPES_FAILURE = 'mobileApp/GET_MOBILE_APP_PAGES_TYPES_FAILURE';

export const GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_REQUESTED = 'mobileApp/GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_REQUESTED';
export const GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_SUCCESS = 'mobileApp/GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_SUCCESS';
export const GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_FAILURE = 'mobileApp/GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_FAILURE';

export const GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_REQUESTED = 'mobileApp/GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_REQUESTED';
export const GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_SUCCESS = 'mobileApp/GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_SUCCESS';
export const GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_FAILURE = 'mobileApp/GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_FAILURE';

export const GET_MOBILE_APP_LAYOUTS_LIST_REQUESTED = 'mobileApp/GET_MOBILE_APP_LAYOUTS_LIST_REQUESTED';
export const GET_MOBILE_APP_LAYOUTS_LIST_SUCCESS = 'mobileApp/GET_MOBILE_APP_LAYOUTS_LIST_SUCCESS';
export const GET_MOBILE_APP_LAYOUTS_LIST_FAILURE = 'mobileApp/GET_MOBILE_APP_LAYOUTS_LIST_FAILURE';

export const GET_MOBILE_APP_LAYOUTS_DATA_REQUESTED = 'mobileApp/GET_MOBILE_APP_LAYOUTS_DATA_REQUESTED';
export const GET_MOBILE_APP_LAYOUTS_DATA_SUCCESS = 'mobileApp/GET_MOBILE_APP_LAYOUTS_DATA_SUCCESS';
export const GET_MOBILE_APP_LAYOUTS_DATA_FAILURE = 'mobileApp/GET_MOBILE_APP_LAYOUTS_DATA_FAILURE';

export const ADD_MOBILE_APP_LAYOUT_DATA_REQUESTED = 'mobileApp/ADD_MOBILE_APP_LAYOUT_DATA_REQUESTED';
export const ADD_MOBILE_APP_LAYOUT_DATA_SUCCESS = 'mobileApp/ADD_MOBILE_APP_LAYOUT_DATA_SUCCESS';
export const ADD_MOBILE_APP_LAYOUT_DATA_FAILURE = 'mobileApp/ADD_MOBILE_APP_LAYOUT_DATA_FAILURE';

export const UPDATE_MOBILE_APP_LAYOUT_DATA_REQUESTED = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DATA_REQUESTED';
export const UPDATE_MOBILE_APP_LAYOUT_DATA_SUCCESS = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DATA_SUCCESS';
export const UPDATE_MOBILE_APP_LAYOUT_DATA_FAILURE = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DATA_FAILURE';

export const ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_REQUESTED = 'mobileApp/ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_REQUESTED';
export const ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_SUCCESS = 'mobileApp/ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_SUCCESS';
export const ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_FAILURE = 'mobileApp/ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_FAILURE';

export const ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_REQUESTED = 'mobileApp/ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_REQUESTED';
export const ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_SUCCESS = 'mobileApp/ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_SUCCESS';
export const ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_FAILURE = 'mobileApp/ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_FAILURE';

export const GET_MOBILE_APP_LAYOUT_DESIGN_REQUESTED = 'mobileApp/GET_MOBILE_APP_LAYOUT_DESIGN_REQUESTED';
export const GET_MOBILE_APP_LAYOUT_DESIGN_SUCCESS = 'mobileApp/GET_MOBILE_APP_LAYOUT_DESIGN_SUCCESS';
export const GET_MOBILE_APP_LAYOUT_DESIGN_FAILURE = 'mobileApp/GET_MOBILE_APP_LAYOUT_DESIGN_FAILURE';

export const UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_REQUESTED = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_REQUESTED';
export const UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_SUCCESS = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_SUCCESS';
export const UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_FAILURE = 'mobileApp/UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_FAILURE';

export const GET_MOBILE_APP_PAGE_DETAIL_DATA_REQUESTED = 'mobileApp/GET_MOBILE_APP_PAGE_DETAIL_DATA_REQUESTED';
export const GET_MOBILE_APP_PAGE_DETAIL_DATA_SUCCESS = 'mobileApp/GET_MOBILE_APP_PAGE_DETAIL_DATA_SUCCESS';
export const GET_MOBILE_APP_PAGE_DETAIL_DATA_FAILURE = 'mobileApp/GET_MOBILE_APP_PAGE_DETAIL_DATA_FAILURE';

export const UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_REQUESTED = 'mobileApp/UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_REQUESTED';
export const UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_SUCCESS = 'mobileApp/UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_SUCCESS';
export const UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_FAILURE = 'mobileApp/UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_FAILURE';

export const DELETE_LAYOUT_REQUESTED = 'brandCharity/DELETE_LAYOUT_REQUESTED';
export const DELETE_LAYOUT_SUCCESS = 'brandCharity/DELETE_LAYOUT_SUCCESS';
export const DELETE_LAYOUT_FAILURE = 'brandCharity/DELETE_LAYOUT_FAILURE';

export const DELETE_MEDIA_REQUESTED = 'mobileApp/DELETE_MEDIA_REQUESTED';
export const DELETE_MEDIA_SUCCESS = 'mobileApp/DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILURE = 'mobileApp/DELETE_MEDIA_FAILURE';

export const DELETE_PAGE_REQUESTED = 'brandCharity/DELETE_PAGE_REQUESTED';
export const DELETE_PAGE_SUCCESS = 'brandCharity/DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'brandCharity/DELETE_PAGE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  mobileAppMediaList: [],
  mobileAppPagesList: [],
  mobileAppPageTypes: [],
  mobileAppBuiltInPageTypes: [],
  getMediaObjectData: [],
  mobileAppLayoutsList: null,
  mobileAppLayoutsData: null,
  pageData: null,
  mobileAppLayoutDesignData: null,
  mobileAppPageDetailData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MOBILE_IMAGE_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        mobileAppMediaList: []
      };
    }
    case GET_MOBILE_IMAGE_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppMediaList: action.result,
      };
    }
    case GET_MOBILE_IMAGE_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MEDIA_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MEDIA_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMediaObjectData: action.result,
      };
    }
    case GET_MEDIA_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MEDIA_IMAGE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case MEDIA_IMAGE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case MEDIA_IMAGE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_PAGES_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        mobileAppPagesList: [],
      };
    }
    case GET_MOBILE_APP_PAGES_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppPagesList: action.result,
      };
    }
    case GET_MOBILE_APP_PAGES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MOBILE_APP_PAGES_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MOBILE_APP_PAGES_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MOBILE_APP_PAGES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MOBILE_APP_PAGES_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MOBILE_APP_PAGES_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MOBILE_APP_PAGES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_PAGES_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_PAGES_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppPageTypes: action.result,
      };
    }
    case GET_MOBILE_APP_PAGES_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppBuiltInPageTypes: action.result,
      };
    }
    case GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        pageData: null,
      };
    }
    case GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        pageData: action.result,
      };
    }
    case GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppLayoutsList: action.result,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppLayoutsData: action.result,
      };
    }
    case GET_MOBILE_APP_LAYOUTS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MOBILE_APP_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MOBILE_APP_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MOBILE_APP_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_LAYOUT_DESIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_LAYOUT_DESIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppLayoutDesignData: action.result,
      };
    }
    case GET_MOBILE_APP_LAYOUT_DESIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MOBILE_APP_PAGE_DETAIL_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MOBILE_APP_PAGE_DETAIL_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobileAppPageDetailData: action.result,
      };
    }
    case GET_MOBILE_APP_PAGE_DETAIL_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_LAYOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_LAYOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_LAYOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_PAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

// Media list api
export const getMobileMediaData = (brandId) => {
  return {
    types: [GET_MOBILE_IMAGE_MEDIA_REQUESTED, GET_MOBILE_IMAGE_MEDIA_SUCCESS, GET_MOBILE_IMAGE_MEDIA_FAILURE],
    promise: client => client.get(`getMobileAppMedia/${brandId}`)
  };
};

export const getMobileAppMediaById = (mediaId) => {
  return {
    types: [GET_MEDIA_BY_ID_REQUESTED, GET_MEDIA_BY_ID_SUCCESS, GET_MEDIA_BY_ID_FAILURE],
    promise: client => client.get(`getMobileAppMediaById/${mediaId}`)
  };
};

export const addMobileAppMediaSetup = (data) => {
  return {
    types: [ADD_MEDIA_REQUESTED, ADD_MEDIA_SUCCESS, ADD_MEDIA_FAILURE],
    promise: client => client.post('addMobileAppMediaSetup', { data })
  };
};

export const updateMobileAppMediaSetup = (data) => {
  return {
    types: [UPDATE_MEDIA_REQUESTED, UPDATE_MEDIA_SUCCESS, UPDATE_MEDIA_FAILURE],
    promise: client => client.post('updateMobileAppMediaSetup', { data })
  };
};


// Image upload api
export const uploadMobileAppMediaImage = (mediaId, data) => {
  return {
    types: [MEDIA_IMAGE_ACTION_REQUESTED, MEDIA_IMAGE_ACTION_SUCCESS, MEDIA_IMAGE_ACTION_FAILURE],
    promise: client => client.post(`uploadMobileAppMediaImage/${mediaId}`, { data })
  };
};

export const updateMobileAppPage = (data) => {
  return {
    types: [UPDATE_MOBILE_APP_PAGES_DATA_REQUESTED, UPDATE_MOBILE_APP_PAGES_DATA_SUCCESS, UPDATE_MOBILE_APP_PAGES_DATA_FAILURE],
    promise: client => client.post('updateMobileAppPage', { data })
  };
};

export const addMobileAppPages = (data) => {
  return {
    types: [ADD_MOBILE_APP_PAGES_DATA_REQUESTED, ADD_MOBILE_APP_PAGES_DATA_SUCCESS, ADD_MOBILE_APP_PAGES_DATA_FAILURE],
    promise: client => client.post('addMobileAppPage', { data })
  };
};

export const getMobileAppPageType = () => {
  return {
    types: [GET_MOBILE_APP_PAGES_TYPES_REQUESTED, GET_MOBILE_APP_PAGES_TYPES_SUCCESS, GET_MOBILE_APP_PAGES_TYPES_FAILURE],
    promise: client => client.get('mobileAppPageType')
  };
};

export const getMobileAppBuiltInPageTypes = () => {
  return {
    types: [GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_REQUESTED, GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_SUCCESS, GET_MOBILE_APP_BUILT_IN_PAGE_TYPES_FAILURE],
    promise: client => client.get('mobileAppBuiltInPageTypes')
  };
};

export const getMobileAppPages = (brandId) => {
  return {
    types: [GET_MOBILE_APP_PAGES_DATA_REQUESTED, GET_MOBILE_APP_PAGES_DATA_SUCCESS, GET_MOBILE_APP_PAGES_DATA_FAILURE],
    promise: client => client.get(`getMobileAppPagesByBrandId/${brandId}`)
  };
};

export const getPageDataByPageID = (pageId) => {
  return {
    types: [GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_REQUESTED, GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_SUCCESS, GET_MOBILE_APP_PAGE_DATA_BY_PAGEID_FAILURE],
    promise: client => client.get(`mobileAppPageDataByPageID/${pageId}`)
  };
};

export const getMobileAppLayoutsByBrandId = (brandId) => {
  return {
    types: [GET_MOBILE_APP_LAYOUTS_LIST_REQUESTED, GET_MOBILE_APP_LAYOUTS_LIST_SUCCESS, GET_MOBILE_APP_LAYOUTS_LIST_FAILURE],
    promise: client => client.get(`getMobileAppLayoutsByBrandId/${brandId}`)
  };
};

export const getMobileAppLayoutData = (layoutId) => {
  return {
    types: [GET_MOBILE_APP_LAYOUTS_DATA_REQUESTED, GET_MOBILE_APP_LAYOUTS_DATA_SUCCESS, GET_MOBILE_APP_LAYOUTS_DATA_FAILURE],
    promise: client => client.get(`getMobileAppLayout/${layoutId}`)
  };
};

export const addMobileAppLayout = (data) => {
  return {
    types: [ADD_MOBILE_APP_LAYOUT_DATA_REQUESTED, ADD_MOBILE_APP_LAYOUT_DATA_SUCCESS, ADD_MOBILE_APP_LAYOUT_DATA_FAILURE],
    promise: client => client.post('addMobileAppLayout', { data })
  };
};

export const updateMobileAppLayout = (data) => {
  return {
    types: [UPDATE_MOBILE_APP_LAYOUT_DATA_REQUESTED, UPDATE_MOBILE_APP_LAYOUT_DATA_SUCCESS, UPDATE_MOBILE_APP_LAYOUT_DATA_FAILURE],
    promise: client => client.post('updateMobileAppLayout', { data })
  };
};

export const enableProdMobileAppLayout = (layoutId) => {
  return {
    types: [ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_REQUESTED, ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_SUCCESS, ENABLE_PROD_MOBILE_APP_LAYOUT_DATA_FAILURE],
    promise: client => client.get(`enableProdMobileAppLayout/${layoutId}`)
  };
};

export const enableTestMobileAppLayout = (layoutId) => {
  return {
    types: [ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_REQUESTED, ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_SUCCESS, ENABLE_TEST_MOBILE_APP_LAYOUT_DATA_FAILURE],
    promise: client => client.get(`enableTestMobileAppLayout/${layoutId}`)
  };
};

export const getMobileAppLayoutDesign = (layoutId) => {
  return {
    types: [GET_MOBILE_APP_LAYOUT_DESIGN_REQUESTED, GET_MOBILE_APP_LAYOUT_DESIGN_SUCCESS, GET_MOBILE_APP_LAYOUT_DESIGN_FAILURE],
    promise: client => client.get(`getMobileAppLayoutDesign/${layoutId}`)
  };
};

export const updateMobileAppLayoutDesign = (data) => {
  return {
    types: [UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_REQUESTED, UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_SUCCESS, UPDATE_MOBILE_APP_LAYOUT_DESIGN_DATA_FAILURE],
    promise: client => client.post('updateMobileAppLayoutDesign', { data })
  };
};

export const getMobileAppPageDetail = (pageId) => {
  return {
    types: [GET_MOBILE_APP_PAGE_DETAIL_DATA_REQUESTED, GET_MOBILE_APP_PAGE_DETAIL_DATA_SUCCESS, GET_MOBILE_APP_PAGE_DETAIL_DATA_FAILURE],
    promise: client => client.get(`getMobileAppPageDetail/${pageId}`)
  };
};

export const updateMobileAppPageDesign = (data) => {
  return {
    types: [UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_REQUESTED, UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_SUCCESS, UPDATE_MOBILE_APP_PAGE_DESIGN_DATA_FAILURE],
    promise: client => client.post('updateMobileAppPageDesign', { data })
  };
};

export const deleteMobileAppLayout = (layoutId) => {
  return {
    types: [DELETE_LAYOUT_REQUESTED, DELETE_LAYOUT_SUCCESS, DELETE_LAYOUT_FAILURE],
    promise: client => client.post(`deleteMobileAppLayout/${layoutId}`)
  };
};

export const deleteMobileAppMedia = (mediaId) => {
  return {
    types: [DELETE_MEDIA_REQUESTED, DELETE_MEDIA_SUCCESS, DELETE_MEDIA_FAILURE],
    promise: client => client.post(`deleteMobileAppMediaSetup/${mediaId}`)
  };
};

export const deleteMobileAppPage = (pageId) => {
  return {
    types: [DELETE_PAGE_REQUESTED, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAILURE],
    promise: client => client.post(`deleteMobileAppPage/${pageId}`)
  };
};
