export const GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  stripeList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        stripeList: null
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stripeList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        ingenicoList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stripeList: action.result,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfigStripe = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigStripe/${storeId}`)
  };
};

export const updateStorePaymentSystemConfigStripe = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_STRIPE_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfigStripe/${storeId}`, { data })
  };
};
