const GET_POSAGENT_STATUS_REQUESTED = 'posAgent/GET_POSAGENT_STATUS_REQUESTED';
const GET_POSAGENT_STATUS_SUCCESS = 'posAgent/GET_POSAGENT_STATUS_SUCCESS';
const GET_POSAGENT_STATUS_FAILURE = 'posAgent/GET_POSAGENT_STATUS_FAILURE';

const GET_POSAGENT_FORMATTED_HEALTH_INFO_REQUESTED = 'posAgent/GET_POSAGENT_FORMATTED_HEALTH_INFO_REQUESTED';
const GET_POSAGENT_FORMATTED_HEALTH_INFO_SUCCESS = 'posAgent/GET_POSAGENT_FORMATTED_HEALTH_INFO_SUCCESS';
const GET_POSAGENT_FORMATTED_HEALTH_INFO_FAILURE = 'posAgent/GET_POSAGENT_FORMATTED_HEALTH_INFO_FAILURE';

const POST_POSAGENT_CHECK_UPDATE_REQUESTED = 'posAgent/POST_POSAGENT_CHECK_UPDATE_REQUESTED';
const POST_POSAGENT_CHECK_UPDATE_SUCCESS = 'posAgent/POST_POSAGENT_CHECK_UPDATE_SUCCESS';
const POST_POSAGENT_CHECK_UPDATE_FAILURE = 'posAgent/POST_POSAGENT_CHECK_UPDATE_FAILURE';

const POST_POSAGENT_REACTIVATE_REQUESTED = 'posAgent/POST_POSAGENT_REACTIVATE_REQUESTED';
const POST_POSAGENT_REACTIVATE_SUCCESS = 'posAgent/POST_POSAGENT_REACTIVATE_SUCCESS';
const POST_POSAGENT_REACTIVATE_FAILURE = 'posAgent/POST_POSAGENT_REACTIVATE_FAILURE';

const POST_POSAGENT_RESTART_REQUESTED = 'posAgent/POST_POSAGENT_RESTART_REQUESTED';
const POST_POSAGENT_RESTART_SUCCESS = 'posAgent/POST_POSAGENT_RESTART_SUCCESS';
const POST_POSAGENT_RESTART_FAILURE = 'posAgent/POST_POSAGENT_RESTART_FAILURE';

const POST_POSAGENT_REFRESH_CACHE_REQUESTED = 'posAgent/POST_POSAGENT_REFRESH_CACHE_REQUESTED';
const POST_POSAGENT_REFRESH_CACHE_SUCCESS = 'posAgent/POST_POSAGENT_REFRESH_CACHE_SUCCESS';
const POST_POSAGENT_REFRESH_CACHE_FAILURE = 'posAgent/POST_POSAGENT_REFRESH_CACHE_FAILURE';

const GET_POSAGENT_MENU_FILE_REQUESTED = 'posAgent/GET_POSAGENT_MENU_FILE_REQUESTED';
const GET_POSAGENT_MENU_FILE_SUCCESS = 'posAgent/GET_POSAGENT_MENU_FILE_SUCCESS';
const GET_POSAGENT_MENU_FILE_FAILURE = 'posAgent/GET_POSAGENT_MENU_FILE_FAILURE';

const GET_POSAGENT_CURRENT_LOG_FILE_REQUESTED = 'posAgent/GET_POSAGENT_CURRENT_LOG_FILE_REQUESTED';
const GET_POSAGENT_CURRENT_LOG_FILE_SUCCESS = 'posAgent/GET_POSAGENT_CURRENT_LOG_FILE_SUCCESS';
const GET_POSAGENT_CURRENT_LOG_FILE_FAILURE = 'posAgent/GET_POSAGENT_CURRENT_LOG_FILE_FAILURE';

const GET_POSAGENT_LOGS_DATA_REQUESTED = 'posAgent/GET_POSAGENT_LOGS_DATA_REQUESTED';
const GET_POSAGENT_LOGS_DATA_SUCCESS = 'posAgent/GET_POSAGENT_LOGS_DATA_SUCCESS';
const GET_POSAGENT_LOGS_DATA_FAILURE = 'posAgent/GET_POSAGENT_LOGS_DATA_FAILURE';

const GET_POSAGENT_HEALTH_INFO_REQUESTED = 'posAgent/GET_POSAGENT_HEALTH_INFO_REQUESTED';
const GET_POSAGENT_HEALTH_INFO_SUCCESS = 'posAgent/GET_POSAGENT_HEALTH_INFO_SUCCESS';
const GET_POSAGENT_HEALTH_INFO_FAILURE = 'posAgent/GET_POSAGENT_HEALTH_INFO_FAILURE';

export const CLEAR_POSAGENT_HEALTH_INFO = 'posAgent/CLEAR_POSAGENT_HEALTH_INFO';

const initialState = {
  loaded: false,
  loading: false,
  error: false,
  posAgentStatusList: [],
  posAgentFormattedHealthInfo: null,
  posAgentHealthInfoData: [],
};

let timeoutId = '';

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POSAGENT_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POSAGENT_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posAgentStatusList: action.result.statuses,
      };
    }
    case GET_POSAGENT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        posAgentStatusList: []
      };
    }
    case GET_POSAGENT_FORMATTED_HEALTH_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        posAgentFormattedHealthInfo: null
      };
    }
    case GET_POSAGENT_FORMATTED_HEALTH_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posAgentFormattedHealthInfo: action.result,
      };
    }
    case GET_POSAGENT_FORMATTED_HEALTH_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        posAgentFormattedHealthInfo: null
      };
    }
    case CLEAR_POSAGENT_HEALTH_INFO: {
      return {
        ...state,
        posAgentFormattedHealthInfo: null,
      };
    }
    case POST_POSAGENT_CHECK_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_POSAGENT_CHECK_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_POSAGENT_CHECK_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_POSAGENT_REACTIVATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_POSAGENT_REACTIVATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_POSAGENT_REACTIVATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_POSAGENT_RESTART_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_POSAGENT_RESTART_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_POSAGENT_RESTART_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POST_POSAGENT_REFRESH_CACHE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_POSAGENT_REFRESH_CACHE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_POSAGENT_REFRESH_CACHE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POSAGENT_MENU_FILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POSAGENT_MENU_FILE_SUCCESS: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_POSAGENT_MENU_FILE_FAILURE: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POSAGENT_CURRENT_LOG_FILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POSAGENT_CURRENT_LOG_FILE_SUCCESS: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_POSAGENT_CURRENT_LOG_FILE_FAILURE: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POSAGENT_LOGS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POSAGENT_LOGS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_POSAGENT_LOGS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POSAGENT_HEALTH_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        posAgentHealthInfoData: [],
      };
    }
    case GET_POSAGENT_HEALTH_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posAgentHealthInfoData: action.result,
      };
    }
    case GET_POSAGENT_HEALTH_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        posAgentStatusList: []
      };
    }
    default: return state;
  }
};

export const getPosAgentStatusList = () => {
  return {
    types: [GET_POSAGENT_STATUS_REQUESTED, GET_POSAGENT_STATUS_SUCCESS, GET_POSAGENT_STATUS_FAILURE],
    promise: client => client.get('getPosAgentStatus')
  };
};

export const getFormattedHealthInfoData = (storeId) => {
  return {
    types: [GET_POSAGENT_FORMATTED_HEALTH_INFO_REQUESTED, GET_POSAGENT_FORMATTED_HEALTH_INFO_SUCCESS, GET_POSAGENT_FORMATTED_HEALTH_INFO_FAILURE],
    promise: client => client.get(`getFormattedHealthInfo/${storeId}`)
  };
};

export const posAgentCheckUpdate = (storeId) => {
  return {
    types: [POST_POSAGENT_CHECK_UPDATE_REQUESTED, POST_POSAGENT_CHECK_UPDATE_SUCCESS, POST_POSAGENT_CHECK_UPDATE_FAILURE],
    promise: client => client.post(`posAgentCheckForUpdate/${storeId}`)
  };
};

export const posAgentReactivate = (storeId) => {
  return {
    types: [POST_POSAGENT_REACTIVATE_REQUESTED, POST_POSAGENT_REACTIVATE_SUCCESS, POST_POSAGENT_REACTIVATE_FAILURE],
    promise: client => client.post(`posAgentReactivate/${storeId}`)
  };
};

export const posAgentRestart = (storeId) => {
  return {
    types: [POST_POSAGENT_RESTART_REQUESTED, POST_POSAGENT_RESTART_SUCCESS, POST_POSAGENT_RESTART_FAILURE],
    promise: client => client.post(`posAgentRestart/${storeId}`)
  };
};

export const posAgentRefreshCache = (storeId) => {
  return {
    types: [POST_POSAGENT_REFRESH_CACHE_REQUESTED, POST_POSAGENT_REFRESH_CACHE_SUCCESS, POST_POSAGENT_REFRESH_CACHE_FAILURE],
    promise: client => client.post(`posAgentRefreshCache/${storeId}`)
  };
};

export const getPosAgentMenuFile = (storeId, cacheStatus) => {
  return {
    types: [GET_POSAGENT_MENU_FILE_REQUESTED, GET_POSAGENT_MENU_FILE_SUCCESS, GET_POSAGENT_MENU_FILE_FAILURE],
    promise: client => client.get(`posAgentDownloadMenuFile/${storeId}?refreshCache=${cacheStatus}`)
  };
};

export const getPosAgentCurrentLogFile = (storeId) => {
  return {
    types: [GET_POSAGENT_CURRENT_LOG_FILE_REQUESTED, GET_POSAGENT_CURRENT_LOG_FILE_SUCCESS, GET_POSAGENT_CURRENT_LOG_FILE_FAILURE],
    promise: client => client.get(`posAgentDownloadCurrentLogFile/${storeId}`)
  };
};

export const getPosAgentLogsData = (storeId, startDate, endDate) => {
  return {
    types: [GET_POSAGENT_LOGS_DATA_REQUESTED, GET_POSAGENT_LOGS_DATA_SUCCESS, GET_POSAGENT_LOGS_DATA_FAILURE],
    promise: client => client.get(`posAgentDownloadLogs/${storeId}?startDate=${startDate}&endDate=${endDate}`)
  };
};
export const getPosAgentHealthInfo = (storeId, startDate, endDate) => {
  return {
    types: [GET_POSAGENT_HEALTH_INFO_REQUESTED, GET_POSAGENT_HEALTH_INFO_SUCCESS, GET_POSAGENT_HEALTH_INFO_FAILURE],
    promise: client => client.get(`posAgentHealthInfo/${storeId}?startDate=${startDate}&endDate=${endDate}`)
  };
};

export const resetPosAgentHealthInfo = () => {
  return {
    type: CLEAR_POSAGENT_HEALTH_INFO,
  };
};

export const RequestError = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        const error = new Error('Request Timeout');
        reject(error);
        dispatch({
          type: GET_POSAGENT_MENU_FILE_FAILURE
        });
      }, 60000);
    });
  };
};
