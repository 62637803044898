export const GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_REQUESTED = 'integrationConfigurations/GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_REQUESTED';
export const GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_SUCCESS = 'integrationConfigurations/GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_SUCCESS';
export const GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_FAILURE = 'integrationConfigurations/GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  integrationConfigureData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        integrationConfigureData: null
      };
    }
    case GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        integrationConfigureData: action.result
      };
    }
    case GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getIntegrationConfigureByComapanyId = (companyId) => {
  return {
    types: [GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_REQUESTED, GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_SUCCESS, GET_INTEGRATION_CONFIGURATION_BY_COMPANY_ID_FAILURE],
    promise: client => client.get(`getIntegrationConfigureByComapanyId/${companyId}`)
  };
};
