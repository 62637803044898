export const GET_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE';

export const GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  mobilePaymentList: null,
  paymentSystemByVendor: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        mobilePaymentList: null,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobilePaymentList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        mobilePaymentList: null,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mobilePaymentList: action.result,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        paymentSystemByVendor: null,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paymentSystemByVendor: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        paymentSystemByVendor: null,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfig = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigMobile/${storeId}`)
  };
};

export const updateStorePaymentSystemConfig = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfigMobile/${storeId}`, { data })
  };
};

export const getPaymentSystemConfigMobileByVendor = (storeId, vendorId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_MOBILE_BY_VENDOR_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigMobileByVendor/${storeId}/vendorId/${vendorId}`)
  };
};
