import { map } from 'lodash';

/* eslint-disable */
export const groupBy = (xs, f) => {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};
/* eslint-enable */

export const groupElemetsByKey = (list, key) => {
  return groupBy(list, obj => obj[key]);
};

export const groupElemetsByValue = (list, key, val) => {
  return groupBy(list, obj => obj[key] === val).true;
};

export const groupElemetsByKeyAsArray = (list, key) => {
  const x = groupBy(list, obj => obj[key]);
  return Object.keys(x).map(val => ({ key: val, children: x[val] }));
};

export const groupElemetsByKeyAsArrayOnly = (list, key) => {
  const x = groupBy(list, obj => obj[key]);
  return Object.keys(x).map(val => x[val]);
};

export const findIndex = (array, key, value) => {
  return array.findIndex((obj) => {
    return obj[key] === value;
  });
};

export const removeObjectFromKey = (array, key, value) => {
  const index = findIndex(array, key, value);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

export const swapArrayElements = (array, fromIndex, toIndex) => {
  array.slice();
  const startIndex = (fromIndex < 0) ? (array.length + toIndex) : toIndex;
  const item = array.splice(fromIndex, 1)[0];
  array.splice(startIndex, 0, item);
  return array;
};

const modifyObjectOfArray = (arrObj, obj) => {
  return map(arrObj, (o) => {
    if (o.id === obj.id) {
      return obj;
    }
    return o;
  });
};

export const compareTwoArraysByKeys = (arr1, arr2, key1, key2) => {
  let result = [];
  result = arr1.filter(data => arr2.some(val => data[key1] === val[key2]));
  return result;
};

export default {
  groupElemetsByKey,
  removeObjectFromKey,
  findIndex,
  swapArrayElements,
  groupElemetsByKeyAsArray,
  groupElemetsByValue,
  modifyObjectOfArray,
  compareTwoArraysByKeys
};
