const GET_MANAGER_SENSOR_GROUP_REQUESTED = 'sensorGroup/GET_MANAGER_SENSOR_GROUP_REQUESTED';
const GET_MANAGER_SENSOR_GROUP_SUCCESS = 'sensorGroup/GET_MANAGER_SENSOR_GROUP_SUCCESS';
const GET_MANAGER_SENSOR_GROUP_FAILURE = 'sensorGroup/GET_MANAGER_SENSOR_GROUP_FAILURE';

const GET_MANAGER_STATION_BY_STORE_REQUESTED = 'sensorGroup/GET_MANAGER_STATION_BY_STORE_REQUESTED';
const GET_MANAGER_STATION_BY_STORE_SUCCESS = 'sensorGroup/GET_MANAGER_STATION_BY_STORE_SUCCESS';
const GET_MANAGER_STATION_BY_STORE_FAILURE = 'sensorGroup/GET_MANAGER_STATION_BY_STORE_FAILURE';

const GET_MANAGER_SENSOR_BY_STORE_REQUESTED = 'sensorGroup/GET_MANAGER_SENSOR_BY_STORE_REQUESTED';
const GET_MANAGER_SENSOR_BY_STORE_SUCCESS = 'sensorGroup/GET_MANAGER_SENSOR_BY_STORE_SUCCESS';
const GET_MANAGER_SENSOR_BY_STORE_FAILURE = 'sensorGroup/GET_MANAGER_SENSOR_BY_STORE_FAILURE';

const ASSIGN_MANAGER_SENSOR_GROUP_REQUESTED = 'sensorGroup/ASSIGN_MANAGER_SENSOR_GROUP_REQUESTED';
const ASSIGN_MANAGER_SENSOR_GROUP_SUCCESS = 'sensorGroup/ASSIGN_MANAGER_SENSOR_GROUP_SUCCESS';
const ASSIGN_MANAGER_SENSOR_GROUP_FAILURE = 'sensorGroup/ASSIGN_MANAGER_SENSOR_GROUP_FAILURE';

const DEREGISTER_SENSOR_REQUESTED = 'sensorGroup/DEREGISTER_SENSOR_REQUESTED';
const DEREGISTER_SENSOR_SUCCESS = 'sensorGroup/DEREGISTER_SENSOR_SUCCESS';
const DEREGISTER_SENSOR_FAILURE = 'sensorGroup/DEREGISTER_SENSOR_FAILURE';

const MANAGER_SENSOR_REGISTER_REQUESTED = 'sensorGroup/MANAGER_SENSOR_REGISTER_REQUESTED';
const MANAGER_SENSOR_REGISTER_SUCCESS = 'sensorGroup/MANAGER_SENSOR_REGISTER_SUCCESS';
const MANAGER_SENSOR_REGISTER_FAILURE = 'sensorGroup/MANAGER_SENSOR_REGISTER_FAILURE';

const GET_SELECTED_SENSOR = 'sensorGroup/GET_SELECTED_SENSOR';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sensorGroupListByBrandId: [],
  sensorByStore: [],
  stationByStore: [],
  selectedSensor: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_MANAGER_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupListByBrandId: action.result
      };
    }
    case GET_MANAGER_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupListByBrandId: [],
      };
    }
    case GET_MANAGER_STATION_BY_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_MANAGER_STATION_BY_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationByStore: action.result
      };
    }
    case GET_MANAGER_STATION_BY_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        stationByStore: [],
      };
    }
    case GET_MANAGER_SENSOR_BY_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_MANAGER_SENSOR_BY_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorByStore: action.result
      };
    }
    case GET_MANAGER_SENSOR_BY_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorByStore: [],
      };
    }
    case ASSIGN_MANAGER_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ASSIGN_MANAGER_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ASSIGN_MANAGER_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DEREGISTER_SENSOR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DEREGISTER_SENSOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case DEREGISTER_SENSOR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case MANAGER_SENSOR_REGISTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MANAGER_SENSOR_REGISTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case MANAGER_SENSOR_REGISTER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_SELECTED_SENSOR: {
      return {
        ...state,
        selectedSensor: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getManagerSensorGroupByRoleId = (brandId) => {
  return {
    types: [GET_MANAGER_SENSOR_GROUP_REQUESTED, GET_MANAGER_SENSOR_GROUP_SUCCESS, GET_MANAGER_SENSOR_GROUP_FAILURE],
    promise: client => client.get(`getManagerSensorGroupByRoleId/${brandId}`)
  };
};

export const getManagerStationByStore = (storeId) => {
  return {
    types: [GET_MANAGER_STATION_BY_STORE_REQUESTED, GET_MANAGER_STATION_BY_STORE_SUCCESS, GET_MANAGER_STATION_BY_STORE_FAILURE],
    promise: client => client.get(`getManagerStationByStore/${storeId}`)
  };
};

export const getManagerSensorByStore = (storeId) => {
  return {
    types: [GET_MANAGER_SENSOR_BY_STORE_REQUESTED, GET_MANAGER_SENSOR_BY_STORE_SUCCESS, GET_MANAGER_SENSOR_BY_STORE_FAILURE],
    promise: client => client.get(`getManagerSensorByStore/${storeId}`)
  };
};

export const assignManagerSensorGroup = (sensorId, sensorGroupId) => {
  return {
    types: [ASSIGN_MANAGER_SENSOR_GROUP_REQUESTED, ASSIGN_MANAGER_SENSOR_GROUP_SUCCESS, ASSIGN_MANAGER_SENSOR_GROUP_FAILURE],
    promise: client => client.post(`assignManagerSensorGroup/${sensorId}/assignSensorGroup/sensorGroupId/${sensorGroupId}`)
  };
};
export const unassignManagerSensorGroup = (sensorId) => {
  return {
    types: [ASSIGN_MANAGER_SENSOR_GROUP_REQUESTED, ASSIGN_MANAGER_SENSOR_GROUP_SUCCESS, ASSIGN_MANAGER_SENSOR_GROUP_FAILURE],
    promise: client => client.post(`assignManagerSensorGroup/${sensorId}/unAssignSensorGroup`)
  };
};

export const sensorDeRegister = (data) => {
  return {
    types: [DEREGISTER_SENSOR_REQUESTED, DEREGISTER_SENSOR_SUCCESS, DEREGISTER_SENSOR_FAILURE],
    promise: client => client.post(`sensorDeRegister/${data}`),
  };
};

export const managerSensorRegister = (data) => {
  return {
    types: [MANAGER_SENSOR_REGISTER_REQUESTED, MANAGER_SENSOR_REGISTER_SUCCESS, MANAGER_SENSOR_REGISTER_FAILURE],
    promise: client => client.post('managerSensorRegister', {
      data
    }),
  };
};

export const selectedSensor = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: GET_SELECTED_SENSOR,
        payload: data
      }
    );
  };
};
