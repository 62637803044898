export const GET_DELIVERY_CONFIG_REQUESTED = 'brand/GET_DELIVERY_CONFIG_REQUESTED';
export const GET_DELIVERY_CONFIG_SUCCESS = 'brand/GET_DELIVERY_CONFIG_SUCCESS';
export const GET_DELIVERY_CONFIG_FAILURE = 'brand/GET_DELIVERY_CONFIG_FAILURE';

export const GET_DELIVERY_SYSTEMS_REQUESTED = 'brand/GET_DELIVERY_SYSTEMS_REQUESTED';
export const GET_DELIVERY_SYSTEMS_SUCCESS = 'brand/GET_DELIVERY_SYSTEMS_SUCCESS';
export const GET_DELIVERY_SYSTEMS_FAILURE = 'brand/GET_DELIVERY_SYSTEMS_FAILURE';

export const UPDATE_DELIVERY_CONFIG_REQUESTED = 'brand/UPDATE_DELIVERY_CONFIG_REQUESTED';
export const UPDATE_DELIVERY_CONFIG_SUCCESS = 'brand/UPDATE_DELIVERY_CONFIG_SUCCESS';
export const UPDATE_DELIVERY_CONFIG_FAILURE = 'brand/UPDATE_DELIVERY_CONFIG_FAILURE';

export const GET_BRAND_TENDER_TYPE_REQUESTED = 'deliverySetup/GET_BRAND_TENDER_TYPE_REQUESTED';
export const GET_BRAND_TENDER_TYPE_SUCCESS = 'deliverySetup/GET_BRAND_TENDER_TYPE_SUCCESS';
export const GET_BRAND_TENDER_TYPE_FAILURE = 'deliverySetup/GET_BRAND_TENDER_TYPE_FAILURE';

export const GET_BRAND_ORDER_TYPE_REQUESTED = 'deliverySetup/GET_BRAND_ORDER_TYPE_REQUESTED';
export const GET_BRAND_ORDER_TYPE_SUCCESS = 'deliverySetup/GET_BRAND_ORDER_TYPE_SUCCESS';
export const GET_BRAND_ORDER_TYPE_FAILURE = 'deliverySetup/GET_BRAND_ORDER_TYPE_FAILURE';

export const GET_BRAND_REVENUCENTER_LIST_REQUESTED = 'deliverySetup/GET_BRAND_REVENUCENTER_LIST_REQUESTED';
export const GET_BRAND_REVENUCENTER_LIST_SUCCESS = 'deliverySetup/GET_BRAND_REVENUCENTER_LIST_SUCCESS';
export const GET_BRAND_REVENUCENTER_LIST_FAILURE = 'deliverySetup/GET_BRAND_REVENUCENTER_LIST_FAILURE';

export const GET_ITEM_ORDER_TYPE_REQUESTED = 'deliverySetup/GET_ITEM_ORDER_TYPE_REQUESTED';
export const GET_ITEM_ORDER_TYPE_SUCCESS = 'deliverySetup/GET_ITEM_ORDER_TYPE_SUCCESS';
export const GET_ITEM_ORDER_TYPE_FAILURE = 'deliverySetup/GET_ITEM_ORDER_TYPE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  deliverySetupList: [],
  deliverySystems: [],
  tenderTypesList: [],
  brandOrderTypes: [],
  revenueCenterList: [],
  itemOrderTypeList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        deliverySetupList: [],
      };
    }
    case GET_DELIVERY_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deliverySetupList: action.result,
      };
    }
    case GET_DELIVERY_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DELIVERY_SYSTEMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        deliverySetupList: [],
      };
    }
    case GET_DELIVERY_SYSTEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deliverySystems: action.result,
      };
    }
    case GET_DELIVERY_SYSTEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_DELIVERY_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_DELIVERY_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DELIVERY_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_TENDER_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        tenderTypesList: [],
      };
    }
    case GET_BRAND_TENDER_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tenderTypesList: action.result,
      };
    }
    case GET_BRAND_TENDER_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_ORDER_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        brandOrderTypes: [],
      };
    }
    case GET_BRAND_ORDER_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandOrderTypes: action.result,
      };
    }
    case GET_BRAND_ORDER_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_REVENUCENTER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        revenueCenterList: [],
      };
    }
    case GET_BRAND_REVENUCENTER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        revenueCenterList: action.result,
      };
    }
    case GET_BRAND_REVENUCENTER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ITEM_ORDER_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        itemOrderTypeList: [],
      };
    }
    case GET_ITEM_ORDER_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemOrderTypeList: action.result,
      };
    }
    case GET_ITEM_ORDER_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getDeliveryConfig = (brandId) => {
  return {
    types: [GET_DELIVERY_CONFIG_REQUESTED, GET_DELIVERY_CONFIG_SUCCESS, GET_DELIVERY_CONFIG_FAILURE],
    promise: client => client.get(`getDeliveryConfig/${brandId}`)
  };
};
export const getDeliverySystems = () => {
  return {
    types: [GET_DELIVERY_SYSTEMS_REQUESTED, GET_DELIVERY_SYSTEMS_SUCCESS, GET_DELIVERY_SYSTEMS_FAILURE],
    promise: client => client.get('getDeliverySystems')
  };
};

export const updateDeliveryConfig = (brandId, data) => {
  return {
    types: [UPDATE_DELIVERY_CONFIG_REQUESTED, UPDATE_DELIVERY_CONFIG_SUCCESS, UPDATE_DELIVERY_CONFIG_FAILURE],
    promise: client => client.post(`updateDeliveryConfig/${brandId}`, { data })
  };
};

export const getBrandTenderTypes = (brandId) => {
  return {
    types: [GET_BRAND_TENDER_TYPE_REQUESTED, GET_BRAND_TENDER_TYPE_SUCCESS, GET_BRAND_TENDER_TYPE_FAILURE],
    promise: client => client.get(`getBrandTenderTypes/${brandId}`)
  };
};

export const getBrandOrderTypes = (brandId) => {
  return {
    types: [GET_BRAND_ORDER_TYPE_REQUESTED, GET_BRAND_ORDER_TYPE_SUCCESS, GET_BRAND_ORDER_TYPE_FAILURE],
    promise: client => client.get(`getBrandOrderTypes/${brandId}`)
  };
};

export const getrevenueCenterList = (brandId) => {
  return {
    types: [GET_BRAND_REVENUCENTER_LIST_REQUESTED, GET_BRAND_REVENUCENTER_LIST_SUCCESS, GET_BRAND_REVENUCENTER_LIST_FAILURE],
    promise: client => client.get(`getBrandRevenueCenters/${brandId}`)
  };
};

export const getItemOrderTypesList = (brandId) => {
  return {
    types: [GET_ITEM_ORDER_TYPE_REQUESTED, GET_ITEM_ORDER_TYPE_SUCCESS, GET_ITEM_ORDER_TYPE_FAILURE],
    promise: client => client.get(`getItemOrderTypesList/${brandId}`)
  };
};
