export const GET_SURVEY_BY_BRAND_REQUESTED = 'survey/GET_SURVEY_BY_BRAND_REQUESTED';
export const GET_SURVEY_BY_BRAND_SUCCESS = 'survey/GET_SURVEY_BY_BRAND_SUCCESS';
export const GET_SURVEY_BY_BRAND_FAILURE = 'survey/GET_SURVEY_BY_BRAND_FAILURE';

export const ADD_SURVEY_REQUESTED = 'survey/ADD_SURVEY_REQUESTED';
export const ADD_SURVEY_SUCCESS = 'survey/ADD_SURVEY_SUCCESS';
export const ADD_SURVEY_FAILURE = 'survey/ADD_SURVEY_FAILURE';

export const UPDATE_SURVEY_REQUESTED = 'survey/UPDATE_SURVEY_REQUESTED';
export const UPDATE_SURVEY_SUCCESS = 'survey/UPDATE_SURVEY_SUCCESS';
export const UPDATE_SURVEY_FAILURE = 'survey/UPDATE_SURVEY_FAILURE';

export const GET_SURVEY_BY_ID_REQUESTED = 'survey/GET_SURVEY_BY_ID_REQUESTED';
export const GET_SURVEY_BY_ID_SUCCESS = 'survey/GET_SURVEY_BY_ID_SUCCESS';
export const GET_SURVEY_BY_ID_FAILURE = 'survey/GET_SURVEY_BY_ID_FAILURE';

export const GET_SURVEY_QUESTION_SUBJECTS_REQUESTED = 'survey/GET_SURVEY_QUESTION_SUBJECTS_REQUESTED';
export const GET_SURVEY_QUESTION_SUBJECTS_SUCCESS = 'survey/GET_SURVEY_QUESTION_SUBJECTS_SUCCESS';
export const GET_SURVEY_QUESTION_SUBJECTS_FAILURE = 'survey/GET_SURVEY_QUESTION_SUBJECTS_FAILURE';

export const GET_SURVEY_QUESTION_TYPES_REQUESTED = 'survey/GET_SURVEY_QUESTION_TYPES_REQUESTED';
export const GET_SURVEY_QUESTION_TYPES_SUCCESS = 'survey/GET_SURVEY_QUESTION_TYPES_SUCCESS';
export const GET_SURVEY_QUESTION_TYPES_FAILURE = 'survey/GET_SURVEY_QUESTION_TYPES_FAILURE';

export const GET_SURVEY_QUESTION_OBJECT_TYPES_REQUESTED = 'survey/GET_SURVEY_QUESTION_OBJECT_TYPES_REQUESTED';
export const GET_SURVEY_QUESTION_OBJECT_TYPES_SUCCESS = 'survey/GET_SURVEY_QUESTION_OBJECT_TYPES_SUCCESS';
export const GET_SURVEY_QUESTION_OBJECT_TYPES_FAILURE = 'survey/GET_SURVEY_QUESTION_OBJECT_TYPES_FAILURE';

export const GET_SURVEY_QUESTION_WORKFLOW_TYPES_REQUESTED = 'survey/GET_SURVEY_QUESTION_WORKFLOW_TYPES_REQUESTED';
export const GET_SURVEY_QUESTION_WORKFLOW_TYPES_SUCCESS = 'survey/GET_SURVEY_QUESTION_WORKFLOW_TYPES_SUCCESS';
export const GET_SURVEY_QUESTION_WORKFLOW_TYPES_FAILURE = 'survey/GET_SURVEY_QUESTION_WORKFLOW_TYPES_FAILURE';

export const ADD_SURVEY_QUESTION_REQUESTED = 'survey/ADD_SURVEY_QUESTION_REQUESTED';
export const ADD_SURVEY_QUESTION_SUCCESS = 'survey/ADD_SURVEY_QUESTION_SUCCESS';
export const ADD_SURVEY_QUESTION_FAILURE = 'survey/ADD_SURVEY_QUESTION_FAILURE';

export const UPDATE_SURVEY_QUESTION_REQUESTED = 'survey/UPDATE_SURVEY_QUESTION_REQUESTED';
export const UPDATE_SURVEY_QUESTION_SUCCESS = 'survey/UPDATE_SURVEY_QUESTION_SUCCESS';
export const UPDATE_SURVEY_QUESTION_FAILURE = 'survey/UPDATE_SURVEY_QUESTION_FAILURE';

export const DELETE_SURVEY_QUESTION_REQUESTED = 'survey/DELETE_SURVEY_QUESTION_REQUESTED';
export const DELETE_SURVEY_QUESTION_SUCCESS = 'survey/DELETE_SURVEY_QUESTION_SUCCESS';
export const DELETE_SURVEY_QUESTION_FAILURE = 'survey/DELETE_SURVEY_QUESTION_FAILURE';

export const GET_SURVEY_RESPONSE_SENTIMENTS_REQUESTED = 'survey/GET_SURVEY_RESPONSE_SENTIMENTS_REQUESTED';
export const GET_SURVEY_RESPONSE_SENTIMENTS_SUCCESS = 'survey/GET_SURVEY_RESPONSE_SENTIMENTS_SUCCESS';
export const GET_SURVEY_RESPONSE_SENTIMENTS_FAILURE = 'survey/GET_SURVEY_RESPONSE_SENTIMENTS_FAILURE';

export const ADD_SURVEY_RESPONSE_REQUESTED = 'survey/ADD_SURVEY_RESPONSE_REQUESTED';
export const ADD_SURVEY_RESPONSE_SUCCESS = 'survey/ADD_SURVEY_RESPONSE_SUCCESS';
export const ADD_SURVEY_RESPONSE_FAILURE = 'survey/ADD_SURVEY_RESPONSE_FAILURE';

export const UPDATE_SURVEY_RESPONSE_REQUESTED = 'survey/UPDATE_SURVEY_RESPONSE_REQUESTED';
export const UPDATE_SURVEY_RESPONSE_SUCCESS = 'survey/UPDATE_SURVEY_RESPONSE_SUCCESS';
export const UPDATE_SURVEY_RESPONSE_FAILURE = 'survey/UPDATE_SURVEY_RESPONSE_FAILURE';

export const DELETE_SURVEY_RESPONSE_REQUESTED = 'survey/DELETE_SURVEY_RESPONSE_REQUESTED';
export const DELETE_SURVEY_RESPONSE_SUCCESS = 'survey/DELETE_SURVEY_RESPONSE_SUCCESS';
export const DELETE_SURVEY_RESPONSE_FAILURE = 'survey/DELETE_SURVEY_RESPONSE_FAILURE';

export const REORDER_SURVEY_RESPONSE_REQUESTED = 'survey/REORDER_SURVEY_RESPONSE_REQUESTED';
export const REORDER_SURVEY_RESPONSE_SUCCESS = 'survey/REORDER_SURVEY_RESPONSE_SUCCESS';
export const REORDER_SURVEY_RESPONSE_FAILURE = 'survey/REORDER_SURVEY_RESPONSE_FAILURE';

export const REORDER_SURVEY_QUESTION_REQUESTED = 'survey/REORDER_SURVEY_QUESTION_REQUESTED';
export const REORDER_SURVEY_QUESTION_SUCCESS = 'survey/REORDER_SURVEY_QUESTION_SUCCESS';
export const REORDER_SURVEY_QUESTION_FAILURE = 'survey/REORDER_SURVEY_QUESTION_FAILURE';

export const UPLOAD_SURVEY_WELCOME_IMAGE_REQUESTED = 'survey/UPLOAD_SURVEY_WELCOME_IMAGE_REQUESTED';
export const UPLOAD_SURVEY_WELCOME_IMAGE_SUCCESS = 'survey/UPLOAD_SURVEY_WELCOME_IMAGE_SUCCESS';
export const UPLOAD_SURVEY_WELCOME_IMAGE_FAILURE = 'survey/UPLOAD_SURVEY_WELCOME_IMAGE_FAILURE';

export const GET_SURVEY_QUESTIONS_BY_BRANDID_REQUESTED = 'survey/GET_SURVEY_QUESTIONS_BY_BRANDID_REQUESTED';
export const GET_SURVEY_QUESTIONS_BY_BRANDID_SUCCESS = 'survey/GET_SURVEY_QUESTIONS_BY_BRANDID_SUCCESS';
export const GET_SURVEY_QUESTIONS_BY_BRANDID_FAILURE = 'survey/GET_SURVEY_QUESTIONS_BY_BRANDID_FAILURE';

export const GET_SURVEY_QUESTIONS_BY_ID_REQUESTED = 'survey/GET_SURVEY_QUESTIONS_BY_ID_REQUESTED';
export const GET_SURVEY_QUESTIONS_BY_ID_SUCCESS = 'survey/GET_SURVEY_QUESTIONS_BY_ID_SUCCESS';
export const GET_SURVEY_QUESTIONS_BY_ID_FAILURE = 'survey/GET_SURVEY_QUESTIONS_BY_ID_FAILURE';

export const SURVEY_QUESTION_LIST_BY_BRANDID = 'survey/SURVEY_QUESTION_LIST_BY_BRANDID';

export const ADD_QUESTION_REQUESTED = 'survey/ADD_QUESTION_REQUESTED';
export const ADD_QUESTION_SUCCESS = 'survey/ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_FAILURE = 'survey/ADD_QUESTION_FAILURE';

export const UPDATE_QUESTION_REQUESTED = 'survey/UPDATE_QUESTION_REQUESTED';
export const UPDATE_QUESTION_SUCCESS = 'survey/UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILURE = 'survey/UPDATE_QUESTION_FAILURE';

export const DELETE_QUESTION_REQUESTED = 'survey/DELETE_QUESTION_REQUESTED';
export const DELETE_QUESTION_SUCCESS = 'survey/DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILURE = 'survey/DELETE_QUESTION_FAILURE';

export const ADD_SURVEY_QUICK_RESPONSES_REQUESTED = 'survey/ADD_SURVEY_QUICK_RESPONSES_REQUESTED';
export const ADD_SURVEY_QUICK_RESPONSES_SUCCESS = 'survey/ADD_SURVEY_QUICK_RESPONSES_SUCCESS';
export const ADD_SURVEY_QUICK_RESPONSES_FAILURE = 'survey/ADD_SURVEY_QUICK_RESPONSES_FAILURE';

export const GET_PAYMENT_TYPES_REQUESTED = 'survey/GET_PAYMENT_TYPES_REQUESTED';
export const GET_PAYMENT_TYPES_SUCCESS = 'survey/GET_PAYMENT_TYPES_SUCCESS';
export const GET_PAYMENT_TYPES_FAILURE = 'survey/GET_PAYMENT_TYPES_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  surveyQuestionList: [],
  surveyList: [],
  surveyData: null,
  surveyQuestionType: [],
  surveyQuestionObjectType: [],
  surveyQuestionSubject: [],
  surveyResponseSentiments: [],
  surveyQuestionWorkFlowType: [],
  surveyQuestionById: null,
  surveyQuestionListByBrandId: [],
  paymentTypes: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
        surveyList: action.result,
      };
    }
    case GET_SURVEY_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SURVEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case ADD_SURVEY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SURVEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case UPDATE_SURVEY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyData: action.result,
      };
    }
    case GET_SURVEY_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_QUESTION_SUBJECTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTION_SUBJECTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyQuestionSubject: action.result,
      };
    }
    case GET_SURVEY_QUESTION_SUBJECTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_QUESTION_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTION_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyQuestionType: action.result,
      };
    }
    case GET_SURVEY_QUESTION_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_QUESTION_OBJECT_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTION_OBJECT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyQuestionObjectType: action.result,
      };
    }
    case GET_SURVEY_QUESTION_OBJECT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_QUESTION_WORKFLOW_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTION_WORKFLOW_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyQuestionWorkFlowType: action.result,
      };
    }
    case GET_SURVEY_QUESTION_WORKFLOW_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case ADD_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case UPDATE_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case DELETE_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_RESPONSE_SENTIMENTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_RESPONSE_SENTIMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyResponseSentiments: action.result,
      };
    }
    case GET_SURVEY_RESPONSE_SENTIMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SURVEY_RESPONSE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SURVEY_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case ADD_SURVEY_RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SURVEY_RESPONSE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SURVEY_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case UPDATE_SURVEY_RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_SURVEY_RESPONSE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SURVEY_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case DELETE_SURVEY_RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REORDER_SURVEY_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REORDER_SURVEY_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPLOAD_SURVEY_WELCOME_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPLOAD_SURVEY_WELCOME_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_SURVEY_WELCOME_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SURVEY_QUESTIONS_BY_BRANDID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTIONS_BY_BRANDID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
        surveyQuestionList: action.result,
      };
    }
    case GET_SURVEY_QUESTIONS_BY_BRANDID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_QUESTIONS_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_QUESTIONS_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
        surveyQuestionById: action.result,
      };
    }
    case GET_SURVEY_QUESTIONS_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SURVEY_QUESTION_LIST_BY_BRANDID: {
      return {
        ...state,
        surveyQuestionListByBrandId: action.payload,
      };
    }
    case ADD_SURVEY_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SURVEY_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case ADD_SURVEY_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SURVEY_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SURVEY_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case UPDATE_SURVEY_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_SURVEY_QUESTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SURVEY_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case DELETE_SURVEY_QUESTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SURVEY_QUICK_RESPONSES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SURVEY_QUICK_RESPONSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        erroe: false,
      };
    }
    case ADD_SURVEY_QUICK_RESPONSES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PAYMENT_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        paymentTypes: [],
      };
    }
    case GET_PAYMENT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paymentTypes: action.result,
      };
    }
    case GET_PAYMENT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        paymentTypes: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const getSurveyByBrand = (brandId) => {
  return {
    types: [GET_SURVEY_BY_BRAND_REQUESTED, GET_SURVEY_BY_BRAND_SUCCESS, GET_SURVEY_BY_BRAND_FAILURE],
    promise: client => client.get(`getSurveyByBrandId/${brandId}`)
  };
};

export const addSurvey = (data) => {
  return {
    types: [ADD_SURVEY_REQUESTED, ADD_SURVEY_SUCCESS, ADD_SURVEY_FAILURE],
    promise: client => client.post('addSurvey', { data })
  };
};

export const updateSurvey = (data) => {
  return {
    types: [UPDATE_SURVEY_REQUESTED, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE],
    promise: client => client.post('updateSurvey', { data })
  };
};

export const getSurveyById = (surveyId) => {
  return {
    types: [GET_SURVEY_BY_ID_REQUESTED, GET_SURVEY_BY_ID_SUCCESS, GET_SURVEY_BY_ID_FAILURE],
    promise: client => client.get(`getSurveyByID/${surveyId}`)
  };
};

export const getSurveyQuestionType = () => {
  return {
    types: [GET_SURVEY_QUESTION_TYPES_REQUESTED, GET_SURVEY_QUESTION_TYPES_SUCCESS, GET_SURVEY_QUESTION_TYPES_FAILURE],
    promise: client => client.get('getSurveyQuestionTypes')
  };
};

export const getSurveyQuestionObjectType = () => {
  return {
    types: [
      GET_SURVEY_QUESTION_OBJECT_TYPES_REQUESTED,
      GET_SURVEY_QUESTION_OBJECT_TYPES_SUCCESS,
      GET_SURVEY_QUESTION_OBJECT_TYPES_FAILURE
    ],
    promise: client => client.get('getSurveyQuestionObjectTypes')
  };
};

export const getSurveyQuestionWorkFlowTypes = () => {
  return {
    types: [
      GET_SURVEY_QUESTION_WORKFLOW_TYPES_REQUESTED,
      GET_SURVEY_QUESTION_WORKFLOW_TYPES_SUCCESS,
      GET_SURVEY_QUESTION_WORKFLOW_TYPES_FAILURE
    ],
    promise: client => client.get('getSurveyQuestionWorkflowTypes')
  };
};

export const getSurveyQuestionSubjects = () => {
  return {
    types: [
      GET_SURVEY_QUESTION_SUBJECTS_REQUESTED,
      GET_SURVEY_QUESTION_SUBJECTS_SUCCESS,
      GET_SURVEY_QUESTION_SUBJECTS_FAILURE
    ],
    promise: client => client.get('getSurveyQuestionSubjects')
  };
};

export const addQuestion = (data) => {
  return {
    types: [ADD_QUESTION_REQUESTED, ADD_QUESTION_SUCCESS, ADD_QUESTION_FAILURE],
    promise: client => client.post('addQuestion', { data })
  };
};
export const updateQuestion = (data) => {
  return {
    types: [UPDATE_QUESTION_REQUESTED, UPDATE_QUESTION_SUCCESS, UPDATE_QUESTION_FAILURE],
    promise: client => client.post('updateQuestion', { data })
  };
};

export const deleteQuestion = (questionId) => {
  return {
    types: [DELETE_QUESTION_REQUESTED, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAILURE],
    promise: client => client.post(`deleteQuestion/${questionId}`)
  };
};

export const getSurveyResponseSentiments = () => {
  return {
    types: [
      GET_SURVEY_RESPONSE_SENTIMENTS_REQUESTED,
      GET_SURVEY_RESPONSE_SENTIMENTS_SUCCESS,
      GET_SURVEY_RESPONSE_SENTIMENTS_FAILURE
    ],
    promise: client => client.get('getSurveyResponseSentiments')
  };
};

export const addSurveyResponse = (data) => {
  return {
    types: [ADD_SURVEY_RESPONSE_REQUESTED, ADD_SURVEY_RESPONSE_SUCCESS, ADD_SURVEY_RESPONSE_FAILURE],
    promise: client => client.post('addSurveyResponse', { data })
  };
};
export const updateSurveyResponse = (data) => {
  return {
    types: [UPDATE_SURVEY_RESPONSE_REQUESTED, UPDATE_SURVEY_RESPONSE_SUCCESS, UPDATE_SURVEY_RESPONSE_FAILURE],
    promise: client => client.post('updateSurveyResponse', { data })
  };
};

export const deleteSurveyResponse = (responseId) => {
  return {
    types: [DELETE_SURVEY_RESPONSE_REQUESTED, DELETE_SURVEY_RESPONSE_SUCCESS, DELETE_SURVEY_RESPONSE_FAILURE],
    promise: client => client.post(`deleteSurveyResponse/${responseId}`)
  };
};

export const reorderSurveyQuestion = (id, sortedIds) => {
  return {
    types: [REORDER_SURVEY_QUESTION_REQUESTED, REORDER_SURVEY_QUESTION_SUCCESS, REORDER_SURVEY_QUESTION_FAILURE],
    promise: client => client.post(`reorderSurveyQuestion/${id}`, { data: sortedIds })
  };
};

export const reorderSurveyResponse = (id, sortedIds) => {
  return {
    types: [REORDER_SURVEY_RESPONSE_REQUESTED, REORDER_SURVEY_RESPONSE_SUCCESS, REORDER_SURVEY_RESPONSE_FAILURE],
    promise: client => client.post(`reorderSurveyResponse/${id}`, { data: sortedIds })
  };
};

export const handleSurveyImage = (surveyId, type, action, data) => {
  return {
    types: [UPLOAD_SURVEY_WELCOME_IMAGE_REQUESTED, UPLOAD_SURVEY_WELCOME_IMAGE_SUCCESS, UPLOAD_SURVEY_WELCOME_IMAGE_FAILURE],
    promise: client => client.post(`surveyWelcomeImage/${surveyId}/imageType/${type}/${action}`, { data })
  };
};

export const getSurveyQuestionByBrand = (brandId) => {
  return {
    types: [GET_SURVEY_QUESTIONS_BY_BRANDID_REQUESTED, GET_SURVEY_QUESTIONS_BY_BRANDID_SUCCESS, GET_SURVEY_QUESTIONS_BY_BRANDID_FAILURE],
    promise: client => client.get(`getSurveyQuestionByBrand/${brandId}`)
  };
};

export const getSurveyQuestionById = (questionId) => {
  return {
    types: [GET_SURVEY_QUESTIONS_BY_ID_REQUESTED, GET_SURVEY_QUESTIONS_BY_ID_SUCCESS, GET_SURVEY_QUESTIONS_BY_ID_FAILURE],
    promise: client => client.get(`getSurveyQuestionById/${questionId}`)
  };
};

export const surveyQuestionListByBrand = (data) => {
  return {
    type: SURVEY_QUESTION_LIST_BY_BRANDID,
    payload: data
  };
};

export const addSurveyQuestion = (data) => {
  return {
    types: [ADD_SURVEY_QUESTION_REQUESTED, ADD_SURVEY_QUESTION_SUCCESS, ADD_SURVEY_QUESTION_FAILURE],
    promise: client => client.post('addSurveyQuestion', { data })
  };
};
export const updateSurveyQuestion = (data) => {
  return {
    types: [UPDATE_SURVEY_QUESTION_REQUESTED, UPDATE_SURVEY_QUESTION_SUCCESS, UPDATE_SURVEY_QUESTION_FAILURE],
    promise: client => client.post('updateSurveyQuestion', { data })
  };
};

export const deleteSurveyQuestion = (surveyId, questionId) => {
  return {
    types: [DELETE_SURVEY_QUESTION_REQUESTED, DELETE_SURVEY_QUESTION_SUCCESS, DELETE_SURVEY_QUESTION_FAILURE],
    promise: client => client.post(`deleteSurveyQuestion/${surveyId}/surveyQuestionId/${questionId}`)
  };
};

export const addSurveyQuickResponses = (data) => {
  return {
    types: [ADD_SURVEY_QUICK_RESPONSES_REQUESTED, ADD_SURVEY_QUICK_RESPONSES_SUCCESS, ADD_SURVEY_QUICK_RESPONSES_FAILURE],
    promise: client => client.post('addQuickResponses', { data })
  };
};

export const getPaymentTypes = () => {
  return {
    types: [
      GET_PAYMENT_TYPES_REQUESTED,
      GET_PAYMENT_TYPES_SUCCESS,
      GET_PAYMENT_TYPES_FAILURE
    ],
    promise: client => client.get('getPaymentTypes')
  };
};
