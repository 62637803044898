import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import style from './notificationV4.module.css';

const Notification = ({ isOpen, type = 'success', message }) => {
  return (
    <React.Fragment>
      <div
        className={cn('row mx-0 justify-content-center', style.notificationBar,
          isOpen ? style.notificationOpen : style.notificationClose,
          type === 'danger' ? style.alertDanger : style.alertSuccess,
          type === 'danger' ? 'alert-danger' : 'alert-success')}
        role="alert"
      >
        <div className="d-flex align-items-center">
          {type === 'success' ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path d="M11.1078 18.6724L20.0719 9.70832L18.6668 8.30322L11.1078 15.8622L7.30783 12.0622L5.90273 13.4673L11.1078 18.6724ZM13.0024 25.6673C11.2505 25.6673 9.60374 25.3348 8.06223 24.6699C6.5207 24.005 5.17979 23.1027 4.0395 21.9629C2.89919 20.8231 1.99644 19.4828 1.33126 17.942C0.666085 16.4011 0.333496 14.7548 0.333496 13.0029C0.333496 11.2509 0.665941 9.60423 1.33083 8.06272C1.99572 6.52118 2.89805 5.18028 4.03783 4.03999C5.17763 2.89968 6.51795 1.99693 8.0588 1.33175C9.59962 0.666575 11.246 0.333984 12.9979 0.333984C14.7498 0.333984 16.3965 0.66643 17.938 1.33132C19.4796 1.99621 20.8205 2.89854 21.9608 4.03832C23.1011 5.17812 24.0038 6.51844 24.669 8.05929C25.3342 9.60011 25.6668 11.2465 25.6668 12.9984C25.6668 14.7503 25.3343 16.397 24.6694 17.9385C24.0045 19.4801 23.1022 20.821 21.9624 21.9613C20.8226 23.1016 19.4823 24.0043 17.9415 24.6695C16.4006 25.3347 14.7543 25.6673 13.0024 25.6673ZM13.0001 23.6673C15.9779 23.6673 18.5001 22.634 20.5668 20.5673C22.6335 18.5006 23.6668 15.9784 23.6668 13.0006C23.6668 10.0228 22.6335 7.50062 20.5668 5.43395C18.5001 3.36729 15.9779 2.33395 13.0001 2.33395C10.0224 2.33395 7.50013 3.36729 5.43346 5.43395C3.3668 7.50062 2.33346 10.0228 2.33346 13.0006C2.33346 15.9784 3.3668 18.5006 5.43346 20.5673C7.50013 22.634 10.0224 23.6673 13.0001 23.6673Z" fill="white" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M16.0024 28.6668C14.2505 28.6668 12.6037 28.3343 11.0622 27.6694C9.5207 27.0045 8.17979 26.1022 7.0395 24.9624C5.89919 23.8226 4.99644 22.4823 4.33126 20.9415C3.66609 19.4006 3.3335 17.7543 3.3335 16.0024C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.5207 5.89805 8.17979 7.03783 7.0395C8.17763 5.89919 9.51795 4.99644 11.0588 4.33126C12.5996 3.66609 14.246 3.3335 15.9979 3.3335C17.7498 3.3335 19.3965 3.66594 20.938 4.33083C22.4796 4.99572 23.8205 5.89805 24.9608 7.03783C26.1011 8.17763 27.0038 9.51795 27.669 11.0588C28.3342 12.5996 28.6668 14.246 28.6668 15.9979C28.6668 17.7498 28.3343 19.3965 27.6694 20.938C27.0045 22.4796 26.1022 23.8205 24.9624 24.9608C23.8226 26.1011 22.4823 27.0038 20.9415 27.669C19.4006 28.3342 17.7543 28.6668 16.0024 28.6668ZM16.0001 26.6668C18.9779 26.6668 21.5001 25.6335 23.5668 23.5668C25.6335 21.5001 26.6668 18.9779 26.6668 16.0001C26.6668 14.7492 26.4531 13.5446 26.0258 12.3863C25.5984 11.228 24.9882 10.1693 24.195 9.21036L9.21036 24.195C10.1693 24.9967 11.228 25.6091 12.3863 26.0322C13.5446 26.4553 14.7492 26.6668 16.0001 26.6668ZM7.80526 22.7899L22.7899 7.80526C21.8395 6.99502 20.7837 6.38049 19.6227 5.96166C18.4616 5.54286 17.2541 5.33346 16.0001 5.33346C13.0224 5.33346 10.5001 6.3668 8.43346 8.43346C6.3668 10.5001 5.33346 13.0224 5.33346 16.0001C5.33346 17.2511 5.54501 18.4557 5.9681 19.614C6.39116 20.7723 7.00355 21.8309 7.80526 22.7899Z" fill="white" />
            </svg>
          )}
          <span className={cn('ml-15')}>
            {message}
          </span>
        </div>
        {/* <div className="col-md-2 col-lg-3 pr-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M2.53317 19.3332L0.666504 17.4665L8.13317 9.99984L0.666504 2.53317L2.53317 0.666504L9.99984 8.13317L17.4665 0.666504L19.3332 2.53317L11.8665 9.99984L19.3332 17.4665L17.4665 19.3332L9.99984 11.8665L2.53317 19.3332Z" fill="white" />
          </svg>
        </div> */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ notification }) => ({
  isOpen: notification.isOpen,
  message: notification.message,
  type: notification.type
});
export default connect(
  mapStateToProps,
  null
)(Notification);
