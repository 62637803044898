export const handleTablePreferences = (data, reportType, getTableColumnDetailes) => {
  let dataToSend = {};
  if (getTableColumnDetailes) {
    dataToSend = JSON.parse(getTableColumnDetailes);
    if (data && dataToSend && dataToSend[reportType]) {
      dataToSend.reportSettings[reportType].columns = data;
    } else dataToSend.reportSettings[`${reportType}`] = { columns: data };
  } else dataToSend = { reportSettings: { [`${reportType}`]: { columns: data } } };
  return dataToSend;
};

export default {
  handleTablePreferences
};
