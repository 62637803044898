export const GET_CHOWLY_SETUP_REQUESTED = 'chowly/GET_CHOWLY_SETUP_REQUESTED';
export const GET_CHOWLY_SETUP_SUCCESS = 'chowly/GET_CHOWLY_SETUP_SUCCESS';
export const GET_CHOWLY_SETUP_FAILURE = 'chowly/GET_CHOWLY_SETUP_FAILURE';

export const UPDATE_CHOWLY_SETUP_REQUESTED = 'chowly/UPDATE_CHOWLY_SETUP_REQUESTED';
export const UPDATE_CHOWLY_SETUP_SUCCESS = 'chowly/UPDATE_CHOWLY_SETUP_SUCCESS';
export const UPDATE_CHOWLY_SETUP_FAILURE = 'chowly/UPDATE_CHOWLY_SETUP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  chowlyList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_CHOWLY_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        chowlyList: [],
      };
    }
    case GET_CHOWLY_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chowlyList: action.result,
      };
    }
    case GET_CHOWLY_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CHOWLY_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CHOWLY_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CHOWLY_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getChowlyConfig = (brandId) => {
  return {
    types: [GET_CHOWLY_SETUP_REQUESTED, GET_CHOWLY_SETUP_SUCCESS, GET_CHOWLY_SETUP_FAILURE],
    promise: client => client.get(`getChowlyConfig/${brandId}`)
  };
};

export const updateChowlyConfig = (brandId, data) => {
  return {
    types: [UPDATE_CHOWLY_SETUP_REQUESTED, UPDATE_CHOWLY_SETUP_SUCCESS, UPDATE_CHOWLY_SETUP_FAILURE],
    promise: client => client.post(`updateChowlyConfig/${brandId}`, { data })
  };
};
