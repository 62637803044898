import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

const withRouter = Component => (props) => {
  const location = useLocation();
  const [storedData, setStoredData] = useState(location);
  const params = useParams();
  const navigate = useNavigate();
  const selector = useSelector;
  const dispatch = useDispatch();

  useEffect(() => {
    setStoredData((prevData) => {
      if (prevData && location && prevData.pathname === location.pathname) {
        if (prevData.state !== null) {
          navigate(location.pathname, { state: { ...prevData.state } });
          return navigate;
        }
      }
      return location.state;
    });
  }, [storedData]);

  return (
    <Component
      {...props}
      {...{
        location, params, navigate, selector, dispatch
      }}
    />
  );
};

export default withRouter;
