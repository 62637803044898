
export const GET_EZCATER_LOCATIONS_REQUESTED = 'otter/GET_EZCATER_LOCATIONS_REQUESTED';
export const GET_EZCATER_LOCATIONS_SUCCESS = 'otter/GET_EZCATER_LOCATIONS_SUCCESS';
export const GET_EZCATER_LOCATIONS_FAILURE = 'otter/GET_EZCATER_LOCATIONS_FAILURE';

export const GET_EZCATER_CONFIG_REQUESTED = 'otter/GET_EZCATER_CONFIG_REQUESTED';
export const GET_EZCATER_CONFIG_SUCCESS = 'otter/GET_EZCATER_CONFIG_SUCCESS';
export const GET_EZCATER_CONFIG_FAILURE = 'otter/GET_EZCATER_CONFIG_FAILURE';

export const UPDATE_EZCATER_CONFIG_REQUESTED = 'otter/UPDATE_EZCATER_CONFIG_REQUESTED';
export const UPDATE_EZCATER_CONFIG_SUCCESS = 'otter/UPDATE_EZCATER_CONFIG_SUCCESS';
export const UPDATE_EZCATER_CONFIG_FAILURE = 'otter/UPDATE_EZCATER_CONFIG_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  locationsList: [],
  ezCaterData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_EZCATER_LOCATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_EZCATER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        locationsList: action.result,
      };
    }
    case GET_EZCATER_LOCATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_EZCATER_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        ezCaterData: null,
      };
    }
    case GET_EZCATER_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ezCaterData: action.result,
      };
    }
    case GET_EZCATER_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_EZCATER_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_EZCATER_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_EZCATER_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getEzCaterLocationsByBrand = (brandId) => {
  return {
    types: [GET_EZCATER_LOCATIONS_REQUESTED, GET_EZCATER_LOCATIONS_SUCCESS, GET_EZCATER_LOCATIONS_FAILURE],
    promise: client => client.get(`getEzCaterLocations/${brandId}/locations`)
  };
};

export const getEzCaterConfig = (brandId) => {
  return {
    types: [GET_EZCATER_CONFIG_REQUESTED, GET_EZCATER_CONFIG_SUCCESS, GET_EZCATER_CONFIG_FAILURE],
    promise: client => client.get(`getEzCardConfig/${brandId}`)
  };
};

export const updateEzCaterConfig = (brandId, data) => {
  return {
    types: [UPDATE_EZCATER_CONFIG_REQUESTED, UPDATE_EZCATER_CONFIG_SUCCESS, UPDATE_EZCATER_CONFIG_FAILURE],
    promise: client => client.post(`updateEzCardConfig/${brandId}`, { data })
  };
};
