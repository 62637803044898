import { version } from '../../package.json';

const TITLE = '';

const ONEDINE_APP_URL = {
  localhost: 'https://app.dev.onedine.com',
  'portal.dev.onedine.com': 'https://app.dev.onedine.com',
  'portal.onedine.com': 'https://app.onedine.com'
};

export default{
  LOGIN: `${TITLE} Login`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  OTP: `${TITLE} OTP`,
  DASHBOARD: `${TITLE} Dashboard`,
  PROFILE: `${TITLE} Profile`,
  BASE_URL: process.env.PUBLIC_URL,
  PAYMENT_QR_BASE_URL: ONEDINE_APP_URL[window.location.hostname],
  VERSION: version
};
