export const ADD_CHARITY_REQUESTED = 'charity/ADD_CHARITY_REQUESTED';
export const ADD_CHARITY_SUCCESS = 'charity/ADD_CHARITY_SUCCESS';
export const ADD_CHARITY_FAILURE = 'charity/ADD_CHARITY_FAILURE';

export const UPDATE_CHARITY_REQUESTED = 'charity/UPDATE_CHARITY_REQUESTED';
export const UPDATE_CHARITY_SUCCESS = 'charity/UPDATE_CHARITY_SUCCESS';
export const UPDATE_CHARITY_FAILURE = 'charity/UPDATE_CHARITY_FAILURE';

export const GET_CHARITY_BYID_REQUESTED = 'charity/GET_CHARITY_BYID_REQUESTED';
export const GET_CHARITY_BYID_SUCCESS = 'charity/GET_CHARITY_BYID_SUCCESS';
export const GET_CHARITY_BYID_FAILURE = 'charity/GET_CHARITY_BYID_FAILURE';

export const GET_CHARITY_LIST_DATA_REQUESTED = 'charity/GET_CHARITY_LIST_DATA_REQUESTED';
export const GET_CHARITY_LIST_DATA_SUCCESS = 'charity/GET_CHARITY_LIST_DATA_SUCCESS';
export const GET_CHARITY_LIST_DATA_FAILURE = 'charity/GET_CHARITY_LIST_DATA_FAILURE';

export const CHARITY_IMAGE_SAVE_REQUESTED = 'charity/CHARITY_IMAGE_SAVE_REQUESTED';
export const CHARITY_IMAGE_SAVE_SUCCESS = 'charity/CHARITY_IMAGE_SAVE_SUCCESS';
export const CHARITY_IMAGE_SAVE_FAILURE = 'charity/CHARITY_IMAGE_SAVE_FAILURE';

export const CHARITY_IMAGE_DELETE_REQUESTED = 'charity/CHARITY_IMAGE_DELETE_REQUESTED';
export const CHARITY_IMAGE_DELETE_SUCCESS = 'charity/CHARITY_IMAGE_DELETE_SUCCESS';
export const CHARITY_IMAGE_DELETE_FAILURE = 'charity/CHARITY_IMAGE_DELETE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getCharityByID: null,
  getStoreCharityList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHARITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CHARITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CHARITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CHARITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CHARITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CHARITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHARITY_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CHARITY_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCharityByID: action.result,
      };
    }
    case GET_CHARITY_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CHARITY_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CHARITY_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getStoreCharityList: action.result,
      };
    }
    case GET_CHARITY_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHARITY_IMAGE_SAVE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHARITY_IMAGE_SAVE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CHARITY_IMAGE_SAVE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHARITY_IMAGE_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHARITY_IMAGE_DELETE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CHARITY_IMAGE_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addCharity = (data) => {
  return {
    types: [ADD_CHARITY_REQUESTED, ADD_CHARITY_SUCCESS, ADD_CHARITY_FAILURE],
    promise: client => client.post('addCharity', { data })
  };
};

export const updateCharity = (data) => {
  return {
    types: [UPDATE_CHARITY_REQUESTED, UPDATE_CHARITY_SUCCESS, UPDATE_CHARITY_FAILURE],
    promise: client => client.post('updateCharity', { data })
  };
};

export const getCharityById = (id) => {
  return {
    types: [GET_CHARITY_BYID_REQUESTED, GET_CHARITY_BYID_SUCCESS, GET_CHARITY_BYID_FAILURE],
    promise: client => client.get(`getCharityById/${id}`)
  };
};

export const getCharitiesList = (storeId) => {
  return {
    types: [GET_CHARITY_LIST_DATA_REQUESTED, GET_CHARITY_LIST_DATA_SUCCESS, GET_CHARITY_LIST_DATA_FAILURE],
    promise: client => client.get(`getStoresCharity/${storeId}`)
  };
};

export const charityImageSave = (charityId, data) => {
  return {
    types: [CHARITY_IMAGE_SAVE_REQUESTED, CHARITY_IMAGE_SAVE_SUCCESS, CHARITY_IMAGE_SAVE_FAILURE],
    promise: client => client.post(`charityImage/${charityId}/save`, { data })
  };
};

export const charityImageDelete = (charityId) => {
  return {
    types: [CHARITY_IMAGE_SAVE_REQUESTED, CHARITY_IMAGE_SAVE_SUCCESS, CHARITY_IMAGE_SAVE_FAILURE],
    promise: client => client.post(`charityImage/${charityId}/delete`)
  };
};
