export const GET_NAMED_SENSOR_LIST_REQUESTED = 'namedSensor/GET_NAMED_SENSOR_LIST_REQUESTED';
export const GET_NAMED_SENSOR_LIST_SUCCESS = 'namedSensor/GET_NAMED_SENSOR_LIST_SUCCESS';
export const GET_NAMED_SENSOR_LIST_FAILURE = 'namedSensor/GET_NAMED_SENSOR_LIST_FAILURE';

export const DEREGISTER_NAMED_SENSOR_REQUESTED = 'namedSensor/DEREGISTER_NAMED_SENSOR_REQUESTED';
export const DEREGISTER_NAMED_SENSOR_SUCCESS = 'namedSensor/DEREGISTER_NAMED_SENSOR_SUCCESS';
export const DEREGISTER_NAMED_SENSOR_FAILURE = 'namedSensor/DEREGISTER_NAMED_SENSOR_FAILURE';

export const ADD_NAMED_SENSOR_REQUESTED = 'namedSensor/ADD_NAMED_SENSOR_REQUESTED';
export const ADD_NAMED_SENSOR_SUCCESS = 'namedSensor/ADD_NAMED_SENSOR_SUCCESS';
export const ADD_NAMED_SENSOR_FAILURE = 'namedSensor/ADD_NAMED_SENSOR_FAILURE';

export const UPDATE_NAMED_SENSOR_REQUESTED = 'namedSensor/UPDATE_NAMED_SENSOR_REQUESTED';
export const UPDATE_NAMED_SENSOR_SUCCESS = 'namedSensor/UPDATE_NAMED_SENSOR_SUCCESS';
export const UPDATE_NAMED_SENSOR_FAILURE = 'namedSensor/UPDATE_NAMED_SENSOR_FAILURE';

export const GET_TABLE_SENSOR_LIST_REQUESTED = 'namedSensor/GET_TABLE_SENSOR_LIST_REQUESTED';
export const GET_TABLE_SENSOR_LIST_SUCCESS = 'namedSensor/GET_TABLE_SENSOR_LIST_SUCCESS';
export const GET_TABLE_SENSOR_LIST_FAILURE = 'namedSensor/GET_TABLE_SENSOR_LIST_FAILURE';

export const GET_VENUE_NAMED_SENSOR_LIST_REQUESTED = 'namedSensor/GET_VENUE_NAMED_SENSOR_LIST_REQUESTED';
export const GET_VENUE_NAMED_SENSOR_LIST_SUCCESS = 'namedSensor/GET_VENUE_NAMED_SENSOR_LIST_SUCCESS';
export const GET_VENUE_NAMED_SENSOR_LIST_FAILURE = 'namedSensor/GET_VENUE_NAMED_SENSOR_LIST_FAILURE';

export const GET_OFFPREMISE_SENSOR_LIST_REQUESTED = 'namedSensor/GET_OFFPREMISE_SENSOR_LIST_REQUESTED';
export const GET_OFFPREMISE_SENSOR_LIST_SUCCESS = 'namedSensor/GET_OFFPREMISE_SENSOR_LIST_SUCCESS';
export const GET_OFFPREMISE_SENSOR_LIST_FAILURE = 'namedSensor/GET_OFFPREMISE_SENSOR_LIST_FAILURE';

export const GET_SENSOR_TYPE_LIST_REQUESTED = 'namedSensor/GET_SENSOR_TYPE_LIST_REQUESTED';
export const GET_SENSOR_TYPE_LIST_SUCCESS = 'namedSensor/GET_SENSOR_TYPE_LIST_SUCCESS';
export const GET_SENSOR_TYPE_LIST_FAILURE = 'namedSensor/GET_SENSOR_TYPE_LIST_FAILURE';

export const GET_OFFPREMISE_SENSOR_TYPE_LIST_REQUESTED = 'namedSensor/GET_OFFPREMISE_SENSOR_TYPE_LIST_REQUESTED';
export const GET_OFFPREMISE_SENSOR_TYPE_LIST_SUCCESS = 'namedSensor/GET_OFFPREMISE_SENSOR_TYPE_LIST_SUCCESS';
export const GET_OFFPREMISE_SENSOR_TYPE_LIST_FAILURE = 'namedSensor/GET_OFFPREMISE_SENSOR_TYPE_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  namedSensorList: [],
  tableSensorList: [],
  venueSensorList: [],
  offPremiseSensorList: [],
  sensorTypeList: [],
  offPremiseSensorTypesList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NAMED_SENSOR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_NAMED_SENSOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        namedSensorList: action.result
      };
    }
    case GET_NAMED_SENSOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case ADD_NAMED_SENSOR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_NAMED_SENSOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_NAMED_SENSOR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_NAMED_SENSOR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_NAMED_SENSOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_NAMED_SENSOR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DEREGISTER_NAMED_SENSOR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DEREGISTER_NAMED_SENSOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case DEREGISTER_NAMED_SENSOR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_TABLE_SENSOR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_TABLE_SENSOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        tableSensorList: action.result
      };
    }
    case GET_TABLE_SENSOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_VENUE_NAMED_SENSOR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_VENUE_NAMED_SENSOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        venueSensorList: action.result
      };
    }
    case GET_VENUE_NAMED_SENSOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_OFFPREMISE_SENSOR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OFFPREMISE_SENSOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        offPremiseSensorList: action.result
      };
    }
    case GET_OFFPREMISE_SENSOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_SENSOR_TYPE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_SENSOR_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        sensorTypeList: action.result
      };
    }
    case GET_SENSOR_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_OFFPREMISE_SENSOR_TYPE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OFFPREMISE_SENSOR_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        offPremiseSensorTypesList: action.result
      };
    }
    case GET_OFFPREMISE_SENSOR_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const getNamedSensorListByStoreId = (storeId) => {
  return {
    types: [GET_NAMED_SENSOR_LIST_REQUESTED, GET_NAMED_SENSOR_LIST_SUCCESS, GET_NAMED_SENSOR_LIST_FAILURE],
    promise: client => client.get(`getStoreNamedSensorByStoreId/${storeId}`),
  };
};

export const getTableSensorsByStoreId = (storeId) => {
  return {
    types: [GET_TABLE_SENSOR_LIST_REQUESTED, GET_TABLE_SENSOR_LIST_SUCCESS, GET_TABLE_SENSOR_LIST_FAILURE],
    promise: client => client.get(`getTableSensorsByStoreId/${storeId}`),
  };
};

export const getVenueNamedSensorByStoreId = (storeId) => {
  return {
    types: [GET_VENUE_NAMED_SENSOR_LIST_REQUESTED, GET_VENUE_NAMED_SENSOR_LIST_SUCCESS, GET_VENUE_NAMED_SENSOR_LIST_FAILURE],
    promise: client => client.get(`getVenueNamedSensorByStoreId/${storeId}`),
  };
};

export const getOffPremiseSensorByStoreId = (storeId) => {
  return {
    types: [GET_OFFPREMISE_SENSOR_LIST_REQUESTED, GET_OFFPREMISE_SENSOR_LIST_SUCCESS, GET_OFFPREMISE_SENSOR_LIST_FAILURE],
    promise: client => client.get(`getOffPremiseSensorByStoreId/${storeId}`),
  };
};

export const deRegisterNamedSensor = (data) => {
  return {
    types: [DEREGISTER_NAMED_SENSOR_REQUESTED, DEREGISTER_NAMED_SENSOR_SUCCESS, DEREGISTER_NAMED_SENSOR_FAILURE],
    promise: client => client.post(`deRegisterSensor/${data}`),
  };
};

export const addNamedSensor = (data) => {
  return {
    types: [ADD_NAMED_SENSOR_REQUESTED, ADD_NAMED_SENSOR_SUCCESS, ADD_NAMED_SENSOR_FAILURE],
    promise: client => client.post('registerSensor', { data })
  };
};

export const updateNamedSensor = (data) => {
  return {
    types: [UPDATE_NAMED_SENSOR_REQUESTED, UPDATE_NAMED_SENSOR_SUCCESS, UPDATE_NAMED_SENSOR_FAILURE],
    promise: client => client.post('registerSensor', { data })
  };
};

export const getSensorTypeList = () => {
  return {
    types: [GET_SENSOR_TYPE_LIST_REQUESTED, GET_SENSOR_TYPE_LIST_SUCCESS, GET_SENSOR_TYPE_LIST_FAILURE],
    promise: client => client.get('sensorList')
  };
};

export const getOffPremiseSensorTypes = () => {
  return {
    types: [GET_OFFPREMISE_SENSOR_TYPE_LIST_REQUESTED, GET_OFFPREMISE_SENSOR_TYPE_LIST_SUCCESS, GET_OFFPREMISE_SENSOR_TYPE_LIST_FAILURE],
    promise: client => client.get('getOffPremiseSensorTypes')
  };
};
