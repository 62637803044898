export const getCompanyId = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedCompany')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedCompany') || {}).id;
  }
  return currentItem;
};

export const getBrandId = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedBrand')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedBrand') || {}).id;
  }
  return currentItem;
};

export const getBrandNonIntegratedPOS = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedBrand')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedBrand') || {}).nonIntegratedPos;
  }
  return currentItem;
};

export const getStoreId = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedStore')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedStore') || {}).id;
  }
  return currentItem;
};

export const getStoreData = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedStore')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedStore') || {});
  }
  return currentItem;
};

export const getOptedStoreData = () => {
  let currentItem = '';
  if (window.localStorage.getItem('optedStoreData')) {
    currentItem = JSON.parse(window.localStorage.getItem('optedStoreData') || {});
  }
  return currentItem;
};

// *************************************************** //
export const setSurveyId = (surveyId) => {
  return window.localStorage.setItem('surveyId', surveyId);
};

export const getSurveyId = () => {
  return window.localStorage.getItem('surveyId');
};

//
export const setDeliveryId = (deliveryId) => {
  return window.localStorage.setItem('deliveryId', deliveryId);
};

export const getDeliveryId = () => {
  return window.localStorage.getItem('deliveryId');
};

export const setSegmentId = (segmentId) => {
  return window.localStorage.setItem('segmentId', segmentId);
};

// getters and setters for localstorage data
export const setSelectedCompany = (company) => {
  window.localStorage.setItem('selectedCompany', JSON.stringify(company));
};

export const setSelectedBrand = (brand) => {
  window.localStorage.setItem('selectedBrand', JSON.stringify(brand));
};

export const setSelectedStore = (store) => {
  window.localStorage.setItem('selectedStore', JSON.stringify(store));
};

export const setOptedStoreData = (store) => {
  window.localStorage.setItem('optedStoreData', JSON.stringify(store));
};

export const getSelectedCompanyName = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedCompany')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedCompany') || {}).name;
  }
  return currentItem;
};

export const getSelectedBrandName = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedBrand')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedBrand') || {}).name;
  }
  return currentItem;
};

export const getSelectedStoreName = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedStore')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedStore') || {}).name;
  }
  return currentItem;
};

export const getBrandObject = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedBrand')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedBrand') || {});
  }
  return currentItem;
};

export const setBusinessNode = (node) => {
  window.localStorage.setItem('business_node', JSON.stringify(node));
};

export const getBusinessNode = () => {
  return JSON.parse(window.localStorage.getItem('business_node'));
};

export const setBusinessType = (type) => {
  window.localStorage.setItem('business_type', JSON.stringify(type));
};

export const getBusinessType = () => {
  return JSON.parse(window.localStorage.getItem('business_type'));
};

export const getAuthLocations = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData') || {});
  if (!userData || !userData.user || !userData.user.locations) {
    return [];
  }
  return userData.user.locations;
};

export const urlSearchParams = (name) => {
  // eslint-disable-next-line
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
};


export const getUserId = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData')) || {};
  if (!userData || !userData.user || !userData.user.id) {
    return [];
  }
  return userData.user.id;
};

export const setConfigData = (data) => {
  window.localStorage.setItem('configSettings', JSON.stringify(data));
};

export const getConfigData = () => {
  return JSON.parse(window.localStorage.getItem('configSettings')) || {};
};

export const getCompanyData = () => {
  let currentItem = '';
  if (window.localStorage.getItem('selectedCompany')) {
    currentItem = JSON.parse(window.localStorage.getItem('selectedCompany') || {});
  }
  return currentItem;
};

export const getUserInfo = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData')) || {};
  if (!userData || !userData.user) return {};
  return userData.user;
};

export default {
  getBrandId,
  getCompanyId,
  getStoreId,
  getStoreData,
  setSelectedCompany,
  setSelectedBrand,
  setSelectedStore,
  getSelectedCompanyName,
  getSelectedBrandName,
  getSelectedStoreName,
  getBrandObject,
  setBusinessNode,
  getBusinessNode,
  setBusinessType,
  getBusinessType,
  getAuthLocations,
  urlSearchParams,
  getOptedStoreData,
  setOptedStoreData,
  setConfigData,
  getCompanyData,
  getUserInfo,
};
