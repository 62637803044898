export default {
  MOBILE_NUMBER: 'Please enter valid mobile number',
  PASSWORD: 'Please enter valid password',
  FULL_NAME: 'Please enter your full name',
  COUNTRY_CODE: 'Please enter valid country code',
  EMAIL_ID: 'Please enter valid email',
  REENTER_PASSWORD: 'Please re-enter password',
  DOB: 'Please enter your DOB',
  MOBILE_AND_EMAIL: 'Please enter valid mobile number or email Id',
  FIRST_NAME: 'Please enter your first name',
  LAST_NAME: 'Please enter your last name',
  STREET_ADDRESS: 'Please enter your street address',
  LOCALITY: 'Please enter your locality/district',
  CITY: 'Please enter your city',
  STATE: 'Please enter your state',
  PINCODE: 'Please enter valid pincode',
  DROPDOWN: 'Please enter',
  OTP: 'Please enter valid OTP',

  // Notification_message
  SERVER_ERROR: 'Internal server error',
  INVALID_LOGIN: 'Invalid Login Credentials',
  INVALID_APIKEY: 'Invalid API key',
  SOMETHING_WRONG: 'Oops! Something went wrong please try again later',
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
  USER_NOT_FOUND: 'User not found!',
  INVALID_TABLE_NO: 'Invalid Table Number please try again',

  // Company
  COMPANY_ADD_FAILURE: 'Failed to Add Company',
  COMPANY_UPDATE_FAILURE: 'Failed to Update Company',
  TABLE_FILTER_ERROR: 'No matches for your filter',
  MALL_ADD_FAILURE: 'Failed to add venue',
  MALL_UPDATE_FAILURE: 'Failed to update venue',

  // Booking System
  BOOKING_SYSTEM_UPDATE_FAILURE: 'Failed to update Booking System',

  // Brand
  BRAND_ADD_FAILURE: 'Failed to Add Brand',
  BRAND_UPDATE_FAILURE: 'Failed to Update Brand',
  OFFER_SYSTEM_UPDATE_FAILURE: 'Failed to update offer system',
  TIPS_UPDATE_FAILURE: 'Failed to update Tips',
  OTHER_SETTINGS_UPDATE_FAILURE: 'Failed to update other setting',
  FAILED_TO_UPDATE_OMS_OFFER: 'Failed to update brand offer',

  // Charity
  CHARITY_ADD_FAILURE: 'Failed to add Charity',
  CHARITY_UPDATE_FAILURE: 'Failed to updated Charity',

  // Device
  DEVICE_ADD_FAILURE: 'Failed To Add Device',
  DEVICE_UPDATE_FAILURE: 'Failed To Update Device',
  DEVICE_REREGISTER_FAILURE: 'Failed To Re-Registered Device',
  DEVICE_DEREGISTER_FAILURE: 'Failed To De-Registered Device',
  SEND_MESSAGE_FAILURE: 'Failed to send message',
  REGISTER_BULK_OTG_FAILURE: 'Failed to register the devices',

  // Data Request
  DATA_REQUEST_FAILURE_MESSAGE: 'Failed to load data request',
  // POSMenu
  SCHEDULE_ADD_FAILURE: 'Failed to add schedule',
  SCHEDULE_UPDATE_FAILURE: 'Failed to update schedule',
  SCHEDULE_MENU_DELETE_FAILURE: 'Failed to delete schedule',
  POS_MENU_ADD_FAILURE: 'Failed to update POS System',
  ADD_MENU_STORE_FAILURE: 'Failed to add menu store',
  DELETE_MENU_STORE_FAILURE: 'Failed to delete menu store',
  ADD_SCREEN_STORE_FAILURE: 'Failed to add screen store',
  DELETE_SCREEN_STORE_FAILURE: 'Failed to delete screen store',
  POSMENU_DISPLAY_NAME_UPDATED_FAILURE: 'Failed to update pos menu name',
  // Profile
  PROFILE_UPDATE_FAILURE: 'Failed to update New Password',
  BASIC_DETAILS_FAILURE: 'Failed to update Basic Details',
  FAILED_TO_DELETE_CAMPAIGN_TRIGGER: 'Failed to delete campaign trigger',

  // Guest App
  GUEST_APP_UPDATE_FAILURE: 'Failed to update Guest App',

  // MAPPING
  MAPPING_UPDATE_FAILURE: 'Failed to Update Mapping',

  // PAYAMENT SYSTEM
  PAYMENT_SYSTEM_UPDATE_FAILURE: 'Failed to update Payment System',

  // SERVER APP
  SERVER_APP_UPDATE_FAILURE: 'Failed to update Server App',

  // Tile
  TILE_ADD_FAILURE: 'Failed To Add Tile',
  TILE_UPDATE_FAILURE: 'Failed To Update Tile',
  TILE_DELETE_FAILURE: 'Failed To Delete Tile',
  TILES_SORTING_FAILURE: 'Failed To Sort Tile',

  // Delivery
  DELIVERY_DELETE_FAILURE: 'Failed to delete delivery setup item',
  DELIVERY_ADD_FAILURE: 'Failed to add delivery setup',
  DELIVERY_UPDATE_FAILURE: 'Failed to update delivery setup',
  DELIVERY_RULE_DELETE_FAILURE: 'Failed to delete delivery rule item',
  DELIVERY_RULE_ADD_FAILURE: 'Failed to add delivery tier',
  DELIVERY_RULE_UPDATE_FAILURE: 'Failed to update delivery tier',
  DELIVERY_SLOT_DELETE_FAILURE: 'Failed to delete delivery slot item',
  DELIVERY_SLOT_ADD_FAILURE: 'Failed to add delivery slot',
  DELIVERY_SLOT_UPDATE_FAILURE: 'Failed to update delivery slot',

  // TIPS
  TIP_DELETE_FAILURE: 'Failed to delete tip setup item',
  TIP_ADD_FAILURE: 'Failed to add tip',
  TIP_UPDATE_FAILURE: 'Failed to updated tip',
  TIP_RULE_UPDATE_FAILURE: 'Failed to update tip rule',
  TIP_RULE_DELETE_FAILURE: 'Failed to delete tip rule',
  TIP_RULE_ADD_FAILURE: 'Failed to add tip rule',


  // SURVEY
  QUESTION_ADD_FAILURE: 'Failed to Add Question',
  QUESTION_UPDATE_FAILURE: 'Failed to Update Question',
  RESPONSE_DELETE_FAILURE: 'Failed to Delete Response',
  RESPONSE_ADD_FAILURE: 'Failed to Add Response',
  RESPONSE_UPDATE_FAILURE: 'Failed to Update Response',
  SURVEY_ADD_FAILURE: 'Failed To Add Survey',
  SURVEY_UPDATE_FAILURE: 'Failed to Update Survey',
  QUESTION_DELETE_FAILURE: 'Failed to Delete Question',
  DELETE_WELCOME_IMAGE_FAILURE: 'Failed to delete Welcome Image',
  DELETE_CONFIRMATION_IMAGE_FAILURE: 'Failed to delete Confirmation Image',
  WELCOME_IMAGE_FAILURE: 'Failed to upload Welcome Image',
  CONFIRMATION_IMAGE_FAILURE: 'Failed to upload Confirmation Image',
  QUICK_RESPONSES_ADD_FAILURE: 'Failed to Add Quick Responses',

  // TAX
  TAX_ADD_FAILURE: 'Failed To Add Tax Rate',
  TAX_UPDATE_FAILURE: 'Failed to Update Tax Rate',

  // USER
  USER_UPDATE_FAILURE: 'Failed to update user',
  USER_ADD_FAILURE: 'Failed to add user',

  // store
  STORE_ADD_FAILURE: 'Failed To Add Store',
  STORE_UPDATE_FAILURE: 'Failed To Update Store',
  CHANNEL_CONFIG_UPDATE_FAILUER: 'Failed to update channel config',
  UPDATE_ENTERPRISE_ITEM_FAILUER: 'Failed to update enterprise item',


  FAILED_TO_ADD_MENUITEM_IMAGE: 'Failed To Add MenuItem Image',
  FAILED_TO_DELETE_MENUITEM_IMAGE: 'Failed To Delete MenuItem Image',
  FAILED_TO_UPDATE_MODIFIER: 'Failed To Update Modifier',
  FAILED_TO_ADD_MODIFIER: 'Failed To Add Modifier',
  FAILED_TO_ADD_MODIFIER_GROUP: 'Failed to Add Modifier Group',
  FAILED_TO_UPDATED_MODIFIER_GROUP: 'Failed to Update Modifier Group',
  FAILED_TO_ADD_SCREEN: 'Failed To Add Screen',
  FAILED_TO_UPDATED_SCREEN: 'Failed To Update Screen',
  FAILED_TO_CLONE_SCREEN: 'Failed To Clone Screen',
  FAILED_TO_CLONE_MODIFIER_GROUP: 'Failed To Clone Modifier Group',
  FAILED_TO_CLONE_MODIFIER: 'Failed To Clone Modifier',
  FAILED_TO_UPDATE_THE_JOB: 'Failed to update the Job',
  FAILED_TO_ADD_THE_JOB: 'Failed to add the Job',
  FAILED_TO_ADD_MALL_LOGO: 'Failed to add venue ',
  FAILED_TO_DELETED_MALL_LOGO: 'Failed to delete venue ',
  FAILED_TO_DELETED_STORE: 'Failed to delete store',
  FAILED_TO_ADD_STORE: 'Failed to Add store',
  FAILED_TO_ADD_MENUITEM: 'Failed To Add MenuItem',
  FAILED_TO_ADD_THE_TABLEGROUP: 'Failed to add the TableGroup',
  FAILED_TO_UPDATE_THE_TABLEGROUP: 'Failed to Update the TableGroup',
  TEXT_TO_PAY_REQUEST_FAILED: 'Text to pay request failed, Try again',
  FAILED_TO_UPDATE_STORE_PHONE: 'Failed to update store phone',
  FAILED_TO_DELETED_STORE_PHONE: 'Failed to delete store phone',
  FAILED_TO_ADD_MENU: 'Failed To Add Menu',
  FAILED_TO_UPDATE_MENU: 'Failed To Update Menu',
  FAILED_TO_CLONE_MENU: 'Failed To Clone Menu',
  FAILED_TO_UPDATE_MENUITEM: 'Failed To Update MenuItem',
  FAILED_TO_UPDATE_INGENICO: 'Failed to update ingenico',
  FAILED_TO_UPDATE_CARD_CONNECT_BOLT: 'Failed to update card connect bolt',
  FAILED_TO_SEND_API: 'Failed to send API key SMS',
  FAILED_REVOKED_API: 'Failed to revoke API key',
  FAILED_TO_UPDATE_STORE_HOURS: 'Failed to update store hours',
  FAILED_TO_DELETE_THE_STORE_IMAGE: 'Failed to delete the store Image',
  FAILED_TO_ADD_THE_DEFAULT_MENUITEM_IMAGE: 'Failed to add the Default MenuItem Image',
  FAILED_TO_ADD_THE_TABLETOP_CONFIRMATION_IMAGE: 'Failed to add the TableTop Confirmation Image',
  FAILED_TO_ADD_THE_TABLETOP_LANDING_IMAGE: 'Failed to add the TableTop Landing Image',
  FAILED_TO_ADD_THE_KIOSK_CONFIRMATION_IMAGE: 'Failed to add the Kiosk Confirmation Image',
  FAILED_TO_ADD_THE_STORE_LOGO_IMAGE: 'Failed to add the store Logo Image',
  FAILED_TO_ADD_THE_STORE_IMAGE: 'Failed to add the store Image',
  FAILED_TO_DELETE_THE_DEFAULT_MENUITEM_IMAGE: 'Failed to delete the Default MenuItem Image',
  FAILED_TO_DELETE_THE_TABLETOP_CONFIRMATION_IMAGE: 'Failed to delete the TableTop Confirmation Image',
  FAILED_TO_DELETE_THE_TABLETOP_LANDING_IMAGE: 'Failed to delete the TableTop Landing Image',
  FAILED_TO_ADD_THE_KIOSK_LANDING_IMAGE: 'Failed to add the Kiosk Landing Image',
  FAILED_TO_DELETE_THE_STORE_LOGO_IMAGE: 'Failed to delete the store Logo Image',
  FAILED_TO_UPDATE_MOBILE_PAYMENT_SYSTEM: 'Failed to update Mobile Payement System',
  FAILED_TO_DELETE_THE_TABLEGROUP: 'Failed To Delete the TableGroup',
  FAILED_TO_UPDATE_PAYTRONIX_LOYALTY: 'Failed to update Paytronix Loyalty',
  FAILED_TO_UPDATE_PAYTRONIX_GIFT_CARD: 'Failed to update Paytronix Gard Card',
  FAILED_TO_UPDATE_PNP: 'Failed to update PNP',
  FAILED_TO_GENERATE_CHANNEL_SETUP: 'Failed to generate Channel setup',
  FAILED_TO_UPDATE_STORE_TAXE_RATES: 'Failed to update store taxe rates',
  FAILED_TO_SAVE_THE_CHARITY_IMAGE: 'Failed to save the charity Image',
  FAILED_TO_DELETE_THE_CHARITY_IMAGE: 'Failed to delete the charity Image',
  FAILED_TO_ADD_THE_MENU_IMAGE: 'Failed to add the menu Image',
  FAILED_TO_DELETE_THE_MENU_IMAGE: 'Failed to delete the menu Image',
  FAILED_TO_DELETE_MENU: 'Failed To Delete Menu',
  FAILED_TO_DELETE_SCREEN: 'Failed To Delete Screen',
  FAILED_TO_ADD_SCREEN_IMAGE: 'Failed To Add Screen Image',
  FAILED_TO_DELETE_SCREEN_IMAGE: 'Failed To Delete Screen Image',
  STORE_BATCH_EXECUTION_FAILUER: 'Failed service execution action',
  GUEST_REPORT_FAILURE: 'Failed to updated Email Address',
  FAILED_TO_ADD_MODIFIER_IMAGE: 'Failed To Add Modifier Item Image',
  FAILED_TO_DELETE_MODIFIER_IMAGE: 'Failed To Delete Modifier Image',
  FAILED_TO_UPDATE_NMI_PAYMENTS: 'Failed to update NMI Payments',
  FAILED_TO_UPDATE_PAYMENT_SENSE: 'Failed to update Payment Sense',
  FAILED_TO_UPDATE_CLUTCH_LOYALTY: 'Failed to update Clutch Loyalty',
  FAILED_TO_UPDATE_LOOK_LOYALTY: 'Failed to update Look Loyalty',

  // SIFT Fraud System
  FAILED_TO_UPDATE_SIFT_FRAUD_SYSTEM: 'Failed to update SIFT Fraud System',

  // ROKT
  FAILED_TO_UPDATE_ROKT_INTEGRATION: 'Failed to update ROKT Integration',

  // Marketman
  FAILED_TO_UPDATE_MARKETMAN_INVENTORY: 'Failed to update Marketman Inventory',

  // Named Sensor
  NAMED_SENSOR_ADD_FAILURE: 'Failed To Add Sensor',
  NAMED_SENSOR_UPDATE_FAILURE: 'Failed To Update Sensor',
  DEREGISTER_SENSOR_FAILURE: 'Failed to Deregister Sensor',

  // seatOrder
  SEAT_ORDER_APPROVAL_FAILURE: 'Failed to Approve/Reject seat order',
  ADD_MDM_POLICY_FAILED: 'Failed to register under MDM',
  UPDATE_MDM_POLICY_FAILED: 'Failed to update MDM Policy',

  // prep times
  FAILED_TO_DELETE_PREP_TIME: 'Failed to delete prep time',
  FAILED_TO_ADD_PREP_TIME: 'Failed to add prep time',
  FAILED_TO_UPDATE_PREP_TIME: 'Failed to update prep time',
  // TMS Store Tag
  FAILED_TO_REGISTER_TMS: 'Failed to register under TMS',
  FAILED_UPDATE_TMS_CHANNEL: 'Failed to update channel',

  FAILED_TO_UPDATE_TMS_OCCASSION: 'Failed to update occassion',

  FAILED_TO_UPDATE_HEARTLAND: 'Failed to update heartland',
  FAILED_TO_UPDATE_SPARKFLY_SETUP: 'Failed to update sparkfly setup.',

  APP_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update App customization',
  FAILED_TO_UPLOAD_TILE_PDF: 'Failed to upload file',
  FAILED_TO_DELETE_TILE_PDF: 'Failed to delete file',
  FAILED_TO_UPLOAD_FONT: 'Failed to upload font',
  FAILED_TO_UPLOAD_HEADER_FONT: 'Failed to upload header font',
  FAILED_TO_DELETE_FONT: 'Failed to delete font',
  FAILED_TO_DELETE_HEADER_FONT: 'Failed to delete header font',
  CHECKOUT_FAILED_TO_UPLOAD_FONT: 'Failed to upload checkout font',
  CHECKOUT_FAILED_TO_UPLOAD_HEADER_FONT: 'Failed to upload checkout header font',
  CHECKOUT_FAILED_TO_DELETE_FONT: 'Failed to delete checkout font',
  CHECKOUT_FAILED_TO_DELETE_HEADER_FONT: 'Failed to delete checkout header font',
  FAILED_TO_UPLOAD_OPTIN_CSV: 'Failed to upload file',


  FAILED_TO_UPDATE_PAYPAL: 'Failed to update paypal',
  FAILED_TO_GET_ONBOARDING_PAYPAL: 'Failed to get On boarding url',
  FAILED_TO_GET_PAYPAL: 'Failed to get paypal',

  STORE_ADD_ACCOUNTING_FAILURE: 'Failed to add Accounting Info',
  STORE_UPDATE_ACCOUNTING_FAILURE: 'Failed to update Accounting Info',

  FAILED_TO_ADD_THE_SENSORGROUP: 'Failed to add sensor group',
  FAILED_TO_ADD_THE_SENSORGROUPS: 'Failed to add sensor group(s)',
  FAILED_TO_UPDATE_THE_SENSORGROUP: 'Failed to update sensor group',
  FAILED_TO_DELETE_THE_SENSORGROUP: 'Failed to delete sensor group',
  FAILED_TO_DELETE_THE_SENSORGROUPS: 'Failed to delete sensor group(s)',

  FAILED_TO_UPDATE_GIVEX: 'Failed to update givex',

  // admin Tags
  FAILED_TO_ADD_THE_ADMIN_TAG: 'Failed to add the tag',
  FAILED_TO_UPDATE_THE_ADMIN_TAG: 'Failed to updated the tag',
  UPDATED_MEMBER_RELEASE_NOTE_FAILURE: 'Failed to update member release note',
  UPDATE_COLUMN_FAILURE: 'Failed to update table column',

  // Campaigns
  CAMPAIGN_ADD_FAILURE: 'Failed to add campaigns',
  CAMPAIGN_TRIGGER_ADD_FAILURE: 'Failed to add campaigns trigger',
  CAMPAIGN_TRIGGER_UPDATE_FAILURE: 'Failed to update campaigns trigger',
  CAMPAIGN_DELETE_FAILURE: 'Failed to delete campaigns',
  CAMPAIGN_UPDATE_FAILURE: 'Campaign update failuer',
  FAILED_TO_ADD_CAMPAIGN_IMAGE: 'Failed to Add Campaign Image',
  FAILED_TO_DELETE_CAMPAIGN_IMAGE: 'Failed to Delete Campaign Image',
  CAMPAIGN_ADD_LOCATION_FAILURE: 'Failed to add campaign location',

  FAILED_TO_UPDATE_MERGE_MENU_ITEM: 'Failed to updated merge menu item',
  FAILED_TO_UPDATE_MERGE_MODIFIER: 'Failed to updated merge modifier',

  FAILED_RESET_PASSWORD: 'Failed to send the OTP',

  FAILED_TO_UPDATE_STRIPE: 'Failed to update stripe',
  FAILED_TO_GET_STRIPE: 'Failed to get stripe',

  FAILED_TO_UPDATE_BRAND_TAG: 'Failed to override brandTag',

  FAILED_TO_ADD_BRAND_TAG_IMAGE: 'Failed to Add Brand Tag Icon',
  FAILED_TO_DELETE_BRAND_TAG_IMAGE: 'Failed to Delete Brand Tag Icon',

  FAILED_TO_ADD_ADMIN_TAG_IMAGE: 'Failed to Add Tag Icon',
  FAILED_TO_DELETE_ADMIN_TAG_IMAGE: 'Failed to Delete Tag Icon',

  FAILED_TO_ADD_BRAND_TILE_IMAGE: 'Failed to Add Tile Image',
  FAILED_TO_DELETE_BRAND_TILE_IMAGE: 'Failed to Delete Tile Image',

  FAILED_TO_ADD_BRAND_CHARITY_IMAGE: 'Failed to Add Charity Image',
  FAILED_TO_DELETE_BRAND_CHARITY_IMAGE: 'Failed to Delete Charity Image',
  BRAND_CHARITY_DELETE_FAILURE: 'Failed to delete charity',
  FAILED_TO_UPDATE_SPREEDLY: 'Failed to update Spreedly for Toast',
  FAILED_TO_GET_SPREEDLY: 'Failed to get Spreedly for Toast',

  // Segment
  SEGEMNT_ADD_FAILURE: 'Failed to add Segment',
  SEGMENT_UPDATE_FAILURE: 'Failed to upadte Segment',
  SEGMENT_OFFER_DEACTIVATE_FAILURE: 'Failed to deactivate Segment Offer',
  REISSUE_OFFER_FAILURE: 'Offer Reissue Failed',
  ISSUE_OFFER_FAILURE: 'Offer Issue Failed',
  IMPORT_CSV_SEGMENT_GUESTS_FAILURE: 'Import Segment Guests failed',

  // SMS Template
  SMS_TEMPLATE_DELETE_FAILURE: 'Failed to delete sms template',
  SMS_TEMPLATE_ADD_FAILURE: 'Failed to add sms template',
  UPDATE_TEMPLATE_ADD_FAILURE: 'Failed to update sms template',
  // System Notification
  SN_ADD_FAILURE: 'Failed to add system notification',
  SN_UPDATE_FAILURE: 'Failed to update system notification',
  SN_DELETE_FAILURE: 'Failed to delete system notification',
  SN_PUBLISH_FAILURE: 'Failed to publish system notification',

  // campaign Occurrence
  CAMPAIGN_OCCURRENCE_ADD_FAILURE: 'Failed to Add Campaign Occurrence',
  CAMPAIGN_OCCURRENCE_UPDATE_FAILURE: 'Failed to Update Campaign Occurrence',
  CAMPAIGN_OCCURRENCE_RULE_ADD_FAILURE: 'Failed To Add Campaign Occurrence Rule',
  CAMPAIGN_OCCURRENCE_RULE_DELETE_FAILURE: 'Failed To Delete Campaign Occurrence Rule',
  PUBLISH_FAILURE: 'Failed to Publish Campaign',

  // Category
  FAILED_TO_ADD_CATEGORY_ADDED_SUCCESSFULLY: 'Failed to add categorty',
  FAILED_TO_UPDATE_CATEGORY: 'Failed to update categorty',

  // Email Template
  EMAIL_TEMPLATE_DELETE_FAILURE: 'Failed to delete Email template',
  EMAIL_TEMPLATE_ADD_FAILURE: 'Failed to add Email template',
  UPDATE_EMAIL_TEMPLATE_ADD_FAILURE: 'Failed to update Email template',

  // Download Apps
  DOWNLOAD_FAILURE: 'Failed to download',
  // External Script
  UPDATE_TEXTERNAL_SCRIPT_FAILURE: 'Failed to update External script',
  // Store setups
  FAILED_TO_ADD_THE_CATEGORIES: 'Failed to add categories',
  FAILED_TO_UPDATE_THE_CATEGORIES: 'Failed to update categories',
  FAILED_TO_ADD_THE_DISCOUNTS: 'Failed to add discounts',
  FAILED_TO_UPDATE_THE_DISCOUNTS: 'Failed to update discounts',
  FAILED_TO_ADD_THE_ORDER_TYPES: 'Failed to add order types',
  FAILED_TO_ADD_THE_TENDER_TYPE: 'Failed to add tender types',
  FAILED_TO_UPDATE_THE_TENDER_TYPE: 'Failed to update tender types',
  FAILED_TO_UPDATE_THE_ORDER_TYPES: 'Failed to update order type',
  FAILED_TO_ADD_THE_RC: 'Failed to add revenue center',
  FAILED_TO_UPDATE_THE_RC: 'Failed to update revenue center',
  FAILED_TO_ADD_THE_TABLE: 'Failed to add table',
  FAILED_TO_UPDATE_THE_TABLE: 'Failed to update table',
  FAILED_TO_QUICK_ADD_THE_TABLE: 'Failed to quick add tables',
  FAILED_TO_ADD_THE_STORE_DRAWER: 'Failed to add store drawers',
  FAILED_TO_UPDATE_THE_STORE_DRAWER: 'Failed to update store drawers',
  FAILED_TO_ADD_THE_STORE_SECTION: 'Failed to add store section',
  FAILED_TO_UPDATE_THE_STORE_SECTION: 'Failed to update the store section',
  // Teams
  FAILED_TO_ADD_JOB_TEAM: 'Failed to add job team',
  FAILED_TO_UPDATE_JOB_TEAM: 'Failed to update job team',
  // Service Charges
  FAILED_TO_ADD_SERVICE_CHARGES: 'Failed to add service charges',
  FAILED_TO_UPDATE_SERVICE_CHARGES: 'Failed to update service charges',

  // Charge
  CHARGE_DELETE_FAILURE: 'Failed to delete charge',
  CHARGE_ADD_FAILURE: 'Failed to add charge',
  CHARGE_TIER_ADD_FAILURE: 'Failed to add charge tier',
  CHARGE_UPDATE_FAILURE: 'Failed to update charge',
  CHARGE_TIER_UPDATE_FAILURE: 'Failed to update charge tier',
  CHARGE_TIER_DELETE_FAILURE: 'Failed to delete charge tier',

  FAILED_TO_UPDATE_THE_DATA_REQUEST: 'Failed to update data request',
  FAILED_TO_FETCH_DATA_REPORT: 'Failed to fetch data request report',
  // Print Category
  ADDED_PRINT_CATEGORY_FAILURE: 'Failed to added print category',
  UPDATED_PRINT_CATEGORY_FAILURE: 'Failed to updated print category',
  DELETED_PRINT_CATEGORY_FAILURE: 'Failed to deleted print category',

  FAILED_TO_CALL_AGENT_UPDATE: 'Failed to call agent update',
  FAILED_TO_REACTIVATE_POSAGENT: 'Failed to reactivate pos agent',
  FAILED_TO_RESTART_POSAGENT: 'Failed to restart pos agent',
  FAILED_TO_REFESH_CACHE: 'Failed to refresh cache',
  FAILED_TO_DOWNLOAD_MENU_FILE: 'Failed to download menu file',
  FAILED_TO_DOWNLOAD_CURRENT_LOG_FILE: 'Failed to download current log file',
  FAILED_TO_DOWNLOAD_LOGS: 'Failed to download logs',
  FAILED_TO_GET_AGENT_INFO: 'Failed to get agent health info',

  // VOUCHERS
  FAILED_TO_ADD_VOUCHERS: 'Failed to add vouchers',
  FAILED_TO_UPDATE_VOUCHERS: 'Failed to update vouchers',
  FAILED_TO_ADD_BULK_VOUCHERS: 'Failed to add bulk vouchers',
  FAILED_TO_ADD_VOUCHER_DATE: 'Failed to add voucher date',
  FAILED_TO_UPDATE_VOUCHER_DATE: 'Failed to update voucher date',
  FAILED_TO_DELETE_VOUCHER_DATE: 'Failed to delete voucher date',
  FAILED_TO_ADD_VOUCHER_TIME: 'Failed to add voucher time',
  FAILED_TO_UPDATE_VOUCHER_TIME: 'Failed to update voucher time',
  FAILED_TO_DELETE_VOUCHER_TIME: 'Failed to delete voucher time',
  FAILED_TO_ADD_VOUCHER_IMAGE: 'Failed to add voucher image',
  FAILED_TO_DELETE_VOUCHER_IMAGE: 'Failed to delete voucher image',

  // Device Group

  DEVICE_GROUP_UPDATE_FAILURE: 'Failed to update Device Group',
  DEVICE_GROUP_ADD_FAILURE: 'Failed to add Device Group',
  DEVICE_GROUP_DELETE_FAILURE: 'Failed to delete Device Group',

  // APILOG DETAILS
  APILOG_DETAILS_FAILURE: 'Failed to fetch apilog details',

  // Store Production
  FAILED_TO_ADD_STORE_PROD: 'Failed to add store production rate',
  FAILED_TO_UPDATE_STORE_PROD: 'Failed to update store production rate',
  STORE_PROD_DELETED_SUCCESSFULLY: 'Failed to delete selected store production rate',
  FAILED_TO_OVERRIDE_STORE_PROD: 'Failed to override store production rate',
  FAILED_TO_QUICK_ADD_STORE: 'Failed to quick add store production rate',
  PLEASE_SELECT_STORE: 'Please select a store',
  FAILED_TO_QUICK_DELETE_STORE: 'Failed to quick delete store production rate',

  // Admin language
  APP_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update mobile web default customization',
  SERVER_APP_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update serverApp default customization',
  KIOSK_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update kiosk customization',
  TABLE_TOP_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update tanleTop default customization',
  KDS_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update kds customization',
  ORDER_UP_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update orderUp default customization',
  WAIT_LIST_DEFAULT_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update wait list customization',
  MOBILE_APP_CUSTOMIZATION_UPDATE_FAILURE: 'Failed to update Mobile web new default  customization',


  // Brand language
  DELETE_BRAND_LANGUAGE_FAILURE: 'Failed to delete the selected language',
  DEFAULT_BRAND_LANGUAGE_FAILURE: 'Failed to make the selected language as default',
  BRAND_LANGUAGE_SORTING_FAILURE: 'Failed to sort language',
  ADDED_BRAND_LANGUAGE_FAILURE: 'Failed to add the selected language',
  ENABLE_FAILURE: 'Failed to enable selected language',

  // theme
  BRAND_SERVER_APP_THEME_UPDATE_FAILURE: 'Failed to update brand Server App theme',
  BRAND_KIOSK_THEME_UPDATE_FAILURE: 'Failed to update brand Kiosk theme',
  BRAND_TABLE_TOP_THEME_UPDATE_FAILURE: 'Failed to update brand Tabletop theme',
  BRAND_ORDER_UP_THEME_UPDATE_FAILURE: 'Failed to update brand OrderUp theme',
  BRAND_KDS_THEME_UPDATE_FAILURE: 'Failed to update brand KDS theme',
  BRAND_MOBILE_APP_THEME_UPDATE_FAILURE: 'Failed to update Mobile App theme',
  BRAND_WAITLIST_THEME_UPDATE_FAILURE: 'Failed to update brand Wait List theme',

  // Cubby
  CUBBY_ADD_FAILURE: 'Failed to add cubby',
  CUBBY_UPDATE_FAILURE: 'Failed to update cubby',
  SLOTS_ADD_FAILURE: 'Failed To add cubby slots',
  SLOTS_UPDATE_FAILURE: 'Failed to update cubby slots',

  // Fiserv
  FAILED_TO_UPDATE_FISERV: 'Failed to update fiserv',

  // Internationalization
  LANGUAGE_CUSTOMIZATION_FAILURE: 'Failed to update lable customization of',

  // Employee
  FAILED_TO_UNLINK_EMPLOYEE: 'Failed to unlink selected employee',
  FAILED_TO_LINK_EMPLOYEE: 'Failed to link selected employee',

  // Financial Account
  BRAND_ACCOUNT_UPDATE_FAILURE: 'Failed to updated brand account',
  STORE_ACCOUNT_UPDATE_FAILURE: 'Failed to updated store account',

  // reorder

  ORDER_TYPE_SORTING_FAIL: 'Failed To Sort Order Type',
  REVENUE_CENTER_SORTING_FAIL: 'Failed To Sort Revenue Center',

  // EmpApp Payment Adjustment
  PAYMENT_ADJUSTMENT_REPORT_FAILURE: 'Payment Adjustment Failed',

  // EmpApp Void
  VOID_ITEM_FAILURE: 'Void Items Failed',
  VOID_CHECK_FAILURE: 'Void Check Failed',
  CHECK_ALREADY_VOIDED: 'Check has been already voided',
  DISCOUNT_CHECK_FAILURE: 'Discount Check Failed',

  // Risk Rule
  RISK_RULE_ADD_FAILURE: 'Failed to add Risk Rule',
  RISK_RULE_UPDATE_FAILURE: 'Failed to update Risk Rule',
  RISK_RULE_DELETE_FAILURE: 'Failed To Delete Risk Rule',

  // Payment Adjustment
  REFUND_FAILURE: 'Refund Failed',
  CHARGEBACK_FAILURE: 'Chargeback Failed',
  USER_BLOCKING_FAILURE: 'User Blocking Failed',

  // Apple pay
  APPLE_PAY_FAIL: 'Failed to Verify Apple Pay',

  // TSD Global
  TSD_GLOBAL_UPDATE_FAILURE: 'Failed to Update TSD Global',

  // STORE MAPPING
  STORE_MAPPING_UPDATE_FAILURE: 'Failed to update Store Mapping',
  TENDER_TYPE_MAPPING_UPDATE_FAILURE: 'Failed to update Tender Type Mapping',
  OFF_PREMISE_MAPPING_UPDATE_FAILURE: 'Failed to update Off Premise Mapping',
  POS_SMS_MAPPING_UPDATE_FAILURE: 'Failed to update POS SMS Mapping',

  // MENU COURSES
  FAILED_TO_ADD_MENU_COURSE: 'Failed to Add Menu Course',
  FAILED_TO_UPDATE_MENU_COURSE: 'Failed to Update Menu Course',
  MENU_COURSE_DELETE_FAILURE: 'Failed to Delete Menu Course',
  MENU_COURSE_SORTING_FAIL: 'Failed to Sort Menu Course',

  // Date request report
  FAILED_TO_DELETED_SELECTED_DATA_REPORT: 'Failed to delete selected data report',
  FAILED_TO_UPDATED_GUEST_DATA: 'Failed to update guest data',

  // UserWay Third Party
  FAILED_TO_UPDATE_USERWAY_WIDGET: 'Failed to update UserWay Accessibility Widget',

  // Cabbage Pay
  FAILED_TO_UPDATE_CABBAGE_PAY: 'Failed to update cabbage pay',
  FAILED_TO_GET_CABBAGE_PAY: 'Failed to get cabbage pay',

  // Brand KDS Setup
  FAILED_TO_UPDATE_KDS_SETTINGS: 'Failed to update KDS settings',
  BRAND_KDS_STATION_TYPE_DELETE_FAILURE: 'Failed to delete KDS Station Type',
  BRAND_KDS_STATION_TYPE_ADD_FAILURE: 'Failed to add KDS Station Type',
  BRAND_KDS_STATION_TYPE_UPDATE_FAILURE: 'Failed to update KDS Station Type',
  BRAND_KDS_LAYOUT_ADD_FAILURE: 'Failed to add KDS Layout',
  BRAND_KDS_LAYOUT_UPDATE_FAILURE: 'Failed to update KDS Layout',
  BRAND_KDS_LAYOUT_DELETE_FAILURE: 'Failed to delete KDS Layout',
  BRAND_KDS_LAYOUT_SORTED_FAILURE: 'Failed to sort KDS Layout',
  BRAND_KDS_PRINT_ADD_FAILURE: 'Failed to add KDS Print Definition',
  BRAND_KDS_PRINT_UPDATE_FAILURE: 'Failed to update KDS Print Definition',
  BRAND_KDS_PRINT_DELETE_FAILURE: 'Failed to delete KDS Print Definition',
  DEFAULT_KDS_PRINT_FAILURE: 'Failed to make the selected KDS Print Definition as default',


  // Amazon Pay
  FAILED_TO_UPDATE_AMAZON_PAY: 'Failed to update amazon pay',
  FAILED_TO_GET_AMAZON_PAY: 'Failed to get amazon pay',

  // KDS Layout
  DEFAULT_KDS_LAYOUT_FAILURE: 'Failed to make the selected KDS Layout as default',

  // Medallia Survey
  FAILED_TO_UPDATE_MEDALLIA_SURVEY: 'Failed to update Medallia Survey',

  // External Integration
  EXTERNAL_INTEGRATION_DELETE_FAILURE: 'Failed to delete External integration',
  EXTERNAL_INTEGRATION_ADD_FAILURE: 'Failed to add External integration',
  EXTERNAL_INTEGRATION_UPDATE_FAILURE: 'Failed to update External integration',
  EXTERNAL_INTEGRATION_REGENERATE_FAILURE: 'Failed to regenerate External integration',

  // Mobile App
  FAILED_TO_ADD_MOBILE_APP_MEDIA: 'Failed to add Mobile App Media',
  FAILED_TO_ADD_MOBILE_APP_MEDIA_IMAGE: 'Failed to add Mobile App Media Image',
  FAILED_TO_UPDATE_MOBILE_APP_MEDIA: 'Failed to update Mobile App Media',
  MOBILE_APP_LAYOUT_ADD_FAILURE: 'Failed to add Mobile App Layout',
  MOBILE_APP_LAYOUT_UPDATE_FAILURE: 'Failed to update Mobile App Layout',
  MOBILE_APP_LAYOUT_ENABLE_PRODUCTION_FAILURE: 'Failed to enable the Layout for Production',
  MOBILE_APP_LAYOUT_ENABLE_TEST_FAILURE: 'Failed to enable the Layout for Test',
  MOBILE_APP_PAGE_ADD_FAILURE: 'Failed to add Mobile App Page',
  MOBILE_APP_PAGE_UPDATE_FAILURE: 'Failed to update Mobile App Page',
  MOBILE_APP_LAYOUT_DESIGN_UPDATE_FAILURE: 'Failed to update Mobile App Layout Design',
  MOBILE_APP_PAGE_DESIGN_UPDATE_FAILURE: 'Failed to update Mobile App Page design',
  MOBILE_APP_LAYOUT_DELETE_FAILURE: 'Failed to delete Mobile App Layout',
  FAILED_TO_DELETE_MOBILE_APP_MEDIA: 'Failed to delete Mobile App Media',
  MOBILE_APP_PAGE_DELETE_FAILURE: 'Failed to delete Mobile App Page',

  // menu Device group

  FAILED_TO_ADD_THE_DEVICEGROUPS: 'Failed to add device group(s)',
  FAILED_TO_DELETE_THE_DEVICEGROUPS: 'Failed to delete device group(s)',
  // intercard

  FAILED_UPDATED_INTERCARD: 'Failed to update Intercard',
  FAILED_UPDATED_POWERCARD: 'Failed to update Powercard',
  FAILED_UPDATED_GIFTCARD: 'Failed to update Intercard',

  // POS Menu Load
  FAILED_UPDATED_POS_SYSTEM: 'Failed to update POS System',

  // D&B Loyalty
  FAILED_TO_UPDATE_DB_LOYALTY: 'Failed to update D&B Loyalty',

  // Role Templates
  ROLE_TEMPLATES_DELETE_FAILURE: 'Failed to delete Role templates',
  ROLE_TEMPLATES_ADD_FAILURE: 'Failed to add Role templates',
  ROLE_TEMPLATES_UPDATE_FAILURE: 'Failed to update Role templates',

  // Company Role
  FAILED_TO_ADD_ROLE: 'Failed to add Role',
  FAILED_TO_UPDATE_ROLE: 'Failed to update Role',
  FAILED_TO_DELETE_ROLE: 'Failed to delete Role',
  FAILED_TO_ADD_ROLE_PERMISSIONS: 'Failed to add Role Permissions',

  // Permissions Templates
  FAILED_TO_UPDATE_PERMISSION: 'Failed to update Permissions Templates',
};
