export const GET_MAPPING_BY_STORE_ID_REQUESTED = 'store/GET_MAPPING_BY_STORE_ID_REQUESTED';
export const GET_MAPPING_BY_STORE_ID_SUCCESS = 'store/GET_MAPPING_BY_STORE_ID_SUCCESS';
export const GET_MAPPING_BY_STORE_ID_FAILURE = 'store/GET_MAPPING_BY_STORE_ID_FAILURE';

export const GET_MAPPING_STORE_ID_REQUESTED = 'store/GET_MAPPING_STORE_ID_REQUESTED';
export const GET_MAPPING_STORE_ID_SUCCESS = 'store/GET_MAPPING_STORE_ID_SUCCESS';
export const GET_MAPPING_STORE_ID_FAILURE = 'store/GET_MAPPING_STORE_ID_FAILURE';

export const UPDATE_MAPPING_BY_STORE_ID_REQUESTED = 'store/GET_MAPPING_BY_STORE_ID_REQUESTED';
export const UPDATE_MAPPING_BY_STORE_ID_SUCCESS = 'store/GET_MAPPING_BY_STORE_ID_SUCCESS';
export const UPDATE_MAPPING_BY_STORE_ID_FAILURE = 'store/GET_MAPPING_BY_STORE_ID_FAILURE';

export const UPDATE_STOREMAPPING_BY_STORE_ID_REQUESTED = 'store/GET_STOREMAPPING_BY_STORE_ID_REQUESTED';
export const UPDATE_STOREMAPPING_BY_STORE_ID_SUCCESS = 'store/GET_STOREMAPPING_BY_STORE_ID_SUCCESS';
export const UPDATE_STOREMAPPING_BY_STORE_ID_FAILURE = 'store/GET_STOREMAPPING_BY_STORE_ID_FAILURE';

export const UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_REQUESTED = 'store/GET_OFF_PREMISE_MAPPING_BY_STORE_ID_REQUESTED';
export const UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_SUCCESS = 'store/GET_OFF_PREMISE_MAPPING_BY_STORE_ID_SUCCESS';
export const UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_FAILURE = 'store/GET_OFF_PREMISE_MAPPING_BY_STORE_ID_FAILURE';

export const UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_REQUESTED = 'store/GET_TENDER_TYPE_MAPPING_BY_STORE_ID_REQUESTED';
export const UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_SUCCESS = 'store/GET_TENDER_TYPE_MAPPING_BY_STORE_ID_SUCCESS';
export const UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_FAILURE = 'store/GET_TENDER_TYPE_MAPPING_BY_STORE_ID_FAILURE';

export const UPDATE_POS_SMS_MAPPING_BY_STORE_ID_REQUESTED = 'store/GET_POS_SMS_MAPPING_BY_STORE_ID_REQUESTED';
export const UPDATE_POS_SMS_MAPPING_BY_STORE_ID_SUCCESS = 'store/GET_POS_SMS_MAPPING_BY_STORE_ID_SUCCESS';
export const UPDATE_POS_SMS_MAPPING_BY_STORE_ID_FAILURE = 'store/GET_POS_SMS_MAPPING_BY_STORE_ID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getMapping: [],
  getMap: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMapping: action.result,
      };
    }
    case GET_MAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // getMapping: action.result,
      };
    }
    case UPDATE_MAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STOREMAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STOREMAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // getMapping: action.result,
      };
    }
    case UPDATE_STOREMAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // getMapping: action.result,
      };
    }
    case UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // getMapping: action.result,
      };
    }
    case UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_POS_SMS_MAPPING_BY_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_POS_SMS_MAPPING_BY_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // getMapping: action.result,
      };
    }
    case UPDATE_POS_SMS_MAPPING_BY_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MAPPING_STORE_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getMap: null
      };
    }
    case GET_MAPPING_STORE_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMap: action.result,
      };
    }
    case GET_MAPPING_STORE_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getMap: null
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getMappingByStore = (storeId) => {
  return {
    types: [GET_MAPPING_BY_STORE_ID_REQUESTED, GET_MAPPING_BY_STORE_ID_SUCCESS, GET_MAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.get(`getMappingByStoreId/${storeId}`)
  };
};

export const getMapByStore = (storeId) => {
  return {
    types: [GET_MAPPING_STORE_ID_REQUESTED, GET_MAPPING_STORE_ID_SUCCESS, GET_MAPPING_STORE_ID_FAILURE],
    promise: client => client.get(`getMapByStoreId/${storeId}`)
  };
};

export const updateMappingByStore = (storeId, data) => {
  return {
    types: [UPDATE_MAPPING_BY_STORE_ID_REQUESTED, UPDATE_MAPPING_BY_STORE_ID_SUCCESS, UPDATE_MAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.post(`updateMappingByStoreId/${storeId}`, { data })
  };
};

export const updateStoreMappingByStore = (storeId, data) => {
  return {
    types: [UPDATE_STOREMAPPING_BY_STORE_ID_REQUESTED, UPDATE_STOREMAPPING_BY_STORE_ID_SUCCESS, UPDATE_STOREMAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.post(`updateMapByStoreId/${storeId}`, { data })
  };
};

export const updateTenderTypeMappingByStore = (storeId, data) => {
  return {
    types: [UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_REQUESTED, UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_SUCCESS, UPDATE_TENDER_TYPE_MAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.post(`updateTenderTypeByStoreId/${storeId}`, { data })
  };
};

export const updateOffPremiseMappingByStore = (storeId, data) => {
  return {
    types: [UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_REQUESTED, UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_SUCCESS, UPDATE_OFF_PREMISE_MAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.post(`updateOffPremiseByStoreId/${storeId}`, { data })
  };
};

export const updatePOSSmsMappingByStore = (storeId, data) => {
  return {
    types: [UPDATE_POS_SMS_MAPPING_BY_STORE_ID_REQUESTED, UPDATE_POS_SMS_MAPPING_BY_STORE_ID_SUCCESS, UPDATE_POS_SMS_MAPPING_BY_STORE_ID_FAILURE],
    promise: client => client.post(`updatePOSSmsByStoreId/${storeId}`, { data })
  };
};
