const currentReducer = 'mergeSetup';
export const ITEMS_MERGE_REQUESTED = `${currentReducer}/ITEMS_MERGE_REQUESTED`;
export const ITEMS_MERGE_SUCCESS = `${currentReducer}/ITEMS_MERGE_SUCCESS`;
export const ITEMS_MERGE_FAILURE = `${currentReducer}/ITEMS_MERGE_FAILURE`;

export const UPDATE_MERGE_REQUESTED = `${currentReducer}/UPDATE_MERGE_REQUESTED`;
export const UPDATE_MERGE_SUCCESS = `${currentReducer}/UPDATE_MERGE_SUCCESS`;
export const UPDATE_MERGE_FAILURE = `${currentReducer}/UPDATE_MERGE_FAILURE`;


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  itemList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_MERGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ITEMS_MERGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemList: action.result
      };
    }
    case ITEMS_MERGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MERGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_MERGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        itemList: []
      };
    }
    case UPDATE_MERGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getMergeItems = (menuId, menuType) => {
  let url = '';
  if (menuType === 'menu') {
    url = 'getMergeMenuItemsByMenuItemId';
  } else {
    url = 'getMergeModifiersByModifierId';
  }
  return {
    types: [ITEMS_MERGE_REQUESTED, ITEMS_MERGE_SUCCESS, ITEMS_MERGE_FAILURE],
    promise: client => client.get(`${url}/${menuId}`)
  };
};

export const updateMenuItems = (data, menuType) => {
  let url = '';
  if (menuType === 'menu') {
    url = 'updateMergeMenuItemsByMenuItemId';
  } else {
    url = 'updateMergeModifiersByModifierId';
  }
  return {
    types: [UPDATE_MERGE_REQUESTED, UPDATE_MERGE_SUCCESS, UPDATE_MERGE_FAILURE],
    promise: client => client.post(url, { data })
  };
};
