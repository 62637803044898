export const GET_STORE_HOURS_REQUESTED = 'storeSetup/GET_STORE_HOURS_REQUESTED';
export const GET_STORE_HOURS_SUCCESS = 'storeSetup/GET_STORE_HOURS_SUCCESS';
export const GET_STORE_HOURS_FAILURE = 'storeSetup/GET_STORE_HOURS_FAILURE';

export const UPDATE_STORE_HOURS_REQUESTED = 'storeSetup/UPDATE_STORE_HOURS_REQUESTED';
export const UPDATE_STORE_HOURS_SUCCESS = 'storeSetup/UPDATE_STORE_HOURS_SUCCESS';
export const UPDATE_STORE_HOURS_FAILURE = 'storeSetup/UPDATE_STORE_HOURS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  storeHours: [],
  storeHolidaysList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_HOURS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_HOURS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeHours: action.result,
      };
    }
    case GET_STORE_HOURS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        storeHours: [],
      };
    }
    case UPDATE_STORE_HOURS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_HOURS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_HOURS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStoreHours = (brandId) => {
  return {
    types: [GET_STORE_HOURS_REQUESTED, GET_STORE_HOURS_SUCCESS, GET_STORE_HOURS_FAILURE],
    promise: client => client.get(`getStoreHours/${brandId}`)
  };
};

export const updateStoreHours = (data) => {
  return {
    types: [UPDATE_STORE_HOURS_REQUESTED, UPDATE_STORE_HOURS_SUCCESS, UPDATE_STORE_HOURS_FAILURE],
    promise: client => client.post('updateStoreHours', { data })
  };
};
