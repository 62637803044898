export const GET_INTERCARD_REQUESTED = 'brand/GET_INTERCARD_REQUESTED';
export const GET_INTERCARD_SUCCESS = 'brand/GET_INTERCARD_SUCCESS';
export const GET_INTERCARD_FAILURE = 'brand/GET_INTERCARD_FAILURE';

export const UPDATE_INTERCARD_REQUESTED = 'brand/UPDATE_INTERCARD_REQUESTED';
export const UPDATE_INTERCARD_SUCCESS = 'brand/UPDATE_INTERCARD_SUCCESS';
export const UPDATE_INTERCARD_FAILURE = 'brand/UPDATE_INTERCARD_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  intercardList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_INTERCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        intercardList: null,
      };
    }
    case GET_INTERCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        intercardList: action.result,
      };
    }
    case GET_INTERCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_INTERCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_INTERCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_INTERCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getIntercard = (brandId) => {
  return {
    types: [GET_INTERCARD_REQUESTED, GET_INTERCARD_SUCCESS, GET_INTERCARD_FAILURE],
    promise: client => client.get(`getIntercardConfig/${brandId}`)
  };
};

export const updateIntercard = (brandId, data) => {
  return {
    types: [UPDATE_INTERCARD_REQUESTED, UPDATE_INTERCARD_SUCCESS, UPDATE_INTERCARD_FAILURE],
    promise: client => client.post(`updateIntercardConfig/${brandId}`, { data })
  };
};
