export const GET_PAYMENT_SYSTEM_REQUESTED = 'empApPaymentAdjustment/GET_PAYMENT_SYSTEM_REQUESTED';
export const GET_PAYMENT_SYSTEM_SUCCESS = 'empApPaymentAdjustment/GET_PAYMENT_SYSTEM_SUCCESS';
export const GET_PAYMENT_SYSTEM_FAILURE = 'empApPaymentAdjustment/GET_PAYMENT_SYSTEM_FAILURE';

export const UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED = 'empApPaymentAdjustment/UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED';
export const UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS = 'empApPaymentAdjustment/UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS';
export const UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE = 'empApPaymentAdjustment/UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE';

export const GET_TICKET_REASONS_REQUESTED = 'empApPaymentAdjustment/GET_TICKET_REASONS_REQUESTED';
export const GET_TICKET_REASONS_SUCCESS = 'empApPaymentAdjustment/GET_TICKET_REASONS_SUCCESS';
export const GET_TICKET_REASONS_FAILURE = 'empApPaymentAdjustment/GET_TICKET_REASONS_FAILURE';

export const GET_BUSINESS_DATE_REQUESTED = 'empApPaymentAdjustment/GET_BUSINESS_DATE_REQUESTED';
export const GET_BUSINESS_DATE_SUCCESS = 'empApPaymentAdjustment/GET_BUSINESS_DATE_SUCCESS';
export const GET_BUSINESS_DATE_FAILURE = 'empApPaymentAdjustment/GET_BUSINESS_DATE_FAILURE';

export const SAVE_PAYLOAD_DATA = 'empApPaymentAdjustment/SAVE_PAYLOAD_DATA';

export const PROCESS_REFUND_REQUESTED = 'tickets/PROCESS_REFUND_REQUESTED';
export const PROCESS_REFUND_SUCCESS = 'tickets/PROCESS_REFUND_SUCCESS';
export const PROCESS_REFUND_FAILURE = 'tickets/PROCESS_REFUND_FAILURE';

export const MANAGER_PROCESS_REFUND_REQUESTED = 'tickets/MANAGER_PROCESS_REFUND_REQUESTED';
export const MANAGER_PROCESS_REFUND_SUCCESS = 'tickets/MANAGER_PROCESS_REFUND_SUCCESS';
export const MANAGER_PROCESS_REFUND_FAILURE = 'tickets/MANAGER_PROCESS_REFUND_FAILURE';

export const MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED = 'empApPaymentAdjustment/MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED';
export const MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS = 'empApPaymentAdjustment/MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS';
export const MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE = 'empApPaymentAdjustment/MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  paymentSystemTypes: [],
  payloadData: null,
  paymentDetailsList: [],
  ticketReasonList: [],
  businessDate: null,
  managerPaymentDetailsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_SYSTEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        paymentSystemTypes: [],
      };
    }
    case GET_PAYMENT_SYSTEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paymentSystemTypes: action.result,
      };
    }
    case GET_PAYMENT_SYSTEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        payloadData: null,
      };
    }
    case UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        paymentDetailsList: action.result,
      };
    }
    case UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_PAYLOAD_DATA: {
      return {
        ...state,
        payloadData: action.payload,
      };
    }
    case GET_TICKET_REASONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TICKET_REASONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ticketReasonList: action.result,
      };
    }
    case GET_TICKET_REASONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BUSINESS_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BUSINESS_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        businessDate: action.result,
      };
    }
    case GET_BUSINESS_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case PROCESS_REFUND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case PROCESS_REFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBusinessDt: action.result,
      };
    }
    case PROCESS_REFUND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MANAGER_PROCESS_REFUND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case MANAGER_PROCESS_REFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBusinessDt: action.result,
      };
    }
    case MANAGER_PROCESS_REFUND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        payloadData: null,
      };
    }
    case MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        managerPaymentDetailsList: action.result,
      };
    }
    case MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPaymentSystemsTypes = () => {
  return {
    types: [GET_PAYMENT_SYSTEM_REQUESTED, GET_PAYMENT_SYSTEM_SUCCESS, GET_PAYMENT_SYSTEM_FAILURE],
    promise: client => client.get('getPaymentSystems')
  };
};

export const paymentAdjustmentReport = (data) => {
  return {
    types: [UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED, UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS, UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE],
    promise: client => client.post('paymentAdjustmentReport', { data })
  };
};

export const savePayloadData = (data) => {
  return {
    type: SAVE_PAYLOAD_DATA,
    payload: data
  };
};

export const getTicketReasons = () => {
  return {
    types: [GET_TICKET_REASONS_REQUESTED, GET_TICKET_REASONS_SUCCESS, GET_TICKET_REASONS_FAILURE],
    promise: client => client.get('getTicketReasons')
  };
};

export const getBusinessDate = (storeId) => {
  return {
    types: [GET_BUSINESS_DATE_REQUESTED, GET_BUSINESS_DATE_SUCCESS, GET_BUSINESS_DATE_FAILURE],
    promise: client => client.get(`getBusinessDate/${storeId}`)
  };
};

export const processRefund = (data) => {
  return {
    types: [PROCESS_REFUND_REQUESTED, PROCESS_REFUND_SUCCESS, PROCESS_REFUND_FAILURE],
    promise: client => client.post('processRefund', { data })
  };
};
export const managerProcessRefund = (data) => {
  return {
    types: [MANAGER_PROCESS_REFUND_REQUESTED, MANAGER_PROCESS_REFUND_SUCCESS, MANAGER_PROCESS_REFUND_FAILURE],
    promise: client => client.post('managerProcessRefund', { data })
  };
};
export const managerPaymentAdjustmentReport = (data) => {
  return {
    types: [MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_REQUESTED, MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_SUCCESS, MANAGER_UPDATE_PAYMENT_ADJUSTMENT_REPORT_FAILURE],
    promise: client => client.post('managerPaymentAdjustmentReport', { data })
  };
};
