export const GET_KDS_SETTINGS_REQUESTED = 'kdsSetup/GET_KDS_SETTINGS_REQUESTED';
export const GET_KDS_SETTINGS_SUCCESS = 'kdsSetup/GET_KDS_SETTINGS_SUCCESS';
export const GET_KDS_SETTINGS_FAILURE = 'kdsSetup/GET_KDS_SETTINGS_FAILURE';

export const UPDATE_KDS_SETTINGS_REQUESTED = 'kdsSetup/UPDATE_KDS_SETTINGS_REQUESTED';
export const UPDATE_KDS_SETTINGS_SUCCESS = 'kdsSetup/UPDATE_KDS_SETTINGS_SUCCESS';
export const UPDATE_KDS_SETTINGS_FAILURE = 'kdsSetup/UPDATE_KDS_SETTINGS_FAILURE';

// type lookup
export const GET_KDS_STATION_TYPES_REQUESTED = 'kdsSetup/GET_KDS_STATION_TYPES_REQUESTED';
export const GET_KDS_STATION_TYPES_SUCCESS = 'kdsSetup/GET_KDS_STATION_TYPES_SUCCESS';
export const GET_KDS_STATION_TYPES_FAILURE = 'kdsSetup/GET_KDS_STATION_TYPES_FAILURE';

// kds station list
export const GET_KDS_STATION_TYPE_REQUESTED = 'kdsSetup/GET_KDS_STATION_TYPE_REQUESTED';
export const GET_KDS_STATION_TYPE_SUCCESS = 'kdsSetup/GET_KDS_STATION_TYPE_SUCCESS';
export const GET_KDS_STATION_TYPE_FAILURE = 'kdsSetup/GET_KDS_STATION_TYPE_FAILURE';

// add kds
export const ADD_KDS_STATION_TYPE_REQUESTED = 'kdsSetup/ADD_KDS_STATION_TYPE_REQUESTED';
export const ADD_KDS_STATION_TYPE_SUCCESS = 'kdsSetup/ADD_KDS_STATION_TYPE_SUCCESS';
export const ADD_KDS_STATION_TYPE_FAILURE = 'kdsSetup/ADD_KDS_STATION_TYPE_FAILURE';

// update kds
export const UPDATE_KDS_STATION_TYPE_REQUESTED = 'kdsSetup/UPDATE_KDS_STATION_TYPE_REQUESTED';
export const UPDATE_KDS_STATION_TYPE_SUCCESS = 'kdsSetup/UPDATE_KDS_STATION_TYPE_SUCCESS';
export const UPDATE_KDS_STATION_TYPE_FAILURE = 'kdsSetup/UPDATE_KDS_STATION_TYPE_FAILURE';

// delete kds
export const DELETE_KDS_STATION_TYPE_REQUESTED = 'kdsSetup/DELETE_KDS_STATION_TYPE_REQUESTED';
export const DELETE_KDS_STATION_TYPE_SUCCESS = 'kdsSetup/DELETE_KDS_STATION_TYPE_SUCCESS';
export const DELETE_KDS_STATION_TYPE_FAILURE = 'kdsSetup/DELETE_KDS_STATION_TYPE_FAILURE';

// get kds by id
export const GET_KDS_STATION_TYPE_BY_ID_REQUESTED = 'kdsSetup/GET_KDS_STATION_TYPE_BY_ID_REQUESTED';
export const GET_KDS_STATION_TYPE_BY_ID_SUCCESS = 'kdsSetup/GET_KDS_STATION_TYPE_BY_ID_SUCCESS';
export const GET_KDS_STATION_TYPE_BY_ID_FAILURE = 'kdsSetup/GET_KDS_STATION_TYPE_BY_ID_FAILURE';

export const GET_KDS_LAYOUTS_BY_STORE_REQUESTED = 'kdsSetup/GET_KDS_LAYOUTS_BY_STORE_REQUESTED';
export const GET_KDS_LAYOUTS_BY_STORE_SUCCESS = 'kdsSetup/GET_KDS_LAYOUTS_BY_STORE_SUCCESS';
export const GET_KDS_LAYOUTS_BY_STORE_FAILURE = 'kdsSetup/GET_KDS_LAYOUTS_BY_STORE_FAILURE';

export const GET_KDS_PREP_STATIONS_REQUESTED = 'kdsSetup/GET_KDS_PREP_STATIONS_REQUESTED';
export const GET_KDS_PREP_STATIONS_SUCCESS = 'kdsSetup/GET_KDS_PREP_STATIONS_SUCCESS';
export const GET_KDS_PREP_STATIONS_FAILURE = 'kdsSetup/GET_KDS_PREP_STATIONS_FAILURE';

export const GET_KDS_BUILD_STATIONS_REQUESTED = 'kdsSetup/GET_KDS_BUILD_STATIONS_REQUESTED';
export const GET_KDS_BUILD_STATIONS_SUCCESS = 'kdsSetup/GET_KDS_BUILD_STATIONS_SUCCESS';
export const GET_KDS_BUILD_STATIONS_FAILURE = 'kdsSetup/GET_KDS_BUILD_STATIONS_FAILURE';

export const GET_KDS_EXPO_STATIONS_REQUESTED = 'kdsSetup/GET_KDS_EXPO_STATIONS_REQUESTED';
export const GET_KDS_EXPO_STATIONS_SUCCESS = 'kdsSetup/GET_KDS_EXPO_STATIONS_SUCCESS';
export const GET_KDS_EXPO_STATIONS_FAILURE = 'kdsSetup/GET_KDS_EXPO_STATIONS_FAILURE';

export const GET_KDS_LAYOUTS_LIST_REQUESTED = 'kdsSetup/GET_KDS_LAYOUTS_LIST_REQUESTED';
export const GET_KDS_LAYOUTS_LIST_SUCCESS = 'kdsSetup/GET_KDS_LAYOUTS_LIST_SUCCESS';
export const GET_KDS_LAYOUTS_LIST_FAILURE = 'kdsSetup/GET_KDS_LAYOUTS_LIST_FAILURE';

export const GET_KDS_LAYOUT_DATA_REQUESTED = 'kdsSetup/GET_KDS_LAYOUT_DATA_REQUESTED';
export const GET_KDS_LAYOUT_DATA_SUCCESS = 'kdsSetup/GET_KDS_LAYOUT_DATA_SUCCESS';
export const GET_KDS_LAYOUT_DATA_FAILURE = 'kdsSetup/GET_KDS_LAYOUT_DATA_FAILURE';

export const ADD_KDS_LAYOUT_DATA_REQUESTED = 'kdsSetup/ADD_KDS_LAYOUT_DATA_REQUESTED';
export const ADD_KDS_LAYOUT_DATA_SUCCESS = 'kdsSetup/ADD_KDS_LAYOUT_DATA_SUCCESS';
export const ADD_KDS_LAYOUT_DATA_FAILURE = 'kdsSetup/ADD_KDS_LAYOUT_DATA_FAILURE';

export const UPDATE_KDS_LAYOUT_DATA_REQUESTED = 'kdsSetup/UPDATE_KDS_LAYOUT_DATA_REQUESTED';
export const UPDATE_KDS_LAYOUT_DATA_SUCCESS = 'kdsSetup/UPDATE_KDS_LAYOUT_DATA_SUCCESS';
export const UPDATE_KDS_LAYOUT_DATA_FAILURE = 'kdsSetup/UPDATE_KDS_LAYOUT_DATA_FAILURE';

export const DELETE_KDS_LAYOUT_DATA_REQUESTED = 'kdsSetup/DELETE_KDS_LAYOUT_DATA_REQUESTED';
export const DELETE_KDS_LAYOUT_DATA_SUCCESS = 'kdsSetup/DELETE_KDS_LAYOUT_DATA_SUCCESS';
export const DELETE_KDS_LAYOUT_DATA_FAILURE = 'kdsSetup/DELETE_KDS_LAYOUT_DATA_FAILURE';

export const GET_KDS_PREP_DEVICES_REQUESTED = 'kdsSetup/GET_KDS_PREP_DEVICES_REQUESTED';
export const GET_KDS_PREP_DEVICES_SUCCESS = 'kdsSetup/GET_KDS_PREP_DEVICES_SUCCESS';
export const GET_KDS_PREP_DEVICES_FAILURE = 'kdsSetup/GET_KDS_PREP_DEVICES_FAILURE';

export const GET_KDS_BUILD_DEVICES_REQUESTED = 'kdsSetup/GET_KDS_BUILD_DEVICES_REQUESTED';
export const GET_KDS_BUILD_DEVICES_SUCCESS = 'kdsSetup/GET_KDS_BUILD_DEVICES_SUCCESS';
export const GET_KDS_BUILD_DEVICES_FAILURE = 'kdsSetup/GET_KDS_BUILD_DEVICES_FAILURE';

export const GET_KDS_EXPO_DEVICES_REQUESTED = 'kdsSetup/GET_KDS_EXPO_DEVICES_REQUESTED';
export const GET_KDS_EXPO_DEVICES_SUCCESS = 'kdsSetup/GET_KDS_EXPO_DEVICES_SUCCESS';
export const GET_KDS_EXPO_DEVICES_FAILURE = 'kdsSetup/GET_KDS_EXPO_DEVICES_FAILURE';

export const SET_DEFAULT_KDS_LAYOUT_REQUESTED = 'kdsSetup/SET_DEFAULT_KDS_LAYOUT_REQUESTED';
export const SET_DEFAULT_KDS_LAYOUT_SUCCESS = 'kdsSetup/SET_DEFAULT_KDS_LAYOUT_SUCCESS';
export const SET_DEFAULT_KDS_LAYOUT_FAILURE = 'kdsSetup/SET_DEFAULT_KDS_LAYOUT_FAILURE';

export const SET_KDS_BRAND_ID = 'kdsSetup/SET_KDS_BRAND_ID';

export const GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_REQUESTED = 'kdsSetup/GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_REQUESTED';
export const GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_SUCCESS = 'kdsSetup/GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_SUCCESS';
export const GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_FAILURE = 'kdsSetup/GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_FAILURE';

export const GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_REQUESTED = 'kdsSetup/GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_REQUESTED';
export const GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_SUCCESS = 'kdsSetup/GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_SUCCESS';
export const GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_FAILURE = 'kdsSetup/GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_FAILURE';

export const SAVE_KDS_RULES_REQUESTED = 'kdsSetup/SAVE_KDS_RULES_REQUESTED';
export const SAVE_KDS_RULES_SUCCESS = 'kdsSetup/SAVE_KDS_RULES_SUCCESS';
export const SAVE_KDS_RULES_FAILURE = 'kdsSetup/SAVE_KDS_RULES_FAILURE';

export const DELETE_KDS_RULES_REQUESTED = 'kdsSetup/DELETE_KDS_RULES_REQUESTED';
export const DELETE_KDS_RULES_SUCCESS = 'kdsSetup/DELETE_KDS_RULES_SUCCESS';
export const DELETE_KDS_RULES_FAILURE = 'kdsSetup/DELETE_KDS_RULES_FAILURE';

// KDS Print
export const GET_KDS_PRINT_LIST_REQUESTED = 'kdsSetup/GET_KDS_PRINT_LIST_REQUESTED';
export const GET_KDS_PRINT_LIST_SUCCESS = 'kdsSetup/GET_KDS_PRINT_LIST_SUCCESS';
export const GET_KDS_PRINT_LIST_FAILURE = 'kdsSetup/GET_KDS_PRINT_LIST_FAILURE';

export const GET_KDS_PRINT_BY_STORE_REQUESTED = 'kdsSetup/GET_KDS_PRINT_BY_STORE_REQUESTED';
export const GET_KDS_PRINT_BY_STORE_SUCCESS = 'kdsSetup/GET_KDS_PRINT_BY_STORE_SUCCESS';
export const GET_KDS_PRINT_BY_STORE_FAILURE = 'kdsSetup/GET_KDS_PRINT_BY_STORE_FAILURE';

export const GET_KDS_PRINT_DATA_REQUESTED = 'kdsSetup/GET_KDS_PRINT_DATA_REQUESTED';
export const GET_KDS_PRINT_DATA_SUCCESS = 'kdsSetup/GET_KDS_PRINT_DATA_SUCCESS';
export const GET_KDS_PRINT_DATA_FAILURE = 'kdsSetup/GET_KDS_PRINT_DATA_FAILURE';

export const ADD_KDS_PRINT_DATA_REQUESTED = 'kdsSetup/ADD_KDS_PRINT_DATA_REQUESTED';
export const ADD_KDS_PRINT_DATA_SUCCESS = 'kdsSetup/ADD_KDS_PRINT_DATA_SUCCESS';
export const ADD_KDS_PRINT_DATA_FAILURE = 'kdsSetup/ADD_KDS_PRINT_DATA_FAILURE';

export const UPDATE_KDS_PRINT_DATA_REQUESTED = 'kdsSetup/UPDATE_KDS_PRINT_DATA_REQUESTED';
export const UPDATE_KDS_PRINT_DATA_SUCCESS = 'kdsSetup/UPDATE_KDS_PRINT_DATA_SUCCESS';
export const UPDATE_KDS_PRINT_DATA_FAILURE = 'kdsSetup/UPDATE_KDS_PRINT_DATA_FAILURE';

export const DELETE_KDS_PRINT_DATA_REQUESTED = 'kdsSetup/DELETE_KDS_PRINT_DATA_REQUESTED';
export const DELETE_KDS_PRINT_DATA_SUCCESS = 'kdsSetup/DELETE_KDS_PRINT_DATA_SUCCESS';
export const DELETE_KDS_PRINT_DATA_FAILURE = 'kdsSetup/DELETE_KDS_PRINT_DATA_FAILURE';

export const SET_DEFAULT_KDS_PRINT_REQUESTED = 'kdsSetup/SET_DEFAULT_KDS_PRINT_REQUESTED';
export const SET_DEFAULT_KDS_PRINT_SUCCESS = 'kdsSetup/SET_DEFAULT_KDS_PRINT_SUCCESS';
export const SET_DEFAULT_KDS_PRINT_FAILURE = 'kdsSetup/SET_DEFAULT_KDS_PRINT_FAILURE';

export const SAVE_KDS_PRINT_RULES_REQUESTED = 'kdsSetup/SAVE_KDS_PRINT_RULES_REQUESTED';
export const SAVE_KDS_PRINT_RULES_SUCCESS = 'kdsSetup/SAVE_KDS_PRINT_RULES_SUCCESS';
export const SAVE_KDS_PRINT_RULES_FAILURE = 'kdsSetup/SAVE_KDS_PRINT_RULES_FAILURE';

export const DELETE_KDS_PRINT_RULES_REQUESTED = 'kdsSetup/DELETE_KDS_PRINT_RULES_REQUESTED';
export const DELETE_KDS_PRINT_RULES_SUCCESS = 'kdsSetup/DELETE_KDS_PRINT_RULES_SUCCESS';
export const DELETE_KDS_PRINT_RULES_FAILURE = 'kdsSetup/DELETE_KDS_PRINT_RULES_FAILURE';

export const SORT_KDS_LAYOUT_REQUESTED = 'kdsLayout/SORT_KDS_LAYOUT_REQUESTED';
export const SORT_KDS_LAYOUT_SUCCESS = 'kdsLayout/SORT_KDS_LAYOUT_SUCCESS';
export const SORT_KDS_LAYOUT_FAILURE = 'kdsLayout/SORT_KDS_LAYOUT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  settingsData: null,
  kdsStationTypesList: [],
  stationTypeList: [],
  stationTypeDataById: [],
  kdsLayoutsByStoreId: [],
  kdsPrepStationsList: [],
  kdsBuildStationsList: [],
  kdsExpoStationsList: [],
  kdsLayoutsData: [],
  kdsLayoutData: {},
  KDSBrandId: null,
  KDSPrepDevicesList: [],
  KDSBuildDevicesList: [],
  KDSExpoDevicesList: [],
  orderTypeMasterList: [],
  revenueCenterMasterList: [],
  kdsPrintsByStoreId: [],
  kdsPrintList: [],
  kdsPrintData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_KDS_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_KDS_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        settingsData: action.result
      };
    }
    case GET_KDS_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_KDS_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_KDS_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        settingsData: {}
      };
    }
    case UPDATE_KDS_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_KDS_STATION_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_STATION_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationTypeList: action.result
      };
    }
    case GET_KDS_STATION_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_KDS_STATION_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_KDS_STATION_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationTypeList: [],
      };
    }
    case ADD_KDS_STATION_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_KDS_STATION_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_KDS_STATION_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationTypeList: [],
      };
    }
    case UPDATE_KDS_STATION_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_KDS_STATION_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_KDS_STATION_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_KDS_STATION_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_KDS_STATION_TYPE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_KDS_STATION_TYPE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        stationTypeDataById: action.result
      };
    }
    case GET_KDS_STATION_TYPE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_KDS_STATION_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_KDS_STATION_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsStationTypesList: action.result,
      };
    }
    case GET_KDS_STATION_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_LAYOUTS_BY_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_LAYOUTS_BY_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsLayoutsByStoreId: action.result,
      };
    }
    case GET_KDS_LAYOUTS_BY_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_PREP_STATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_PREP_STATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsPrepStationsList: action.result,
      };
    }
    case GET_KDS_PREP_STATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_BUILD_STATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_BUILD_STATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsBuildStationsList: action.result,
      };
    }
    case GET_KDS_BUILD_STATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_EXPO_STATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_EXPO_STATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsExpoStationsList: action.result,
      };
    }
    case GET_KDS_EXPO_STATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_LAYOUTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_LAYOUTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsLayoutsData: action.result,
      };
    }
    case GET_KDS_LAYOUTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsLayoutData: action.result,
      };
    }
    case GET_KDS_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_KDS_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_KDS_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_KDS_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_KDS_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_KDS_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_KDS_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_KDS_LAYOUT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case DELETE_KDS_LAYOUT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_KDS_LAYOUT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_PREP_DEVICES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_PREP_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        KDSPrepDevicesList: action.result,
      };
    }
    case GET_KDS_PREP_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_BUILD_DEVICES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_BUILD_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        KDSBuildDevicesList: action.result,
      };
    }
    case GET_KDS_BUILD_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_EXPO_DEVICES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_EXPO_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        KDSExpoDevicesList: action.result,
      };
    }
    case GET_KDS_EXPO_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SET_KDS_BRAND_ID: {
      return {
        ...state,
        KDSBrandId: action.payload,
      };
    }
    case SET_DEFAULT_KDS_LAYOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SET_DEFAULT_KDS_LAYOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SET_DEFAULT_KDS_LAYOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        orderTypeMasterList: action.result,
      };
    }
    case GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        revenueCenterMasterList: action.result,
      };
    }
    case GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_KDS_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_KDS_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_KDS_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_KDS_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_KDS_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_KDS_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_KDS_PRINT_BY_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_PRINT_BY_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsPrintsByStoreId: action.result,
      };
    }
    case GET_KDS_PRINT_BY_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_PRINT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_PRINT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsPrintList: action.result,
      };
    }
    case GET_KDS_PRINT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_PRINT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_KDS_PRINT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kdsPrintData: action.result,
      };
    }
    case GET_KDS_PRINT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_KDS_PRINT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_KDS_PRINT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_KDS_PRINT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_KDS_PRINT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_KDS_PRINT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_KDS_PRINT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_KDS_PRINT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case DELETE_KDS_PRINT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_KDS_PRINT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SET_DEFAULT_KDS_PRINT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SET_DEFAULT_KDS_PRINT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SET_DEFAULT_KDS_PRINT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_KDS_PRINT_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_KDS_PRINT_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_KDS_PRINT_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_KDS_PRINT_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_KDS_PRINT_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_KDS_PRINT_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SORT_KDS_LAYOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SORT_KDS_LAYOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SORT_KDS_LAYOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getKDSSettingsData = (brandId) => {
  return {
    types: [GET_KDS_SETTINGS_REQUESTED, GET_KDS_SETTINGS_SUCCESS, GET_KDS_SETTINGS_FAILURE],
    promise: client => client.get(`getKDSSettings/${brandId}`)
  };
};

export const updateKDSSettingsData = (data) => {
  return {
    types: [UPDATE_KDS_SETTINGS_REQUESTED, UPDATE_KDS_SETTINGS_SUCCESS, UPDATE_KDS_SETTINGS_FAILURE],
    promise: client => client.post('updateKDSSettings', { data })
  };
};

export const getKDSStationTypes = () => {
  return {
    types: [GET_KDS_STATION_TYPES_REQUESTED, GET_KDS_STATION_TYPES_SUCCESS, GET_KDS_STATION_TYPES_FAILURE],
    promise: client => client.get('getKDSStationTypes')
  };
};

export const getKDSStationTypeData = (Id) => {
  return {
    types: [GET_KDS_STATION_TYPE_REQUESTED, GET_KDS_STATION_TYPE_SUCCESS, GET_KDS_STATION_TYPE_FAILURE],
    promise: client => client.get(`getKDSStationTypeData/${Id}`)
  };
};

export const addKDSStationTypeData = (data) => {
  return {
    types: [ADD_KDS_STATION_TYPE_REQUESTED, ADD_KDS_STATION_TYPE_SUCCESS, ADD_KDS_STATION_TYPE_FAILURE],
    promise: client => client.post('addKDSStationTypeData', { data })
  };
};

export const updateKDSStationTypeData = (data) => {
  return {
    types: [UPDATE_KDS_STATION_TYPE_REQUESTED, UPDATE_KDS_STATION_TYPE_SUCCESS, UPDATE_KDS_STATION_TYPE_FAILURE],
    promise: client => client.post('updateKDSStationTypeData', { data })
  };
};

export const deleteKDSStationTypeData = (Id) => {
  return {
    types: [DELETE_KDS_STATION_TYPE_REQUESTED, DELETE_KDS_STATION_TYPE_SUCCESS, DELETE_KDS_STATION_TYPE_FAILURE],
    promise: client => client.post(`deleteKDSStationTypeData/${Id}`)
  };
};

export const getKDSStationTypeDataById = (Id) => {
  return {
    types: [GET_KDS_STATION_TYPE_BY_ID_REQUESTED, GET_KDS_STATION_TYPE_BY_ID_SUCCESS, GET_KDS_STATION_TYPE_BY_ID_FAILURE],
    promise: client => client.get(`getKDSStationTypeDataById/${Id}`)
  };
};

export const getKDSLayoutsByStoreId = (storeId) => {
  return {
    types: [GET_KDS_LAYOUTS_BY_STORE_REQUESTED, GET_KDS_LAYOUTS_BY_STORE_SUCCESS, GET_KDS_LAYOUTS_BY_STORE_FAILURE],
    promise: client => client.get(`getKDSLayoutsByStoreId/${storeId}`)
  };
};

export const getKDSPrepStations = (brandId) => {
  return {
    types: [GET_KDS_PREP_STATIONS_REQUESTED, GET_KDS_PREP_STATIONS_SUCCESS, GET_KDS_PREP_STATIONS_FAILURE],
    promise: client => client.get(`getKDSPrepStations/${brandId}`)
  };
};

export const getKDSBuildStations = (brandId) => {
  return {
    types: [GET_KDS_BUILD_STATIONS_REQUESTED, GET_KDS_BUILD_STATIONS_SUCCESS, GET_KDS_BUILD_STATIONS_FAILURE],
    promise: client => client.get(`getKDSBuildStations/${brandId}`)
  };
};

export const getKDSExpoStations = (brandId) => {
  return {
    types: [GET_KDS_EXPO_STATIONS_REQUESTED, GET_KDS_EXPO_STATIONS_SUCCESS, GET_KDS_EXPO_STATIONS_FAILURE],
    promise: client => client.get(`getKDSExpoStations/${brandId}`)
  };
};

export const getKDSLayoutsList = (brandId) => {
  return {
    types: [GET_KDS_LAYOUTS_LIST_REQUESTED, GET_KDS_LAYOUTS_LIST_SUCCESS, GET_KDS_LAYOUTS_LIST_FAILURE],
    promise: client => client.get(`getKDSLayoutsData/${brandId}`)
  };
};

export const getKDSLayoutData = (brandKdsLayoutId) => {
  return {
    types: [GET_KDS_LAYOUT_DATA_REQUESTED, GET_KDS_LAYOUT_DATA_SUCCESS, GET_KDS_LAYOUT_DATA_FAILURE],
    promise: client => client.get(`getKDSLayoutById/${brandKdsLayoutId}`)
  };
};

export const addKDSLayoutData = (data) => {
  return {
    types: [ADD_KDS_LAYOUT_DATA_REQUESTED, ADD_KDS_LAYOUT_DATA_SUCCESS, ADD_KDS_LAYOUT_DATA_FAILURE],
    promise: client => client.post('addKDSLayout', { data })
  };
};

export const updateKDSLayoutData = (data) => {
  return {
    types: [UPDATE_KDS_LAYOUT_DATA_REQUESTED, UPDATE_KDS_LAYOUT_DATA_SUCCESS, UPDATE_KDS_LAYOUT_DATA_FAILURE],
    promise: client => client.post('updateKDSLayout', { data })
  };
};

export const deleteKDSLayoutData = (brandKdsLayoutId) => {
  return {
    types: [DELETE_KDS_LAYOUT_DATA_REQUESTED, DELETE_KDS_LAYOUT_DATA_SUCCESS, DELETE_KDS_LAYOUT_DATA_FAILURE],
    promise: client => client.post(`deleteKDSLayout/${brandKdsLayoutId}`)
  };
};

export const getKDSPrepDevices = (brandId) => {
  return {
    types: [GET_KDS_PREP_DEVICES_REQUESTED, GET_KDS_PREP_DEVICES_SUCCESS, GET_KDS_PREP_DEVICES_FAILURE],
    promise: client => client.get(`getKDSPrepDevices/${brandId}`)
  };
};

export const getKDSBuildDevices = (brandId) => {
  return {
    types: [GET_KDS_BUILD_DEVICES_REQUESTED, GET_KDS_BUILD_DEVICES_SUCCESS, GET_KDS_BUILD_DEVICES_FAILURE],
    promise: client => client.get(`getKDSBuildDevices/${brandId}`)
  };
};

export const getKDSExpoDevices = (brandId) => {
  return {
    types: [GET_KDS_EXPO_DEVICES_REQUESTED, GET_KDS_EXPO_DEVICES_SUCCESS, GET_KDS_EXPO_DEVICES_FAILURE],
    promise: client => client.get(`getKDSExpoDevices/${brandId}`)
  };
};

// state to avoid calling lookup APIs more than once
export const setKDSBrandId = (value) => {
  return {
    type: SET_KDS_BRAND_ID,
    payload: value,
  };
};


export const getKDSLayoutDefaultSetup = (brandKdsLayoutId) => {
  return {
    types: [SET_DEFAULT_KDS_LAYOUT_REQUESTED, SET_DEFAULT_KDS_LAYOUT_SUCCESS, SET_DEFAULT_KDS_LAYOUT_FAILURE],
    promise: client => client.post(`getKDSDefaultSetup/${brandKdsLayoutId}`)
  };
};

export const getOrderTypeMasterByBrandId = (brandId) => {
  return {
    types: [GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_REQUESTED, GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_SUCCESS, GET_ORDER_TYPES_MASTERS_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getOrderTypeMasterByBrandId/${brandId}`)
  };
};

export const getRevenueCenterMasterByBrandId = (brandId) => {
  return {
    types: [GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_REQUESTED, GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_SUCCESS, GET_REVENUE_CENTER_MASTERS_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getRevenueCenterMasterByBrandId/${brandId}`)
  };
};

export const saveKDSRule = (data) => {
  return {
    types: [SAVE_KDS_RULES_REQUESTED, SAVE_KDS_RULES_SUCCESS, SAVE_KDS_RULES_FAILURE],
    promise: client => client.post('saveKDSRule', { data })
  };
};

export const deleteKDSRuleId = (brandRiskRuleId) => {
  return {
    types: [DELETE_KDS_RULES_REQUESTED, DELETE_KDS_RULES_SUCCESS, DELETE_KDS_RULES_FAILURE],
    promise: client => client.post(`deleteKDSRule/${brandRiskRuleId}`)
  };
};

export const getKDSPrintByStoreId = (storeId) => {
  return {
    types: [GET_KDS_PRINT_BY_STORE_REQUESTED, GET_KDS_PRINT_BY_STORE_SUCCESS, GET_KDS_PRINT_BY_STORE_FAILURE],
    promise: client => client.get(`getKDSPrintByStoreId/${storeId}`)
  };
};

export const getKDSPrintByBrandId = (brandId) => {
  return {
    types: [GET_KDS_PRINT_LIST_REQUESTED, GET_KDS_PRINT_LIST_SUCCESS, GET_KDS_PRINT_LIST_FAILURE],
    promise: client => client.get(`getKDSPrintByBrandId/${brandId}`)
  };
};

export const getKDSPrintDataById = (brandKdsPrintId) => {
  return {
    types: [GET_KDS_PRINT_DATA_REQUESTED, GET_KDS_PRINT_DATA_SUCCESS, GET_KDS_PRINT_DATA_FAILURE],
    promise: client => client.get(`getKDSPrintDataById/${brandKdsPrintId}`)
  };
};

export const addKDSPrint = (data) => {
  return {
    types: [ADD_KDS_PRINT_DATA_REQUESTED, ADD_KDS_PRINT_DATA_SUCCESS, ADD_KDS_PRINT_DATA_FAILURE],
    promise: client => client.post('addKDSPrint', { data })
  };
};

export const updateKDSPrint = (data) => {
  return {
    types: [UPDATE_KDS_PRINT_DATA_REQUESTED, UPDATE_KDS_PRINT_DATA_SUCCESS, UPDATE_KDS_PRINT_DATA_FAILURE],
    promise: client => client.post('updateKDSPrint', { data })
  };
};

export const deleteKDSPrint = (brandKdsPrintId) => {
  return {
    types: [DELETE_KDS_PRINT_DATA_REQUESTED, DELETE_KDS_PRINT_DATA_SUCCESS, DELETE_KDS_PRINT_DATA_FAILURE],
    promise: client => client.post(`deleteKDSPrint/${brandKdsPrintId}`)
  };
};

export const getKDSPrintDefaultSetup = (brandKdsPrintId) => {
  return {
    types: [SET_DEFAULT_KDS_PRINT_REQUESTED, SET_DEFAULT_KDS_PRINT_SUCCESS, SET_DEFAULT_KDS_PRINT_FAILURE],
    promise: client => client.post(`getKDSPrintDefaultSetup/${brandKdsPrintId}`)
  };
};

export const saveKDSPrintRule = (data) => {
  return {
    types: [SAVE_KDS_PRINT_RULES_REQUESTED, SAVE_KDS_PRINT_RULES_SUCCESS, SAVE_KDS_PRINT_RULES_FAILURE],
    promise: client => client.post('saveKDSPrintRule', { data })
  };
};

export const deleteKDSPrintRuleId = (brandKdsPrintRuleId) => {
  return {
    types: [DELETE_KDS_PRINT_RULES_REQUESTED, DELETE_KDS_PRINT_RULES_SUCCESS, DELETE_KDS_PRINT_RULES_FAILURE],
    promise: client => client.post(`deleteKDSPrintRule/${brandKdsPrintRuleId}`)
  };
};

export const sortKDSLayout = (data) => {
  return {
    types: [SORT_KDS_LAYOUT_REQUESTED, SORT_KDS_LAYOUT_SUCCESS, SORT_KDS_LAYOUT_FAILURE],
    promise: client => client.post('sortKDSLayout', { data })
  };
};
