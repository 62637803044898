export default
[
  // Company Level
  {
    link: '/company/update',
    backNavLink: '',
  },
  {
    link: '/company/add',
    backNavLink: '',
  },
  // Brand Level
  {
    link: '/brand/update',
    backNavLink: '',
  },
  {
    link: '/brand/add',
    backNavLink: '',
  },
  {
    link: '/brand/giftcard',
    backNavLink: '',
  },
  {
    link: '/brand/marketPlace',
    backNavLink: '',
  },
  {
    link: '/brand/posSystemConfig',
    backNavLink: '',
  },
  {
    link: '/brand/posagent/download',
    backNavLink: '',
  },
  {
    link: '/brand/tileSettings',
    backNavLink: '',
  },
  {
    link: '/brand/receiptTemplates/list',
    backNavLink: '',
  },
  {
    link: '/brand/orderLabelTemplate/list',
    backNavLink: '',
  },
  {
    link: '/brand/manageStore/menuItemByStore',
    backNavLink: '',
  },
  {
    link: '/brand/manageStore/menuItemByCategory',
    backNavLink: '',
  },
  {
    link: '/brand/manageStore/modifierByStore',
    backNavLink: '',
  },
  {
    link: '/brand/manageStore/modifierAcrossStore',
    backNavLink: '',
  },
  {
    link: '/brand/menus/happyHour',
    backNavLink: '',
  },
  {
    link: '/brand/kds/settings',
    backNavLink: '',
  },
  {
    link: '/brand/multiLingual',
    backNavLink: '',
  },
  {
    link: 'brand/config-channel',
    backNavLink: '',
  },
  {
    link: 'brand/external/campaigns',
    backNavLink: '',
  },
  {
    link: 'brand/omsoffers/list',
    backNavLink: '',
  },
  {
    link: 'brand/clutchLoyalty',
    backNavLink: '',
  },
  {
    link: 'brand/lookLoyalty',
    backNavLink: '',
  },
  {
    link: 'brand/paytronixLoyalty',
    backNavLink: '',
  },
  {
    link: 'brand/punchh',
    backNavLink: '',
  },
  {
    link: 'sparkfly/setup',
    backNavLink: '',
  },
  {
    link: 'brand/spendgo',
    backNavLink: '',
  },
  {
    link: 'brand/fishbowl',
    backNavLink: '',
  },
  {
    link: 'brand/fiserv',
    backNavLink: '',
  },
  {
    link: 'brand/givex',
    backNavLink: '',
  },
  {
    link: 'brand/heartland',
    backNavLink: '',
  },
  {
    link: 'brand/paytronixGiftCard',
    backNavLink: '',
  },
  {
    link: 'brand/svs',
    backNavLink: '',
  },
  {
    link: 'brand/valueTec',
    backNavLink: '',
  },
  {
    link: 'brand/medallia',
    backNavLink: '',
  },
  {
    link: 'brand/siftFraudSystem',
    backNavLink: '',
  },
  {
    link: 'brand/userWay',
    backNavLink: '',
  },
  {
    link: 'brand/deliverySetup',
    backNavLink: '',
  },
  {
    link: 'brand/chowly',
    backNavLink: '',
  },
  {
    link: 'brand/otter',
    backNavLink: '',
  },
  {
    link: 'brand/ezCater',
    backNavLink: '',
  },
  {
    link: 'brand/rokt',
    backNavLink: '',
  },
  {
    link: 'brand/tsdGlobal',
    backNavLink: '',
  },
  {
    link: 'brand/intercard',
    backNavLink: '',
  },
  {
    link: 'brand/powercard',
    backNavLink: '',
  },
  {
    link: 'brand/spendgoWebhooks',
    backNavLink: '',
  },
  {
    link: 'brand/wiselyWebhooks',
    backNavLink: '',
  },
  {
    link: 'brand/dnbLoyalty',
    backNavLink: '',
  },
  // Store Level
  {
    link: '/store/add',
    backNavLink: '',
  },
  {
    link: 'store/update',
    backNavLink: '',
  },
  {
    link: 'store/otherSettings',
    backNavLink: '',
  },
  {
    link: 'device/register',
    backNavLink: 'device/list',
  },
  {
    link: 'store/mobilePaymentSetup',
    backNavLink: '',
  },
  {
    link: 'store/cardconnectbolt',
    backNavLink: '',
  },
  {
    link: 'store/nmiPayments',
    backNavLink: '',
  },
  {
    link: 'store/ingenico',
    backNavLink: '',
  },
  {
    link: 'store/paymentSense',
    backNavLink: '',
  },
];
