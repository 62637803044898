export const GET_WISELY_WEBHOOKS_CONFIG_REQUESTED = 'brand/GET_WISELY_WEBHOOKS_CONFIG_REQUESTED';
export const GET_WISELY_WEBHOOKS_CONFIG_SUCCESS = 'brand/GET_WISELY_WEBHOOKS_CONFIG_SUCCESS';
export const GET_WISELY_WEBHOOKS_CONFIG_FAILURE = 'brand/GET_WISELY_WEBHOOKS_CONFIG_FAILURE';

export const UPDATE_WISELY_WEBHOOKS_CONFIG_REQUESTED = 'brand/UPDATE_WISELY_WEBHOOKS_CONFIG_REQUESTED';
export const UPDATE_WISELY_WEBHOOKS_CONFIG_SUCCESS = 'brand/UPDATE_WISELY_WEBHOOKS_CONFIG_SUCCESS';
export const UPDATE_WISELY_WEBHOOKS_CONFIG_FAILURE = 'brand/UPDATE_WISELY_WEBHOOKS_CONFIG_FAILURE';

export const GET_SPENDGO_WEBHOOKS_CONFIG_REQUESTED = 'brand/GET_SPENDGO_WEBHOOKS_CONFIG_REQUESTED';
export const GET_SPENDGO_WEBHOOKS_CONFIG_SUCCESS = 'brand/GET_SPENDGO_WEBHOOKS_CONFIG_SUCCESS';
export const GET_SPENDGO_WEBHOOKS_CONFIG_FAILURE = 'brand/GET_SPENDGO_WEBHOOKS_CONFIG_FAILURE';

export const UPDATE_SPENDGO_WEBHOOKS_CONFIG_REQUESTED = 'brand/UPDATE_SPENDGO_WEBHOOKS_CONFIG_REQUESTED';
export const UPDATE_SPENDGO_WEBHOOKS_CONFIG_SUCCESS = 'brand/UPDATE_SPENDGO_WEBHOOKS_CONFIG_SUCCESS';
export const UPDATE_SPENDGO_WEBHOOKS_CONFIG_FAILURE = 'brand/UPDATE_SPENDGO_WEBHOOKS_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  wiselyWebhooksList: [],
  spendgoWebhooksList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_WISELY_WEBHOOKS_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        wiselyWebhooksList: [],
      };
    }
    case GET_WISELY_WEBHOOKS_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        wiselyWebhooksList: action.result,
      };
    }
    case GET_WISELY_WEBHOOKS_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_WISELY_WEBHOOKS_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_WISELY_WEBHOOKS_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_WISELY_WEBHOOKS_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SPENDGO_WEBHOOKS_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        spendgoWebhooksList: [],
      };
    }
    case GET_SPENDGO_WEBHOOKS_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        spendgoWebhooksList: action.result,
      };
    }
    case GET_SPENDGO_WEBHOOKS_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SPENDGO_WEBHOOKS_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SPENDGO_WEBHOOKS_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SPENDGO_WEBHOOKS_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getWiselyWebhooksListConfig = (brandId) => {
  return {
    types: [GET_WISELY_WEBHOOKS_CONFIG_REQUESTED, GET_WISELY_WEBHOOKS_CONFIG_SUCCESS, GET_WISELY_WEBHOOKS_CONFIG_FAILURE],
    promise: client => client.get(`getWiselyWebhooksListConfig/${brandId}`)
  };
};

export const updateWiselyWebhooksListConfig = (brandId, data) => {
  return {
    types: [UPDATE_WISELY_WEBHOOKS_CONFIG_REQUESTED, UPDATE_WISELY_WEBHOOKS_CONFIG_SUCCESS, UPDATE_WISELY_WEBHOOKS_CONFIG_FAILURE],
    promise: client => client.post(`updateWiselyWebhooksListConfig/${brandId}`, { data })
  };
};


export const getspendgoWebhooksListConfig = (brandId) => {
  return {
    types: [GET_SPENDGO_WEBHOOKS_CONFIG_REQUESTED, GET_SPENDGO_WEBHOOKS_CONFIG_SUCCESS, GET_SPENDGO_WEBHOOKS_CONFIG_FAILURE],
    promise: client => client.get(`getspendgoWebhooksListConfig/${brandId}`)
  };
};

export const updatespendgoWebhooksListConfig = (brandId, data) => {
  return {
    types: [UPDATE_SPENDGO_WEBHOOKS_CONFIG_REQUESTED, UPDATE_SPENDGO_WEBHOOKS_CONFIG_SUCCESS, UPDATE_SPENDGO_WEBHOOKS_CONFIG_FAILURE],
    promise: client => client.post(`updatespendgoWebhooksListConfig/${brandId}`, { data })
  };
};
