export const GET_RESTAURANTMAGIC_SETUP_REQUESTED = 'brand/GET_RESTAURANTMAGIC_SETUP_REQUESTED';
export const GET_RESTAURANTMAGIC_SETUP_SUCCESS = 'brand/GET_RESTAURANTMAGIC_SETUP_SUCCESS';
export const GET_RESTAURANTMAGIC_SETUP_FAILURE = 'brand/GET_RESTAURANTMAGIC_SETUP_FAILURE';

export const UPDATE_RESTAURANTMAGIC_SETUP_REQUESTED = 'brand/UPDATE_RESTAURANTMAGIC_SETUP_REQUESTED';
export const UPDATE_RESTAURANTMAGIC_SETUP_SUCCESS = 'brand/UPDATE_RESTAURANTMAGIC_SETUP_SUCCESS';
export const UPDATE_RESTAURANTMAGIC_SETUP_FAILURE = 'brand/UPDATE_RESTAURANTMAGIC_SETUP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  restaurantMagicList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANTMAGIC_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        restaurantMagicList: [],
      };
    }
    case GET_RESTAURANTMAGIC_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        restaurantMagicList: action.result,
      };
    }
    case GET_RESTAURANTMAGIC_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_RESTAURANTMAGIC_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_RESTAURANTMAGIC_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_RESTAURANTMAGIC_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getRestaurantMagicConfig = (brandId) => {
  return {
    types: [GET_RESTAURANTMAGIC_SETUP_REQUESTED, GET_RESTAURANTMAGIC_SETUP_SUCCESS, GET_RESTAURANTMAGIC_SETUP_FAILURE],
    promise: client => client.get(`getRestaurantMagicConfig/${brandId}`)
  };
};

export const updateRestaurantMagicConfig = (brandId, data) => {
  return {
    types: [UPDATE_RESTAURANTMAGIC_SETUP_REQUESTED, UPDATE_RESTAURANTMAGIC_SETUP_SUCCESS, UPDATE_RESTAURANTMAGIC_SETUP_FAILURE],
    promise: client => client.post(`updateRestaurantMagicConfig/${brandId}`, { data })
  };
};
