const currentReducer = 'v3Header';

export const SET_HEADER_STYLE = `${currentReducer}/SET_HEADER_STYLE`;
export const TOGGLE_MAIN_MENU = `${currentReducer}/TOGGLE_MAIN_MENU`;
export const CLOSE_MAIN_MENU = `${currentReducer}/CLOSE_MAIN_MENU`;


const initialState = {
  headerStyle: window.scrollY > 100 ? 'compact' : 'full',
  mainMenuOpen: false,
  profileMenuOpen: false,
  router: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_STYLE: {
      return {
        ...state,
        headerStyle: window.scrollY > 100 ? 'compact' : 'full'
      };
    }
    case TOGGLE_MAIN_MENU: {
      return {
        ...state,
        mainMenuOpen: !state.mainMenuOpen
      };
    }
    case CLOSE_MAIN_MENU: {
      return {
        ...state,
        mainMenuOpen: false
      };
    }
    default:
      return state;
  }
};

export const setHeaderStyle = () => {
  return {
    type: SET_HEADER_STYLE
  };
};

export const toggleMainMenu = () => {
  return {
    type: TOGGLE_MAIN_MENU
  };
};

export const closeMainMenu = () => {
  return {
    type: CLOSE_MAIN_MENU
  };
};
