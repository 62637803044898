export default {
  WaitList: {
    screenLabel: 'Wait List',
    customLabels: [
      {
        defaultText: 'Notes',
        key: 'label.WAITLIST_NOTES',
        type: 'label',
        value: 'WAITLIST_NOTES',
      },
      {
        defaultText: 'Staff Comments',
        key: 'label.WAITLIST_STAFF_COMMENTS',
        type: 'label',
        value: 'WAITLIST_STAFF_COMMENTS',
      },
      {
        defaultText: 'Add Guest',
        key: 'label.WAITLIST_ADD_GUEST',
        type: 'label',
        value: 'WAITLIST_ADD_GUEST',
      },
      {
        defaultText: 'Waitlist',
        key: 'label.WAITLIST_WAITLIST',
        type: 'label',
        value: 'WAITLIST_WAITLIST',
      },
      {
        defaultText: 'Seated',
        key: 'label.WAITLIST_SEATED',
        type: 'label',
        value: 'WAITLIST_SEATED',
      },
      {
        defaultText: 'Cancelled',
        key: 'label.WAITLIST_CANCELLED',
        type: 'label',
        value: 'WAITLIST_CANCELLED',
      }
    ],
  },
  createWaitList: {
    screenLabel: 'Create Waitlist',
    customLabels: [
      {
        defaultText: 'Create a waitlist',
        key: 'label.CREATE_WAITLIST_CREATING_WAITLIST',
        type: 'label',
        value: 'CREATE_WAITLIST_CREATING_WAITLIST',
      },
      {
        defaultText: 'Name',
        key: 'label.CREATE_WAITLIST_NAME',
        type: 'label',
        value: 'CREATE_WAITLIST_NAME',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.CREATE_WAITLIST_PHONE',
        type: 'label',
        value: 'CREATE_WAITLIST_PHONE',
      },
      {
        defaultText: 'Party Size',
        key: 'label.CREATE_WAITLIST_PARTY_SIZE',
        type: 'label',
        value: 'CREATE_WAITLIST_PARTY_SIZE',
      },
      {
        defaultText: 'Email-id',
        key: 'label.CREATE_WAITLIST_EMAIL',
        type: 'label',
        value: 'CREATE_WAITLIST_EMAIL',
      },
      {
        defaultText: 'Note',
        key: 'label.CREATE_WAITLIST_NOTE',
        type: 'label',
        value: 'CREATE_WAITLIST_NOTE',
      },
      {
        defaultText: 'Staff Comment',
        key: 'label.CREATE_WAITLIST_STAFF_COMMENT',
        type: 'label',
        value: 'CREATE_WAITLIST_STAFF_COMMENT',
      },
      {
        defaultText: 'Add to waitlist',
        key: 'label.CREATE_WAITLIST_ADD_TO_WAITLIST',
        type: 'label',
        value: 'CREATE_WAITLIST_ADD_TO_WAITLIST',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CREATE_WAITLIST_CANCEL',
        type: 'label',
        value: 'CREATE_WAITLIST_CANCEL',
      },
      {
        defaultText: 'Optional',
        key: 'label.CREATE_WAITLIST_OPTIONAL',
        type: 'label',
        value: 'CREATE_WAITLIST_OPTIONAL',
      },
      {
        defaultText: 'Update waitlist',
        key: 'label.CREATE_WAITLIST_UPDATE_WAITLIST',
        type: 'label',
        value: 'CREATE_WAITLIST_UPDATE_WAITLIST',
      }
    ]
  },
  tmsMenu: {
    screenLabel: 'Tms Menu',
    customLabels: [
      {
        defaultText: 'Layout Design',
        key: 'label.TMS_MENU_LAYOUT_DESIGN',
        type: 'label',
        value: 'TMS_MENU_LAYOUT_DESIGN',
      },
      {
        defaultText: 'Sensors',
        key: 'label.TMS_MENU_SENSORS',
        type: 'label',
        value: 'TMS_MENU_SENSORS',
      },
      {
        defaultText: 'Sync Checks',
        key: 'label.TMS_MENU_SYNC_CHECKS',
        type: 'label',
        value: 'TMS_MENU_SYNC_CHECKS',
      },
      {
        defaultText: 'Settings',
        key: 'label.TMS_MENU_SETTINGS',
        type: 'label',
        value: 'TMS_MENU_SETTINGS',
      },
      {
        defaultText: 'Help',
        key: 'label.TMS_MENU_HELP',
        type: 'label',
        value: 'TMS_MENU_HELP',
      },
      {
        defaultText: 'About',
        key: 'label.TMS_MENU_ABOUT',
        type: 'label',
        value: 'TMS_MENU_ABOUT',
      },
      {
        defaultText: 'Table Sensor',
        key: 'label.TMS_MENU_TABLE_SENSOR',
        type: 'label',
        value: 'TMS_MENU_TABLE_SENSOR',
      },
      {
        defaultText: 'Named Sensor',
        key: 'label.TMS_MENU_NAMED_SENSOR',
        type: 'label',
        value: 'TMS_MENU_NAMED_SENSOR',
      }
    ]
  },
  helpPopup: {
    screenLabel: 'Help Popup',
    customLabels: [
      {
        defaultText: 'Help',
        key: 'label.HELP_POPUP_HELP',
        type: 'label',
        value: 'HELP_POPUP_HELP',
      },
      {
        defaultText: 'Assign',
        key: 'label.HELP_POPUP_ASSIGN',
        type: 'label',
        value: 'HELP_POPUP_ASSIGN',
      },
      {
        defaultText: 'Check Details',
        key: 'label.HELP_POPUP_CHECK_DETAILS',
        type: 'label',
        value: 'HELP_POPUP_CHECK_DETAILS',
      },
      {
        defaultText: 'Return to Waitlist',
        key: 'label.HELP_POPUP_RETURN_TO_WAITLIST',
        type: 'label',
        value: 'HELP_POPUP_RETURN_TO_WAITLIST',
      },
      {
        defaultText: 'Unmerge Table',
        key: 'label.HELP_POPUP_UNMERGE_TABLE',
        type: 'label',
        value: 'HELP_POPUP_UNMERGE_TABLE',
      },
      {
        defaultText: 'Edit Waitlist',
        key: 'label.HELP_POPUP_EDIT_WAITLIST',
        type: 'label',
        value: 'HELP_POPUP_EDIT_WAITLIST',
      },
      {
        defaultText: 'Clear Table',
        key: 'label.HELP_POPUP_CLEAR_TABLE',
        type: 'label',
        value: 'HELP_POPUP_CLEAR_TABLE',
      },
      {
        defaultText: 'Delete Waitlist',
        key: 'label.HELP_POPUP_DELETE_WAITLIST',
        type: 'label',
        value: 'HELP_POPUP_DELETE_WAITLIST',
      },
      {
        defaultText: 'Transfer Table',
        key: 'label.HELP_POPUP_TRANSFER_TABLE',
        type: 'label',
        value: 'HELP_POPUP_TRANSFER_TABLE',
      },
      {
        defaultText: 'SMS Guest',
        key: 'label.HELP_POPUP_SMS_GUEST',
        type: 'label',
        value: 'HELP_POPUP_SMS_GUEST',
      },
      {
        defaultText: 'Merge Table',
        key: 'label.HELP_POPUP_MERGE_TABLE',
        type: 'label',
        value: 'HELP_POPUP_MERGE_TABLE',
      },
      {
        defaultText: 'Reorder Waitlist',
        key: 'label.HELP_POPUP_REORDER_WAITLIST',
        type: 'label',
        value: 'HELP_POPUP_REORDER_WAITLIST',
      },
      {
        defaultText: 'Void Check',
        key: 'label.HELP_POPUP_VOID_CHECK',
        type: 'label',
        value: 'HELP_POPUP_VOID_CHECK',
      }
    ]
  },
  checklookup: {
    screenLabel: 'Check Lookup',
    customLabels: [
      {
        defaultText: 'Select a Check',
        key: 'label.CHECK_LOOKUP_SELECT_A_CHECK',
        type: 'label',
        value: 'CHECK_LOOKUP_SELECT_A_CHECK',
      },
      {
        defaultText: 'Check#',
        key: 'label.CHECK_LOOKUP_CHECK_NUM',
        type: 'label',
        value: 'CHECK_LOOKUP_CHECK_NUM',
      },
      {
        defaultText: 'Table#',
        key: 'label.CHECK_LOOKUP_TABLE_NUM',
        type: 'label',
        value: 'CHECK_LOOKUP_TABLE_NUM',
      },
      {
        defaultText: 'Employee',
        key: 'label.CHECK_LOOKUP_EMPLOYEE',
        type: 'label',
        value: 'CHECK_LOOKUP_EMPLOYEE',
      },
      {
        defaultText: 'Open',
        key: 'label.CHECK_LOOKUP_OPEN',
        type: 'label',
        value: 'CHECK_LOOKUP_OPEN',
      },
      {
        defaultText: 'Close',
        key: 'label.CHECK_LOOKUP_CLOSE',
        type: 'label',
        value: 'CHECK_LOOKUP_CLOSE',
      },
      {
        defaultText: 'Total',
        key: 'label.CHECK_LOOKUP_TOTAL',
        type: 'label',
        value: 'CHECK_LOOKUP_TOTAL',
      }
    ]
  },
  aboutPopup: {
    screenLabel: 'About Popup',
    customLabels: [
      {
        defaultText: 'About',
        key: 'label.ABOUT_POPUP_ABOUT',
        type: 'label',
        value: 'ABOUT_POPUP_ABOUT',
      },
      {
        defaultText: 'Development',
        key: 'label.ABOUT_POPUP_DEVELOPMENT',
        type: 'label',
        value: 'ABOUT_POPUP_DEVELOPMENT',
      },
      {
        defaultText: 'UAT',
        key: 'label.ABOUT_POPUP_UAT',
        type: 'label',
        value: 'ABOUT_POPUP_UAT',
      },
      {
        defaultText: 'Stage',
        key: 'label.ABOUT_POPUP_STAGE',
        type: 'label',
        value: 'ABOUT_POPUP_STAGE',
      },
      {
        defaultText: 'Production',
        key: 'label.ABOUT_POPUP_PRODUCTION',
        type: 'label',
        value: 'ABOUT_POPUP_PRODUCTION',
      },
      {
        defaultText: 'Store Name',
        key: 'label.ABOUT_POPUP_STORE_NAME',
        type: 'label',
        value: 'ABOUT_POPUP_STORE_NAME',
      },
      {
        defaultText: 'Device Name',
        key: 'label.ABOUT_POPUP_DEVICE_NAME',
        type: 'label',
        value: 'ABOUT_POPUP_DEVICE_NAME',
      },
      {
        defaultText: 'Device Group Name',
        key: 'label.ABOUT_POPUP_DEVICE_GROUP_NAME',
        type: 'label',
        value: 'ABOUT_POPUP_DEVICE_GROUP_NAME',
      },
      {
        defaultText: 'Web Version',
        key: 'label.ABOUT_POPUP_WEB_VERSION',
        type: 'label',
        value: 'ABOUT_POPUP_WEB_VERSION',
      },
      {
        defaultText: 'Environment',
        key: 'label.ABOUT_POPUP_ENVIRONMENT',
        type: 'label',
        value: 'ABOUT_POPUP_ENVIRONMENT',
      },
      {
        defaultText: 'For Support',
        key: 'label.ABOUT_POPUP_FOR_SUPPORT',
        type: 'label',
        value: 'ABOUT_POPUP_FOR_SUPPORT',
      },
      {
        defaultText: 'DoorDash Drive Delivery',
        key: 'label.ABOUT_POPUP_DOORDASH_DRIVE_DELIVERY',
        type: 'label',
        value: 'ABOUT_POPUP_DOORDASH_DRIVE_DELIVERY',
      },
      {
        defaultText: 'DoorDash Marketplace',
        key: 'label.ABOUT_POPUP_DOORDASH_MARKETPLACE',
        type: 'label',
        value: 'ABOUT_POPUP_DOORDASH_MARKETPLACE',
      },
      {
        defaultText: 'Grubhub Marketplace',
        key: 'label.ABOUT_POPUP_GRUBHUB_MARKETPLACE',
        type: 'label',
        value: 'ABOUT_POPUP_GRUBHUB_MARKETPLACE',
      },
      {
        defaultText: 'UberEats Marketplace',
        key: 'label.ABOUT_POPUP_UBEREATS_MARKETPLACE',
        type: 'label',
        value: 'ABOUT_POPUP_UBEREATS_MARKETPLACE',
      },
      {
        defaultText: 'URL',
        key: 'label.ABOUT_POPUP_URL',
        type: 'label',
        value: 'ABOUT_POPUP_URL',
      }
    ]
  },
  tableAssignment: {
    screenLabel: 'Table Assignment',
    customLabels: [
      {
        defaultText: 'Name',
        key: 'label.ASSIGN_NAME',
        type: 'label',
        value: 'ASSIGN_NAME'
      },
      {
        defaultText: 'Notes',
        key: 'label.ASSIGN_NOTES',
        type: 'label',
        value: 'ASSIGN_NOTES'
      },
      {
        defaultText: 'Contact Number',
        key: 'label.ASSIGN_CONTACT',
        type: 'label',
        value: 'ASSIGN_CONTACT'
      },
      {
        defaultText: 'Email',
        key: 'label.ASSIGN_EMAIL',
        type: 'label',
        value: 'ASSIGN_EMAIL'
      },
      {
        defaultText: 'Assign a Table',
        key: 'label.ASSIGN_TABLE',
        type: 'label',
        value: 'ASSIGN_TABLE'
      },
      {
        defaultText: 'Enter Table Number',
        key: 'label.ASSIGN_ENTER_TABLE_NUMBER',
        type: 'label',
        value: 'ASSIGN_ENTER_TABLE_NUMBER'
      },
      {
        defaultText: 'Proceed without a table number',
        key: 'label.ASSIGN_PROCEED',
        type: 'label',
        value: 'ASSIGN_PROCEED'
      },
      {
        defaultText: 'Guest Details',
        key: 'label.ASSIGN_GUEST_DETAILS',
        type: 'label',
        value: 'ASSIGN_GUEST_DETAILS'
      },
      {
        defaultText: 'Assign',
        key: 'label.ASSIGN_BUTTON_LABEL',
        type: 'label',
        value: 'ASSIGN_BUTTON_LABEL'
      },
      {
        defaultText: 'Cancel',
        key: 'label.ASSIGN_CANCEL',
        type: 'label',
        value: 'ASSIGN_CANCEL'
      },
      {
        defaultText: 'Assign a Server',
        key: 'label.ASSIGN_SERVER',
        type: 'label',
        value: 'ASSIGN_SERVER'
      },
      {
        defaultText: 'Optional',
        key: 'label.ASSIGN_OPTIONAL',
        type: 'label',
        value: 'ASSIGN_OPTIONAL'
      },
      {
        defaultText: 'Table',
        key: 'label.ASSIGN_TABLE_TEXT',
        type: 'label',
        value: 'ASSIGN_TABLE_TEXT'
      },
      {
        defaultText: 'is already occupied! Please select another table',
        key: 'label.ASSIGN_OCCUPIED',
        type: 'label',
        value: 'ASSIGN_OCCUPIED'
      },
      {
        defaultText: 'is already merged! Please select another table',
        key: 'label.ASSIGN_ALREADY_MERGED',
        type: 'label',
        value: 'ASSIGN_ALREADY_MERGED'
      },
      {
        defaultText: 'Please select tables from layout to proceed',
        key: 'label.ASSIGN_SNACKBAR_MSG',
        type: 'label',
        value: 'ASSIGN_SNACKBAR_MSG'
      }
    ]
  },
  transferTable: {
    screenLabel: 'Transfer Table',
    customLabels: [
      {
        defaultText: 'Selected station cannot be same as move station',
        key: 'label.TRANSFER_MSG',
        type: 'label',
        value: 'TRANSFER_MSG'
      },
      {
        defaultText: 'Table',
        key: 'label.TRANSFER_TABLE_TEXT',
        type: 'label',
        value: 'TRANSFER_TABLE_TEXT'
      },
      {
        defaultText: 'is already occupied! Please select another table',
        key: 'label.TRANSFER_OCCUPIED',
        type: 'label',
        value: 'TRANSFER_OCCUPIED'
      },
      {
        defaultText: 'Please select a table from layout to proceed',
        key: 'label.TRANSFER_SNACKBAR_MSG',
        type: 'label',
        value: 'TRANSFER_SNACKBAR_MSG'
      },
      {
        defaultText: 'This will move all checks from table',
        key: 'label.TRANSFER_MOVE_CHECKS',
        type: 'label',
        value: 'TRANSFER_MOVE_CHECKS'
      },
      {
        defaultText: 'This will move the check from table',
        key: 'label.TRANSFER_MOVE_CHECKS_FROM',
        type: 'label',
        value: 'TRANSFER_MOVE_CHECKS_FROM'
      },
      {
        defaultText: 'to',
        key: 'label.TRANSFER_TO',
        type: 'label',
        value: 'TRANSFER_TO'
      },
      {
        defaultText: 'Please select a table to move check',
        key: 'label.TRANSFER_SELECT_TO_MOVE',
        type: 'label',
        value: 'TRANSFER_SELECT_TO_MOVE'
      }
    ]
  },
  checkDetails: {
    screenLabel: 'Check Details',
    customLabels: [
      {
        defaultText: 'POS',
        key: 'label.CHECK_DETAILS_POS',
        type: 'label',
        value: 'CHECK_DETAILS_POS'
      },
      {
        defaultText: 'Discounts and Service Charges',
        key: 'label.CHECK_DETAILS_DISCOUNT_SC',
        type: 'label',
        value: 'CHECK_DETAILS_DISCOUNT_SC'
      },
      {
        defaultText: 'Sub Total: ',
        key: 'label.CHECK_DETAILS_SUBTOTAL',
        type: 'label',
        value: 'CHECK_DETAILS_SUBTOTAL'
      },
      {
        defaultText: 'TAX',
        key: 'label.CHECK_DETAILS_TAX',
        type: 'label',
        value: 'CHECK_DETAILS_TAX'
      },
      {
        defaultText: 'DISCOUNT',
        key: 'label.CHECK_DETAILS_DISCOUNT',
        type: 'label',
        value: 'CHECK_DETAILS_DISCOUNT'
      },
      {
        defaultText: 'SVC CHG',
        key: 'label.CHECK_DETAILS_SVC',
        type: 'label',
        value: 'CHECK_DETAILS_SVC'
      },
      {
        defaultText: 'TOTAL',
        key: 'label.CHECK_DETAILS_TOTAL',
        type: 'label',
        value: 'CHECK_DETAILS_TOTAL'
      },
      {
        defaultText: 'Paid',
        key: 'label.CHECK_DETAILS_PAID',
        type: 'label',
        value: 'CHECK_DETAILS_PAID'
      },
      {
        defaultText: 'TIP',
        key: 'label.CHECK_DETAILS_TIP',
        type: 'label',
        value: 'CHECK_DETAILS_TIP'
      },
      {
        defaultText: 'Unpaid',
        key: 'label.CHECK_DETAILS_UNPAID',
        type: 'label',
        value: 'CHECK_DETAILS_UNPAID'
      }
    ]
  },
  tableProperties: {
    screenLabel: 'Table Properties',
    customLabels: [
      {
        defaultText: 'Table Properties',
        key: 'label.PROPERTIES_LABEL',
        type: 'label',
        value: 'PROPERTIES_LABEL'
      },
      {
        defaultText: 'Clear Table',
        key: 'label.PROPERTIES_CLEAR_TABLE',
        type: 'label',
        value: 'PROPERTIES_CLEAR_TABLE'
      },
      {
        defaultText: 'Merge Table',
        key: 'label.PROPERTIES_MERGE_TABLE',
        type: 'label',
        value: 'PROPERTIES_MERGE_TABLE'
      },
      {
        defaultText: 'Unmerge Table',
        key: 'label.PROPERTIES_UNMERGE_TABLE',
        type: 'label',
        value: 'PROPERTIES_UNMERGE_TABLE'
      },
      {
        defaultText: 'Transfer Table',
        key: 'label.PROPERTIES_TRANSFER_TABLE',
        type: 'label',
        value: 'PROPERTIES_TRANSFER_TABLE'
      },
      {
        defaultText: 'Void Check',
        key: 'label.PROPERTIES_VOID_CHECK',
        type: 'label',
        value: 'PROPERTIES_VOID_CHECK'
      },
      {
        defaultText: 'SMS Guest',
        key: 'label.PROPERTIES_SMS_GUEST',
        type: 'label',
        value: 'PROPERTIES_SMS_GUEST'
      },
      {
        defaultText: 'This will clear the table',
        key: 'label.PROPERTIES_CLEAR_MSG',
        type: 'label',
        value: 'PROPERTIES_CLEAR_MSG'
      },
      {
        defaultText: 'This will merge table',
        key: 'label.PROPERTIES_MERGE_TEXT',
        type: 'label',
        value: 'PROPERTIES_MERGE_TEXT'
      },
      {
        defaultText: 'This will unmerge table',
        key: 'label.PROPERTIES_UNMERGE_TEXT',
        type: 'label',
        value: 'PROPERTIES_UNMERGE_TEXT'
      },
      {
        defaultText: 'Are you sure you want to continue?',
        key: 'label.PROPERTIES_CONTINUE_MSG',
        type: 'label',
        value: 'PROPERTIES_CONTINUE_MSG'
      },
      {
        defaultText: 'Cancel',
        key: 'label.PROPERTIES_CANCEL',
        type: 'label',
        value: 'PROPERTIES_CANCEL'
      },
      {
        defaultText: 'Continue',
        key: 'label.PROPERTIES_CONTINUE',
        type: 'label',
        value: 'PROPERTIES_CONTINUE'
      }
    ]
  },
  mergeTable: {
    screenLabel: 'Merge Table',
    customLabels: [
      {
        defaultText: 'Choose the tables to merge',
        key: 'label.MERGE_CHOOSE_TABLES',
        type: 'label',
        value: 'MERGE_CHOOSE_TABLES'
      }
    ]
  },
  popupMessage: {
    screenLabel: 'Popup Message',
    customLabels: [
      {
        defaultText: 'MERGED',
        key: 'label.POPUP_MESSAGE_MERGED',
        type: 'label',
        value: 'POPUP_MESSAGE_MERGED',
      },
      {
        defaultText: 'Confirmation',
        key: 'label.POPUP_MESSAGE_CONFIRMATION',
        type: 'label',
        value: 'POPUP_MESSAGE_CONFIRMATION',
      },
      {
        defaultText: 'No',
        key: 'label.POPUP_MESSAGE_NO',
        type: 'label',
        value: 'POPUP_MESSAGE_NO',
      },
      {
        defaultText: 'Yes',
        key: 'label.POPUP_MESSAGE_YES',
        type: 'label',
        value: 'POPUP_MESSAGE_YES',
      },
      {
        defaultText: 'Getting merged stations',
        key: 'label.POPUP_MESSAGE_GETTING_MERGED_STATIONS_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_GETTING_MERGED_STATIONS_MSG',
      },
      {
        defaultText: 'Seating Guest without Table Selection',
        key: 'label.POPUP_MESSAGE_SEAT_GUEST_WITHOUT_TABLE_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SEAT_GUEST_WITHOUT_TABLE_MSG',
      },
      {
        defaultText: 'Are you sure you want to seat guest without selecting a table',
        key: 'label.POPUP_MESSAGE_SEAT_GUEST_WITHOUT_TABLE_CONF_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SEAT_GUEST_WITHOUT_TABLE_CONF_MSG',
      },
      {
        defaultText: 'Merging Tables',
        key: 'label.POPUP_MESSAGE_MERGING_TABLES_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_MERGING_TABLES_MSG',
      },
      {
        defaultText: 'Send SMS',
        key: 'label.POPUP_MESSAGE_SEND_SMS',
        type: 'label',
        value: 'POPUP_MESSAGE_SEND_SMS',
      },
      {
        defaultText: 'Send SMS to All',
        key: 'label.POPUP_MESSAGE_SEND_SMS_ALL',
        type: 'label',
        value: 'POPUP_MESSAGE_SEND_SMS_ALL',
      },
      {
        defaultText: 'Move',
        key: 'label.POPUP_MESSAGE_MOVE',
        type: 'label',
        value: 'POPUP_MESSAGE_MOVE',
      },
      {
        defaultText: 'Move All',
        key: 'label.POPUP_MESSAGE_MOVE_ALL',
        type: 'label',
        value: 'POPUP_MESSAGE_MOVE_ALL',
      },
      {
        defaultText: 'Sending SMS',
        key: 'label.POPUP_MESSAGE_SENDING_SMS_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SENDING_SMS_MSG',
      },
      {
        defaultText: 'This will send an SMS message to the phone number on file. Do you want to continue?',
        key: 'label.POPUP_MESSAGE_SEND_SMS_CONF_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SEND_SMS_CONF_MSG',
      },
      {
        defaultText: 'Moving Guest to a different table',
        key: 'label.POPUP_MESSAGE_MOVING_GUEST_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_MOVING_GUEST_MSG',
      },
      {
        defaultText: 'Unmerging Tables',
        key: 'label.POPUP_MESSAGE_UNMERGING_TABLES_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_UNMERGING_TABLES_MSG',
      },
      {
        defaultText: 'Fetching store layout',
        key: 'label.POPUP_MESSAGE_FETCHING_STORE_LAYOUT_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_FETCHING_STORE_LAYOUT_MSG',
      },
      {
        defaultText: 'Unseat Guest',
        key: 'label.POPUP_MESSAGE_UNSEAT_GUEST',
        type: 'label',
        value: 'POPUP_MESSAGE_UNSEAT_GUEST',
      },
      {
        defaultText: 'Unseat All Guests',
        key: 'label.POPUP_MESSAGE_UNSEAT_ALL_GUEST',
        type: 'label',
        value: 'POPUP_MESSAGE_UNSEAT_ALL_GUEST',
      },
      {
        defaultText: 'Clearing the table',
        key: 'label.POPUP_MESSAGE_CLEARING_TABLE_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_CLEARING_TABLE_MSG',
      },
      {
        defaultText: 'Clearing booking',
        key: 'label.POPUP_MESSAGE_CLEARING_BOOKING_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_CLEARING_BOOKING_MSG',
      },
      {
        defaultText: 'This will move the guest back to the waitlist. Do you want to proceed?',
        key: 'label.POPUP_MESSAGE_MOVE_TO_WAITLIST_CONF_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_MOVE_TO_WAITLIST_CONF_MSG',
      },
      {
        defaultText: 'Canceling booking',
        key: 'label.POPUP_MESSAGE_CANCELING_BOOKING_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_CANCELING_BOOKING_MSG',
      },
      {
        defaultText: 'This will cancel the waitlist for',
        key: 'label.POPUP_MESSAGE_CANCEL_WAITLIST_CONF_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_CANCEL_WAITLIST_CONF_MSG',
      },
      {
        defaultText: 'Creating new booking',
        key: 'label.POPUP_MESSAGE_CREATING_NEW_BOOKING_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_CREATING_NEW_BOOKING_MSG',
      },
      {
        defaultText: 'Updating booking',
        key: 'label.POPUP_MESSAGE_UPDATING_BOOKING_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_UPDATING_BOOKING_MSG',
      },
      {
        defaultText: 'Assigning Table',
        key: 'label.POPUP_MESSAGE_ASSIGNING_TABLE_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_ASSIGNING_TABLE_MSG',
      },
      {
        defaultText: 'Seating party',
        key: 'label.POPUP_MESSAGE_SEATING_PARTY_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SEATING_PARTY_MSG',
      },
      {
        defaultText: 'This will seat the party',
        key: 'label.POPUP_MESSAGE_SEAT_PARTY_CONF_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SEAT_PARTY_CONF_MSG',
      },
      {
        defaultText: 'to table',
        key: 'label.POPUP_MESSAGE_TO_TABLE',
        type: 'label',
        value: 'POPUP_MESSAGE_TO_TABLE',
      },
      {
        defaultText: 'Moving booking',
        key: 'label.POPUP_MESSAGE_MOVING_BOOKING_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_MOVING_BOOKING_MSG',
      },
      {
        defaultText: 'Syncing with your POS',
        key: 'label.POPUP_MESSAGE_SYNCING_WITH_POS_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_SYNCING_WITH_POS_MSG',
      },
      {
        defaultText: 'Voiding Check',
        key: 'label.POPUP_MESSAGE_VOIDING_CHECK_MSG',
        type: 'label',
        value: 'POPUP_MESSAGE_VOIDING_CHECK_MSG',
      },
      {
        defaultText: 'Cancel',
        key: 'label.POPUP_MESSAGE_CANCEL',
        type: 'label',
        value: 'POPUP_MESSAGE_CANCEL',
      }
    ]
  },
  designer: {
    screenLabel: 'Designer',
    customLabels: [
      {
        defaultText: 'No',
        key: 'label.DESIGNER_NO',
        type: 'label',
        value: 'DESIGNER_NO',
      },
      {
        defaultText: 'Yes',
        key: 'label.DESIGNER_YES',
        type: 'label',
        value: 'DESIGNER_YES',
      },
      {
        defaultText: 'Cancel',
        key: 'label.DESIGNER_CANCEL',
        type: 'label',
        value: 'DESIGNER_CANCEL',
      },
      {
        defaultText: 'Ok',
        key: 'label.DESIGNER_OK',
        type: 'label',
        value: 'DESIGNER_OK',
      },
      {
        defaultText: 'Select Sensor Group',
        key: 'label.DESIGNER_SELECT_SENSOR_GROUP',
        type: 'label',
        value: 'DESIGNER_SELECT_SENSOR_GROUP',
      },
      {
        defaultText: 'Size',
        key: 'label.DESIGNER_SIZE',
        type: 'label',
        value: 'DESIGNER_SIZE',
      },
      {
        defaultText: 'Position',
        key: 'label.DESIGNER_POSITION',
        type: 'label',
        value: 'DESIGNER_POSITION',
      },
      {
        defaultText: 'Scale',
        key: 'label.DESIGNER_SCALE',
        type: 'label',
        value: 'DESIGNER_SCALE',
      },
      {
        defaultText: 'Angle',
        key: 'label.DESIGNER_ANGLE',
        type: 'label',
        value: 'DESIGNER_ANGLE',
      },
      {
        defaultText: 'Stroke Width',
        key: 'label.DESIGNER_STROKE_WIDTH',
        type: 'label',
        value: 'DESIGNER_STROKE_WIDTH',
      },
      {
        defaultText: 'Rectangle',
        key: 'label.DESIGNER_RECTANGLE',
        type: 'label',
        value: 'DESIGNER_RECTANGLE',
      },
      {
        defaultText: 'Square',
        key: 'label.DESIGNER_SQUARE',
        type: 'label',
        value: 'DESIGNER_SQUARE',
      },
      {
        defaultText: 'Circle',
        key: 'label.DESIGNER_CIRCLE',
        type: 'label',
        value: 'DESIGNER_CIRCLE',
      },
      {
        defaultText: 'Polygon',
        key: 'label.DESIGNER_POLYGON',
        type: 'label',
        value: 'DESIGNER_POLYGON',
      },
      {
        defaultText: 'Door',
        key: 'label.DESIGNER_DOOR',
        type: 'label',
        value: 'DESIGNER_DOOR',
      },
      {
        defaultText: 'Window',
        key: 'label.DESIGNER_WINDOW',
        type: 'label',
        value: 'DESIGNER_WINDOW',
      },
      {
        defaultText: 'Aquarium',
        key: 'label.DESIGNER_AQUARIUM',
        type: 'label',
        value: 'DESIGNER_AQUARIUM',
      },
      {
        defaultText: 'Painting',
        key: 'label.DESIGNER_PAINTING',
        type: 'label',
        value: 'DESIGNER_PAINTING',
      },
      {
        defaultText: 'Restroom',
        key: 'label.DESIGNER_RESTROOM',
        type: 'label',
        value: 'DESIGNER_RESTROOM',
      },
      {
        defaultText: 'Kitchen',
        key: 'label.DESIGNER_KITCHEN',
        type: 'label',
        value: 'DESIGNER_KITCHEN',
      },
      {
        defaultText: 'Table',
        key: 'label.DESIGNER_TABLE',
        type: 'label',
        value: 'DESIGNER_TABLE',
      },
      {
        defaultText: 'Fill and Stroke',
        key: 'label.DESIGNER_FILL_STROKE',
        type: 'label',
        value: 'DESIGNER_FILL_STROKE',
      },
      {
        defaultText: 'Fill',
        key: 'label.DESIGNER_FILL',
        type: 'label',
        value: 'DESIGNER_FILL',
      },
      {
        defaultText: 'Stroke',
        key: 'label.DESIGNER_STROKE',
        type: 'label',
        value: 'DESIGNER_STROKE',
      },
      {
        defaultText: 'Warning',
        key: 'label.DESIGNER_WARNING',
        type: 'label',
        value: 'DESIGNER_WARNING',
      },
      {
        defaultText: 'Confirmation',
        key: 'label.DESIGNER_CONFIRMATION',
        type: 'label',
        value: 'DESIGNER_CONFIRMATION',
      },
      {
        defaultText: 'Create your layout',
        key: 'label.DESIGNER_CREATE_LAYOUT',
        type: 'label',
        value: 'DESIGNER_CREATE_LAYOUT',
      },
      {
        defaultText: 'Click on the',
        key: 'label.DESIGNER_CLICK_ON',
        type: 'label',
        value: 'DESIGNER_CLICK_ON',
      },
      {
        defaultText: 'icon at the top left to create layout',
        key: 'label.DESIGNER_ICON_TOP_LEFT',
        type: 'label',
        value: 'DESIGNER_ICON_TOP_LEFT',
      },
      {
        defaultText: 'Please select a tool',
        key: 'label.DESIGNER_SELECT_TOOL',
        type: 'label',
        value: 'DESIGNER_SELECT_TOOL',
      },
      {
        defaultText: 'Please select a static shape',
        key: 'label.DESIGNER_SELECT_STATIC_SHAPE',
        type: 'label',
        value: 'DESIGNER_SELECT_STATIC_SHAPE',
      },
      {
        defaultText: 'Please select seat capacity',
        key: 'label.DESIGNER_SELECT_SEAT_CAPACITY',
        type: 'label',
        value: 'DESIGNER_SELECT_SEAT_CAPACITY',
      },
      {
        defaultText: 'Please select a table',
        key: 'label.DESIGNER_SELECT_TABLE',
        type: 'label',
        value: 'DESIGNER_SELECT_TABLE',
      },
      {
        defaultText: 'Please select a table shape',
        key: 'label.DESIGNER_SELECT_TABLE_SHAPE',
        type: 'label',
        value: 'DESIGNER_SELECT_TABLE_SHAPE',
      },
      {
        defaultText: 'Fetching store layout',
        key: 'label.DESIGNER_FETCH_STORE_LAYOUT',
        type: 'label',
        value: 'DESIGNER_FETCH_STORE_LAYOUT',
      },
      {
        defaultText: 'Updating store layout',
        key: 'label.DESIGNER_UPDATE_STORE_LAYOUT',
        type: 'label',
        value: 'DESIGNER_UPDATE_STORE_LAYOUT',
      },
      {
        defaultText: 'Please select sensor group to draw layout',
        key: 'label.DESIGNER_SELECT_SENSOR_GROUP_DRAW',
        type: 'label',
        value: 'DESIGNER_SELECT_SENSOR_GROUP_DRAW',
      },
      {
        defaultText: 'Please select sensor group to save layout.',
        key: 'label.DESIGNER_SELECT_SENSOR_GROUP_SAVE',
        type: 'label',
        value: 'DESIGNER_SELECT_SENSOR_GROUP_SAVE',
      },
      {
        defaultText: 'This action will delete the layout.\nAre you sure want to continue?',
        key: 'label.DESIGNER_DELETE_LAYOUT',
        type: 'label',
        value: 'DESIGNER_DELETE_LAYOUT',
      },
      {
        defaultText: 'There are changes in the layout that are not saved.\nWould you like to save the changes?',
        key: 'label.DESIGNER_CLOSE_LAYOUT',
        type: 'label',
        value: 'DESIGNER_CLOSE_LAYOUT',
      },
      {
        defaultText: 'Seat',
        key: 'label.DESIGNER_SEAT',
        type: 'label',
        value: 'DESIGNER_SEAT',
      },
      {
        defaultText: 'Seats',
        key: 'label.DESIGNER_SEATS',
        type: 'label',
        value: 'DESIGNER_SEATS',
      }
    ]
  }
};
