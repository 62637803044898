
export const GET_HAPPY_COG_SETUP_REQUESTED = 'happyCog/GET_HAPPY_COG_SETUP_REQUESTED';
export const GET_HAPPY_COG_SETUP_SUCCESS = 'happyCog/GET_HAPPY_COG_SETUP_SUCCESS';
export const GET_HAPPY_COG_SETUP_FAILURE = 'happyCog/GET_HAPPY_COG_SETUP_FAILURE';

export const UPDATE_HAPPY_COG_SETUP_REQUESTED = 'happyCog/UPDATE_HAPPY_COG_SETUP_REQUESTED';
export const UPDATE_HAPPY_COG_SETUP_SUCCESS = 'happyCog/UPDATE_HAPPY_COG_SETUP_SUCCESS';
export const UPDATE_HAPPY_COG_SETUP_FAILURE = 'happyCog/UPDATE_HAPPY_COG_SETUP_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  happyCogList: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_HAPPY_COG_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        happyCogList: [],
      };
    }
    case GET_HAPPY_COG_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        happyCogList: action.result,
      };
    }
    case GET_HAPPY_COG_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_HAPPY_COG_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_HAPPY_COG_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_HAPPY_COG_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getHappyCogConfig = (brandId) => {
  return {
    types: [GET_HAPPY_COG_SETUP_REQUESTED, GET_HAPPY_COG_SETUP_SUCCESS, GET_HAPPY_COG_SETUP_FAILURE],
    promise: client => client.get(`getHappyCogConfig/${brandId}`)
  };
};

export const updateHappyCogConfig = (brandId, data) => {
  return {
    types: [UPDATE_HAPPY_COG_SETUP_REQUESTED, UPDATE_HAPPY_COG_SETUP_SUCCESS, UPDATE_HAPPY_COG_SETUP_FAILURE],
    promise: client => client.post(`updateHappyCogConfig/${brandId}`, { data })
  };
};
