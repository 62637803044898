const currentReducer = 'mdmDevice';

const GET_MDM_DEVICES_REQUESTED = `${currentReducer}/GET_MDM_DEVICES_REQUESTED`;
const GET_MDM_DEVICES_SUCCESS = `${currentReducer}/GET_MDM_DEVICES_SUCCESS`;
const GET_MDM_DEVICES_FAILURE = `${currentReducer}/GET_MDM_DEVICES_FAILURE`;

const ADD_MDM_DEVICE_REQUESTED = `${currentReducer}/ADD_MDM_DEVICE_REQUESTED`;
const ADD_MDM_DEVICE_SUCCESS = `${currentReducer}/ADD_MDM_DEVICE_SUCCESS`;
const ADD_MDM_DEVICE_FAILURE = `${currentReducer}/ADD_MDM_DEVICE_FAILURE`;

const UPDATE_MDM_DEVICE_REQUESTED = `${currentReducer}/UPDATE_MDM_DEVICE_REQUESTED`;
const UPDATE_MDM_DEVICE_SUCCESS = `${currentReducer}/UPDATE_MDM_DEVICE_SUCCESS`;
const UPDATE_MDM_DEVICE_FAILURE = `${currentReducer}/UPDATE_MDM_DEVICE_FAILURE`;

const MDM_DEVICE_ACTIVATION_REQUESTED = `${currentReducer}/MDM_DEVICE_ACTIVATION_REQUESTED`;
const MDM_DEVICE_ACTIVATION_SUCCESS = `${currentReducer}/MDM_DEVICE_ACTIVATION_SUCCESS`;
const MDM_DEVICE_ACTIVATION_FAILURE = `${currentReducer}/MDM_DEVICE_ACTIVATION_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  mdmDevicesList: null,
  mdmDevice: null
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MDM_DEVICES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        mdmDevicesList: null
      };
    }
    case GET_MDM_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmDevicesList: action.result
      };
    }
    case GET_MDM_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_MDM_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_MDM_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmDevice: action.result
      };
    }
    case ADD_MDM_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MDM_DEVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_MDM_DEVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmDevice: action.result
      };
    }
    case UPDATE_MDM_DEVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case MDM_DEVICE_ACTIVATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case MDM_DEVICE_ACTIVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case MDM_DEVICE_ACTIVATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getStoreMDMDevicesList = (data) => {
  return {
    types: [GET_MDM_DEVICES_REQUESTED, GET_MDM_DEVICES_SUCCESS, GET_MDM_DEVICES_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const addMDMDevice = (data) => {
  return {
    types: [ADD_MDM_DEVICE_REQUESTED, ADD_MDM_DEVICE_SUCCESS, ADD_MDM_DEVICE_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const updateMDMDevice = (data) => {
  return {
    types: [UPDATE_MDM_DEVICE_REQUESTED, UPDATE_MDM_DEVICE_SUCCESS, UPDATE_MDM_DEVICE_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const reActivateMDMDevice = (data) => {
  return {
    types: [MDM_DEVICE_ACTIVATION_REQUESTED, MDM_DEVICE_ACTIVATION_SUCCESS, MDM_DEVICE_ACTIVATION_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const deActivateMDMDevice = (data) => {
  return {
    types: [MDM_DEVICE_ACTIVATION_REQUESTED, MDM_DEVICE_ACTIVATION_SUCCESS, MDM_DEVICE_ACTIVATION_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};
