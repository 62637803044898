export const GET_SVS_SETUP_CONFIG_REQUESTED = 'brand/GET_SVS_SETUP_CONFIG_REQUESTED';
export const GET_SVS_SETUP_CONFIG_SUCCESS = 'brand/GET_SVS_SETUP_CONFIG_SUCCESS';
export const GET_SVS_SETUP_CONFIG_FAILURE = 'brand/GET_SVS_SETUP_CONFIG_FAILURE';

export const UPDATE_SVS_SETUP_CONFIG_REQUESTED = 'brand/UPDATE_SVS_SETUP_CONFIG_REQUESTED';
export const UPDATE_SVS_SETUP_CONFIG_SUCCESS = 'brand/UPDATE_SVS_SETUP_CONFIG_SUCCESS';
export const UPDATE_SVS_SETUP_CONFIG_FAILURE = 'brand/UPDATE_SVS_SETUP_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  svsConfiglist: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SVS_SETUP_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        svsConfiglist: [],
      };
    }
    case GET_SVS_SETUP_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        svsConfiglist: action.result,
      };
    }
    case GET_SVS_SETUP_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SVS_SETUP_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SVS_SETUP_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SVS_SETUP_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getSvsConfig = (brandId) => {
  return {
    types: [GET_SVS_SETUP_CONFIG_REQUESTED, GET_SVS_SETUP_CONFIG_SUCCESS, GET_SVS_SETUP_CONFIG_FAILURE],
    promise: client => client.get(`getSvsConfig/${brandId}`)
  };
};

export const updateSvsConfig = (brandId, data) => {
  return {
    types: [UPDATE_SVS_SETUP_CONFIG_REQUESTED, UPDATE_SVS_SETUP_CONFIG_SUCCESS, UPDATE_SVS_SETUP_CONFIG_FAILURE],
    promise: client => client.post(`updateSvsConfig/${brandId}`, { data })
  };
};
