const currentReducer = 'COMPANY_ROLE_TEMPLATE';
const GET_COMPANY_ROLE_TEMPLATES_REQUESTED = `${currentReducer}/GET_COMPANY_ROLE_TEMPLATES_REQUESTED`;
const GET_COMPANY_ROLE_TEMPLATES_SUCCESS = `${currentReducer}/GET_COMPANY_ROLE_TEMPLATES_SUCCESS`;
const GET_COMPANY_ROLE_TEMPLATES_FAILURE = `${currentReducer}/GET_COMPANY_ROLE_TEMPLATES_FAILURE`;

const GET_COMPANY_ROLE_PERMISSIONS_REQUESTED = `${currentReducer}/GET_COMPANY_ROLE_PERMISSIONS_REQUESTED`;
const GET_COMPANY_ROLE_PERMISSIONS_SUCCESS = `${currentReducer}/GET_COMPANY_ROLE_PERMISSIONS_SUCCESS`;
const GET_COMPANY_ROLE_PERMISSIONS_FAILURE = `${currentReducer}/GET_COMPANY_ROLE_PERMISSIONS_FAILURE`;

const GET_COMPANY_ROLES_BY_ID_REQUESTED = `${currentReducer}/GET_COMPANY_ROLES_BY_ID_REQUESTED`;
const GET_COMPANY_ROLES_BY_ID_SUCCESS = `${currentReducer}/GET_COMPANY_ROLES_BY_ID_SUCCESS`;
const GET_COMPANY_ROLES_BY_ID_FAILURE = `${currentReducer}/GET_COMPANY_ROLES_BY_ID_FAILURE`;

const UPDATE_COMPANY_ROLE_BY_ID_REQUESTED = `${currentReducer}/UPDATE_COMPANY_ROLE_BY_ID_REQUESTED`;
const UPDATE_COMPANY_ROLE_BY_ID_SUCCESS = `${currentReducer}/UPDATE_COMPANY_ROLE_BY_ID_SUCCESS`;
const UPDATE_COMPANY_ROLE_BY_ID_FAILURE = `${currentReducer}/UPDATE_COMPANY_ROLE_BY_ID_FAILURE`;

const ADD_COMPANY_ROLE_REQUESTED = `${currentReducer}/ADD_COMPANY_ROLE_REQUESTED`;
const ADD_COMPANY_ROLE_SUCCESS = `${currentReducer}/ADD_COMPANY_ROLE_SUCCESS`;
const ADD_COMPANY_ROLE_FAILURE = `${currentReducer}/ADD_COMPANY_ROLE_FAILURE`;

const DELETE_COMPANY_ROLE_BY_ID_REQUESTED = `${currentReducer}/DELETE_COMPANY_ROLE_BY_ID_REQUESTED`;
const DELETE_COMPANY_ROLE_BY_ID_SUCCESS = `${currentReducer}/DELETE_COMPANY_ROLE_BY_ID_SUCCESS`;
const DELETE_COMPANY_ROLE_BY_ID_FAILURE = `${currentReducer}/DELETE_COMPANY_ROLE_BY_ID_FAILURE`;

const GET_ROLE_BY_ID_REQUESTED = `${currentReducer}/GET_ROLE_BY_ID_REQUESTED`;
const GET_ROLE_BY_ID_SUCCESS = `${currentReducer}/GET_ROLE_BY_ID_SUCCESS`;
const GET_ROLE_BY_ID_FAILURE = `${currentReducer}/GET_ROLE_BY_ID_FAILURE`;

const UPDATE_ROLE_PERMISSIONS_REQUESTED = `${currentReducer}/UPDATE_ROLE_PERMISSIONS_REQUESTED`;
const UPDATE_ROLE_PERMISSIONS_SUCCESS = `${currentReducer}/UPDATE_ROLE_PERMISSIONS_SUCCESS`;
const UPDATE_ROLE_PERMISSIONS_FAILURE = `${currentReducer}/UPDATE_ROLE_PERMISSIONS_FAILURE`;

const GET_ROLE_TEMPLATE_BY_ROLEID_REQUESTED = `${currentReducer}/GET_ROLE_TEMPLATE_BY_ROLEID_REQUESTED`;
const GET_ROLE_TEMPLATE_BY_ROLEID_SUCCESS = `${currentReducer}/GET_ROLE_TEMPLATE_BY_ROLEID_SUCCESS`;
const GET_ROLE_TEMPLATE_BY_ROLEID_FAILURE = `${currentReducer}/GET_ROLE_TEMPLATE_BY_ROLEID_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  companyRoleTemplateData: [],
  companyRolePermissionsData: [],
  companyRolesList: [],
  roleData: null,
  roleTemplateData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_ROLE_TEMPLATES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        companyRoleTemplateData: []
      };
    }
    case GET_COMPANY_ROLE_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyRoleTemplateData: action.result
      };
    }
    case GET_COMPANY_ROLE_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_COMPANY_ROLE_PERMISSIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        companyRolePermissionsData: []
      };
    }
    case GET_COMPANY_ROLE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyRolePermissionsData: action.result
      };
    }
    case GET_COMPANY_ROLE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_COMPANY_ROLES_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        companyRolesList: []
      };
    }
    case GET_COMPANY_ROLES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyRolesList: action.result
      };
    }
    case GET_COMPANY_ROLES_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_COMPANY_ROLE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_COMPANY_ROLE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_COMPANY_ROLE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_COMPANY_ROLE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_COMPANY_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_COMPANY_ROLE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_COMPANY_ROLE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case DELETE_COMPANY_ROLE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_COMPANY_ROLE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_ROLE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        roleData: null
      };
    }
    case GET_ROLE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        roleData: action.result
      };
    }
    case GET_ROLE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_ROLE_PERMISSIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_ROLE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ROLE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_ROLE_TEMPLATE_BY_ROLEID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        roleTemplateData: null
      };
    }
    case GET_ROLE_TEMPLATE_BY_ROLEID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        roleTemplateData: action.result
      };
    }
    case GET_ROLE_TEMPLATE_BY_ROLEID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getCompanyRoleTemplate = () => {
  return {
    types: [GET_COMPANY_ROLE_TEMPLATES_REQUESTED, GET_COMPANY_ROLE_TEMPLATES_SUCCESS, GET_COMPANY_ROLE_TEMPLATES_FAILURE],
    promise: client => client.get('getCompanyRoleTemplate')
  };
};

export const getCompanyRolePermissions = () => {
  return {
    types: [GET_COMPANY_ROLE_PERMISSIONS_REQUESTED, GET_COMPANY_ROLE_PERMISSIONS_SUCCESS, GET_COMPANY_ROLE_PERMISSIONS_FAILURE],
    promise: client => client.get('getCompanyRolePermissions')
  };
};

export const getRolesByCompanyId = (id) => {
  return {
    types: [GET_COMPANY_ROLES_BY_ID_REQUESTED, GET_COMPANY_ROLES_BY_ID_SUCCESS, GET_COMPANY_ROLES_BY_ID_FAILURE],
    promise: client => client.get(`getRolesByCompanyId/${id}`)
  };
};

export const addCompanyRole = (data) => {
  return {
    types: [ADD_COMPANY_ROLE_REQUESTED, ADD_COMPANY_ROLE_SUCCESS, ADD_COMPANY_ROLE_FAILURE],
    promise: client => client.post('addCompanyRole', { data })
  };
};

export const updateCompanyRole = (data) => {
  return {
    types: [UPDATE_COMPANY_ROLE_BY_ID_REQUESTED, UPDATE_COMPANY_ROLE_BY_ID_SUCCESS, UPDATE_COMPANY_ROLE_BY_ID_FAILURE],
    promise: client => client.post('updateCompanyRole', { data })
  };
};

export const deleteCompanyRole = (id) => {
  return {
    types: [DELETE_COMPANY_ROLE_BY_ID_REQUESTED, DELETE_COMPANY_ROLE_BY_ID_SUCCESS, DELETE_COMPANY_ROLE_BY_ID_FAILURE],
    promise: client => client.post(`deleteCompanyRole/${id}`)
  };
};

export const getRoleById = (id) => {
  return {
    types: [GET_ROLE_BY_ID_REQUESTED, GET_ROLE_BY_ID_SUCCESS, GET_ROLE_BY_ID_FAILURE],
    promise: client => client.get(`getRoleById/${id}`)
  };
};

export const updateRolePermissions = (data) => {
  return {
    types: [UPDATE_ROLE_PERMISSIONS_REQUESTED, UPDATE_ROLE_PERMISSIONS_SUCCESS, UPDATE_ROLE_PERMISSIONS_FAILURE],
    promise: client => client.post('updateRolePermissions', { data })
  };
};

export const getRoleTemplateByRoleId = (id) => {
  return {
    types: [GET_ROLE_TEMPLATE_BY_ROLEID_REQUESTED, GET_ROLE_TEMPLATE_BY_ROLEID_SUCCESS, GET_ROLE_TEMPLATE_BY_ROLEID_FAILURE],
    promise: client => client.get(`getRoleTemplateByRoleId/${id}`)
  };
};
