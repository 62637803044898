
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 *
 * @returns authenicated components
*/
// const PublicRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props => (!window.localStorage.getItem('isUserLogin') ? (
//       <Component {...props} />
//     ) : (
//       <Navigate to={{
//         pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/storeSelect' : '/dashboard',
//         state: {
//           from: props.location
//         }
//       }}
//       />
//     )
//     )}
//   />
// );
const PublicRoute = () => {
  const isAuthenticated = !window.localStorage.getItem('isUserLogin');
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/storeSelect' : '/dashboard' }} />
  );
};

export default PublicRoute;
