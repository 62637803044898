export const GET_TILES_BY_BRAND_REQUESTED = 'tile/GET_TILES_BY_BRAND_REQUESTED';
export const GET_TILES_BY_BRAND_SUCCESS = 'tile/GET_TILES_BY_BRAND_SUCCESS';
export const GET_TILES_BY_BRAND_FAILURE = 'tile/GET_TILES_BY_BRAND_FAILURE';

export const GET_TILES_BY_ID_REQUESTED = 'tile/GET_TILES_BY_ID_REQUESTED';
export const GET_TILES_BY_ID_SUCCESS = 'tile/GET_TILES_BY_ID_SUCCESS';
export const GET_TILES_BY_ID_FAILURE = 'tile/GET_TILES_BY_ID_FAILURE';

export const POST_ADD_UPDATE_FLYER_REQUESTED = 'tile/POST_ADD_UPDATE_FLYER_REQUESTED';
export const POST_ADD_UPDATE_FLYER_SUCCESS = 'tile/POST_ADD_UPDATE_FLYER_SUCCESS';
export const POST_ADD_UPDATE_FLYER_FAILURE = 'tile/POST_ADD_UPDATE_FLYER_FAILURE';

export const POST_DELETE_FLYER_REQUESTED = 'tile/POST_DELETE_FLYER_REQUESTED';
export const POST_DELETE_FLYER_SUCCESS = 'tile/POST_DELETE_FLYER_SUCCESS';
export const POST_DELETE_FLYER_FAILURE = 'tile/POST_DELETE_FLYER_FAILURE';

const GET_FLYER_TYPE_REQUESTED = 'tile/GET_FLYER_TYPE_REQUESTED';
const GET_FLYER_TYPE_SUCCESS = 'tile/GET_FLYER_TYPE_SUCCESS';
const GET_FLYER_TYPE_FAILURE = 'tile/GET_FLYER_TYPE_FAILURE';
const GET_APP_LINK_TYPE_SUCCESS = 'tile/GET_APP_LINK_TYPE_SUCCESS';

export const IMAGE_ACTION_REQUESTED = 'tile/IMAGE_ACTION_REQUESTED';
export const IMAGE_ACTION_SUCCESS = 'tile/IMAGE_ACTION_SUCCESS';
export const IMAGE_ACTION_FAILURE = 'tile/IMAGE_ACTION_FAILURE';

export const GET_TILE_TARGET_REQUESTED = 'tile/GET_TILE_TARGET_REQUESTED';
export const GET_TILE_TARGET_SUCCESS = 'tile/GET_TILE_TARGET_SUCCESS';
export const GET_TARGET_TYPE_FAILURE = 'tile/GET_TARGET_TYPE_FAILURE';

export const UPLOAD_ACTION_REQUESTED = 'tile/UPLOAD_ACTION_REQUESTED';
export const UPLOAD_ACTION_SUCCESS = 'tile/UPLOAD_ACTION_SUCCESS';
export const UPLOAD_ACTION_FAILURE = 'tile/UPLOAD_ACTION_FAILURE';

export const REORDER_TILES_REQUESTED = 'brand/REORDER_TILES_REQUESTED';
export const REORDER_TILES_SUCCESS = 'brand/REORDER_TILES_SUCCESS';
export const REORDER_TILES_FAILURE = 'brand/REORDER_TILES_FAILURE';

export const SAVE_CAROUSEL_IMAGES_REQUESTED = 'tile/SAVE_CAROUSEL_IMAGES_REQUESTED';
export const SAVE_CAROUSEL_IMAGES_SUCCESS = 'tile/SAVE_CAROUSEL_IMAGES_SUCCESS';
export const SAVE_CAROUSEL_IMAGES_FAILURE = 'tile/SAVE_CAROUSEL_IMAGES_FAILURE';

export const DELETE_CAROUSEL_IMAGE_REQUESTED = 'tile/DELETE_CAROUSEL_IMAGE_REQUESTED';
export const DELETE_CAROUSEL_IMAGE_SUCCESS = 'tile/DELETE_CAROUSEL_IMAGE_SUCCESS';
export const DELETE_CAROUSEL_IMAGE_FAILURE = 'tile/DELETE_CAROUSEL_IMAGE_FAILURE';

export const GET_ORDER_MENUS_LIST_REQUESTED = 'tile/GET_ORDER_MENUS_LIST_REQUESTED';
export const GET_ORDER_MENUS_LIST_SUCCESS = 'tile/GET_ORDER_MENUS_LIST_SUCCESS';
export const GET_ORDER_MENUS_LIST_FAILURE = 'tile/GET_ORDER_MENUS_LIST_FAILURE';

export const GET_BROWSE_MENUS_LIST_REQUESTED = 'tile/GET_BROWSE_MENUS_LIST_REQUESTED';
export const GET_BROWSE_MENUS_LIST_SUCCESS = 'tile/GET_BROWSE_MENUS_LIST_SUCCESS';
export const GET_BROWSE_MENUS_LIST_FAILURE = 'tile/GET_BROWSE_MENUS_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tilesListByStore: [],
  tileById: {},
  tileTypes: [],
  tileTargets: [],
  appLinkTypes: [],
  ordeMenusList: [],
  browseMenusList: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_TILES_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_TILES_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tilesListByStore: action.result,
      };
    }
    case GET_TILES_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        tilesListByStore: [],
      };
    }
    case GET_TILES_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        tileById: null,
      };
    }
    case GET_TILES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tileById: action.result,
      };
    }
    case GET_TILES_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        tileById: [],
      };
    }
    case POST_ADD_UPDATE_FLYER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case POST_ADD_UPDATE_FLYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ADD_UPDATE_FLYER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case POST_DELETE_FLYER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case POST_DELETE_FLYER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_DELETE_FLYER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_FLYER_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_FLYER_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tileTypes: action.result
      };
    }
    case GET_FLYER_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_APP_LINK_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        appLinkTypes: action.result
      };
    }
    case IMAGE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case IMAGE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TILE_TARGET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_TILE_TARGET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tileTargets: action.result
      };
    }
    case GET_TARGET_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case REORDER_TILES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REORDER_TILES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case REORDER_TILES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SAVE_CAROUSEL_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case SAVE_CAROUSEL_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_CAROUSEL_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_CAROUSEL_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case DELETE_CAROUSEL_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_CAROUSEL_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ORDER_MENUS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_ORDER_MENUS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ordeMenusList: action.result,
      };
    }
    case GET_ORDER_MENUS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        ordeMenusList: [],
      };
    }
    case GET_BROWSE_MENUS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_BROWSE_MENUS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        browseMenusList: action.result,
      };
    }
    case GET_BROWSE_MENUS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        browseMenusList: [],
      };
    }
    default:
      return state;
  }
};

export const getTilesByBrand = (storeId) => {
  return {
    types: [GET_TILES_BY_BRAND_REQUESTED, GET_TILES_BY_BRAND_SUCCESS, GET_TILES_BY_BRAND_FAILURE],
    promise: client => client.get(`getBrandTiles/${storeId}`)
  };
};

export const addOrUpdateBrandTile = (data, isAdd) => {
  let url = '';
  if (isAdd) {
    url = 'addUpdateBrandTile/add';
  } else {
    url = 'addUpdateBrandTile/update';
  }
  return {
    types: [POST_ADD_UPDATE_FLYER_REQUESTED, POST_ADD_UPDATE_FLYER_SUCCESS, POST_ADD_UPDATE_FLYER_FAILURE],
    promise: client => client.post(url, { data })
  };
};

export const deleteBrandTile = (tileId) => {
  return {
    types: [POST_DELETE_FLYER_REQUESTED, POST_DELETE_FLYER_SUCCESS, POST_DELETE_FLYER_FAILURE],
    promise: client => client.post(`deleteBrandTile/${tileId}`)
  };
};

export const getBrandTileById = (tileId) => {
  return {
    types: [GET_TILES_BY_ID_REQUESTED, GET_TILES_BY_ID_SUCCESS, GET_TILES_BY_ID_FAILURE],
    promise: client => client.get(`getBrandTileById/${tileId}`)
  };
};

export const getTileTypes = () => {
  return {
    types: [GET_FLYER_TYPE_REQUESTED, GET_FLYER_TYPE_SUCCESS, GET_FLYER_TYPE_FAILURE],
    promise: client => client.get('getTileTypes')
  };
};

export const getTileTargets = () => {
  return {
    types: [GET_TILE_TARGET_REQUESTED, GET_TILE_TARGET_SUCCESS, GET_TARGET_TYPE_FAILURE],
    promise: client => client.get('getTileTargets')
  };
};

export const getAppLinkTypes = () => {
  return {
    types: [GET_FLYER_TYPE_REQUESTED, GET_APP_LINK_TYPE_SUCCESS, GET_FLYER_TYPE_FAILURE],
    promise: client => client.get('getAppLinkTypes')
  };
};

export const uplaodTileImage = (id, data) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`tileImageSave/${id}/save`, { data })
  };
};

export const deleteTileImage = (id) => {
  return {
    types: [IMAGE_ACTION_REQUESTED, IMAGE_ACTION_SUCCESS, IMAGE_ACTION_FAILURE],
    promise: client => client.post(`tileImageDelete/${id}/delete`)
  };
};

export const uploadTileFile = (id, data) => {
  return {
    types: [UPLOAD_ACTION_REQUESTED, UPLOAD_ACTION_SUCCESS, UPLOAD_ACTION_FAILURE],
    promise: client => client.post(`uploadTilePdf/${id}/save`, { data })
  };
};

export const deleteTileFile = (id) => {
  return {
    types: [UPLOAD_ACTION_REQUESTED, UPLOAD_ACTION_SUCCESS, UPLOAD_ACTION_FAILURE],
    promise: client => client.post(`deleteTilePdf/${id}/delete`)
  };
};

export const reOrderTiles = (data) => {
  return {
    types: [REORDER_TILES_REQUESTED, REORDER_TILES_SUCCESS, REORDER_TILES_FAILURE],
    promise: client => client.post('sortedTiles', { data }),
  };
};

export const saveCarouselImages = (brandTileId, data) => {
  return {
    types: [SAVE_CAROUSEL_IMAGES_REQUESTED, SAVE_CAROUSEL_IMAGES_SUCCESS, SAVE_CAROUSEL_IMAGES_FAILURE],
    promise: client => client.post(`saveCarouselImages/${brandTileId}/save`, { data })
  };
};

export const deleteCarouselImage = (brandTileId, imageFileName, data) => {
  return {
    types: [DELETE_CAROUSEL_IMAGE_REQUESTED, DELETE_CAROUSEL_IMAGE_SUCCESS, DELETE_CAROUSEL_IMAGE_FAILURE],
    promise: client => client.post(`deleteCarouselImage/${brandTileId}/imageFileName/${imageFileName}/delete`, { data })
  };
};

export const getOrderMenusList = (brandId) => {
  return {
    types: [GET_ORDER_MENUS_LIST_REQUESTED, GET_ORDER_MENUS_LIST_SUCCESS, GET_ORDER_MENUS_LIST_FAILURE],
    promise: client => client.get(`getOrderMenusList/${brandId}`)
  };
};

export const getBrowseMenusList = (brandId) => {
  return {
    types: [GET_BROWSE_MENUS_LIST_REQUESTED, GET_BROWSE_MENUS_LIST_SUCCESS, GET_BROWSE_MENUS_LIST_FAILURE],
    promise: client => client.get(`getBrowseMenusList/${brandId}`)
  };
};
