export const ADD_TILE_SENSOR_GROUP_REQUESTED = 'tileSensorGroupSetUp/ADD_TILE_SENSOR_GROUP_REQUESTED';
export const ADD_TILE_SENSOR_GROUP_SUCCESS = 'tileSensorGroupSetUp/ADD_TILE_SENSOR_GROUP_SUCCESS';
export const ADD_TILE_SENSOR_GROUP_FAILURE = 'tileSensorGroupSetUp/ADD_TILE_SENSOR_GROUP_FAILURE';

export const GET_TILE_SENSOR_GROUPS_REQUESTED = 'tileSensorGroupSetUp/GET_TILE_SENSOR_GROUPS_REQUESTED';
export const GET_TILE_SENSOR_GROUPS_SUCCESS = 'tileSensorGroupSetUp/GET_TILE_SENSOR_GROUPS_SUCCESS';
export const GET_TILE_SENSOR_GROUPS_FAILURE = 'tileSensorGroupSetUp/GET_TILE_SENSOR_GROUPS_FAILURE';

export const DELETE_TILE_SENSOR_GROUPS_REQUESTED = 'tileSensorGroupSetUp/DELETE_TILE_SENSOR_GROUPS_REQUESTED';
export const DELETE_TILE_SENSOR_GROUPS_SUCCESS = 'tileSensorGroupSetUp/DELETE_TILE_SENSOR_GROUPS_SUCCESS';
export const DELETE_TILE_SENSOR_GROUPS_FAILURE = 'tileSensorGroupSetUp/DELETE_TILE_SENSOR_GROUPS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sensorGroupList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TILE_SENSOR_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TILE_SENSOR_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TILE_SENSOR_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TILE_SENSOR_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        sensorGroupList: [],
      };
    }
    case GET_TILE_SENSOR_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorGroupList: action.result,
      };
    }
    case GET_TILE_SENSOR_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        sensorGroupList: [],
      };
    }
    case DELETE_TILE_SENSOR_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TILE_SENSOR_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_TILE_SENSOR_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addTileSensorGroup = (data) => {
  return {
    types: [ADD_TILE_SENSOR_GROUP_REQUESTED, ADD_TILE_SENSOR_GROUP_SUCCESS, ADD_TILE_SENSOR_GROUP_FAILURE],
    promise: client => client.post('addTileSensorGroup', { data })
  };
};

export const getTileSensorGroup = (tileId) => {
  return {
    types: [GET_TILE_SENSOR_GROUPS_REQUESTED, GET_TILE_SENSOR_GROUPS_SUCCESS, GET_TILE_SENSOR_GROUPS_FAILURE],
    promise: client => client.get(`getTileSensorGroup/${tileId}`)
  };
};

export const deleteTileSensorGroup = (data) => {
  return {
    types: [DELETE_TILE_SENSOR_GROUPS_REQUESTED, DELETE_TILE_SENSOR_GROUPS_SUCCESS, DELETE_TILE_SENSOR_GROUPS_FAILURE],
    promise: client => client.post('deleteTileSensorGroup', { data })
  };
};
