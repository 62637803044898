export const GET_SMS_TEMPLATE_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_REQUESTED';
export const GET_SMS_TEMPLATE_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_SUCCESS';
export const GET_SMS_TEMPLATE_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_FAILURE';

export const GET_SMS_TEMPLATE_CUSTOMIZABLE_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_CUSTOMIZABLE_REQUESTED';
export const GET_SMS_TEMPLATE_CUSTOMIZABLE_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_CUSTOMIZABLE_SUCCESS';
export const GET_SMS_TEMPLATE_CUSTOMIZABLE_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_CUSTOMIZABLE_FAILURE';

export const GET_SMS_TEMPLATE_STATUS_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_STATUS_REQUESTED';
export const GET_SMS_TEMPLATE_STATUS_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_STATUS_SUCCESS';
export const GET_SMS_TEMPLATE_STATUS_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_STATUS_FAILURE';

export const GET_SMS_TEMPLATE_TYPES_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_TYPES_REQUESTED';
export const GET_SMS_TEMPLATE_TYPES_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_TYPES_SUCCESS';
export const GET_SMS_TEMPLATE_TYPES_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_TYPES_FAILURE';

export const GET_SMS_TEMPLATE_BY_ID_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_BY_ID_REQUESTED';
export const GET_SMS_TEMPLATE_BY_ID_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_BY_ID_SUCCESS';
export const GET_SMS_TEMPLATE_BY_ID_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_BY_ID_FAILURE';

export const GET_SMS_TEMPLATE_MERGE_FIELDS_REQUESTED = 'smsTemplate/GET_SMS_TEMPLATE_MERGE_FIELDS_REQUESTED';
export const GET_SMS_TEMPLATE_MERGE_FIELDS_SUCCESS = 'smsTemplate/GET_SMS_TEMPLATE_MERGE_FIELDS_SUCCESS';
export const GET_SMS_TEMPLATE_MERGE_FIELDS_FAILURE = 'smsTemplate/GET_SMS_TEMPLATE_MERGE_FIELDS_FAILURE';

export const ADD_SMS_TEMPLATE_REQUESTED = 'smsTemplate/ADD_SMS_TEMPLATE_REQUESTED';
export const ADD_SMS_TEMPLATE_SUCCESS = 'smsTemplate/ADD_SMS_TEMPLATE_SUCCESS';
export const ADD_SMS_TEMPLATE_FAILURE = 'smsTemplate/ADD_SMS_TEMPLATE_FAILURE';

export const DELETE_SMS_TEMPLATE_REQUESTED = 'smsTemplate/DELETE_SMS_TEMPLATE_REQUESTED';
export const DELETE_SMS_TEMPLATE_SUCCESS = 'smsTemplate/DELETE_SMS_TEMPLATE_SUCCESS';
export const DELETE_SMS_TEMPLATE_FAILURE = 'smsTemplate/DELETE_SMS_TEMPLATE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  smsTemplateList: null,
  smsCustomizableList: [],
  smTemplateUpdate: [],
  smsTemplateStatus: [],
  smsMergeField: [],
  smsTemplateById: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SMS_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        smsTemplateList: action.result,
      };
    }
    case GET_SMS_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SMS_TEMPLATE_CUSTOMIZABLE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_CUSTOMIZABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        smsCustomizableList: action.result,
      };
    }
    case GET_SMS_TEMPLATE_CUSTOMIZABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SMS_TEMPLATE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        smsTemplateStatus: action.result,
      };
    }
    case GET_SMS_TEMPLATE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SMS_TEMPLATE_MERGE_FIELDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_SMS_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        smsTemplateById: action.result
      };
    }
    case GET_SMS_TEMPLATE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_SMS_TEMPLATE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        smsTypesList: action.result
      };
    }
    case GET_SMS_TEMPLATE_TYPES_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_TEMPLATE_MERGE_FIELDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        smsMergeField: action.result,
      };
    }
    case GET_SMS_TEMPLATE_MERGE_FIELDS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_SMS_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_SMS_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SMS_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_SMS_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getSMSTemplateList = (smsTemplateId) => {
  return {
    types: [GET_SMS_TEMPLATE_REQUESTED, GET_SMS_TEMPLATE_SUCCESS, GET_SMS_TEMPLATE_FAILURE],
    promise: client => client.get(`smsTemplateList/${smsTemplateId}`)
  };
};

export const getSMSCustomizableList = () => {
  return {
    types: [GET_SMS_TEMPLATE_CUSTOMIZABLE_REQUESTED, GET_SMS_TEMPLATE_CUSTOMIZABLE_SUCCESS, GET_SMS_TEMPLATE_CUSTOMIZABLE_FAILURE],
    promise: client => client.get('smsCustomizable')
  };
};

export const getSMSTypesList = () => {
  return {
    types: [GET_SMS_TEMPLATE_TYPES_REQUESTED, GET_SMS_TEMPLATE_TYPES_SUCCESS, GET_SMS_TEMPLATE_TYPES_FAILURE],
    promise: client => client.get('smsTypes')
  };
};

export const getSMSTemplateStatus = () => {
  return {
    types: [GET_SMS_TEMPLATE_STATUS_REQUESTED, GET_SMS_TEMPLATE_STATUS_SUCCESS, GET_SMS_TEMPLATE_STATUS_FAILURE],
    promise: client => client.get('smsTemplateStatus')
  };
};

export const getSMSTemplateById = (id) => {
  return {
    types: [GET_SMS_TEMPLATE_BY_ID_REQUESTED, GET_SMS_TEMPLATE_BY_ID_SUCCESS, GET_SMS_TEMPLATE_BY_ID_FAILURE],
    promise: client => client.get(`smsTemplateById/${id}`)
  };
};

export const getSMSMergeFields = (id) => {
  return {
    types: [GET_SMS_TEMPLATE_MERGE_FIELDS_REQUESTED, GET_SMS_TEMPLATE_MERGE_FIELDS_SUCCESS, GET_SMS_TEMPLATE_MERGE_FIELDS_FAILURE],
    promise: client => client.get(`smsMergeFields/${id}`)
  };
};

export const deleteSMSTemplate = (smsTemplateId, data) => {
  return {
    types: [DELETE_SMS_TEMPLATE_REQUESTED, DELETE_SMS_TEMPLATE_SUCCESS, DELETE_SMS_TEMPLATE_FAILURE],
    promise: client => client.post(`deleteSmsTemplate/${smsTemplateId}`, { data })
  };
};

export const addSMSTemplate = (data) => {
  return {
    types: [ADD_SMS_TEMPLATE_REQUESTED, ADD_SMS_TEMPLATE_SUCCESS, ADD_SMS_TEMPLATE_FAILURE],
    promise: client => client.post('addSmsTemplate', { data })
  };
};

export const updateSMSTemplate = (data) => {
  return {
    types: [ADD_SMS_TEMPLATE_REQUESTED, ADD_SMS_TEMPLATE_SUCCESS, ADD_SMS_TEMPLATE_FAILURE],
    promise: client => client.post('updateSmsTemplate', { data })
  };
};
