export const GET_PAYMENT_SYSTEMS_GUESTAPP_REQUESTED = 'paymentsystem/GET_PAYMENT_SYSTEMS_GUESTAPP_REQUESTED';
export const GET_PAYMENT_SYSTEMS_GUESTAPP_SUCCESS = 'paymentsystem/GET_PAYMENT_SYSTEMS_GUESTAPP_SUCCESS';
export const GET_PAYMENT_SYSTEMS_GUESTAPP_FAILURE = 'paymentsystem/GET_PAYMENT_SYSTEMS_GUESTAPP_FAILURE';

export const GET_PAYMENT_SYSTEMS_SERVERAPP_REQUESTED = 'paymentsystem/GET_PAYMENT_SYSTEMS_SERVERAPP_REQUESTED';
export const GET_PAYMENT_SYSTEMS_SERVERAPP_SUCCESS = 'paymentsystem/GET_PAYMENT_SYSTEMS_SERVERAPP_SUCCESS';
export const GET_PAYMENT_SYSTEMS_SERVERAPP_FAILURE = 'paymentsystem/GET_PAYMENT_SYSTEMS_SERVERAPP_FAILURE';

export const GET_PAYMENT_CONFIG_REQUESTED = 'paymentsystem/GET_PAYMENT_CONFIG_REQUESTED';
export const GET_PAYMENT_CONFIG_SUCCESS = 'paymentsystem/GET_PAYMENT_CONFIG_SUCCESS';
export const GET_PAYMENT_CONFIG_FAILURE = 'paymentsystem/GET_PAYMENT_CONFIG_FAILURE';

export const UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED = 'paymentsystem/UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED';
export const UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS = 'paymentsystem/UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS';
export const UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE = 'paymentsystem/UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getPaymentGuest: [],
  getPaymentServer: [],
  getPayConfig: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_SYSTEMS_GUESTAPP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_SYSTEMS_GUESTAPP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPaymentGuest: action.result,
      };
    }
    case GET_PAYMENT_SYSTEMS_GUESTAPP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PAYMENT_SYSTEMS_SERVERAPP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_SYSTEMS_SERVERAPP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPaymentServer: action.result,
      };
    }
    case GET_PAYMENT_SYSTEMS_SERVERAPP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PAYMENT_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPayConfig: action.result,
      };
    }
    case GET_PAYMENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_POS_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPaymentSystemGuestApp = () => {
  return {
    types: [GET_PAYMENT_SYSTEMS_GUESTAPP_REQUESTED, GET_PAYMENT_SYSTEMS_GUESTAPP_SUCCESS, GET_PAYMENT_SYSTEMS_GUESTAPP_FAILURE],
    promise: client => client.get('getPaymentSystemsGuestApp')
  };
};

export const getPaymentSystemServerApp = () => {
  return {
    types: [GET_PAYMENT_SYSTEMS_SERVERAPP_REQUESTED, GET_PAYMENT_SYSTEMS_SERVERAPP_SUCCESS, GET_PAYMENT_SYSTEMS_SERVERAPP_FAILURE],
    promise: client => client.get('getPaymentSystemsServerApp')
  };
};

export const getPaymentConfig = (storeId) => {
  return {
    types: [GET_PAYMENT_CONFIG_REQUESTED, GET_PAYMENT_CONFIG_SUCCESS, GET_PAYMENT_CONFIG_FAILURE],
    promise: client => client.get(`getPaymentSystemConfig/${storeId}`)
  };
};

export const updateStorePaymentConfig = (storeId, data) => {
  return {
    types: [GET_PAYMENT_CONFIG_REQUESTED, GET_PAYMENT_CONFIG_SUCCESS, GET_PAYMENT_CONFIG_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfig/${storeId}`, { data })
  };
};
