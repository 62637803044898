const currentReducer = 'cubby';
export const GET_CUBBY_REQUESTED = `${currentReducer}/GET_CUBBY_REQUESTED`;
export const GET_CUBBY_SUCCESS = `${currentReducer}/GET_CUBBY_SUCCESS`;
export const GET_CUBBY_FAILURE = `${currentReducer}/GET_CUBBY_FAILURE`;

export const ADD_CUBBY_REQUESTED = `${currentReducer}/ADD_CUBBY_REQUESTED`;
export const ADD_CUBBY_SUCCESS = `${currentReducer}/ADD_CUBBY_SUCCESS`;
export const ADD_CUBBY_FAILURE = `${currentReducer}/ADD_CUBBY_FAILURE`;

export const GET_CUBBY_BY_ID_REQUESTED = `${currentReducer}/GET_CUBBY_BY_ID_REQUESTED`;
export const GET_CUBBY_BY_ID_SUCCESS = `${currentReducer}/GET_CUBBY_BY_ID_SUCCESS`;
export const GET_CUBBY_BY_ID_FAILURE = `${currentReducer}/GET_CUBBY_BY_ID_FAILURE`;

export const UPDATE_CUBBY_REQUESTED = `${currentReducer}/UPDATE_CUBBY_REQUESTED`;
export const UPDATE_CUBBY_SUCCESS = `${currentReducer}/UPDATE_CUBBY_SUCCESS`;
export const UPDATE_CUBBY_FAILURE = `${currentReducer}/UPDATE_CUBBY_FAILURE`;

export const ADD_CUBBY_SLOT_REQUESTED = `${currentReducer}/ADD_CUBBY_SLOT_REQUESTED`;
export const ADD_CUBBY_SLOT_SUCCESS = `${currentReducer}/ADD_CUBBY_SLOT_SUCCESS`;
export const ADD_CUBBY_SLOT_FAILURE = `${currentReducer}/ADD_CUBBY_SLOT_FAILURE`;

export const UPDATE_CUBBY_SLOT_REQUESTED = `${currentReducer}/UPDATE_CUBBY_SLOT_REQUESTED`;
export const UPDATE_CUBBY_SLOT_SUCCESS = `${currentReducer}/UPDATE_CUBBY_SLOT_SUCCESS`;
export const UPDATE_CUBBY_SLOT_FAILURE = `${currentReducer}/UPDATE_CUBBY_SLOT_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  cubbyList: [],
  cubbyListById: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUBBY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CUBBY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cubbyList: action.result
      };
    }
    case GET_CUBBY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_CUBBY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_CUBBY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cubbyList: action.result
      };
    }
    case UPDATE_CUBBY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_CUBBY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_CUBBY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cubbyList: action.result
      };
    }
    case ADD_CUBBY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_CUBBY_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_CUBBY_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cubbyListById: action.result
      };
    }
    case GET_CUBBY_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_CUBBY_SLOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_CUBBY_SLOT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CUBBY_SLOT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_CUBBY_SLOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_CUBBY_SLOT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CUBBY_SLOT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getCubby = (storeId) => {
  return {
    types: [GET_CUBBY_REQUESTED, GET_CUBBY_SUCCESS, GET_CUBBY_FAILURE],
    promise: client => client.get(`getCubby/${storeId}`)
  };
};

export const addCubby = (data) => {
  return {
    types: [ADD_CUBBY_REQUESTED, ADD_CUBBY_SUCCESS, ADD_CUBBY_FAILURE],
    promise: client => client.post('addCubby', { data })
  };
};

export const getCubbyById = (id) => {
  return {
    types: [GET_CUBBY_BY_ID_REQUESTED, GET_CUBBY_BY_ID_SUCCESS, GET_CUBBY_BY_ID_FAILURE],
    promise: client => client.get(`getCubbyById/${id}`)
  };
};

export const updateCubby = (data) => {
  return {
    types: [UPDATE_CUBBY_REQUESTED, UPDATE_CUBBY_SUCCESS, UPDATE_CUBBY_FAILURE],
    promise: client => client.post('updateCubby', { data })
  };
};

export const addSlots = (data) => {
  return {
    types: [ADD_CUBBY_SLOT_REQUESTED, ADD_CUBBY_SLOT_SUCCESS, ADD_CUBBY_SLOT_FAILURE],
    promise: client => client.post('addSlot', { data })
  };
};

export const updateSlots = (data) => {
  return {
    types: [UPDATE_CUBBY_SLOT_REQUESTED, UPDATE_CUBBY_SLOT_SUCCESS, UPDATE_CUBBY_SLOT_FAILURE],
    promise: client => client.post('updateSlot', { data })
  };
};
