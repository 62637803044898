export const GET_POWERCARD_REQUESTED = 'brand/GET_POWERCARD_REQUESTED';
export const GET_POWERCARD_SUCCESS = 'brand/GET_POWERCARD_SUCCESS';
export const GET_POWERCARD_FAILURE = 'brand/GET_POWERCARD_FAILURE';

export const UPDATE_POWERCARD_REQUESTED = 'brand/UPDATE_POWERCARD_REQUESTED';
export const UPDATE_POWERCARD_SUCCESS = 'brand/UPDATE_POWERCARD_SUCCESS';
export const UPDATE_POWERCARD_FAILURE = 'brand/UPDATE_POWERCARD_FAILURE';

export const GET_POWERCARDMOD_REQUESTED = 'brand/GET_POWERCARDMOD_REQUESTED';
export const GET_POWERCARDMOD_SUCCESS = 'brand/GET_POWERCARDMOD_SUCCESS';
export const GET_POWERCARDMOD_FAILURE = 'brand/GET_POWERCARDMOD_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  powercardList: null,
  powercardModList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_POWERCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        powercardList: null,
      };
    }
    case GET_POWERCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        powercardList: action.result,
      };
    }
    case GET_POWERCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_POWERCARDMOD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POWERCARDMOD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        powercardModList: action.result,
      };
    }
    case GET_POWERCARDMOD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_POWERCARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_POWERCARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_POWERCARD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getPowercard = (brandId) => {
  return {
    types: [GET_POWERCARD_REQUESTED, GET_POWERCARD_SUCCESS, GET_POWERCARD_FAILURE],
    promise: client => client.get(`getPowerCardConfig/${brandId}`)
  };
};

export const updatePowercard = (brandId, data) => {
  return {
    types: [UPDATE_POWERCARD_REQUESTED, UPDATE_POWERCARD_SUCCESS, UPDATE_POWERCARD_FAILURE],
    promise: client => client.post(`updatePowerCardConfig/${brandId}`, { data })
  };
};

export const getPowercardMod = (brandId) => {
  return {
    types: [GET_POWERCARDMOD_REQUESTED, GET_POWERCARDMOD_SUCCESS, GET_POWERCARDMOD_FAILURE],
    promise: client => client.get(`getAllModifiersByBrandId/${brandId}`)
  };
};
