

import orderstatus from '@assets/serverappscreen/orderstatus.png';
import OrderUpCubby from '@assets/serverappscreen/OrderUpCubby.png';

export default {
  LandingPage: {
    screenLabel: 'OrderUp(Cubby)',
    image: [OrderUpCubby],
    customLabels: [
      {
        defaultText: 'ORDERS READY FOR PICKUP',
        key: 'customMessage.ORDERS_READY_FOR_PICKUP',
        type: 'customMessage',
        value: 'ORDERS_READY_FOR_PICKUP',
      },
    ]
  },
  OrderStatus: {
    screenLabel: 'Order Status',
    image: [orderstatus],
    customLabels: [

      {
        defaultText: 'ORDER STATUS',
        key: 'customMessage.ORDER_STATUS',
        type: 'customMessage',
        value: 'ORDER_STATUS',
      },
      {
        defaultText: 'PREPARING',
        key: 'label.ORDERUP_PREPARING',
        type: 'label',
        value: 'ORDERUP_PREPARING',
      },
      {
        defaultText: 'READY',
        key: 'label.ORDERUP_READY',
        type: 'label',
        value: 'ORDERUP_READY',
      },
      {
        defaultText: 'SEE COUNTER',
        key: 'label.ORDERUP_ SEE_COUNTER',
        type: 'label',
        value: 'ORDERUP_ SEE_COUNTER',
      },
    ]
  },
};
