export const GET_BATCH_EXECUTION_REQUESTED = 'batchservices/GET_STORES_REQUESTED';
export const GET_BATCH_EXECUTION_SUCCESS = 'batchservices/GET_STORES_SUCCESS';
export const GET_BATCH_EXECUTION_FAILURE = 'batchservices/GET_STORES_FAILURE';

export const BATCH_EXECUTION_ACTIVATION_REQUESTED = 'batchservices/BATCH_EXECUTION_ACTIVATION_REQUESTED';
export const BATCH_EXECUTION_ACTIVATION_SUCCESS = 'batchservices/BATCH_EXECUTION_ACTIVATION_SUCCESS';
export const BATCH_EXECUTION_ACTIVATION_FAILURE = 'batchservices/BATCH_EXECUTIOyaN_ACTIVATION_FAILURE';

export const BATCH_EXECUTION_RUN_REQUESTED = 'batchservices/BATCH_EXECUTION_RUN_REQUESTED';
export const BATCH_EXECUTION_RUN_SUCCESS = 'batchservices/BATCH_EXECUTION_RUN_SUCCESS';
export const BATCH_EXECUTION_RUN_FAILURE = 'batchservices/BATCH_EXECUTIOyaN_RUN_FAILURE';

export const BATCH_CYCLE_SERVICE_REQUESTED = 'batchservices/BATCH_CYCLE_SERVICE_REQUESTED';
export const BATCH_CYCLE_SERVICE_SUCCESS = 'batchservices/BATCH_CYCLE_SERVICE_SUCCESS';
export const BATCH_CYCLE_SERVICE_FAILURE = 'batchservices/BATCH_CYCLE_SERVICE_FAILURE';

export const SETUP_BATCH_EXECUTION_REQUESTED = 'batchservices/SETUP_STORES_REQUESTED';
export const SETUP_BATCH_EXECUTION_SUCCESS = 'batchservices/SETUP_STORES_SUCCESS';
export const SETUP_BATCH_EXECUTION_FAILURE = 'batchservices/SETUP_STORES_FAILURE';

export const GET_BRAND_BATCH_EXECUTION_REQUESTED = 'batchservices/GET_BRAND_BATCH_EXECUTION_REQUESTED';
export const GET_BRAND_BATCH_EXECUTION_SUCCESS = 'batchservices/GET_BRAND_BATCH_EXECUTION_SUCCESS';
export const GET_BRAND_BATCH_EXECUTION_FAILURE = 'batchservices/GET_BRAND_BATCH_EXECUTION_FAILURE';

export const BRAND_BATCH_EXECUTION_ACTIVATION_REQUESTED = 'batchservices/BRAND_BATCH_EXECUTION_ACTIVATION_REQUESTED';
export const BRAND_BATCH_EXECUTION_ACTIVATION_SUCCESS = 'batchservices/BRAND_BATCH_EXECUTION_ACTIVATION_SUCCESS';
export const BRAND_BATCH_EXECUTION_ACTIVATION_FAILURE = 'batchservices/BRAND_BATCH_EXECUTION_ACTIVATION_FAILURE';

export const BRAND_BATCH_EXECUTION_RUN_REQUESTED = 'batchservices/BRAND_BATCH_EXECUTION_RUN_REQUESTED';
export const BRAND_BATCH_EXECUTION_RUN_SUCCESS = 'batchservices/BRAND_BATCH_EXECUTION_RUN_SUCCESS';
export const BRAND_BATCH_EXECUTION_RUN_FAILURE = 'batchservices/BRAND_BATCH_EXECUTION_RUN_FAILURE';

export const GET_BRAND_BATCH_TYPES_REQUESTED = 'batchservices/GET_BRAND_BATCH_TYPES_REQUESTED';
export const GET_BRAND_BATCH_TYPES_SUCCESS = 'batchservices/GET_BRAND_BATCH_TYPES_SUCCESS';
export const GET_BRAND_BATCH_TYPES_FAILURE = 'batchservices/GET_BRAND_BATCH_TYPES_FAILURE';

export const GET_BRAND_BATCH_STATUSES_REQUESTED = 'batchservices/GET_BRAND_BATCH_STATUSES_REQUESTED';
export const GET_BRAND_BATCH_STATUSES_SUCCESS = 'batchservices/GET_BRAND_BATCH_STATUSES_SUCCESS';
export const GET_BRAND_BATCH_STATUSES_FAILURE = 'batchservices/GET_BRAND_BATCH_STATUSES_FAILURE';

export const UPDATE_BRAND_BATCH_SERVICE = 'batchservices/UPDATE_BRAND_BATCH_SERVICE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getBatchService: [],
  getBrandBatchService: [],
  getBatchTypes: [],
  getBatchStatuses: [],
  tempData: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BATCH_EXECUTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BATCH_EXECUTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchService: action.result,
      };
    }
    case GET_BATCH_EXECUTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getBatchService: [],
      };
    }
    case BATCH_EXECUTION_ACTIVATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BATCH_EXECUTION_ACTIVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchService: action.result,
      };
    }
    case BATCH_EXECUTION_ACTIVATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case BRAND_BATCH_EXECUTION_ACTIVATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BRAND_BATCH_EXECUTION_ACTIVATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandBatchService: action.result,
      };
    }
    case BRAND_BATCH_EXECUTION_ACTIVATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case BATCH_EXECUTION_RUN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BATCH_EXECUTION_RUN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchService: action.result,
      };
    }
    case BATCH_EXECUTION_RUN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case BRAND_BATCH_EXECUTION_RUN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BRAND_BATCH_EXECUTION_RUN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandBatchService: action.result,
      };
    }
    case BRAND_BATCH_EXECUTION_RUN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case BATCH_CYCLE_SERVICE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BATCH_CYCLE_SERVICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // BatchServiceActivation: action.result,
      };
    }
    case BATCH_CYCLE_SERVICE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SETUP_BATCH_EXECUTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SETUP_BATCH_EXECUTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchService: action.result,
      };
    }
    case SETUP_BATCH_EXECUTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getBatchService: []
      };
    }
    case GET_BRAND_BATCH_EXECUTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_BATCH_EXECUTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBrandBatchService: action.result,
      };
    }
    case GET_BRAND_BATCH_EXECUTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getBrandBatchService: [],
      };
    }
    case GET_BRAND_BATCH_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_BATCH_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchTypes: action.result,
      };
    }
    case GET_BRAND_BATCH_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_BATCH_STATUSES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_BATCH_STATUSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBatchStatuses: action.result,
      };
    }
    case GET_BRAND_BATCH_STATUSES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_BATCH_SERVICE: {
      return {
        ...state,
        // getBrandBatchService: action.payload,
        tempData: action.payload,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBatchExecution = (storeId) => {
  return {
    types: [GET_BATCH_EXECUTION_REQUESTED, GET_BATCH_EXECUTION_SUCCESS, GET_BATCH_EXECUTION_FAILURE],
    promise: client => client.get(`getBatchExecutions/${storeId}`)
  };
};

export const batchExecutionActivation = (data) => {
  return {
    types: [BATCH_EXECUTION_ACTIVATION_REQUESTED, BATCH_EXECUTION_ACTIVATION_SUCCESS, BATCH_EXECUTION_ACTIVATION_FAILURE],
    promise: client => client.post('batchExecutionActivation', { data })
  };
};

export const brandBatchExecutionActivation = (data) => {
  return {
    types: [BRAND_BATCH_EXECUTION_ACTIVATION_REQUESTED, BRAND_BATCH_EXECUTION_ACTIVATION_SUCCESS, BRAND_BATCH_EXECUTION_ACTIVATION_FAILURE],
    promise: client => client.post('batchExecutionActivation', { data })
  };
};

export const batchExecutionRun = (data) => {
  return {
    types: [BATCH_EXECUTION_RUN_REQUESTED, BATCH_EXECUTION_RUN_SUCCESS, BATCH_EXECUTION_RUN_FAILURE],
    promise: client => client.post('batchExecutionRun', { data })
  };
};

export const brandBatchExecutionRun = (data) => {
  return {
    types: [BRAND_BATCH_EXECUTION_RUN_REQUESTED, BRAND_BATCH_EXECUTION_RUN_SUCCESS, BRAND_BATCH_EXECUTION_RUN_FAILURE],
    promise: client => client.post('batchExecutionRun', { data })
  };
};

export const batchCycleService = () => {
  return {
    types: [BATCH_CYCLE_SERVICE_REQUESTED, BATCH_CYCLE_SERVICE_SUCCESS, BATCH_CYCLE_SERVICE_FAILURE],
    promise: client => client.post('batchCycleService')
  };
};

export const setupBatchException = (storeId) => {
  return {
    types: [SETUP_BATCH_EXECUTION_REQUESTED, SETUP_BATCH_EXECUTION_SUCCESS, SETUP_BATCH_EXECUTION_FAILURE],
    promise: client => client.post(`setupBatchException/${storeId}`)
  };
};

export const getBrandBatchExecution = (brandId) => {
  return {
    types: [GET_BRAND_BATCH_EXECUTION_REQUESTED, GET_BRAND_BATCH_EXECUTION_SUCCESS, GET_BRAND_BATCH_EXECUTION_FAILURE],
    promise: client => client.get(`getBrandBatchExecutions/${brandId}`)
  };
};

export const getBrandBatchTypes = () => {
  return {
    types: [GET_BRAND_BATCH_TYPES_REQUESTED, GET_BRAND_BATCH_TYPES_SUCCESS, GET_BRAND_BATCH_TYPES_FAILURE],
    promise: client => client.get('getBatchTypes')
  };
};

export const getBrandBatchStatus = () => {
  return {
    types: [GET_BRAND_BATCH_STATUSES_REQUESTED, GET_BRAND_BATCH_STATUSES_SUCCESS, GET_BRAND_BATCH_STATUSES_FAILURE],
    promise: client => client.get('getBatchStatuses')
  };
};

export const updateBatchServiceData = (data) => {
  return {
    type: UPDATE_BRAND_BATCH_SERVICE,
    payload: data,
  };
};
