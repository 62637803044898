const currentReducer = 'store-employee';
const GET_EMPLOYEE_REQUESTED = `${currentReducer}/GET_EMPLOYEE_REQUESTED`;
const GET_EMPLOYEE_SUCCESS = `${currentReducer}/GET_EMPLOYEE_SUCCESS`;
const GET_EMPLOYEE_FAILURE = `${currentReducer}/GET_EMPLOYEE_FAILURE`;

const GET_EMPLOYEE_DETAILS_REQUESTED = `${currentReducer}/GET_EMPLOYEE_DETAILS_REQUESTED`;
const GET_EMPLOYEE_DETAILS_SUCCESS = `${currentReducer}/GET_EMPLOYEE_DETAILS_SUCCESS`;
const GET_EMPLOYEE_DETAILS_FAILURE = `${currentReducer}/GET_EMPLOYEE_DETAILS_FAILURE`;

const ADD_EMPLOYEE_REQUESTED = `${currentReducer}/ADD_EMPLOYEE_REQUESTED`;
const ADD_EMPLOYEE_SUCCESS = `${currentReducer}/ADD_EMPLOYEE_SUCCESS`;
const ADD_EMPLOYEE_FAILURE = `${currentReducer}/ADD_EMPLOYEE_FAILURE`;

const UPDATE_EMPLOYEE_REQUESTED = `${currentReducer}/UPDATE_EMPLOYEE_REQUESTED`;
const UPDATE_EMPLOYEE_SUCCESS = `${currentReducer}/UPDATE_EMPLOYEE_SUCCESS`;
const UPDATE_EMPLOYEE_FAILURE = `${currentReducer}/UPDATE_EMPLOYEE_FAILURE`;

const GET_LINK_MEMBERS_BY_STOREID_REQUESTED = `${currentReducer}/GET_LINK_MEMBERS_BY_STOREID_REQUESTED`;
const GET_LINK_MEMBERS_BY_STOREID_SUCCESS = `${currentReducer}/GET_LINK_MEMBERS_BY_STOREID_SUCCESS`;
const GET_LINK_MEMBERS_BY_STOREID_FAILURE = `${currentReducer}/GET_LINK_MEMBERS_BY_STOREID_FAILURE`;

const UPDATE_EMPLOYEE_UNLINK_REQUESTED = `${currentReducer}/UPDATE_EMPLOYEE_UNLINK_REQUESTED`;
const UPDATE_EMPLOYEE_UNLINK_SUCCESS = `${currentReducer}/UPDATE_EMPLOYEE_UNLINK_SUCCESS`;
const UPDATE_EMPLOYEE_UNLINK_FAILURE = `${currentReducer}/UPDATE_EMPLOYEE_UNLINK_FAILURE`;

const UPDATE_EMPLOYEE_LINK_REQUESTED = `${currentReducer}/UPDATE_EMPLOYEE_LINK_REQUESTED`;
const UPDATE_EMPLOYEE_LINK_SUCCESS = `${currentReducer}/UPDATE_EMPLOYEE_LINK_SUCCESS`;
const UPDATE_EMPLOYEE_LINK_FAILURE = `${currentReducer}/UPDATE_EMPLOYEE_LINK_FAILURE`;

const GET_MANAGER_EMPLOYEE_REQUESTED = `${currentReducer}/GET_MANAGER_EMPLOYEE_REQUESTED`;
const GET_MANAGER_EMPLOYEE_SUCCESS = `${currentReducer}/GET_MANAGER_EMPLOYEE_SUCCESS`;
const GET_MANAGER_EMPLOYEE_FAILURE = `${currentReducer}/GET_MANAGER_EMPLOYEE_FAILURE`;


const GET_MANAGER_LINK_MEMBERS_BY_STOREID_REQUESTED = `${currentReducer}/GET_MANAGER_LINK_MEMBERS_BY_STOREID_REQUESTED`;
const GET_MANAGER_LINK_MEMBERS_BY_STOREID_SUCCESS = `${currentReducer}/GET_MANAGER_LINK_MEMBERS_BY_STOREID_SUCCESS`;
const GET_MANAGER_LINK_MEMBERS_BY_STOREID_FAILURE = `${currentReducer}/GET_MANAGER_LINK_MEMBERS_BY_STOREID_FAILURE`;

const ADD_MANAGER_EMPLOYEE_REQUESTED = `${currentReducer}/ADD_MANAGER_EMPLOYEE_REQUESTED`;
const ADD_MANAGER_EMPLOYEE_SUCCESS = `${currentReducer}/ADD_MANAGER_EMPLOYEE_SUCCESS`;
const ADD_MANAGER_EMPLOYEE_FAILURE = `${currentReducer}/ADD_MANAGER_EMPLOYEE_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  employeeList: [],
  employeeDetails: {},
  linkMembersByStoreId: [],
  manageremployeeList: [],
  manageremployeeDetails: {},
  managerlinkMembersByStoreId: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        employeeList: []
      };
    }
    case GET_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        employeeList: action.result
      };
    }
    case GET_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_EMPLOYEE_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        employeeDetails: {}
      };
    }
    case GET_EMPLOYEE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        employeeDetails: action.result
      };
    }
    case GET_EMPLOYEE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_LINK_MEMBERS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        employeeDetails: {}
      };
    }
    case GET_LINK_MEMBERS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        linkMembersByStoreId: action.result
      };
    }
    case GET_LINK_MEMBERS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_EMPLOYEE_LINK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_LINK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_EMPLOYEE_UNLINK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_UNLINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_EMPLOYEE_UNLINK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MANAGER_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        manageremployeeList: []
      };
    }
    case GET_MANAGER_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        manageremployeeList: action.result
      };
    }
    case GET_MANAGER_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MANAGER_LINK_MEMBERS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        manageremployeeDetails: {}
      };
    }
    case GET_MANAGER_LINK_MEMBERS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        managerlinkMembersByStoreId: action.result
      };
    }
    case GET_MANAGER_LINK_MEMBERS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_MANAGER_EMPLOYEE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_MANAGER_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MANAGER_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};


export const getStoreEmployees = (storeId) => {
  return {
    types: [GET_EMPLOYEE_REQUESTED, GET_EMPLOYEE_SUCCESS, GET_EMPLOYEE_FAILURE],
    promise: client => client.get(`getEmployeesByStoreId/${storeId}`)
  };
};

export const getEmployeeDetails = (employeId) => {
  return {
    types: [GET_EMPLOYEE_DETAILS_REQUESTED, GET_EMPLOYEE_DETAILS_SUCCESS, GET_EMPLOYEE_DETAILS_FAILURE],
    promise: client => client.get(`getEmployeeById/${employeId}`)
  };
};

export const addEmployeeDetails = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: client => client.post('addEmployee', { data })
  };
};

export const updateEmployeeDetails = (data) => {
  return {
    types: [UPDATE_EMPLOYEE_REQUESTED, UPDATE_EMPLOYEE_SUCCESS, UPDATE_EMPLOYEE_FAILURE],
    promise: client => client.post('updateEmployee', { data })
  };
};

export const getLinkMembers = (storeId) => {
  return {
    types: [GET_LINK_MEMBERS_BY_STOREID_REQUESTED, GET_LINK_MEMBERS_BY_STOREID_SUCCESS, GET_LINK_MEMBERS_BY_STOREID_FAILURE],
    promise: client => client.get(`getLinkMembers/${storeId}`)
  };
};

export const updateEmployeeUnLink = (employeeId) => {
  return {
    types: [UPDATE_EMPLOYEE_LINK_REQUESTED, UPDATE_EMPLOYEE_LINK_SUCCESS, UPDATE_EMPLOYEE_LINK_FAILURE],
    promise: client => client.post(`unlinkEmployee/${employeeId}`)
  };
};

export const updateEmployeeLink = (employeeId, memberId) => {
  return {
    types: [UPDATE_EMPLOYEE_LINK_REQUESTED, UPDATE_EMPLOYEE_LINK_SUCCESS, UPDATE_EMPLOYEE_LINK_FAILURE],
    promise: client => client.post(`updateEmployeeLink/${employeeId}/memberId/${memberId}`)
  };
};
export const getManagerStoreEmployees = (storeId) => {
  return {
    types: [GET_MANAGER_EMPLOYEE_REQUESTED, GET_EMPLOYEE_SUCCESS, GET_EMPLOYEE_FAILURE],
    promise: client => client.get(`getManagerEmployeesByStoreId/${storeId}`)
  };
};
export const getManagerLinkMembers = (storeId) => {
  return {
    types: [GET_MANAGER_LINK_MEMBERS_BY_STOREID_REQUESTED, GET_MANAGER_LINK_MEMBERS_BY_STOREID_SUCCESS, GET_MANAGER_LINK_MEMBERS_BY_STOREID_FAILURE],
    promise: client => client.get(`getManagerLinkMembers/${storeId}`)
  };
};
export const addManagerEmployeeDetails = (data) => {
  return {
    types: [ADD_MANAGER_EMPLOYEE_REQUESTED, ADD_MANAGER_EMPLOYEE_SUCCESS, ADD_MANAGER_EMPLOYEE_FAILURE],
    promise: client => client.post('addManagerEmployee', { data })
  };
};
