const currentReducer = 'cache';
const CACHE_REQUESTED = `${currentReducer}/CACHE_REQUESTED`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isCached: false
};

export default(state = initialState, action) => {
  switch (action.type) {
    case CACHE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        isCached: action.payload
      };
    }
    default:
      return state;
  }
};

export const loadDataFromCache = (isCached) => {
  return {
    type: CACHE_REQUESTED,
    payload: isCached
  };
};
