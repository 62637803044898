export const GET_CHECK_BY_ID_REQUESTED = 'reports/GET_CHECK_BY_ID_REQUESTED';
export const GET_CHECK_BY_ID_SUCCESS = 'reports/GET_CHECK_BY_ID_SUCCESS';
export const GET_CHECK_BY_ID_FAILURE = 'reports/GET_CHECK_BY_ID_FAILURE';

export const SYNC_REQUESTED = 'reports/SYNC_REQUESTED';
export const SYNC_SUCCESS = 'reports/SYNC_SUCCESS';
export const SYNC_FAILURE = 'reports/SYNC_FAILURE';

export const CLOSE_REQUESTED = 'reports/CLOSE_REQUESTED';
export const CLOSE_SUCCESS = 'reports/CLOSE_SUCCESS';
export const CLOSE_FAILURE = 'reports/CLOSE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  checkById: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_CHECK_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CHECK_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkById: action.result,
      };
    }
    case GET_CHECK_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        checkById: []
      };
    }
    case SYNC_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SYNC_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkById: action.result,
      };
    }
    case SYNC_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        checkById: []
      };
    }
    case CLOSE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CLOSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkById: action.result,
      };
    }
    case CLOSE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        checkById: []
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getCheckById = (checkHeaderId) => {
  return {
    types: [GET_CHECK_BY_ID_REQUESTED, GET_CHECK_BY_ID_SUCCESS, GET_CHECK_BY_ID_FAILURE],
    promise: client => client.get(`getCheckById/${checkHeaderId}`)
  };
};

export const syncCheckDetail = (checkHeaderId) => {
  return {
    types: [SYNC_REQUESTED, SYNC_SUCCESS, SYNC_FAILURE],
    promise: client => client.post(`sync/${checkHeaderId}`)
  };
};

export const closeCheckDetail = (checkHeaderId) => {
  return {
    types: [CLOSE_REQUESTED, CLOSE_SUCCESS, CLOSE_FAILURE],
    promise: client => client.post(`close/${checkHeaderId}`)
  };
};
