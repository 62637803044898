export const GET_COMPANIES_REQUESTED = 'companyFilter/GET_COMPANIES_REQUESTED';
export const GET_COMPANIES_SUCCESS = 'companyFilter/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'companyFilter/GET_COMPANIES_FAILURE';

export const GET_BRANDS_BY_COMPANY_ID_REQUESTED = 'companyFilter/GET_BRANDS_BY_COMPANY_ID_REQUESTED';
export const GET_BRANDS_BY_COMPANY_ID_SUCCESS = 'companyFilter/GET_BRANDS_BY_COMPANY_ID_SUCCESS';
export const GET_BRANDS_BY_COMPANY_ID_FAILURE = 'companyFilter/GET_BRANDS_BY_COMPANY_ID_FAILURE';

export const GET_STORES_BY_BRAND_ID_REQUESTED = 'companyFilter/GET_STORES_BY_BRAND_ID_REQUESTED';
export const GET_STORES_BY_BRAND_ID_SUCCESS = 'companyFilter/GET_STORES_BY_BRAND_ID_SUCCESS';
export const GET_STORES_BY_BRAND_ID_FAILURE = 'companyFilter/GET_STORES_BY_BRAND_ID_FAILURE';

export const GET_SURVEY_LIST_REQUESTED = 'companyFilter/GET_SURVEY_LIST_REQUESTED';
export const GET_SURVEY_LIST_SUCCESS = 'companyFilter/GET_SURVEY_LIST_SUCCESS';
export const GET_SURVEY_LIST_FAILURE = 'companyFilter/GET_SURVEY_LIST_FAILURE';

export const GET_SENSOR_LIST_REQUESTED = 'companyFilter/GET_SENSOR_LIST_REQUESTED';
export const GET_SENSOR_LIST_SUCCESS = 'companyFilter/GET_SENSOR_LIST_SUCCESS';
export const GET_SENSOR_LIST_FAILURE = 'companyFilter/GET_SENSOR_LIST_FAILURE';

export const GET_API_LOG_SOURCE_TYPES_REQUESTED = 'companyFilter/GET_API_LOG_SOURCE_TYPES_REQUESTED';
export const GET_API_LOG_SOURCE_TYPES_SUCCESS = 'companyFilter/GET_API_LOG_SOURCE_TYPES_SUCCESS';
export const GET_API_LOG_SOURCE_TYPES_FAILURE = 'companyFilter/GET_API_LOG_SOURCE_TYPES_FAILURE';

export const SET_SELECTED_STORES = 'companyFilter/SET_SELECTED_STORES';
export const SET_REPORT_INPUTS = 'companyFilter/SET_REPORT_INPUTS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  companyList: [],
  brandList: [],
  storeList: [],
  surveyList: [],
  sensorList: [],
  selectedStores: [],
  reportInputs: {},
  apiLogsSourceTypes: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyList: action.result,
        brandList: [],
        storeList: [],
      };
    }
    case GET_COMPANIES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRANDS_BY_COMPANY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRANDS_BY_COMPANY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandList: action.result,
        storeList: [],
      };
    }
    case GET_BRANDS_BY_COMPANY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORES_BY_BRAND_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORES_BY_BRAND_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeList: action.result,
      };
    }
    case GET_STORES_BY_BRAND_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SURVEY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveyList: action.result,
      };
    }
    case GET_SURVEY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SENSOR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SENSOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sensorList: action.result,
      };
    }
    case GET_SENSOR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SET_SELECTED_STORES: {
      return {
        ...state,
        selectedStores: action.payload
      };
    }
    case SET_REPORT_INPUTS: {
      return {
        ...state,
        reportInputs: action.payload,
      };
    }
    case GET_API_LOG_SOURCE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_API_LOG_SOURCE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        apiLogsSourceTypes: action.result,
      };
    }
    case GET_API_LOG_SOURCE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getCompanies = (data) => {
  return {
    types: [GET_COMPANIES_REQUESTED, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE],
    promise: client => client.get('getCompanies/', { data })
  };
};

export const getBrandsByCompanyId = (companyId) => {
  return {
    types: [GET_BRANDS_BY_COMPANY_ID_REQUESTED, GET_BRANDS_BY_COMPANY_ID_SUCCESS, GET_BRANDS_BY_COMPANY_ID_FAILURE],
    promise: client => client.get(`getBrandsByCompanyId/${companyId}`)
  };
};

export const getStoresByBrandId = (brandId) => {
  return {
    types: [GET_STORES_BY_BRAND_ID_REQUESTED, GET_STORES_BY_BRAND_ID_SUCCESS, GET_STORES_BY_BRAND_ID_FAILURE],
    promise: client => client.get(`getStoresByBrandId/${brandId}`)
  };
};

export const setSelectedStores = (stores) => {
  return {
    type: SET_SELECTED_STORES,
    payload: stores
  };
};

export const setReportInputs = (data) => {
  return {
    type: SET_REPORT_INPUTS,
    payload: data,
  };
};


export const getSurveryList = (brandId) => {
  return {
    types: [GET_SURVEY_LIST_REQUESTED, GET_SURVEY_LIST_SUCCESS, GET_SURVEY_LIST_FAILURE],
    promise: client => client.get(`getSurveyByBrandId/${brandId}`)
  };
};

export const getSensorList = () => {
  return {
    types: [GET_SENSOR_LIST_REQUESTED, GET_SENSOR_LIST_SUCCESS, GET_SENSOR_LIST_FAILURE],
    promise: client => client.get('sensorList')
  };
};

export const getAPILogSourceTypes = () => {
  return {
    types: [GET_API_LOG_SOURCE_TYPES_REQUESTED, GET_API_LOG_SOURCE_TYPES_SUCCESS, GET_API_LOG_SOURCE_TYPES_FAILURE],
    promise: client => client.get('apiLogSourceTypes')
  };
};
