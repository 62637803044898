const currentReducer = 'SPARKFLY_SETUP';
const GET_SPARKFLY_SETUP_REQUESTED = `${currentReducer}/GET_SPARKFLY_SETUP_REQUESTED`;
const GET_SPARKFLY_SETUP_SUCCESS = `${currentReducer}/GET_SPARKFLY_SETUP_SUCCESS`;
const GET_SPARKFLY_SETUP_FAILURE = `${currentReducer}/GET_SPARKFLY_SETUP_FAILURE`;

const UPDATE_SPARKFLY_SETUP_REQUESTED = `${currentReducer}/UPDATE_SPARKFLY_SETUP_REQUESTED`;
const UPDATE_SPARKFLY_SETUP_SUCCESS = `${currentReducer}/UPDATE_SPARKFLY_SETUP_SUCCESS`;
const UPDATE_SPARKFLY_SETUP_FAILURE = `${currentReducer}/UPDATE_SPARKFLY_SETUP_FAILURE`;

const GET_KEYBOARD_STYLES_REQUESTED = `${currentReducer}/GET_KEYBOARD_STYLES_REQUESTED`;
const GET_KEYBOARD_STYLES_SUCCESS = `${currentReducer}/GET_KEYBOARD_STYLES_SUCCESS`;
const GET_KEYBOARD_STYLES_FAILURE = `${currentReducer}/GET_KEYBOARD_STYLES_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sparkFlyData: [],
  keyBoardStylesData: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SPARKFLY_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        sparkFlyData: []
      };
    }
    case GET_SPARKFLY_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sparkFlyData: action.result
      };
    }
    case GET_SPARKFLY_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_SPARKFLY_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_SPARKFLY_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sparkFlyData: action.result
      };
    }
    case UPDATE_SPARKFLY_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_KEYBOARD_STYLES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        keyBoardStylesData: []
      };
    }
    case GET_KEYBOARD_STYLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        keyBoardStylesData: action.result
      };
    }
    case GET_KEYBOARD_STYLES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};


export const getSparkFlySetup = (id) => {
  return {
    types: [GET_SPARKFLY_SETUP_REQUESTED, GET_SPARKFLY_SETUP_SUCCESS, GET_SPARKFLY_SETUP_FAILURE],
    promise: client => client.get(`getSparkFlySetup/${id}`)
  };
};

export const updateSparkFlySetup = (data, id) => {
  return {
    types: [UPDATE_SPARKFLY_SETUP_REQUESTED, UPDATE_SPARKFLY_SETUP_SUCCESS, UPDATE_SPARKFLY_SETUP_FAILURE],
    promise: client => client.post(`updateSparkFlySetup/${id}`, { data })
  };
};

export const getKeyboardStyles = () => {
  return {
    types: [GET_KEYBOARD_STYLES_REQUESTED, GET_KEYBOARD_STYLES_SUCCESS, GET_KEYBOARD_STYLES_FAILURE],
    promise: client => client.get('keyboardStyles')
  };
};
