

import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Route
} from 'react-router-dom';
import { APP_CONFIG } from '@constants';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import withRouter from './withRouter';
import OnRouteChange from '@containers/common/OnRouteChange';
import PageLoader from '@components/common/PageLoader';
import NotFound from '@components/404NotFound/index';
import { lazyRetry } from './utility/helperFunction';
import Notification from '@components/common/notification/indexV4';


const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
        Sorry, unable to load the page
      </div>
    );
  }
  return null;
};

const OnRouteChangeWithRouter = withRouter(OnRouteChange);
const BasicLayout = () => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <OnRouteChangeWithRouter>
        <Notification />
        <Outlet />
      </OnRouteChangeWithRouter>
    </Suspense>
  );
};

const Login = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Login')),
));

const ManagerDashboard = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard')),
));

const ManagerLoginFailed = withRouter(lazy(
  () => lazyRetry(() => import('@components/storeManager/failureLoginPage')),
));

const StorePhone = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard/storeManagerPhone')),
));

const LoginWithApiKey = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Login/loginWithApiKey')),
));

const ForgotPassword = withRouter(lazy(
  () => lazyRetry(() => import('@containers/ForgotPassword')),
));

const V4Component = withRouter(lazy(
  () => lazyRetry(() => import('@containers/V4')),
));

const EmpApp = withRouter(lazy(
  () => lazyRetry(() => import('@containers/EmpApp')),
));

const PaymentRequest = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard/paymentRequest')),
));

const Receipt = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Receipt')),
));

const AppDownloadLink = withRouter(lazy(
  () => lazyRetry(() => import('@containers/appDownloadUrl')),
));

const OrderApproval = withRouter(lazy(
  () => lazyRetry(() => import('@containers/OrderApproval')),
));

const GiftCardReceipt = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Receipt/giftCardReceipt')),
));

const DisplayStoreOrder = withRouter(lazy(
  () => lazyRetry(() => import('@containers/DisplayStoreOrder')),
));

const ApiLogsDetails = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Reports/ApiLogDetails')),
));

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route key={`${APP_CONFIG.BASE_URL}/`} path={`${APP_CONFIG.BASE_URL}/`} element={<BasicLayout />}>
      <Route path={`${APP_CONFIG.BASE_URL}/`} element={<PublicRoute />}>
        {/* Public routes */}
        <Route index key={`${APP_CONFIG.BASE_URL}/`} path={`${APP_CONFIG.BASE_URL}/`} element={<Login />} />
        <Route index key={`${APP_CONFIG.BASE_URL}/login`} path={`${APP_CONFIG.BASE_URL}/login`} element={<Login />} />
        <Route key={`${APP_CONFIG.BASE_URL}/manager/dashboard`} path={`${APP_CONFIG.BASE_URL}/manager/dashboard`} element={<ManagerDashboard />} />
        <Route key={`${APP_CONFIG.BASE_URL}/login-falied`} path={`${APP_CONFIG.BASE_URL}/login-falied`} element={<ManagerLoginFailed />} />
        <Route key={`${APP_CONFIG.BASE_URL}/store-manaager/phone`} path={`${APP_CONFIG.BASE_URL}/store-manaager/phone`} element={<StorePhone />} />
        <Route key={`${APP_CONFIG.BASE_URL}/store-manaager/text-to-pay`} path={`${APP_CONFIG.BASE_URL}/store-manaager/text-to-pay`} element={<PaymentRequest />} />
        <Route key={`${APP_CONFIG.BASE_URL}/token/apiKey/:apiKey`} path={`${APP_CONFIG.BASE_URL}/token/apiKey/:apiKey`} element={<LoginWithApiKey />} />
        <Route key={`${APP_CONFIG.BASE_URL}/forgotpassword`} path={`${APP_CONFIG.BASE_URL}/forgotpassword`} element={<ForgotPassword />} />
      </Route>
      <Route key={`${APP_CONFIG.BASE_URL}/download`} path={`${APP_CONFIG.BASE_URL}/download`} element={<AppDownloadLink />} />
      <Route key={`${APP_CONFIG.BASE_URL}/empApp/*`} path={`${APP_CONFIG.BASE_URL}/empApp/*`} element={<EmpApp />} />
      <Route key={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} element={<Receipt />} />
      <Route key={`${APP_CONFIG.BASE_URL}/apiLogsDetails/:apilogId/:requestDate`} path={`${APP_CONFIG.BASE_URL}/apiLogsDetails/:apilogId/:requestDate`} element={<ApiLogsDetails />} />
      <Route key={`${APP_CONFIG.BASE_URL}/order/display/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/order/display/:referenceCode`} element={<DisplayStoreOrder />} />
      <Route key={`${APP_CONFIG.BASE_URL}/orders/seat/approvalRequest/:checkHeaderId/:referenceCode/:seatId`} path={`${APP_CONFIG.BASE_URL}/orders/seat/approvalRequest/:checkHeaderId/:referenceCode/:seatId`} element={<OrderApproval />} />
      <Route key={`${APP_CONFIG.BASE_URL}/:receiptType/giftCard/display/:ticketId/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/:receiptType/giftCard/display/:ticketId/:referenceCode`} element={<GiftCardReceipt />} />
      <Route path={`${APP_CONFIG.BASE_URL}/`} element={<PrivateRoute />}>
        {/* Protected routes */}
        <Route path="*" element={<V4Component />} />
        {/* Add more protected routes here */}
      </Route>
      <Route key="*" path="*" element={<NotFound />} />
    </Route>

  </>
));
export default (
  <div className="container-fluid p-0">
    <RouterProvider router={router} />
  </div>
);

// import React from 'react';
// import {
//   BrowserRouter as Router,
//   Route,
//   Redirect,
//   Switch
// } from 'react-router-dom';
// import Loadable from 'react-loadable';
// import PageLoader from '@components/common/PageLoader';
// import { APP_CONFIG } from '@constants';
// import Login from '@containers/Login';
// import OnRouteChange from '@containers/common/OnRouteChange';
// import managerDashboard from '@containers/managerDashboard';
// import managerLoginFailed from '@components/storeManager/failureLoginPage';
// import StorePhone from '@containers/managerDashboard/storeManagerPhone';
// import LoginWithApiKey from '@containers/Login/loginWithApiKey';
// import ForgotPassword from '@containers/ForgotPassword';


// const PrivateRoute = ({ component: Component, props, ...rest }) => {
//   const userLogin = (formProps) => {
//     if (window.localStorage.getItem('isUserLogin')) {
//       return (<Component {...props} {...formProps} />);
//     }
//     return (
//       <Redirect to={{
//         pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/login' : '/login',
//         state: {
//           from: rest.location
//         }
//       }}
//       />
//     );
//   };
//   return (
//     <Route
//       {...rest}
//       render={formProps => userLogin(formProps)}
//     />);
// };

// const PublicRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props => (!window.localStorage.getItem('isUserLogin') ? (
//       <Component {...props} />
//     ) : (
//       <Redirect to={{
//         pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/storeSelect' : '/dashboard',
//         state: {
//           from: props.location
//         }
//       }}
//       />
//     )
//     )}
//   />
// );


// const LoadingComponent = ({ isLoading, isError }) => {
//   if (isLoading) {
//     return <PageLoader />;
//   }
//   if (isError) {
//     return (
//       <div>
//           Sorry, unable to load the page
//       </div>
//     );
//   }
//   return null;
// };

// const V4Component = Loadable({
//   loader: () => import('@containers/V4'),
//   loading: LoadingComponent,
// });

// const EmpApp = Loadable({
//   loader: () => import('@containers/EmpApp'),
//   loading: LoadingComponent,
// });

// const PaymentRequest = Loadable({
//   loader: () => import('@containers/managerDashboard/paymentRequest'),
//   loading: LoadingComponent,
// });

// const Receipt = Loadable({
//   loader: () => import('@containers/Receipt'),
//   loading: LoadingComponent,
// });

// const AppDownloadLink = Loadable({
//   loader: () => import('@containers/appDownloadUrl'),
//   loading: LoadingComponent,
// });

// const OrderApproval = Loadable({
//   loader: () => import('@containers/OrderApproval'),
//   loading: LoadingComponent,
// });

// const GiftCardReceipt = Loadable({
//   loader: () => import('@containers/Receipt/giftCardReceipt'),
//   loading: LoadingComponent,
// });

// const DisplayStoreOrder = Loadable({
//   loader: () => import('@containers/DisplayStoreOrder'),
//   loading: LoadingComponent,
// });

// const ApiLogsDetails = Loadable({
//   loader: () => import('@containers/Reports/ApiLogDetails'),
//   loading: LoadingComponent,
// });


// export default (
//   <Router>
//     <OnRouteChange>
//       <Switch>
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/`} component={Login} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/manager/dashboard`} component={managerDashboard} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/login-falied`} component={managerLoginFailed} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/store-manaager/phone`} component={StorePhone} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/store-manaager/text-to-pay`} component={PaymentRequest} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/token/apiKey/:apiKey`} component={LoginWithApiKey} />
//         <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/forgotpassword`} component={ForgotPassword} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/download`} component={AppDownloadLink} />
//         <Route path={`${APP_CONFIG.BASE_URL}/empApp`} component={EmpApp} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} component={Receipt} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/apiLogsDetails/:apilogId/:requestDate`} component={ApiLogsDetails} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/order/display/:referenceCode`} component={DisplayStoreOrder} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/download`} component={AppDownloadLink} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/orders/seat/approvalRequest/:checkHeaderId/:referenceCode/:seatId`} component={OrderApproval} />
//         <Route exact path={`${APP_CONFIG.BASE_URL}/:receiptType/giftCard/display/:ticketId/:referenceCode`} component={GiftCardReceipt} />
//         <PrivateRoute path={`${APP_CONFIG.BASE_URL}/`} component={V4Component} />
//       </Switch>
//     </OnRouteChange>
//   </Router>
// );
