export const GET_CAMPAIGN_TRIGGER_LIST_DATA_REQUESTED = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_LIST_DATA_REQUESTED';
export const GET_CAMPAIGN_TRIGGER_LIST_DATA_SUCCESS = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_LIST_DATA_SUCCESS';
export const GET_CAMPAIGN_TRIGGER_LIST_DATA_FAILURE = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_LIST_DATA_FAILURE';

export const ADD_CAMPAIGN_TRIGGER_REQUESTED = 'campaignTriggers/ADD_CAMPAIGN_TRIGGER_REQUESTED';
export const ADD_CAMPAIGN_TRIGGER_SUCCESS = 'campaignTriggers/ADD_CAMPAIGN_TRIGGER_SUCCESS';
export const ADD_CAMPAIGN_TRIGGER_FAILURE = 'campaignTriggers/ADD_CAMPAIGN_TRIGGER_FAILURE';

export const UPDATE_CAMPAIGN_TRIGGER_REQUESTED = 'campaignTriggers/UPDATE_CAMPAIGN_TRIGGER_REQUESTED';
export const UPDATE_CAMPAIGN_TRIGGER_SUCCESS = 'campaignTriggers/UPDATE_CAMPAIGN_TRIGGER_SUCCESS';
export const UPDATE_CAMPAIGN_TRIGGER_FAILURE = 'campaignTriggers/UPDATE_CAMPAIGN_TRIGGER_FAILURE';

export const GET_CAMPAIGN_TRIGGER_BYID_REQUESTED = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_BYID_REQUESTED';
export const GET_CAMPAIGN_TRIGGER_BYID_SUCCESS = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_BYID_SUCCESS';
export const GET_CAMPAIGN_TRIGGER_BYID_FAILURE = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_BYID_FAILURE';

const DELETE_CAMPAIGN_TRIGGER_REQUESTED = 'campaignTriggers/DELETE_CAMPAIGN_TRIGGER_REQUESTED';
const DELETE_CAMPAIGN_TRIGGER_SUCCESS = 'campaignTriggers/DELETE_CAMPAIGN_TRIGGER_SUCCESS';
const DELETE_CAMPAIGN_TRIGGER_FAILURE = 'campaignTriggers/DELETE_CAMPAIGN_TRIGGER_FAILURE';

export const GET_CAMPAIGN_TRIGGER_OCCURENCE_REQUESTED = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_OCCURENCE_REQUESTED';
export const GET_CAMPAIGN_TRIGGER_OCCURENCE_SUCCESS = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_OCCURENCE_SUCCESS';
export const GET_CAMPAIGN_TRIGGER_OCCURENCE_FAILURE = 'campaignTriggers/GET_CAMPAIGN_TRIGGER_OCCURENCE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getCampaignTriggersList: [],
  campaignTriggerById: null,
  campaignTriggerOccurrenceList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_TRIGGER_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_TRIGGER_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getCampaignTriggersList: action.result,
      };
    }
    case GET_CAMPAIGN_TRIGGER_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_CAMPAIGN_TRIGGER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CAMPAIGN_TRIGGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CAMPAIGN_TRIGGER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CAMPAIGN_TRIGGER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CAMPAIGN_TRIGGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CAMPAIGN_TRIGGER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CAMPAIGN_TRIGGER_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_TRIGGER_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignTriggerById: action.result,
      };
    }
    case GET_CAMPAIGN_TRIGGER_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_TRIGGER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CAMPAIGN_TRIGGER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_CAMPAIGN_TRIGGER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_CAMPAIGN_TRIGGER_OCCURENCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_TRIGGER_OCCURENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignTriggerOccurrenceList: action.result,
      };
    }
    case GET_CAMPAIGN_TRIGGER_OCCURENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
export const getCamapignTriggerList = (brandId) => {
  return {
    types: [GET_CAMPAIGN_TRIGGER_LIST_DATA_REQUESTED, GET_CAMPAIGN_TRIGGER_LIST_DATA_SUCCESS, GET_CAMPAIGN_TRIGGER_LIST_DATA_FAILURE],
    promise: client => client.get(`getCamapignTrigger/${brandId}`)
  };
};

export const addCampaignTrigger = (data) => {
  return {
    types: [ADD_CAMPAIGN_TRIGGER_REQUESTED, ADD_CAMPAIGN_TRIGGER_SUCCESS, ADD_CAMPAIGN_TRIGGER_FAILURE],
    promise: client => client.post('addCampaignTrigger', { data })
  };
};

export const updateCampaignTrigger = (data) => {
  return {
    types: [UPDATE_CAMPAIGN_TRIGGER_REQUESTED, UPDATE_CAMPAIGN_TRIGGER_SUCCESS, UPDATE_CAMPAIGN_TRIGGER_FAILURE],
    promise: client => client.post('updateCampaignTrigger', { data })
  };
};

export const getCampaignTriggerById = (id) => {
  return {
    types: [GET_CAMPAIGN_TRIGGER_BYID_REQUESTED, GET_CAMPAIGN_TRIGGER_BYID_SUCCESS, GET_CAMPAIGN_TRIGGER_BYID_FAILURE],
    promise: client => client.get(`campaignTriggerById/${id}`)
  };
};

export const deleteCampaignTrigger = (id) => {
  return {
    types: [DELETE_CAMPAIGN_TRIGGER_REQUESTED, DELETE_CAMPAIGN_TRIGGER_SUCCESS, DELETE_CAMPAIGN_TRIGGER_FAILURE],
    promise: client => client.post(`deleteCampaignTrigger/${id}`)
  };
};

export const getCampaignTriggerOccurence = (brandId) => {
  return {
    types: [GET_CAMPAIGN_TRIGGER_OCCURENCE_REQUESTED, GET_CAMPAIGN_TRIGGER_OCCURENCE_SUCCESS, GET_CAMPAIGN_TRIGGER_OCCURENCE_FAILURE],
    promise: client => client.get(`campaignTriggerOccurence/${brandId}/triggerMethod/2`)
  };
};
