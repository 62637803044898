export const POST_ADD_MENU_DEVICE_GROUP_REQUESTED = 'menuDeviceGroupSetUp/POST_ADD_MENU_DEVICE_GROUP_REQUESTED';
export const POST_ADD_MENU_DEVICE_GROUP_SUCCESS = 'menuDeviceGroupSetUp/POST_ADD_MENU_DEVICE_GROUP_SUCCESS';
export const POST_ADD_MENU_DEVICE_GROUP_FAILURE = 'menuDeviceGroupSetUp/POST_ADD_MENU_DEVICE_GROUP_FAILURE';

export const GET_DEVICE_GROUPS_REQUESTED = 'menuDeviceGroupSetUp/GET_DEVICE_GROUPS_REQUESTED';
export const GET_DEVICE_GROUPS_SUCCESS = 'menuDeviceGroupSetUp/GET_DEVICE_GROUPS_SUCCESS';
export const GET_DEVICE_GROUPS_FAILURE = 'menuDeviceGroupSetUp/GET_DEVICE_GROUPS_FAILURE';

export const POST_DELETE_DEVICE_GROUPS_REQUESTED = 'menuDeviceGroupSetUp/POST_DELETE_DEVICE_GROUPS_REQUESTED';
export const POST_DELETE_DEVICE_GROUPS_SUCCESS = 'menuDeviceGroupSetUp/POST_DELETE_DEVICE_GROUPS_SUCCESS';
export const POST_DELETE_DEVICE_GROUPS_FAILURE = 'menuDeviceGroupSetUp/POST_DELETE_DEVICE_GROUPS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  deviceGroupList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_MENU_DEVICE_GROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ADD_MENU_DEVICE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ADD_MENU_DEVICE_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DEVICE_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DEVICE_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deviceGroupList: action.result,
      };
    }
    case GET_DEVICE_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        deviceGroupList: [],
      };
    }
    case POST_DELETE_DEVICE_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_DELETE_DEVICE_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_DELETE_DEVICE_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addMenuDeviceGroup = (data) => {
  return {
    types: [POST_ADD_MENU_DEVICE_GROUP_REQUESTED, POST_ADD_MENU_DEVICE_GROUP_SUCCESS, POST_ADD_MENU_DEVICE_GROUP_FAILURE],
    promise: client => client.post('addMenuDeviceGroup', { data })
  };
};

export const getMenuDeviceGroup = (menuId) => {
  return {
    types: [GET_DEVICE_GROUPS_REQUESTED, GET_DEVICE_GROUPS_SUCCESS, GET_DEVICE_GROUPS_FAILURE],
    promise: client => client.get(`getMenuDeviceGroup/${menuId}`)
  };
};

export const deleteMenuDeviceGroup = (data) => {
  return {
    types: [POST_DELETE_DEVICE_GROUPS_REQUESTED, POST_DELETE_DEVICE_GROUPS_SUCCESS, POST_DELETE_DEVICE_GROUPS_FAILURE],
    promise: client => client.post('deleteMenuDeviceGroup', { data })
  };
};
