const currentReducer = 'screenSchedule';
const GET_SCHEDULE_REQUESTED = `${currentReducer}/GET_SCHEDULE_REQUESTED`;
const GET_SCHEDULE_SUCCESS = `${currentReducer}/GET_SCHEDULE_SUCCESS`;
const GET_SCHEDULE_FAILURE = `${currentReducer}/GET_SCHEDULE_FAILURE`;

const ADD_SCREEN_SCHEDULE_REQUESTED = 'screenSchedule/ADD_SCREEN_SCHEDULE_REQUESTED';
const ADD_SCREEN_SCHEDULE_SUCCESS = 'screenSchedule/ADD_SCREEN_SCHEDULE_SUCCESS';
const ADD_SCREEN_SCHEDULE_FAILURE = 'screenSchedule/ADD_SCREEN_SCHEDULE_FAILURE';

const UPDATE_SCREEN_SCHEDULE_REQUESTED = 'screenSchedule/UPDATE_SCREEN_SCHEDULE_REQUESTED';
const UPDATE_SCREEN_SCHEDULE_SUCCESS = 'screenSchedule/UPDATE_SCREEN_SCHEDULE_SUCCESS';
const UPDATE_SCREEN_SCHEDULE_FAILURE = 'screenSchedule/UPDATE_SCREEN_SCHEDULE_FAILURE';

const DELETE_SCREEN_SCHEDULE_REQUESTED = 'screenSchedule/DELETE_SCREEN_SCHEDULE_REQUESTED';
const DELETE_SCREEN_SCHEDULE_SUCCESS = 'screenSchedule/DELETE_SCREEN_SCHEDULE_SUCCESS';
const DELETE_SCREEN_SCHEDULE_FAILURE = 'screenSchedule/DELETE_SCREEN_SCHEDULE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  screenScheduleList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        screenScheduleList: action.result
      };
    }
    case GET_SCHEDULE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_SCREEN_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SCREEN_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_SCREEN_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_SCREEN_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SCREEN_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_SCREEN_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_SCREEN_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SCREEN_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_SCREEN_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getScreenSchedule = (screenId) => {
  return {
    types: [GET_SCHEDULE_REQUESTED, GET_SCHEDULE_SUCCESS, GET_SCHEDULE_FAILURE],
    promise: client => client.get(`getMenuScreenScheduleByMenuId/${screenId}`)
  };
};

export const addScreenSchedule = (data, screenId) => {
  return {
    types: [ADD_SCREEN_SCHEDULE_REQUESTED, ADD_SCREEN_SCHEDULE_SUCCESS, ADD_SCREEN_SCHEDULE_FAILURE],
    promise: client => client.post(`addMenuScreenSchedule/${screenId}`, { data })
  };
};

export const updateScreenSchedule = (data, screenId) => {
  return {
    types: [UPDATE_SCREEN_SCHEDULE_REQUESTED, UPDATE_SCREEN_SCHEDULE_SUCCESS, UPDATE_SCREEN_SCHEDULE_FAILURE],
    promise: client => client.post(`updateMenuScreenSchedule/${screenId}`, { data })
  };
};

export const deleteScreenSchedule = (scheduleId) => {
  return {
    types: [DELETE_SCREEN_SCHEDULE_REQUESTED, DELETE_SCREEN_SCHEDULE_SUCCESS, DELETE_SCREEN_SCHEDULE_FAILURE],
    promise: client => client.post(`deleteMenuSchedule/${scheduleId}`)
  };
};
