const GET_POSMENU_LIST_STORE_REQUESTED = 'posMenu/GET_POSMENU_LIST_STORE_REQUESTED';
const GET_POSMENU_LIST_STORE_SUCCESS = 'posMenu/GET_POSMENU_LIST_STORE_SUCCESS';
const GET_POSMENU_LIST_STORE_FAILURE = 'posMenu/GET_POSMENU_LIST_STORE_FAILURE';

const ENABLE_DISABLE_POSMENU_REQUESTED = 'posMenu/ENABLE_DISABLE_POSMENU_REQUESTED';
const ENABLE_DISABLE_POSMENU_SUCCESS = 'posMenu/ENABLE_DISABLE_POSMENU_SUCCESS';
const ENABLE_DISABLE_POSMENU_FAILURE = 'posMenu/ENABLE_DISABLE_POSMENU_FAILURE';

const DISABLE_POSMENU_REQUESTED = 'posMenu/DISABLE_POSMENU_REQUESTED';
const DISABLE_POSMENU_SUCCESS = 'posMenu/DISABLE_POSMENU_SUCCESS';
const DISABLE_POSMENU_FAILURE = 'posMenu/DISABLE_POSMENU_FAILURE';

const GET_MENU_SCHEDULE_REQUSTED = 'posMenu/GET_MENU_SCHEDULE_REQUSTED';
const GET_MENU_SCHEDULE_SUCCESS = 'posMenu/GET_MENU_SCHEDULE_SUCCESS';
const GET_MENU_SCHEDULE_FAILURE = 'posMenu/GET_MENU_SCHEDULE_FAILURE';

const ADD_MENU_SCHEDULE_REQUESTED = 'posMenu/ADD_MENU_SCHEDULE_REQUESTED';
const ADD_MENU_SCHEDULE_SUCCESS = 'posMenu/ADD_MENU_SCHEDULE_SUCCESS';
const ADD_MENU_SCHEDULE_FAILURE = 'posMenu/ADD_MENU_SCHEDULE_FAILURE';

const UPDATE_MENU_SCHEDULE_REQUESTED = 'posMenu/UPDATE_MENU_SCHEDULE_REQUESTED';
const UPDATE_MENU_SCHEDULE_SUCCESS = 'posMenu/UPDATE_MENU_SCHEDULE_SUCCESS';
const UPDATE_MENU_SCHEDULE_FAILURE = 'posMenu/UPDATE_MENU_SCHEDULE_FAILURE';

const DELETE_MENU_SCHEDULE_REQUESTED = 'posMenu/DELETE_MENU_SCHEDULE_REQUESTED';
const DELETE_MENU_SCHEDULE_SUCCESS = 'posMenu/DELETE_MENU_SCHEDULE_SUCCESS';
const DELETE_MENU_SCHEDULE_FAILURE = 'posMenu/DELETE_MENU_SCHEDULE_FAILURE';

const UPDATE_POS_MENUS_BY_STOREID_REQUESTED = 'posMenu/UPDATE_POS_MENUS_BY_STOREID_REQUESTED';
const UPDATE_POS_MENUS_BY_STOREID_SUCCESS = 'posMenu/UPDATE_POS_MENUS_BY_STOREID_SUCCESS';
const UPDATE_POS_MENUS_BY_STOREID_FAILURE = 'posMenu/UPDATE_POS_MENUS_BY_STOREID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  posMenuList: [],
  menuScheduleList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POSMENU_LIST_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_POSMENU_LIST_STORE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        posMenuList: action.result,
      };
    }
    case GET_POSMENU_LIST_STORE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        posMenuList: [],
      };
    }
    case ENABLE_DISABLE_POSMENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ENABLE_DISABLE_POSMENU_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ENABLE_DISABLE_POSMENU_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DISABLE_POSMENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DISABLE_POSMENU_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DISABLE_POSMENU_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_MENU_SCHEDULE_REQUSTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENU_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        menuScheduleList: action.result,
      };
    }
    case GET_MENU_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        menuScheduleList: [],
      };
    }
    case ADD_MENU_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENU_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_MENU_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_MENU_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MENU_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_MENU_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_POS_MENUS_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_POS_MENUS_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_POS_MENUS_BY_STOREID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_MENU_SCHEDULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MENU_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_MENU_SCHEDULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default: return state;
  }
};

export const getPosMenusByStoreId = (storeId) => {
  return {
    types: [GET_POSMENU_LIST_STORE_REQUESTED, GET_POSMENU_LIST_STORE_SUCCESS, GET_POSMENU_LIST_STORE_FAILURE],
    promise: client => client.get(`getPosMenusByStoreId/${storeId}`)
  };
};

export const enableDisablePosMenu = (menuId, enabled) => {
  return {
    types: [ENABLE_DISABLE_POSMENU_REQUESTED, ENABLE_DISABLE_POSMENU_SUCCESS, ENABLE_DISABLE_POSMENU_FAILURE],
    promise: client => client.post(`enableDisablePosMenu/${menuId}/enabled/${enabled}`)
  };
};

export const getMenuSchedules = (menuId, dayOfWeek, includeDayOfWeek = true) => {
  let url = `getMenuSchedule/${menuId}`;
  if (includeDayOfWeek) {
    url = `${url}/dayOfWeek/${dayOfWeek}`;
  }
  return {
    types: [GET_MENU_SCHEDULE_REQUSTED, GET_MENU_SCHEDULE_SUCCESS, GET_MENU_SCHEDULE_FAILURE],
    promise: client => client.get(url)
  };
};

export const saveMenuSchedule = (data, menuId) => {
  return {
    types: [ADD_MENU_SCHEDULE_REQUESTED, ADD_MENU_SCHEDULE_SUCCESS, ADD_MENU_SCHEDULE_FAILURE],
    promise: client => client.post(`addMenuSchedule/${menuId}`, { data })
  };
};

export const updateMenuSchedule = (data, menuId) => {
  return {
    types: [UPDATE_MENU_SCHEDULE_REQUESTED, UPDATE_MENU_SCHEDULE_SUCCESS, UPDATE_MENU_SCHEDULE_FAILURE],
    promise: client => client.post(`updateMenuSchedule/${menuId}`, { data })
  };
};

export const deleteMenuSchedule = (scheduleId) => {
  return {
    types: [DELETE_MENU_SCHEDULE_REQUESTED, DELETE_MENU_SCHEDULE_SUCCESS, DELETE_MENU_SCHEDULE_FAILURE],
    promise: client => client.post(`deleteMenuSchedule/${scheduleId}`)
  };
};

export const updatePosMenusByStoreId = (data) => {
  return {
    types: [UPDATE_POS_MENUS_BY_STOREID_REQUESTED, UPDATE_POS_MENUS_BY_STOREID_SUCCESS, UPDATE_POS_MENUS_BY_STOREID_FAILURE],
    promise: client => client.post('updatePosMenusByStoreId', { data })
  };
};

export const disableProcessing = (menuId, enabled) => {
  return {
    types: [DISABLE_POSMENU_REQUESTED, DISABLE_POSMENU_SUCCESS, DISABLE_POSMENU_FAILURE],
    promise: client => client.post(`disableProcessing/${menuId}/enabled/${enabled}`)
  };
};
