const currentReducer = 'MDM_POLICY';
const GET_MDM_POLICY_REQUESTED = `${currentReducer}/GET_MDM_POLICY_REQUESTED`;
const GET_MDM_POLICY_SUCCESS = `${currentReducer}/GET_MDM_POLICY_SUCCESS`;
const GET_MDM_POLICY_FAILURE = `${currentReducer}/GET_MDM_POLICY_FAILURE`;

const GET_MDM_BRAND_POLICY_REQUESTED = `${currentReducer}/GET_MDM_BRAND_POLICY_REQUESTED`;
const GET_MDM_BRAND_POLICY_SUCCESS = `${currentReducer}/GET_MDM_BRAND_POLICY_SUCCESS`;
const GET_MDM_BRAND_POLICY_FAILURE = `${currentReducer}/GET_MDM_BRAND_POLICY_FAILURE`;

const UPDATE_MDM_BRAND_POLICY_REQUESTED = `${currentReducer}/UPDATE_MDM_BRAND_POLICY_REQUESTED`;
const UPDATE_MDM_BRAND_POLICY_SUCCESS = `${currentReducer}/UPDATE_MDM_BRAND_POLICY_SUCCESS`;
const UPDATE_MDM_BRAND_POLICY_FAILURE = `${currentReducer}/UPDATE_MDM_BRAND_POLICY_FAILURE`;

const GET_MDM_STORE_POLICY_REQUESTED = `${currentReducer}/GET_MDM_STORE_POLICY_REQUESTED`;
const GET_MDM_STORE_POLICY_SUCCESS = `${currentReducer}/GET_MDM_STORE_POLICY_SUCCESS`;
const GET_MDM_STORE_POLICY_FAILURE = `${currentReducer}/GET_MDM_STORE_POLICY_FAILURE`;

const UPDATE_MDM_STORE_POLICY_REQUESTED = `${currentReducer}/UPDATE_MDM_STORE_POLICY_REQUESTED`;
const UPDATE_MDM_STORE_POLICY_SUCCESS = `${currentReducer}/UPDATE_MDM_STORE_POLICY_SUCCESS`;
const UPDATE_MDM_STORE_POLICY_FAILURE = `${currentReducer}/UPDATE_MDM_STORE_POLICY_FAILURE`;


const UPDATE_MDM_POLICY_REQUESTED = `${currentReducer}/UPDATE_MDM_POLICY_REQUESTED`;
const UPDATE_MDM_POLICY_SUCCESS = `${currentReducer}/UPDATE_MDM_POLICY_SUCCESS`;
const UPDATE_MDM_POLICY_FAILURE = `${currentReducer}/UPDATE_MDM_POLICY_FAILURE`;

const GET_MDM_APPS_REQUESTED = `${currentReducer}/GET_MDM_APPS_REQUESTED`;
const GET_MDM_APPS_SUCCESS = `${currentReducer}/GET_MDM_APPS_SUCCESS`;
const GET_MDM_APPS_FAILURE = `${currentReducer}/GET_MDM_APPS_FAILURE`;

const ADD_MDM_SUCCESS = `${currentReducer}/ADD_MDM_SUCCESS`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  mdmPolicys: undefined,
  mdmBrandPolicy: null,
  mdmStorePolicy: null,
  mdmAppsDeployed: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_MDM_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        mdmPolicys: undefined
      };
    }
    case ADD_MDM_SUCCESS:
    case GET_MDM_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmPolicys: action.result
      };
    }
    case GET_MDM_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MDM_BRAND_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        mdmBrandPolicy: undefined
      };
    }
    case GET_MDM_BRAND_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmBrandPolicy: action.result
      };
    }
    case GET_MDM_BRAND_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MDM_STORE_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        mdmBrandPolicy: undefined
      };
    }
    case GET_MDM_STORE_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmStorePolicy: action.result
      };
    }
    case GET_MDM_STORE_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_MDM_APPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        mdmAppsDeployed: []
      };
    }
    case GET_MDM_APPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmAppsDeployed: (action.result && action.result.appDeploy) || []
      };
    }
    case GET_MDM_APPS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MDM_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_MDM_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmPolicys: action.result
      };
    }
    case UPDATE_MDM_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MDM_BRAND_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_MDM_BRAND_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmPolicys: action.result
      };
    }
    case UPDATE_MDM_BRAND_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MDM_STORE_POLICY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_MDM_STORE_POLICY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmPolicys: action.result
      };
    }
    case UPDATE_MDM_STORE_POLICY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const addtoMdm = (data) => {
  return {
    types: [GET_MDM_POLICY_REQUESTED, ADD_MDM_SUCCESS, GET_MDM_POLICY_REQUESTED],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const getMDMPloicies = (data) => {
  return {
    types: [GET_MDM_POLICY_REQUESTED, GET_MDM_POLICY_SUCCESS, GET_MDM_POLICY_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const updateMDMPloicies = (data) => {
  return {
    types: [UPDATE_MDM_POLICY_REQUESTED, UPDATE_MDM_POLICY_SUCCESS, UPDATE_MDM_POLICY_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const getMDMAppsDeployed = (data) => {
  return {
    types: [GET_MDM_APPS_REQUESTED, GET_MDM_APPS_SUCCESS, GET_MDM_APPS_FAILURE],
    promise: client => client.post('mdmPolicy', { data })
  };
};

export const getMDMBrandPolicy = (brandId) => {
  return {
    types: [GET_MDM_BRAND_POLICY_REQUESTED, GET_MDM_BRAND_POLICY_SUCCESS, GET_MDM_BRAND_POLICY_FAILURE],
    promise: client => client.get(`getMDMBrandPolicy/${brandId}`)
  };
};

export const getMDMStorePolicy = (storeId) => {
  return {
    types: [GET_MDM_STORE_POLICY_REQUESTED, GET_MDM_STORE_POLICY_SUCCESS, GET_MDM_STORE_POLICY_FAILURE],
    promise: client => client.get(`getMDMStorePolicy/${storeId}`)
  };
};

export const updateMDMStorePolicy = (storeId, data) => {
  return {
    types: [UPDATE_MDM_STORE_POLICY_REQUESTED, UPDATE_MDM_STORE_POLICY_SUCCESS, UPDATE_MDM_STORE_POLICY_FAILURE],
    promise: client => client.post(`updateMDMStorePolicy/${storeId}`, { data })
  };
};

export const updateMDMBrandPolicy = (brandId, data) => {
  return {
    types: [UPDATE_MDM_BRAND_POLICY_REQUESTED, UPDATE_MDM_BRAND_POLICY_SUCCESS, UPDATE_MDM_BRAND_POLICY_FAILURE],
    promise: client => client.post(`updateMDMBrandPolicy/${brandId}`, { data })
  };
};
