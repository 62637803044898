const currentReducer = 'releaseNote';

export const GET_RELEASE_NOTE_PRODUCTS_REQUESTED = 'releaseNote/RELEASE_NOTE_PRODUCTS_REQUESTED';
export const GET_RELEASE_NOTE_PRODUCTS_SUCCESS = 'releaseNote/RELEASE_NOTE_PRODUCTS_SUCCESS';
export const GET_RELEASE_NOTE_PRODUCTS_FAILURE = 'releaseNote/RELEASE_NOTE_PRODUCTS_FAILURE';

export const GET_RELEASE_NOTE_TYPES_REQUESTED = 'releaseNote/GET_RELEASE_NOTE_TYPES_REQUESTED';
export const GET_RELEASE_NOTE_TYPES_SUCCESS = 'releaseNote/GET_RELEASE_NOTE_TYPES_SUCCESS';
export const GET_RELEASE_NOTE_TYPES_FAILURE = 'releaseNote/GET_RELEASE_NOTE_TYPES_FAILURE';

export const GET_RELEASE_NOTE_STATUSES_REQUESTED = 'releaseNote/GET_RELEASE_NOTE_STATUSES_REQUESTED';
export const GET_RELEASE_NOTE_STATUSES_SUCCESS = 'releaseNote/GET_RELEASE_NOTE_STATUSES_SUCCESS';
export const GET_RELEASE_NOTE_STATUSES_FAILURE = 'releaseNote/GET_RELEASE_NOTE_STATUSES_FAILURE';

const GET_RELEASE_NOTE_REQUESTED = `${currentReducer}/GET_RELEASE_NOTE_REQUESTED`;
const GET_RELEASE_NOTE_SUCCESS = `${currentReducer}/GET_RELEASE_NOTE_SUCCESS`;
const GET_RELEASE_NOTE_FAILURE = `${currentReducer}/GET_RELEASE_NOTE_FAILURE`;

const ADD_RELEASE_NOTE_REQUESTED = `${currentReducer}/ADD_RELEASE_NOTE_REQUESTED`;
const ADD_RELEASE_NOTE_SUCCESS = `${currentReducer}/ADD_RELEASE_NOTE_SUCCESS`;
const ADD_RELEASE_NOTE_FAILURE = `${currentReducer}/ADD_RELEASE_NOTE_FAILURE`;

const ADD_RELEASE_NOTE_ITEM_REQUESTED = `${currentReducer}/ADD_RELEASE_NOTE_ITEM_REQUESTED`;
const ADD_RELEASE_NOTE_ITEM_SUCCESS = `${currentReducer}/ADD_RELEASE_NOTE_ITEM_SUCCESS`;
const ADD_RELEASE_NOTE_ITEM_FAILURE = `${currentReducer}/ADD_RELEASE_NOTE_ITEM_FAILURE`;

const UPDATE_RELEASE_NOTE_REQUESTED = `${currentReducer}/UPDATE_RELEASE_NOTE_REQUESTED`;
const UPDATE_RELEASE_NOTE_SUCCESS = `${currentReducer}/UPDATE_RELEASE_NOTE_SUCCESS`;
const UPDATE_RELEASE_NOTE_FAILURE = `${currentReducer}/UPDATE_RELEASE_NOTE_FAILURE`;

const UPDATE_RELEASE_NOTE_ITEM_REQUESTED = `${currentReducer}/UPDATE_RELEASE_NOTE_ITEM_REQUESTED`;
const UPDATE_RELEASE_NOTE_ITEM_SUCCESS = `${currentReducer}/UPDATE_RELEASE_NOTE_ITEM_SUCCESS`;
const UPDATE_RELEASE_NOTE_ITEM_FAILURE = `${currentReducer}/UPDATE_RELEASE_NOTE_ITEM_FAILURE`;

const DELETE_RELEASE_NOTE_REQUESTED = `${currentReducer}/DELETE_RELEASE_NOTE_REQUESTED`;
const DELETE_RELEASE_NOTE_SUCCESS = `${currentReducer}/DELETE_RELEASE_NOTE_SUCCESS`;
const DELETE_RELEASE_NOTE_FAILURE = `${currentReducer}/DELETE_RELEASE_NOTE_FAILURE`;

const DELETE_RELEASE_NOTE_ITEM_REQUESTED = `${currentReducer}/DELETE_RELEASE_NOTE_ITEM_REQUESTED`;
const DELETE_RELEASE_NOTE_ITEM_SUCCESS = `${currentReducer}/DELETE_RELEASE_NOTE_ITEM_SUCCESS`;
const DELETE_RELEASE_NOTE_ITEM_FAILURE = `${currentReducer}/DELETE_RELEASE_NOTE_ITEM_FAILURE`;

const UPDATE_MEMBER_RELEASE_NOTE_REQUESTED = 'releaseNote/UPDATE_MEMBER_RELEASE_NOTE_REQUESTED';
const UPDATE_MEMBER_RELEASE_NOTE_SUCCESS = 'releaseNote/UPDATE_MEMBER_RELEASE_NOTE_SUCCESS';
const UPDATE_MEMBER_RELEASE_NOTE_FAILURE = 'releaseNote/UPDATE_MEMBER_RELEASE_NOTE_FAILURE';

const DELETE_RELEASE_NOTE_PDF_REQUESTED = `${currentReducer}/DELETE_RELEASE_NOTE_PDF_REQUESTED`;
const DELETE_RELEASE_NOTE_PDF_SUCCESS = `${currentReducer}/DELETE_RELEASE_NOTE_PDF_SUCCESS`;
const DELETE_RELEASE_NOTE_PDF_FAILURE = `${currentReducer}/DELETE_RELEASE_NOTE_PDF_FAILURE`;

const UPDATE_RELEASE_NOTE_PDF_REQUESTED = 'releaseNote/UPDATE_RELEASE_NOTE_PDF_REQUESTED';
const UPDATE_RELEASE_NOTE_PDF_SUCCESS = 'releaseNote/UPDATE_RELEASE_NOTE_PDF_SUCCESS';
const UPDATE_RELEASE_NOTE_PDF_FAILURE = 'releaseNote/UPDATE_RELEASE_NOTE_PDF_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  releaseNoteList: [],
  releaseNotesProducts: [],
  releaseNotesTypes: [],
  releaseNotesStatus: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNoteList: action.result
      };
    }
    case GET_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_RELEASE_NOTE_PRODUCTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RELEASE_NOTE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNotesProducts: action.result,
      };
    }
    case GET_RELEASE_NOTE_PRODUCTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        releaseNotesProducts: null,
      };
    }
    case GET_RELEASE_NOTE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RELEASE_NOTE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNotesTypes: action.result,
      };
    }
    case GET_RELEASE_NOTE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        releaseNotesTypes: null,
      };
    }
    case GET_RELEASE_NOTE_STATUSES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        releaseNotesStatus: []
      };
    }
    case GET_RELEASE_NOTE_STATUSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        releaseNotesStatus: action.result,
      };
    }
    case GET_RELEASE_NOTE_STATUSES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        releaseNotesStatus: null,
      };
    }
    case ADD_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case ADD_RELEASE_NOTE_ITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case ADD_RELEASE_NOTE_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_RELEASE_NOTE_ITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_RELEASE_NOTE_ITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_RELEASE_NOTE_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_RELEASE_NOTE_ITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DELETE_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_RELEASE_NOTE_ITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DELETE_RELEASE_NOTE_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RELEASE_NOTE_ITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_MEMBER_RELEASE_NOTE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_MEMBER_RELEASE_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MEMBER_RELEASE_NOTE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_RELEASE_NOTE_PDF_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DELETE_RELEASE_NOTE_PDF_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RELEASE_NOTE_PDF_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_RELEASE_NOTE_PDF_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case UPDATE_RELEASE_NOTE_PDF_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_RELEASE_NOTE_PDF_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};


export const getReleaseNoteProducts = () => {
  return {
    types: [GET_RELEASE_NOTE_PRODUCTS_REQUESTED, GET_RELEASE_NOTE_PRODUCTS_SUCCESS, GET_RELEASE_NOTE_PRODUCTS_FAILURE],
    promise: client => client.get('getReleaseNoteProducts'),
  };
};

export const getReleaseNoteTypes = () => {
  return {
    types: [GET_RELEASE_NOTE_TYPES_REQUESTED, GET_RELEASE_NOTE_TYPES_SUCCESS, GET_RELEASE_NOTE_TYPES_FAILURE],
    promise: client => client.get('getReleaseNoteTypes'),
  };
};

export const getReleaseNoteStatuses = () => {
  return {
    types: [GET_RELEASE_NOTE_STATUSES_REQUESTED, GET_RELEASE_NOTE_STATUSES_SUCCESS, GET_RELEASE_NOTE_STATUSES_FAILURE],
    promise: client => client.get('getReleaseNoteStatuses'),
  };
};

export const getReleaseNote = () => {
  return {
    types: [GET_RELEASE_NOTE_REQUESTED, GET_RELEASE_NOTE_SUCCESS, GET_RELEASE_NOTE_FAILURE],
    promise: client => client.get('getReleaseNote')
  };
};

export const addReleaseNote = (data) => {
  return {
    types: [ADD_RELEASE_NOTE_REQUESTED, ADD_RELEASE_NOTE_SUCCESS, ADD_RELEASE_NOTE_FAILURE],
    promise: client => client.post('addReleaseNote', { data })
  };
};

export const updateReleaseNote = (data) => {
  return {
    types: [UPDATE_RELEASE_NOTE_REQUESTED, UPDATE_RELEASE_NOTE_SUCCESS, UPDATE_RELEASE_NOTE_FAILURE],
    promise: client => client.post('updateReleaseNote', { data })
  };
};

export const addReleaseNoteItem = (data) => {
  return {
    types: [ADD_RELEASE_NOTE_ITEM_REQUESTED, ADD_RELEASE_NOTE_ITEM_SUCCESS, ADD_RELEASE_NOTE_ITEM_FAILURE],
    promise: client => client.post('addReleaseNoteItem', { data })
  };
};

export const updateReleaseNoteItem = (data) => {
  return {
    types: [UPDATE_RELEASE_NOTE_ITEM_REQUESTED, UPDATE_RELEASE_NOTE_ITEM_SUCCESS, UPDATE_RELEASE_NOTE_ITEM_FAILURE],
    promise: client => client.post('updateReleaseNoteItem', { data })
  };
};

export const deleteReleaseNote = (releaseNoteId) => {
  return {
    types: [DELETE_RELEASE_NOTE_REQUESTED, DELETE_RELEASE_NOTE_SUCCESS, DELETE_RELEASE_NOTE_FAILURE],
    promise: client => client.post(`deleteReleaseNote/${releaseNoteId}`)
  };
};

export const deleteReleaseNoteItem = (releaseNoteId) => {
  return {
    types: [DELETE_RELEASE_NOTE_ITEM_REQUESTED, DELETE_RELEASE_NOTE_ITEM_SUCCESS, DELETE_RELEASE_NOTE_ITEM_FAILURE],
    promise: client => client.post(`deleteReleaseNoteItem/${releaseNoteId}`)
  };
};

export const updateMemberReleaseNotes = (status) => {
  return {
    types: [UPDATE_MEMBER_RELEASE_NOTE_REQUESTED, UPDATE_MEMBER_RELEASE_NOTE_SUCCESS, UPDATE_MEMBER_RELEASE_NOTE_FAILURE],
    promise: client => client.post(`memberReleaseNotes/${status}`)
  };
};

export const deleteReleaseNotePdf = (releaseNoteId) => {
  return {
    types: [DELETE_RELEASE_NOTE_PDF_REQUESTED, DELETE_RELEASE_NOTE_PDF_SUCCESS, DELETE_RELEASE_NOTE_PDF_FAILURE],
    promise: client => client.post(`deleteReleaseNotePdf/${releaseNoteId}`)
  };
};

export const uploadReleaseNotePdf = (releaseNoteId, data) => {
  return {
    types: [UPDATE_RELEASE_NOTE_PDF_REQUESTED, UPDATE_RELEASE_NOTE_PDF_SUCCESS, UPDATE_RELEASE_NOTE_PDF_FAILURE],
    promise: client => client.post(`uploadReleaseNotePdf/${releaseNoteId}`, { data })
  };
};
