export const ADD_BRAND_CHARITY_REQUESTED = 'brandCharity/ADD_BRAND_CHARITY_REQUESTED';
export const ADD_BRAND_CHARITY_SUCCESS = 'brandCharity/ADD_BRAND_CHARITY_SUCCESS';
export const ADD_BRAND_CHARITY_FAILURE = 'brandCharity/ADD_BRAND_CHARITY_FAILURE';

export const UPDATE_BRAND_CHARITY_REQUESTED = 'brandCharity/UPDATE_BRAND_CHARITY_REQUESTED';
export const UPDATE_BRAND_CHARITY_SUCCESS = 'brandCharity/UPDATE_BRAND_CHARITY_SUCCESS';
export const UPDATE_BRAND_CHARITY_FAILURE = 'brandCharity/UPDATE_BRAND_CHARITY_FAILURE';

export const GET_BRAND_CHARITY_BYID_REQUESTED = 'brandCharity/GET_BRAND_CHARITY_BYID_REQUESTED';
export const GET_BRAND_CHARITY_BYID_SUCCESS = 'brandCharity/GET_BRAND_CHARITY_BYID_SUCCESS';
export const GET_BRAND_CHARITY_BYID_FAILURE = 'brandCharity/GET_BRAND_CHARITY_BYID_FAILURE';

export const GET_BRAND_CHARITY_LIST_DATA_REQUESTED = 'brandCharity/GET_BRAND_CHARITY_LIST_DATA_REQUESTED';
export const GET_BRAND_CHARITY_LIST_DATA_SUCCESS = 'brandCharity/GET_BRAND_CHARITY_LIST_DATA_SUCCESS';
export const GET_BRAND_CHARITY_LIST_DATA_FAILURE = 'brandCharity/GET_BRAND_CHARITY_LIST_DATA_FAILURE';

export const CHARITY_IMAGE_SAVE_REQUESTED = 'brandCharity/CHARITY_IMAGE_SAVE_REQUESTED';
export const CHARITY_IMAGE_SAVE_SUCCESS = 'brandCharity/CHARITY_IMAGE_SAVE_SUCCESS';
export const CHARITY_IMAGE_SAVE_FAILURE = 'brandCharity/CHARITY_IMAGE_SAVE_FAILURE';

export const CHARITY_IMAGE_DELETE_REQUESTED = 'brandCharity/CHARITY_IMAGE_DELETE_REQUESTED';
export const CHARITY_IMAGE_DELETE_SUCCESS = 'brandCharity/CHARITY_IMAGE_DELETE_SUCCESS';
export const CHARITY_IMAGE_DELETE_FAILURE = 'brandCharity/CHARITY_IMAGE_DELETE_FAILURE';

export const DELETE_CHARITY_REQUESTED = 'brandCharity/DELETE_CHARITY_REQUESTED';
export const DELETE_CHARITY_SUCCESS = 'brandCharity/DELETE_CHARITY_SUCCESS';
export const DELETE_CHARITY_FAILURE = 'brandCharity/DELETE_CHARITY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getCharityById: null,
  brandCharityList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BRAND_CHARITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_CHARITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_CHARITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BRAND_CHARITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_CHARITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_CHARITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_CHARITY_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_CHARITY_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCharityById: action.result,
      };
    }
    case GET_BRAND_CHARITY_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_CHARITY_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_CHARITY_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        brandCharityList: action.result,
      };
    }
    case GET_BRAND_CHARITY_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHARITY_IMAGE_SAVE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHARITY_IMAGE_SAVE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CHARITY_IMAGE_SAVE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CHARITY_IMAGE_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHARITY_IMAGE_DELETE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CHARITY_IMAGE_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_CHARITY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CHARITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_CHARITY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addCharity = (data) => {
  return {
    types: [ADD_BRAND_CHARITY_REQUESTED, ADD_BRAND_CHARITY_SUCCESS, ADD_BRAND_CHARITY_FAILURE],
    promise: client => client.post('addBrandCharity', { data })
  };
};

export const updateCharity = (data) => {
  return {
    types: [UPDATE_BRAND_CHARITY_REQUESTED, UPDATE_BRAND_CHARITY_SUCCESS, UPDATE_BRAND_CHARITY_FAILURE],
    promise: client => client.post('updateBrandCharity', { data })
  };
};

export const getCharityById = (id) => {
  return {
    types: [GET_BRAND_CHARITY_BYID_REQUESTED, GET_BRAND_CHARITY_BYID_SUCCESS, GET_BRAND_CHARITY_BYID_FAILURE],
    promise: client => client.get(`getBrandCharityById/${id}`)
  };
};

export const getCharitiesList = (brandId) => {
  return {
    types: [GET_BRAND_CHARITY_LIST_DATA_REQUESTED, GET_BRAND_CHARITY_LIST_DATA_SUCCESS, GET_BRAND_CHARITY_LIST_DATA_FAILURE],
    promise: client => client.get(`getBrandCharityList/${brandId}`)
  };
};

export const handleCharityImage = (charityId, type, data) => {
  return {
    types: [CHARITY_IMAGE_SAVE_REQUESTED, CHARITY_IMAGE_SAVE_SUCCESS, CHARITY_IMAGE_SAVE_FAILURE],
    promise: client => client.post(`addCharityImage/${charityId}/${type}`, { data })
  };
};

export const deleteCharityImage = (charityId) => {
  return {
    types: [CHARITY_IMAGE_SAVE_REQUESTED, CHARITY_IMAGE_SAVE_SUCCESS, CHARITY_IMAGE_SAVE_FAILURE],
    promise: client => client.post(`charityImage/${charityId}/delete`)
  };
};

export const deleteBrandCharity = (charityId) => {
  return {
    types: [DELETE_CHARITY_REQUESTED, DELETE_CHARITY_SUCCESS, DELETE_CHARITY_FAILURE],
    promise: client => client.post(`deleteBrandCharity/${charityId}`)
  };
};
