import loginScreen from '@assets/serverappscreen/LoginPage.png';
import checkListingScreen from '@assets/serverappscreen/LandingPage.png';
import orderScreen from '@assets/serverappscreen/OrdersAndCart.png';
import stageScreen from '@assets/serverappscreen/StageScreen.png';
import itemdetailsScreen from '@assets/serverappscreen/ItemDetailScreen.png';
import loyaltyScreen from '@assets/serverappscreen/LoyaltyScreen.png';
import paymentScreen from '@assets/serverappscreen/PaymentScreen.png';
import OtherScreen from '@assets/serverappscreen/othersScreen.png';
import CharityScreen from '@assets/serverappscreen/CharityScreen.png';
import SurveyScreen from '@assets/serverappscreen/SurveyScreen.png';
import PaymentLandingScreen from '@assets/serverappscreen/PaymentLandingScreen.png';
import PayementSBSScreen from '@assets/serverappscreen/PayementSBSScreen.png';
import PaymentSplitsBySelected from '@assets/serverappscreen/PaymentSplitsBySelected.png';
import PaymentSplitEvenly from '@assets/serverappscreen/PaymentSplitEvenly.png';
import PaymentCheckPresenterScreen from '@assets/serverappscreen/PaymentCheckPresenterScreen.png';
import PayementTipScreen from '@assets/serverappscreen/PayementTipScreen.png';
import PaymentOfferScreen from '@assets/serverappscreen/PaymentOfferScreen.png';
import PaymentLoyaltyScreen from '@assets/serverappscreen/PaymentLoyaltyScreen.png';
import PaymentGiftCardSscreen from '@assets/serverappscreen/PaymentGiftCard.png';
import PaymentCouponScreen from '@assets/serverappscreen/PaymentCouponScreen.png';
import PayementEIPScreen from '@assets/serverappscreen/PayementEIPScreen.png';
import PaymentPEScreen from '@assets/serverappscreen/PaymentP&EScreen.png';
import PaymentConfirmationScreen from '@assets/serverappscreen/PaymentConfirmationScreen.png';
import PaymentRecieptScreen from '@assets/serverappscreen/PaymentRecieptScreen.png';

export default {
  LoginPage: {
    screenLabel: 'Login Screen',
    image: [loginScreen],
    customLabels: [
      {
        defaultText: 'LOGIN',
        key: 'label.LOGIN',
        type: 'label',
        value: 'LOGIN',
      },
      {
        defaultText: 'ACTIVE INGENICO DEVICE',
        key: 'label.ACTIVE_INGENICO_DEVICE',
        type: 'label',
        value: 'ACTIVE_INGENICO_DEVICE',
      },
      {
        defaultText: 'CONTACT',
        key: 'label.CONTACT',
        type: 'label',
        value: 'CONTACT',
      },
      {
        defaultText: 'FOR SUPPORT',
        key: 'label.FOR_SUPPORT',
        type: 'label',
        value: 'FOR_SUPPORT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CANCEL',
        type: 'label',
        value: 'CANCEL',
      },
      {
        defaultText: 'Succesfully Logged In',
        key: 'customMessage.LOGIN_SUCCESS',
        type: 'customMessage',
        value: 'LOGIN_SUCCESS',
      },
      {
        defaultText: 'Device Activated Successfully',
        key: 'customMessage.ACT_SUCCESS',
        type: 'customMessage',
        value: 'ACT_SUCCESS',
      },
      {
        defaultText: 'No Notification Found',
        key: 'customMessage.NO_NOTIFICATION',
        type: 'customMessage',
        value: 'NO_NOTIFICATION',
      },
      {
        defaultText: 'Do you wish to re-activate with different activation code?',
        key: 'customMessage.REACTIVATE_WITH_NEW_CODE',
        type: 'customMessage',
        value: 'REACTIVATE_WITH_NEW_CODE',
      },
      {
        defaultText: 'Enter your verification code:',
        key: 'customMessage.ENTER_VERIFICATION_CODE',
        type: 'customMessage',
        value: 'ENTER_VERIFICATION_CODE',
      },
      {
        defaultText: 'TABLET REQUIRES A REBOOT. PLEASE HOLD THE POWER BUTTON AND CLICK ON RESTART',
        key: 'customMessage.TABLET_REQUIRES_A_REBOOT',
        type: 'customMessage',
        value: 'TABLET_REQUIRES_A_REBOOT',
      },
    ]
  },
  LandingPage: {
    screenLabel: 'Landing Screen',
    image: [checkListingScreen],
    customLabels: [
      {
        defaultText: 'All Checks',
        key: 'label.ALL_CHECKS',
        type: 'label',
        value: 'ALL_CHECKS',
      },
      {
        defaultText: 'Open Checks',
        key: 'label.OPEN_CHECKS',
        type: 'label',
        value: 'OPEN_CHECKS',
      },
      {
        defaultText: 'My Tips',
        key: 'label.MY_TIPS',
        type: 'label',
        value: 'MY_TIPS',
      },
      {
        defaultText: 'Change Job Type',
        key: 'label.CHANGE_JOB',
        type: 'label',
        value: 'CHANGE_JOB',
      },
      {
        defaultText: 'Pair Watch',
        key: 'label.PAIR_WATCH',
        type: 'label',
        value: 'PAIR_WATCH',
      },
      {
        defaultText: 'Sync Checks',
        key: 'label.SYNC_CHECKS',
        type: 'label',
        value: 'SYNC_CHECKS',
      },
      {
        defaultText: 'About',
        key: 'label.ABOUT',
        type: 'label',
        value: 'ABOUT',
      },
      {
        defaultText: 'TABLE',
        key: 'label.TABLE',
        type: 'label',
        value: 'TABLE',
      },
      {
        defaultText: 'TAB',
        key: 'label.TAB',
        type: 'label',
        value: 'TAB',
      },
      {
        defaultText: 'OTHER',
        key: 'label.OTHER',
        type: 'label',
        value: 'OTHER',
      },
      {
        defaultText: 'Order',
        key: 'label.ORDER',
        type: 'label',
        value: 'ORDER',
      },
      {
        defaultText: 'Print',
        key: 'label.PRINT',
        type: 'label',
        value: 'PRINT',
      },
      {
        defaultText: 'Stage',
        key: 'label.STAGE',
        type: 'label',
        value: 'STAGE',
      },
      {
        defaultText: 'Pay',
        key: 'label.PAY',
        type: 'label',
        value: 'PAY',
      },
      {
        defaultText: 'Receipt',
        key: 'label.RECEIPT',
        type: 'label',
        value: 'RECEIPT',
      },
      {
        defaultText: 'Tools',
        key: 'label.TOOLS',
        type: 'label',
        value: 'TOOLS',
      },
      {
        defaultText: 'NO CHECKS',
        key: 'label.NO_CHECKS',
        type: 'label',
        value: 'NO_CHECKS',
      },
      {
        defaultText: 'Sent',
        key: 'label.SENT',
        type: 'label',
        value: 'SENT',
      },
      {
        defaultText: 'No Items Found',
        key: 'customMessage.NO_ITEM_FOUND',
        type: 'customMessage',
        value: 'NO_ITEM_FOUND',
      },
      {
        defaultText: 'Pending',
        key: 'label.PENDING',
        type: 'label',
        value: 'PENDING',
      },
      {
        defaultText: 'Edit',
        key: 'label.EDIT',
        type: 'label',
        value: 'EDIT',
      },
      {
        defaultText: 'Move',
        key: 'label.MOVE',
        type: 'label',
        value: 'MOVE',
      },
      {
        defaultText: 'Delete',
        key: 'label.DELETE',
        type: 'label',
        value: 'DELETE',
      },
      {
        defaultText: 'Send',
        key: 'label.SEND',
        type: 'label',
        value: 'SEND',
      },
      {
        defaultText: 'Repeat',
        key: 'label.REPEAT',
        type: 'label',
        value: 'REPEAT',
      },
      {
        defaultText: 'Delete All',
        key: 'label.DELETE_ALL',
        type: 'label',
        value: 'DELETE_ALL',
      },
      {
        defaultText: 'Send All',
        key: 'label.SEND_ALL',
        type: 'label',
        value: 'SEND_ALL',
      },
      {
        defaultText: 'Select Job',
        key: 'label.SELECT_JOB',
        type: 'label',
        value: 'SELECT_JOB',
      },
      {
        defaultText: 'Save',
        key: 'label.SAVE',
        type: 'label',
        value: 'SAVE',
      },
      {
        defaultText: 'Enter 6-digit code from watch',
        key: 'customMessage.ENTER_CODE_FROM_WATCH',
        type: 'customMessage',
        value: 'ENTER_CODE_FROM_WATCH',
      },
      {
        defaultText: 'Pair',
        key: 'label.PAIR',
        type: 'label',
        value: 'PAIR',
      },
      {
        defaultText: 'Enter Table Number',
        key: 'customMessage.ENTER_TABLE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_TABLE_NUMBER',
      },
      {
        defaultText: 'GUEST COUNT',
        key: 'label.GUEST_COUNT',
        type: 'label',
        value: 'GUEST_COUNT',
      },
      {
        defaultText: 'Open',
        key: 'label.OPEN',
        type: 'label',
        value: 'OPEN',
      },
      {
        defaultText: 'Enter Tab Name',
        key: 'customMessage.ENTER_TAB_NAME',
        type: 'customMessage',
        value: 'ENTER_TAB_NAME',
      },
      {
        defaultText: 'Off Premise Orders',
        key: 'label.OFF_PROMISE_ORDER',
        type: 'label',
        value: 'OFF_PROMISE_ORDER',
      },
      {
        defaultText: 'Walk Up',
        key: 'label.TAKE_OUT',
        type: 'label',
        value: 'TAKE_OUT',
      },
      {
        defaultText: 'Call In',
        key: 'label.CALL_IN',
        type: 'label',
        value: 'CALL_IN',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.PHONE_NUMBER',
        type: 'label',
        value: 'PHONE_NUMBER',
      },
      {
        defaultText: 'Guest Name',
        key: 'label.GUEST_NAME',
        type: 'label',
        value: 'GUEST_NAME',
      },
      {
        defaultText: 'Pickup Time',
        key: 'label.PICKUP_TIME',
        type: 'label',
        value: 'PICKUP_TIME',
      },
      {
        defaultText: 'Delivery Time',
        key: 'label.DELIVERY_TIME',
        type: 'label',
        value: 'DELIVERY_TIME',
      },
      {
        defaultText: 'Address 1',
        key: 'label.ADDRESS_1',
        type: 'label',
        value: 'ADDRESS_1',
      },
      {
        defaultText: 'Address 2',
        key: 'label.ADDRESS_2',
        type: 'label',
        value: 'ADDRESS_2',
      },
      {
        defaultText: 'City',
        key: 'label.CITY',
        type: 'label',
        value: 'CITY',
      },
      {
        defaultText: 'State',
        key: 'label.STATE',
        type: 'label',
        value: 'STATE',
      },
      {
        defaultText: 'Zip',
        key: 'label.ZIP',
        type: 'label',
        value: 'ZIP',
      },
      {
        defaultText: 'Sync Check with POS',
        key: 'label.SYNC_CHECKS_WITH_POS',
        type: 'label',
        value: 'SYNC_CHECKS_WITH_POS',
      },
      {
        defaultText: 'Repost Payment',
        key: 'label.REPOST_PAYMENT',
        type: 'label',
        value: 'REPOST_PAYMENT',
      },
      {
        defaultText: 'Email',
        key: 'label.EMAIL',
        type: 'label',
        value: 'EMAIL',
      },
      {
        defaultText: 'Text',
        key: 'label.TEXT',
        type: 'label',
        value: 'TEXT',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'customMessage.ENTER_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Enter Phone Number',
        key: 'customMessage.ENTER_PHONE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_PHONE_NUMBER',
      },
      {
        defaultText: 'Submit',
        key: 'label.SUBMIT',
        type: 'label',
        value: 'SUBMIT',
      },
      {
        defaultText: 'Search',
        key: 'label.SEARCH',
        type: 'label',
        value: 'SEARCH',
      },
      {
        defaultText: 'Yes',
        key: 'label.YES',
        type: 'label',
        value: 'YES',
      },
      {
        defaultText: 'No',
        key: 'label.NO',
        type: 'label',
        value: 'NO',
      },
      {
        defaultText: 'Void',
        key: 'label.VOID',
        type: 'label',
        value: 'VOID',
      },
      {
        defaultText: 'Emp',
        key: 'label.EMP',
        type: 'label',
        value: 'EMP',
      },
      {
        defaultText: 'Time',
        key: 'label.TIME',
        type: 'label',
        value: 'TIME',
      },
      {
        defaultText: 'Paid',
        key: 'label.PAID',
        type: 'label',
        value: 'PAID',
      },
      {
        defaultText: 'Select Employee',
        key: 'label.SELECT_EMPLOYEE',
        type: 'label',
        value: 'SELECT_EMPLOYEE',
      },
      {
        defaultText: 'Employee',
        key: 'label.EMPLOYEE',
        type: 'label',
        value: 'EMPLOYEE',
      },
      {
        defaultText: 'No Records Found',
        key: 'customMessage.NO_RECORDS_FOUND',
        type: 'customMessage',
        value: 'NO_RECORDS_FOUND',
      },
      {
        defaultText: 'Update Check Name',
        key: 'label.UPDATE_CHECK_NAME',
        type: 'label',
        value: 'UPDATE_CHECK_NAME',
      },
      {
        defaultText: 'Scan',
        key: 'label.OPEN_SCAN',
        type: 'label',
        value: 'OPEN_SCAN',
      },
      {
        defaultText: 'Table',
        key: 'label.OPEN_TABLE',
        type: 'label',
        value: 'OPEN_TABLE',
      },
      {
        defaultText: 'Tab',
        key: 'label.OPEN_TAB',
        type: 'label',
        value: 'OPEN_TAB',
      },
      {
        defaultText: 'Other',
        key: 'label.OPEN_OTHER',
        type: 'label',
        value: 'OPEN_OTHER',
      },
      {
        defaultText: 'This will void the item from the check. this operation cannot be reversed. do you want to continue?',
        key: 'customMessage.SENT_VOID',
        type: 'customMessage',
        value: 'SENT_VOID',
      },
      {
        defaultText: 'Ensure that you fit the QR code completely into the box below',
        key: 'customMessage.QR_CODE_MESSAGE',
        type: 'customMessage',
        value: 'QR_CODE_MESSAGE',
      },
      {
        defaultText: 'Change Orientation',
        key: 'label.CHANGE_ORIENTATION',
        type: 'label',
        value: 'CHANGE_ORIENTATION',
      },
      {
        defaultText: 'Job type',
        key: 'label.JOB_TYPE',
        type: 'label',
        value: 'JOB_TYPE',
      },
      {
        defaultText: 'Select Orientation',
        key: 'label.SELECT_ORIENTATION',
        type: 'label',
        value: 'SELECT_ORIENTATION',
      },
      {
        defaultText: 'Left',
        key: 'label.LEFT',
        type: 'label',
        value: 'LEFT',
      },
      {
        defaultText: 'Right',
        key: 'label.RIGHT',
        type: 'label',
        value: 'RIGHT',
      },
      {
        defaultText: 'Cash Out',
        key: 'label.CASH_OUT',
        type: 'label',
        value: 'CASH_OUT',
      },
      {
        defaultText: 'Cash In',
        key: 'label.CASH_IN',
        type: 'label',
        value: 'CASH_IN',
      },
      {
        defaultText: 'Cash payment has to be processed by server. Do you want to continue?',
        key: 'customMessage.CASH_PAYMENT_PROCESSED_BY_SERVER',
        type: 'customMessage',
        value: 'CASH_PAYMENT_PROCESSED_BY_SERVER',
      },
      {
        defaultText: 'Speed order',
        key: 'label.SPEED_ORDER',
        type: 'label',
        value: 'SPEED_ORDER',
      },
      {
        defaultText: 'send sms',
        key: 'label.SEND_SMS',
        type: 'label',
        value: 'SEND_SMS',
      },
      {
        defaultText: 'send qr link',
        key: 'label.SEND_QR_LINK',
        type: 'label',
        value: 'SEND_QR_LINK',
      },
      {
        defaultText: 'send an sms link to allow qr order and pay',
        key: 'customMessage.SEND_AN_SMS_LINK_TO_ALLOW_QR_ORDER_AND_PAY',
        type: 'customMessage',
        value: 'SEND_AN_SMS_LINK_TO_ALLOW_QR_ORDER_AND_PAY',
      },
      {
        defaultText: 'add phone number',
        key: 'customMessage.ADD_PHONE_NUMBER',
        type: 'customMessage',
        value: 'ADD_PHONE_NUMBER',
      },
      {
        defaultText: 'the check has a payment which should be reversed from payment adjustment report',
        key: 'label.THE_CHECK_HAS_A_PAYMENT_WHICH_SHOULD_BE_REVERSED_FROM_PAYMENT_ADJUSTMENT_ REPORT',
        type: 'label',
        value: 'THE_CHECK_HAS_A_PAYMENT_WHICH_SHOULD_BE_REVERSED_FROM_PAYMENT_ADJUSTMENT_ REPORT',
      },
      {
        defaultText: 'this will void the check this operation cannot be reversed do you want to continue?',
        key: 'label.THIS_WILL_VOID_THE_CHECK_THIS_OPERATION_CANNOT_BE_REVERSED_DO_YOU_WANT_TO_CONTINUE?',
        type: 'label',
        value: 'THIS_WILL_VOID_THE_CHECK_THIS_OPERATION_CANNOT_BE_REVERSED_DO_YOU_WANT_TO_CONTINUE?',
      },
      {
        defaultText: 'transfer table',
        key: 'label.TRANSFER_TABLE',
        type: 'label',
        value: 'TRANSFER_TABLE',
      },
      {
        defaultText: 'transfer employee',
        key: 'label.TRANSFER_EMPLOYEE',
        type: 'label',
        value: 'TRANSFER_EMPLOYEE',
      },
      {
        defaultText: 'merge check',
        key: 'label.MERGE_CHECK',
        type: 'label',
        value: 'MERGE_CHECK',
      },
      {
        defaultText: 'game cards',
        key: 'label.GAME_CARDS',
        type: 'label',
        value: 'GAME_CARDS',
      },
      {
        defaultText: 'table merge',
        key: 'label.TABLE_MERGE',
        type: 'label',
        value: 'TABLE_MERGE',
      },
      {
        defaultText: 'Tax Exempt',
        key: 'label.TAX_EXEMPT',
        type: 'label',
        value: 'TAX_EXEMPT',
      },
      {
        defaultText: 'this will void the check this operation cannot be reversed do you want to continue?',
        key: 'customMessage.CHECK_VOID',
        type: 'customMessage',
        value: 'CHECK_VOID',
      },
      {
        defaultText: 'Print Table QR',
        key: 'label.PRINT_TABLE_QR',
        type: 'label',
        value: 'PRINT_TABLE_QR',
      },
      {
        defaultText: 'This will send the order and SMS the guest a payment link. Do you want to continue?',
        key: 'customMessage.TEXT_TO_PAY_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'TEXT_TO_PAY_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'This will remove the tax from the check. This operation cannot be reversed. Do you want to continue?',
        key: 'customMessage.REMOVE_TAX_EXEMPT',
        type: 'customMessage',
        value: 'REMOVE_TAX_EXEMPT',
      },
      {
        defaultText: 'Table',
        key: 'label.CHECK_LISTING_TABLE_NUMBER',
        type: 'label',
        value: 'CHECK_LISTING_TABLE_NUMBER',
      },
      {
        defaultText: 'Check#',
        key: 'label.CHECK_LISTING_CHECK_NUMBER',
        type: 'label',
        value: 'CHECK_LISTING_CHECK_NUMBER',
      },
      {
        defaultText: 'Owner',
        key: 'label.CHECK_LISTING_OWNER',
        type: 'label',
        value: 'CHECK_LISTING_OWNER',
      },
      {
        defaultText: 'Paid',
        key: 'label.CHECK_LISTING_PAID',
        type: 'label',
        value: 'CHECK_LISTING_PAID',
      },
      {
        defaultText: 'Balance',
        key: 'label.CHECK_LISTING_BALANCE',
        type: 'label',
        value: 'CHECK_LISTING_BALANCE',
      },
      {
        defaultText: 'Time',
        key: 'label.CHECK_LISTING_TIME',
        type: 'label',
        value: 'CHECK_LISTING_TIME',
      },
      {
        defaultText: 'LOYALTY ACCOUNT BALANCE',
        key: 'label.LOYALTY_ACCOUNT_BALANCE',
        type: 'label',
        value: 'LOYALTY_ACCOUNT_BALANCE',
      },
      {
        defaultText: 'GIFT CARD BALANCE',
        key: 'label.GIFT_BALANCE',
        type: 'label',
        value: 'GIFT_BALANCE',
      },
    ]
  },
  OrdersPage: {
    screenLabel: 'Order & Cart Screen',
    image: [orderScreen],
    customLabels: [
      {
        defaultText: 'Clear',
        key: 'label.CLEAR',
        type: 'label',
        value: 'CLEAR',
      },
      {
        defaultText: 'Due',
        key: 'label.DUE',
        type: 'label',
        value: 'DUE',
      },
      {
        defaultText: 'Enter Check Name',
        key: 'customMessage.ENTER_CHECK_NAME',
        type: 'customMessage',
        value: 'ENTER_CHECK_NAME',
      },
      {
        defaultText: 'Enter Seat Number',
        key: 'customMessage.ENTER_SEAT_NUMBER',
        type: 'customMessage',
        value: 'ENTER_SEAT_NUMBER',
      },
      {
        defaultText: 'Occupy Seat',
        key: 'label.OCCUPY_SEAT',
        type: 'label',
        value: 'OCCUPY_SEAT',
      },
      {
        defaultText: 'Seat',
        key: 'label.SEAT',
        type: 'label',
        value: 'SEAT',
      },
      {
        defaultText: 'Total',
        key: 'label.TOTAL',
        type: 'label',
        value: 'TOTAL',
      },
      {
        defaultText: 'View',
        key: 'label.VIEW',
        type: 'label',
        value: 'VIEW',
      },
      {
        defaultText: 'Modify',
        key: 'label.MODIFY',
        type: 'label',
        value: 'MODIFY',
      },
      {
        defaultText: 'OK',
        key: 'label.OK',
        type: 'label',
        value: 'OK',
      },
      {
        defaultText: 'Remove',
        key: 'label.REFILL_REMOVE',
        type: 'label',
        value: 'REFILL_REMOVE',
      },
      {
        defaultText: 'This will delete item from the cart. Do you want to continue ?',
        key: 'customMessage.SINGLE_DELETE_MSG',
        type: 'customMessage',
        value: 'SINGLE_DELETE_MSG',
      },
      {
        defaultText: 'This will send the item from the cart. Do you want to continue?',
        key: 'customMessage.SINGLE_SEND_MSG',
        type: 'customMessage',
        value: 'SINGLE_SEND_MSG',
      },
      {
        defaultText: 'This will delete all unsent items. Do you want to continue ?',
        key: 'customMessage.DELETE_ALL_UNSENT_MSG',
        type: 'customMessage',
        value: 'DELETE_ALL_UNSENT_MSG',
      },
      {
        defaultText: 'This will send all items from the cart. Do you want to continue ?',
        key: 'customMessage.SEND_ALL_UNSENT_MSG',
        type: 'customMessage',
        value: 'SEND_ALL_UNSENT_MSG',
      },
      {
        defaultText: 'This will clear all items from the seat and move it to the table. do you want to continue?',
        key: 'customMessage.CLEAR_ALL_FROM_SEAT',
        type: 'customMessage',
        value: 'CLEAR_ALL_FROM_SEAT',
      },
      {
        defaultText: 'Transfer',
        key: 'label.TRANSFER',
        type: 'label',
        value: 'TRANSFER',
      },
      {
        defaultText: 'Retry',
        key: 'label.RETRY',
        type: 'label',
        value: 'RETRY',
      },
      {
        defaultText: 'Order Now',
        key: 'label.ORDER_NOW',
        type: 'label',
        value: 'ORDER_NOW',
      },
      {
        defaultText: 'Check Out',
        key: 'label.CHECK_OUT',
        type: 'label',
        value: 'CHECK_OUT',
      },
      {
        defaultText: 'Send now',
        key: 'label.SEND_NOW',
        type: 'label',
        value: 'SEND_NOW',
      },
      {
        defaultText: 'Cart',
        key: 'label.CART',
        type: 'label',
        value: 'CART',
      },
      {
        defaultText: 'Close',
        key: 'label.CLOSE',
        type: 'label',
        value: 'CLOSE',
      },
      {
        defaultText: 'SMS',
        key: 'label.SMS',
        type: 'label',
        value: 'SMS',
      },
      {
        defaultText: 'This will clear all items from the seat and move it to the selected table or seat. Do you want to continue ?',
        key: 'customMessage.CLEAR_SEAT',
        type: 'customMessage',
        value: 'CLEAR_SEAT',
      },
      {
        defaultText: 'This will delete selected items from the cart. Do you want to continue ?',
        key: 'customMessage.MULTIPLE_DELETE_MSG',
        type: 'customMessage',
        value: 'MULTIPLE_DELETE_MSG',
      },
      {
        defaultText: 'This will send selected items from the cart. Do you want to continue ?',
        key: 'customMessage.MULTIPLE_SEND_MSG',
        type: 'customMessage',
        value: 'MULTIPLE_SEND_MSG',
      },
      {
        defaultText: 'This will delete selected item from the cart. Do you want to continue ?',
        key: 'customMessage.SINGLE_SELECTED_DELETE_MSG',
        type: 'customMessage',
        value: 'SINGLE_SELECTED_DELETE_MSG',
      },
      {
        defaultText: 'This will send selected item from the cart. Do you want to continue ?',
        key: 'customMessage.SINGLE_SELECTED_SEND_MSG',
        type: 'customMessage',
        value: 'SINGLE_SELECTED_SEND_MSG',
      },
      {
        defaultText: 'This will delete all unsent item. Do you want to continue ?',
        key: 'customMessage.DELETE_ALL_SINGLE_UNSENT_MSG',
        type: 'customMessage',
        value: 'DELETE_ALL_SINGLE_UNSENT_MSG',
      },
      {
        defaultText: 'This will send all item from the cart. Do you want to continue ?',
        key: 'customMessage.SEND_ALL_SINGLE_UNSENT_MSG',
        type: 'customMessage',
        value: 'SEND_ALL_SINGLE_UNSENT_MSG',
      },
      {
        defaultText: 'To Go',
        key: 'label.TOGO',
        type: 'label',
        value: 'TOGO',
      },
      {
        defaultText: 'On the fly',
        key: 'label.ON_THE_FLY',
        type: 'label',
        value: 'ON_THE_FLY',
      },
      {
        defaultText: 'Runner',
        key: 'label.RUNNER',
        type: 'label',
        value: 'RUNNER',
      },
      {
        defaultText: 'Do not make',
        key: 'label.DO_NOT_MAKE',
        type: 'label',
        value: 'DO_NOT_MAKE',
      },
      {
        defaultText: 'This will repeat item from the cart. Do you want to continue ?',
        key: 'customMessage.REPEAT_ITEM',
        type: 'customMessage',
        value: 'REPEAT_ITEM',
      },
      {
        defaultText: 'This will repeat selected items from the cart. Do you want to continue ?',
        key: 'customMessage.REPEAT_ITEMS',
        type: 'customMessage',
        value: 'REPEAT_ITEMS',
      },
      {
        defaultText: 'Search',
        key: 'label.ITEM_SEARCH',
        type: 'label',
        value: 'ITEM_SEARCH',
      },
      {
        defaultText: 'Profile',
        key: 'label.PROFILE',
        type: 'label',
        value: 'PROFILE',
      },
      {
        defaultText: 'SEND ORDER',
        key: 'label.SEND_ORDER',
        type: 'label',
        value: 'SEND_ORDER',
      },
      {
        defaultText: 'Please select where following items should be routed to',
        key: 'customMessage.Please_select_where_following_items_should_be_routed_to',
        type: 'customMessage',
        value: 'Please_select_where_following_items_should_be_routed_to',
      },
      {
        defaultText: 'keep it open',
        key: 'label.KEEP_IT_OPEN',
        type: 'label',
        value: 'KEEP_IT_OPEN',
      },
      {
        defaultText: 'Please make a selection',
        key: 'customMessage.PLEASE_MAKE_A_SELECTION',
        type: 'customMessage',
        value: 'PLEASE_MAKE_A_SELECTION',
      },
      {
        defaultText: 'order skip',
        key: 'label.ORDER_SKIP',
        type: 'label',
        value: 'ORDER_SKIP',
      },
      {
        defaultText: 'delete discount',
        key: 'label.DELETE_DISCOUNT',
        type: 'label',
        value: 'DELETE_DISCOUNT',
      },
      {
        defaultText: 'Refill',
        key: 'label.REFILL',
        type: 'label',
        value: 'REFILL',
      },
      {
        defaultText: 'update order',
        key: 'label.UPDATE_ORDER',
        type: 'label',
        value: 'UPDATE_ORDER',
      },
      {
        defaultText: 'this will send the order. do you want to continue?',
        key: 'customMessage.SEND_ORDER_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'SEND_ORDER_CONFIRMATION_MESSAGE',
      },
    ]
  },
  ItemDetailsPage: {
    screenLabel: 'Item Details Screen',
    image: [itemdetailsScreen],
    customLabels: [
      {
        defaultText: 'Add to Cart',
        key: 'label.ADD_TO_CART',
        type: 'label',
        value: 'ADD_TO_CART',
      },
      {
        defaultText: 'Update Cart',
        key: 'label.UPDATE_CART',
        type: 'label',
        value: 'UPDATE_CART',
      },
      {
        defaultText: 'Add a note',
        key: 'label.ADD_A_NOTE',
        type: 'label',
        value: 'ADD_A_NOTE',
      },
      {
        defaultText: 'SPECIAL INSTRUCTIONS',
        key: 'label.SPECIAL_INSTRUCTIONS',
        type: 'label',
        value: 'SPECIAL_INSTRUCTIONS',
      },
      {
        defaultText: 'Add to Bag',
        key: 'label.ADD_TO_BAG',
        type: 'label',
        value: 'ADD_TO_BAG',
      },
      {
        defaultText: 'Additional Time',
        key: 'label.ADDITIONAL_TIME',
        type: 'label',
        value: 'ADDITIONAL_TIME',
      },
      {
        defaultText: 'Time restricted',
        key: 'label.TIME_RESTRICTED',
        type: 'label',
        value: 'TIME_RESTRICTED',
      },
      {
        defaultText: 'View Only',
        key: 'label.VIEW_ONLY',
        type: 'label',
        value: 'VIEW_ONLY',
      },
      {
        defaultText: 'Special',
        key: 'label.SPECIAL',
        type: 'label',
        value: 'SPECIAL',
      },
      {
        defaultText: 'Coming soon',
        key: 'label.COMING_SOON',
        type: 'label',
        value: 'COMING_SOON',
      },
      {
        defaultText: 'Limited quantity',
        key: 'label.LIMITED_QUANTITY',
        type: 'label',
        value: 'LIMITED_QUANTITY',
      },
      {
        defaultText: 'Featured',
        key: 'label.FEATURED',
        type: 'label',
        value: 'FEATURED',
      },
      {
        defaultText: 'Out of Stock',
        key: 'label.OUT_OF_STOCK',
        type: 'label',
        value: 'OUT_OF_STOCK',
      },
      {
        defaultText: 'Limited Time',
        key: 'label.LIMITED_TIME',
        type: 'label',
        value: 'LIMITED_TIME',
      },
      {
        defaultText: 'New Item',
        key: 'label.NEW_ITEM',
        type: 'label',
        value: 'NEW_ITEM',
      },
      {
        defaultText: 'Item Facts',
        key: 'label.ITEM_FACTS',
        type: 'label',
        value: 'ITEM_FACTS',
      },
      {
        defaultText: 'Nutrition facts',
        key: 'label.NUTRITION_FACT',
        type: 'label',
        value: 'NUTRITION_FACT',
      },
    ]
  },
  StagePage: {
    screenLabel: 'Stage Screen',
    image: [stageScreen],
    customLabels: [
      {
        defaultText: 'Going Dutch',
        key: 'label.GOING_DUTCH',
        type: 'label',
        value: 'GOING_DUTCH',
      },
      {
        defaultText: 'Clear All',
        key: 'label.CLEAR_ALL',
        type: 'label',
        value: 'CLEAR_ALL',
      },
      {
        defaultText: 'No stages',
        key: 'label.NO_STAGES',
        type: 'label',
        value: 'NO_STAGES',
      },
      {
        defaultText: 'Select seat(s) and click on stage',
        key: 'customMessage.SELECT_SEATS_CLICK_STAGE',
        type: 'customMessage',
        value: 'SELECT_SEATS_CLICK_STAGE',
      },
      {
        defaultText: 'This will delete the stage, do you want to continue ?',
        key: 'customMessage.DELETE_STAGE',
        type: 'customMessage',
        value: 'DELETE_STAGE',
      },
      {
        defaultText: 'Please select a stage to see items',
        key: 'customMessage.PLEASE_SELECT_A_STAGE_TO_SEE_ITEMS',
        type: 'customMessage',
        value: 'PLEASE_SELECT_A_STAGE_TO_SEE_ITEMS',
      },
      {
        defaultText: 'All Stages',
        key: 'label.ALL_STAGES',
        type: 'label',
        value: 'ALL_STAGES',
      },
      {
        defaultText: 'Edit seats',
        key: 'customMessage.Edit_seat',
        type: 'customMessage',
        value: 'Edit_seat',
      },
    ]
  },
  LoyaltyPage: {
    screenLabel: 'Loyalty Screen',
    image: [loyaltyScreen],
    customLabels: [
      {
        defaultText: 'Would you like to join our loyalty program today?',
        key: 'customMessage.LOYALTY_JOIN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_JOIN_MESSAGE',
      },
      {
        defaultText: 'Already a Member',
        key: 'label.ALREADY_A_MEMBER',
        type: 'label',
        value: 'ALREADY_A_MEMBER',
      },
      {
        defaultText: 'Join now',
        key: 'label.JOIN_NOW',
        type: 'label',
        value: 'JOIN_NOW',
      },
      {
        defaultText: 'Not now',
        key: 'label.NOT_NOW',
        type: 'label',
        value: 'NOT_NOW',
      },
      {
        defaultText: 'AccountNumber',
        key: 'label.ACCOUNT_NUMBER',
        type: 'label',
        value: 'ACCOUNT_NUMBER',
      },
      {
        defaultText: 'No offers Available',
        key: 'label.NO_OFFERS',
        type: 'label',
        value: 'NO_OFFERS',
      },
      {
        defaultText: 'Available offers',
        key: 'label.AVAILABLE_OFFERS',
        type: 'label',
        value: 'AVAILABLE_OFFERS',
      },
      {
        defaultText: 'Apply Offer',
        key: 'label.APPLY_OFFER',
        type: 'label',
        value: 'APPLY_OFFER',
      },
      {
        defaultText: 'Skip Offer',
        key: 'label.SKIP_OFFER',
        type: 'label',
        value: 'SKIP_OFFER',
      },
      {
        defaultText: 'QR code',
        key: 'label.QR_CODE',
        type: 'label',
        value: 'QR_CODE',
      },
      {
        defaultText: 'How would you like to join today?',
        key: 'customMessage.LOYALTY_JOIN_IN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_JOIN_IN_MESSAGE',
      },
      {
        defaultText: 'Gift card read successfully',
        key: 'customMessage.SUCCESSFULLY_READ_GIFT_CARD',
        type: 'customMessage',
        value: 'SUCCESSFULLY_READ_GIFT_CARD',
      },
      {
        defaultText: 'How would you like to sign in today?',
        key: 'customMessage.LOYALTY_SIGN_IN_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_SIGN_IN_MESSAGE',
      },
      {
        defaultText: 'Use Coupon',
        key: 'label.USE_COUPON',
        type: 'label',
        value: 'USE_COUPON',
      },
      {
        defaultText: 'Cancel',
        key: 'label.COUPON_CANCEL',
        type: 'label',
        value: 'COUPON_CANCEL',
      },
      {
        defaultText: 'Apply Code',
        key: 'label.APPLY_CODE',
        type: 'label',
        value: 'APPLY_CODE',
      },
      {
        defaultText: 'Account',
        key: 'label.LOYALTY_ACCOUNT',
        type: 'label',
        value: 'LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Points',
        key: 'label.POINTS',
        type: 'label',
        value: 'POINTS',
      },
      {
        defaultText: 'Tier',
        key: 'label.TIER',
        type: 'label',
        value: 'TIER',
      },
      {
        defaultText: 'Enter Coupon Code',
        key: 'customMessage.ENTER_COUPON_CODE',
        type: 'customMessage',
        value: 'ENTER_COUPON_CODE',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.LOYALTY_PHONE_NUMBER',
        type: 'label',
        value: 'LOYALTY_PHONE_NUMBER',
      },
      {
        defaultText: 'Swipe',
        key: 'label.LOYALTY_SWIPE',
        type: 'label',
        value: 'LOYALTY_SWIPE',
      },
      {
        defaultText: 'Scan',
        key: 'label.LOYALTY_SCAN',
        type: 'label',
        value: 'LOYALTY_SCAN',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.LOYALTY_PHONE_PLACEHOLDER',
        type: 'label',
        value: 'LOYALTY_PHONE_PLACEHOLDER',
      },
      {
        defaultText: 'Account Number',
        key: 'label.LOYALTY_ACCOUNT_PLACEHOLDER',
        type: 'label',
        value: 'LOYALTY_ACCOUNT_PLACEHOLDER',
      },
      {
        defaultText: 'SIGN IN TO LOYALTY',
        key: 'label.SIGN_IN_TO_LOYALTY',
        type: 'label',
        value: 'SIGN_IN_TO_LOYALTY',
      },
      {
        defaultText: 'SIGN UP TO LOYALTY',
        key: 'label.SIGN_UP_TO_LOYALTY',
        type: 'label',
        value: 'SIGN_UP_TO_LOYALTY',
      },
      {
        defaultText: 'Scan qr code',
        key: 'label.SCAN_QR_CODE',
        type: 'label',
        value: 'SCAN_QR_CODE',
      },
    ]
  },
  PaymentPage: {
    screenLabel: 'Payment & Receipt Screen',
    image: [paymentScreen],
    customLabels: [
      {
        defaultText: 'Pay An Amount',
        key: 'label.PAY_AN_AMOUNT',
        type: 'label',
        value: 'PAY_AN_AMOUNT',
      },
      {
        defaultText: 'Split Evenly',
        key: 'label.SPLIT_EVENLY',
        type: 'label',
        value: 'SPLIT_EVENLY',
      },
      {
        defaultText: 'SPLIT BY',
        key: 'label.SPLIT_BY',
        type: 'label',
        value: 'SPLIT_BY',
      },
      {
        defaultText: 'Pay Full Check',
        key: 'label.PAY_FULL_CHECK',
        type: 'label',
        value: 'PAY_FULL_CHECK',
      },
      {
        defaultText: 'Continue',
        key: 'label.CONTINUE',
        type: 'label',
        value: 'CONTINUE',
      },
      {
        defaultText: 'Gift Card',
        key: 'label.GIFT_CARD',
        type: 'label',
        value: 'GIFT_CARD',
      },
      {
        defaultText: 'Credit Card',
        key: 'label.CREDIT_CARD',
        type: 'label',
        value: 'CREDIT_CARD',
      },
      {
        defaultText: 'Signature',
        key: 'label.SIGNATURE',
        type: 'label',
        value: 'SIGNATURE',
      },
      {
        defaultText: 'No Receipt',
        key: 'label.NO_RECEIPT',
        type: 'label',
        value: 'NO_RECEIPT',
      },
      {
        defaultText: 'Click on Continue to pay remaining balance',
        key: 'customMessage.CONTINUE_PAY_REMAINING_BALANCE',
        type: 'customMessage',
        value: 'CONTINUE_PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Enter Employee Code',
        key: 'customMessage.ENTER_EMPLOYEE_CODE',
        type: 'customMessage',
        value: 'ENTER_EMPLOYEE_CODE',
      },
      {
        defaultText: 'Come back to see us again soon',
        key: 'customMessage.COME_BACK_SOON',
        type: 'customMessage',
        value: 'COME_BACK_SOON',
      },
      {
        defaultText: 'Swipe Card',
        key: 'label.SWIPE_CARD',
        type: 'label',
        value: 'SWIPE_CARD',
      },
      {
        defaultText: 'Enter Card Number',
        key: 'customMessage.ENTER_CARD_NUMBER',
        type: 'customMessage',
        value: 'ENTER_CARD_NUMBER',
      },
      {
        defaultText: 'Enter Security Code',
        key: 'customMessage.ENTER_SECURITY_CODE',
        type: 'customMessage',
        value: 'ENTER_SECURITY_CODE',
      },
      {
        defaultText: 'Cash',
        key: 'label.CASH',
        type: 'label',
        value: 'CASH',
      },
      {
        defaultText: 'Pay Remaining Balance',
        key: 'label.PAY_REMAINING_BALANCE',
        type: 'label',
        value: 'PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Done',
        key: 'label.DONE',
        type: 'label',
        value: 'DONE',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.SUB_TOTAL',
        type: 'label',
        value: 'SUB_TOTAL',
      },
      {
        defaultText: 'Tax',
        key: 'label.TAX',
        type: 'label',
        value: 'TAX',
      },
      {
        defaultText: 'VAT',
        key: 'label.VAT',
        type: 'label',
        value: 'VAT',
      },
      {
        defaultText: 'Payment',
        key: 'label.PAYMENT',
        type: 'label',
        value: 'PAYMENT',
      },
      {
        defaultText: 'Delivery',
        key: 'label.DELIVERY',
        type: 'label',
        value: 'DELIVERY',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP',
        type: 'label',
        value: 'TIP',
      },
      {
        defaultText: 'PAYMENT COMPLETED',
        key: 'label.PAYMENT_COMPLETED',
        type: 'label',
        value: 'PAYMENT_COMPLETED',
      },
      {
        defaultText: 'PAYMENT FAILED',
        key: 'label.PAYMENT_FAILED',
        type: 'label',
        value: 'PAYMENT_FAILED',
      },
      {
        defaultText: 'Skip Survey',
        key: 'label.SKIP_SURVEY',
        type: 'label',
        value: 'SKIP_SURVEY',
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'label.ENTER_MOBILE_NUMBER',
        type: 'label',
        value: 'ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Enter Account Number',
        key: 'label.ENTER_ACCOUNT_NUMBER',
        type: 'label',
        value: 'ENTER_ACCOUNT_NUMBER',
      },
      {
        defaultText: 'Enter Table Name',
        key: 'label.ENTER_TABLE_NAME',
        type: 'label',
        value: 'ENTER_TABLE_NAME',
      },
      {
        defaultText: 'Text To Pay',
        key: 'label.TEXT_TO_PAY',
        type: 'label',
        value: 'TEXT_TO_PAY',
      },
      {
        defaultText: 'UNPAID',
        key: 'label.UNPAID',
        type: 'label',
        value: 'UNPAID',
      },
      {
        defaultText: 'Price Check',
        key: 'label.PRICE_CHECK',
        type: 'label',
        value: 'PRICE_CHECK',
      },
      {
        defaultText: 'Confirm',
        key: 'label.CONFIRM',
        type: 'label',
        value: 'CONFIRM',
      },
      {
        defaultText: 'TOTAL DUE',
        key: 'label.PAYEMENT_TOTAL_DUE',
        type: 'label',
        value: 'PAYEMENT_TOTAL_DUE',
      },
      {
        defaultText: 'Total',
        key: 'label.PAYMENT_TOTAL',
        type: 'label',
        value: 'PAYMENT_TOTAL',
      },
      {
        defaultText: 'Payments',
        key: 'label.PAYMENTS',
        type: 'label',
        value: 'PAYMENTS',
      },
      {
        defaultText: 'Service Charge',
        key: 'label.SERVICE_CHARGE',
        type: 'label',
        value: 'SERVICE_CHARGE',
      },
      {
        defaultText: 'Discount',
        key: 'label.DISCOUNT_AMOUNT',
        type: 'label',
        value: 'DISCOUNT_AMOUNT',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP_AMOUNT',
        type: 'label',
        value: 'TIP_AMOUNT',
      },
      {
        defaultText: 'Pay later',
        key: 'label.PAY_LATER',
        type: 'label',
        value: 'PAY_LATER',
      },
      {
        defaultText: 'PERCENT',
        key: 'label.PERCENT',
        type: 'label',
        value: 'PERCENT',
      },
      {
        defaultText: 'AMOUNT',
        key: 'label.AMOUNT',
        type: 'label',
        value: 'AMOUNT',
      },
      {
        defaultText: 'Pay Check',
        key: 'label.PAY_CHECK',
        type: 'label',
        value: 'PAY_CHECK',
      },
      {
        defaultText: 'Total Paid',
        key: 'label.TOTAL_PAID',
        type: 'label',
        value: 'TOTAL_PAID',
      },
      {
        defaultText: 'Balance',
        key: 'label.BALANCE',
        type: 'label',
        value: 'BALANCE',
      },
      {
        defaultText: 'Pay Now',
        key: 'label.PAY_NOW',
        type: 'label',
        value: 'PAY_NOW',
      },
      {
        defaultText: 'Tips',
        key: 'label.TIPS',
        type: 'label',
        value: 'TIPS',
      },
      {
        defaultText: 'Paid Tip',
        key: 'label.PAID_TIPS',
        type: 'label',
        value: 'PAID_TIPS',
      },
      {
        defaultText: 'Unpaid Tip',
        key: 'label.UNPAID_TIPS',
        type: 'label',
        value: 'UNPAID_TIPS',
      },
      {
        defaultText: 'Discounts',
        key: 'label.DISCOUNTS',
        type: 'label',
        value: 'DISCOUNTS',
      },
      {
        defaultText: 'Location',
        key: 'label.LOCATION',
        type: 'label',
        value: 'LOCATION',
      },
      {
        defaultText: 'Total due',
        key: 'label.TOTAL_DUE',
        type: 'label',
        value: 'TOTAL_DUE',
      },
      {
        defaultText: 'Payments',
        key: 'label.RECEIPT_PAYMENT',
        type: 'label',
        value: 'RECEIPT_PAYMENT',
      },
      {
        defaultText: 'Signature required',
        key: 'customMessage.SIGNATURE_REQUIRED',
        type: 'customMessage',
        value: 'SIGNATURE_REQUIRED',
      },
      {
        defaultText: 'Select tips or enter a valid tip amount or 0',
        key: 'customMessage.ENTER_TIP_MSG',
        type: 'customMessage',
        value: 'ENTER_TIP_MSG',
      },
      {
        defaultText: 'Amount to pay',
        key: 'customMessage.AMOUNT_TO_PAY',
        type: 'customMessage',
        value: 'AMOUNT_TO_PAY',
      },
      {
        defaultText: 'It was great serving you',
        key: 'customMessage.GREAT_SERVING_YOU',
        type: 'customMessage',
        value: 'GREAT_SERVING_YOU',
      },
      {
        defaultText: 'There was error processing your payment',
        key: 'customMessage.PAYMENT_PROCESSING_ERROR',
        type: 'customMessage',
        value: 'PAYMENT_PROCESSING_ERROR',
      },
      {
        defaultText: 'Proceed with cash payment of',
        key: 'customMessage.PROCEED_WITH_CASH_PAYMENT_OF',
        type: 'customMessage',
        value: 'PROCEED_WITH_CASH_PAYMENT_OF',
      },
      {
        defaultText: 'Please swipe card after the beep',
        key: 'customMessage.SWIPE_CARD_AFTER_BEEP',
        type: 'customMessage',
        value: 'SWIPE_CARD_AFTER_BEEP',
      },
      {
        defaultText: 'Type',
        key: 'label.CARD_TYPE',
        type: 'label',
        value: 'CARD_TYPE',
      },
      {
        defaultText: 'Name',
        key: 'label.NAME',
        type: 'label',
        value: 'NAME',
      },
      {
        defaultText: 'Card #',
        key: 'label.REDACTED_CARD_NUMBER',
        type: 'label',
        value: 'REDACTED_CARD_NUMBER',
      },
      {
        defaultText: 'RECEIPT',
        key: 'label.PAYMENT_RECEIPT',
        type: 'label',
        value: 'PAYMENT_RECEIPT',
      },
      {
        defaultText: 'ENTER TIP',
        key: 'label.ENTER_TIP',
        type: 'label',
        value: 'ENTER_TIP',
      },
      {
        defaultText: 'Previous',
        key: 'label.PREVIOUS',
        type: 'label',
        value: 'PREVIOUS',
      },
      {
        defaultText: 'Next',
        key: 'label.NEXT',
        type: 'label',
        value: 'NEXT',
      },
      {
        defaultText: 'Employee Login',
        key: 'label.EMPLOYEE_LOGIN',
        type: 'label',
        value: 'EMPLOYEE_LOGIN',
      },
      {
        defaultText: 'Call Server',
        key: 'label.CALL_SERVER',
        type: 'label',
        value: 'CALL_SERVER',
      },
      {
        defaultText: 'Other Options',
        key: 'label.OTHER_OPTIONS',
        type: 'label',
        value: 'OTHER_OPTIONS',
      },
      {
        defaultText: 'view detail',
        key: 'label.VIEW_DETAILS',
        type: 'label',
        value: 'VIEW_DETAILS',
      },
      {
        defaultText: 'Cash Payment has to be processed by your server. Please select one of the following options',
        key: 'customMessage.CASH_PAYMENT_PROCESS',
        type: 'customMessage',
        value: 'CASH_PAYMENT_PROCESS',
      },
      {
        defaultText: 'Estimated points earned',
        key: 'label.ESTIMATED_POINTS_EARNED',
        type: 'label',
        value: 'ESTIMATED_POINTS_EARNED',
      },
      {
        defaultText: 'This will delete the loyalty account associated with this check. Do you want to continue?',
        key: 'customMessage.DELETE_LOYALTY_ACCOUNT',
        type: 'customMessage',
        value: 'DELETE_LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Credit card or contactless',
        key: 'label.CREDIT_CARD_OR_CONTACTLESS',
        type: 'label',
        value: 'CREDIT_CARD_OR_CONTACTLESS',
      },
      {
        defaultText: 'Your check total is',
        key: 'label.CHECK_TOTAL_TIP',
        type: 'label',
        value: 'CHECK_TOTAL_TIP',
      },
      {
        defaultText: 'Please select a tip amount',
        key: 'label.PLEASE_SELECT_TIP',
        type: 'label',
        value: 'PLEASE_SELECT_TIP',
      },
      {
        defaultText: 'Continue with new total',
        key: 'label.TIP_CONTINUE',
        type: 'label',
        value: 'TIP_CONTINUE',
      },
      {
        defaultText: 'Custom Amount',
        key: 'label.CUSTOM_AMOUNT',
        type: 'label',
        value: 'CUSTOM_AMOUNT',
      },
      {
        defaultText: 'Custom Percent',
        key: 'label.CUSTOM_PERCENT',
        type: 'label',
        value: 'CUSTOM_PERCENT',
      },
      {
        defaultText: 'Please select your form of payment below',
        key: 'customMessage.SELECT_PAYMENT_TYPE',
        type: 'customMessage',
        value: 'SELECT_PAYMENT_TYPE',
      },
      {
        defaultText: 'Payment is now complete',
        key: 'label.PAYMENT_NOW_COMPLETE',
        type: 'label',
        value: 'PAYMENT_NOW_COMPLETE',
      },
      {
        defaultText: 'How would you like your receipt?',
        key: 'customMessage.SELECT_RECEIPT_TYPE',
        type: 'customMessage',
        value: 'SELECT_RECEIPT_TYPE',
      },
      {
        defaultText: 'PAY',
        key: 'label.STAGE_PAY',
        type: 'label',
        value: 'STAGE_PAY',
      },
      {
        defaultText: 'Please select the check you would like to pay',
        key: 'customMessage.SELECT_CHECK',
        type: 'customMessage',
        value: 'SELECT_CHECK',
      },
      {
        defaultText: 'CASH',
        key: 'label.CASH_PAYMENT',
        type: 'label',
        value: 'CASH_PAYMENT',
      },
      {
        defaultText: 'TOTAL PAID',
        key: 'label.TOTAL_PAYMENT_PAID',
        type: 'label',
        value: 'TOTAL_PAYMENT_PAID',
      },
      {
        defaultText: 'TOTAL DUE',
        key: 'label.TOTAL_DUE_PAYMENT',
        type: 'label',
        value: 'TOTAL_DUE_PAYMENT',
      },
      {
        defaultText: 'NO RECEIPT',
        key: 'label.NO_RECEIPT_OPTION',
        type: 'label',
        value: 'NO_RECEIPT_OPTION',
      },
      {
        defaultText: 'TEXT IT TO ME',
        key: 'label.RECEIPT_TEXT_IT_TO_ME',
        type: 'label',
        value: 'RECEIPT_TEXT_IT_TO_ME',
      },
      {
        defaultText: 'EMAIL IT TO ME',
        key: 'label.RECEIPT_EMAIL_IT_TO_ME',
        type: 'label',
        value: 'RECEIPT_EMAIL_IT_TO_ME',
      },
      {
        defaultText: 'If check is split by persons or amount, then no guest in your party can apply offers to this check. Please request the server to split the check if anybody in your party want to redeem an offer. Do you want to continue with this form of payment?',
        key: 'customMessage.CONFIRM_SPLIT',
        type: 'customMessage',
        value: 'CONFIRM_SPLIT',
      },
      {
        defaultText: 'Some of the items on this check are split across seats. Loyalty offers cannot be applied when items are split. Do you want to continue with payment',
        key: 'customMessage.CONFIRM_STAGE_WITH_SPLIT',
        type: 'customMessage',
        value: 'CONFIRM_STAGE_WITH_SPLIT',
      },
      {
        defaultText: 'CHANGE PAYMENT',
        key: 'label.CHANGE_PAYMENT_TYPE',
        type: 'label',
        value: 'CHANGE_PAYMENT_TYPE',
      },
      {
        defaultText: 'CHANGE',
        key: 'label.CHANGE',
        type: 'label',
        value: 'CHANGE',
      },
      {
        defaultText: 'FINALIZE',
        key: 'label.FINALIZE',
        type: 'label',
        value: 'FINALIZE',
      },
      {
        defaultText: 'DRAWER OPEN',
        key: 'label.DRAWER_OPEN',
        type: 'label',
        value: 'DRAWER_OPEN',
      },
      {
        defaultText: 'COLLECTED',
        key: 'label.COLLECTED',
        type: 'label',
        value: 'COLLECTED',
      },
      {
        defaultText: 'PAPER',
        key: 'label.PAPER',
        type: 'label',
        value: 'PAPER',
      },
      {
        defaultText: 'NONE',
        key: 'label.NONE',
        type: 'label',
        value: 'NONE',
      },
      {
        defaultText: 'DRAWER CLOSED',
        key: 'label.DRAWER_CLOSE',
        type: 'label',
        value: 'DRAWER_CLOSE',
      },
      {
        defaultText: 'Amount Entered',
        key: 'label.AMOUNT_ENTERED',
        type: 'label',
        value: 'AMOUNT_ENTERED',
      },
      {
        defaultText: 'Pending Balance',
        key: 'label.PENDING_BALANCE',
        type: 'label',
        value: 'PENDING_BALANCE',
      },
      {
        defaultText: 'Current Balance',
        key: 'label.CURRENT_BALANCE',
        type: 'label',
        value: 'CURRENT_BALANCE',
      },
      {
        defaultText: 'Amount Owed',
        key: 'label.ACCOUNT_OWED',
        type: 'label',
        value: 'ACCOUNT_OWED',
      },
      {
        defaultText: 'Amount Due',
        key: 'label.AMOUNT_DUE',
        type: 'label',
        value: 'AMOUNT_DUE',
      },
      {
        defaultText: 'Nothing due or owed',
        key: 'label.NOTHING_DUE_OWED',
        type: 'label',
        value: 'NOTHING_DUE_OWED',
      },
      {
        defaultText: 'Server Cash Out Report',
        key: 'label.SERVER_CASHOUT_REPORT',
        type: 'label',
        value: 'SERVER_CASHOUT_REPORT',
      },
      {
        defaultText: 'Cashier Cash Out Report',
        key: 'label.CASHIER_CASHOUT_REPORT',
        type: 'label',
        value: 'CASHIER_CASHOUT_REPORT',
      },
      {
        defaultText: 'Amount Over',
        key: 'label.AMOUNT_OVER',
        type: 'label',
        value: 'AMOUNT_OVER',
      },
      {
        defaultText: 'Amount Short',
        key: 'label.AMOUNT_SHORT',
        type: 'label',
        value: 'AMOUNT_SHORT',
      },
      {
        defaultText: 'Nothing Over or Short',
        key: 'label.NOTHING_OVER_SHORT',
        type: 'label',
        value: 'NOTHING_OVER_SHORT',
      },
      {
        defaultText: 'Bank Amount',
        key: 'label.BANK_AMOUNT',
        type: 'label',
        value: 'BANK_AMOUNT',
      },
      {
        defaultText: 'Paid In Amount',
        key: 'label.PAID_IN_AMOUNT',
        type: 'label',
        value: 'PAID_IN_AMOUNT',
      },
      {
        defaultText: 'Paid Out Amount',
        key: 'label.PAID_OUT_AMOUNT',
        type: 'label',
        value: 'PAID_OUT_AMOUNT',
      },
      {
        defaultText: 'Cash Drop Amount',
        key: 'label.CASH_DROP_AMOUNT',
        type: 'label',
        value: 'CASH_DROP_AMOUNT',
      },
      {
        defaultText: 'Cash Payment Amount',
        key: 'label.CASH_PAYMENT_AMOUNT',
        type: 'label',
        value: 'CASH_PAYMENT_AMOUNT',
      },
      {
        defaultText: 'Credit Card Payment Amount',
        key: 'label.CREDIT_CARD_PAYMENT_AMOUNT',
        type: 'label',
        value: 'CREDIT_CARD_PAYMENT_AMOUNT',
      },
      {
        defaultText: 'Credit Card Tip Amount',
        key: 'label.CREDIT_CARD_TIP_AMOUNT',
        type: 'label',
        value: 'CREDIT_CARD_TIP_AMOUNT',
      },
      {
        defaultText: 'Pending Cash Out Amount',
        key: 'label.PENDING_CASHOUT_AMOUNT',
        type: 'label',
        value: 'PENDING_CASHOUT_AMOUNT',
      },
      {
        defaultText: 'Over Short Amount',
        key: 'label.OVER_SHORT_AMOUNT',
        type: 'label',
        value: 'OVER_SHORT_AMOUNT',
      },
      {
        defaultText: 'Drawer is not responding to open command. Please complete the cash in and contact a manager to open the drawer.',
        key: 'customMessage.DRAWER_CASHIN_FAIL',
        type: 'customMessage',
        value: 'DRAWER_CASHIN_FAIL',
      },
      {
        defaultText: 'Cashier should cash out from their assigned terminal',
        key: 'customMessage.EMPLOYEE_DRAWER_FAIL',
        type: 'customMessage',
        value: 'EMPLOYEE_DRAWER_FAIL',
      },
      {
        defaultText: 'Drawer is not responding to open command. Please complete cash out and request a manger to open the drawer',
        key: 'customMessage.DRAWER_CASHOUT_FAIL',
        type: 'customMessage',
        value: 'DRAWER_CASHOUT_FAIL',
      },
      {
        defaultText: 'Cash Out Summary',
        key: 'label.CASHOUT_SUMMARY',
        type: 'label',
        value: 'CASHOUT_SUMMARY',
      },
      {
        defaultText: 'Tip Out',
        key: 'label.TIP_OUT',
        type: 'label',
        value: 'TIP_OUT',
      },
      {
        defaultText: 'Cash Out',
        key: 'label.CASH_OUT_DEPOSIT_AMOUNT',
        type: 'label',
        value: 'CASH_OUT_DEPOSIT_AMOUNT',
      },
      {
        defaultText: 'ENTER THE AMOUNT YOU WANT TO PAY',
        key: 'label.ENTER_THE_AMOUNT_YOU_WANT_TO_PAY',
        type: 'label',
        value: 'ENTER_THE_AMOUNT_YOU_WANT_TO_PAY',
      },
      {
        defaultText: 'HOW MANY WILL BE SPLITTING?',
        key: 'label.HOW_MANY_WILL_BE_SPLITTING',
        type: 'label',
        value: 'HOW_MANY_WILL_BE_SPLITTING',
      },
      {
        defaultText: 'ENTER THE PERCENT YOU WANT TO TIP',
        key: 'label.ENTER_THE_PERCENT_YOU_WANT_TO_TIP',
        type: 'label',
        value: 'ENTER_THE_PERCENT_YOU_WANT_TO_TIP',
      },
      {
        defaultText: 'ENTER THE AMOUNT YOU WANT TO TIP',
        key: 'label.ENTER_THE_AMOUNT_YOU_WANT_TO_TIP',
        type: 'label',
        value: 'ENTER_THE_AMOUNT_YOU_WANT_TO_TIP',
      },
      {
        defaultText: 'Do you want to continue ?',
        key: 'customMessage.Do_you_want_to_continue',
        type: 'customMessage',
        value: 'Do_you_want_to_continue',
      },
      {
        defaultText: 'PLEASE HAVE YOUR CARD READY BEFORE YOU PROCEED. CLICK THE PAY NOW BUTTON WHEN READY.',
        key: 'customMessage.PLEASE_HAVE_YOUR_CARD_READY',
        type: 'customMessage',
        value: 'PLEASE_HAVE_YOUR_CARD_READY',
      },
      {
        defaultText: '',
        key: 'customMessage.Suggested_tip_is_calculated_on_check_disclaimer',
        type: 'customMessage',
        value: 'Suggested_tip_is_calculated_on_check_disclaimer',
      },
      {
        defaultText: 'Need a receipt?',
        key: 'label.NEED_A_RECEIPT',
        type: 'label',
        value: 'NEED_A_RECEIPT',
      },
      {
        defaultText: 'You’re all done!',
        key: 'label.YOURE_ALL_DONE',
        type: 'label',
        value: 'YOURE_ALL_DONE',
      },
      {
        defaultText: 'clear splits',
        key: 'label.CLEAR_SPLITS',
        type: 'label',
        value: 'CLEAR_SPLITS',
      },
      {
        defaultText: 'split by seats',
        key: 'label.SPLIT_BY_SEATS',
        type: 'label',
        value: 'SPLIT_BY_SEATS',
      },
      {
        defaultText: 'split by selected seats',
        key: 'label.SPLIT_BY_SELECTED_SEATS',
        type: 'label',
        value: 'SPLIT_BY_SELECTED_SEATS',
      },
      {
        defaultText: 'pay remaining splits',
        key: 'label.PAY_REMAINING_SPLITS',
        type: 'label',
        value: 'PAY_REMAINING_SPLITS',
      },
      {
        defaultText: 'pay remaining seats',
        key: 'label.PAY_REMAINING_SEATS',
        type: 'label',
        value: 'PAY_REMAINING_SEATS',
      },
      {
        defaultText: 'Pay with cash or multiple cards?',
        key: 'label.PAY_WITH_CASH_OR_MULTIPLE_CARDS',
        type: 'label',
        value: 'PAY_WITH_CASH_OR_MULTIPLE_CARDS',
      },
      {
        defaultText: 'pay remaining balance',
        key: 'label.PAY_REMAINING_BALANCE',
        type: 'label',
        value: 'PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Split',
        key: 'label.SPLIT',
        type: 'label',
        value: 'SPLIT',
      },
      {
        defaultText: 'Round up my tip',
        key: 'label.ROUND_UP_MY_TIP',
        type: 'label',
        value: 'ROUND_UP_MY_TIP',
      },
    ]
  },
  Survey: {
    screenLabel: 'Survey Screen',
    image: [SurveyScreen],
    customLabels: [
      {
        defaultText: 'Yes',
        key: 'label.SURVEY_YES',
        type: 'label',
        value: 'SURVEY_YES',
      },
      {
        defaultText: 'No',
        key: 'label.SURVEY_NO',
        type: 'label',
        value: 'SURVEY_NO',
      },
      {
        defaultText: 'May we contact you about your experience?',
        key: 'customMessage.MAY_WE_CONTACT_YOU_ABOUT_YOUR_EXPERIENCE?',
        type: 'customMessage',
        value: 'MAY_WE_CONTACT_YOU_ABOUT_YOUR_EXPERIENCE?',
      },
      {
        defaultText: 'What is the best email address to reach you?',
        key: 'customMessage. WHAT_IS_THE_BEST_EMAIL_ADDRESS_TO_REACH_YOU?',
        type: 'customMessage',
        value: ' WHAT_IS_THE_BEST_EMAIL_ADDRESS_TO_REACH_YOU?',
      },
      {
        defaultText: 'Yes also contact me by phone',
        key: 'customMessage.YES_ALSO_CONTACT_ME_BY_PHONE',
        type: 'customMessage',
        value: 'YES_ALSO_CONTACT_ME_BY_PHONE',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'customMessage.ENTER_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Enter Phone Number',
        key: 'customMessage.ENTER_PHONE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_PHONE_NUMBER',
      },
    ]
  },
  Charity: {
    screenLabel: 'Charity Screen',
    image: [CharityScreen],
    customLabels: [
      {
        defaultText: 'Apply Donation',
        key: 'label.APPLY_DONATION',
        type: 'label',
        value: 'APPLY_DONATION',
      },
      {
        defaultText: 'Skip Donation',
        key: 'label.SKIP_DONATION',
        type: 'label',
        value: 'SKIP_DONATION',
      },
      {
        defaultText: 'Custom',
        key: 'label.CUSTOM',
        type: 'label',
        value: 'CUSTOM',
      },
    ]
  },
  OtherScreen: {
    screenLabel: 'Other Screen',
    image: [OtherScreen],
    customLabels: [
      {
        defaultText: 'You will be logged out for inactivity in',
        key: 'customMessage.INACTIVITY_LOG_OUT',
        type: 'customMessage',
        value: 'INACTIVITY_LOG_OUT',
      },
      {
        defaultText: 'Log Out',
        key: 'label.LOG_OUT',
        type: 'label',
        value: 'LOG_OUT',
      },
      {
        defaultText: 'Stay',
        key: 'label.STAY',
        type: 'label',
        value: 'STAY',
      },
      {
        defaultText: 'seconds',
        key: 'label.SECONDS',
        type: 'label',
        value: 'SECONDS',
      },
    ]
  },
  GiftCard: {
    screenLabel: 'Gift/Game Card Screen',
    customLabels: [
      {
        defaultText: 'pending',
        key: 'label.PENDING',
        type: 'label',
        value: 'PENDING',
      },
      {
        defaultText: 'processed',
        key: 'label.PROCESS',
        type: 'label',
        value: 'PROCESS',
      },
      {
        defaultText: 'balance',
        key: 'label.CARD_BALANCE',
        type: 'label',
        value: 'CARD_BALANCE',
      },
      {
        defaultText: 'send and activate',
        key: 'label.SENDANDACTIVATE',
        type: 'label',
        value: 'SENDANDACTIVATE',
      },
      {
        defaultText: 'swipe',
        key: 'label.GAMECARDSWIPE',
        type: 'label',
        value: 'GAMECARDSWIPE',
      },
      {
        defaultText: 'Send and pay',
        key: 'label.SENDANDPAY',
        type: 'label',
        value: 'SENDANDPAY',
      },
      {
        defaultText: 'this will activate the swiped cards. do you want to continue?',
        key: 'customMessage.SWIPE_MESSAGE',
        type: 'customMessage',
        value: 'SWIPE_MESSAGE',
      },
      {
        defaultText: 'balance amount',
        key: 'label.BALANCE_AMOUNT',
        type: 'label',
        value: 'BALANCE_AMOUNT',
      },
      {
        defaultText: 'redeemed amount',
        key: 'label.REDEEMED_AMOUNT',
        type: 'label',
        value: 'REDEEMED_AMOUNT',
      },
      {
        defaultText: 'No receipt option was selected. Do you want to proceed without a receipt?',
        key: 'customMessage.RS_NO_RECEIPT_SELECTED',
        type: 'customMessage',
        value: 'RS_NO_RECEIPT_SELECTED',
      },
      {
        defaultText: 'No',
        key: 'label.RS_NO',
        type: 'label',
        value: 'RS_NO',
      },
      {
        defaultText: 'Yes',
        key: 'label.RS_YES',
        type: 'label',
        value: 'RS_YES',
      },
    ]
  },
  PaymentLandingScreen: {
    screenLabel: 'Payment - Landing Screen',
    image: [PaymentLandingScreen],
    customLabels: [
      {
        defaultText: 'BACK',
        key: 'label.LS_BACK',
        type: 'label',
        value: 'LS_BACK',
      },
      {
        defaultText: 'CLEAR SPLITS',
        key: 'label.LS_CLEAR_SPLITS',
        type: 'label',
        value: 'LS_CLEAR_SPLITS',
      },
      {
        defaultText: 'SPLIT BY SEATS',
        key: 'label.LS_SPLIT_BY_SEATS',
        type: 'label',
        value: 'LS_SPLIT_BY_SEATS',
      },
      {
        defaultText: 'PAY REMAINING SEATS',
        key: 'label.LS_PAY_REMAINING_SEATS',
        type: 'label',
        value: 'LS_PAY_REMAINING_SEATS',
      },
      {
        defaultText: 'SPLIT BY SELECTED SEATS',
        key: 'label.LS_SPLIT_BY_SELECTED_SEATS',
        type: 'label',
        value: 'LS_SPLIT_BY_SELECTED_SEATS',
      },
      {
        defaultText: 'PAY REMAINING SELECTED SEATS',
        key: 'label.LS_PAY_REMAINING_SELECTED_SEATS',
        type: 'label',
        value: 'LS_PAY_REMAINING_SELECTED_SEATS',
      },
      {
        defaultText: 'SPLIT EVENLY',
        key: 'label.LS_SPLIT_EVENLY',
        type: 'label',
        value: 'LS_SPLIT_EVENLY',
      },
      {
        defaultText: 'PAY REMAINING SPLITS',
        key: 'label.LS_PAY_REMAINING_SPLITS',
        type: 'label',
        value: 'LS_PAY_REMAINING_SPLITS',
      },
      {
        defaultText: 'PAY FULL CHECK',
        key: 'label.LS_PAY_FULL_CHECK',
        type: 'label',
        value: 'LS_PAY_FULL_CHECK',
      },
      {
        defaultText: 'PAY BALANCE',
        key: 'label.LS_PAY_BALANCE',
        type: 'label',
        value: 'LS_PAY_BALANCE',
      },
    ]
  },
  PaymentSBSScreen: {
    screenLabel: 'Payment - Split by Seats Screen',
    image: [PayementSBSScreen],
    customLabels: [
      {
        defaultText: 'SEATS + /-',
        key: 'label.SBS_SEATS_INC_DEC',
        type: 'label',
        value: 'SBS_SEATS_INC_DEC',
      },
      {
        defaultText: 'CHECK',
        key: 'label.SBS_CHECK',
        type: 'label',
        value: 'SBS_CHECK',
      },
      {
        defaultText: 'SAVE',
        key: 'label.SBS_SAVE',
        type: 'label',
        value: 'SBS_SAVE',
      },
      {
        defaultText: 'BACK',
        key: 'label.SBS_BACK',
        type: 'label',
        value: 'SBS_BACK',
      },
      {
        defaultText: 'CLICK TO PAY',
        key: 'label.SBS_CLICK_TO_PAY',
        type: 'label',
        value: 'SBS_CLICK_TO_PAY',
      },
      {
        defaultText: 'MOVE',
        key: 'label.SBS_MOVE',
        type: 'label',
        value: 'SBS_MOVE',
      },
      {
        defaultText: 'Submit',
        key: 'label.SBS_SUBMIT',
        type: 'label',
        value: 'SBS_SUBMIT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.SBS_CANCEL',
        type: 'label',
        value: 'SBS_CANCEL',
      },
    ]
  },
  PaymentSplitsBySelectedScreen: {
    screenLabel: 'Payment - Split by Selected Seats Screen',
    image: [PaymentSplitsBySelected],
    customLabels: [
      {
        defaultText: 'SELECT SEAT(S) AND CLICK CREATE CHECKS',
        key: 'customMessage.SBSS_SELECT_SEAT_AND_CREATE_CHECKS',
        type: 'customMessage',
        value: 'SBSS_SELECT_SEAT_AND_CREATE_CHECKS',
      },
      {
        defaultText: 'CREATE CHECK',
        key: 'label.SBSS_CREATE_CHECK',
        type: 'label',
        value: 'SBSS_CREATE_CHECK',
      },
      {
        defaultText: 'SEATS + /-',
        key: 'label.SBSS_SEATS_INC_DEC',
        type: 'label',
        value: 'SBSS_SEATS_INC_DEC',
      },
      {
        defaultText: 'Please select seat(s) to preview items',
        key: 'customMessage.SBSS_SELECT_SEAT_TO_PREVIEW_ITEMS',
        type: 'customMessage',
        value: 'SBSS_SELECT_SEAT_TO_PREVIEW_ITEMS',
      },
      {
        defaultText: 'Please select a check to preview items',
        key: 'customMessage.SBSS_SELECT_CHECK_TO_PREVIEW_ITEMS',
        type: 'customMessage',
        value: 'SBSS_SELECT_CHECK_TO_PREVIEW_ITEMS',
      },
      {
        defaultText: 'CHECK',
        key: 'label.SBSS_CHECK',
        type: 'label',
        value: 'SBSS_CHECK',
      },
      {
        defaultText: 'SAVE',
        key: 'label.SBSS_SAVE',
        type: 'label',
        value: 'SBSS_SAVE',
      },
      {
        defaultText: 'BACK',
        key: 'label.SBSS_BACK',
        type: 'label',
        value: 'SBSS_BACK',
      },
      {
        defaultText: 'CLICK TO PAY',
        key: 'label.SBSS_CLICK_TO_PAY',
        type: 'label',
        value: 'SBSS_CLICK_TO_PAY',
      },
      {
        defaultText: 'MOVE',
        key: 'label.SBSS_MOVE',
        type: 'label',
        value: 'SBSS_MOVE',
      },
      {
        defaultText: 'Submit',
        key: 'label.SBSS_SUBMIT',
        type: 'label',
        value: 'SBSS_SUBMIT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.SBSS_CANCEL',
        type: 'label',
        value: 'SBSS_CANCEL',
      },
    ]
  },
  PaymentSplitEvenlyScreen: {
    screenLabel: 'Payment - Split Evenly Screen',
    image: [PaymentSplitEvenly],
    customLabels: [
      {
        defaultText: 'EACH WILL PAY',
        key: 'label.SE_EACH_WILL_PAY',
        type: 'label',
        value: 'SE_EACH_WILL_PAY',
      },
      {
        defaultText: 'SAVE',
        key: 'label.SE_SAVE',
        type: 'label',
        value: 'SE_SAVE',
      },
      {
        defaultText: 'BACK',
        key: 'label.SE_BACK',
        type: 'label',
        value: 'SE_BACK',
      },
      {
        defaultText: 'CLICK TO PAY',
        key: 'label.SE_CLICK_TO_PAY',
        type: 'label',
        value: 'SE_CLICK_TO_PAY',
      },
    ]
  },
  PaymentCheckPresenterScreen: {
    screenLabel: 'Payment - Check Presenter Screen',
    image: [PaymentCheckPresenterScreen],
    customLabels: [
      {
        defaultText: 'PAY',
        key: 'label.CHECK_PRESENTER_PAY',
        type: 'label',
        value: 'CHECK_PRESENTER_PAY',
      },
      {
        defaultText: 'NEXT CHECK',
        key: 'label.CHECK_PRESENTER_NEXT_CHECK',
        type: 'label',
        value: 'CHECK_PRESENTER_NEXT_CHECK',
      },
      {
        defaultText: 'PREVIOUS CHECK',
        key: 'label.CHECK_PRESENTER_PREVIOUS_CHECK',
        type: 'label',
        value: 'CHECK_PRESENTER_PREVIOUS_CHECK',
      },
      {
        defaultText: 'Tip',
        key: 'label.RECEIPT_TIP',
        type: 'label',
        value: 'RECEIPT_TIP',
      },
      {
        defaultText: 'Total Paid',
        key: 'label.RECEIPT_TOTAL_PAID',
        type: 'label',
        value: 'RECEIPT_TOTAL_PAID',
      },
      {
        defaultText: 'Service Charge',
        key: 'label.RECEIPT_SERVICE_CHARGE',
        type: 'label',
        value: 'RECEIPT_SERVICE_CHARGE',
      },
    ]
  },
  PaymentTipScreen: {
    screenLabel: 'Payment - Tip Screen',
    image: [PayementTipScreen],
    customLabels: [
      {
        defaultText: 'CHECK TOTAL',
        key: 'label.TIP_CHECKTOTAL',
        type: 'label',
        value: 'TIP_CHECKTOTAL',
      },
      {
        defaultText: 'Please select a tip',
        key: 'label.TIP_SELECT',
        type: 'label',
        value: 'TIP_SELECT',
      },
      {
        defaultText: 'OTHER',
        key: 'label.TIP_OTHER',
        type: 'label',
        value: 'TIP_OTHER',
      },
      {
        defaultText: 'No Tip',
        key: 'label.TIP_NO_TIP',
        type: 'label',
        value: 'TIP_NO_TIP',
      },
      {
        defaultText: 'Amount',
        key: 'label.TIP_AMOUNT',
        type: 'label',
        value: 'TIP_AMOUNT',
      },
      {
        defaultText: 'Percent',
        key: 'label.TIP_PERCENT',
        type: 'label',
        value: 'TIP_PERCENT',
      },
      {
        defaultText: 'Tip',
        key: 'label.TIP',
        type: 'label',
        value: 'TIP',
      },
      {
        defaultText: 'No',
        key: 'label.TIP_NO',
        type: 'label',
        value: 'TIP_NO',
      },
      {
        defaultText: 'Yes',
        key: 'label.TIP_YES',
        type: 'label',
        value: 'TIP_YES',
      },
      {
        defaultText: 'Select tips or enter a valid tip amount or 0',
        key: 'customMessage.TIP_VALIDATION_MESSAGE',
        type: 'customMessage',
        value: 'TIP_VALIDATION_MESSAGE',
      },
      {
        defaultText: 'Do you want to continue ?',
        key: 'customMessage.TIP_DO_YOU_WANT_TO_CONTINUE',
        type: 'customMessage',
        value: 'TIP_DO_YOU_WANT_TO_CONTINUE',
      },
    ]
  },
  PaymentOfferScreen: {
    screenLabel: 'Payment - Offers Screen',
    image: [PaymentOfferScreen],
    customLabels: [
      {
        defaultText: 'DUE',
        key: 'label.OS_DUE',
        type: 'label',
        value: 'OS_DUE',
      },
      {
        defaultText: 'ITEMIZED CHECK',
        key: 'label.OS_ITEMIZED_CHECK',
        type: 'label',
        value: 'OS_ITEMIZED_CHECK',
      },
      {
        defaultText: 'SIGN IN TO LOYALTY',
        key: 'label.OS_SIGN_IN_TO_LOYALTY',
        type: 'label',
        value: 'OS_SIGN_IN_TO_LOYALTY',
      },
      {
        defaultText: 'Offer',
        key: 'label.OS_OFFER',
        type: 'label',
        value: 'OS_OFFER',
      },
      {
        defaultText: 'NO OFFERS',
        key: 'label.OS_NO_OFFERS',
        type: 'label',
        value: 'OS_NO_OFFERS',
      },
      {
        defaultText: 'VIEW OFFERS',
        key: 'label.OS_VIEW_OFFERS',
        type: 'label',
        value: 'OS_VIEW_OFFERS',
      },
      {
        defaultText: 'USE A COUPON',
        key: 'label.OS_USE_A_COUPON',
        type: 'label',
        value: 'OS_USE_A_COUPON',
      },
      {
        defaultText: 'Coupon',
        key: 'label.OS_COUPON',
        type: 'label',
        value: 'OS_COUPON',
      },
      {
        defaultText: 'ADD A GIFT CARD',
        key: 'label.OS_ADD_A_GIFT_CARD',
        type: 'label',
        value: 'OS_ADD_A_GIFT_CARD',
      },
      {
        defaultText: 'Balance',
        key: 'label.OS_BALANCE',
        type: 'label',
        value: 'OS_BALANCE',
      },
      {
        defaultText: 'Redeemed',
        key: 'label.OS_REDEEMED',
        type: 'label',
        value: 'OS_REDEEMED',
      },
      {
        defaultText: 'PLEASE HAVE YOUR CARD READY BEFORE YOU PROCEED. CLICK THE PAY BUTTON WHEN READY',
        key: 'label.OS_CARD_READY',
        type: 'label',
        value: 'OS_CARD_READY',
      },
      {
        defaultText: 'PAY WITH CASH OR MULTIPLE CARDS?',
        key: 'label.OS_PAY_WITH_CASH_OR_MULTIPLE_CARDS',
        type: 'label',
        value: 'OS_PAY_WITH_CASH_OR_MULTIPLE_CARDS',
      },
      {
        defaultText: 'Pay With Card',
        key: 'label.OS_PAY_WITH_CARD',
        type: 'label',
        value: 'OS_PAY_WITH_CARD',
      },
    ]
  },
  PaymentLoyaltyScreen: {
    screenLabel: 'Payment - Loyalty Screen',
    image: [PaymentLoyaltyScreen],
    customLabels: [
      {
        defaultText: 'Phone',
        key: 'label.LS_PHONE',
        type: 'label',
        value: 'LS_PHONE',
      },
      {
        defaultText: 'Email',
        key: 'label.LS_EMAIL',
        type: 'label',
        value: 'LS_EMAIL',
      },
      {
        defaultText: 'Account #',
        key: 'label.LS_ACCOUNT',
        type: 'label',
        value: 'LS_ACCOUNT',
      },
      {
        defaultText: 'SIGN IN TO LOYALTY',
        key: 'label.LS_SIGN_IN_TO_LOYALTY',
        type: 'label',
        value: 'LS_SIGN_IN_TO_LOYALTY',
      },
      {
        defaultText: 'Would you like to join our loyalty program today?',
        key: 'label.LS_JOIN_LOYALTY_PROGRAM',
        type: 'label',
        value: 'LS_JOIN_LOYALTY_PROGRAM',
      },
      {
        defaultText: 'JOIN NOW',
        key: 'label.LS_JOIN_NOW',
        type: 'label',
        value: 'LS_JOIN_NOW',
      },
      {
        defaultText: 'ALREADY A MEMBER',
        key: 'label.LS_ALREADY_A_MEMBER',
        type: 'label',
        value: 'LS_ALREADY_A_MEMBER',
      },
      {
        defaultText: 'NOT NOW',
        key: 'label.LS_NOT_NOW',
        type: 'label',
        value: 'LS_NOT_NOW',
      },
      {
        defaultText: 'SIGN UP TO LOYALTY',
        key: 'label.LS_SIGN_UP_TO_LOYALTY',
        type: 'label',
        value: 'LS_SIGN_UP_TO_LOYALTY',
      },
      {
        defaultText: 'How would you like to join today?',
        key: 'label.LS_JOIN_TODAY',
        type: 'label',
        value: 'LS_JOIN_TODAY',
      },
      {
        defaultText: 'Sign Up',
        key: 'label.LS_SIGN_UP',
        type: 'label',
        value: 'LS_SIGN_UP',
      },
      {
        defaultText: 'How would you like to sign in today?',
        key: 'label.LS_SIGN_IN_TODAY',
        type: 'label',
        value: 'LS_SIGN_IN_TODAY',
      },
      {
        defaultText: 'Sign In',
        key: 'label.LS_SIGN_IN',
        type: 'label',
        value: 'LS_SIGN_IN',
      },
      {
        defaultText: 'CANCEL',
        key: 'label.LS_CANCEL',
        type: 'label',
        value: 'LS_CANCEL',
      },
      {
        defaultText: 'CONTINUE',
        key: 'label.LS_CONTINUE',
        type: 'label',
        value: 'LS_CONTINUE',
      },
      {
        defaultText: 'Welcome back!',
        key: 'label.LS_WELCOME_BACK',
        type: 'label',
        value: 'LS_WELCOME_BACK',
      },
      {
        defaultText: 'Value',
        key: 'label.LS_VALUE',
        type: 'label',
        value: 'LS_VALUE',
      },
      {
        defaultText: 'SKIP OFFER',
        key: 'label.LS_SKIP_OFFER',
        type: 'label',
        value: 'LS_SKIP_OFFER',
      },
      {
        defaultText: 'USE OFFER',
        key: 'label.LS_USE_OFFER',
        type: 'label',
        value: 'LS_USE_OFFER',
      },
      {
        defaultText: 'Change Account',
        key: 'label.LS_CHANGE_ACCOUNT',
        type: 'label',
        value: 'LS_CHANGE_ACCOUNT',
      },
    ]
  },
  PaymentCouponScreen: {
    screenLabel: 'Payment - Coupon Screen',
    image: [PaymentCouponScreen],
    customLabels: [
      {
        defaultText: 'Scan Qr Code',
        key: 'label.CS_SCAN_QR_CODE',
        type: 'label',
        value: 'CS_SCAN_QR_CODE',
      },
      {
        defaultText: 'Apply Code',
        key: 'label.CS_APPLY_CODE',
        type: 'label',
        value: 'CS_APPLY_CODE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CS_CANCEL',
        type: 'label',
        value: 'CS_CANCEL',
      },
      {
        defaultText: 'Please scan QR code',
        key: 'customMessage.CS_PLEASE_SCAN_QR',
        type: 'customMessage',
        value: 'CS_PLEASE_SCAN_QR',
      },
    ]
  },
  PaymentGiftCardScreen: {
    screenLabel: 'Payment - Gift Card Screen',
    image: [PaymentGiftCardSscreen],
    customLabels: [
      {
        defaultText: 'CLICK TO SWIPE CARD',
        key: 'label.GS_CLICK_TO_SWIPE_CARD',
        type: 'label',
        value: 'GS_CLICK_TO_SWIPE_CARD',
      },
      {
        defaultText: 'APPLY',
        key: 'label.GS_APPLY',
        type: 'label',
        value: 'GS_APPLY',
      },
      {
        defaultText: 'Click To Enter Card Number',
        key: 'label.GS_CLICK_TO_ENTER_CARD_NUMBER',
        type: 'label',
        value: 'GS_CLICK_TO_ENTER_CARD_NUMBER',
      },
      {
        defaultText: 'Click To Enter Security Code',
        key: 'label.GS_CLICK_TO_ENTER_SECURITY_CODE',
        type: 'label',
        value: 'GS_CLICK_TO_ENTER_SECURITY_CODE',
      },
      {
        defaultText: 'CANCEL',
        key: 'label.GS_CANCEL',
        type: 'label',
        value: 'GS_CANCEL',
      },
    ]
  },
  EmployeeInitiatedPaymentScreen: {
    screenLabel: 'Payment - Employee initiated Payment Screen',
    image: [PayementEIPScreen],
    customLabels: [
      {
        defaultText: 'DUE',
        key: 'label.EPS_DUE',
        type: 'label',
        value: 'EPS_DUE',
      },
      {
        defaultText: 'CANCEL',
        key: 'label.EPS_CANCEL',
        type: 'label',
        value: 'EPS_CANCEL',
      },
      {
        defaultText: 'APPLY',
        key: 'label.EPS_APPLY',
        type: 'label',
        value: 'EPS_APPLY',
      },
      {
        defaultText: 'LOGIN',
        key: 'label.EPS_LOGIN',
        type: 'label',
        value: 'EPS_LOGIN',
      },
      {
        defaultText: 'SELECT PAYMENT METHOD',
        key: 'label.EPS_SELECT_PAYMENT_METHOD',
        type: 'label',
        value: 'EPS_SELECT_PAYMENT_METHOD',
      },
      {
        defaultText: 'PAY CASH',
        key: 'label.EPS_PAY_CASH',
        type: 'label',
        value: 'EPS_PAY_CASH',
      },
      {
        defaultText: 'PAY GIFT CARD',
        key: 'label.EPS_PAY_GIFT_CARD',
        type: 'label',
        value: 'EPS_PAY_GIFT_CARD',
      },
      {
        defaultText: 'PAY CREDIT CARD',
        key: 'label.EPS_PAY_CREDIT_CARD',
        type: 'label',
        value: 'EPS_PAY_CREDIT_CARD',
      },
      {
        defaultText: 'CREDIT CARD',
        key: 'label.EPS_CREDIT_CARD',
        type: 'label',
        value: 'EPS_CREDIT_CARD',
      },
      {
        defaultText: 'GIFT CARD',
        key: 'label.EPS_GIFT_CARD',
        type: 'label',
        value: 'EPS_GIFT_CARD',
      },
      {
        defaultText: 'CASH',
        key: 'label.EPS_CASH',
        type: 'label',
        value: 'EPS_CASH',
      },
      {
        defaultText: 'CHANGE',
        key: 'label.EPS_CHANGE',
        type: 'label',
        value: 'EPS_CHANGE',
      },
      {
        defaultText: 'PAY',
        key: 'label.EPS_PAY',
        type: 'label',
        value: 'EPS_PAY',
      },
      {
        defaultText: 'COLLECTED',
        key: 'label.EPS_COLLECTED',
        type: 'label',
        value: 'EPS_COLLECTED',
      },
      {
        defaultText: 'Credit Card Payment',
        key: 'label.EPS_CREDIT_CARD_PAYMENT',
        type: 'label',
        value: 'EPS_CREDIT_CARD_PAYMENT',
      },
      {
        defaultText: 'REDEEMED',
        key: 'label.EPS_REDEEM',
        type: 'label',
        value: 'EPS_REDEEM',
      },
      {
        defaultText: 'BALANCE',
        key: 'label.EPS_BALANCE',
        type: 'label',
        value: 'EPS_BALANCE',
      },
      {
        defaultText: 'AMOUNT COLLECTED',
        key: 'label.EPS_AMOUNT_COLLECTED',
        type: 'label',
        value: 'EPS_AMOUNT_COLLECTED',
      },
      {
        defaultText: 'Cash Payment has to be processed by your server',
        key: 'customMessage.EPS_CASH_PAYMENT_PROCESSED_BY_SERVER',
        type: 'customMessage',
        value: 'EPS_CASH_PAYMENT_PROCESSED_BY_SERVER',
      },
      {
        defaultText: 'check has a due amount of',
        key: 'customMessage.EPS_CHECK_DUE_AMOUNT',
        type: 'customMessage',
        value: 'EPS_CHECK_DUE_AMOUNT',
      },
      {
        defaultText: '.are you sure you want to exit?',
        key: 'customMessage.EPS_YOU_WANT_TO_EXIT',
        type: 'customMessage',
        value: 'EPS_YOU_WANT_TO_EXIT',
      },
    ]
  },
  PartialPaymentErrorScreen: {
    screenLabel: 'Payment - Partial Payment and Error Screen',
    image: [PaymentPEScreen],
    customLabels: [
      {
        defaultText: 'CHECK',
        key: 'label.PPES_CHECK',
        type: 'label',
        value: 'PPES_CHECK',
      },
      {
        defaultText: 'AUTHORIZED',
        key: 'label.PPES_AUTHORIZED',
        type: 'label',
        value: 'PPES_AUTHORIZED',
      },
      {
        defaultText: 'BALANCE DUE',
        key: 'label.PPES_BALANCE_DUE',
        type: 'label',
        value: 'PPES_BALANCE_DUE',
      },
      {
        defaultText: 'PAY BALANCE',
        key: 'label.PPES_PAY_BALANCE',
        type: 'label',
        value: 'PPES_PAY_BALANCE',
      },
      {
        defaultText: 'PAYMENT NOT PROCESSED',
        key: 'label.PPES_PAYMENT_NOT_PROCESSED',
        type: 'label',
        value: 'PPES_PAYMENT_NOT_PROCESSED',
      },
      {
        defaultText: 'RETRY',
        key: 'label.PPES_RETRY',
        type: 'label',
        value: 'PPES_RETRY',
      },
      {
        defaultText: 'TIP',
        key: 'label.PPES_TIP',
        type: 'label',
        value: 'PPES_TIP',
      },
      {
        defaultText: 'TOTAL',
        key: 'label.PPES_TOTAL',
        type: 'label',
        value: 'PPES_TOTAL',
      },
      {
        defaultText: 'TIP COLLECTED',
        key: 'label.PPES_TIP_COLLECTED',
        type: 'label',
        value: 'PPES_TIP_COLLECTED',
      },
      // {
      //   defaultText: 'TIP DUE',
      //   key: 'label.PPES_TIP_DUE',
      //   type: 'label',
      //   value: 'PPES_TIP_DUE',
      // },
      {
        defaultText: 'CONTINUE',
        key: 'label.PPES_CONTINUE',
        type: 'label',
        value: 'PPES_CONTINUE',
      },
      {
        defaultText: 'UNCOLLECTED TIP',
        key: 'label.PPES_UNCOLLECTED_TIP',
        type: 'label',
        value: 'PPES_UNCOLLECTED_TIP',
      },
      {
        defaultText: 'PAYMENT PARTIALLY APPROVED',
        key: 'label.PPES_PAYMENT_PARTIALLY_APPROVED',
        type: 'label',
        value: 'PPES_PAYMENT_PARTIALLY_APPROVED',
      },
    ]
  },
  PaymentConfirmationScreen: {
    screenLabel: 'Payment - Confirmation Screen',
    image: [PaymentConfirmationScreen],
    customLabels: [
      {
        defaultText: 'CONTINUE',
        key: 'label.PC_CONTINUE',
        type: 'label',
        value: 'PC_CONTINUE',
      },
      {
        defaultText: 'PAYMENT SUCCESSFUL',
        key: 'label.PC_PAYMENT_SUCCESSFUL',
        type: 'label',
        value: 'PC_PAYMENT_SUCCESSFUL',
      },
      {
        defaultText: 'PAYMENT COMPLETE',
        key: 'label.PC_PAYMENT_COMPLETE',
        type: 'label',
        value: 'PC_PAYMENT_COMPLETE',
      },
      {
        defaultText: 'DUE',
        key: 'label.PC_DUE',
        type: 'label',
        value: 'PC_DUE',
      },
      {
        defaultText: 'Details',
        key: 'label.PC_DETAILS',
        type: 'label',
        value: 'PC_DETAILS',
      },
    ]
  },
  PaymentRecieptScreen: {
    screenLabel: 'Payment - Receipt screen',
    image: [PaymentRecieptScreen],
    customLabels: [
      {
        defaultText: 'No Receipt',
        key: 'label.RS_NO_RECEIPT',
        type: 'label',
        value: 'RS_NO_RECEIPT',
      },
      {
        defaultText: 'Due',
        key: 'label.RS_DUE',
        type: 'label',
        value: 'RS_DUE',
      },
      {
        defaultText: 'Need a Receipt?',
        key: 'label.RS_NEED_A_RECEIPT',
        type: 'label',
        value: 'RS_NEED_A_RECEIPT',
      },
      {
        defaultText: 'Payment Complete!',
        key: 'customMessage.RS_PAYMENT_COMPLETE',
        type: 'customMessage',
        value: 'RS_PAYMENT_COMPLETE',
      },
      {
        defaultText: 'It was Great Serving You!',
        key: 'customMessage.RS_IT_WAS_GREAT_SERVING_YOU',
        type: 'customMessage',
        value: 'RS_PAYMENT_COMPLETE',
      },
      {
        defaultText: 'Please Come Back To See Us Again Soon.',
        key: 'customMessage.RS_PLEASE_COME_BACK_SOON',
        type: 'customMessage',
        value: 'RS_PLEASE_COME_BACK_SOON',
      },
      {
        defaultText: 'You’re all done!',
        key: 'label.RS_YOU_ARE_ALL_DONE',
        type: 'label',
        value: 'RS_YOU_ARE_ALL_DONE',
      },
    ]
  },
};
