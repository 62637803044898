import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import users from './users';
import otp from './otp';
import oauth from './oauth';
import password from './password';
import header from './header';
import profile from './profile';
import notification from './notification';
import theme from './theme';
import company from './company';
import mall from './mall';
import brand from './brand';
import customIcons from './customIcons';
import store from './store';
import device from './device';
import utils from './utils';
import batchservices from './batchservices';
import mapping from './mapping';
import serverApp from './serverApp';
import paymentSystem from './paymentSystem';
import charity from './charity';
import tables from './tables';
import tips from './tips';
import offerSystem from './offerSystem';
import loyalty from './loyalty';
import bookingsystem from './bookingsystem';
import possystem from './possystem';
import survey from './survey';
import posMenu from './posMenu';
import customMenuData from './customMenu/customMenuData';
import menu from './customMenu/menu';
import menuItem from './customMenu/menuItem';
import screen from './customMenu/screen';
import modifierModifierGroup from './customMenu/modifierModifierGroup';
import reports from './reports';
import guestReport from './guestReport';
import companyFilter from './companyFilter';
import ticket from './tickets';
import systemStatus from './systemStatus';
import jobs from './jobs';
import tableGroups from './tableGroups';
import receipt from './receipt';
import sensorManagement from './sensorManagement';
import checkDetail from './checkDetail';
import storeSetup from './storeSetup';
import offPremiseSetup from './offPremiseSetup';
import taxsetup from './taxsetup';
import channelConfig from './channelConfig';
import storeManager from './storeManager';
import menuScreen from './customMenu/menuScreen';
import menuLocationSetup from './customMenu/menuLocationSetup';
import screenLocation from './customMenu/screenLocationSetup';
import enterpriseLocationSetup from './enterpriseLocationSetup';
import mergeSetup from './mergeSetup';
import cardHandler from './cardHandler';
import customModifires from './customMenu/modifires';
import mobilePaymentSetup from './mobilePaymentSetup';
import ingenico from './ingenico';
import adminTags from './adminTags';
import tile from './tile';
import wareHouseReports from './wareHouseReports';
import v3Header from './v3header';
import screenSchedule from './screenSchedules';
import employee from './employee';
import namedSensor from './namedSensor';
import cache from './cache';
import operations from './operations';
import seatOrderApproval from './seatOrderApproval';
import mdmPolicy from './mdmPolicy';
import mdmDevice from './mdmDevice';
import tmsStoreChannelSetup from './tms/tmsStoreChannelSetup';
import heartland from './heartland';
import sparkFlySetup from './sparkFlySetup';
import newReleases from './whatsNew';
import releaseNote from './releaseNote';
import svsSetup from './svsSetup';
import punchh from './punchh';
import spendgo from './spendgo';
import fishbowl from './fishbowl';
import paypal from './paypal';
import sensorGroup from './sensorGroup';
import deliveryScreen from './deliveryScreen';
import menuSensorGroupSetUp from './menuSensorGroupSetUp';
import offerManagement from './offerManagement';
import omsSetup from './omsSetup';
import stripe from './stripe';
import campaign from './campaign';
import smsTemplate from './smsTemplate';
import brandHolidaysSetup from './brandHolidaysSetup';
import prepTime from './prepTime';
import spreedlyForToast from './spreedlyForToast';
import systemNotifications from './systemNotifications';
import segment from './segment';
import campaignOccurrence from './campaignOccurrence';
import campaignTriggers from './campaignTriggers';
import brandEmailTemplate from './brandEmailTemplate';
import brandReceiptTemplate from './brandReceiptTemplate';
import orderLabelTemplate from './orderLabelTemplate';
import emailEditor from './emailEditor';
import deliverySetup from './deliverySetup';
import categoryMenu from './categoryMenu';
import restaurantMagic from './restaurantMagic';
import givex from './givex';
import tattleSurvey from './tattleSurvey';
import storeSetupOptions from './storeSetupOptions';
import brandCharges from './brandCharges';
import chowly from './chowly';
import dataRequest from './dataRequest';
import printCategory from './printCategory';
import brandCharity from './brandCharity';
import posAgent from './posAgent';
import productionRate from './productionRate';
import vouchers from './vouchers';
import languages from './languages';
import cubby from './cubby';
import webhooks from './webhooks';
import fiserv from './fiserv';
import menuEnterprise from './customMenu/menuEnterprise';
import multiLingual from './multiLingual';
import empApp from './empApp/empApp';
import financialAccounts from './financialAccounts';
import empAppPaymentAdjustment from './empApp/empAppPaymentAdjustment';
import riskRules from './riskRules';
import siftFraud from './siftFraud';
import marketMan from './marketMan';
import applePay from './applePay';
import valueTecGiftCard from './valueTecGiftCard';
import tsdGlobal from './brandThirdParty/tsdGlobal';
import menuCourses from './menuCourses';
import userWay from './userWay';
import cabbagePay from './cabbagePay';
import otter from './otter';
import brandMarketPlace from './brandMarketPlace';
import happyHourRule from './happyHourRule';
import kdsSetup from './kdsSetup';
import amazonPay from './amazonPay';
import tileSensorGroupSetUp from './tileSensorGroupSetUp';
import medallia from './medallia';
import externalIntegrations from './externalIntegrations';
import mobileApp from './mobileApp';
import happyCog from './happyCog';
import integrationConfigurations from './integrationConfigurations';
import menuDeviceGroupSetUp from './menuDeviceGroupSetUp';
import configData from './configData';
import mainContent from './v4/mainContent';
import intercard from './intercard';
import powerCard from './powerCard';
import giftcard from './giftcard';
import ezCater from './ezCater';
import brandPosSystem from './brandPosSystem';
import roktIntegration from './roktIntegration';
import dbSetup from './dbSetup';
import roleTemplates from './roleTemplates';
import companyRoleTemplate from './companyRoleTemplate';
import empAppTableSensor from './empApp/managerSensorTable';
import mwebCustomizations from './mWebCustomizations';
import permissionsTemplate from './permissionsTemplates';

const appReducer = combineReducers({
  router: routerReducer,
  users,
  otp,
  oauth,
  password,
  header,
  profile,
  notification,
  theme,
  customIcons,
  company,
  mall,
  brand,
  store,
  device,
  utils,
  smsTemplate,
  batchservices,
  mapping,
  serverApp,
  paymentSystem,
  charity,
  tables,
  tips,
  offerSystem,
  categoryMenu,
  loyalty,
  bookingsystem,
  possystem,
  survey,
  posMenu,
  customMenuData,
  menu,
  menuItem,
  screen,
  systemNotifications,
  modifierModifierGroup,
  reports,
  guestReport,
  companyFilter,
  ticket,
  systemStatus,
  jobs,
  tableGroups,
  receipt,
  sensorManagement,
  checkDetail,
  storeSetup,
  offPremiseSetup,
  taxsetup,
  channelConfig,
  storeManager,
  menuScreen,
  enterpriseLocationSetup,
  mergeSetup,
  cardHandler,
  menuLocationSetup,
  screenLocation,
  dataRequest,
  customModifires,
  mobilePaymentSetup,
  ingenico,
  brandCharity,
  tile,
  screenSchedule,
  v3Header,
  employee,
  cache,
  namedSensor,
  operations,
  seatOrderApproval,
  mdmPolicy,
  mdmDevice,
  tmsStoreChannelSetup,
  heartland,
  sparkFlySetup,
  newReleases,
  releaseNote,
  svsSetup,
  punchh,
  spendgo,
  wareHouseReports,
  fishbowl,
  paypal,
  adminTags,
  storeSetupOptions,
  sensorGroup,
  deliveryScreen,
  offerManagement,
  menuSensorGroupSetUp,
  omsSetup,
  stripe,
  campaign,
  brandHolidaysSetup,
  prepTime,
  spreedlyForToast,
  segment,
  campaignOccurrence,
  campaignTriggers,
  brandEmailTemplate,
  brandReceiptTemplate,
  orderLabelTemplate,
  emailEditor,
  deliverySetup,
  restaurantMagic,
  givex,
  tattleSurvey,
  brandCharges,
  chowly,
  printCategory,
  productionRate,
  posAgent,
  vouchers,
  languages,
  cubby,
  webhooks,
  fiserv,
  menuEnterprise,
  multiLingual,
  empApp,
  financialAccounts,
  empAppPaymentAdjustment,
  riskRules,
  siftFraud,
  marketMan,
  applePay,
  valueTecGiftCard,
  tsdGlobal,
  menuCourses,
  userWay,
  cabbagePay,
  otter,
  brandMarketPlace,
  happyHourRule,
  kdsSetup,
  amazonPay,
  tileSensorGroupSetUp,
  medallia,
  externalIntegrations,
  mobileApp,
  happyCog,
  integrationConfigurations,
  menuDeviceGroupSetUp,
  configData,
  mainContent,
  intercard,
  powerCard,
  giftcard,
  ezCater,
  brandPosSystem,
  roktIntegration,
  dbSetup,
  roleTemplates,
  companyRoleTemplate,
  empAppTableSensor,
  mwebCustomizations,
  permissionsTemplate
});

// Setup root reducer
const rootReducer = (state, action) => {
  if (action.type === 'oauth/LOGOUT_REQUEST') {
    state = undefined; // eslint-disable-line
  }
  return appReducer(state, action);
};

export default rootReducer;
