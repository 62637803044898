export const GET_LANGUAGES_LIST_REQUESTED = 'languages/GET_LANGUAGES_LIST_REQUESTED';
export const GET_LANGUAGES_LIST_SUCCESS = 'languages/GET_LANGUAGES_LIST_SUCCESS';
export const GET_LANGUAGES_LIST_FAILURE = 'languages/GET_LANGUAGES_LIST_FAILURE';

export const GET_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_BRAND_LANGUAGES_LIST_REQUESTED = 'languages/GET_BRAND_LANGUAGES_LIST_REQUESTED';
export const GET_BRAND_LANGUAGES_LIST_SUCCESS = 'languages/GET_BRAND_LANGUAGES_LIST_SUCCESS';
export const GET_BRAND_LANGUAGES_LIST_FAILURE = 'languages/GET_BRAND_LANGUAGES_LIST_FAILURE';

export const ADD_BRAND_LANGUAGE_REQUESTED = 'languages/ADD_BRAND_LANGUAGE_REQUESTED';
export const ADD_BRAND_LANGUAGE_SUCCESS = 'languages/ADD_BRAND_LANGUAGE_SUCCESS';
export const ADD_BRAND_LANGUAGE_FAILURE = 'languages/ADD_BRAND_LANGUAGE_FAILURE';

export const DELETE_BRAND_LANGUAGE_REQUESTED = 'languages/DELETE_BRAND_LANGUAGE_REQUESTED';
export const DELETE_BRAND_LANGUAGE_SUCCESS = 'languages/DELETE_BRAND_LANGUAGE_SUCCESS';
export const DELETE_BRAND_LANGUAGE_FAILURE = 'languages/DELETE_BRAND_LANGUAGE_FAILURE';

export const SET_DEFAULT_BRAND_LANGUAGE_REQUESTED = 'languages/SET_DEFAULT_BRAND_LANGUAGE_REQUESTED';
export const SET_DEFAULT_BRAND_LANGUAGE_SUCCESS = 'languages/SET_DEFAULT_BRAND_LANGUAGE_SUCCESS';
export const SET_DEFAULT_BRAND_LANGUAGE_FAILURE = 'languages/SET_DEFAULT_BRAND_LANGUAGE_FAILURE';

export const REORDER_BRAND_LANGUAGE_REQUESTED = 'languages/REORDER_BRAND_LANGUAGE_REQUESTED';
export const REORDER_BRAND_LANGUAGE_SUCCESS = 'languages/REORDER_BRAND_LANGUAGE_SUCCESS';
export const REORDER_BRAND_LANGUAGE_FAILURE = 'languages/REORDER_BRAND_LANGUAGE_FAILURE';

export const GET_LANGUAGE_DEFAULT_VALUE_BY_ID_REQUESTED = 'languages/GET_LANGUAGE_DEFAULT_VALUE_BY_ID_REQUESTED';
export const GET_LANGUAGE_DEFAULT_VALUE_BY_ID_SUCCESS = 'languages/GET_LANGUAGE_DEFAULT_VALUE_BY_ID_SUCCESS';
export const GET_LANGUAGE_DEFAULT_VALUE_BY_ID_FAILURE = 'languages/GET_LANGUAGE_DEFAULT_VALUE_BY_ID_FAILURE';

export const GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

export const UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_LANGUAGES_LIST_LOOKUP_REQUESTED = 'languages/GET_LANGUAGES_LIST_LOOKUP_REQUESTED';
export const GET_LANGUAGES_LIST_LOOKUP_SUCCESS = 'languages/GET_LANGUAGES_LIST_LOOKUP_SUCCESS';
export const GET_LANGUAGES_LIST_LOOKUP_FAILURE = 'languages/GET_LANGUAGES_LIST_LOOKUP_FAILURE';

export const UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_REQUESTED = 'languages/UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_REQUESTED';
export const UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_SUCCESS = 'languages/UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_SUCCESS';
export const UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_FAILURE = 'languages/UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_FAILURE';

export const GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED = 'languages/GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED';
export const GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS = 'languages/GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS';
export const GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE = 'languages/GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  languageTypesList: [],
  languageDefaulValuesList: [],
  serverAppLanguageDefaulValuesList: [],
  kioskAppLanguageDefaulValuesList: [],
  tableTopAppLanguageDefaulValuesList: [],
  kdsAppLanguageDefaulValuesList: [],
  orderUpAppLanguageDefaulValuesList: [],
  brandLanguageList: [],
  customizationByLangType: {},
  waitListAppLanguageDefaulValuesList: [],
  languageListData: [],
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LANGUAGES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageTypesList: action.result,
      };
    }
    case GET_LANGUAGES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_LANGUAGES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        brandLanguageList: null,
      };
    }
    case GET_BRAND_LANGUAGES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandLanguageList: action.result,
      };
    }
    case GET_BRAND_LANGUAGES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_BRAND_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_BRAND_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_BRAND_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SET_DEFAULT_BRAND_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SET_DEFAULT_BRAND_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SET_DEFAULT_BRAND_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REORDER_BRAND_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REORDER_BRAND_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REORDER_BRAND_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_LANGUAGE_DEFAULT_VALUE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LANGUAGE_DEFAULT_VALUE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        customizationByLangType: action.result,
      };
    }
    case GET_LANGUAGE_DEFAULT_VALUE_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_LANGUAGES_LIST_LOOKUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LANGUAGES_LIST_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageListData: action.result,
      };
    }
    case GET_LANGUAGES_LIST_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        languageDefaulValuesList: [],
      };
    }
    case GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        languageDefaulValuesList: action.result,
      };
    }
    case GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getLanguageTypesList = () => {
  return {
    types: [GET_LANGUAGES_LIST_REQUESTED, GET_LANGUAGES_LIST_SUCCESS, GET_LANGUAGES_LIST_FAILURE],
    promise: client => client.get('getLanguageList')
  };
};

export const getLanguageDefaultsById = (langiageId) => {
  return {
    types: [GET_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getLangaugeDefaultsById/${langiageId}`)
  };
};

export const saveAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveAdminLanguageDefault', { data })
  };
};

export const getBrandLanguageList = (brandId, appType) => {
  return {
    types: [GET_BRAND_LANGUAGES_LIST_REQUESTED, GET_BRAND_LANGUAGES_LIST_SUCCESS, GET_BRAND_LANGUAGES_LIST_FAILURE],
    promise: client => client.get(`getApplicationLanguage/${appType}/brandId/${brandId}`)
  };
};

export const saveBrandLanguage = (data, appType) => {
  return {
    types: [ADD_BRAND_LANGUAGE_REQUESTED, ADD_BRAND_LANGUAGE_SUCCESS, ADD_BRAND_LANGUAGE_FAILURE],
    promise: client => client.post(`getApplicationLanguage/${appType}/update`, { data })
  };
};

export const deleteBrandLanguage = (brandId, languageId, appType) => {
  return {
    types: [DELETE_BRAND_LANGUAGE_REQUESTED, DELETE_BRAND_LANGUAGE_SUCCESS, DELETE_BRAND_LANGUAGE_FAILURE],
    promise: client => client.post(`getApplicationLanguage/${appType}/delete/brandId/${brandId}/languageId/${languageId}`)
  };
};

export const setDefaultBrandLanguage = (brandId, languageId, appType) => {
  return {
    types: [SET_DEFAULT_BRAND_LANGUAGE_REQUESTED, SET_DEFAULT_BRAND_LANGUAGE_SUCCESS, SET_DEFAULT_BRAND_LANGUAGE_FAILURE],
    promise: client => client.post(`getApplicationLanguage/${appType}/setDefault/brandId/${brandId}/languageId/${languageId}`)
  };
};

export const reOrderBrandLanguage = (brandId, data, appType) => {
  return {
    types: [REORDER_BRAND_LANGUAGE_REQUESTED, REORDER_BRAND_LANGUAGE_SUCCESS, REORDER_BRAND_LANGUAGE_FAILURE],
    promise: client => client.post(`getApplicationLanguage/${appType}/reorder/brandId/${brandId}`, { data })
  };
};

export const getAppCustomizationByLanguageId = (languageId) => {
  return {
    types: [GET_LANGUAGE_DEFAULT_VALUE_BY_ID_REQUESTED, GET_LANGUAGE_DEFAULT_VALUE_BY_ID_SUCCESS, GET_LANGUAGE_DEFAULT_VALUE_BY_ID_FAILURE],
    promise: client => client.get(`getAppCustomizationByLanguageId/${languageId}`)
  };
};

export const getServerAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_SERVER_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getServerAppLangaugeDefaultsById/${languageId}`)
  };
};

export const saveServerAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_SERVER_APP_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveServerAppAdminLanguageDefault', { data })
  };
};

export const getKioskAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_KIOSK_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getKioskAppLangaugeDefaultsById/${languageId}`)
  };
};

export const saveKioskAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_KIOSK_APP_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveKioskAppAdminLanguageDefault', { data })
  };
};

export const getTableTopAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_TABLETOP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getTableTopAppLangaugeDefaultsById/${languageId}`)
  };
};

export const saveTableTopAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_TABLETOP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveTableTopAppAdminLanguageDefault', { data })
  };
};
export const getOrderUpAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_ORDERUP_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getOrderUpAppLangaugeDefaultsById/${languageId}`)
  };
};

export const saveOrderUpAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_ORDERUP_APP_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveOrderUpAppAdminLanguageDefault', { data })
  };
};

export const getKdsAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_KDS_APP_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getKdsAppLanguageDefaultsById/${languageId}`)
  };
};

export const saveKdsAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_KDS_APP_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveKdsAppAdminLanguageDefault', { data })
  };
};
export const getWaitListAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, GET_WAIT_LIST_LANGUAGES_DEFAULTS_BY_ID_LIST_FAILURE],
    promise: client => client.get(`getWaitListAppLangaugeDefaultsById/${languageId}`)
  };
};

export const saveWaitListAppAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_WAIT_LIST_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveWaitListAppAdminLanguageDefault', { data })
  };
};

export const getmWebAppLanguageDefaultsById = (languageId) => {
  return {
    types: [GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_REQUESTED, GET_MWEB_LANGUAGES_DEFAULTS_BY_ID_LIST_SUCCESS, UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.get(`getMwebAppLangaugeDefaultsById/${languageId}`)
  };
};

export const savemWebAdminLanguageDefaultValues = (data) => {
  return {
    types: [UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_REQUESTED, UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_SUCCESS, UPDATE_MWEB_LANGUAGES_DEFAULT_VALUES_FAILURE],
    promise: client => client.post('saveMwebAppAdminLanguageDefault', { data })
  };
};

export const getLanguageListLookup = () => {
  return {
    types: [GET_LANGUAGES_LIST_LOOKUP_REQUESTED, GET_LANGUAGES_LIST_LOOKUP_SUCCESS, GET_LANGUAGES_LIST_LOOKUP_FAILURE],
    promise: client => client.get('getLanguageListLookup')
  };
};
