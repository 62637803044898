/* eslint-disable */

// import checkInImg from '@assets/images/Screenshots/checkInScreen.png';
// import signInImg from '@assets/images/Screenshots/signInScreen.png';
import afterPayment from '@assets/screen/afterPayment.png';
import alreadyAmember from '@assets/screen/alreadyAmember.png';
import checkInScreen from '@assets/screen/checkInScreen.png';
import checkout_cartInBag from '@assets/screen/checkout-cartInBag.png';
import curbside from '@assets/screen/curbside.png';
import forgotPasswordScreen from '@assets/screen/forgotPasswordScreen.png';
import itemDetailScreen from '@assets/screen/itemDetailScreen.png';
import joinNow_emailAddress from '@assets/screen/joinNow-emailAddress.png';
import joinNow_phoneNumber from '@assets/screen/joinNow-phoneNumber.png';
import joinNow from '@assets/screen/joinNow.png';
import loyaltyScreen from '@assets/screen/loyaltyScreen.png';
import moreScreen from '@assets/screen/moreScreen.png';
import myAccountScreen from '@assets/screen/myAccountScreen.png';
import online from '@assets/screen/online.png';
import onlineDelivery from '@assets/screen/onlineDelivery.png';
import onlinePickup from '@assets/screen/onlinePickup.png';
import orderHistoryScreen from '@assets/screen/orderHistoryScreen.png';
import orderScreen from '@assets/screen/orderScreen.png';
import orderScreenTableOrder from '@assets/screen/orderScreenTableOrder.png';
import payLater from '@assets/screen/payLater.png';
import payLaterScreen from '@assets/screen/payLaterScreen.png';
import payLaterSuccessMessageScreen from '@assets/screen/payLaterSuccessMessageScreen.png';
import paymentScreen_applyCouponAndLoyalty from '@assets/screen/paymentScreen-applyCouponAndLoyalty.png';
import payNow_emailScreen from '@assets/screen/payNow-emailScreen.png';
import payNow_smsScreen from '@assets/screen/payNow-smsScreen.png';
import payNowScreen from '@assets/screen/payNowScreen.png';
import payNow from '@assets/screen/payNow.png';
import payNowSuccessMessage from '@assets/screen/payNowSuccessMessage.png';
import phoneNumber_alreadyAmember from '@assets/screen/phoneNumber-alreadyAmember.png';
import profileScreen from '@assets/screen/profileScreen.png';
import qrCodeCheckScreen from '@assets/screen/qrCodeCheckScreen.png';
import receiptPopUp from '@assets/screen/receiptPopUp.png';
import signInScreen_account from '@assets/screen/signInScreen-account.png';
import signInScreen_phoneNumber from '@assets/screen/signInScreen-phoneNumber.png';
import tableOrder_homePage from '@assets/screen/tableOrder-homePage.png';
import upsellingScreenPage from '@assets/screen/upsellingScreenPage.png';
import walkUp from '@assets/screen/walkUp.png';
import paymentPage from '@assets/screen/paymentPage.png';
import landingPage from '@assets/screen/landingPage.png';
import preAuth from '@assets/screen/preAuth.png';
import ordersAndCard from '@assets/screen/ordersPage.png';
import orderPage from '@assets/screen/orderPage.png';
import addToBag from '@assets/screen/addToBag.png';
import modifyAndOrderAnother from '@assets/screen/modifyAndOrderAnother.png';
import updateBag from '@assets/screen/updateBag.png';
import mayWeSuggest from '@assets/screen/mayWeSuggest.png';
import selectStoreCheckOut from '@assets/screen/selectStoreCheckOut.png';
import ordersRetryAndSubmit from '@assets/screen/ordersRetryAndSubmit.png';
import priceCheckPOPIN from '@assets/screen/priceCheckPOPIN.png';
import areUSureDelete from '@assets/screen/areUSureDelete.png';
import coupons from '@assets/screen/coupons.png';
import giftCard from '@assets/screen/giftCard.png';
import paymentPOPUP from '@assets/screen/paymentPOPUP.png';
import paymentAmountScreen from '@assets/screen/paymentAmountScreen.png';
import paymentCharge from '@assets/screen/paymentCharge.png';
import orderWillBePlaced from '@assets/screen/orderWillBePlaced.png';
import receiptFirstPage from '@assets/screen/receiptFirstPage.png';
import receiptSecondPage from '@assets/screen/receiptSecondPage.png';
import payNowSuccessMsg from '@assets/screen/payNowSuccessMsg.png';
import curdSideWalkupPayLater from '@assets/screen/curdSideWalkupPayLater.png';
import oneLinePayLater from '@assets/screen/oneLinePayLater.png';
import ClickToPayLabel from '@assets/screen/ClickToPayLabel.png';
import addItemsToRefill from '@assets/screen/addItemsToRefill.png';
import addItemsToRefill2 from '@assets/screen/addItemsToRefill2.png';
import refillCartMsg from '@assets/screen/refillCartMsg.png';
import refillEmptyCartMSg from '@assets/screen/refillEmptyCartMSg.png';
import waitListFirstScreen from '@assets/screen/waitListFirstScreen.png';
import waitListThirdScreen from '@assets/screen/waitListThirdScreen.png';
import waitListSecondScreen from '@assets/screen/waitListSecondScreen.png';
import waitListPoPUPScreen from '@assets/screen/waitListPoPUPScreen.png';
import waitListSuccessScreen from '@assets/screen/waitListSuccessScreen.png';
import splitCheckFirstScreen from '@assets/screen/splitCheckFirstScreen.png';
import splitCheckSecondScreen from '@assets/screen/splitCheckSecondScreen.png';
import splitCheckThirdScreen from '@assets/screen/splitCheckThirdScreen.png';
import daisyChainFirstScreen from '@assets/screen/daisyChainFirstScreen.png';
import daisyChainSecondScreen from '@assets/screen/daisyChainSecondScreen.png';
import receiptPage from '@assets/screen/receiptPage _1.png';
import FooterMenus from '@assets/screen/footerMenus.png';
import daisyChain from '@assets/screen/daisyChain.png';
import waitList from '@assets/screen/waitList.png';
import HomePage from '@assets/screen/HomePage.png';
import splitCheck from '@assets/screen/splitCheck.png';
import loyalty from '@assets/screen/loyalty.png';
import tableGroupOrdering from '@assets/screen/tableGroupOrdering.png';
import submitOrder from '@assets/screen/submitOrder.png';
import immediateFeature from '@assets/screen/immediateFeature.png';
import LoyaltyAccountNumber from '@assets/screen/loyalty_account_number.png';
import LoyaltyAlreadyMember from '@assets/screen/loyalty_alreadyAmember.png';
import LoyaltyPhoneOrEmail from '@assets/screen/loyalty_PhoneOrEmail.png';
import LoyaltySelectOffer from '@assets/screen/loyalty_selectOffer.png';
import LandingStoreSelect from '@assets/screen/landingStoreSelect.png';
import PaymentPageOrderPlaced from '@assets/screen/paymentPage_orderPlaced.png';
import PaymentPageOrderCharged from '@assets/screen/paymentPage_orderedCharged.png';
import PaymentPageAmount from '@assets/screen/paymentpage_amount.png';
import PaymentPagePercent from '@assets/screen/paymentpage_percent.png';
import PaymentPageTotal from '@assets/screen/paymentpage_total.png';
import OrderCartDeleteNow from '@assets/screen/orderAndCart_deleteNow.png';
import OrderCartSubmitOrder from '@assets/screen/orderAndCart_submitOrder.png';


export default {
  landingPage: {
    screenLabel: 'Landing Page',
    key: 'landingPage',
    image: [landingPage],
    customLabels: [
      {
        data: [
          {
            key: 'label.SPOT_NUMBER',
            defaultText: 'Spot Number',
          },
          {
            key: 'customMessage.YOUR_SPOT_NUMBER',
            defaultText: 'What is your spot number?',
          },
        ],
        defaultText: ['Spot Number', 'What is your spot number?'],
        key: ['label.SPOT_NUMBER', 'customMessage.YOUR_SPOT_NUMBER'],
        image: [curbside],
        screenName: ['curbside']
      },
      {
        data: [
          {
            key: 'customMessage.ENTER_NAME_BELOW',
            defaultText: 'Enter your name below',
          },
          {
            key: 'label.NAME',
            defaultText: 'Name',
          },
          {
            key: 'label.NEXT',
            defaultText: 'Next',
          },
          {
            defaultText: 'Minutes',
            key: 'customMessage.MINUTES',
          },
          {
            defaultText: 'Orders are currently taking',
            key: 'customMessage.ORDER_COMPLETE_MSG',
          },
        ],
        defaultText: ['Enter your name below', 'Name', 'Next', 'Pickup', 'Delivery', 'When would you like to pickup your order?', 'When would you like your order delivered?', 'Select the store you would like to order from'],
        key: ['customMessage.ENTER_NAME_BELOW', 'label.NAME', 'label.NEXT', 'label.PICKUP', 'label.DELIVERY', 'customMessage.PICKUP_YOUR_ORDER', 'customMessage.DELIVER_YOUR_ORDER', 'customMessage.SELECT_STORE_ORDER_FROM'],
        image: [walkUp],
        screenName: ['walkUp', 'online']
      },
      {
        data: [
          {
            key: 'customMessage.ENTER_NAME_BELOW',
            defaultText: 'Enter your name below',
          },
          {
            key: 'label.NAME',
            defaultText: 'Name',
          },
          {
            key: 'label.NEXT',
            defaultText: 'Next',
          },
          {
            key: 'label.PICKUP',
            defaultText: 'Pickup',
          },
          {
            key: 'label.DELIVERY',
            defaultText: 'Delivery',
          },
          {
            key: 'customMessage.PICKUP_YOUR_ORDER',
            defaultText: 'When would you like to pickup your order?',
          },
          {
            defaultText: 'Minutes',
            key: 'customMessage.MINUTES',
          },
          {
            defaultText: 'Orders are currently taking',
            key: 'customMessage.ORDER_COMPLETE_MSG',
          },
        ],
        defaultText: ['Enter your name below', 'Name', 'Next', 'Pickup', 'Delivery', 'When would you like to pickup your order?', 'When would you like your order delivered?', 'Select the store you would like to order from'],
        key: ['customMessage.ENTER_NAME_BELOW', 'label.NAME', 'label.NEXT', 'label.PICKUP', 'label.DELIVERY', 'customMessage.PICKUP_YOUR_ORDER', 'customMessage.DELIVER_YOUR_ORDER', 'customMessage.SELECT_STORE_ORDER_FROM'],
        image: [online],
        screenName: ['online']
      },
      {
        data: [
          {
            key: 'label.NEXT',
            defaultText: 'Next',
          },
          {
            key: 'label.PICKUP',
            defaultText: 'Pickup',
          },
          {
            key: 'label.DELIVERY',
            defaultText: 'Delivery',
          },
          {
            key: 'customMessage.DELIVER_YOUR_ORDER',
            defaultText: 'When would you like your order delivered?',
          },
          {
            key: 'customMessage.SELECT_STORE_ORDER_FROM',
            defaultText: 'Select the store you would like to order from',
          },
        ],
        defaultText: ['Enter your name below', 'Name', 'Next', 'Pickup', 'Delivery', 'When would you like to pickup your order?', 'When would you like your order delivered?', 'Select the store you would like to order from'],
        key: ['customMessage.ENTER_NAME_BELOW', 'label.NAME', 'label.NEXT', 'label.PICKUP', 'label.DELIVERY', 'customMessage.PICKUP_YOUR_ORDER', 'customMessage.DELIVER_YOUR_ORDER', 'customMessage.SELECT_STORE_ORDER_FROM'],
        image: [onlineDelivery],
        screenName: ['onlineDelivery']
      },
      {
        data: [
          {
            key: 'label.NEXT',
            defaultText: 'Next',
          },
        ],
        defaultText: ['Enter your name below', 'Name', 'Next', 'Pickup', 'Delivery', 'When would you like to pickup your order?', 'When would you like your order delivered?', 'Select the store you would like to order from'],
        key: ['customMessage.ENTER_NAME_BELOW', 'label.NAME', 'label.NEXT', 'label.PICKUP', 'label.DELIVERY', 'customMessage.PICKUP_YOUR_ORDER', 'customMessage.DELIVER_YOUR_ORDER', 'customMessage.SELECT_STORE_ORDER_FROM'],
        image: [upsellingScreenPage],
        screenName: ['upsellingScreenPage']
      },
      {
        data: [{ key: 'customMessage.WELCOME_MSG', defaultText: 'Welcome Message' }],
        defaultText: 'Welcome Message',
        key: 'customMessage.WELCOME_MSG',
        image: [LandingStoreSelect],
        screenName: []
      },
    ]
  },
  ordersScreen: {
    screenLabel: 'Orders & Cart',
    key: 'orderAndCart',
    image: [ordersAndCard],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Order',
            key: 'label.ORDER',
          },
        ],
        defaultText: 'Order',
        key: 'label.ORDER',
        image: [orderPage],
        screenName: ['tableOrder_homePage','orderScreenTableOrder','upsellingScreenPage','checkout_cartInBag','moreScreen']
      },
      {
        data: [
          {
            defaultText: 'Add to Bag',
            key: 'label.ADD_TO_CART',
          }
        ],
        defaultText: 'Add to Bag',
        key: 'label.ADD_TO_CART',
        image: [addToBag],
        screenName: ['itemDetailScreen','upsellingScreenPage']
      },
      {
        data: [
          {
            defaultText: 'Modify',
            key: 'label.MODIFY',
          },
          {
            defaultText: 'Order Another',
            key: 'label.ORDER_ANOTHER',
          },
        ],
        defaultText: 'Modify',
        key: 'label.MODIFY',
        image: [modifyAndOrderAnother],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'Update Bag',
            key: 'label.UPDATE_CART',
          }
        ],
        defaultText: 'Update Bag',
        key: 'label.UPDATE_CART',
        image: [updateBag],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'May we suggest?',
            key: 'customMessage.MAY_WE_SUGGEST',
          }
        ],
        defaultText: 'May we suggest?',
        key: 'customMessage.MAY_WE_SUGGEST',
        image: [upsellingScreenPage],
        screenName: ['orderandcart']
      },
      {
        data: [
          {
            defaultText: 'Please select the store to checkout from?',
            key: 'customMessage.STORE_CHECK_OUT',
          }
        ],
        defaultText: 'Please select the store to checkout from?',
        key: 'customMessage.STORE_CHECK_OUT',
        image: [selectStoreCheckOut],
        screenName: ['storeCheckOut']
      },
      {
        data: [
          {
            defaultText: 'Pay Now',
            key: 'label.PAY_NOW',
          },
          {
            defaultText: 'Pay Later',
            key: 'label.PAY_LATER',
          }
        ],
        defaultText: 'Pay Now',
        key: 'label.PAY_NOW',
        image: [checkout_cartInBag,payNowScreen,paymentScreen_applyCouponAndLoyalty],
        screenName: ['checkout_cartInBag','payNowScreen','paymentScreen_applyCouponAndLoyalty']
      },
      {
        data: [
          {
            defaultText: 'Retry Payment',
            key: 'label.RETRY_PAYMENT',
          },
          {
            defaultText: 'Submit Order',
            key: 'label.SUBMIT_ORDER',
          },
          {
            defaultText: 'Price Check Pop up Error Message',
            key: 'label.PRICE_CHECK_ERROR_MSG',
          }
        ],
        image: [OrderCartSubmitOrder],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'Are you sure, you want to delete this item?',
            key: 'customMessage.DELETE_ITEM_CONFIRMATION',
          }
        ],
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.DELETE_ITEM_CONFIRMATION',
        image: [OrderCartDeleteNow],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'Submit Order',
            key: 'label.CART_BUTTON',
          }
        ],
        defaultText: 'Submit Order',
        key: 'label.CART_BUTTON',
        image: [submitOrder],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'Order Now',
            key: 'label.ORDER_NOW',
          },
          {
            defaultText: 'Checkout',
            key: 'label.CHECKOUT',
          },
          {
            defaultText: 'Out of Stock',
            key: 'label.OUT_OF_STOCK',
          }
        ],
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
        },
    ]
  },
  PaymentPage: {
    screenLabel: 'Payment Page',
    key: 'paymentPage',
    image: [paymentPage],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Coupons',
            key: 'label.COUPONS',
          },
          {
            defaultText: 'Add a tip',
            key: 'label.ADD_A_TIP',
          },
          {
            defaultText: 'No Tip',
            key: 'label.NO_TIP',
          },
          {
            defaultText: 'Other',
            key: 'label.OTHER',
          },
          {
            defaultText: 'Total Amount',
            key: 'label.TOTAL_AMOUNT',
          },
          {
            defaultText: 'Sub Total',
            key: 'label.SUB_TOTAL',
          },
          {
            defaultText: 'TAX',
            key: 'label.TAX',
          },
          {
            defaultText: 'TIP',
            key: 'label.TIP',
          },
          {
            defaultText: 'Service Charge',
            key: 'label.SERVICE_CHARGE',
          },
          {
            defaultText: 'Payments',
            key: 'label.PAYMENTS',
          },
          {
            defaultText: 'Due',
            key: 'label.DUE',
          },
          {
            defaultText: 'Total',
            key: 'label.PAYMENT_TOTAL',
          },
          {
            defaultText: 'Total Due',
            key: 'label.TOTAL_DUE',
          },
        ],
        image: [coupons],
        screenName: ['coupons']
      },
      {
        data: [
          {
            defaultText: 'No',
            key: 'label.NO',
          },
          {
            defaultText: 'Yes',
            key: 'label.YES',
          }
        ],
        image: [payNowScreen,paymentScreen_applyCouponAndLoyalty],
        screenName: ['payNowScreen','paymentScreen_applyCouponAndLoyalty']
      },
      {
        data: [
          {
            defaultText: 'Confirm',
            key: 'customMessage.CONFIRM',
          }
        ],
        image: [],
        screenName: []
      },
      {
        data: [
          {
            defaultText: 'Gift Card',
            key: 'label.GIFT_CARD',
          }
        ],
        image: [giftCard],
        screenName: ['giftCard']
      },
      {
        data: [
          {
            defaultText: 'Percent',
            key: 'label.PERCENT',
          },
          {
            defaultText: 'Amount',
            key: 'label.AMOUNT',
          },
          {
            defaultText: 'Tip this percentage of the subtotal:',
            key: 'customMessage.TIPS_PERCENTAGE_MSG',
          },
          {
            defaultText: 'Enter percentage',
            key: 'label.ENTER_PERCENTAGE',
          },
          {
            defaultText: 'Including tip,pay a total of',
            key: 'label.INCLUDING_TIP_NEW_TOTAL',
          },
          {
            defaultText: 'Including tip, make the total',
            key: 'customMessage.TOTAL_TIPS_MSG',
          },
          {
            defaultText: 'Cancel',
            key: 'customMessage.CANCEL',
          },
        ],
        image: [PaymentPagePercent],
        screenName: ['paymentPOPUP']
      },
      {
        data: [
          {
            defaultText: 'Tip this amount',
            key: 'label.TIP_THIS_AMOUNT',
          },
          {
            defaultText: 'Enter Tip Amount',
            key: 'label.ENTER_TIP_AMOUNT',
          },
          {
            defaultText: 'Deliver To',
            key: 'label.CHECKOUT_DELIVER_TO',
          },
          {
            defaultText: 'Spot Number',
            key: 'label.CHECKOUT_SPOT_NUMBER',
          },
          {
            defaultText: 'Add Card',
            key: 'label.ADD_CARD',
          },
          {
            defaultText: 'Remove Card',
            key: 'label.REMOVE_CARD'
          },
        ],
        defaultText: 'Tip this amount',
        key: 'label.TIP_THIS_AMOUNT',
        image: [PaymentPageAmount],
        screenName: ['paymentAmountScreen']
      },
      {
        data: [
          {
            defaultText: 'Total',
            key: 'label.Total',
          },
          {
            defaultText: 'Tip this amount',
            key: 'label.TIP_THIS_AMOUNT',
          },
          {
            defaultText: 'Enter Tip Amount',
            key: 'label.ENTER_TIP_AMOUNT',
          },
          {
            defaultText: 'Deliver To',
            key: 'label.CHECKOUT_DELIVER_TO',
          },
          {
            defaultText: 'Spot Number',
            key: 'label.CHECKOUT_SPOT_NUMBER',
          },
          {
            defaultText: 'Add Card',
            key: 'label.ADD_CARD',
          },
          {
            defaultText: 'Remove Card',
            key: 'label.REMOVE_CARD'
          },
        ],
        image: [PaymentPageTotal],
        screenName: ['paymentAmountScreen']
      },
      {
        data: [
          {
            defaultText: 'If you proceed,your card will be charged',
            key: 'label.CARD_CHARGED',
          },
        ],
        image: [PaymentPageOrderCharged],
        screenName: ['paymentCharge']
      },
      {
        data: [
          {
            defaultText: 'If you proceed,your order will be placed',
            key: 'label.PROCEED_ORDER_PLACED',
          },
          {
            defaultText: 'An automatic Service Charge has been added to you bill. 100% of our Service Charge benefits our team. To mantain a safe and contactless environment, we do not accept tips or gratuities. Thank you!',
            key: 'customMessage.AUTOMATIC_SERVICE_CHARGE_ADDED',
          },
        ],
        image: [ PaymentPageOrderPlaced],
        screenName: ['orderWillBePlaced']
      },
    ]
  },
  ReceiptPage : {
    screenLabel: 'Receipt Page',
    key: 'receiptPage',
    image: [receiptPage],
    customLabels: [
        // {
        //   defaultText: 'Subtotal',
        //   key: 'label.SUBTOTAL',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        // {
        //   defaultText: 'Tax',
        //   key: 'label.TAX',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        // {
        //   defaultText: 'Tip',
        //   key: 'label.TIP',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        // {
        //   defaultText: 'Total',
        //   key: 'label.TOTAL',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        {
          data: [
            {
              defaultText: 'Choose a receipt option',
              key: 'label.RECEIPT_OPTION',
            },
            {
              defaultText: 'No Receipt',
              key: 'label.NO_RECEIPT',
            },
            {
              defaultText: 'SMS',
              key: 'label.SMS',
            },
            {
              defaultText: 'Email',
              key: 'label.EMAIL',
            },
          ],
          defaultText: 'Choose a receipt option',
          key: 'label.RECEIPT_OPTION',
          image: [receiptFirstPage],
          screenName: ['receiptFirstPage']
        },
        // {
        //   defaultText: 'No Receipt',
        //   key: 'label.NO_RECEIPT',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        /* {
          defaultText: 'No Receipt',
          key: 'label.NO_RECEIPT',
          image: [receiptFirstPage],
          screenName: ['receiptFirstPage']
        }, */
        // {
        //   defaultText: 'SMS',
        //   key: 'label.SMS',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        // {
        //   defaultText: 'Email',
        //   key: 'label.EMAIL',
        //   image: [receiptFirstPage],
        //   screenName: ['receiptFirstPage']
        // },
        {
          data: [
            {
              defaultText: 'Please Enter Your Mobile Number',
              key: 'label.MOB_NUMBER',
            },
            {
              defaultText: 'Close',
              key: 'label.CLOSE',
            },
            {
              defaultText: 'Submit',
              key: 'label.SUBMIT',
            },
          ],
          defaultText: 'Please Enter Your Mobile Number',
          key: 'label.MOB_NUMBER',
          image: [receiptSecondPage],
          screenName: ['receiptSecondPage']
        },
        // {
        //   defaultText: 'Close',
        //   key: 'label.CLOSE',
        //   image: [receiptSecondPage],
        //   screenName: ['receiptSecondPage']
        // },
        // {
        //   defaultText: 'Submit',
        //   key: 'label.SUBMIT',
        //   image: [receiptSecondPage],
        //   screenName: ['receiptSecondPage']
        // },
        {
          data: [
            {
              defaultText: 'Pay Now Success Message',
              key: 'label.PAY_NOW_SUCCESS_MSG ',
            },
          ],
          defaultText: 'Pay Now Success Message',
          key: 'label.PAY_NOW_SUCCESS_MSG ',
          image: [payNowSuccessMsg],
          screenName: ['payNowSuccessMsg']
        },
        {
          data: [
            {
              defaultText: 'Curb Side/Walkup Pay Later',
              key: 'label.CURB_SIDE_WALKUP_PAY_LATER',
            },
          ],
          defaultText: 'Curb Side/Walkup Pay Later',
          key: 'label.CURB_SIDE_WALKUP_PAY_LATER',
          image: [curdSideWalkupPayLater],
          screenName: ['curdSideWalkupPayLater']
        },
       {
         data: [
           {
            defaultText: 'Online Pay Later',
            key: 'label.ONLINE_PAY_LATER',
           },
           {
            defaultText: 'Enter Mobile Number',
            key: 'label.ENTER_MOBILE_NUMBER',
           },
           {
            defaultText: 'Please Enter Your Email Id',
            key: 'customMessage.ENTER_EMAIL_ID',
           },
           {
            defaultText: 'Email Address',
            key: 'customMessage.EMAIL_ADDRESS',
           }
         ],
          defaultText: 'Online Pay Later',
          key: 'label.ONLINE_PAY_LATER',
          image: [oneLinePayLater],
          screenName: ['oneLinePayLater']
        },
        // {
        //   defaultText: 'Enter Mobile Number',
        //   key: 'label.ENTER_MOBILE_NUMBER',
        //   image: [oneLinePayLater],
        //   screenName: ['oneLinePayLater']
        // },
        // {
        //   defaultText: 'Please Enter Your Email Id',
        //   key: 'customMessage.ENTER_EMAIL_ID',
        //   image: [oneLinePayLater],
        //   screenName: ['oneLinePayLater']
        // },
        // {
        //   defaultText: 'Email Address',
        //   key: 'customMessage.EMAIL_ADDRESS',
        //   image: [oneLinePayLater],
        //   screenName: ['oneLinePayLater']
        // },
    ]
  },
  HomePageAndRefill: {
    screenLabel: 'Home Page and Refill',
    key: 'HomePageAndRefill',
    image: [HomePage],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Click To Pay Label',
            key: 'label.CLICKTOPAYLABEL',
          },
        ],
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        data: [
          {
            defaultText: 'Add items to refill',
            key: 'label.ADD_ITEMS_TO_REFILL',
          }
        ],
        image: [addItemsToRefill],
        screenName: ['addItemsToRefill']
      },
      // {
      //   defaultText: 'Add to Bag',
      //   key: 'label.ADD_TO_BAG',
      //   image: [addItemsToRefill],
      //   screenName: ['addItemsToRefill']
      // },
      {
        data: [
          {
            defaultText: 'Remove',
            key: 'label.REFILL_REMOVE',
          }
        ],
        image: [addItemsToRefill2],
        screenName: ['addItemsToRefill2']
      },
      {
        data: [
          {
            defaultText: 'Refill',
            key: 'label.REFILL',
          }
        ],
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
      },
      {
        data: [
          {
            defaultText: 'Refill Empty Cart Message',
            key: 'label.REFILL_EMPTY_CART_MSG',
          }
        ],
        image: [refillEmptyCartMSg],
        screenName: ['refillEmptyCartMSg']
      },
    ]
  },
  footer : {
    screenLabel: 'Footer',
    key: 'footer',
    image: [FooterMenus],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Home',
            key: 'label.FOOTER_HOME',
          },
          {
            defaultText: 'Menus',
            key: 'label.FOOTER_MENUS',
          },
          {
            defaultText: 'Order',
            key: 'label.FOOTER_ORDERS',
          },
          {
            defaultText: 'My Bag',
            key: 'label.FOOTER_MY_BAG',
          },
          {
            defaultText: 'More',
            key: 'label.FOOTER_MORE',
          },
          {
            defaultText: 'Favorite',
            key: 'label.FAVOURITE',
          },
          {
            defaultText: 'Store',
            key: 'label.FOOTER_STORE',
          }
        ],
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      // {
      //   defaultText: 'Menus',
      //   key: 'label.FOOTER_MENUS',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
      // {
      //   defaultText: 'Order',
      //   key: 'label.FOOTER_ORDERS',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
      // {
      //   defaultText: 'My Bag',
      //   key: 'label.FOOTER_MY_BAG',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
      // {
      //   defaultText: 'More',
      //   key: 'label.FOOTER_MORE',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
      // {
      //   defaultText: 'Favourite',
      //   key: 'label.FAVOURITE',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
      // {
      //   defaultText: 'Store',
      //   key: 'label.FOOTER_STORE',
      //   image: [ClickToPayLabel],
      //   screenName: ['ClickToPayLabel']
      // },
    ]
  },
  waitList: {
    screenLabel: 'Wait List',
    key: 'waitList',
    image: [waitList],
    customLabels: [
      {
        data: [
          {
            defaultText: 'What would you like to today?',
            key: 'label.WHAT_WOULD_YOU_LIKE',
          },
          {
            defaultText: 'Dine In',
            key: 'label.DINE_IN',
          },
          {
            defaultText: 'Take Out',
            key: 'label.TAKE_OUT',
          },
        ],
        image: [waitListFirstScreen],
        screenName: ['waitListFirstScreen']
      },
      // {
      //   defaultText: 'Dine In',
      //   key: 'label.DINE_IN',
      //   image: [waitListFirstScreen],
      //   screenName: ['waitListFirstScreen']
      // },
      // {
      //   defaultText: 'Take Out',
      //   key: 'label.TAKE_OUT',
      //   image: [waitListFirstScreen],
      //   screenName: ['waitListFirstScreen']
      // },
      {
        data: [
          {
            defaultText: 'Number of Guest In Your Party',
            key: 'label.NO_OF_GUEST',
          },
          {
            defaultText: 'Of your guest,How Many are Children?',
            key: 'label.HOW_MANY_CHILDREN',
          },
          {
            defaultText: 'Check Availability',
            key: 'label.CHECK_AVAILABILITY',
          },
        ],
        image: [waitListSecondScreen],
        screenName: ['waitListSecondScreen']
      },
      {
        data: [
          {
            defaultText: 'Add to Wait List',
            key: 'label.ADD_TO_WAIT_LIST',
          },
        ],
        image: [waitListThirdScreen],
        screenName: ['waitListThirdScreen']
      },
      {
        data: [
          {
            defaultText: 'Add to wait List PoP Up Message',
            key: 'label.ADD_TO_WAIT_LIST_POP_MSG',
          },
          {
            defaultText: 'Add to wait List Success Message',
            key: 'label.ADD_TO_WAIT_LIST_SUCCESS_MSG',
          }
        ],
        image: [waitListPoPUPScreen],
        screenName: ['waitListPoPUPScreen']
      },
      {
        data: [
          {
            defaultText: 'Add to wait List Success Message',
            key: 'label.ADD_TO_WAIT_LIST_SUCCESS_MSG',
          }
        ],
        image: [waitListSuccessScreen],
        screenName: ['waitListSuccessScreen']
      },
    ]
  },
  splitCheck: {
    screenLabel: 'Split Check',
    key: 'splitCheck',
    image: [splitCheck],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Full Check',
            key: 'label.FULL_CHECK',
          },
          {
            defaultText: 'Split Evenly',
            key: 'label.SPLIT_EVENLY',
          }
        ],
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        data: [
          {
            defaultText: 'Please Select How Many People Will Split this',
            key: 'label.HOW_MANY_PPL',
          },
          {
            defaultText: 'Continue',
            key: 'label.CONTINUE',
          }
        ],
        image: [splitCheckSecondScreen],
        screenName: ['splitCheckSecondScreen']
      },
      {
        data: [
          {
            defaultText: 'Pay',
            key: 'label.PAY',
          },
          {
            defaultText: 'Pay Remaining Balance',
            key: 'label.PAY_REMAINING_BALANCE',
          }
        ],
        image: [splitCheckThirdScreen],
        screenName: ['splitCheckThirdScreen']
      },
    ]
  },
  daisyChain: {
    screenLabel: 'Daisy Chain',
    key: 'daisyChain',
    image: [daisyChain],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Are you the last person to add to this order?',
            key: 'label.DC_ORDER_MSG',
          }
        ],
        defaultText: 'Are you the last person to add to this order?',
        key: 'label.DC_ORDER_MSG',
        image: [daisyChainSecondScreen],
        screenName: ['daisyChainSecondScreen']
      },
      {
        data: [
          {
            defaultText: 'Daisy Chain',
            key: 'label.DAISYCHAIN',
            image: [daisyChainFirstScreen],
            screenName: ['daisyChainFirstScreen']
          },
          {
            defaultText: 'Yes',
            key: 'label.DC_ORDER_PLACE_YES',
            image: [daisyChainFirstScreen],
            screenName: ['daisyChainFirstScreen']
          },
          {
            defaultText: 'No',
            key: 'label.DC_ORDER_PLACE_NO',
            image: [daisyChainFirstScreen],
            screenName: ['daisyChainFirstScreen']
          },
          {
            defaultText: 'Enter the name of this order',
            key: 'label.DC_NAME_OF_THIS_ORDER',
          },
          {
            defaultText: 'Are you the only person sending an order right now?',
            key: 'label.DC_ORDER_PLACE_MSG',
          },
          {
            defaultText: 'Yes, I’m the only one',
            key: 'label.DAISY_FIRST_PERSON_YES',
          },
          {
            defaultText: 'No, I’m not the only one',
            key: 'label.DAISY_FIRST_PERSON_NO',
          },
          {
            defaultText: 'Yes, I’m the last one',
            key: 'label.DAISY_LAST_PERSON_YES',
          },
          {
            defaultText: 'No, I’m not the last one',
            key: 'label.DAISY_LAST_PERSON_NO',
          },
        ],
        defaultText: 'Enter the name of this order',
        key: 'label.DC_NAME_OF_THIS_ORDER',
        image: [daisyChainFirstScreen],
        screenName: ['daisyChainFirstScreen']
      },
    ]
  },
  badges: {
    screenLabel: 'Badge',
    key: 'badges',
    image: [],
    customLabels: [
      {
        defaultText: 'View Only',
        key: 'label.VIEW_ONLY',
        image: [],
        screenName: []
      },
      {
        defaultText: 'New Item',
        key: 'label.NEW_ITEM',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Limited Time',
        key: 'label.LIMITED_TIME',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Featured',
        key: 'label.FEATURED',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Special',
        key: 'label.SPECIAL',
        image: [],
        screenName: []
      }
    ]
  },
  loyalty: {
    screenLabel: 'Loyalty',
    key: 'loyalty',
    image: [loyalty],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Would you like to join rewards program?',
            key: 'customMessage.LOYALTY_REWARDS_MESSAGE',
          },
          {
            defaultText: 'Already a member',
            key: 'label.LOYALTY_ALREADY_MEMBER',
          },
          {
            defaultText: 'Join now',
            key: 'label.LOYALTY_JOIN_NOW',
          },
          {
            defaultText: 'How would you like to look up your account?',
            key: 'customMessage.LOYALTY_LOOKUP_ACCOUNT',
          },
          {
            defaultText: 'Phone Number',
            key: 'label.LOYALTY_PHONE_NUMBER',
          },
          {
            defaultText: 'Email Address',
            key: 'label.LOYALTY_EMAIL_ADDRESS',
          },
          {
            defaultText: 'Account Number',
            key: 'label.LOYALTY_ACC_NUMBER',
          },
          {
            defaultText: 'Please enter your phone number',
            key: 'customMessage.LOYALTY_PHONE_NUMBER_LABEL',
          },
          {
            defaultText: 'Please enter your email address',
            key: 'customMessage.LOYALTY_EMAIL_ADDRESS_LABEL',
          },
          {
            defaultText: 'Please enter your account number',
            key: 'customMessage.LOYALTY_ACC_NUMBER_LABEL',
          },
          {
            defaultText: 'Account Number',
            key: 'label.LOYALTY_ACCOUNT_NUMBER',
          },
          {
            defaultText: 'Select an offer',
            key: 'label.LOYALTY_SELECT_AN_OFFER',
          },
          {
            defaultText: 'Skip Offer',
            key: 'label.LOYALTY_SKIP_OFFER',
          },
          {
            defaultText: 'Apply Offer',
            key: 'label.LOYALTY_APPLY_OFFER',
          }
        ],
        image: [LoyaltyAccountNumber, LoyaltyAlreadyMember, LoyaltyPhoneOrEmail, LoyaltySelectOffer],
        screenName: ['loyalty']
      },
    ]
  },
  preAuthPage: {
    screenLabel: 'PreAuth Changes',
    key: 'preAuthPage',
    image: [preAuth],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Keep It Open',
            key: 'label.KEEP_IT_OPEN',
          },
          {
            defaultText: 'I Agree',
            key: 'label.I_AGREE',
          },
          {
            defaultText: 'Authorize Card',
            key: 'label.AUTHORIZE_CARD',
          },
          {
            defaultText: 'Hold',
            key: 'label.HOLD',
          },
          {
            defaultText: 'If you leave without settling your tab, your hold will be used to settle the final bill amount.',
            key: 'customMessage.PRE_AUTH_PERCENTAGE_ZERO',
          },
          {
            defaultText: 'If you leave without settling your tab, your card will be used to settle the final bill amount plus a 20% gratuity',
            key: 'customMessage.PRE_AUTH_PERCENTAGE_NOT_ZERO',
          },
          {
            defaultText: 'A hold will be placed on your card. I agree to settle my tab for the final check amount before departing this establishment',
            key: 'customMessage.PRE_AUTH_HOLD',
          },
          // {
          //   defaultText: 'If I leave without settling my tab, I agree to allow this location to to settle my final bill amount.',
          //   key: 'customMessage.PRE_AUTH_LOCATION_PERCENTAGE_ZERO',
          // },
          // {
          //   defaultText: 'If I leave without settling my tab, I agree to allow this location to to settle my final bill amount plus a 20% gratuity.',
          //   key: 'customMessage.PRE_AUTH_LOCATION_PERCENTAGE_NOT_ZERO',
          // },
          {
            defaultText: 'This location requires a credit card hold before placing an order. You must settle your tab for the final check amount before departing this establishment.',
            key: 'customMessage.PRE_AUTH_LOCATION_CREDIT_CARD',
          },
        ],
        defaultText: 'Keep It Open',
        key: 'label.KEEP_IT_OPEN',
        image: [curbside],
        screenName: ['curbside']
      },
    ]
  },
  tableGroupOrdering: {
    screenLabel: 'Table Group Ordering',
    key: 'tableGroupOrdering',
    image: [tableGroupOrdering],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Please Present this QR code to any other member of your party who would like to join this check',
            key: 'customMessage.PLEASE_PRESENT_THIS_QR',
          },
          {
            defaultText: 'Want to invite over SMS?Copy the link below and send it using your SMS app',
            key: 'customMessage.WANT_TO_INVITE_OVER_SMS',
          },
          {
            defaultText: 'Please request a QR from your party host to join this table.The host can generate...',
            key: 'customMessage.PLEASE_REQUEST_QR_PARTY HOST',
          },
          {
            defaultText: 'There is a check open on this table.would you like to join this check?',
            key: 'customMessage.CHECK_TABLE_OPEN',
          },
          {
            defaultText: 'Join Check and continue',
            key: 'label.JOIN_CHECK_AND_CONTINUE',
          },
          {
            defaultText: 'Start a new check',
            key: 'label.START_A_NEW_CHECK',
          },
          {
            defaultText: 'Join existing check',
            key: 'label.JOIN_EXISTING_CHECK',
          },
          {
            defaultText: 'Join a party with',
            key: 'label.JOIN_PARTY_WITH',
          }
        ],
        defaultText: 'Please Present this QR code to any other member of your party who would like to join this check',
        key: 'customMessage.PLEASE_PRESENT_THIS_QR',
        image: [curbside],
        screenName: ['curbside']
      },
    ]
  },
  immediateFeature: {
    screenLabel: 'Immediate Feature',
    key: 'immediateFeature',
    image: [immediateFeature],
    customLabels: [
      {
        data: [
          {
            defaultText: 'Would you like it send right away?',
            key: 'customMessage.IMMEDIATE_ORDER_SEND',
          },
          {
            defaultText: 'Send Now',
            key: 'label.SEND_NOW',
          },
          {
            defaultText: 'Add to Bag',
            key: 'label.IMMEDIATE_ADD_TO_BAG',
          },
          {
            defaultText: 'There was an error sending your order. Would you like to add to cart instead?',
            key: 'label.IMMEDIATE_ORDER_FAILURE',
          },
        ],
        image: [immediateFeature],
      }
    ]
  }
};
