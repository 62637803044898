export const OMS_SETUP_RULE_TYPE_REQUESTED = 'oms/OMS_SETUP_RULE_TYPE_REQUESTED';
export const OMS_SETUP_RULE_TYPE_SUCCESS = 'oms/OMS_SETUP_RULE_TYPE_SUCCESS';
export const OMS_SETUP_RULE_TYPE_FAILURE = 'oms/OMS_SETUP_RULE_TYPE_FAILURE';

export const OMS_SETUP_REQUESTED = 'oms/OMS_SETUP_REQUESTED';
export const OMS_SETUP_SUCCESS = 'oms/OMS_SETUP_SUCCESS';
export const OMS_SETUP_FAILURE = 'oms/OMS_SETUP_FAILURE';

export const OMS_LOAD_OFFER_REQUESTED = 'oms/OMS_LOAD_OFFER_REQUESTED';
export const OMS_LOAD_OFFER_SUCCESS = 'oms/OMS_LOAD_OFFER_SUCCESS';
export const OMS_LOAD_OFFER_FAILURE = 'oms/OMS_LOAD_OFFER_FAILURE';

export const OMS_UPDATE_OFFER_REQUESTED = 'oms/OMS_UPDATE_OFFER_REQUESTED';
export const OMS_UPDATE_OFFER_SUCCESS = 'oms/OMS_UPDATE_OFFER_SUCCESS';
export const OMS_UPDATE_OFFER_FAILURE = 'oms/OMS_UPDATE_OFFER_FAILURE';

export const OMS_SETUP_DISCOUNT_TYPE_REQUESTED = 'oms/OMS_SETUP_DISCOUNT_TYPE_REQUESTED';
export const OMS_SETUP_DISCOUNT_TYPE_SUCCESS = 'oms/OMS_SETUP_DISCOUNT_TYPE_SUCCESS';
export const OMS_SETUP_DISCOUNT_TYPE_FAILURE = 'oms/OMS_SETUP_DISCOUNT_TYPE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  ruleTypeList: null,
  discountTypeList: null,
  loadOfferList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OMS_SETUP_RULE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case OMS_SETUP_RULE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        ruleTypeList: action.result.data
      };
    }
    case OMS_SETUP_RULE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OMS_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case OMS_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case OMS_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OMS_LOAD_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case OMS_LOAD_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loadOfferList: action.result.data,
      };
    }
    case OMS_LOAD_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OMS_UPDATE_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case OMS_UPDATE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case OMS_UPDATE_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case OMS_SETUP_DISCOUNT_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case OMS_SETUP_DISCOUNT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        discountTypeList: action.result.data
      };
    }
    case OMS_SETUP_DISCOUNT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const omsSetupRuleType = (data) => {
  return {
    types: [OMS_SETUP_RULE_TYPE_REQUESTED, OMS_SETUP_RULE_TYPE_SUCCESS, OMS_SETUP_RULE_TYPE_FAILURE],
    promise: client => client.post('omsSetup', { data }),
  };
};

export const omssetup = (data) => {
  return {
    types: [OMS_SETUP_REQUESTED, OMS_SETUP_SUCCESS, OMS_SETUP_FAILURE],
    promise: client => client.post('omsSetup', { data }),
  };
};

export const omsSetupDiscountType = (data) => {
  return {
    types: [OMS_SETUP_DISCOUNT_TYPE_REQUESTED, OMS_SETUP_DISCOUNT_TYPE_SUCCESS, OMS_SETUP_DISCOUNT_TYPE_FAILURE],
    promise: client => client.post('omsSetup', { data }),
  };
};

export const omsLoadOffer = (data) => {
  return {
    types: [OMS_LOAD_OFFER_REQUESTED, OMS_LOAD_OFFER_SUCCESS, OMS_LOAD_OFFER_FAILURE],
    promise: client => client.post('omsSetup', { data }),
  };
};

export const omsUpdateOffer = (data) => {
  return {
    types: [OMS_UPDATE_OFFER_REQUESTED, OMS_UPDATE_OFFER_SUCCESS, OMS_UPDATE_OFFER_FAILURE],
    promise: client => client.post('omsSetup', { data }),
  };
};
