export const GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS';
export const GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE = 'store/GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE';

export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS';
export const UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE = 'store/UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  cabbagePayList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        cabbagePayList: null
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cabbagePayList: action.result,
      };
    }
    case GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        ingenicoList: null
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cabbagePayList: action.result,
      };
    }
    case UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStorePaymentSystemConfigCabbagePay = (storeId) => {
  return {
    types: [GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED, GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS, GET_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE],
    promise: client => client.get(`getStorePaymentSystemConfigCabbagePay/${storeId}`)
  };
};

export const updateStorePaymentSystemConfigCabbagePay = (storeId, data) => {
  return {
    types: [UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_REQUESTED, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_SUCCESS, UPDATE_STORE_PAYMENT_SYSTEM_CONFIG_CABBAGE_PAY_FAILURE],
    promise: client => client.post(`updateStorePaymentSystemConfigCabbagePay/${storeId}`, { data })
  };
};
