export const GET_SYSTEM_STATUS_REQUESTED = 'systemStatus/GET_SYSTEM_STATUS_REQUESTED';
export const GET_SYSTEM_STATUS_SUCCESS = 'systemStatus/GET_SYSTEM_STATUS_SUCCESS';
export const GET_SYSTEM_STATUS_FAILURE = 'systemStatus/GET_SYSTEM_STATUS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  systemStatusList: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SYSTEM_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        systemStatusList: action.result,
      };
    }
    case GET_SYSTEM_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getSystemStatus = () => {
  return {
    types: [GET_SYSTEM_STATUS_REQUESTED, GET_SYSTEM_STATUS_SUCCESS, GET_SYSTEM_STATUS_FAILURE],
    promise: client => client.get('systemStatus')
  };
};
