export const UPDATE_ITEM_REQUESTED = 'menuItem/UPDATE_ITEM_REQUESTED';
export const UPDATE_ITEM_SUCCESS = 'menuItem/UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'menuItem/UPDATE_ITEM_FAILURE';

export const ADD_MENUITEM_TO_SCREEN_REQUESTED = 'menuItem/ADD_MENUITEM_TO_SCREEN_REQUESTED';
export const ADD_MENUITEM_TO_SCREEN_SUCCESS = 'menuItem/ADD_MENUITEM_TO_SCREEN_SUCCESS';
export const ADD_MENUITEM_TO_SCREEN_FAILURE = 'menuItem/ADD_MENUITEM_TO_SCREEN_FAILURE';

export const REMOVE_MENUITEM_FROM_SCREEN_REQUESTED = 'menuItem/REMOVE_MENUITEM_FROM_SCREEN_REQUESTED';
export const REMOVE_MENUITEM_FROM_SCREEN_SUCCESS = 'menuItem/REMOVE_MENUITEM_FROM_SCREEN_SUCCESS';
export const REMOVE_MENUITEM_FROM_SCREEN_FAILURE = 'menuItem/REMOVE_MENUITEM_FROM_SCREEN_FAILURE';

export const ADD_MENUITEM_IMAGES_REQUESTED = 'menuItem/ADD_MENUITEM_IMAGES_REQUESTED';
export const ADD_MENUITEM_IMAGES_SUCCESS = 'menuItem/ADD_MENUITEM_IMAGES_SUCCESS';
export const ADD_MENUITEM_IMAGES_FAILURE = 'menuItem/ADD_MENUITEM_IMAGES_FAILURE';

export const ADD_MENUITEM_IMAGES_BY_TYPES_REQUESTED = 'menuItem/ADD_MENUITEM_IMAGES_BY_TYPES_REQUESTED';
export const ADD_MENUITEM_IMAGES_BY_TYPES_SUCCESS = 'menuItem/ADD_MENUITEM_IMAGES_BY_TYPES_SUCCESS';
export const ADD_MENUITEM_IMAGES_BY_TYPES_FAILURE = 'menuItem/ADD_MENUITEM_IMAGES_BY_TYPES_FAILURE';

export const DELETE_MENUITEM_IMAGES_REQUESTED = 'menuItem/DELETE_MENUITEM_IMAGES_REQUESTED';
export const DELETE_MENUITEM_IMAGES_SUCCESS = 'menuItem/DELETE_MENUITEM_IMAGES_SUCCESS';
export const DELETE_MENUITEM_IMAGES_FAILURE = 'menuItem/DELETE_MENUITEM_IMAGES_FAILURE';

export const ADD_MENU_ITEM_REQUESTED = 'menuItem/ADD_MENU_ITEM_REQUESTED';
export const ADD_MENU_ITEM_SUCCESS = 'menuItem/ADD_MENU_ITEM_SUCCESS';
export const ADD_MENU_ITEM_FAILURE = 'menuItem/ADD_MENU_ITEM_FAILURE';

export const GET_TAG_TYPES_REQUESTED = 'menuItem/GET_TAG_TYPES_REQUESTED';
export const GET_TAG_TYPES_SUCCESS = 'menuItem/GET_TAG_TYPES_SUCCESS';
export const GET_TAG_TYPES_FAILURE = 'menuItem/GET_TAG_TYPES_FAILURE';

export const GET_TAGS_REQUESTED = 'menuItem/GET_TAGS_REQUESTED';
export const GET_TAGS_SUCCESS = 'menuItem/GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'menuItem/GET_TAGS_FAILURE';

export const GET_ADVANCE_ORDER_TIME_UNITS_REQUESTED = 'menuItem/GET_ADVANCE_ORDER_TIME_UNITS_REQUESTED';
export const GET_ADVANCE_ORDER_TIME_UNITS_SUCCESS = 'menuItem/GET_ADVANCE_ORDER_TIME_UNITS_SUCCESS';
export const GET_ADVANCE_ORDER_TIME_UNITS_FAILURE = 'menuItem/GET_ADVANCE_ORDER_TIME_UNITS_FAILURE';

export const GET_SERVICE_MODES_LIST_REQUESTED = 'menuItem/GET_SERVICE_MODES_LIST_REQUESTED';
export const GET_SERVICE_MODES_LIST_SUCCESS = 'menuItem/GET_SERVICE_MODES_LIST_SUCCESS';
export const GET_SERVICE_MODES_LIST_FAILURE = 'menuItem/GET_SERVICE_MODES_LIST_FAILURE';

export const GET_DELIVERY_VEHICLE_TYPES_REQUESTED = 'menuItem/GET_DELIVERY_VEHICLE_TYPES_REQUESTED';
export const GET_DELIVERY_VEHICLE_TYPES_SUCCESS = 'menuItem/GET_DELIVERY_VEHICLE_TYPES_SUCCESS';
export const GET_DELIVERY_VEHICLE_TYPES_FAILURE = 'menuItem/GET_DELIVERY_VEHICLE_TYPES_FAILURE';

export const GET_MENU_ITEM_CARD_LIST_REQUESTED = 'menuItem/GET_MENU_ITEM_CARD_LIST_REQUESTED';
export const GET_MENU_ITEM_CARD_LIST_SUCCESS = 'menuItem/GET_MENU_ITEM_CARD_LIST_SUCCESS';
export const GET_MENU_ITEM_CARD_LIST_FAILURE = 'menuItem/GET_MENU_ITEM_CARD_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tagTypesList: [],
  tagsList: [],
  advanceOrderTimeUnitList: [],
  serviceModesList: [],
  vehicleTypes: [],
  menuItemCardList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_ITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MENUITEM_TO_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENUITEM_TO_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENUITEM_TO_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case REMOVE_MENUITEM_FROM_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REMOVE_MENUITEM_FROM_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case REMOVE_MENUITEM_FROM_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MENUITEM_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENUITEM_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENUITEM_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MENUITEM_IMAGES_BY_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENUITEM_IMAGES_BY_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENUITEM_IMAGES_BY_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MENUITEM_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MENUITEM_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MENUITEM_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_MENU_ITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENU_ITEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TAG_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TAG_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tagTypesList: action.result,
      };
    }
    case GET_TAG_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TAGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tagsList: action.result,
      };
    }
    case GET_TAGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ADVANCE_ORDER_TIME_UNITS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ADVANCE_ORDER_TIME_UNITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        advanceOrderTimeUnitList: action.result,
      };
    }
    case GET_ADVANCE_ORDER_TIME_UNITS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SERVICE_MODES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SERVICE_MODES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        serviceModesList: action.result,
      };
    }
    case GET_SERVICE_MODES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DELIVERY_VEHICLE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        vehicleTypes: [],
      };
    }
    case GET_DELIVERY_VEHICLE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        vehicleTypes: action.result,
      };
    }
    case GET_DELIVERY_VEHICLE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        vehicleTypes: [],
      };
    }
    case GET_MENU_ITEM_CARD_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENU_ITEM_CARD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuItemCardList: action.result,
      };
    }
    case GET_MENU_ITEM_CARD_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const updateMenuItem = (data) => {
  let url = 'updateMenuItem';
  if (!Object.prototype.hasOwnProperty.call(data, 'screenId')) {
    url = 'updateBrandMenuItem';
  }
  return {
    types: [UPDATE_ITEM_REQUESTED, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE],
    promise: client => client.post(url, { data })
  };
};

export const addMenuItemToScreen = (screenId, menuItemId) => {
  return {
    types: [ADD_MENUITEM_TO_SCREEN_REQUESTED, ADD_MENUITEM_TO_SCREEN_SUCCESS, ADD_MENUITEM_TO_SCREEN_FAILURE],
    promise: client => client.post(`addMenuItemToScreen/${screenId}/menuItemId/${menuItemId}`)
  };
};

export const removeMenuItemFromScreen = (screenId, menuItemId) => {
  return {
    types: [REMOVE_MENUITEM_FROM_SCREEN_REQUESTED, REMOVE_MENUITEM_FROM_SCREEN_SUCCESS, REMOVE_MENUITEM_FROM_SCREEN_FAILURE],
    promise: client => client.post(`removeMenuItemFromScreen/${screenId}/menuItemId/${menuItemId}`)
  };
};

export const addMenuItemImage = (menuItemId, data) => {
  return {
    types: [ADD_MENUITEM_IMAGES_REQUESTED, ADD_MENUITEM_IMAGES_SUCCESS, ADD_MENUITEM_IMAGES_FAILURE],
    promise: client => client.post(`addMenuItemImage/${menuItemId}/image/add`, { data }),
  };
};

export const addMenuItemImageByType = (menuItemId, data) => {
  return {
    types: [ADD_MENUITEM_IMAGES_BY_TYPES_REQUESTED, ADD_MENUITEM_IMAGES_BY_TYPES_SUCCESS, ADD_MENUITEM_IMAGES_BY_TYPES_FAILURE],
    promise: client => client.post(`addMenuItemImage/${menuItemId}/imageByType/add`, { data }),
  };
};

export const deleteMenuItemImage = (menuItemId) => {
  return {
    types: [DELETE_MENUITEM_IMAGES_REQUESTED, DELETE_MENUITEM_IMAGES_SUCCESS, DELETE_MENUITEM_IMAGES_FAILURE],
    promise: client => client.post(`addMenuItemImage/${menuItemId}/image/delete`),
  };
};

export const addMenuItem = (data) => {
  return {
    types: [ADD_MENU_ITEM_REQUESTED, ADD_MENU_ITEM_SUCCESS, ADD_MENU_ITEM_FAILURE],
    promise: client => client.post('addMenuItem', { data })
  };
};

export const getTagTypes = () => {
  return {
    types: [GET_TAG_TYPES_REQUESTED, GET_TAG_TYPES_SUCCESS, GET_TAG_TYPES_FAILURE],
    promise: client => client.get('getTagTypes')
  };
};

export const getTags = () => {
  return {
    types: [GET_TAGS_REQUESTED, GET_TAGS_SUCCESS, GET_TAGS_FAILURE],
    promise: client => client.get('getTags')
  };
};

export const getAdvanceOrderTimeUnit = () => {
  return {
    types: [GET_ADVANCE_ORDER_TIME_UNITS_REQUESTED, GET_ADVANCE_ORDER_TIME_UNITS_SUCCESS, GET_ADVANCE_ORDER_TIME_UNITS_FAILURE],
    promise: client => client.get('getAdvanceOrderTimeUnit')
  };
};

export const getServiceModeList = () => {
  return {
    types: [GET_SERVICE_MODES_LIST_REQUESTED, GET_SERVICE_MODES_LIST_SUCCESS, GET_SERVICE_MODES_LIST_FAILURE],
    promise: client => client.get('getServiceModeLookUps')
  };
};

export const getdeliveryVehicleTypes = () => {
  return {
    types: [GET_DELIVERY_VEHICLE_TYPES_REQUESTED, GET_DELIVERY_VEHICLE_TYPES_SUCCESS, GET_DELIVERY_VEHICLE_TYPES_FAILURE],
    promise: client => client.get('getdeliveryVehicleTypes')
  };
};
export const getMenuItemCardList = () => {
  return {
    types: [GET_MENU_ITEM_CARD_LIST_REQUESTED, GET_MENU_ITEM_CARD_LIST_SUCCESS, GET_MENU_ITEM_CARD_LIST_FAILURE],
    promise: client => client.get('getMenuItemCardTypes')
  };
};
