const currentReducer = 'DB_SETUP';
const GET_DB_SETUP_REQUESTED = `${currentReducer}/GET_DB_SETUP_REQUESTED`;
const GET_DB_SETUP_SUCCESS = `${currentReducer}/GET_DB_SETUP_SUCCESS`;
const GET_DB_SETUP_FAILURE = `${currentReducer}/GET_DB_SETUP_FAILURE`;

const UPDATE_DB_SETUP_REQUESTED = `${currentReducer}/UPDATE_DB_SETUP_REQUESTED`;
const UPDATE_DB_SETUP_SUCCESS = `${currentReducer}/UPDATE_DB_SETUP_SUCCESS`;
const UPDATE_DB_SETUP_FAILURE = `${currentReducer}/UPDATE_DB_SETUP_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  dbData: []
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_DB_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        dbData: []
      };
    }
    case GET_DB_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        dbData: action.result
      };
    }
    case GET_DB_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case UPDATE_DB_SETUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPDATE_DB_SETUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_DB_SETUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};


export const getDBSetup = (id) => {
  return {
    types: [GET_DB_SETUP_REQUESTED, GET_DB_SETUP_SUCCESS, GET_DB_SETUP_FAILURE],
    promise: client => client.get(`getDBLoyalty/${id}`)
  };
};

export const updateDBSetup = (data, id) => {
  return {
    types: [UPDATE_DB_SETUP_REQUESTED, UPDATE_DB_SETUP_SUCCESS, UPDATE_DB_SETUP_FAILURE],
    promise: client => client.post(`updateDBLoyalty/${id}`, { data })
  };
};
