export const GET_BOOKING_SYSTEM_CONFIG_REQUESTED = 'store/GET_BOOKING_SYSTEM_CONFIG_REQUESTED';
export const GET_BOOKING_SYSTEM_CONFIG_SUCCESS = 'store/GET_BOOKING_SYSTEM_CONFIG_SUCCESS';
export const GET_BOOKING_SYSTEM_CONFIG_FAILURE = 'store/GET_BOOKING_SYSTEM_CONFIG_FAILURE';

export const GET_BOOKING_SYSTEM_REQUESTED = 'store/GET_BOOKING_SYSTEM_REQUESTED';
export const GET_BOOKING_SYSTEM_SUCCESS = 'store/GET_BOOKING_SYSTEM_SUCCESS';
export const GET_BOOKING_SYSTEM_FAILURE = 'store/GET_BOOKING_SYSTEM_FAILURE';

export const UPDATE_BOOKING_SYSTEM_CONFIG_REQUESTED = 'store/UPDATE_BOOKING_SYSTEM_CONFIG_REQUESTED';
export const UPDATE_BOOKING_SYSTEM_CONFIG_SUCCESS = 'store/UPDATE_BOOKING_SYSTEM_CONFIG_SUCCESS';
export const UPDATE_BOOKING_SYSTEM_CONFIG_FAILURE = 'store/UPDATE_BOOKING_SYSTEM_CONFIG_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getBooking: [],
  getbookingsystemConfig: {},
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BOOKING_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        bookingSystemConfig: action.result,
      };
    }
    case GET_BOOKING_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BOOKING_SYSTEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BOOKING_SYSTEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getBooking: action.result,
      };
    }
    case GET_BOOKING_SYSTEM_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_BOOKING_SYSTEM_CONFIG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BOOKING_SYSTEM_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BOOKING_SYSTEM_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getBookingSystemConfig = (storeId) => {
  return {
    types: [GET_BOOKING_SYSTEM_CONFIG_REQUESTED, GET_BOOKING_SYSTEM_CONFIG_SUCCESS, GET_BOOKING_SYSTEM_CONFIG_FAILURE],
    promise: client => client.get(`getStoreBookingSystemConfig/${storeId}`)
  };
};

export const getBookingSystem = () => {
  return {
    types: [GET_BOOKING_SYSTEM_REQUESTED, GET_BOOKING_SYSTEM_SUCCESS, GET_BOOKING_SYSTEM_FAILURE],
    promise: client => client.get('getBookingSystem')
  };
};

export const updateBookingSystemConfig = (storeId, data) => {
  return {
    types: [UPDATE_BOOKING_SYSTEM_CONFIG_REQUESTED, UPDATE_BOOKING_SYSTEM_CONFIG_SUCCESS, UPDATE_BOOKING_SYSTEM_CONFIG_FAILURE],
    promise: client => client.post(`updateStoreBookingSystemConfig/${storeId}`, { data })
  };
};
