export const GET_RISK_RULES_REQUESTED = 'riskRules/GET_RISK_RULES_REQUESTED';
export const GET_RISK_RULES_SUCCESS = 'riskRules/GET_RISK_RULES_SUCCESS';
export const GET_RISK_RULES_FAILURE = 'riskRules/GET_RISK_RULES_FAILURE';

export const DELETE_RISK_RULES_REQUESTED = 'riskRules/DELETE_RISK_RULES_REQUESTED';
export const DELETE_RISK_RULES_SUCCESS = 'riskRules/DELETE_RISK_RULES_SUCCESS';
export const DELETE_RISK_RULES_FAILURE = 'riskRules/DELETE_DELIVERY_FAILURE';

export const ADD_RISK_RULES_REQUESTED = 'riskRules/ADD_RISK_RULES_REQUESTED';
export const ADD_RISK_RULES_SUCCESS = 'riskRules/ADD_RISK_RULES_SUCCESS';
export const ADD_RISK_RULES_FAILURE = 'riskRules/ADD_RISK_RULES_FAILURE';

export const UPDATE_RISK_RULES_REQUESTED = 'riskRules/UPDATE_RISK_RULES_REQUESTED';
export const UPDATE_RISK_RULES_SUCCESS = 'riskRules/UPDATE_RISK_RULES_SUCCESS';
export const UPDATE_RISK_RULES_FAILURE = 'riskRules/UPDATE_RISK_RULES_FAILURE';

export const GET_RISK_RULES_BY_ID_REQUESTED = 'riskRules/GET_RISK_RULES_BY_ID_REQUESTED';
export const GET_RISK_RULES_BY_ID_SUCCESS = 'riskRules/GET_RISK_RULES_BY_ID_SUCCESS';
export const GET_RISK_RULES_BY_ID_FAILURE = 'riskRules/GET_RISK_RULES_BY_ID_FAILURE';

export const GET_RISK_RULE_TYPES_REQUESTED = 'riskScreen/GET_RISK_RULE_TYPES_REQUESTED';
export const GET_RISK_RULE_TYPES_SUCCESS = 'riskScreen/GET_RISK_RULE_TYPES_SUCCESS';
export const GET_RISK_RULE_TYPES_FAILURE = 'riskScreen/GET_RISK_RULE_TYPES_FAILURE';

export const SAVE_RISK_RULE_REQUESTED = 'riskScreen/SAVE_RISK_RULE_REQUESTED';
export const SAVE_RISK_RULE_SUCCESS = 'riskScreen/SAVE_RISK_RULE_SUCCESS';
export const SAVE_RISK_RULE_FAILURE = 'riskScreen/SAVE_RISK_RULE_FAILURE';

export const DELETE_RISK_RULE_REQUESTED = 'RiskScreen/DELETE_RISK_RULE_REQUESTED';
export const DELETE_RISK_RULE_SUCCESS = 'RiskScreen/DELETE_RISK_RULE_SUCCESS';
export const DELETE_RISK_RULE_FAILURE = 'RiskScreen/DELETE_RISK_RULE_FAILURE';

export const SEARCH_GUEST_BY_PHONE_NUMBER_REQUESTED = 'riskScreen/SEARCH_GUEST_BY_PHONE_NUMBER_REQUESTED';
export const SEARCH_GUEST_BY_PHONE_NUMBER_SUCCESS = 'riskScreen/SEARCH_GUEST_BY_PHONE_NUMBER_SUCCESS';
export const SEARCH_GUEST_BY_PHONE_NUMBER_FAILURE = 'riskScreen/SEARCH_GUEST_BY_PHONE_NUMBER_FAILURE';

export const CLEAR_SEARCH_GUEST_BY_PHONE_NUMBER = 'riskScreen/CLEAR_SEARCH_GUEST_BY_PHONE_NUMBER';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  riskRules: [],
  riskRuleListData: null,
  riskRulesTypesList: [],
  guestResultByPhone: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RISK_RULES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        riskRules: action.result,
      };
    }
    case GET_RISK_RULES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_RISK_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_RISK_RULES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RISK_RULES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_RISK_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_RISK_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_RISK_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_RISK_RULES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_RISK_RULES_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_RISK_RULES_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_RISK_RULES_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RISK_RULES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        riskRuleListData: action.result,
      };
    }
    case GET_RISK_RULES_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_RISK_RULE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_RISK_RULE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        riskRulesTypesList: action.result
      };
    }
    case GET_RISK_RULE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case SAVE_RISK_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_RISK_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_RISK_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_RISK_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_RISK_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_RISK_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SEARCH_GUEST_BY_PHONE_NUMBER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        guestResultByPhone: null,
      };
    }
    case SEARCH_GUEST_BY_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        guestResultByPhone: action.result
      };
    }
    case SEARCH_GUEST_BY_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        guestResultByPhone: null,
      };
    }
    case CLEAR_SEARCH_GUEST_BY_PHONE_NUMBER: {
      return {
        ...state,
        guestResultByPhone: null,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getRiskRules = (brandId) => {
  return {
    types: [GET_RISK_RULES_REQUESTED, GET_RISK_RULES_SUCCESS, GET_RISK_RULES_FAILURE],
    promise: client => client.get(`getRiskRules/${brandId}`)
  };
};

export const deleteRiskRules = (id) => {
  return {
    types: [DELETE_RISK_RULES_REQUESTED, DELETE_RISK_RULES_SUCCESS, DELETE_RISK_RULES_FAILURE],
    promise: client => client.post(`deleteRiskRules/${id}`)
  };
};

export const addRiskRule = (data) => {
  return {
    types: [ADD_RISK_RULES_REQUESTED, ADD_RISK_RULES_SUCCESS, ADD_RISK_RULES_FAILURE],
    promise: client => client.post('addRiskRule', { data })
  };
};

export const updateRiskRule = (data) => {
  return {
    types: [UPDATE_RISK_RULES_REQUESTED, UPDATE_RISK_RULES_SUCCESS, UPDATE_RISK_RULES_FAILURE],
    promise: client => client.post('updateRiskRule', { data })
  };
};

export const getRiskRuleById = (riskRuleId) => {
  return {
    types: [GET_RISK_RULES_BY_ID_REQUESTED, GET_RISK_RULES_BY_ID_SUCCESS, GET_RISK_RULES_BY_ID_FAILURE],
    promise: client => client.get(`getRiskRuleById/${riskRuleId}`)
  };
};

export const getRiskRuleTypes = () => {
  return {
    types: [GET_RISK_RULE_TYPES_REQUESTED, GET_RISK_RULE_TYPES_SUCCESS, GET_RISK_RULE_TYPES_FAILURE],
    promise: client => client.get('getRiskRuleTypes')
  };
};

export const saveRiskRule = (data) => {
  return {
    types: [SAVE_RISK_RULE_REQUESTED, SAVE_RISK_RULE_SUCCESS, SAVE_RISK_RULE_FAILURE],
    promise: client => client.post('saveRiskRule', { data })
  };
};

export const deletRiskRuleId = (brandRiskRuleId) => {
  return {
    types: [DELETE_RISK_RULE_REQUESTED, DELETE_RISK_RULE_SUCCESS, DELETE_RISK_RULE_FAILURE],
    promise: client => client.post(`deleteRiskRule/${brandRiskRuleId}`)
  };
};

export const handleSearchByNumber = (data) => {
  return {
    types: [SEARCH_GUEST_BY_PHONE_NUMBER_REQUESTED, SEARCH_GUEST_BY_PHONE_NUMBER_SUCCESS, SEARCH_GUEST_BY_PHONE_NUMBER_FAILURE],
    promise: client => client.post('searchSegmentGuest', { data })
  };
};

export const resetSearchResult = () => {
  return {
    type: CLEAR_SEARCH_GUEST_BY_PHONE_NUMBER,
  };
};
