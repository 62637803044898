export const POST_ADD_MENUSTORE_REQUESTED = 'menuLocation/POST_ADD_MENUSTORE_REQUESTED';
export const POST_ADD_MENUSTORE_SUCCESS = 'menuLocation/POST_ADD_MENUSTORE_SUCCESS';
export const POST_ADD_MENUSTORE_FAILURE = 'menuLocation/POST_ADD_MENUSTORE_FAILURE';

export const GET_MENUSTORES_REQUESTED = 'menuLocation/GET_MENUSTORES_REQUESTED';
export const GET_MENUSTORES_SUCCESS = 'menuLocation/GET_MENUSTORES_SUCCESS';
export const GET_MENUSTORES_FAILURE = 'menuLocation/GET_MENUSTORES_FAILURE';

export const POST_DELETE_MENUSTORE_REQUESTED = 'menuLocation/POST_DELETE_MENUSTORE_REQUESTED';
export const POST_DELETE_MENUSTORE_SUCCESS = 'menuLocation/POST_DELETE_MENUSTORE_SUCCESS';
export const POST_DELETE_MENUSTORE_FAILURE = 'menuLocation/POST_DELETE_MENUSTORE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  menuStoreList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_MENUSTORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_ADD_MENUSTORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_ADD_MENUSTORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MENUSTORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENUSTORES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuStoreList: action.result,
      };
    }
    case GET_MENUSTORES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        menuStoreList: [],
      };
    }
    case POST_DELETE_MENUSTORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POST_DELETE_MENUSTORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case POST_DELETE_MENUSTORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const addMenuStore = (menuId, storeId) => {
  return {
    types: [POST_ADD_MENUSTORE_REQUESTED, POST_ADD_MENUSTORE_SUCCESS, POST_ADD_MENUSTORE_FAILURE],
    promise: client => client.post(`addMenuStore/${menuId}/storeId/${storeId}`)
  };
};

export const getMenuStores = (menuId) => {
  return {
    types: [GET_MENUSTORES_REQUESTED, GET_MENUSTORES_SUCCESS, GET_MENUSTORES_FAILURE],
    promise: client => client.get(`getMenuStores/${menuId}`)
  };
};

export const deleteMenuStore = (menuId, storeId) => {
  return {
    types: [POST_DELETE_MENUSTORE_REQUESTED, POST_DELETE_MENUSTORE_SUCCESS, POST_DELETE_MENUSTORE_FAILURE],
    promise: client => client.post(`deleteMenuStore/${menuId}/storeId/${storeId}`)
  };
};
