export const ADD_MENU_SCREEN_REQUESTED = 'menu/ADD_MENU_SCREEN_REQUESTED';
export const ADD_MENU_SCREEN_SUCCESS = 'menu/ADD_MENU_SCREEN_SUCCESS';
export const ADD_MENU_SCREEN_FAILURE = 'menu/ADD_MENU_SCREEN_FAILURE';

export const UPDATE_MENU_SCREEN_REQUESTED = 'menu/UPDATE_MENU_SCREEN_REQUESTED';
export const UPDATE_MENU_SCREEN_SUCCESS = 'menu/UPDATE_MENU_SCREEN_SUCCESS';
export const UPDATE_MENU_SCREEN_FAILURE = 'menu/UPDATE_MENU_SCREEN_FAILURE';

export const DELETE_MENU_SCREEN_REQUESTED = 'menu/DELETE_MENU_SCREEN_REQUESTED';
export const DELETE_MENU_SCREEN_SUCCESS = 'menu/DELETE_MENU_SCREEN_SUCCESS';
export const DELETE_MENU_SCREEN_FAILURE = 'menu/DELETE_MENU_SCREEN_FAILURE';

export const CLONE_MENU_SCREEN_REQUESTED = 'menu/CLONE_MENU_SCREEN_REQUESTED';
export const CLONE_MENU_SCREEN_SUCCESS = 'menu/CLONE_MENU_SCREEN_SUCCESS';
export const CLONE_MENU_SCREEN_FAILURE = 'menu/CLONE_MENU_SCREEN_FAILURE';

export const ADD_SCREEN_IMAGES_REQUESTED = 'menuItem/ADD_SCREEN_IMAGES_REQUESTED';
export const ADD_SCREEN_IMAGES_SUCCESS = 'menuItem/ADD_SCREEN_IMAGES_SUCCESS';
export const ADD_SCREEN_IMAGES_FAILURE = 'menuItem/ADD_SCREEN_IMAGES_FAILURE';

export const DELETE_SCREEN_IMAGES_REQUESTED = 'menuItem/DELETE_SCREEN_IMAGES_REQUESTED';
export const DELETE_SCREEN_IMAGES_SUCCESS = 'menuItem/DELETE_SCREEN_IMAGES_SUCCESS';
export const DELETE_SCREEN_IMAGES_FAILURE = 'menuItem/DELETE_SCREEN_IMAGES_FAILURE';

export const MENU_SORT_MODES_REQUESTED = 'menu/MENU_SORT_MODES_REQUESTED';
export const MENU_SORT_MODES_SUCCESS = 'menu/MENU_SORT_MODES_SUCCESS';
export const MENU_SORT_MODES_FAILURE = 'menu/MENU_SORT_MODES_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  menuSortModesList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MENU_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MENU_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MENU_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_MENU_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MENU_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_MENU_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MENU_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MENU_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MENU_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CLONE_MENU_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CLONE_MENU_SCREEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CLONE_MENU_SCREEN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SCREEN_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SCREEN_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_SCREEN_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_SCREEN_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SCREEN_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_SCREEN_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case MENU_SORT_MODES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case MENU_SORT_MODES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuSortModesList: action.result
      };
    }
    case MENU_SORT_MODES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export const addMenuScreen = (data) => {
  return {
    types: [ADD_MENU_SCREEN_REQUESTED, ADD_MENU_SCREEN_SUCCESS, ADD_MENU_SCREEN_FAILURE],
    promise: client => client.post('addMenuScreen', { data })
  };
};

export const updateMenuScreen = (data) => {
  return {
    types: [UPDATE_MENU_SCREEN_REQUESTED, UPDATE_MENU_SCREEN_SUCCESS, UPDATE_MENU_SCREEN_FAILURE],
    promise: client => client.post('updateMenuScreen', { data })
  };
};

export const deleteMenuScreen = (screenId) => {
  return {
    types: [UPDATE_MENU_SCREEN_REQUESTED, UPDATE_MENU_SCREEN_SUCCESS, UPDATE_MENU_SCREEN_FAILURE],
    promise: client => client.post(`deleteMenuScreen/${screenId}`)
  };
};

export const cloneScreen = (screenId, menuId) => {
  return {
    types: [CLONE_MENU_SCREEN_REQUESTED, CLONE_MENU_SCREEN_SUCCESS, CLONE_MENU_SCREEN_FAILURE],
    promise: client => client.post(`cloneScreen/${screenId}/menuId/${menuId}`)
  };
};

export const addScreenImage = (menuItemId, data) => {
  return {
    types: [ADD_SCREEN_IMAGES_REQUESTED, ADD_SCREEN_IMAGES_SUCCESS, ADD_SCREEN_IMAGES_FAILURE],
    promise: client => client.post(`addScreenImage/${menuItemId}/image/add`, { data }),
  };
};


export const deleteScreenImage = (menuItemId) => {
  return {
    types: [DELETE_SCREEN_IMAGES_REQUESTED, DELETE_SCREEN_IMAGES_SUCCESS, DELETE_SCREEN_IMAGES_FAILURE],
    promise: client => client.post(`addScreenImage/${menuItemId}/image/delete`),
  };
};

export const menuSortModes = () => {
  return {
    types: [MENU_SORT_MODES_REQUESTED, MENU_SORT_MODES_SUCCESS, MENU_SORT_MODES_FAILURE],
    promise: client => client.get('menuSortModes')
  };
};
