
const GET_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED';
const GET_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS';
const GET_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE';

const UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED = 'mwebCustomizations/UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED';
const UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS = 'mwebCustomizations/UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS';
const UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE = 'mwebCustomizations/UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE';

const SAVE_BRAND_CUSTOMIZATIONS_REORDER_REQUESTED = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_REORDER_REQUESTED';
const SAVE_BRAND_CUSTOMIZATIONS_REORDER_SUCCESS = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_REORDER_SUCCESS';
const SAVE_BRAND_CUSTOMIZATIONS_REORDER_FAILURE = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_REORDER_FAILURE';

const DELETE_BRAND_CUSTOMIZATIONS_REQUESTED = 'mwebCustomizations/DELETE_BRAND_CUSTOMIZATIONS_REQUESTED';
const DELETE_BRAND_CUSTOMIZATIONS_SUCCESS = 'mwebCustomizations/DELETE_BRAND_CUSTOMIZATIONS_SUCCESS';
const DELETE_BRAND_CUSTOMIZATIONS_FAILURE = 'mwebCustomizations/DELETE_BRAND_CUSTOMIZATIONS_FAILURE';

const SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED';
const SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS';
const SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE';

const GET_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED';
const GET_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS';
const GET_BRAND_CUSTOMIZATIONS_LABELS_FAILURE = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_LABELS_FAILURE';

const SAVE_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED';
const SAVE_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS';
const SAVE_BRAND_CUSTOMIZATIONS_LABELS_FAILURE = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_LABELS_FAILURE';

const GET_BRAND_CUSTOMIZATIONS_THEME_REQUESTED = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_THEME_REQUESTED';
const GET_BRAND_CUSTOMIZATIONS_THEME_SUCCESS = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_THEME_SUCCESS';
const GET_BRAND_CUSTOMIZATIONS_THEME_FAILURE = 'mwebCustomizations/GET_BRAND_CUSTOMIZATIONS_THEME_FAILURE';

const SAVE_BRAND_CUSTOMIZATIONS_THEME_REQUESTED = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_THEME_REQUESTED';
const SAVE_BRAND_CUSTOMIZATIONS_THEME_SUCCESS = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_THEME_SUCCESS';
const SAVE_BRAND_CUSTOMIZATIONS_THEME_FAILURE = 'mwebCustomizations/SAVE_BRAND_CUSTOMIZATIONS_THEME_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  brandCustomizationsTheme: null,
  brandCustomizationsLanguage: null,
  brandCustomizationsLabels: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandCustomizationsLanguage: action.result,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        brandCustomizationsLanguage: null,
      };
    }
    case UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_REORDER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_REORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_REORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_BRAND_CUSTOMIZATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_CUSTOMIZATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_BRAND_CUSTOMIZATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandCustomizationsLabels: action.result,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_LABELS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        brandCustomizationsLabels: null,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_LABELS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        brandCustomizationsTheme: action.result,
      };
    }
    case GET_BRAND_CUSTOMIZATIONS_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        brandCustomizationsTheme: null,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_THEME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SAVE_BRAND_CUSTOMIZATIONS_THEME_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: return state;
  }
};

export const getBrandCustomizationsLanguage = (brandId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED, GET_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS, GET_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE],
    promise: client => client.get(`getBrandCustomizationsLanguage/${brandId}`)
  };
};

export const saveBrandCustomizationsLanguage = (brandId, languageId) => {
  return {
    types: [UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED, UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS, UPDATE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE],
    promise: client => client.post(`saveBrandCustomizationsLanguage/${brandId}/languageId/${languageId}`)
  };
};

export const saveBrandCustomizationsReorder = (brandId, data) => {
  return {
    types: [SAVE_BRAND_CUSTOMIZATIONS_REORDER_REQUESTED, SAVE_BRAND_CUSTOMIZATIONS_REORDER_SUCCESS, SAVE_BRAND_CUSTOMIZATIONS_REORDER_FAILURE],
    promise: client => client.post(`saveBrandCustomizationsReorder/${brandId}`, { data })
  };
};

export const deleteBrandCustomizations = (brandId, languageId) => {
  return {
    types: [DELETE_BRAND_CUSTOMIZATIONS_REQUESTED, DELETE_BRAND_CUSTOMIZATIONS_SUCCESS, DELETE_BRAND_CUSTOMIZATIONS_FAILURE],
    promise: client => client.post(`deleteBrandCustomizations/${brandId}/languageId/${languageId}`)
  };
};

export const updateBrandCustomizationsLanguage = (data) => {
  return {
    types: [SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_REQUESTED, SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_SUCCESS, SAVE_BRAND_CUSTOMIZATIONS_LANGUAGE_FAILURE],
    promise: client => client.post('updateBrandCustomizationsLanguage', { data })
  };
};

export const getBrandCustomizationsLabels = (brandId, languageId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED, GET_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS, GET_BRAND_CUSTOMIZATIONS_LABELS_FAILURE],
    promise: client => client.get(`getBrandCustomizationsLabels/${brandId}/languageId/${languageId}`)
  };
};

export const saveBrandCustomizationsLabels = (data) => {
  return {
    types: [SAVE_BRAND_CUSTOMIZATIONS_LABELS_REQUESTED, SAVE_BRAND_CUSTOMIZATIONS_LABELS_SUCCESS, SAVE_BRAND_CUSTOMIZATIONS_LABELS_FAILURE],
    promise: client => client.post('updateBrandCustomizationsLabels', { data })
  };
};
export const getBrandCustomizationsTheme = (brandId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATIONS_THEME_REQUESTED, GET_BRAND_CUSTOMIZATIONS_THEME_SUCCESS, GET_BRAND_CUSTOMIZATIONS_THEME_FAILURE],
    promise: client => client.get(`getBrandCustomizationsTheme/${brandId}`)
  };
};
export const saveBrandCustomizationsTheme = data => ({
  types: [SAVE_BRAND_CUSTOMIZATIONS_THEME_REQUESTED, SAVE_BRAND_CUSTOMIZATIONS_THEME_SUCCESS, SAVE_BRAND_CUSTOMIZATIONS_THEME_FAILURE],
  promise: client => client.post('saveBrandCustomizationsTheme', { data }),
});
