import React, { Fragment, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setHeaderInfo } from '@reducers/header';
import getMetaData from '@constants/meta';
import Loader from '@components/common/Loader';
import { setLocationInfo } from '@reducers/v4/mainContent';
import getV4MetaData from '@constants/meta-v4';

const OnRouteChange = ({ restaurantInfo, loading, ...props }) => {
  const onRouteChangeEvent = (location) => {
    props.setHeaderInfo(getMetaData(location.pathname));
    props.setLocationInfo(getV4MetaData(location.pathname));
  };

  useEffect(() => {
    onRouteChangeEvent(props.location);
  }, [props.location]);

  // useEffect(() => {
  //   props.setHeaderInfo(getMetaData(props.location.pathname));
  //   props.setLocationInfo(getV4MetaData(props.location.pathname));
  //   props.navigate(onRouteChangeEvent);
  // }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      {props.children}
    </Fragment>
  );
};
const mapStateToProps = ({ header }) => ({
  loading: header.loading,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setHeaderInfo,
  setLocationInfo
}, dispatch);
export default (connect(mapStateToProps, mapDispatchToProps)(OnRouteChange));
