import uuid from 'uuid/v4';

export const GET_STORES_REQUESTED = 'store/GET_STORES_REQUESTED';
export const GET_STORES_SUCCESS = 'store/GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'store/GET_STORES_FAILURE';

export const ADD_STORES_REQUESTED = 'store/ADD_STORES_REQUESTED';
export const ADD_STORES_SUCCESS = 'store/ADD_STORES_SUCCESS';
export const ADD_STORES_FAILURE = 'store/ADD_STORES_FAILURE';

export const UPDATE_STORES_REQUESTED = 'store/UPDATE_STORES_REQUESTED';
export const UPDATE_STORES_SUCCESS = 'store/UPDATE_STORES_SUCCESS';
export const UPDATE_STORES_FAILURE = 'store/UPDATE_STORES_FAILURE';

export const GET_STORES_BYID_REQUESTED = 'store/GET_STORES_BYID_REQUESTED';
export const GET_STORES_BYID_SUCCESS = 'store/GET_STORES_BYID_SUCCESS';
export const GET_STORES_BYID_FAILURE = 'store/GET_STORES_BYID_FAILURE';

export const GET_GUEST_APP_BY_STOREID_REQUESTED = 'store/GET_GUEST_APP_BY_STOREID_REQUESTED';
export const GET_GUEST_APP_BY_STOREID_SUCCESS = 'store/GET_GUEST_APP_BY_STOREID_SUCCESS';
export const GET_GUEST_APP_BY_STOREID_FAILURE = 'store/GET_GUEST_APP_BY_STOREID_FAILURE';

export const UPDATE_GUEST_APP_BY_STOREID_REQUESTED = 'store/UPDATE_GUEST_APP_BY_STOREID_REQUESTED';
export const UPDATE_GUEST_APP_BY_STOREID_SUCCESS = 'store/UPDATE_GUEST_APP_BY_STOREID_SUCCESS';
export const UPDATE_GUEST_APP_BY_STOREID_FAILURE = 'store/UPDATE_GUEST_APP_BY_STOREID_FAILURE';

export const GET_STORES_TREE_REQUESTED = 'store/GET_STORES_TREE_REQUESTED';
export const GET_STORES_TREE_SUCCESS = 'store/GET_STORES_TREE_SUCCESS';
export const GET_STORES_TREE_FAILURE = 'store/GET_STORES_TREE_FAILURE';

export const SELECTED_STORE_UPDATE = 'store/SELECTED_STORE_UPDATE';

export const ADD_STORE_IMAGE_REQUESTED = 'store/ADD_STORE_IMAGE_REQUESTED';
export const ADD_STORE_IMAGE_SUCCESS = 'store/ADD_STORE_IMAGE_SUCCESS';
export const ADD_STORE_IMAGE_FAILURE = 'store/ADD_STORE_IMAGE_FAILURE';

export const ADD_STORE_LOGO_IMAGE_REQUESTED = 'store/ADD_STORE_LOGO_IMAGE_REQUESTED';
export const ADD_STORE_LOGO_IMAGE_SUCCESS = 'store/ADD_STORE_LOGO_IMAGE_SUCCESS';
export const ADD_STORE_LOGO_IMAGE_FAILURE = 'store/ADD_STORE_LOGO_IMAGE_FAILURE';

export const ADD_KIOSK_LANDING_IMAGE_REQUESTED = 'store/ADD_KIOSK_LANDING_IMAGE_REQUESTED';
export const ADD_KIOSK_LANDING_IMAGE_SUCCESS = 'store/ADD_KIOSK_LANDING_IMAGE_SUCCESS';
export const ADD_KIOSK_LANDING_IMAGE_FAILURE = 'store/ADD_KIOSK_LANDING_IMAGE_FAILURE';

export const ADD_KIOSK_CONFIRMATION_IMAGE_REQUESTED = 'store/ADD_KIOSK_CONFIRMATION_IMAGE_REQUESTED';
export const ADD_KIOSK_CONFIRMATION_IMAGE_SUCCESS = 'store/ADD_KIOSK_CONFIRMATION_IMAGE_SUCCESS';
export const ADD_KIOSK_CONFIRMATION_IMAGE_FAILURE = 'store/ADD_KIOSK_CONFIRMATION_IMAGE_FAILURE';

export const ADD_TABLETOP_LANDING_IMAGE_REQUESTED = 'store/ADD_TABLETOP_LANDING_IMAGE_REQUESTED';
export const ADD_TABLETOP_LANDING_IMAGE_SUCCESS = 'store/ADD_TABLETOP_LANDING_IMAGE_SUCCESS';
export const ADD_TABLETOP_LANDING_IMAGE_FAILURE = 'store/ADD_TABLETOP_LANDING_IMAGE_FAILURE';

export const ADD_TABLETOP_CONFIRMATION_IMAGE_REQUESTED = 'store/ADD_TABLETOP_CONFIRMATION_IMAGE_REQUESTED';
export const ADD_TABLETOP_CONFIRMATION_IMAGE_SUCCESS = 'store/ADD_TABLETOP_CONFIRMATION_IMAGE_SUCCESS';
export const ADD_TABLETOP_CONFIRMATION_IMAGE_FAILURE = 'store/ADD_TABLETOP_CONFIRMATION_IMAGE_FAILURE';

export const ADD_DEFAULT_MENUITEM_IMAGE_REQUESTED = 'store/ADD_DEFAULT_MENUITEM_IMAGE_REQUESTED';
export const ADD_DEFAULT_MENUITEM_IMAGE_SUCCESS = 'store/ADD_DEFAULT_MENUITEM_IMAGE_SUCCESS';
export const ADD_DEFAULT_MENUITEM_IMAGE_FAILURE = 'store/ADD_DEFAULT_MENUITEM_IMAGE_FAILURE';

export const DELETE_STORE_IMAGE_REQUESTED = 'store/DELETE_STORE_IMAGE_REQUESTED';
export const DELETE_STORE_IMAGE_SUCCESS = 'store/DELETE_STORE_IMAGE_SUCCESS';
export const DELETE_STORE_IMAGE_FAILURE = 'store/DELETE_STORE_IMAGE_FAILURE';

export const DELETE_STORE_LOGO_IMAGE_REQUESTED = 'store/DELETE_STORE_LOGO_IMAGE_REQUESTED';
export const DELETE_STORE_LOGO_IMAGE_SUCCESS = 'store/DELETE_STORE_LOGO_IMAGE_SUCCESS';
export const DELETE_STORE_LOGO_IMAGE_FAILURE = 'store/DELETE_STORE_LOGO_IMAGE_FAILURE';

export const DELETE_KIOSK_LANDING_IMAGE_REQUESTED = 'store/DELETE_KIOSK_LANDING_IMAGE_REQUESTED';
export const DELETE_KIOSK_LANDING_IMAGE_SUCCESS = 'store/DELETE_KIOSK_LANDING_IMAGE_SUCCESS';
export const DELETE_KIOSK_LANDING_IMAGE_FAILURE = 'store/DELETE_KIOSK_LANDING_IMAGE_FAILURE';

export const DELETE_KIOSK_CONFIRMATION_IMAGE_REQUESTED = 'store/DELETE_KIOSK_CONFIRMATION_IMAGE_REQUESTED';
export const DELETE_KIOSK_CONFIRMATION_IMAGE_SUCCESS = 'store/DELETE_KIOSK_CONFIRMATION_IMAGE_SUCCESS';
export const DELETE_KIOSK_CONFIRMATION_IMAGE_FAILURE = 'store/DELETE_KIOSK_CONFIRMATION_IMAGE_FAILURE';

export const DELETE_TABLETOP_LANDING_IMAGE_REQUESTED = 'store/DELETE_TABLETOP_LANDING_IMAGE_REQUESTED';
export const DELETE_TABLETOP_LANDING_IMAGE_SUCCESS = 'store/DELETE_TABLETOP_LANDING_IMAGE_SUCCESS';
export const DELETE_TABLETOP_LANDING_IMAGE_FAILURE = 'store/DELETE_TABLETOP_LANDING_IMAGE_FAILURE';

export const DELETE_TABLETOP_CONFIRMATION_IMAGE_REQUESTED = 'store/DELETE_TABLETOP_CONFIRMATION_IMAGE_REQUESTED';
export const DELETE_TABLETOP_CONFIRMATION_IMAGE_SUCCESS = 'store/DELETE_TABLETOP_CONFIRMATION_IMAGE_SUCCESS';
export const DELETE_TABLETOP_CONFIRMATION_IMAGE_FAILURE = 'store/DELETE_TABLETOP_CONFIRMATION_IMAGE_FAILURE';

export const DELETE_DEFAULT_MENUITEM_IMAGE_REQUESTED = 'store/DELETE_DEFAULT_MENUITEM_IMAGE_REQUESTED';
export const DELETE_DEFAULT_MENUITEM_IMAGE_SUCCESS = 'store/DELETE_DEFAULT_MENUITEM_IMAGE_SUCCESS';
export const DELETE_DEFAULT_MENUITEM_IMAGE_FAILURE = 'store/DELETE_DEFAULT_MENUITEM_IMAGE_FAILURE';

export const GET_STORE_OTHER_SETTINGS_REQUESTED = 'store/GET_STORE_OTHER_SETTINGS_REQUESTED';
export const GET_STORE_OTHER_SETTINGS_SUCCESS = 'store/GET_STORE_OTHER_SETTINGS_SUCCESS';
export const GET_STORE_OTHER_SETTINGS_FAILURE = 'store/GET_STORE_OTHER_SETTINGS_FAILURE';

export const UPDATE_STORE_OTHER_SETTINGS_REQUESTED = 'store/UPDATE_STORE_OTHER_SETTINGS_REQUESTED';
export const UPDATE_STORE_OTHER_SETTINGS_SUCCESS = 'store/UPDATE_STORE_OTHER_SETTINGS_SUCCESS';
export const UPDATE_STORE_OTHER_SETTINGS_FAILURE = 'store/UPDATE_STORE_OTHER_SETTINGS_FAILURE';

export const GET_TIPS_CONFIG_LIST_REQUESTED = 'store/GET_TIPS_CONFIG_LIST_REQUESTED';
export const GET_TIPS_CONFIG_LIST_SUCCESS = 'store/GET_TIPS_CONFIG_LIST_SUCCESS';
export const GET_TIPS_CONFIG_LIST_FAILURE = 'store/GET_TIPS_CONFIG_LIST_FAILURE';

export const POS_AGENT_DOWNLOAD_REQUESTED = 'store/POS_AGENT_DOWNLOAD_REQUESTED';
export const POS_AGENT_DOWNLOAD_SUCCESS = 'store/POS_AGENT_DOWNLOAD_SUCCESS';
export const POS_AGENT_DOWNLOAD_FAILURE = 'store/POS_AGENT_DOWNLOAD_FAILURE';

export const GET_STORE_STATUS_REQUESTED = 'store/GET_STORE_STATUS_REQUESTED';
export const GET_STORE_STATUS_SUCCESS = 'store/GET_STORE_STATUS_SUCCESS';
export const GET_STORE_STATUS_FAILURE = 'store/GET_STORE_STATUS_FAILURE';

export const GET_DASHBOARD_STORE_REQUESTED = 'store/GET_DASHBOARD_STORE_REQUESTED';
export const GET_DASHBOARD_STORE_SUCCESS = 'store/GET_DASHBOARD_STORE_SUCCESS';
export const GET_DASHBOARD_STORE_FAILURE = 'store/GET_DASHBOARD_STORE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getStoreById: null,
  storesList: [],
  getGuestApp: null,
  getStoresTree: null,
  otherSettingsList: [],
  tipsConfigList: [],
  posAgentData: null,
  storeStatus: [],
  storesTreeLoading: false,
  getStoreData: null,
  storeSelected: localStorage.getItem('selectedStore') ? JSON.parse(localStorage.getItem('selectedStore')) : ''
};

let timeoutId = '';

export default(state = initialState, action) => {
  switch (action.type) {
    case GET_STORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storesList: action.result.map(obj => ({ ...obj, uniqueId: uuid() })),
      };
    }
    case GET_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STORES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORES_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORES_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getStoreById: action.result,
      };
    }
    case GET_STORES_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORES_TREE_REQUESTED: {
      return {
        ...state,
        loading: true,
        storesTreeLoading: true,
        loaded: false,
      };
    }
    case GET_STORES_TREE_SUCCESS: {
      return {
        ...state,
        loading: false,
        storesTreeLoading: false,
        loaded: true,
        error: false,
        getStoresTree: action.result,
      };
    }
    case GET_STORES_TREE_FAILURE: {
      return {
        ...state,
        loading: false,
        storesTreeLoading: false,
        loaded: true,
        error: true,
      };
    }
    case SELECTED_STORE_UPDATE: {
      if (action.payload) {
        window.localStorage.setItem('selectedStore', JSON.stringify(action.payload));
      }
      return {
        ...state,
        storeSelected: action.payload,
      };
    }
    case GET_GUEST_APP_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getGuestApp: null,
      };
    }
    case GET_GUEST_APP_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getGuestApp: action.result,
      };
    }
    case GET_GUEST_APP_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        getGuestApp: null,
      };
    }
    case UPDATE_GUEST_APP_BY_STOREID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_GUEST_APP_BY_STOREID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_GUEST_APP_BY_STOREID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STORE_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STORE_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STORE_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_STORE_LOGO_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_STORE_LOGO_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_STORE_LOGO_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_KIOSK_LANDING_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_KIOSK_LANDING_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_KIOSK_LANDING_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_KIOSK_CONFIRMATION_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_KIOSK_CONFIRMATION_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_KIOSK_CONFIRMATION_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_TABLETOP_LANDING_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TABLETOP_LANDING_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TABLETOP_LANDING_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_TABLETOP_CONFIRMATION_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TABLETOP_CONFIRMATION_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_TABLETOP_CONFIRMATION_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_DEFAULT_MENUITEM_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DEFAULT_MENUITEM_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_DEFAULT_MENUITEM_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_STORE_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_STORE_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_STORE_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_STORE_LOGO_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_STORE_LOGO_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_STORE_LOGO_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_KIOSK_LANDING_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_KIOSK_LANDING_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_KIOSK_LANDING_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_KIOSK_CONFIRMATION_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_KIOSK_CONFIRMATION_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_KIOSK_CONFIRMATION_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_TABLETOP_LANDING_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TABLETOP_LANDING_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_TABLETOP_LANDING_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_TABLETOP_CONFIRMATION_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TABLETOP_CONFIRMATION_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_TABLETOP_CONFIRMATION_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_DEFAULT_MENUITEM_IMAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_DEFAULT_MENUITEM_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_DEFAULT_MENUITEM_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_OTHER_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_OTHER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        otherSettingsList: action.result,
      };
    }
    case GET_STORE_OTHER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_STORE_OTHER_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_STORE_OTHER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_STORE_OTHER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TIPS_CONFIG_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TIPS_CONFIG_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tipsConfigList: action.result,
      };
    }
    case GET_TIPS_CONFIG_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case POS_AGENT_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case POS_AGENT_DOWNLOAD_SUCCESS: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        posAgentData: action.result,
      };
    }
    case POS_AGENT_DOWNLOAD_FAILURE: {
      clearTimeout(timeoutId);
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeStatus: action.result
      };
    }
    case GET_STORE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DASHBOARD_STORE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DASHBOARD_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getStoreData: action.result,
      };
    }
    case GET_DASHBOARD_STORE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getStoresByBrand = (brandId) => {
  return {
    types: [GET_STORES_REQUESTED, GET_STORES_SUCCESS, GET_STORES_FAILURE],
    promise: client => client.get(`getStoresByBrand/${brandId}`)
  };
};

export const addStore = (data) => {
  return {
    types: [ADD_STORES_REQUESTED, ADD_STORES_SUCCESS, ADD_STORES_FAILURE],
    promise: client => client.post('addStore', { data })
  };
};

export const updateStore = (data) => {
  return {
    types: [UPDATE_STORES_REQUESTED, UPDATE_STORES_SUCCESS, UPDATE_STORES_FAILURE],
    promise: client => client.post('updateStore', { data })
  };
};

export const getStoreById = (id) => {
  return {
    types: [GET_STORES_BYID_REQUESTED, GET_STORES_BYID_SUCCESS, GET_STORES_BYID_FAILURE],
    promise: client => client.get(`getStoreById/${id}`)
  };
};

export const getGuestAppByStoreId = (storeId) => {
  return {
    types: [GET_GUEST_APP_BY_STOREID_REQUESTED, GET_GUEST_APP_BY_STOREID_SUCCESS, GET_GUEST_APP_BY_STOREID_FAILURE],
    promise: client => client.get(`getGuestAppByStoreId/${storeId}`)
  };
};

export const updateGuestAppByStoreId = (storeId, data) => {
  return {
    types: [UPDATE_GUEST_APP_BY_STOREID_REQUESTED, UPDATE_GUEST_APP_BY_STOREID_SUCCESS, UPDATE_GUEST_APP_BY_STOREID_FAILURE],
    promise: client => client.post(`updateGuestAppByStoreId/${storeId}`, { data })
  };
};

export const getStoreTree = (companyId) => {
  return {
    types: [GET_STORES_TREE_REQUESTED, GET_STORES_TREE_SUCCESS, GET_STORES_TREE_FAILURE],
    promise: client => client.get(`getStoreTree/${companyId}`)
  };
};

export const setSelectedStoreData = (data) => {
  return {
    type: SELECTED_STORE_UPDATE,
    payload: data
  };
};

export const addStoreImage = (storeId, data) => {
  return {
    types: [ADD_STORE_IMAGE_REQUESTED, ADD_STORE_IMAGE_SUCCESS, ADD_STORE_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/default/save`, { data })
  };
};

export const addStorelogoImage = (storeId, data) => {
  return {
    types: [ADD_STORE_LOGO_IMAGE_REQUESTED, ADD_STORE_LOGO_IMAGE_SUCCESS, ADD_STORE_LOGO_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/logo/save`, { data })
  };
};

export const addKioskLandingImage = (storeId, data) => {
  return {
    types: [ADD_KIOSK_LANDING_IMAGE_REQUESTED, ADD_KIOSK_LANDING_IMAGE_SUCCESS, ADD_KIOSK_LANDING_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/kioskLanding/save`, { data })
  };
};

export const addKioskConfirmationImage = (storeId, data) => {
  return {
    types: [ADD_KIOSK_CONFIRMATION_IMAGE_REQUESTED, ADD_KIOSK_CONFIRMATION_IMAGE_SUCCESS, ADD_KIOSK_CONFIRMATION_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/kioskConfirmation/save`, { data })
  };
};

export const addTableTopLandingImage = (storeId, data) => {
  return {
    types: [ADD_TABLETOP_LANDING_IMAGE_REQUESTED, ADD_TABLETOP_LANDING_IMAGE_SUCCESS, ADD_TABLETOP_LANDING_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/tableTopLanding/save`, { data })
  };
};

export const addTableTopConfirmationImage = (storeId, data) => {
  return {
    types: [ADD_TABLETOP_CONFIRMATION_IMAGE_REQUESTED, ADD_TABLETOP_CONFIRMATION_IMAGE_SUCCESS, ADD_TABLETOP_CONFIRMATION_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/tableTopConfirmation/save`, { data })
  };
};

export const addDefaultMenuItemImage = (storeId, data) => {
  return {
    types: [ADD_DEFAULT_MENUITEM_IMAGE_REQUESTED, ADD_DEFAULT_MENUITEM_IMAGE_SUCCESS, ADD_DEFAULT_MENUITEM_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/defaultMenuItem/save`, { data })
  };
};

export const deleteStoreImage = (storeId) => {
  return {
    types: [ADD_STORE_IMAGE_REQUESTED, ADD_STORE_IMAGE_SUCCESS, ADD_STORE_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/default/delete`)
  };
};

export const deleteStorelogoImage = (storeId) => {
  return {
    types: [DELETE_STORE_LOGO_IMAGE_REQUESTED, DELETE_STORE_LOGO_IMAGE_SUCCESS, DELETE_STORE_LOGO_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/logo/delete`)
  };
};

export const deleteKioskLandingImage = (storeId) => {
  return {
    types: [DELETE_KIOSK_LANDING_IMAGE_REQUESTED, DELETE_KIOSK_LANDING_IMAGE_SUCCESS, DELETE_KIOSK_LANDING_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/kioskLanding/delete`)
  };
};

export const deleteKioskConfirmationImage = (storeId) => {
  return {
    types: [DELETE_KIOSK_CONFIRMATION_IMAGE_REQUESTED, DELETE_KIOSK_CONFIRMATION_IMAGE_SUCCESS, DELETE_KIOSK_CONFIRMATION_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/kioskConfirmation/delete`)
  };
};

export const deleteTableTopLandingImage = (storeId) => {
  return {
    types: [DELETE_TABLETOP_LANDING_IMAGE_REQUESTED, DELETE_TABLETOP_LANDING_IMAGE_SUCCESS, DELETE_TABLETOP_LANDING_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/tableTopLanding/delete`)
  };
};

export const deleteTableTopConfirmationImage = (storeId) => {
  return {
    types: [DELETE_TABLETOP_CONFIRMATION_IMAGE_REQUESTED, DELETE_TABLETOP_CONFIRMATION_IMAGE_SUCCESS, DELETE_TABLETOP_CONFIRMATION_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/tableTopConfirmation/delete`)
  };
};

export const deleteDefaultMenuItemImage = (storeId) => {
  return {
    types: [DELETE_DEFAULT_MENUITEM_IMAGE_REQUESTED, DELETE_DEFAULT_MENUITEM_IMAGE_SUCCESS, DELETE_DEFAULT_MENUITEM_IMAGE_FAILURE],
    promise: client => client.post(`storeImage/${storeId}/defaultMenuItem/delete`)
  };
};

export const getStoreOtherSettings = (storeId) => {
  return {
    types: [GET_STORE_OTHER_SETTINGS_REQUESTED, GET_STORE_OTHER_SETTINGS_SUCCESS, GET_STORE_OTHER_SETTINGS_FAILURE],
    promise: client => client.get(`getStoreOtherSettings/${storeId}`)
  };
};

export const updateStoreOtherSettings = (data) => {
  return {
    types: [UPDATE_STORE_OTHER_SETTINGS_REQUESTED, UPDATE_STORE_OTHER_SETTINGS_SUCCESS, UPDATE_STORE_OTHER_SETTINGS_FAILURE],
    promise: client => client.post('updateStoreOtherSettings', { data })
  };
};

export const getTipsConfigList = (brandId) => {
  return {
    types: [GET_TIPS_CONFIG_LIST_REQUESTED, GET_TIPS_CONFIG_LIST_SUCCESS, GET_TIPS_CONFIG_LIST_FAILURE],
    promise: client => client.get(`getTipsConfigList/${brandId}`)
  };
};

export const RequestError = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        const error = new Error('Request Timeout');
        reject(error);
        dispatch({
          type: POS_AGENT_DOWNLOAD_FAILURE
        });
      }, 60000);
    });
  };
};

export const posAgentDownload = (storeId) => {
  return {
    types: [POS_AGENT_DOWNLOAD_REQUESTED, POS_AGENT_DOWNLOAD_SUCCESS],
    promise: client => client.get(`posAgentDownload/${storeId}`)
  };
};
export const getStoreStatus = () => {
  return {
    types: [GET_STORE_STATUS_REQUESTED, GET_STORE_STATUS_SUCCESS, GET_STORE_STATUS_FAILURE],
    promise: client => client.get('getStoreStatus')
  };
};

export const getDashboardStore = (storeId) => {
  return {
    types: [GET_DASHBOARD_STORE_REQUESTED, GET_DASHBOARD_STORE_SUCCESS, GET_DASHBOARD_STORE_FAILURE],
    promise: client => client.get(`getDashboardStoreById/${storeId}`)
  };
};
