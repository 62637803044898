// import { omit } from 'lodash';
import { decriptResponse } from '@utility/helperFunction';

export const POST_WH_BATCH_REPORT_REQUESTED = 'wareHouseReports/POST_WH_BATCH_REPORT_REQUESTED';
export const POST_WH_BATCH_REPORT_SUCCESS = 'wareHouseReports/POST_WH_BATCH_REPORT_SUCCESS';
export const POST_WH_BATCH_REPORT_FAILURE = 'wareHouseReports/POST_WH_BATCH_REPORT_FAILURE';

export const POST_WH_CHECK_REPORT_REQUESTED = 'wareHouseReports/POST_WH_CHECK_REPORT_REQUESTED';
export const POST_WH_CHECK_REPORT_SUCCESS = 'wareHouseReports/POST_WH_CHECK_REPORT_SUCCESS';
export const POST_WH_CHECK_REPORT_FAILURE = 'wareHouseReports/POST_WH_CHECK_REPORT_FAILURE';

export const POST_WH_ROLLING_BATCH_REPORT_REQUESTED = 'wareHouseReports/POST_WH_ROLLING_BATCH_REPORT_REQUESTED';
export const POST_WH_ROLLING_BATCH_REPORT_SUCCESS = 'wareHouseReports/POST_WH_ROLLING_BATCH_REPORT_SUCCESS';
export const POST_WH_ROLLING_BATCH_REPORT_FAILURE = 'wareHouseReports/POST_WH_ROLLING_BATCH_REPORT_FAILURE';

export const POST_WH_ITEM_SALES_REPORT_REQUESTED = 'wareHouseReports/POST_WH_ITEM_SALES_REPORT_REQUESTED';
export const POST_WH_ITEM_SALES_REPORT_SUCCESS = 'wareHouseReports/POST_WH_ITEM_SALES_REPORT_SUCCESS';
export const POST_WH_ITEM_SALES_REPORT_FAILURE = 'wareHouseReports/POST_WH_ITEM_SALES_REPORT_FAILURE';

export const CLEAR_REPORTS_DATA = 'wareHouseReports/CLEAR_REPORTS_DATA';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  wareHouseReports: null,
};

export default(state = initialState, action) => {
  switch (action.type) {
    case POST_WH_BATCH_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        wareHouseReports: null,
      };
    }
    case POST_WH_BATCH_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // wareHouseReports: omit(action.result.body.rows, ['message']),
        wareHouseReports: action.result.body,
      };
    }
    case POST_WH_BATCH_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        wareHouseReports: null,
        reconciliationSummery: [],
      };
    }
    case POST_WH_CHECK_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        wareHouseReports: null,
      };
    }
    case POST_WH_CHECK_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        wareHouseReports: action.result.body,
      };
    }
    case POST_WH_CHECK_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        wareHouseReports: null,
        reconciliationSummery: [],
      };
    }
    case CLEAR_REPORTS_DATA: {
      return {
        ...state,
        wareHouseReports: null,
      };
    }
    case POST_WH_ROLLING_BATCH_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        wareHouseReports: null,
      };
    }
    case POST_WH_ROLLING_BATCH_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        wareHouseReports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body,
      };
    }
    case POST_WH_ROLLING_BATCH_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        wareHouseReports: null,
        reconciliationSummery: [],
      };
    }
    case POST_WH_ITEM_SALES_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        wareHouseReports: null,
      };
    }
    case POST_WH_ITEM_SALES_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        wareHouseReports: action.result.compressed ? action.result && action.result.compressedData && decriptResponse(action.result.compressedData) && decriptResponse(action.result.compressedData).body : action.result.data.body,
      };
    }
    case POST_WH_ITEM_SALES_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        wareHouseReports: null,
        reconciliationSummery: [],
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const postWareHouseReport = (data) => {
  return {
    types: [POST_WH_BATCH_REPORT_REQUESTED, POST_WH_BATCH_REPORT_SUCCESS, POST_WH_BATCH_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const postWareHouseCheckReport = (data) => {
  return {
    types: [POST_WH_CHECK_REPORT_REQUESTED, POST_WH_CHECK_REPORT_SUCCESS, POST_WH_CHECK_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const postWareHouseRollingReport = (data) => {
  return {
    types: [POST_WH_ROLLING_BATCH_REPORT_REQUESTED, POST_WH_ROLLING_BATCH_REPORT_SUCCESS, POST_WH_ROLLING_BATCH_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const postWareHouseItemSalesReport = (data) => {
  return {
    types: [POST_WH_ITEM_SALES_REPORT_REQUESTED, POST_WH_ITEM_SALES_REPORT_SUCCESS, POST_WH_ITEM_SALES_REPORT_FAILURE],
    promise: client => client.post('wareHouseReports', { data })
  };
};

export const resetReportsData = () => {
  return {
    type: CLEAR_REPORTS_DATA,
  };
};
