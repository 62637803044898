export const GET_CAMPAIGN_OCCURRENCE_LIST_DATA_REQUESTED = 'campaign/GET_CAMPAIGN_OCCURRENCE_LIST_DATA_REQUESTED';
export const GET_CAMPAIGN_OCCURRENCE_LIST_DATA_SUCCESS = 'campaign/GET_CAMPAIGN_OCCURRENCE_LIST_DATA_SUCCESS';
export const GET_CAMPAIGN_OCCURRENCE_LIST_DATA_FAILURE = 'campaign/GET_CAMPAIGN_OCCURRENCE_LIST_DATA_FAILURE';

export const ADD_CAMPAIGN_OCCURRENCE_REQUESTED = 'campaign/ADD_CAMPAIGN_OCCURRENCE_REQUESTED';
export const ADD_CAMPAIGN_OCCURRENCE_SUCCESS = 'campaign/ADD_CAMPAIGN_OCCURRENCE_SUCCESS';
export const ADD_CAMPAIGN_OCCURRENCE_FAILURE = 'campaign/ADD_CAMPAIGN_OCCURRENCE_FAILURE';

export const UPDATE_CAMPAIGN_OCCURRENCE_REQUESTED = 'campaign/UPDATE_CAMPAIGN_OCCURRENCE_REQUESTED';
export const UPDATE_CAMPAIGN_OCCURRENCE_SUCCESS = 'campaign/UPDATE_CAMPAIGN_OCCURRENCE_SUCCESS';
export const UPDATE_CAMPAIGN_OCCURRENCE_FAILURE = 'campaign/UPDATE_CAMPAIGN_OCCURRENCE_FAILURE';

export const GET_CAMPAIGN_OCCURRENCE_BYID_REQUESTED = 'campaign/GET_CAMPAIGN_OCCURRENCE_BYID_REQUESTED';
export const GET_CAMPAIGN_OCCURRENCE_BYID_SUCCESS = 'campaign/GET_CAMPAIGN_OCCURRENCE_BYID_SUCCESS';
export const GET_CAMPAIGN_OCCURRENCE_BYID_FAILURE = 'campaign/GET_CAMPAIGN_OCCURRENCE_BYID_FAILURE';

export const ADD_CAMPAIGN_OCCURRENCE_RULE_REQUESTED = 'campaign/ADD_CAMPAIGN_OCCURRENCE_RULE_REQUESTED';
export const ADD_CAMPAIGN_OCCURRENCE_RULE_SUCCESS = 'campaign/ADD_CAMPAIGN_OCCURRENCE_RULE_SUCCESS';
export const ADD_CAMPAIGN_OCCURRENCE_RULE_FAILURE = 'campaign/ADD_CAMPAIGN_OCCURRENCE_RULE_FAILURE';

const DELETE_CAMPAIGN_OCCURRENCE_RULE_REQUESTED = 'campaign/DELETE_CAMPAIGN_OCCURRENCE_RULE_REQUESTED';
const DELETE_CAMPAIGN_OCCURRENCE_RULE_SUCCESS = 'campaign/DELETE_CAMPAIGN_OCCURRENCE_RULE_SUCCESS';
const DELETE_CAMPAIGN_OCCURRENCE_RULE_FAILURE = 'campaign/DELETE_CAMPAIGN_OCCURRENCE_RULE_FAILURE';

export const GET_CAMPAIGN_OCCURRENCE_STATUS_REQUESTED = 'campaignStatus/GET_CAMPAIGN_OCCURRENCE_STATUS_REQUESTED';
export const GET_CAMPAIGN_OCCURRENCE_STATUS_SUCCESS = 'campaignStatus/GET_CAMPAIGN_OCCURRENCE_STATUS_SUCCESS';
export const GET_CAMPAIGN_OCCURRENCE_STATUS_FAILURE = 'campaignStatus/GET_CAMPAIGN_OCCURRENCE_STATUS_FAILURE';

export const CAMPAIGN_OCCURRENCE_PUBLISH_REQUESTED = 'campaign/CAMPAIGN_OCCURRENCE_PUBLISH_REQUESTED';
export const CAMPAIGN_OCCURRENCE_PUBLISH_SUCCESS = 'campaign/CAMPAIGN_OCCURRENCE_PUBLISH_SUCCESS';
export const CAMPAIGN_OCCURRENCE_PUBLISH_FAILURE = 'campaign/CAMPAIGN_OCCURRENCE_PUBLISH_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getCampaignOccerrenceList: [],
  getCampaignOccurrenceByID: null,
  campaignStatusList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_OCCURRENCE_LIST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getCampaignOccerrenceList: action.result,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPDATE_CAMPAIGN_OCCURRENCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_CAMPAIGN_OCCURRENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_CAMPAIGN_OCCURRENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCampaignOccurrenceByID: action.result,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_RULE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_CAMPAIGN_OCCURRENCE_RULE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_OCCURRENCE_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_CAMPAIGN_OCCURRENCE_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_CAMPAIGN_OCCURRENCE_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        campaignStatusList: action.result,
      };
    }
    case GET_CAMPAIGN_OCCURRENCE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CAMPAIGN_OCCURRENCE_PUBLISH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CAMPAIGN_OCCURRENCE_PUBLISH_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case CAMPAIGN_OCCURRENCE_PUBLISH_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
export const getCamapignOccurrence = (campaignId) => {
  return {
    types: [GET_CAMPAIGN_OCCURRENCE_LIST_DATA_REQUESTED, GET_CAMPAIGN_OCCURRENCE_LIST_DATA_SUCCESS, GET_CAMPAIGN_OCCURRENCE_LIST_DATA_FAILURE],
    promise: client => client.get(`getCamapignOccurrence/${campaignId}`)
  };
};

export const addCampaignOccurrence = (data) => {
  return {
    types: [ADD_CAMPAIGN_OCCURRENCE_REQUESTED, ADD_CAMPAIGN_OCCURRENCE_SUCCESS, ADD_CAMPAIGN_OCCURRENCE_FAILURE],
    promise: client => client.post('addCampaignOccurrence', { data })
  };
};

export const updateCampaignOccurrence = (data) => {
  return {
    types: [UPDATE_CAMPAIGN_OCCURRENCE_REQUESTED, UPDATE_CAMPAIGN_OCCURRENCE_SUCCESS, UPDATE_CAMPAIGN_OCCURRENCE_FAILURE],
    promise: client => client.post('updateCampaignOccurrence', { data })
  };
};

export const getCampaignOccurrenceById = (id) => {
  return {
    types: [GET_CAMPAIGN_OCCURRENCE_BYID_REQUESTED, GET_CAMPAIGN_OCCURRENCE_BYID_SUCCESS, GET_CAMPAIGN_OCCURRENCE_BYID_FAILURE],
    promise: client => client.get(`getCampaignOccurrenceById/${id}`)
  };
};

export const addCampaignOccurrenceRule = (data) => {
  return {
    types: [ADD_CAMPAIGN_OCCURRENCE_RULE_REQUESTED, ADD_CAMPAIGN_OCCURRENCE_RULE_SUCCESS, ADD_CAMPAIGN_OCCURRENCE_RULE_FAILURE],
    promise: client => client.post('addCampaignOccurrenceRule', { data })
  };
};

export const deleteCampaignOccurrenceRule = (CampaignOccurrenceRuleId) => {
  return {
    types: [DELETE_CAMPAIGN_OCCURRENCE_RULE_REQUESTED, DELETE_CAMPAIGN_OCCURRENCE_RULE_SUCCESS, DELETE_CAMPAIGN_OCCURRENCE_RULE_FAILURE],
    promise: client => client.post(`deleteCampaignOccurrenceRule/${CampaignOccurrenceRuleId}`)
  };
};

export const getCampaignStatus = () => {
  return {
    types: [GET_CAMPAIGN_OCCURRENCE_STATUS_REQUESTED, GET_CAMPAIGN_OCCURRENCE_STATUS_SUCCESS, GET_CAMPAIGN_OCCURRENCE_STATUS_FAILURE],
    promise: client => client.get('campaignStatus')
  };
};
export const publishCampaignOccurrence = (id) => {
  return {
    types: [CAMPAIGN_OCCURRENCE_PUBLISH_REQUESTED, CAMPAIGN_OCCURRENCE_PUBLISH_SUCCESS, CAMPAIGN_OCCURRENCE_PUBLISH_FAILURE],
    promise: client => client.post(`campaignPublish/${id}/publish`)
  };
};
