const currentReducer = 'segment';
const GET_SEGMENT_REQUESTED = `${currentReducer}/GET_SEGMENT_REQUESTED`;
const GET_SEGMENT_SUCCESS = `${currentReducer}/GET_SEGMENT_SUCCESS`;
const GET_SEGMENT_FAILURE = `${currentReducer}/GET_SEGMENT_FAILURE`;

const GET_SEGMENT_BY_ID_REQUESTED = `${currentReducer}/GET_SEGMENT_BY_ID_REQUESTED`;
const GET_SEGMENT_BY_ID_SUCCESS = `${currentReducer}/GET_SEGMENT_BY_ID_SUCCESS`;
const GET_SEGMENT_BY_ID_FAILURE = `${currentReducer}/GET_SEGMENT_BY_ID_FAILURE`;

const ADD_SEGMENT_REQUESTED = `${currentReducer}/ADD_SEGMENT_REQUESTED`;
const ADD_SEGMENT_SUCCESS = `${currentReducer}/ADD_SEGMENT_SUCCES`;
const ADD_SEGMENT_FAILURE = `${currentReducer}/ADD_SEGMENT_FAILURE`;

const UPDATE_SEGMENT_REQUESTED = `${currentReducer}/UPDATE_SEGMENT_REQUESTED`;
const UPDATE_SEGMENT_SUCCESS = `${currentReducer}/UPDATE_SEGMENT_SUCCES`;
const UPDATE_SEGMENT_FAILURE = `${currentReducer}/UPDATE_SEGMENT_FAILURE`;

const GET_SEGMENT_RULE_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_RULE_TYPES_REQUESTED`;
const GET_SEGMENT_RULE_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_RULE_TYPES_SUCCESS`;
const GET_SEGMENT_RULE_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_RULE_TYPES_FAILURE`;

const GET_SEGMENT_DATE_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_DATE_TYPES_REQUESTED`;
const GET_SEGMENT_DATE_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_DATE_TYPES_SUCCESS`;
const GET_SEGMENT_DATE_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_DATE_TYPES_FAILURE`;

const GET_SEGMENT_PERIOD_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_PERIOD_TYPES_REQUESTED`;
const GET_SEGMENT_PERIOD_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_PERIOD_TYPES_SUCCESS`;
const GET_SEGMENT_PERIOD_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_PERIOD_TYPES_FAILURE`;

const GET_SEGMENT_METRIC_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_METRIC_TYPES_REQUESTED`;
const GET_SEGMENT_METRIC_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_METRIC_TYPES_SUCCESS`;
const GET_SEGMENT_METRIC_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_METRIC_TYPES_FAILURE`;

const GET_SEGMENT_METRIC_VALUE_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_METRIC_VALUE_TYPES_REQUESTED`;
const GET_SEGMENT_METRIC_VALUE_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_METRIC_VALUE_TYPES_SUCCESS`;
const GET_SEGMENT_METRIC_VALUE_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_METRIC_VALUE_TYPES_FAILURE`;

const GET_SEGMENT_MATRIC_OPERATOR_TYPES_REQUESTED = `${currentReducer}/GET_SEGMENT_MATRIC_OPERATOR_TYPES_REQUESTED`;
const GET_SEGMENT_MATRIC_OPERATOR_TYPES_SUCCESS = `${currentReducer}/GET_SEGMENT_MATRIC_OPERATOR_TYPES_SUCCESSSS`;
const GET_SEGMENT_MATRIC_OPERATOR_TYPES_FAILURE = `${currentReducer}/GET_SEGMENT_MATRIC_OPERATOR_TYPES_FAILURE`;

const SAVE_BRAND_SEGMENT_RULE_REQUESTED = `${currentReducer}SAVE_BRAND_SEGMENT_RULE_REQUESTED`;
const SAVE_BRAND_SEGMENT_RULE_SUCCESS = `${currentReducer}/SAVE_BRAND_SEGMENT_RULE_SUCCES`;
const SAVE_BRAND_SEGMENT_RULE_FAILURE = `${currentReducer}/SAVE_BRAND_SEGMENT_RULE_FAILURE`;

const DELETE_BRAND_SEGMENT_RULE_BY_ID_REQUESTED = `${currentReducer}DELETE_BRAND_SEGMENT_RULE_BY_ID_REQUESTED`;
const DELETE_BRAND_SEGMENT_RULE_BY_ID_SUCCESS = `${currentReducer}/DELETE_BRAND_SEGMENT_RULE_BY_ID_SUCCES`;
const DELETE_BRAND_SEGMENT_RULE_BY_ID_FAILURE = `${currentReducer}/DELETE_BRAND_SEGMENT_RULE_BY_ID_FAILURE`;

const GET_SEGMENT_PREVIEW_BY_ID_REQUESTED = `${currentReducer}/GET_SEGMENT_PREVIEW_BY_ID_REQUESTED`;
const GET_SEGMENT_PREVIEW_BY_ID_SUCCESS = `${currentReducer}/GET_SEGMENT_PREVIEW_BY_ID_SUCCESS`;
const GET_SEGMENT_PREVIEW_BY_ID_FAILURE = `${currentReducer}/GET_SEGMENT_PREVIEW_BY_ID_FAILURE`;

const GET_SEGMENT_OFFERS_REQUESTED = `${currentReducer}/GET_SEGMENT_OFFERS_REQUESTED`;
const GET_SEGMENT_OFFERS_SUCCESS = `${currentReducer}/GET_SEGMENT_OFFERS_SUCCESS`;
const GET_SEGMENT_OFFERS_FAILURE = `${currentReducer}/GET_SEGMENT_OFFERS_FAILURE`;

const DEACTIVATE_SEGMENT_OFFER_REQUESTED = `${currentReducer}/DEACTIVATE_SEGMENT_OFFER_REQUESTED`;
const DEACTIVATE_SEGMENT_OFFER_SUCCESS = `${currentReducer}/DEACTIVATE_SEGMENT_OFFER_SUCCESS`;
const DEACTIVATE_SEGMENT_OFFER_FAILURE = `${currentReducer}/DEACTIVATE_SEGMENT_OFFER_FAILURE`;

const REISSUE_SEGMENT_OFFER_REQUESTED = `${currentReducer}/REISSUE_SEGMENT_OFFER_REQUESTED`;
const REISSUE_SEGMENT_OFFER_SUCCESS = `${currentReducer}/REISSUE_SEGMENT_OFFER_SUCCESS`;
const REISSUE_SEGMENT_OFFER_FAILURE = `${currentReducer}/REISSUE_SEGMENT_OFFER_FAILURE`;

const ISSUE_SEGMENT_OFFER_REQUESTED = `${currentReducer}/ISSUE_SEGMENT_OFFER_REQUESTED`;
const ISSUE_SEGMENT_OFFER_SUCCESS = `${currentReducer}/ISSUE_SEGMENT_OFFER_SUCCESS`;
const ISSUE_SEGMENT_OFFER_FAILURE = `${currentReducer}/ISSUE_SEGMENT_OFFER_FAILURE`;

const ADD_SEGMENT_GUEST_REQUESTED = `${currentReducer}/ADD_SEGMENT_GUEST_REQUESTED`;
const ADD_SEGMENT_GUEST_SUCCESS = `${currentReducer}/ADD_SEGMENT_GUEST_SUCCESS`;
const ADD_SEGMENT_GUEST_FAILURE = `${currentReducer}/ADD_SEGMENT_GUEST_FAILURE`;

const SEARCH_SEGMENT_GUESTS_REQUESTED = `${currentReducer}/SEARCH_SEGMENT_GUESTS_REQUESTED`;
const SEARCH_SEGMENT_GUESTS_SUCCESS = `${currentReducer}/SEARCH_SEGMENT_GUESTS_SUCCESS`;
const SEARCH_SEGMENT_GUESTS_FAILURE = `${currentReducer}/SEARCH_SEGMENT_GUESTS_FAILURE`;

const DELETE_SEGMENT_GUESTS_REQUESTED = `${currentReducer}/DELETE_SEGMENT_GUESTS_REQUESTED`;
const DELETE_SEGMENT_GUESTS_SUCCESS = `${currentReducer}/DELETE_SEGMENT_GUESTS_SUCCESS`;
const DELETE_SEGMENT_GUESTS_FAILURE = `${currentReducer}/DELETE_SEGMENT_GUESTS_FAILURE`;

const UPLOAD_ACTION_REQUESTED = `${currentReducer}/UPLOAD_ACTION_REQUESTED`;
const UPLOAD_ACTION_SUCCESS = `${currentReducer}/UPLOAD_ACTION_SUCCESS`;
const UPLOAD_ACTION_FAILURE = `${currentReducer}/UPLOAD_ACTION_FAILURE`;

const RESET_FILTERED_GUESTS = `${currentReducer}/RESET_FILTERED_GUESTS`;

const UPLOAD_CSV_GUESTS_REQUESTED = `${currentReducer}/UPLOAD_CSV_GUESTS_REQUESTED`;
const UPLOAD_CSV_GUESTS_SUCCESS = `${currentReducer}/UPLOAD_CSV_GUESTS_SUCCESS`;
const UPLOAD_CSV_GUESTS_FAILURE = `${currentReducer}/UPLOAD_CSV_GUESTS_FAILURE`;


const ISSUE_MANAGER_OFFER_REQUESTED = `${currentReducer}/ISSUE_MANAGER_OFFER_REQUESTED`;
const ISSUE_MANAGER_OFFER_SUCCESS = `${currentReducer}/ISSUE_MANAGER_OFFER_SUCCESS`;
const ISSUE_MANAGER_OFFER_FAILURE = `${currentReducer}/ISSUE_MANAGER_OFFER_FAILURE`;

const SEARCH_MANAGER_GUESTS_REQUESTED = `${currentReducer}/SEARCH_MANAGER_GUESTS_REQUESTED`;
const SEARCH_MANAGER_GUESTS_SUCCESS = `${currentReducer}/SEARCH_MANAGER_GUESTS_SUCCESS`;
const SEARCH_MANAGER_GUESTS_FAILURE = `${currentReducer}/SEARCH_MANAGER_GUESTS_FAILURE`;

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  segmentList: [],
  segmentData: null,
  segmentRuleTypes: [],
  segmentDateTypes: [],
  segmentPeriodTypes: [],
  segmentMetricTypes: [],
  segmentMetricValueTypes: [],
  segmentMetricOperatorTypes: [],
  previewData: [],
  filteredGuestsData: null,
  filteredManagerGuestsData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEGMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentList: action.result
      };
    }
    case GET_SEGMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentData: action.result,
      };
    }
    case GET_SEGMENT_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_SEGMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SEGMENT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_SEGMENT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_SEGMENT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_SEGMENT_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_SEGMENT_RULE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_RULE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentRuleTypes: action.result
      };
    }
    case GET_SEGMENT_RULE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_DATE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_DATE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentDateTypes: action.result
      };
    }
    case GET_SEGMENT_DATE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_PERIOD_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_PERIOD_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentPeriodTypes: action.result
      };
    }
    case GET_SEGMENT_PERIOD_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_METRIC_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_METRIC_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentMetricTypes: action.result
      };
    }
    case GET_SEGMENT_METRIC_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_METRIC_VALUE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_METRIC_VALUE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentMetricValueTypes: action.result
      };
    }
    case GET_SEGMENT_METRIC_VALUE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_SEGMENT_MATRIC_OPERATOR_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_SEGMENT_MATRIC_OPERATOR_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        segmentMetricOperatorTypes: action.result
      };
    }
    case GET_SEGMENT_MATRIC_OPERATOR_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case SAVE_BRAND_SEGMENT_RULE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_BRAND_SEGMENT_RULE_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case SAVE_BRAND_SEGMENT_RULE_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case DELETE_BRAND_SEGMENT_RULE_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_BRAND_SEGMENT_RULE_BY_ID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_BRAND_SEGMENT_RULE_BY_ID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_SEGMENT_PREVIEW_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGMENT_PREVIEW_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        previewData: action.result,
      };
    }
    case GET_SEGMENT_PREVIEW_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_SEGMENT_OFFERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SEGMENT_OFFERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_SEGMENT_OFFERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DEACTIVATE_SEGMENT_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DEACTIVATE_SEGMENT_OFFER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DEACTIVATE_SEGMENT_OFFER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case REISSUE_SEGMENT_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REISSUE_SEGMENT_OFFER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case REISSUE_SEGMENT_OFFER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ISSUE_SEGMENT_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ISSUE_SEGMENT_OFFER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ISSUE_SEGMENT_OFFER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_SEGMENT_GUEST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SEGMENT_GUEST_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_SEGMENT_GUEST_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SEARCH_SEGMENT_GUESTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SEARCH_SEGMENT_GUESTS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        filteredGuestsData: action.result,
      };
    }
    case SEARCH_SEGMENT_GUESTS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        filteredGuestsData: [],
      };
    }
    case DELETE_SEGMENT_GUESTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SEGMENT_GUESTS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_SEGMENT_GUESTS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPLOAD_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case RESET_FILTERED_GUESTS: {
      return {
        ...state,
        filteredGuestsData: action.payload,
      };
    }
    case UPLOAD_CSV_GUESTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_CSV_GUESTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_CSV_GUESTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case ISSUE_MANAGER_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ISSUE_MANAGER_OFFER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ISSUE_MANAGER_OFFER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case SEARCH_MANAGER_GUESTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SEARCH_MANAGER_GUESTS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        filteredManagerGuestsData: action.result,
      };
    }
    case SEARCH_MANAGER_GUESTS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        filteredManagerGuestsData: [],
      };
    }
    default:
      return state;
  }
};

export const getSegmentList = (brandId) => {
  return {
    types: [GET_SEGMENT_REQUESTED, GET_SEGMENT_SUCCESS, GET_SEGMENT_FAILURE],
    promise: client => client.get(`getSegmentList/${brandId}`)
  };
};
export const getBrandSegmentById = (segmentId) => {
  return {
    types: [GET_SEGMENT_BY_ID_REQUESTED, GET_SEGMENT_BY_ID_SUCCESS, GET_SEGMENT_BY_ID_FAILURE],
    promise: client => client.get(`getSegementById/${segmentId}`)
  };
};

export const addSegment = (data) => {
  return {
    types: [ADD_SEGMENT_REQUESTED, ADD_SEGMENT_SUCCESS, ADD_SEGMENT_FAILURE],
    promise: client => client.post('addSegment', { data })
  };
};
export const updateSegment = (data) => {
  return {
    types: [UPDATE_SEGMENT_REQUESTED, UPDATE_SEGMENT_SUCCESS, UPDATE_SEGMENT_FAILURE],
    promise: client => client.post('updateSegment', { data })
  };
};

export const getSegmentRuleTypes = () => {
  return {
    types: [
      GET_SEGMENT_RULE_TYPES_REQUESTED,
      GET_SEGMENT_RULE_TYPES_SUCCESS,
      GET_SEGMENT_RULE_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentRuleTypes')
  };
};
export const getSegmentDateTypes = () => {
  return {
    types: [
      GET_SEGMENT_DATE_TYPES_REQUESTED,
      GET_SEGMENT_DATE_TYPES_SUCCESS,
      GET_SEGMENT_DATE_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentDateTypes')
  };
};
export const getSegmentPeriodTypes = () => {
  return {
    types: [
      GET_SEGMENT_PERIOD_TYPES_REQUESTED,
      GET_SEGMENT_PERIOD_TYPES_SUCCESS,
      GET_SEGMENT_PERIOD_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentPeriodTypes')
  };
};
export const getSegmentMetricTypes = () => {
  return {
    types: [
      GET_SEGMENT_METRIC_TYPES_REQUESTED,
      GET_SEGMENT_METRIC_TYPES_SUCCESS,
      GET_SEGMENT_METRIC_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentMetricTypes')
  };
};
export const getSegmentMetricValueTypes = () => {
  return {
    types: [
      GET_SEGMENT_METRIC_VALUE_TYPES_REQUESTED,
      GET_SEGMENT_METRIC_VALUE_TYPES_SUCCESS,
      GET_SEGMENT_METRIC_VALUE_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentMetricValueTypes')
  };
};
export const getSegmentMetricOperatorTypes = () => {
  return {
    types: [
      GET_SEGMENT_MATRIC_OPERATOR_TYPES_REQUESTED,
      GET_SEGMENT_MATRIC_OPERATOR_TYPES_SUCCESS,
      GET_SEGMENT_MATRIC_OPERATOR_TYPES_FAILURE
    ],
    promise: client => client.get('getSegmentMetricOperatorTypes')
  };
};
export const saveBrandSegmentRule = (data) => {
  return {
    types: [
      SAVE_BRAND_SEGMENT_RULE_REQUESTED,
      SAVE_BRAND_SEGMENT_RULE_SUCCESS,
      SAVE_BRAND_SEGMENT_RULE_FAILURE
    ],
    promise: client => client.post('saveBrandSegmentRule', { data })
  };
};

export const deleteBrandSegmentRule = (ruleId) => {
  return {
    types: [
      SAVE_BRAND_SEGMENT_RULE_REQUESTED,
      SAVE_BRAND_SEGMENT_RULE_SUCCESS,
      SAVE_BRAND_SEGMENT_RULE_FAILURE
    ],
    promise: client => client.post(`deleteSegementRuleId/${ruleId}`)
  };
};
export const getSegmentGuests = (segmentId) => {
  return {
    types: [
      GET_SEGMENT_PREVIEW_BY_ID_REQUESTED,
      GET_SEGMENT_PREVIEW_BY_ID_SUCCESS,
      GET_SEGMENT_PREVIEW_BY_ID_FAILURE
    ],
    promise: client => client.get(`previewBySegmentId/${segmentId}`)
  };
};

export const getSegmentOffersByBrandId = (data) => {
  return {
    types: [
      GET_SEGMENT_OFFERS_REQUESTED,
      GET_SEGMENT_OFFERS_SUCCESS,
      GET_SEGMENT_OFFERS_FAILURE
    ],
    promise: client => client.post('segmentOffers', { data })
  };
};

export const deactivateSegmentOfferById = (data) => {
  return {
    types: [
      DEACTIVATE_SEGMENT_OFFER_REQUESTED,
      DEACTIVATE_SEGMENT_OFFER_SUCCESS,
      DEACTIVATE_SEGMENT_OFFER_FAILURE
    ],
    promise: client => client.post('inactivateSegmentOffer', { data })
  };
};

export const reissueOffers = (data) => {
  return {
    types: [REISSUE_SEGMENT_OFFER_REQUESTED, REISSUE_SEGMENT_OFFER_SUCCESS, REISSUE_SEGMENT_OFFER_FAILURE],
    promise: client => client.post('reissueSegmentOffer', { data })
  };
};

export const issueOffers = (data) => {
  return {
    types: [ISSUE_SEGMENT_OFFER_REQUESTED, ISSUE_SEGMENT_OFFER_SUCCESS, ISSUE_SEGMENT_OFFER_FAILURE],
    promise: client => client.post('issueSegmentOffer', { data })
  };
};

export const addSegmentGuest = (data) => {
  return {
    types: [ADD_SEGMENT_GUEST_REQUESTED, ADD_SEGMENT_GUEST_SUCCESS, ADD_SEGMENT_GUEST_FAILURE],
    promise: client => client.post('addSegmentGuest', { data })
  };
};

export const deleteSegmentGuests = (data) => {
  return {
    types: [DELETE_SEGMENT_GUESTS_REQUESTED, DELETE_SEGMENT_GUESTS_SUCCESS, DELETE_SEGMENT_GUESTS_FAILURE],
    promise: client => client.post('deleteSegmentGuests', { data })
  };
};

export const searchSegmentGuests = (data) => {
  return {
    types: [
      SEARCH_SEGMENT_GUESTS_REQUESTED,
      SEARCH_SEGMENT_GUESTS_SUCCESS,
      SEARCH_SEGMENT_GUESTS_FAILURE
    ],
    promise: client => client.post('searchSegmentGuest', { data })
  };
};

export const importGuestOptIn = (id, data) => {
  return {
    types: [UPLOAD_ACTION_REQUESTED, UPLOAD_ACTION_SUCCESS, UPLOAD_ACTION_FAILURE],
    promise: client => client.post(`importGuestOptIn/${id}`, { data })
  };
};

export const resetFilteredGuests = () => {
  return {
    type: RESET_FILTERED_GUESTS,
    payload: [],
  };
};

export const importCSVSegmentGuests = (segmentId, data) => {
  return {
    types: [UPLOAD_CSV_GUESTS_REQUESTED, UPLOAD_CSV_GUESTS_SUCCESS, UPLOAD_CSV_GUESTS_FAILURE],
    promise: client => client.post(`importCSVSegmentGuests/${segmentId}`, { data })
  };
};
export const issueManagerOffers = (data) => {
  return {
    types: [ISSUE_MANAGER_OFFER_REQUESTED, ISSUE_MANAGER_OFFER_SUCCESS, ISSUE_MANAGER_OFFER_FAILURE],
    promise: client => client.post('issueManagerOffer', { data })
  };
};
export const searchManagerGuests = (data) => {
  return {
    types: [
      SEARCH_MANAGER_GUESTS_REQUESTED,
      SEARCH_MANAGER_GUESTS_SUCCESS,
      SEARCH_MANAGER_GUESTS_FAILURE
    ],
    promise: client => client.post('managerSearchGuest', { data })
  };
};
